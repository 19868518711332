import styled from "styled-components";
import TokenOmitSvg from "src/assets/icons/contract/token-omit.svg";
import ImageWithFallback from "../images/ImageWithFallback";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";

const Container = styled.div`
  display: flex;
`;

export interface IconProps {
  index: number;
  size: number;
  offset: number;
}

const Icon = styled.img<IconProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  z-index: ${(props) => props.index};

  border-radius: 50%;

  &:not(:first-child) {
    margin-left: -${(props) => props.offset}px;
  }
`;

const getIcon = (props: IconProps) => {
  return styled.img`
    width: ${props.size}px;
    height: ${props.size}px;
    z-index: ${props.index};

    &:not(:first-child) {
      margin-left: -${props.offset}px;
    }
  `;
};

interface TokensIconProps {
  iconList: string[];
  size?: number;
  offset?: number;
  max?: number;
}

const TokensIcon: React.FC<TokensIconProps> = ({
  iconList,
  size = 22,
  offset = 7,
  max,
}) => {
  if (!!max && iconList.length > max) {
    iconList[max] = TokenOmitSvg;
    iconList = iconList.slice(0, max + 1);
  }

  return (
    <Container>
      {iconList.map((icon, index) => {
        return (
          // <ImageWithFallback
          //   key={icon}
          //   src={icon}
          //   StyledImg={getIcon({ index: index, size: size, offset: offset })}
          //   fallbackSrc={DefaultTokenSvg}
          // />
          <ImageWithFallback<IconProps>
            src={icon}
            key={icon + index}
            fallbackSrc={DefaultTokenSvg}
            StyledImg={Icon}
            extraProps={{ index: index, size: size, offset: offset }}
          />
        );
      })}
    </Container>
  );
};

export default TokensIcon;
