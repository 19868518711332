import React, { useEffect } from "react";
import { get } from "src/store/local";
import { KEYS } from "src/store/keys";
import { authorizedDomainArr } from "src/config";
import { StorageKontosKey } from "@zkkontos/kontos-sdk/src/index";
import localkepper from "src/store/localKeeper";

export function checkDomainMatch(url1: string, url2: string): boolean {
  const domain1 = new URL(url1).hostname;
  const domain2 = new URL(url2).hostname;
  return domain1 === domain2;
}

function filterAccountNames(a: string[], b: { accountName: string }[]) {
  return b
    .map((obj) => obj.accountName)
    .filter((accountName) => !a.includes(accountName));
}

const Auth: React.FC = () => {
  // login
  useEffect(() => {
    try {
      // check if in iframe
      if (window === window.parent) return;
      if (window.parent) {
        const data: StorageKontosKey[] = get(KEYS.store_kontos_key) || [];
        const dataRecovering: string[] =
          get(KEYS.store_accounts_recovering) || [];
        const accountsArr = filterAccountNames(dataRecovering, data);
        const currentAccountSelectedIndex = localkepper.getIndex() || 0;
        const currentAccount = data[currentAccountSelectedIndex].accountName;
        authorizedDomainArr.map((item) => {
          window.parent.postMessage(
            {
              data: accountsArr,
              target: "kontosInpage",
            },
            item
          );
          window.parent.postMessage(
            {
              data: currentAccount,
              target: "kontosInpageAccountSelected",
            },
            item
          );
        });
      }
    } catch (e) {
      console.warn("Auth component error", e);
    }
  }, []);

  // switch account
  // useEffect(() => {
  //   const messageHandler = (event: MessageEvent) => {
  //     if (!checkDomainMatch(event.origin, window.location.href)) {
  //       return;
  //     }
  //     const receiveData: tReceiveData = event.data;
  //     console.log('receiveData', receiveData);
  //     if (receiveData && receiveData.target !== 'kontosInpage') return;
  //     // dispatch(setAccounts(receiveData.data.map(obj => obj.accountName) || []));
  //     // dispatch(setPrivateKey(receiveData.data.map(obj => obj.accountName) || []));
  //     // if (receiveData.data.length > 0) {
  //     //   dispatch(setCurrentSelectedAccount(receiveData.data[0]));
  //     // }
  //   };
  //
  //   window.addEventListener('message', messageHandler);
  //
  //   return () => {
  //     window.removeEventListener('message', messageHandler);
  //   };
  // }, []);

  return null;
};

export default Auth;
