import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useState } from "react";
import setGuardian from "src/assets/icons/setUpGuardian.svg";
import deleteIco from "src/assets/icons/delete1.svg";
import deleteIcoGrey from "src/assets/icons/delete-grey.svg";
import editIco from "src/assets/icons/edit.svg";
import editIcoGrey from "src/assets/icons/edit-grey.svg";
import InputWithIcons from "src/components/input/KontosInputV2";
import { ReactComponent as SecurityGoogle } from "src/assets/icons/security-google.svg";
import HorizontalLine from "src/components/line/HorizontalLine";
import AddNewBtn from "src/components/start/AddNewBtn";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import TransformingIcon from "src/components/icons/TransformingIcon";
import { Trans } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex: 1;

  .main {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    .description {
      color: var(--Deep-800, #1a2535);
      text-align: center;

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */

      span {
        color: var(--Kontos-Blue, #413dec);
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
    }

    .setGuardian {
      width: 100px;
      height: 100px;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }

    .buttonWrap {
      width: calc(100% - 40px);
      position: fixed;
      bottom: 16px;
    }
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > div {
    margin-right: 15px;
  }
`;

type IProps = {
  submitFunction: () => void;
  editGuardian: (index: number) => void;
  addGuardian: () => void;
  guardianArr: string[];
  kontosName: string;
  deleteGuardian: (index: number) => void;
  currentThreshold: number;
  minThreshold: number;
  maxThreshold: number;
};

type IRightsProps = {
  k: number;
};

const SetUpGuardians: React.FC<IProps> = ({
  submitFunction,
  editGuardian,
  addGuardian,
  guardianArr,
  kontosName,
  deleteGuardian,
  currentThreshold,
  minThreshold,
  maxThreshold,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClearClick = () => {
    setInputValue("");
  };

  const RightComponent = ({ k }: IRightsProps) => {
    return (
      <RightWrapper>
        <TransformingIcon
          defaultIcon={editIcoGrey}
          hoverIcon={editIco}
          onClick={() => {
            editGuardian(k);
          }}
        />
        <TransformingIcon
          defaultIcon={deleteIcoGrey}
          hoverIcon={deleteIco}
          onClick={() => {
            deleteGuardian(k);
          }}
        />
      </RightWrapper>
    );
  };

  const canSubmit = !(guardianArr.length < minThreshold);

  return (
    <Wrapper>
      <div className={"main"}>
        <img className={"setGuardian"} src={setGuardian} alt="setGuardian" />
        <div className={"description"}>
          <Trans i18nKey={"trans-to-safeguard-your-account"}>
            To safeguard your account <span>{{ kontosName } as any}.os</span>,
            set up a minimum of <span>{{ minThreshold } as any}</span> guardians
            with a threshold set to at least{" "}
            <span>{{ minThreshold } as any}</span>.
          </Trans>
        </div>
        <div className={"lineWrap"}>
          <HorizontalLine />
        </div>
        <div className={"inputWrap"}>
          {guardianArr.map((item, index) => {
            return (
              <InputWithIcons
                key={index}
                leftIcon={<SecurityGoogle />}
                rightIcon={<RightComponent k={index} />}
                placeholder={t("")}
                value={item}
                onChange={handleInputChange}
                disabled={false}
                isDelete={true}
              />
            );
          })}

          <div
            style={{
              marginBottom: "20px",
            }}
            onClick={() => {
              addGuardian();
            }}
          >
            <AddNewBtn text={t("Add Guardians")} />
          </div>
        </div>
        <div className={"buttonWrap"}>
          <Button
            disabled={!canSubmit}
            onClick={submitFunction}
            text={
              t("Confirm") +
              " " +
              `${!canSubmit ? guardianArr.length + "/" + minThreshold : ""}`
            }
            height={"56px"}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default observer(SetUpGuardians);
