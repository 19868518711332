import styled from "styled-components";
import infoIcon from "src/assets/icons/trade/info.svg";
import infoActiveIcon from "src/assets/icons/trade/info-active.svg";

const Container = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: url(${infoIcon});
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center center;

  @media (hover: hover) {
    &:hover {
      background-image: url(${infoActiveIcon});
    }
  }
  &:active {
    background-image: url(${infoActiveIcon});
  }
`;

interface IProps {
  className?: string;
  onClick: (e: React.MouseEvent) => void;
}

export const InfoButton: React.FC<IProps> = ({ className, onClick }) => {
  return <Container className={className} onClick={(e) => onClick(e)} />;
};
