import { ROUTE_TRADE_SPOT, ROUTE_TRADE_SWAP } from "src/router/router-config";
import { KontosTab, Tab } from "src/components/tab/KontosTab";
import styled from "styled-components";
import { t } from "i18next";
import { SingleIconButton } from "src/components/button/SingleIconButton";
import Floater from "react-floater";
import { ManageAssetTip } from "src/components/tips/ManageAssetTip";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { useRef, useState } from "react";
import { useSetState } from "react-use";
import Joyride, { Step, CallBackProps, STATUS } from "react-joyride";
import { JoyRideToolTip } from "src/components/onboarding/JoyRideTooltip";
import { OnboardingType } from "src/store/localKeeper";

const Container = styled.div`
  margin-top: 27px;
  margin-bottom: 15px;
  height: 24px;
  width: 100%;
  padding: 0 16px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ManageBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TradeTabs: Tab[] = [
  { route: ROUTE_TRADE_SPOT, label: t("Trade") },
  { route: ROUTE_TRADE_SWAP, label: t("Swap") },
];

interface IProps {
  onClick?: () => void;
}

interface PaymentOnboardingState {
  run: boolean;
  steps: Step[];
  spotLightRadius: string;
}

export const TradeNavigator: React.FC<IProps> = observer(({ onClick }) => {
  const { tradeStore, userStore } = useStores();
  const manageBtnRef = useRef<HTMLDivElement | null>(null);
  const [showPaymentOnboarding] = useState(!tradeStore.fromAi);
  const [{ run, steps, spotLightRadius }] = useSetState<PaymentOnboardingState>(
    {
      run: true,
      spotLightRadius: "99px",
      steps: [
        {
          content: (
            <>
              <span>
                {t(
                  "You can manage your payment methods here, which will determine the type of assets you use to pay fees."
                )}
              </span>
            </>
          ),
          placement: "bottom",
          target: ".payment-asset-manage",
          title: t("Manage your payment!"),
          disableBeacon: true,
          spotlightPadding: 0,
          offset: 0,
        },
      ],
    }
  );

  const handlePaymentJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      userStore.markOnboardingFinished(OnboardingType.Payment);
    }
  };

  // useMouseDownOutside({
  //   ref: manageBtnRef,
  //   callback: () => {
  //     tradeStore.setShowManageTip(false);
  //   },
  //   shouldClose: true,
  // });

  return (
    <Container>
      <KontosTab
        tabs={TradeTabs}
        fontStyle={{ fontSize: "20px", lineHeight: "24px" }}
        gap={12}
      />

      <ManageBtnWrapper ref={manageBtnRef}>
        <SingleIconButton
          className="payment-asset-manage"
          type="paymentAssetsManage"
          size={24}
          onClick={onClick}
        />
      </ManageBtnWrapper>

      {tradeStore.showManageTip && (
        <Floater
          open
          eventDelay={0}
          component={<ManageAssetTip />}
          styles={{
            options: {
              zIndex: 2000000,
            },
            arrow: {
              color: "var(--Deep-800, #1a2535)",
              length: 5,
              spread: 11,
            },
          }}
          offset={3}
          target={".payment-asset-manage"}
          placement={"top"}
        />
      )}

      {userStore.needOnboardingPayment && showPaymentOnboarding && (
        <Joyride
          callback={handlePaymentJoyrideCallback}
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          continuous
          run={run}
          showProgress
          showSkipButton
          steps={steps}
          floaterProps={{
            styles: {
              arrow: {
                color: "#fff",
                spread: 20,
                length: 14,
              },
            },
          }}
          styles={{
            options: {
              zIndex: 2000000,
            },
            spotlight: {
              borderRadius: spotLightRadius,
            },
            overlay: {
              backgroundColor: "rgba(0, 13, 31, 0.30)",
            },
          }}
          tooltipComponent={JoyRideToolTip}
        />
      )}
    </Container>
  );
});
