import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ArrowSvg from "src/assets/icons/trade//trade-black-right-slim-arrow.svg";
import SearchSvg from "src/assets/icons/trade/trade-search.svg";
import HorizontalScrollableElements, {
  ChainIndex,
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import CircleIconPair from "src/components/icons/CircleIconPair";
import { FtAsset } from "@/type/zkkontos";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import {
  calcFtAssetBalanceInUSD,
  getChainIcon,
} from "src/utils/zkkontosHelper";
import { ethers } from "ethers";
import { loadingStore } from "src/store/loadingStore";
import toast from "src/components/toast/Toast";
import { ChainConfig } from "@/type/zkkontos";
import { fetchSpecificFtAsset } from "src/service/trade-service";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { t } from "i18next";
import { useStores } from "src/hooks/useStore";
import { EndMessage } from "src/components/no-data/EndMessage";
import { AssetSecurityIcon } from "./asset-select/AssetSecurityIcon";
import { CantSellTip } from "src/components/tips/SecurityTip";
import Floater from "react-floater";

const SearchContainer = styled.div`
  margin: 0 16px 16px 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 11px 10px 10px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  margin-bottom: 8px;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

const ItemTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;

const ItemTextContainer2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-end;
`;

const ItemText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;

  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
`;

const ItemText2 = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans;
  font-size: 12px;
  font-weight: 400;
`;

const RightImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const ItemContainer = styled.div<{ $disable?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;

  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);

  &:not(:first-child) {
    margin-top: 16px;
  }

  @media (hover: hover) {
    &:hover {
      border: 1px solid var(--Kontos-Blue, #413dec);
      background-color: ${(props) => props.theme.colors.__white};
    }
  }

  ${(props) =>
    props.$disable &&
    `${ItemText2},${ItemText} { color:  ${props.theme.colors.__deep_200} } `};

  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
  -webkit-tap-highlight-color: transparent;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;

  .no-border-item {
    border: none;
    background: ${(props) => props.theme.colors.__white};

    &:active {
      background-color: ${(props) => props.theme.colors.__deep_50};
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${(props) => props.theme.colors.__deep_25};
      }
    }
  }
`;

interface SelectAssetsModalProps {
  onChainChange?: (chainIndex: ChainIndex) => void;
  onChoose?: (asset: FtAsset) => void;
  allFtAssets: FtAsset[];
  chains: ChainConfig[];
  showBalance: boolean;
  allowSearch?: boolean;
  displayPrecision?: number;
  enableWhitelist?: boolean;
  initChainIndex?: ChainIndex;
}

const ITEMS_TO_INIT = 15;
const ITEMS_TO_FETCH = 10;

const SelectAssetsModal: React.FC<SelectAssetsModalProps> = ({
  onChainChange,
  onChoose,
  allFtAssets,
  chains,
  showBalance,
  allowSearch,
  displayPrecision,
  enableWhitelist = false,
  initChainIndex,
}) => {
  const { chainStore } = useStores();
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentAssets, setCurrentAssets] = useState<FtAsset[]>(allFtAssets);
  const [chainIndex, setChainIndex] = useState<ChainIndex>(
    initChainIndex || NonChainIndex.All
  );
  const [filteredItems, setFilteredItems] = useState<FtAsset[]>(
    allFtAssets || []
  );
  const [visibleTokens, setVisibleTokens] = useState<number>(ITEMS_TO_INIT);
  const [needEndText, setNeedEndText] = useState<boolean>(false);

  const fetchMoreData = () => {
    !needEndText && setNeedEndText(true);
    setVisibleTokens((prev) => prev + ITEMS_TO_FETCH);
  };

  const handleChooseAsset = (asset: FtAsset) => {
    onChainChange?.(chainIndex);
    onChoose?.(asset);
  };

  // Search token by addreee
  const searchToken = async (
    chainIndex: string,
    assetAddress: string
  ): Promise<FtAsset | undefined> => {
    loadingStore.showLoading();
    try {
      const ftAsset = await fetchSpecificFtAsset(chainIndex, assetAddress);
      return ftAsset;
    } catch (e) {
      const errorMessage =
        e instanceof Error
          ? e.message
          : (t("Cannot find token with this address") as string);
      toast({
        text: errorMessage,
        type: "error",
      });
    } finally {
      loadingStore.hideLoading();
    }
    return undefined;
  };

  // Handle chain select
  // This will set current asset array
  const handleChainSelect = async (_chainIndex: string) => {
    setChainIndex(_chainIndex);
  };

  useEffect(() => {
    switch (chainIndex) {
      case NonChainIndex.All:
        setCurrentAssets(allFtAssets);
        break;
      default:
        const targetChainFtAssets = allFtAssets.filter(
          (asset) => asset.chainIndex === chainIndex
        );
        setCurrentAssets(targetChainFtAssets);
    }
  }, [allFtAssets, chainIndex]);

  // This focuses on filter input
  useEffect(() => {
    setVisibleTokens(ITEMS_TO_INIT);
    setNeedEndText(false);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    let filtered = currentAssets;

    if (allowSearch === true && ethers.utils.isAddress(searchTerm)) {
      filtered = filtered.filter((asset) =>
        asset.address.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filtered.length === 0) {
        searchToken(chainIndex, searchTerm).then((ftAsset) => {
          if (ftAsset) {
            filtered = [ftAsset];
          } else {
            filtered = [];
          }
          setFilteredItems(filtered || []);
        });
      } else {
        setFilteredItems(filtered || []);
      }
    } else {
      filtered = filtered
        .filter((asset) =>
          asset.symbol.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
          const aMatchExact =
            a.symbol.toLowerCase() === searchTerm.toLowerCase();
          const bMatchExact =
            b.symbol.toLowerCase() === searchTerm.toLowerCase();

          if (aMatchExact && !bMatchExact) {
            return -1;
          } else if (!aMatchExact && bMatchExact) {
            return 1;
          }

          const aIndex = Math.min(
            a.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
          );
          const bIndex = Math.min(
            b.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
          );

          if (aIndex !== bIndex) {
            return aIndex - bIndex;
          }

          const aLength = a.symbol.length;
          const bLength = b.symbol.length;
          return aLength - bLength;
        });

      if (enableWhitelist) {
        setFilteredItems(
          filtered.sort((a, b) => {
            return +b.isWhitelist - +a.isWhitelist;
          })
        );
      } else {
        setFilteredItems(filtered);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAssets, searchTerm]);

  return (
    <SearchContainer>
      <SearchInput
        ref={inputRef}
        placeholder={t("Search Asset")}
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(e.target.value)
        }
      />
      <HorizontalScrollableElements
        chains={chains}
        onSelect={handleChainSelect}
        chainIndex={chainIndex}
        hasAll
      />
      <Scrollable id="scrollableDiv" ref={scrollRef}>
        {filteredItems?.length > 0 ? (
          <InfiniteScroll
            dataLength={visibleTokens} // The current length of displayed data
            next={fetchMoreData} // The function to trigger to load more
            hasMore={visibleTokens < filteredItems?.length} // Whether there are more items to load
            loader={<h4>Loading...</h4>}
            endMessage={<EndMessage />}
            scrollableTarget="scrollableDiv"
          >
            {filteredItems.slice(0, visibleTokens).map((item, index) =>
              enableWhitelist && !item.isWhitelist ? (
                <Floater
                  key={index}
                  event="hover"
                  eventDelay={0}
                  component={<CantSellTip />}
                  styles={{
                    options: {
                      zIndex: 99999999,
                    },
                    arrow: {
                      color: "var(--Deep-800, #1a2535)",
                      length: 5,
                      spread: 11,
                    },
                  }}
                  offset={3}
                  placement={"top"}
                >
                  <ItemContainer
                    className={"no-border-item"}
                    onClick={() => {
                      if (enableWhitelist && !item.isWhitelist) {
                        toast({
                          text: t(
                            "Unable to select this asset because it is not on Kontos' token whitelist!"
                          ),
                          type: "warning",
                        });
                        return;
                      }
                      handleChooseAsset(item);
                    }}
                    $disable={enableWhitelist && !item.isWhitelist}
                  >
                    <CircleIconPair
                      mainIcon={item?.imageUrl}
                      mainIconFallbackSrc={DefaultTokenSvg}
                      subIcon={getChainIcon(
                        chainStore.chains,
                        item.chainIndex,
                        item.address
                      )}
                      subIconFallbackSrc={DefaultChainSvg}
                      mainWidth={32}
                      mainHeight={32}
                      subWidth={16}
                      subHeight={16}
                      totalWidth={40}
                      totalHeight={32}
                    />
                    <ItemTextContainer>
                      <ItemText>
                        {item.symbol}
                        <AssetSecurityIcon
                          isGreatLiquidity={item?.isGreatLiquidity}
                          securityLevel={item?.securityLevel}
                          disableHoverToClick
                        />
                      </ItemText>
                      <ItemText2>{item.chainSymbol}</ItemText2>
                    </ItemTextContainer>
                    {!showBalance && <RightImage src={ArrowSvg} alt="Select" />}
                    {showBalance && (
                      <ItemTextContainer2>
                        <ItemText>
                          {new KontosNumber(
                            item.balance,
                            DEFAULT_DECIMAL
                          ).toFixed(displayPrecision)}
                        </ItemText>
                        <ItemText2>
                          {calcFtAssetBalanceInUSD(
                            new KontosNumber(item.usdPrice, DEFAULT_DECIMAL),
                            new KontosNumber(item.balance, DEFAULT_DECIMAL)
                          ).toFixed(displayPrecision) + " USD"}
                        </ItemText2>
                      </ItemTextContainer2>
                    )}
                  </ItemContainer>
                </Floater>
              ) : (
                <ItemContainer
                  key={index}
                  className={"no-border-item"}
                  onClick={() => {
                    if (enableWhitelist && !item.isWhitelist) {
                      toast({
                        text: t(
                          "Unable to select this asset because it is not on Kontos' token whitelist!"
                        ),
                        type: "warning",
                      });
                      return;
                    }
                    handleChooseAsset(item);
                  }}
                  $disable={enableWhitelist && !item.isWhitelist}
                >
                  <CircleIconPair
                    mainIcon={item?.imageUrl}
                    mainIconFallbackSrc={DefaultTokenSvg}
                    subIcon={getChainIcon(
                      chainStore.chains,
                      item.chainIndex,
                      item.address
                    )}
                    subIconFallbackSrc={DefaultChainSvg}
                    mainWidth={32}
                    mainHeight={32}
                    subWidth={16}
                    subHeight={16}
                    totalWidth={40}
                    totalHeight={32}
                  />
                  <ItemTextContainer>
                    <ItemText>
                      {item.symbol}
                      <AssetSecurityIcon
                        isGreatLiquidity={item?.isGreatLiquidity}
                        securityLevel={item?.securityLevel}
                        disableHoverToClick
                      />
                    </ItemText>
                    <ItemText2>{item.chainSymbol}</ItemText2>
                  </ItemTextContainer>
                  {!showBalance && <RightImage src={ArrowSvg} alt="Select" />}
                  {showBalance && (
                    <ItemTextContainer2>
                      <ItemText>
                        {new KontosNumber(
                          item.balance,
                          DEFAULT_DECIMAL
                        ).toFixed(displayPrecision)}
                      </ItemText>
                      <ItemText2>
                        {calcFtAssetBalanceInUSD(
                          new KontosNumber(item.usdPrice, DEFAULT_DECIMAL),
                          new KontosNumber(item.balance, DEFAULT_DECIMAL)
                        ).toFixed(displayPrecision) + " USD"}
                      </ItemText2>
                    </ItemTextContainer2>
                  )}
                </ItemContainer>
              )
            )}
          </InfiniteScroll>
        ) : (
          <NoDataV2
            style={{ marginTop: "-30px" }}
            text={t("No assets found")}
          />
        )}
      </Scrollable>
    </SearchContainer>
  );
};

export default SelectAssetsModal;
