import { MotionStyle, motion } from "framer-motion";
import { ReactNode, memo } from "react";
import styled from "styled-components";

const Content = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

interface IProps {
  className?: string;
  style?: MotionStyle;
  children?: ReactNode;
}

export const AnimatedLayout: React.FC<IProps> = memo(
  ({ className, style, children }) => {
    return (
      <Content
        className={className}
        style={style}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </Content>
    );
  }
);
