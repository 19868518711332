import { SpotMode } from "src/store/trade/SpotStore";
import { motion, useAnimate } from "framer-motion";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useRef, useState } from "react";
import upWhiteArrowIcon from "src/assets/icons/trade/trade-white-up-arrow.svg";
import upGreyArrowIcon from "src/assets/icons/trade/trade-grey-up-arrow.svg";
import { t as t2 } from "i18next";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";

const LEFT_COLOR =
  "linear-gradient(88deg, #8FEF2F 0%, #10CE5C 50%, #B4FDFD 100%)";
const RIGHT_COLOR =
  "linear-gradient(92deg, #FEFEEB 0%, #FF1E2B 49.5%, #FFAFDA 100%)";

const FONT_COLOR = "var(--Deep-200, #b2b6bc)";
const FONT_ACTIVE_COLOR = "var(--White, #fff)";

const DURATION = {
  duration: 0.25,
};

const Container = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: var(--White, #fff);
  padding: 4px;
  display: flex;
  justify-content: center;
  gap: 2px;
  user-select: none;
`;

const FixedBlock = styled.div<{ $isActive?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  background: transparent;
  position: relative;

  color: ${(props) => (props.$isActive ? FONT_ACTIVE_COLOR : FONT_COLOR)};
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 16px;

  cursor: pointer;
`;

const BlockText = styled(motion.span)`
  z-index: 10;
`;

const Slider = styled(motion.div)<{ $bgColor: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${(props) => props.$bgColor};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ArrowIcon = styled.div<{ $open: boolean; $left: boolean }>`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) ${(props) => !props.$open && "rotate(180deg)"};
  width: 12px;
  height: 12px;
  z-index: 10;

  background-image: ${(props) =>
    props.$left ? `url(${upGreyArrowIcon})` : `url(${upWhiteArrowIcon})`};
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: -4px;
  width: calc(100% + 8px);

  padding: 4px;
  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--White, #fff);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);

  overflow: hidden;
  z-index: 10;
`;

const DropdownItem = styled.div`
  padding: 9px 11px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: var(--Deep-200, #b2b6bc);

  @media (hover: hover) {
    &:hover {
      margin: 0 4px;
      border-radius: 4px;
      background: var(--Deep-25, #f5f5f6);
      color: var(--error-notice, #ff1e2b);
    }
  }
`;

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 40,
};

type DropItemType = {
  label: string;
  value: SpotMode;
};

const rightDropItems: DropItemType[] = [
  {
    label: t2("Sell"),
    value: SpotMode.Sell,
  },
  {
    label: t2("Batch Sell"),
    value: SpotMode.BatchSell,
  },
];

interface IProps {
  className?: string;
  mode: SpotMode;
  onSwitch: (mode: SpotMode) => void;
}

export const SpotSwitch: React.FC<IProps> = memo(
  ({ className, mode, onSwitch }) => {
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [leftScope, leftAnimate] = useAnimate();
    const [rightScope, rightAnimate] = useAnimate();
    const [showRightDrop, setShowRightDrop] = useState<boolean>(false);
    const [rightItem, setRightItem] = useState<DropItemType>(() => {
      return (
        rightDropItems.find((item) => item.value === mode) || rightDropItems[0]
      );
    });

    const closeDrop = useCallback(() => {
      setShowRightDrop(false);
    }, []);

    useMouseDownOutside({
      ref: wrapperRef,
      callback: closeDrop,
      shouldClose: true,
    });

    const handleClick = useCallback(
      (newMode: SpotMode, target: "left" | "right") => {
        if (mode === newMode) {
          if (rightDropItems.some((item) => item.value === newMode)) {
            setShowRightDrop((prev) => !prev);
          }
          return;
        }
        if (target === "left") {
          setShowRightDrop(false);
          rightAnimate(rightScope.current, { color: FONT_COLOR }, DURATION);
          leftAnimate(
            leftScope.current,
            { color: FONT_ACTIVE_COLOR },
            DURATION
          );
        } else {
          rightAnimate(
            rightScope.current,
            { color: FONT_ACTIVE_COLOR },
            DURATION
          );
          leftAnimate(leftScope.current, { color: FONT_COLOR }, DURATION);
        }
        onSwitch(newMode);
      },
      [leftAnimate, leftScope, mode, onSwitch, rightAnimate, rightScope]
    );

    const onDropItemClick = useCallback(
      (e: React.MouseEvent, item: DropItemType) => {
        e.stopPropagation();
        setShowRightDrop(false);
        if (item.value !== mode) {
          setRightItem(item);
          onSwitch(item.value);
        }
      },
      [mode, onSwitch]
    );

    return (
      <Container className={className} ref={wrapperRef}>
        {/* Left */}
        <FixedBlock
          $isActive={mode === SpotMode.Buy}
          onClick={() => handleClick(SpotMode.Buy, "left")}
        >
          <BlockText ref={leftScope}>{t("Buy")}</BlockText>
          {mode === SpotMode.Buy && (
            <Slider
              $bgColor={LEFT_COLOR}
              layoutId={"spot"}
              transition={spring}
            />
          )}
        </FixedBlock>

        {/* Right */}
        <FixedBlock
          $isActive={mode === rightItem.value}
          onClick={() => handleClick(rightItem.value, "right")}
        >
          <ArrowIcon $open={showRightDrop} $left={mode === SpotMode.Buy} />
          <BlockText ref={rightScope}>{t(rightItem.label)}</BlockText>
          {mode === rightItem.value && (
            <Slider
              $bgColor={RIGHT_COLOR}
              layoutId={"spot"}
              transition={spring}
            >
              {showRightDrop && (
                <DropdownMenu>
                  {rightDropItems.map((item) => (
                    <DropdownItem
                      key={item.value}
                      onClick={(e) => onDropItemClick(e, item)}
                    >
                      {t(item.label)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </Slider>
          )}
        </FixedBlock>
      </Container>
    );
  }
);
