import KontosNumber from "src/utils/KontosNumber";
import { ftassetsV3, token } from "@zkkontos/kontos-sdk/src/api";
import { DEFAULT_DECIMAL } from "src/config";
import * as BrokerApi from "@zkkontos/kontos-sdk/src/api/brokerApi";
import * as ReqTypes from "@zkkontos/kontos-sdk/src/api/reqTypes";
import { FtAsset } from "src/type/zkkontos";
import { fetchFtAssets } from "src/utils/zkkontosHelper";
import { paramStore } from "src/store/independent/paramStore";
import { API as SdkApi } from "@zkkontos/kontos-sdk/src/api/core/API";
import { uploadErrorOnce } from "./wallet-service";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";

export type MarkPaymentAssetsSignData = {
  ids: number[];
  nonce: number;
};

export const callTaskDataV3WithAbortAndMemory = async (
  params: BrokerApi.ReqTaskDataV3,
  needSave: boolean = true
) => {
  try {
    needSave && paramStore.setTaskDataParams(params);
    return BrokerApi.taskDataV3WithAbort(params);
  } catch (e) {
    uploadErrorOnce(params.account, SdkApi.broker.taskDataV3, params, e);
    throw e;
  }
};

export const getToken = (chainIndex: string, assetAddress: string) => {
  return token({
    chainIndex: chainIndex,
    assetAddress: assetAddress,
  });
};

export const fetchSpecificFtAsset = async (
  chainIndex: string,
  assetAddress: string
): Promise<FtAsset | undefined> => {
  if (Object.values(NonChainIndex).some((item) => item === chainIndex)) {
    chainIndex = "";
  }
  const { ftAssets } = await ftassetsV3({
    regex: assetAddress,
    chainIndex,
    offset: 0,
    limit: 1,
  });
  return ftAssets?.[0] || undefined;
};

// Trade - Spot Buy
export const fetchBuyTaskDataV3 = async (
  account: string,
  receiver: string,
  chainIndex: string,
  assetAddress: string,
  usdAmount: KontosNumber,
  slippage: KontosNumber,
  chargeAsset?: ReqTypes.ReqTaskChargeAsset
): Promise<BrokerApi.RespTaskDataV3> => {
  const intentionBuyData: ReqTypes.ReqTaskIntentionDataV3ForBuy = {
    chainIndex,
    receiver,
    usdAmount: usdAmount.toStringWithDecimal(DEFAULT_DECIMAL),
    slippage: slippage.toStringWithDecimal(DEFAULT_DECIMAL),
    assetAddress,
  };
  return await callTaskDataV3WithAbortAndMemory({
    account,
    payer: account,
    reqTaskIntentionData: intentionBuyData,
    reqTaskChargeAsset: chargeAsset,
  });
};

// Trade - Spot Sell
export const fetchSellTaskDataV3 = async (
  account: string,
  receiver: string,
  sellAssetChainIndex: string,
  sellAssetAddress: string,
  sellAssetAmount: KontosNumber,
  buyAssetChainIndex: string,
  buyAssetAddress: string,
  slippage: KontosNumber
) => {
  const intentionSellData: ReqTypes.ReqTaskIntentionSellDataV3 = {
    sellAssetChainIndex,
    sellAssetAddress,
    sellAssetAmount: sellAssetAmount.toStringWithDecimal(DEFAULT_DECIMAL),
    receiver,
    buyAssetChainIndex,
    buyAssetAddress,
    slippage: slippage.toStringWithDecimal(DEFAULT_DECIMAL),
  };
  return await callTaskDataV3WithAbortAndMemory({
    account,
    payer: account,
    reqTaskIntentionSellData: intentionSellData,
  });
};

// Trade - Spot Batch Sell
export const fetchBatchSellTaskDataV3 = async (
  account: string,
  receiver: string,
  chainIndex: string,
  assetAddress: string,
  sellAssets: FtAsset[],
  usdAmount: KontosNumber,
  slippage: KontosNumber
): Promise<BrokerApi.RespTaskDataV3> => {
  const intentionBatchSellData: ReqTypes.ReqTaskIntentionDataV3ForBatchSell = {
    chainIndex,
    receiver,
    assetAddress,
    slippage: slippage.toStringWithDecimal(DEFAULT_DECIMAL),
    usdAmount: usdAmount.toStringWithDecimal(DEFAULT_DECIMAL),
    sellAssets: sellAssets.map((asset) => {
      return { assetAddress: asset.address, chainIndex: asset.chainIndex };
    }),
  };
  return await callTaskDataV3WithAbortAndMemory({
    account,
    payer: account,
    reqTaskIntentionData: intentionBatchSellData,
  });
};

// Trade = Swap
export const fetchSwapTaskDataV3 = async (
  account: string,
  receiver: string,
  chainIndex: string,
  src: string,
  dst: string,
  srcAmount: KontosNumber,
  slippage: KontosNumber
): Promise<BrokerApi.RespTaskDataV3> => {
  const intentionSwapData: ReqTypes.ReqTaskSwapData = {
    chainIndex,
    src,
    dst,
    srcAmount: srcAmount.toStringWithDecimal(DEFAULT_DECIMAL),
    slippage: slippage.toStringWithDecimal(DEFAULT_DECIMAL),
    receiver,
  };
  return await callTaskDataV3WithAbortAndMemory({
    account,
    payer: account,
    reqTaskSwapData: intentionSwapData,
  });
};

// Dapp - Op Call
export const fetchDappOpCallTaskDataV3 = async (data: {
  account: string;
  payer: string;
  chainIndex: string;
  targetAddress: string;
  value: string;
  callData: string;
}): Promise<BrokerApi.RespTaskDataV3> => {
  const dappOpCallData: ReqTypes.ReqTaskUserOpData = {
    chainIndex: data.chainIndex,
    account: data.account,
    targetAddress: data.targetAddress,
    value: data.value,
    callData: data.callData,
    requiredAssets: [],
  };
  return await callTaskDataV3WithAbortAndMemory({
    account: data.account,
    payer: data.account,
    reqTaskUserOpData: dappOpCallData,
  });
};

// Send - Transfer
export const fetchSendCallTaskDataV3 = async (
  account: string,
  chainIndex: string,
  receiver: string,
  assetAddress: string,
  assetAmount: KontosNumber
): Promise<BrokerApi.RespTaskDataV3> => {
  const sendTransferData: ReqTypes.ReqTaskTransferData = {
    chainIndex,
    receiver,
    assetAddress,
    assetAmount: assetAmount.toStringWithDecimal(DEFAULT_DECIMAL),
  };
  return await callTaskDataV3WithAbortAndMemory({
    account: account,
    payer: account,
    reqTaskTransferData: sendTransferData,
  });
};

export const fetchInitRecommendedFtAssets = async () => {
  return await fetchFtAssets({
    regex: "",
    chainIndex: "",
    offset: 0,
    limit: 30,
    isRecommended: true,
  });
};

export const fetchInitFavoriteFtAssets = async (account: string) => {
  return await fetchFtAssets({
    regex: "",
    chainIndex: "",
    offset: 0,
    limit: 30,
    isRecommended: false,
    inWhoseWatchlist: account,
  });
};
