import styled from "styled-components";
import React from "react";
import { shortAddress } from "src/utils/helper";
import Copy from "../copy/Copy";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .copy-able-address__address {
    font-size: 14px;
    color: ${(props) => props.theme.deep_800};
    font-family: ${(props) => props.theme.fontFamilyNormal};
    font-weight: 400;
  }
  .copy-able-address__address-bold {
    font-family: ${(props) => props.theme.fontFamilyBold};
  }
  .copy-able-address__address:hover {
    cursor: default;
    text-decoration: underline;
    color: ${(props) => props.theme.kontos_blue};
  }
  .copy-able-address__address-click-able {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.kontos_blue};
    }
  }
  .copy-able-address__copy-icon {
    margin-left: 4px;
    width: 12px;
    height: 14px;
    cursor: pointer;
  }
  .copy-able-address__chain-icon {
    width: 22px;
    height: 22px;
  }
  .copy-able-address__chain-icon--left {
    margin-right: 8px;
  }
  .copy-able-address__number {
    margin-left: 4px;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.grey1};
    font-family: ${(props) => props.theme.fontFamilyBold};
  }
`;
type ICopyAbleAddressProps = {
  address: string;
  addressClickAble?: boolean;
  addressClickCb?: () => void;
  chainIcon?: string;
  chainPosition?: "left" | "right";
  copyable?: boolean;
  addressFrontLength?: number;
  addressBehindLength?: number;
  number?: number;
  style?: React.CSSProperties;
  needShort?: boolean;
  placeholder?: string;
  isBold?: boolean;
};
export default function CopyAbleAddress({
  address,
  addressClickAble = false,
  addressClickCb = () => {},
  chainIcon,
  copyable = true,
  chainPosition = "right",
  addressFrontLength = 9,
  addressBehindLength = 10,
  number,
  style,
  needShort = true,
  placeholder,
  isBold = false,
}: ICopyAbleAddressProps) {
  const _addressClickCb = () => {
    if (addressClickAble) {
      addressClickCb();
    }
  };
  if (!address) {
    return null;
  }
  return (
    <Wrapper
      style={{
        ...style,
      }}
    >
      {chainPosition === "left" && chainIcon && (
        <img
          width={22}
          height={22}
          src={chainIcon}
          className="copy-able-address__chain-icon copy-able-address__chain-icon--left"
          alt="chain-icon"
        />
      )}
      <div
        className={`copy-able-address__address ${
          isBold && "copy-able-address__address-bold"
        } ${addressClickAble ? "copy-able-address__address-click-able" : ""}`}
        onClick={_addressClickCb}
      >
        {addressBehindLength + addressFrontLength < address.length && needShort
          ? shortAddress(address, addressFrontLength, addressBehindLength)
          : address}
      </div>
      {copyable ? (
        <div className="copy-able-address__copy-icon">
          <Copy text={address} />
        </div>
      ) : null}
      {chainPosition === "right" && chainIcon && (
        <img
          width={22}
          height={22}
          src={chainIcon}
          className="copy-able-address__chain-icon"
          alt="chain-icon"
        />
      )}
      {number && <div className="copy-able-address__number">({number})</div>}
    </Wrapper>
  );
}
