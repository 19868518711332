import { CloseButton } from "src/components/button/CloseButton";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { useTranslation } from "react-i18next";
import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useState } from "react";
import { EbBoostHistoryTab } from "./EbBoostHistoryTab";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { LocalLoading } from "src/components/loading/LocalLoading";
import { beautifyISOTimestamp } from "src/utils/helper";
import * as ebTypes from "src/apis/types/energyBoostingTypes";

const Container = styled.div<{ $darkMode?: boolean }>`
  min-height: min(80%, 471px);
  height: 70%;
  width: calc(100% - 40px);
  border-radius: 16px;
  background: ${(props) =>
    props.$darkMode ? "var(--Deep-800, #1A2535)" : "var(--White, #fff)"};
  padding: 24px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px 33px 20px;
  position: relative;
  overflow-y: hidden;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .eb-history-title {
    color: ${(props) =>
      props.$darkMode ? "var(--White, #FFF)" : "var(--Deep-800, #1a2535)"};
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }

  .eb-history-tab {
    margin-top: 12px;
  }

  .eb-history-scrollable {
    margin-top: 24px;
    margin-left: 9px;
    padding-right: 3px;
    width: calc(100% - 9px);
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

const HistoryItem = styled.div<{ $darkMode?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  .line-time {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .line-text {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    .strong {
      color: ${(props) =>
        props.$darkMode ? "var(--White, #FFF)" : "var(--Deep-800, #1a2535)"};
      font-family: "HarmonyOS Sans Bold";
      font-size: 14px;
    }
  }
`;

export interface EbBoostHistoryDisplayItem {
  name: string;
  time: number;
}

const transToDisplayList = (
  boostType: "me" | "others",
  rawList: ebTypes.BoostRecord[]
): EbBoostHistoryDisplayItem[] => {
  return boostType === "me"
    ? rawList.map((item) => {
        return { name: item.boosterName, time: item.boostedAt };
      })
    : rawList.map((item) => {
        return { name: item.boosteeName, time: item.boostedAt };
      });
};

interface Props {
  onClose: (open: boolean) => void;
  boostMeList: ebTypes.BoostRecord[];
  boostMeListLoading?: boolean;
  boostOthersList: ebTypes.BoostRecord[];
  boostOthersListLoading?: boolean;
  darkMode?: boolean;
}

export const EbBoostHistoryPopup: React.FC<Props> = ({
  onClose,
  boostMeList,
  boostMeListLoading = false,
  boostOthersList,
  boostOthersListLoading = false,
  darkMode = false,
}) => {
  const { t } = useTranslation();
  const [boostType, setBoostType] = useState<"me" | "others">("me");
  const list: EbBoostHistoryDisplayItem[] =
    boostType === "me"
      ? transToDisplayList(boostType, boostMeList)
      : transToDisplayList(boostType, boostOthersList);
  const loading: boolean =
    boostType === "me" ? boostMeListLoading : boostOthersListLoading;
  const text: string =
    boostType === "me"
      ? t("boosts your energy progress.")
      : t("has received your energy boost.");

  return (
    <Overlay>
      <Container $darkMode={darkMode}>
        <CloseButton
          className="close-btn"
          $darkMode={darkMode}
          $lazy={darkMode}
          onClick={() => onClose(false)}
        />
        <div className="eb-history-title">{t("Boost History")}</div>
        <EbBoostHistoryTab
          className="eb-history-tab"
          boostType={boostType}
          onTypeChange={setBoostType}
          darkMode={darkMode}
        />
        <Scrollable className="eb-history-scrollable">
          {list.length > 0 ? (
            list.map((item) => (
              <HistoryItem key={item.name + item.time} $darkMode={darkMode}>
                <div className="line-time">
                  {beautifyISOTimestamp(item.time)}
                </div>
                <div className="line-text">
                  <span className="strong">
                    {item.name.replaceAll(".os", "") + ".os"}
                  </span>
                  &nbsp;
                  {text}
                </div>
              </HistoryItem>
            ))
          ) : loading ? (
            <LocalLoading />
          ) : (
            <NoDataV2 text={t("No history found")} />
          )}
        </Scrollable>
      </Container>
    </Overlay>
  );
};
