import normalOrbImg from "src/assets/images/event/eb/energy-orb.png";
import newcomerOrbImg from "src/assets/images/event/eb/energy-orb-newcomer.png";
import { OrbType } from "src/apis/types/energyBoostingTypes";
import newcomerNormalVideo from "src/assets/videos/event/open-newcomer-normal.mp4";
import normalNormalVideo from "src/assets/videos/event/open-normal-normal.mp4";

export const getOrbImg = (orbType?: number): any => {
  switch (orbType) {
    case OrbType.normal:
      return normalOrbImg;
    case OrbType.newcomer:
      return newcomerOrbImg;
    default:
      return normalOrbImg;
  }
};

export const getRewardVideo = (
  orbType?: number,
  rewardRarity?: "normal" | "rare"
): any => {
  switch ({ orbType, rewardRarity }) {
    case { orbType: OrbType.normal, rewardRarity: "normal" }:
      return normalNormalVideo;
    case { orbType: OrbType.newcomer, rewardRarity: "normal" }:
      return newcomerNormalVideo;
    default:
      return normalNormalVideo;
  }
};
