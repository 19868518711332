import styled from "styled-components";
import avatarIcon from "src/assets/icons/settings/avatar.svg";
import upArrowIcon from "src/assets/icons/settings/blue-up-arrow.svg";

const Container = styled.div<{ $clickable: boolean }>`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.__deep_50};
  background: ${(props) => props.theme.colors.__deep_25};

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 20px 20px;

  cursor: ${(props) => props.$clickable && "pointer"};
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      border-color: ${(props) =>
        props.$clickable && props.theme.colors.__kontos_blue};
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .avatar {
      width: 40px;
      height: 40px;
    }

    .text {
      margin-left: 10px;
      //color: ${(props) => props.theme.colors.__kontos_blue};
      font-family: HarmonyOS Sans SC;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

interface Props {
  onClick?: () => void;
  leftText: string;
  leftColor: any;
}

const AccountPanel: React.FC<Props> = ({ onClick, leftText, leftColor }) => {
  return (
    <Container $clickable={!!onClick} onClick={onClick}>
      <div className="left">
        <img className="avatar" src={avatarIcon} alt="" />
        <span className="text" style={{ color: leftColor }}>
          {leftText}
        </span>
      </div>
      <div className="right">
        {!!onClick && <img src={upArrowIcon} alt="" />}
      </div>
    </Container>
  );
};

export default AccountPanel;
