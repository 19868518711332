import styled from "styled-components";
import tipsIcon from "src/assets/icons/tips.svg";
import { ReactNode } from "react";

const Container = styled.div`
  max-width: 320px;
  padding: 16px;
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.1);
  /* border: 1px solid var(--Deep-50, #edeef1); */
  border-radius: 8px;
  background-color: #fff;

  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const TipsIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const Content = styled.div``;

interface IProps {
  children?: ReactNode;
}

export const TipsV2: React.FC<IProps> = ({ children }) => {
  return (
    <Container>
      <TipsIcon src={tipsIcon} />
      <Content>{children}</Content>
    </Container>
  );
};
