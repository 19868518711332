import React, { useState } from "react";
import { useLocation, Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import TradeIcon from "./TradeIcon";
import { observer } from "mobx-react-lite";
import {
  ROUTE_DISCOVER,
  ROUTE_HOME,
  ROUTE_MAIN,
  ROUTE_MARKETS,
  ROUTE_TRADE,
} from "src/router/router-config";
import { NO_NAV_ROUTES } from "src/router/router-map";
import DiscoverIcon from "./DiscoverIcon";
import { useStores } from "src/hooks/useStore";
import { Flag } from "src/configs/featureFlags";
import HomeIconNew from "src/components/navi-bar/HomeIconNew";
import MarketsIcon from "src/components/navi-bar/MarketsIcon";
import AssetsIcon from "src/components/navi-bar/AssetsIcon";
import { t } from "i18next";

const NavigationBarContainer = styled.nav<{ $isOverWidth: boolean }>`
  /* padding: 0 24px; */
  position: ${(props) => (props.$isOverWidth ? "absolute" : "fixed")};
  bottom: 0;
  width: 100%;
  height: var(--navi-height);
  border-top: 1px solid var(--Deep-50, #edeef1);
  //opacity: 0.9;
  //background: var(--White, #fff);
  z-index: 99;
  overflow-y: visible;
`;

const SubContainer = styled.div`
  width: 100%;
  height: 100%;
  background: var(--White, #fff);
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
`;

const Circle = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 68px;
  height: 68px;
  bottom: 2px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--White, #fff);
  z-index: -1;
  border-radius: 99px;
`;

const NavItem = styled(Link)<{ $isCenter?: boolean }>`
  margin-top: ${(props) => (props.$isCenter ? "-3px" : "7px")};
  height: ${(props) => (props.$isCenter ? "56px" : "46px")};
  width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;

  span {
    height: 14px;
    line-height: 14px;
    margin-top: ${(props) => (props.$isCenter ? "6px" : "4px")};
    color: var(--Deep-200, #b2b6bc);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }

  span.active {
    color: var(--Kontos-Blue, #413dec);
  }

  &:hover {
    span {
      color: var(--Kontos-Blue, #413dec);
    }
  }
`;

const navigationBarMap = [
  {
    path: ROUTE_HOME,
    icon: HomeIconNew,
    text: t("Home"),
    loginRequired: false,
    display: true,
  },
  {
    path: ROUTE_MARKETS,
    icon: MarketsIcon,
    text: t("Markets"),
    loginRequired: false,
    display: true,
  },
  {
    path: ROUTE_TRADE,
    icon: TradeIcon,
    text: t("Trade"),
    loginRequired: true,
    display: true,
  },
  {
    path: ROUTE_DISCOVER,
    icon: DiscoverIcon,
    text: t("Discover"),
    loginRequired: true,
    display: Flag.dapp,
  },
  {
    path: ROUTE_MAIN,
    icon: AssetsIcon,
    text: t("Assets"),
    loginRequired: true,
    display: true,
  },
];

const NavigationBar: React.FC = () => {
  const { uiStore, userStore } = useStores();
  const location = useLocation();
  const displayItems = navigationBarMap.filter((item) => item.display === true);
  const isNavNumOdd = displayItems.length % 2 !== 0;
  const centerIndex = isNavNumOdd ? (displayItems.length - 1) / 2 : null;
  const [hoverIndex, setHoverIndex] = useState<number>();
  const [searchParams] = useSearchParams();
  const hideNavigationBar = searchParams.get("hideNavigationBar");

  const isActive = (path: string): boolean => {
    return location.pathname.startsWith(path);
  };

  if (
    NO_NAV_ROUTES.some(
      (element) =>
        location.pathname.toLowerCase()?.includes(element.toLowerCase())
    ) ||
    hideNavigationBar
  ) {
    return <div></div>;
  } else {
    return (
      <NavigationBarContainer $isOverWidth={uiStore.isOverWidth}>
        <SubContainer>
          {displayItems.map((item, index) => (
            <NavItem
              $isCenter={centerIndex !== null && centerIndex === index}
              key={item.text}
              to={
                userStore.pubKeyException && item.loginRequired
                  ? ROUTE_MAIN
                  : item.path === ROUTE_TRADE
                    ? uiStore.tradeRoute
                    : item.path
              }
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(undefined)}
            >
              <item.icon
                isActive={isActive(item.path) || hoverIndex === index}
                disable={item.path === ROUTE_TRADE && userStore.pubKeyException}
              />
              {
                <span className={isActive(item.path) ? "active" : ""}>
                  {t(item?.text)}
                </span>
              }
            </NavItem>
          ))}
        </SubContainer>
        {isNavNumOdd && <Circle />}
      </NavigationBarContainer>
    );
  }
};

export default observer(NavigationBar);
