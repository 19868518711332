import {observer} from "mobx-react";
import styled from "styled-components";
import React, {useRef} from "react";
import kontosAvatarGoogle from "src/assets/images/kontos-avatar-google.svg";
import mainLoading from "src/assets/images/main-loading.svg"
import taskStatusWarn from "src/assets/icons/taskStatusWarn.svg"
import {t} from "i18next";

const Wrapper = styled.div`
 .main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  padding-top: 20px;

  .kontosAvatarGoogle {
   width: 89px;
   height: 86px;
   flex-shrink: 0;
   margin-bottom: 30px;
  }

  .info {
   border-radius: 8px;
   border: 1px solid var(--Warning, #FAAD14);
   background: rgba(250, 173, 20, 0.10);
   height: 62px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--Warning, #FAAD14);
   width: 100%;

   /* Description */
   font-family: HarmonyOS Sans SC;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   margin-bottom: 133px;

   img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 8px;
   }
  }
  .mainLoading{
   width: 64px;
   height: 64px;
   flex-shrink: 0;
    animation: rotate 1s linear infinite;
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg)
      }
    }
  }
 }
`;

const WaitingGoogle: React.FC = () => {


  return (
    <Wrapper>
      <div className={'main'}>
        <img className={'kontosAvatarGoogle'} src={kontosAvatarGoogle} alt="kontosAvatarGoogle"/>
        <div className={'info'}>
          <img src={taskStatusWarn} alt="taskStatusWarn"/>
          {t('Waiting for Google login...')}
        </div>
        <img className={'mainLoading'} src={mainLoading} alt="mainLoading"/>
      </div>
    </Wrapper>
  );
};

export default observer(WaitingGoogle);
