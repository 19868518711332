import Long from "long";
import { FtAsset } from "./type/zkkontos";
import KontosNumber from "./utils/KontosNumber";

export const isDev =
  process.env.NODE_ENV === "development" && process.env.PROD_URL !== "true";

export const EXTENSION_SYMBOL = "Kontos";

export const chainIdMap = {
  POLYGON: "137",
  BSC: "56",
  KONTOS: "0",
  ETH: "1",
};

export const EXTENSION_SETTING: Record<string, boolean> = {
  password_required: true,
};

export const HANDSHAKE_FROM_POPUP_TO_BG = "connect_bg_from_popup";

export const MORALIS_KEY =
  "spuyxedESvV6g7F8RRHfZvUYMeRWcrTbVGUZgvI306q0n14PYi9J3TLrqxPZUdd2";

export const HASURA_KEY = "";

export const DEFAULT_LAYER = 1;

export const LAYER_OPTIONS = [1, 2, 3];

export const NETWORK_OPTIONS = ["testnet", "mainnet"];

export const DEFAULT_NETWORK = NETWORK_OPTIONS[0];

export const LANGUAGE_OPTIONS = ["en", "zh-CN", "zh-TW"];

export const DEFAULT_LANGUAGE = LANGUAGE_OPTIONS[0];

export const WALLET_CONTENT_OPTIONS = ["asset", "activity"];

export const DEFAULT_WALLET_CONTENT = WALLET_CONTENT_OPTIONS[0];

export const ASSET_SORT_OPTIONS = ["token", "chain"];

export const DEFAULT_ASSET_SORT = ASSET_SORT_OPTIONS[0];

export const ACCOUNT_SUFFIX: Record<string, string> = {
  ZECREY: ".zero",
  LEGEND: ".zec",
};

const _STORAGE_KEYS = {
  store_network: "store_network",
  store_layer: "store_layer",
  store_walletContent: "store_walletContent",
  store_assetSort: "store_assetSort",
  store_accounts: "store_accounts",
  store_request_connection: "store_request_connection",
  store_request_unlock: "store_request_unlock",
  store_permission: "store_permission",
  store_contacts: "store_contacts",
  store_metadata_cache: "store_metadata_cache_v2",
  store_pending_evm_tx: "store_pending_evm_tx",
  store_account_balance_list: "store_account_balance_list",
  store_account_requested_balance_list: "store_account_requested_balance_list",
  store_broker_payment_amount_guide_has_show:
    "store_broker_payment_amount_guide_has_show",
  store_broker_valid_balance_list: "store_broker_valid_balance_list",
  store_broker_max_request_list: "store_broker_max_request_list",
  store_broker_requested_supported_data:
    "store_broker_requested_supported_data",
  store_broker_supported_data: "store_broker_supported_data",
  store_task_list: "store_task_list",
  store_requested_task_list: "store_request_task_list",
  store_history_list: "store_history_list",
  store_requested_history_list: "store_requested_history_list",
  store_all_chain_list: "store_all_chain_list",
  store_asset_type_list: "store_asset_type_list",
  store_requested_asset_type_list: "store_requested_asset_type_list",
  store_lang: "store_lang",
} as { [key: string]: string };

Object.keys(_STORAGE_KEYS).forEach((key: string) => {
  _STORAGE_KEYS[key] = _STORAGE_KEYS[key] + "_v2";
});
export const STORAGE_KEYS = _STORAGE_KEYS;

export const EVENT_NAME_MAP: Record<string, string> = {
  accountsChanged: "accountsChanged",
  setDefaultWallet: "setDefaultWallet",
  navigate: "navigate",
  connect: "connect",
  disconnect: "disconnect",
  chainChanged: "chainChanged",
};

export const MAX_DECIMAL = 6;

export const PRIVACY_POLICY =
  "https://docs.google.com/document/d/1d7eqIImTkRTI8oB8akZZxnhP3378-89wPRmbEuGmK_Y/edit?usp=sharing";

export const NATIVE_TOKEN_ADDRESS =
  "0x0000000000000000000000000000000000000001";

export const ALCHEMY_URL = isDev
  ? "https://ramptest.alchemypay.org/"
  : "https://ramp.alchemypay.org/";
export const ALCHEMY_APPID = isDev ? "f83Is2y7L425rxl8" : "f83Is2y7L425rxl8";
export const BINANCE_URL = isDev
  ? "https://www.binance.com/"
  : "https://www.binance.com/";
export const KONTOS_GATE = isDev
  ? "https://bridge.kontos.io"
  : "https://bridge.kontos.io";

export const ALCHEMY_QUOTE_URL = isDev
  ? "https://alchemy-api.kontos.io/v1/price_query"
  : "https://alchemy-api.kontos.io/v1/price_query";
export const KONTOS_BOT_URL = isDev
  ? "https://kontos-bot-backend.kontos.io/v1/"
  : "https://kontos-bot-backend.kontos.io/v1/";
export const BROKER_URL = isDev
  ? "https://api-broker-test.kontos.io/"
  : "https://api-broker.kontos.io/";
export const GRAPHQL_URL = isDev
  ? "https://hasura-test.kontos.io/v1/graphql"
  : "https://hasura.kontos.io/v1/graphql";
export const KONTOS_URL = isDev
  ? "https://node-test.kontos.io:26657"
  : "https://node-temp.kontos.io:26657";
export const MAX_NONCE: Long = Long.fromString("18446744073709551615", true);
export const KONTOS_JSON_RPC = isDev
  ? "https://node-test.kontos.io:8545"
  : "https://node-temp.kontos.io:8545";
export const EXPLORER_URL = isDev
  ? "https://kontos-explorer-web-zecrey.vercel.app/"
  : "https://explorer.kontos.io/";
export const EXPLORER_KONTOS_URL = isDev
  ? "https://api-explorer-test.kontos.io"
  : "https://api-explorer.kontos.io";
export const GOOGLE_CLIENT_ID: string =
  "256188319045-6ce489oganld15jltschkfi2ov2dg7dt.apps.googleusercontent.com";
export const authorizedDomainArr = isDev
  ? [
      "http://localhost:3100",
      "https://leaderboard.kontos.io",
      "https://quest.kontos.io",
      "https://bridge.kontos.io/",
    ]
  : [
      "http://localhost:3100",
      "https://leaderboard.kontos.io",
      "https://quest.kontos.io",
      "https://bridge.kontos.io/",
    ];

// export const ALCHEMY_QUOTE_URL = isDev
//   ? "https://alchemy-api.kontos.io/v1/price_query"
//   : "https://alchemy-api.kontos.io/v1/price_query";
// export const KONTOS_BOT_URL = isDev
//   ? "https://kontos-bot-backend.kontos.io/v1/"
//   : "https://kontos-bot-backend.kontos.io/v1/";
// export const BROKER_URL = isDev
//   ? "https://api-broker.kontos.io/"
//   : "https://api-broker-test.kontos.io/";
// export const GRAPHQL_URL = isDev
//   ? "https://hasura.kontos.io/v1/graphql"
//   : "https://hasura-test.kontos.io/v1/graphql";
// export const KONTOS_URL = isDev
//   ? "https://node-temp.kontos.io:26657"
//   : "https://node-test.kontos.io:26657";
// export const MAX_NONCE: Long = Long.fromString("18446744073709551615", true);
// export const KONTOS_JSON_RPC = isDev
//   ? "https://node-temp.kontos.io:8545"
//   : "https://node-test.kontos.io:8545";
// export const EXPLORER_URL = isDev
//   ? "https://explorer.kontos.io/"
//   : "https://kontos-explorer-web-zecrey.vercel.app/";
// export const EXPLORER_KONTOS_URL = isDev
//   ? "https://api-explorer.kontos.io"
//   : "https://api-explorer-test.kontos.io";

export const CTX_EXPIRE_TIME = 15 * 60 * 1000; // 15 minutes
export const CTX_EXPIRE_TIME_LONG = 60 * 60 * 1000; // 1 hour

export const KEY_STORE_NAME = `__kontos_auth_public__`;

export const LocalStroage = window.localStorage;

export const blackHoleAddress = "0x0000000000000000000000000000000000000000";

export const DISCORD = "https://discord.com/invite/MdajwkVZE4";

export const INIT = "init";
export const CREATE = "create";
export const RECOVERY = "recover";
export const MULTISIG = "multisig";

export const ttlTime = `3`;
export const ttl = `@cached(ttl:${ttlTime})`;

// 56: bsc, 137: polygon
export const dappContractConfig = [
  {
    address: "0xfeacb05b373f1a08e68235ba7fc92636b92ced01",
    chainId: "56",
  },
  {
    address: "0x13f4EA83D0bd40E75C8222255bc855a974568Dd4",
    chainId: "56",
  },
  {
    address: "0xdef171fe48cf0115b1d80b88dc8eab59176fee57",
    chainId: "137",
  },
];

export const MAX_WIDTH = 540;
export const MAX_HEIGHT = 800;

export const DEFAULT_DISPLAY_PRECESION = 8;
export const DEFAULT_DECIMAL = 18;
export const DEFAULT_PRECISION = 6;
export const DEFAULT_ASSET_ICON_OMIT = 4;
export const DEFAULT_GAS_LIMIT_FOR_KONTOS_OTHERS_TRANSFER = 100000;
export const TEMP_KEY =
  "1bcb0ae8c17945acfaf9ea546c0b754a599fddf27425bac7e532590324137a7d";

export const KONTOS_CHAIN_INDEX = "0";
export const KONTOS_NATIVE_ADDRESS =
  "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

export const ZETA_CHAIN_INDEX = "7000";
export const XLAYER_CHAIN_INDEX = "196";

export const localChainSymbolMap: Map<string, string> = new Map([
  ["0", "KONTOS"],
  ["1", "ETH"],
  ["56", "BNB"],
  ["137", "MATIC"],
]);

// Not Enough - Receive Polygon USDT
export const TRADE_DEFAULT_RECEIVE_FTASSET = {
  chainIndex: "137",
  address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
};

// Not Enough - Receive BNB Chain USDT
export const TRADE_DEFAULT_RECEIVE_FTASSET_2 = {
  chainIndex: "56",
  address: "0x55d398326f99059fF775485246999027B3197955",
};

// Not Enough - Bridge Polygon USDT
export const TRADE_DEFAULT_BRIDGE_FTASSET = {
  chainIndex: "137",
  address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
};

// Not Enough - Bridge BNB Chain USDT
export const TRADE_DEFAULT_BRIDGE_FTASSET_2 = {
  chainIndex: "56",
  address: "0x55d398326f99059fF775485246999027B3197955",
};

// Not Enough - OTC Polygon USDT
export const TRADE_DEFAULT_OTC_FTASSET = {
  chainIndex: "137",
  address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
};

// Not Enough - OTC BNB Chain USDT
export const TRADE_DEFAULT_OTC_FTASSET_2 = {
  chainIndex: "56",
  address: "0x55d398326f99059fF775485246999027B3197955",
};

// Polygon USDT
export const DEFAULT_TO_BUY_FTASSET = {
  chainIndex: "137",
  address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
};

// BSC USDT
export const DEFAULT_TO_BUY_FTASSET_2 = {
  chainIndex: "56",
  address: "0x55d398326f99059fF775485246999027B3197955",
};

export const DEFAULT_TO_BUY_FTASSET_STANDARD: FtAsset = {
  balanceId: 0,
  chainIndex: "137",
  chainSymbol: "Polygon",
  chainImageUrl:
    "https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
  chainGreyImageUrl:
    "https://res.cloudinary.com/zecrey/image/upload/v1713945974/collection/file_zb1baw.png",
  chainExplorerLinkURL: "https://polygonscan.com/",
  chainStatus: 1,
  address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  name: "Tether USD",
  symbol: "USDT",
  decimals: 6,
  imageUrl:
    "https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  usdPrice: "",
  reserveIdUsd: "",
  h24Volume: "",
  assetType: 0,
  ftAssetId: 0,
  marketCapUsd: "",
  marketCapRank: 0,
  fdvUsd: "",
  h24VolumeUsd: "",
  h24High: "",
  h24Low: "",
  h24PriceChange: "",
  h24PriceChangePercentage: "",
  h24MarketCapChange: "",
  h24MarketCapChangePercentage: "",
  circulatingSupply: "",
  totalSupply: "",
  maxSupply: "",
  ath: "",
  athChangePercentage: "",
  athDate: "",
  atl: "",
  atlChangePercentage: "",
  atlDate: "",
  balance: "",
  totalUsd: "",
  isUsed: false,
  isGreatLiquidity: 0,
  coinGeckoCoinId: "",
  mappingOriginAssetId: 0,
  categories: [],
  isWhitelist: true,
  securityLevel: 0,
};

export const DEFAULT_TO_BUY_FTASSET_2_STANDARD: FtAsset = {
  balanceId: 0,
  chainIndex: "56",
  chainSymbol: "BNB Chain",
  chainImageUrl:
    "https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png",
  chainGreyImageUrl:
    "https://res.cloudinary.com/zecrey/image/upload/v1713945969/collection/file_bynts0.png",
  chainExplorerLinkURL: "https://bscscan.com/",
  chainStatus: 1,
  address: "0x55d398326f99059fF775485246999027B3197955",
  name: "Tether USD",
  symbol: "USDT",
  decimals: 18,
  imageUrl:
    "https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  usdPrice: "",
  reserveIdUsd: "",
  h24Volume: "",
  assetType: 0,
  ftAssetId: 0,
  marketCapUsd: "",
  marketCapRank: 0,
  fdvUsd: "",
  h24VolumeUsd: "",
  h24High: "",
  h24Low: "",
  h24PriceChange: "",
  h24PriceChangePercentage: "",
  h24MarketCapChange: "",
  h24MarketCapChangePercentage: "",
  circulatingSupply: "",
  totalSupply: "",
  maxSupply: "",
  ath: "",
  athChangePercentage: "",
  athDate: "",
  atl: "",
  atlChangePercentage: "",
  atlDate: "",
  balance: "",
  totalUsd: "",
  isUsed: false,
  isGreatLiquidity: 0,
  coinGeckoCoinId: "",
  mappingOriginAssetId: 0,
  categories: [],
  isWhitelist: true,
  securityLevel: 0,
};

export const TRADE_DEFAULT_DEBOUNCE_TIME = 200;

export const KONTOS_SUPPORT_EMAIL = "zecrey.kontos@gmail.com";

export type EbStageDistrubution = 1 | 2 | 3 | 4;

// Energy boosting configs
export const ENERGY_BAR_DISTRIBUTIONS = {
  1: [0.8899],
  2: [0.3394, 0.8899],
  3: [0.156, 0.5229, 0.8899],
  4: [0.156, 0.4006, 0.6453, 0.8899],
};

export const EB_SIGN_EXPIREDAT = 10 * 60 * 1000; // 15 minutes

export const DEFAULT_TX_HASH = {
  "1": "0x6351cbced9096b7a9dc0554d704ad0ca858c5ad8bbe77ffd0ac0a811277aae54",
  "10": "0x1b5ad8bb9c4e793056f27a310bdb361c03573d4b69365c6a7210bcd14c16b51b",
  "56": "0xff6888323ce24ef4d2407bec40f3e56600951da826c462b53e9bc8aebf80d380",
  "137": "0x0ccaca29e3a9ac0e544ff1e4bceeb7db0dbe9de3a4b5cb9dcf6792c37b086171",
  "250": "0xdfb3fc1cb486b5e550315b12fde08e5c65b694794da1e274b1878301a9ffa528",
  "8453": "0xd67f853dc769a633e1eaacc38d81dec76531b779300f54df20f3b9db215bb982",
  "42161": "0x75da4a9083155385523e37ccb6839c1177796451ba4a4e1fb84482a9c32e9160",
  "43114": "0x8b6ae8938c9a108842efad0d44f9bc0b8e8ec8ebfdf876d526d7752fb6f4357b",
};

export const BASIC_SHEET_INDEX = 9999999;

export const DEFAULT_SLIPPAGE = new KontosNumber(0.02);

export const millisecondsInADay = 24 * 60 * 60 * 1000;
export const millisecondsInAMonth = 30 * millisecondsInADay;

export const AI_QUERY_PARAM = "aiParam";

export const APP_STANDARD_WIDTH = 375;
