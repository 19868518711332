import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { API } from "./core/API";
import { EXPLORER_KONTOS_URL } from "src/config";
import { AssetChange } from "./types";

export interface ReqSignerNativeTxsAssetsChange {
  signer: string;
  offset: number;
  limit: number;
}

export interface RespSignerNativeTxsAssetsChange {
  total: number;
  assetChange: AssetChange[];
}

export const callSignerNativeTxsAssetsChange = async (
  data: ReqSignerNativeTxsAssetsChange,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespSignerNativeTxsAssetsChange> => {
  return request<
    ReqSignerNativeTxsAssetsChange,
    RespSignerNativeTxsAssetsChange
  >(API.kontosChain.signerNativeTxsAssetsChange, data, endpoint);
};
