import { makeAutoObservable } from "mobx";

class ParamStore {
  taskDataParams?: string;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setTaskDataParams = (params: Object) => {
    this.taskDataParams = JSON.stringify(params);
  };

  getTaskDataParams = () => {
    return this.taskDataParams;
  };
}

export const paramStore = new ParamStore();
