import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import userRowBgImg from "src/assets/images/event/eb/user-row-bg.png";
import { useTranslation } from "react-i18next";
import {
  callLeaderBoard,
  EbAccountStatus,
} from "src/apis/energy-boosting-apis";
import { useStores } from "src/hooks/useStore";
import { Leaderboard } from "src/apis/types/energyBoostingTypes";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { EndMessage } from "src/components/no-data/EndMessage";
import firstIcon from "src/assets/icons/events/eb/leaderboard-1st.png";
import secondIcon from "src/assets/icons/events/eb/leaderboard-2nd.png";
import thirdIcon from "src/assets/icons/events/eb/leaderboard-3rd.png";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import defaultAvatarIcon from "src/assets/icons/events/eb/default-avatar.svg";
import Skeleton from "react-loading-skeleton";
import { formatAddress, getDisplayAmount, openUrl } from "src/utils/helper";
import { KontosButton } from "src/components/button/KontosButton";
import dotIcon from "src/assets/icons/events/eb/dot.svg";
import eyeIcon from "src/assets/icons/events/eb/eye.svg";
import loadingGif from "src/assets/icons/events/eb/leaderboard-loading.gif";
import { EBLeaderBoardLinkPopup } from "./EBLeaderBoardLinkPopup";
import { loadingStore } from "src/store/loadingStore";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { ebError, KontosError } from "src/type/error";
import { runInAction } from "mobx";
import { AUTH_TIMEOUT } from "src/store/events/EbStore";
import toast from "src/components/toast/Toast";
import { observer } from "mobx-react";
import { useSetState } from "react-use";
import Joyride, { Step, CallBackProps, STATUS } from "react-joyride";
import { OnboardingType } from "src/store/localKeeper";
import { EbLeaderBoardToolTip } from "./EbLeaderBoardToolTip";
import Floater from "react-floater";
import { TipsEbLeaderBoardRanking } from "src/components/tips/TipsText";

const REFRESH_INTERVAL = 45000;
const LIMIT: number = 30;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderRow = styled.div`
  height: 16px;
  padding: 0 20px 0 18px;
  position: sticky;
  top: 0;

  z-index: 1;

  color: var(--Deep-400, #80868f) !important;
  font-family: "HarmonyOS Sans SC" !important;
  font-size: 14px !important;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const UserRow = styled.div`
  margin: 0 6px;
  width: calc(100% - 12px);
  padding: 0 13px 0 3px;
  position: sticky;
  top: 28px;

  display: flex;
  align-items: center;
  gap: 10px;

  z-index: 1;

  height: 42px;
  /* background-image: url(${userRowBgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center; */
  border-radius: 8px;
  border: 1px solid var(--Deep-600, #4d5662);
  background: var(--Deep-700, #333d4c);
`;

const StyledScrollable = styled(Scrollable)`
  margin-top: 26px;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

const DataRow = styled.div`
  padding: 0 20px 0 10px;
  height: 26px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(1) {
    flex: 0 0 50px;
    font-size: 14px;
  }
  &:nth-child(2) {
    width: 100%;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
  &:nth-child(3) {
    text-align: right;
    justify-content: right;
    font-size: 14px;
  }
`;

const HeaderCell = styled(Cell)`
  &:nth-child(2) {
    margin-left: 11px;
  }
`;

const UserCell = styled(Cell)<{ $normalRank?: boolean }>`
  color: var(--White, #fff);
  text-align: center;
  font-family: "HarmonyOS Sans Bold";

  &:nth-child(1) {
    text-align: center;
    width: 50px;
    height: 26px;
    justify-content: center;
    font-size: ${(props) => (props.$normalRank ? "14px" : "12px")};
  }
  &:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &:nth-child(3) {
  }
`;

const DataCell = styled(Cell)`
  color: var(--White, #fff);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:nth-child(1) {
    text-align: center;
    width: 50px;
    height: 26px;
    justify-content: center;
  }
  &:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &:nth-child(3) {
    text-align: right;
  }
`;

const Avatar = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 99px;
`;

const AccountItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;
`;

const AccountText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GradeIcon = styled.img`
  width: 50px;
  height: 26px;
`;

const InfiniteLoading = styled.img`
  width: 80px;
  text-align: center;
`;

const LinkButton = styled(KontosButton)`
  padding: 0;
  width: 50px;
  height: 24px;
  border-radius: 99px;
  background: var(--Kontos-Blue, #413dec);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const DotIcon = styled.img`
  width: 2px;
  height: 2px;
`;

const EyeIcon = styled.img`
  width: 14px;
  height: 10.5px;
`;

const LoadingGif = styled.img`
  width: 24px;
  height: 8.64px;
`;

const getRank = (
  rank?: number,
  selfUnbound?: boolean,
  isAnySocialAccountLinked?: boolean,
  forceLoading?: boolean,
  onClick?: () => void
): ReactNode => {
  switch (rank) {
    case 1:
      return <GradeIcon src={firstIcon} alt="1st" />;
    case 2:
      return <GradeIcon src={secondIcon} alt="2nd" />;
    case 3:
      return <GradeIcon src={thirdIcon} alt="3rd" />;
    case 0:
      if (forceLoading) return <LoadingGif src={loadingGif} alt="loading.." />;
      if (selfUnbound === true && isAnySocialAccountLinked === true)
        return <LoadingGif src={loadingGif} alt="loading.." />;
      if (selfUnbound === true && isAnySocialAccountLinked === false)
        return (
          <LinkButton onClick={onClick}>
            <DotIcon src={dotIcon} alt="" />
            <EyeIcon src={eyeIcon} alt="link" />
            <DotIcon src={dotIcon} alt="" />
          </LinkButton>
        );
      return "-";
    default:
      if (typeof rank === "number")
        return new Intl.NumberFormat("en-US").format(rank);
      return "-";
  }
};

interface EbLeaderBoardRankOnboardingState {
  run: boolean;
  steps: Step[];
  spotLightRadius: string;
}

interface IProps {
  setCount: (count?: number) => void;
}

const EbLeaderBoardTable: React.FC<IProps> = observer(({ setCount }) => {
  const { t } = useTranslation();
  const { userStore, uiStore, ebStore } = useStores();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [self, setSelf] = useState<Leaderboard>();
  const [unbound, setUnbound] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Leaderboard[]>([]);
  const refreshTimerRef = useRef<number | null>(null);
  const [showLinkPopup, setShowLinkPopup] = useState<boolean>(false);
  const [showRankingTips, setShowRankingTips] = useState<boolean>(false);
  const realDiscordPending: boolean = useMemo(() => {
    const isExist = ebStore.isDiscordSuccess;
    return isExist ? false : ebStore.discordPending;
  }, [ebStore.discordPending, ebStore.isDiscordSuccess]);
  const realTwitterPending: boolean = useMemo(() => {
    const isExist = ebStore.isTwitterSuccess;
    return isExist ? false : ebStore.twitterPending;
  }, [ebStore.isTwitterSuccess, ebStore.twitterPending]);
  const [{ run, steps, spotLightRadius }] =
    useSetState<EbLeaderBoardRankOnboardingState>({
      run: true,
      spotLightRadius: "8px",
      steps: [
        {
          content: (
            <>
              <span>
                {t(
                  "To check the ranking, you need to bind any social account."
                )}
              </span>
            </>
          ),
          placement: "bottom",
          target: ".onboarding-target",
          title: t("Here is your ranking!"),
          disableBeacon: true,
          spotlightPadding: 0,
          offset: 0,
        },
      ],
    });
  const showRankingTipsReal = useMemo(() => {
    return (
      unbound === true &&
      ebStore.isAnySocialAccountLinked &&
      showRankingTips === true
    );
  }, [ebStore.isAnySocialAccountLinked, showRankingTips, unbound]);

  const refreshUserRank = useCallback(async () => {
    if (!userStore.accountName) {
      return;
    }
    try {
      const { account, accountStatus } = await callLeaderBoard({
        accountName: userStore.accountName,
        offset: data.length,
        limit: LIMIT,
      });
      setSelf(account);
      setUnbound(accountStatus === EbAccountStatus.Unbound);
    } catch (e) {
      console.warn("Fetch ftassets failed", e);
    }
  }, [data.length, setUnbound, userStore.accountName]);

  const startRefreshTimer = useCallback(() => {
    if (unbound === false) {
      return;
    }

    if (refreshTimerRef.current !== null) {
      clearInterval(refreshTimerRef.current);
    }

    refreshTimerRef.current = window.setInterval(() => {
      refreshUserRank();
    }, REFRESH_INTERVAL);
  }, [refreshUserRank, unbound]);

  const fetchLeaderBoardData = useCallback(async () => {
    if (!userStore.accountName) {
      return;
    }
    try {
      const { account, entries, total, totalParticipants, accountStatus } =
        await callLeaderBoard({
          accountName: userStore.accountName,
          offset: data.length,
          limit: LIMIT,
        });
      if (!self) setSelf(account);
      setData((prev) => prev.concat(entries));
      setHasMore(data.length + entries.length < total);
      setCount(totalParticipants);
      setUnbound(accountStatus === EbAccountStatus.Unbound);
      startRefreshTimer();
    } catch (e) {
      console.warn("Fetch ftassets failed", e);
    }
  }, [
    data.length,
    self,
    setCount,
    setUnbound,
    startRefreshTimer,
    userStore.accountName,
  ]);

  const handleLinkButtonClick = useCallback(() => {
    setShowLinkPopup(true);
  }, []);

  const noAccountFallback = useCallback(() => {
    throw ebError.noEbAccount;
  }, []);

  const handleSpecificError = useCallback((e: unknown, logMsg: string) => {
    if (e instanceof KontosError) {
      if (e.name === ebError.noCli.name) return;
      if (e.name === ebError.noEbAccount.name) {
        toast({
          type: "warning",
          text: e.message,
        });
        return;
      }
      if (e.name === ebError.noEbInviter.name) {
        toast({
          type: "warning",
          text: e.message,
        });
        return;
      }
    }
    console.log("Error log: " + logMsg, e);
    const errorMessage = e instanceof Error ? e.message : logMsg;
    toast({
      type: "error",
      text: errorMessage,
    });
  }, []);

  const handleAuth = useCallback(
    async (authType: number) => {
      try {
        loadingStore.showLoading();
        let url: string = "";
        switch (authType) {
          case ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD:
            url = await ebStore.authDiscord(noAccountFallback, () => {});
            break;
          case ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER:
            url = await ebStore.authTwitter(noAccountFallback, () => {});
            break;
          default:
            break;
        }
        if (!!url && url !== "") {
          openUrl(url);

          switch (authType) {
            case ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD:
              runInAction(() => {
                ebStore.setDiscordPending(true);
              });
              setTimeout(() => {
                ebStore.setDiscordPending(false);
              }, AUTH_TIMEOUT);
              break;

            case ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER:
              runInAction(() => {
                ebStore.setTwitterPending(true);
              });
              setTimeout(() => {
                ebStore.setTwitterPending(false);
              }, AUTH_TIMEOUT);
              break;
            default:
              break;
          }
        } else {
          toast({
            text: "No Url Provided: " + url,
            type: "error",
          });
        }
      } catch (e) {
        handleSpecificError(e, t("Failed to auth") + " " + authType);
      } finally {
        loadingStore.hideLoading();
      }
    },
    [ebStore, handleSpecificError, noAccountFallback, t]
  );

  useEffect(() => {
    fetchLeaderBoardData();

    return () => {
      if (refreshTimerRef.current !== null) {
        clearInterval(refreshTimerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEbLeaderBoardRankingJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status } = data;

      if (status === STATUS.FINISHED) {
        userStore.markOnboardingFinished(OnboardingType.EbLeaderBoardRanking);
      }

      if (status === STATUS.FINISHED) {
        handleLinkButtonClick();
      }
    },
    [handleLinkButtonClick, userStore]
  );

  return (
    <TableContainer>
      <HeaderRow>
        <HeaderCell>{t("Rank")}</HeaderCell>
        <HeaderCell>{t("Account")}</HeaderCell>
        <HeaderCell>{t("Energy")}</HeaderCell>
      </HeaderRow>

      <UserRow
        className="onboarding-target"
        onMouseEnter={() => {
          setShowRankingTips(true);
        }}
        onMouseLeave={() => setShowRankingTips(false)}
      >
        <UserCell $normalRank={(self?.rank || 0) <= 10000}>
          {self ? (
            getRank(
              self?.rank,
              unbound,
              ebStore.isAnySocialAccountLinked,
              realDiscordPending || realTwitterPending,
              handleLinkButtonClick
            )
          ) : (
            <Skeleton count={1} style={{ width: "20px" }} />
          )}
        </UserCell>
        <UserCell>
          <AccountItem>
            <ImageWithFallback
              src={self?.avatar}
              StyledImg={Avatar}
              fallbackSrc={defaultAvatarIcon}
            />
            <AccountText>
              {self ? (
                formatAddress(self?.name)
              ) : (
                <Skeleton count={1} style={{ width: "70px" }} />
              )}
            </AccountText>
          </AccountItem>
        </UserCell>
        <UserCell>
          {self ? (
            uiStore.isOverWidth ? (
              new Intl.NumberFormat("en-US").format(self?.energyValue)
            ) : (
              getDisplayAmount(self?.energyValue.toString(), {
                isAutomateProcessAllTypes: true,
                decimal: 0,
              })
            )
          ) : (
            <Skeleton count={1} style={{ width: "50px" }} />
          )}
        </UserCell>
      </UserRow>
      <StyledScrollable id="scrollableDiv">
        <InfiniteScroll
          dataLength={data.length}
          next={fetchLeaderBoardData}
          hasMore={hasMore}
          loader={
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <InfiniteLoading src="/static/loading.gif" alt="Loading..." />
            </div>
          }
          endMessage={<EndMessage />}
          scrollableTarget="scrollableDiv"
        >
          {data.map((user, index) => (
            <DataRow key={index}>
              <DataCell>{getRank(user.rank)}</DataCell>
              <DataCell>
                <AccountItem>
                  <ImageWithFallback
                    src={self?.avatar}
                    StyledImg={Avatar}
                    fallbackSrc={defaultAvatarIcon}
                  />

                  <AccountText>{formatAddress(user.name)}</AccountText>
                </AccountItem>
              </DataCell>
              <DataCell>
                {uiStore.isOverWidth
                  ? new Intl.NumberFormat("en-US").format(user.energyValue)
                  : getDisplayAmount(user.energyValue.toString(), {
                      isAutomateProcessAllTypes: true,
                      decimal: 0,
                    })}
              </DataCell>
            </DataRow>
          ))}
        </InfiniteScroll>
      </StyledScrollable>

      {/* Lack Boost Precondition Popup */}
      {showLinkPopup && (
        <EBLeaderBoardLinkPopup
          onAuth={(authType) => {
            setShowLinkPopup(false);
            userStore.unlock(() => handleAuth(authType), true);
          }}
          onClose={setShowLinkPopup}
          discordPending={realDiscordPending}
          twitterPending={realTwitterPending}
        />
      )}

      {userStore.needOnboardingEbLeaderBoardRanking &&
        unbound === true &&
        ebStore.isAnySocialAccountLinked === false &&
        !realDiscordPending &&
        !realTwitterPending && (
          <Joyride
            callback={handleEbLeaderBoardRankingJoyrideCallback}
            disableCloseOnEsc
            disableOverlayClose
            disableScrolling
            disableScrollParentFix
            continuous
            run={run}
            showProgress
            showSkipButton
            steps={steps}
            floaterProps={{
              styles: {
                arrow: {
                  color: "var(--Deep-800, #1A2535)",
                  spread: 20,
                  length: 14,
                  marginLeft: "0",
                },
                wrapper: {
                  overflowY: "auto",
                },
              },
            }}
            styles={{
              options: {
                zIndex: 2000000,
              },
              spotlight: {
                borderRadius: spotLightRadius,
              },
              overlay: {
                backgroundColor: "rgba(0, 13, 31, 0.30)",
              },
              tooltip: {
                left: 130,
              },
            }}
            tooltipComponent={EbLeaderBoardToolTip}
          />
        )}

      {showRankingTipsReal && (
        <Floater
          open={showRankingTipsReal}
          eventDelay={0}
          component={TipsEbLeaderBoardRanking}
          styles={{
            options: {
              zIndex: 20,
            },
            arrow: {
              color: "var(--Deep-700, #333D4C)",
              length: 5,
              spread: 11,
            },
          }}
          offset={5}
          placement={"top"}
          target={".onboarding-target"}
        />
      )}
    </TableContainer>
  );
});

export default EbLeaderBoardTable;
