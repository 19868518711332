export const LANG = {
  en: {
    name: "en",
    displayName: "English",
  },
  zh_cn: {
    name: "zh-CN",
    displayName: "简体中文",
  },
  zh_tw: {
    name: "zh-TW",
    displayName: "繁體中文",
  },
  ru: {
    name: "ru",
    displayName: "Русский",
  },
  ja: {
    name: "ja",
    displayName: "日本語",
  },
  kr: {
    name: "kr",
    displayName: "한국어",
  },
};

export function getLangDisplayName(name: string) {
  const lang = Object.values(LANG).find((lang) => lang.name === name);
  return lang ? lang.displayName : null;
}
