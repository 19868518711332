import styled from "styled-components";
import { AiScorePanel } from "./AiScorePanel";
import Divider from "src/components/divider/Divider";
import { AiScoreMarkdown } from "./AiScoreMarkdown";

const Container = styled.div`
  flex: 1;
  padding: 6px 24px;
  overflow-y: auto;
`;

interface IProps {
  aiData?: string;
  aiScore?: number;
  requesting?: boolean;
}

export const AiScoreTab: React.FC<IProps> = ({
  aiData,
  aiScore,
  requesting,
}) => {
  return (
    <Container>
      <AiScorePanel score={aiScore} requesting={requesting} />

      <Divider isDashed top={16} bottom={16} />

      <AiScoreMarkdown markdown={aiData} requesting={requesting} />
    </Container>
  );
};
