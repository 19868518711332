import {
  KontosKey,
  KontosKeyHelper,
  StorageKontosKey,
} from "@zkkontos/kontos-sdk";
import { KEYS } from "./keys";
import { set, get, remove } from "./local";
import { FtAsset } from "src/type/zkkontos";
import { isSameKontosName, isSameName } from "src/utils/zkkontosHelper";
import { FtAssetType } from "@zkkontos/kontos-sdk/src/api/types";
import {
  DappHistoryItem,
  DappHistoryItemMetaData,
  DappHistoryItemVerifyContext,
  LocalDappHistory,
} from "src/type/dapp";
import { RecordOverviewDisplayProps } from "src/components/task-activity/TaskOrActivityOverviewItem";
import { millisecondsInAMonth } from "src/config";
import { getNoSuffixKontosName } from "src/utils/helper";

export const getStorageKontosValList = (): StorageKontosKey[] => {
  const data = get(KEYS.store_kontos_key) || [];
  return data;
};

export enum OnboardingType {
  Home = "home",
  Eb = "eb",
  Assets = "assets",
  Payment = "payment",
  AiScore = "aiScore",
  EbLeaderBoardRanking = "ebLeaderBoardRanking",
  Sell = "sell",
}

export interface OnboardingItem {
  type: OnboardingType;
  finished: boolean;
}

export type LocalRecordType = "task" | "activity";

export interface LocalRecord {
  accountName: string;
  record: RecordOverviewDisplayProps;
  expiredAt: number;
  type: LocalRecordType;
}

class LocalKeeper {
  formatKontosKeys = () => {
    const storedKeys = get(KEYS.store_kontos_key);
    const keys: StorageKontosKey[] = Array.isArray(storedKeys)
      ? storedKeys
      : [];
    keys.forEach(
      (key) => (key.accountName = getNoSuffixKontosName(key.accountName))
    );
    set(KEYS.store_kontos_key, keys);
  };

  constructor() {
    this.formatKontosKeys();
  }

  updateKontosKey = async (key: KontosKey) => {
    key.accountName = getNoSuffixKontosName(key.accountName);
    const storageKey = await KontosKeyHelper.toStorageKontosKey(key);
    const storeKontosVal = getStorageKontosValList();
    const marchIndex = storeKontosVal.findIndex(
      (item: StorageKontosKey) => item.accountName === storageKey.accountName
    );
    if (marchIndex !== -1) {
      storeKontosVal[marchIndex] = storageKey;
    } else {
      storeKontosVal.push(storageKey);
      set(KEYS.store_account_selected_index, storeKontosVal.length - 1);
    }
    set(KEYS.store_kontos_key, storeKontosVal);
  };

  updateEmailAccountsRecovering = (arr: string[]) => {
    return set(KEYS.store_accounts_recovering_email, arr);
  };

  getEmailAccountsRecovering = (): string[] => {
    return get(KEYS.store_accounts_recovering_email);
  };

  updateAccountsRecovering = (arr: string[]) => {
    return set(KEYS.store_accounts_recovering, arr);
  };

  getAccountsRecovering = (): string[] => {
    return get(KEYS.store_accounts_recovering);
  };

  resetAccount = () => {
    Object.values(KEYS).map((item) => {
      return remove(item);
    });
  };

  getStorageKontosKeyWithCheck = (): StorageKontosKey | undefined => {
    let res: StorageKontosKey | undefined = undefined;

    const data = get(KEYS.store_kontos_key) as StorageKontosKey[];
    if (!data || data.length === 0) {
      return undefined;
    }

    let store_account_selected_index =
      typeof get(KEYS.store_account_selected_index) === "number"
        ? get(KEYS.store_account_selected_index)
        : 0;

    if (store_account_selected_index > data.length - 1) {
      set(KEYS.store_account_selected_index, 0);
      store_account_selected_index = 0;
    }
    if (store_account_selected_index < 0) {
      set(KEYS.store_account_selected_index, 0);
      store_account_selected_index = 0;
    }

    const selectedKontosKey = data[
      store_account_selected_index
    ] as StorageKontosKey;

    res = selectedKontosKey;

    const accountsRecovering = this.getAccountsRecovering();

    const recovering = accountsRecovering?.find((item) =>
      isSameKontosName(item, selectedKontosKey.accountName)
    );

    if (recovering) {
      const canBeUsedKontosKeys = data.filter((item) => {
        let flag = true;
        accountsRecovering?.forEach((subItem) => {
          if (isSameKontosName(subItem, item.accountName)) {
            flag = false;
          }
        });
        return flag;
      });
      if (canBeUsedKontosKeys?.length > 0) {
        set(KEYS.store_account_selected_index, 0);
        res = canBeUsedKontosKeys[0];
      }
    }

    return res;
  };

  getStorageKontosKey = (
    accountName?: string
  ): StorageKontosKey | undefined => {
    try {
      const data = Array.isArray(get(KEYS.store_kontos_key))
        ? (get(KEYS.store_kontos_key) as StorageKontosKey[])
        : [];

      if (data.length === 0) {
        return undefined;
      }

      if (typeof accountName === "string") {
        return data.find((item) =>
          isSameKontosName(item.accountName, accountName)
        );
      }

      let store_account_selected_index =
        get(KEYS.store_account_selected_index) || 0;

      store_account_selected_index = Math.min(
        store_account_selected_index,
        data.length - 1
      );

      return data[store_account_selected_index] as StorageKontosKey;
    } catch {
      return undefined;
    }
  };

  getAllStorageKontosKey = (): StorageKontosKey[] => {
    const data = get(KEYS.store_kontos_key) || [];
    return data;
  };

  getIndex = (): number | undefined => {
    return (get(KEYS.store_account_selected_index) as number) || undefined;
  };

  switchAccount = (index: number) => {
    set(KEYS.store_account_selected_index, index);
  };

  removeAccount = (name: string) => {
    const arr = (get(KEYS.store_kontos_key) as StorageKontosKey[]) || [];
    const currentIndex =
      (get(KEYS.store_account_selected_index) as number) || 0;
    if (arr) {
      const index = arr.findIndex((item) =>
        isSameKontosName(item.accountName, name)
      );
      const filteredArr = arr.filter(
        (item) => !isSameKontosName(item.accountName, name)
      );
      set(KEYS.store_kontos_key, filteredArr);
      if (currentIndex > index && currentIndex > 0 && index !== -1) {
        set(KEYS.store_account_selected_index, currentIndex - 1);
      } else if (currentIndex === index) {
        set(KEYS.store_account_selected_index, 0);
      }
    }
  };

  removeRecoveringAccount = (name: string) => {
    const arr = (get(KEYS.store_accounts_recovering) as string[]) || [];
    const newArr = arr.filter((item) => !isSameKontosName(item, name));
    set(KEYS.store_accounts_recovering, newArr);
  };

  updateUnlockAmount = (amount: number) => {
    return set(KEYS.store_unlock_amount, amount);
  };

  getUnlockAmount = (): number => {
    return (get(KEYS.store_unlock_amount) || 0) as number;
  };

  updateFtAssets = (assets: FtAsset[]) => {
    const data = {
      timestamp: new Date().getTime(),
      assets: assets,
    };
    return set(KEYS.store_ft_assets, data);
  };

  getFtAssets = (): {
    ftAssets: FtAsset[];
    timestamp: number;
  } => {
    const data = get(KEYS.store_ft_assets);
    if (data) {
      return {
        ftAssets: data.assets as FtAsset[],
        timestamp: data.timestamp as number,
      };
    }
    return {
      ftAssets: [],
      timestamp: 1670384199826,
    };
  };

  clearFtAssets = () => {
    return remove(KEYS.store_ft_assets);
  };

  updateAccountTotalUSDBalance = async (data: string | undefined) => {
    set(KEYS.store_account_total_USD_balance, data);
  };

  getAccountTotalUSDBalance = (): string | undefined => {
    const data = get(KEYS.store_account_total_USD_balance);
    if (!data) {
      return undefined;
    }
    return data as string;
  };

  updateAggregatedAssetList = async (data: FtAssetType[]) => {
    set(KEYS.store_aggregated_asset_list, data);
  };

  getLang = (): string => {
    return get(KEYS.store_lang) as string;
  };

  updateLang = (lang: string) => {
    set(KEYS.store_lang, lang);
  };

  getDappHistory = (account: string): DappHistoryItem[] => {
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    const matchedAccount = Object.keys(dappHistory).find((storageAccount) =>
      isSameKontosName(storageAccount, account)
    );
    return matchedAccount ? dappHistory[matchedAccount].history : [];
  };

  addDappHistory = (
    account: string,
    metadata: DappHistoryItemMetaData,
    verifyContext: DappHistoryItemVerifyContext
  ) => {
    const newHistoryItem: DappHistoryItem = {
      metadata,
      verifyContext,
      timestamp: Date.now(),
    };
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    if (!(account in dappHistory)) {
      dappHistory[account] = { history: [newHistoryItem] };
    } else {
      const existedItem = dappHistory[account].history.find(
        (item) => item.metadata.name === newHistoryItem.metadata.name
      );
      if (!existedItem) {
        dappHistory[account].history.push(newHistoryItem);
      }
    }
    set(KEYS.store_dapp_history, dappHistory);
  };

  removeDappHistory = (account: string, timestamp: number) => {
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    if (account in dappHistory) {
      const filteredHistory = dappHistory[account].history.filter(
        (item) => item.timestamp !== timestamp
      );
      dappHistory[account].history = filteredHistory;
      set(KEYS.store_dapp_history, dappHistory);
    }
  };

  clearDappHistory = (account: string) => {
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    if (account in dappHistory) {
      dappHistory[account].history = [];
      set(KEYS.store_dapp_history, dappHistory);
    }
  };

  setWalletConnectUser = (account: string) => {
    set(KEYS.store_wc_user, account);
  };

  getWalletConnectUser = (): string => {
    return get(KEYS.store_wc_user) || "";
  };

  startOnboarding = () => {
    const existedItems = this.getOnboardingStatus();
    const onboardings: OnboardingItem[] = Object.values(OnboardingType).map(
      (value) => {
        return { type: value, finished: false };
      }
    );

    const newItems = onboardings.filter(
      (item) =>
        !existedItems.some((existedItem) => existedItem.type === item.type)
    );

    const updatedItems = [...existedItems, ...newItems];
    set(KEYS.store_onboarding, updatedItems);
  };

  markOnboardingFinished = (type: OnboardingType) => {
    const arr: OnboardingItem[] = get(KEYS.store_onboarding) || [];
    if (arr.length === 0) {
      return;
    }
    const matchedOne = arr.find((item) => item.type === type);
    if (matchedOne) {
      matchedOne.finished = true;
    }
    set(KEYS.store_onboarding, arr);
  };

  getOnboardingStatus = (): OnboardingItem[] => {
    return get(KEYS.store_onboarding) || [];
  };

  // Local tasks
  checkLocalRecords = () => {
    const records = this.getLocalRecords();
    const filtered = records.filter((record) => record.expiredAt > Date.now());
    set(KEYS.store_tasks_and_txs, filtered);
  };

  removeLocalRecord = (ids: string[]) => {
    const records = this.getLocalRecords();
    const filtered = records.filter(
      (record) => !ids.includes(record.record.id)
    );
    set(KEYS.store_tasks_and_txs, filtered);
    return filtered;
  };

  saveRecord = (
    accountName: string,
    record: RecordOverviewDisplayProps,
    type: LocalRecordType
  ) => {
    const records = this.getLocalRecords();
    const saveObj: LocalRecord = {
      accountName,
      record,
      expiredAt: Date.now() + millisecondsInAMonth,
      type,
    };
    const existed = records.find(
      (record) => record.record.id === saveObj.record.id
    );
    if (!existed) {
      records.push(saveObj);
    }
    set(KEYS.store_tasks_and_txs, records);
  };

  getLocalRecords = (
    accountName?: string,
    type?: LocalRecordType
  ): LocalRecord[] => {
    const records = (get(KEYS.store_tasks_and_txs) || []) as LocalRecord[];
    return records.filter(
      (item) =>
        (!accountName || isSameName(item.accountName, accountName)) &&
        (!type || item.type === type)
    );
  };

  getDisableAiScore = () => {
    const disable = get(KEYS.store_disable_ai_score);
    return disable as boolean;
  };

  setDisableAiScore = (disable: boolean) => {
    set(KEYS.store_disable_ai_score, disable);
  };
}

const localKeeper = new LocalKeeper();
export default localKeeper;
