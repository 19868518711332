import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { scrollbar } from "../global-styles";
import BG from "./BG";

const LayoutWrap = styled.div`
  height: 100vh;
  overflow-y: auto;
  background: #f2f2f2;
  ${scrollbar};
`;

const LayoutForWebPage = (props: { children: ReactNode }) => {
  return (
    <LayoutWrap className="web-page-layout">
      <BG></BG>
      {props.children}
    </LayoutWrap>
  );
};

export default LayoutForWebPage;
