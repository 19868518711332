import { AnimatedLayout } from "src/components/tab/AnimatedLayout";
import styled from "styled-components";
import { observer } from "mobx-react";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStores } from "src/hooks/useStore";
import { ChainSelectPopup } from "src/components/chain-select/ChainSelectPopup";
import { ChainConfig, FtAsset } from "@/type/zkkontos";
import { Timer } from "src/components/timer/Timer";
import { calculateBySlippage, shortAddress, sleep } from "src/utils/helper";
import {
  BlankFilledArea,
  BottomPlaceholder,
  CallTaskDataPurpose,
  DarkBtnText,
  LightBtnText,
} from "../spot/TradeSpot";
import { isSameFtAsset, parseApiErrorMessage } from "src/utils/zkkontosHelper";
import toast from "src/components/toast/Toast";
import { runInAction } from "mobx";
import { debounce } from "lodash";
import { SwapPanel } from "./SwapPanel";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "../asset-select/ToBuyAssetSelector";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { useTranslation } from "react-i18next";
import { SlippageSetup } from "../common/SlippageSetup";
import {
  BreakDownAssetIcon,
  BreakDownLineWrapper,
  DarkBreakDownText,
  LightBreakDownText,
  TradeBreakdownEditableItem,
} from "../common/TradeBreakdownEditableItem";
import {
  DEFAULT_DECIMAL,
  DEFAULT_SLIPPAGE,
  KONTOS_CHAIN_INDEX,
} from "src/config";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import { TradeBreakdownViewV2 } from "../common/TradeBreakdownViewV2";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import FloatingButton from "../FloatingButton";
import darkWhiteLoadingIcon from "src/assets/icons/dark-white-loading.svg";
import { loadingStore } from "src/store/loadingStore";
import { ReceiverSelectModal } from "../common/ReceiverSelectModal";
import KontosNumber from "src/utils/KontosNumber";
import { ContractInteraction } from "src/pages/contract-interaction/ContractInteraction";
import { ContractInteractionType } from "src/pages/contract-interaction/types";
import { ethers } from "ethers";
import { AvailableHelper } from "./AvailableHelper";
import { TradeAiPanel } from "../common/TradeAiPanel";

const DEBOUNCE_TIME = 500;
const REFRESH_TIME = 15000;

const Container = styled.div`
  padding: 5px 16px 16px 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FirstLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiddleArea = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const TimerWrapper = styled.div`
  margin: 1px;
`;

const PriceParityBox = styled.div`
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-align: right;
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const FloatingButtonWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(16px + var(--navi-height));
  width: 95%;
  z-index: 101;
`;

export const TradeSwap: React.FC = observer(() => {
  const { t } = useTranslation();
  const { swapStore, chainStore, userStore, spotStore, uiStore, tradeStore } =
    useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [timerKey, setTimerKey] = useState<number>(0);
  const [innerModalOpen, setInnerModalOpen] = useState<boolean>(false);
  const [showChainSelector, setShowChainSelector] = useState<boolean>(false);
  const [showSwapAssetSelect, setShowSwapAssetSelect] =
    useState<boolean>(false);
  const [showReceiveAssetSelect, setShowReceiveAssetSelect] =
    useState<boolean>(false);
  const [showSlippage, setShowSlippage] = useState<boolean>(false);
  const [showReceiver, setShowReceiver] = useState<boolean>(false);
  const [showContractInteraction, setShowContractInteraction] = useState(false);
  // Input Loading
  const [inputLoadingCount, setInputLoadingCount] = useState(0);
  const addInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount - 1);
  }, []);
  // Result Loading
  const [resultLoadingCount, setResultLoadingCount] = useState(0);
  const addResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount - 1);
  }, []);
  const [showAiScore, setShowAiScore] = useState<boolean>(true);

  const resetFakeTimer = useCallback(() => {
    setTimerKey((prev) => prev + 1);
  }, []);

  const priceParity: ReactNode = useMemo(() => {
    if (!swapStore.toSwapFtAsset || !swapStore.toReceiveFtAsset) {
      return "";
    }
    return (
      <>
        {"1 " +
          swapStore.toSwapFtAsset.symbol +
          " ≈ " +
          new KontosNumber(swapStore.toSwapFtAsset.usdPrice, DEFAULT_DECIMAL)
            .divide(swapStore.toReceiveFtAsset.usdPrice, DEFAULT_DECIMAL)
            .toFormat() +
          " " +
          swapStore.toReceiveFtAsset.symbol}
      </>
    );
  }, [swapStore.toReceiveFtAsset, swapStore.toSwapFtAsset]);

  const onInnerModalChange = useCallback((isOpen: boolean) => {
    setInnerModalOpen(isOpen);
  }, []);

  const closeModals = useCallback(() => {
    setShowChainSelector(false);
    setShowSwapAssetSelect(false);
    setShowReceiveAssetSelect(false);
    setShowSlippage(false);
    setShowReceiver(false);
    setShowContractInteraction(false);
  }, []);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: !innerModalOpen,
  });

  const handleInteractionFail = useCallback((e: any) => {
    setShowContractInteraction(false);
  }, []);

  const handleInteractionCancel = useCallback(() => {
    setShowContractInteraction(false);
  }, []);

  const handleInteractionSuccess = useCallback(async () => {
    setShowContractInteraction(false);
    swapStore.reset();
    spotStore.resetAfterRegisteringTask();
    await sleep(1000);
    // await userStore.fetchAndUpdateAccountBalances();
  }, [spotStore, swapStore]);

  const handleChainSelect = useCallback(
    (chain: ChainConfig) => {
      if (chain.chainIndex === swapStore.chain?.chainIndex) {
        return;
      }
      swapStore.resetTaskData();
      const targetChainAssets = userStore.userHoldings.filter(
        (item) => item.chainIndex === chain.chainIndex
      );
      swapStore.setChain(chain);
      swapStore.setToReceiveFtAsset(undefined);
      swapStore.setToSwapFtAsset(targetChainAssets?.[0] || undefined);
      setShowChainSelector(false);
    },
    [swapStore, userStore.userHoldings]
  );

  const handleToSwapAssetSelect = useCallback(
    (asset: FtAsset) => {
      if (asset.chainIndex === KONTOS_CHAIN_INDEX) {
        toast({
          text: t("Currently, swap on Kontos is not supported"),
          type: "warning",
        });
        return;
      }
      if (isSameFtAsset(swapStore.toReceiveFtAsset, asset)) {
        toast({
          text: t("You cannot swap identical assets."),
          type: "warning",
        });
        return;
      }
      setShowSwapAssetSelect(false);
      if (isSameFtAsset(asset, swapStore.toSwapFtAsset)) {
        return;
      }

      if (asset.chainIndex !== swapStore.chain?.chainIndex) {
        const newChain = chainStore.chains.find(
          (chain) => chain.chainIndex === asset.chainIndex
        );
        if (newChain) {
          swapStore.setChain(newChain);
          swapStore.setToSwapFtAsset(asset);
          swapStore.setToSwapFtAssetQuantity(undefined);
          swapStore.setToReceiveFtAsset(undefined);
        } else {
          swapStore.reset();
        }
      } else {
        swapStore.setToSwapFtAsset(asset);
      }
    },
    [chainStore.chains, swapStore, t]
  );

  const handleToReceiveAssetSelect = useCallback(
    (asset: FtAsset) => {
      if (asset.chainIndex === KONTOS_CHAIN_INDEX) {
        toast({
          text: t("Currently, swap on Kontos is not supported"),
          type: "warning",
        });
        return;
      }
      if (isSameFtAsset(asset, swapStore.toSwapFtAsset)) {
        toast({
          text: t("You cannot swap identical assets."),
          type: "warning",
        });
        return;
      }
      setShowReceiveAssetSelect(false);
      if (isSameFtAsset(asset, swapStore.toReceiveFtAsset)) {
        return;
      }

      if (asset.chainIndex !== swapStore.chain?.chainIndex) {
        const newChain = chainStore.chains.find(
          (chain) => chain.chainIndex === asset.chainIndex
        );
        if (newChain) {
          swapStore.setChain(newChain);
          swapStore.setToSwapFtAsset(undefined);
          swapStore.setToSwapFtAssetQuantity(undefined);
          swapStore.setToReceiveFtAsset(asset);
        } else {
          swapStore.reset();
        }
      } else {
        swapStore.setToReceiveFtAsset(asset);
      }
    },
    [chainStore.chains, swapStore, t]
  );

  const startTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          break;
        default:
          break;
      }
    },
    [addInputLoadingCount, addResultLoadingCount]
  );

  const endTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          minusInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          minusResultLoadingCount();
          break;
        default:
          break;
      }
    },
    [minusInputLoadingCount, minusResultLoadingCount]
  );

  const callFetchAndSetTaskData = useCallback(
    async (purpose: CallTaskDataPurpose) => {
      try {
        startTaskDataLoading(purpose);
        const resp = await swapStore.fetchAndSetTaskData();
        if (resp?.isChainLiquidityInsufficient === true) {
          toast({
            type: "error",
            text: t("Chain liquidity insufficient, please try other chains"),
          });
          return;
        }
        return resp;
      } catch (e) {
        console.log("Failed to fetch taskdata", e);
        const errorMessage =
          e instanceof Error
            ? parseApiErrorMessage(e).message
            : "An unknown error occurred when calculating task data";
        if (errorMessage.toLocaleLowerCase() !== "canceled") {
          toast({ type: "error", text: errorMessage });
          runInAction(() => {
            swapStore.resetTaskData();
          });
        }
      } finally {
        endTaskDataLoading(purpose);
        resetFakeTimer();
      }
    },
    [startTaskDataLoading, swapStore, t, endTaskDataLoading, resetFakeTimer]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchTaskData = useCallback(
    debounce((purpose: CallTaskDataPurpose) => {
      callFetchAndSetTaskData(purpose);
    }, DEBOUNCE_TIME),
    []
  );

  const prepareTaskDataLoading = useCallback(
    (
      purpose: CallTaskDataPurpose,
      debounceTime: number = DEBOUNCE_TIME + 50
    ) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          setTimeout(() => {
            minusInputLoadingCount();
          }, debounceTime);
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          setTimeout(() => {
            minusResultLoadingCount();
          }, debounceTime);
          break;
        default:
          break;
      }
    },
    [
      addInputLoadingCount,
      addResultLoadingCount,
      minusInputLoadingCount,
      minusResultLoadingCount,
    ]
  );

  const prepareAndDebouncedFetchTaskData = useCallback(
    (purpose: CallTaskDataPurpose) => {
      prepareTaskDataLoading(purpose);
      debouncedFetchTaskData(purpose);
    },
    [debouncedFetchTaskData, prepareTaskDataLoading]
  );

  // input useEffect
  useEffect(() => {
    if (
      swapStore.toSwapFtAsset &&
      swapStore.toReceiveFtAsset &&
      !swapStore.toSwapFtAssetQuantity
    ) {
      prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Input);
    }
  }, [
    prepareAndDebouncedFetchTaskData,
    swapStore.toReceiveFtAsset,
    swapStore.toSwapFtAsset,
    swapStore.toSwapFtAssetQuantity,
    tradeStore.payableChangeCount,
  ]);

  // result useEffect
  useEffect(() => {
    const cronjob = () => {
      if (
        swapStore.receiver &&
        swapStore.slippage &&
        swapStore.toSwapFtAsset &&
        swapStore.toReceiveFtAsset &&
        swapStore.toSwapFtAssetQuantity?.gt(0)
      ) {
        prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Result);
        return true;
      } else {
        return false;
      }
    };

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (!showContractInteraction) {
      const res = cronjob();
      if (res) {
        timerRef.current = setInterval(() => {
          const flag = cronjob();
          if (!flag && timerRef.current) {
            clearInterval(timerRef.current);
          }
        }, REFRESH_TIME);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [
    prepareAndDebouncedFetchTaskData,
    showContractInteraction,
    swapStore.toReceiveFtAsset,
    swapStore.toSwapFtAsset,
    swapStore.toSwapFtAssetQuantity,
    swapStore.slippage,
    swapStore.receiver,
    tradeStore.payableChangeCount,
  ]);

  const handleConfirm = useCallback(async () => {
    if (swapStore.insufficient) {
      return;
    }

    let taskData = swapStore.taskData;

    if (!taskData || !taskData.newTasksByPaymentPlans) {
      loadingStore.showLoading();
      taskData = await callFetchAndSetTaskData(CallTaskDataPurpose.Result);
      loadingStore.hideLoading();
      if (!taskData) return;
    }

    if (taskData?.isChainUnderSyncing) {
      toast({
        text: t(
          "Your previous transaction is still syncing on the blockchain. Please try again later."
        ),
        type: "warning",
      });
      return;
    }

    setShowContractInteraction(true);
    return;
  }, [callFetchAndSetTaskData, swapStore.insufficient, swapStore.taskData, t]);

  // init hook
  useEffect(() => {
    swapStore.checkChain();
    swapStore.startTrackingChains();

    return () => {
      swapStore.stopTrackingChains();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breakdownList: {
    label: string;
    value: ReactNode;
  }[] = useMemo(() => {
    const slippage = (
      <TradeBreakdownEditableItem
        text={
          swapStore.slippage.eq(DEFAULT_SLIPPAGE)
            ? "Auto (" + swapStore.slippage.multiply(100) + "%)"
            : swapStore.slippage.multiply(100) + "%"
        }
        onClick={() => setShowSlippage(true)}
      />
    );
    const receivingAddress = (
      <TradeBreakdownEditableItem
        prefixIcon={avatarIcon}
        text={
          swapStore.receiver
            ? ethers.utils.isAddress(swapStore.receiver)
              ? shortAddress(swapStore.receiver)
              : swapStore.receiver.replaceAll(".os", "") + ".os"
            : "-"
        }
        onClick={() => setShowReceiver(true)}
      />
    );
    const fee = (
      <LightBreakDownText>
        {/* fee */}
        {resultLoadingCount > 0 ? (
          <EllipsisPlaceholder />
        ) : (
          swapStore?.fee?.toFormat() || "-"
        )}
        {/* symbol */}
        {" USD"}
      </LightBreakDownText>
    );
    // const orderPrice = (
    //   <DarkBreakDownText>
    //     {/* fee */}
    //     {resultLoadingCount > 0 ? (
    //       <>
    //         <EllipsisPlaceholder />{" "}
    //       </>
    //     ) : swapStore?.orderPrice?.gt(0) && swapStore.insufficient ? (
    //       "≈" + swapStore?.orderPrice?.toFormatV2({ zeroPlaceholder: "-" }) ||
    //       "-"
    //     ) : (
    //       swapStore?.orderPrice?.toFormatV2({ zeroPlaceholder: "-" }) || "-"
    //     )}
    //     {" USD"}
    //   </DarkBreakDownText>
    // );
    const estimatedExecutionTime = (
      <LightBreakDownText>{t("<10s")}</LightBreakDownText>
    );
    return [
      {
        label: t("Slippage") + ": ",
        value: slippage,
      },
      {
        label: t("Minimum received") + ": ",
        value: (
          <BreakDownLineWrapper>
            <ImageWithFallback
              src={swapStore.toReceiveFtAsset?.imageUrl || defaultTokenIcon}
              fallbackSrc={defaultTokenIcon}
              StyledImg={BreakDownAssetIcon}
            />
            <DarkBreakDownText style={{ marginLeft: "4px" }}>
              {/* quantity */}
              {resultLoadingCount > 0 ? (
                <EllipsisPlaceholder />
              ) : swapStore.toReceiveFtAssetQuantity ? (
                calculateBySlippage(
                  swapStore.toReceiveFtAssetQuantity,
                  swapStore.slippage
                ).toFormat()
              ) : (
                "-"
              )}
              {/* symbol */}
              {swapStore.toReceiveFtAsset
                ? " " + swapStore.toReceiveFtAsset?.symbol
                : ""}
            </DarkBreakDownText>
          </BreakDownLineWrapper>
        ),
      },
      {
        label: t("Receiving address") + ": ",
        value: receivingAddress,
      },
      {
        label: t("Fee") + ": ",
        value: fee,
      },
      {
        label: t("Estimated execution time") + ": ",
        value: estimatedExecutionTime,
      },
    ];
  }, [
    resultLoadingCount,
    swapStore?.fee,
    swapStore.receiver,
    swapStore.slippage,
    swapStore.toReceiveFtAsset,
    swapStore.toReceiveFtAssetQuantity,
    t,
  ]);

  const mainBtnContext: {
    children?: ReactNode;
    disabled?: boolean;
    icon?: any;
    loading?: boolean;
  } = useMemo(() => {
    if (!swapStore.toSwapFtAssetQuantity) {
      return {
        children: <DarkBtnText>{t("Input Quantity to Continue")}</DarkBtnText>,
        disabled: true,
        icon: null,
        loading: false,
      };
    }
    if (resultLoadingCount > 0) {
      return {
        children: <DarkBtnText>{t("Calculating...")}</DarkBtnText>,
        disabled: true,
        icon: darkWhiteLoadingIcon,
        loading: true,
      };
    }
    if (swapStore.insufficient) {
      return {
        children: <DarkBtnText>{t("Insufficient quantity")}</DarkBtnText>,
        disabled: true,
        icon: null,
        loading: false,
      };
    }
    if (swapStore.mayFail) {
      return {
        children: (
          <LightBtnText>{t("Task may fail by low slippage")}</LightBtnText>
        ),
        disabled: false,
        icon: null,
        loading: false,
      };
    }
    if (
      swapStore.taskData &&
      swapStore.taskData.isChainLiquidityInsufficient === true
    ) {
      return {
        children: <DarkBtnText>{t("Liquidity Insufficient")}</DarkBtnText>,
        disabled: true,
        icon: darkWhiteLoadingIcon,
        loading: false,
      };
    }
    return {
      children: <LightBtnText>{t("Confirm")}</LightBtnText>,
      disabled: false,
      icon: null,
      loading: false,
    };
  }, [
    resultLoadingCount,
    swapStore.insufficient,
    swapStore.mayFail,
    swapStore.taskData,
    swapStore.toSwapFtAssetQuantity,
    t,
  ]);

  useEffect(() => {
    uiStore.setTradeRouteSwap();
  }, [uiStore]);

  // useEffect(() => {
  //   // If the user did not provide a usdAmount, the confirm sheet should not open automatically
  //   if (
  //     !swapStore.toSwapFtAssetQuantity ||
  //     swapStore.toSwapFtAssetQuantity.eq(0)
  //   ) {
  //     tradeStore.setFromAi(false);
  //   }
  //   if (!mainBtnContext.disabled && tradeStore.fromAi) {
  //     tradeStore.setFromAi(false);
  //     handleConfirm();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mainBtnContext, swapStore.toSwapFtAssetQuantity]);

  return (
    <AnimatedLayout>
      <Container ref={wrapperRef}>
        <FirstLine>
          <AvailableHelper inputLoading={inputLoadingCount > 0} />
        </FirstLine>

        <TradeAiPanel
          style={{
            marginTop: "10px",
            boxShadow: "6px 6px 10px 0px rgba(0, 13, 31, 0.03)",
          }}
          assetSymbol={swapStore.toReceiveFtAsset?.symbol}
          assetImageUrl={swapStore.toReceiveFtAsset?.imageUrl}
          assetChainIndex={swapStore.toReceiveFtAsset?.chainIndex}
          assetAddress={swapStore.toReceiveFtAsset?.address}
          showAi={!tradeStore.disableAiScore && showAiScore}
          onClose={() => {
            setShowAiScore(false);
          }}
        >
          <SwapPanel
            enabelAiBox={!tradeStore.disableAiScore && showAiScore}
            resultLoading={resultLoadingCount > 0}
            onFromClick={() => {
              setShowSwapAssetSelect(true);
            }}
            onToClick={() => {
              setShowReceiveAssetSelect(true);
            }}
          />
        </TradeAiPanel>

        {/* Right - Price Calculator & Timer */}
        <MiddleArea>
          <PriceParityBox>{priceParity}</PriceParityBox>

          <div>
            {!showChainSelector &&
              !showContractInteraction &&
              !showContractInteraction &&
              !showSwapAssetSelect &&
              inputLoadingCount === 0 &&
              swapStore.toSwapFtAsset &&
              swapStore.toReceiveFtAsset &&
              swapStore.toSwapFtAssetQuantity && (
                <TimerWrapper>
                  <Timer
                    key={timerKey}
                    seconds={REFRESH_TIME / 1000}
                    loading={resultLoadingCount > 0}
                  />
                </TimerWrapper>
              )}
          </div>
        </MiddleArea>

        {/* Only if from, to and value is set, show breakdown */}
        {breakdownList.length > 0 &&
          swapStore.toSwapFtAsset &&
          swapStore.toReceiveFtAsset &&
          swapStore.toSwapFtAssetQuantity && (
            <div style={{ width: "100%" }}>
              <TradeBreakdownViewV2 list={breakdownList} />
            </div>
          )}

        <BottomPlaceholder>
          <div style={{ height: "calc(80px + var(--navi-height))" }}></div>
        </BottomPlaceholder>

        {/* Blank filled area */}
        <BlankFilledArea />

        <FloatingButtonWrapper>
          <FloatingButton
            onClick={handleConfirm}
            icon={mainBtnContext.icon}
            loading={mainBtnContext.loading}
            disabled={mainBtnContext.disabled}
          >
            {mainBtnContext.children}
          </FloatingButton>
        </FloatingButtonWrapper>

        {showChainSelector && (
          <ChainSelectPopup
            chains={chainStore.allowSwapChains}
            onSelect={handleChainSelect}
            onClose={setShowChainSelector}
          />
        )}

        <BottomSheet
          isOpen={showSwapAssetSelect}
          onClose={() => setShowSwapAssetSelect(false)}
          mountPoint={domNode}
        >
          {swapStore.chain && (
            <ToBuyAssetSelector
              onChoose={handleToSwapAssetSelect}
              chains={chainStore.allowSwapChains}
              hasRecommend={false}
              hasAll={false}
              hasFavorites={true}
              hasMyAssets={true}
              showAssetType={ShowAssetType.Balance}
              initAssetType={chainStore.defaultOptForSwap}
              onChainChange={chainStore.setDefaultOptForSwap}
              sortByBalance
              title={t("Choose asset to swap out")}
            />
          )}
        </BottomSheet>

        <BottomSheet
          isOpen={showReceiveAssetSelect}
          onClose={() => setShowReceiveAssetSelect(false)}
          mountPoint={domNode}
        >
          {swapStore.chain && (
            <ToBuyAssetSelector
              onChoose={handleToReceiveAssetSelect}
              chains={[swapStore.chain]}
              hasRecommend={false}
              hasAll={false}
              hasFavorites={true}
              showAssetType={ShowAssetType.Detail}
              initAssetType={swapStore.chain.chainIndex}
              title={t("Choose asset to receive")}
            />
          )}
        </BottomSheet>

        <BottomSheet
          isOpen={showSlippage}
          mountPoint={domNode}
          customHeight={330}
          disableScrollLocking
          onClose={() => setShowSlippage(false)}
        >
          <SlippageSetup
            initSlippage={swapStore.slippage}
            symbol={swapStore?.toReceiveFtAsset?.symbol}
            rawAmount={swapStore?.toReceiveFtAssetQuantity}
            onSubmit={(slippage) => {
              swapStore.setSlippage(slippage);
              setShowSlippage(false);
            }}
          />
        </BottomSheet>

        {/* Receiving Address Sheet */}
        {swapStore.receiver && (
          <BottomSheet
            isOpen={showReceiver}
            onClose={() => setShowReceiver(false)}
            mountPoint={domNode}
            disableScrollLocking
          >
            <ReceiverSelectModal
              rawAddress={swapStore.receiver}
              onConfirm={(address) => {
                swapStore.setReceiver(address);
                setShowReceiver(false);
              }}
              onCancel={() => setShowReceiver(false)}
            />
          </BottomSheet>
        )}

        {/* Contract Interaction Sheet*/}
        <BottomSheet
          isOpen={showContractInteraction}
          onClose={() => {
            setShowContractInteraction(false);
          }}
          mountPoint={domNode}
        >
          {swapStore.taskData && (
            <ContractInteraction
              interactionType={ContractInteractionType.Swap}
              target={"Kontos Trade · Swap"}
              onInnerModalChange={onInnerModalChange}
              onCancel={handleInteractionCancel}
              onSuccess={handleInteractionSuccess}
              onFail={handleInteractionFail}
              onInnerIndexChange={swapStore.setSelectedTaskDataIndex}
              selectedTaskDataIndex={swapStore.selectedTaskDataIndex}
              wallet={userStore.accountName!}
              receiver={swapStore.receiver}
              swapProps={{
                taskData: swapStore.taskData,
                toSwapFtAsset: swapStore.toSwapFtAsset!,
                toSwapFtAssetQuantity: swapStore.toSwapFtAssetQuantity!,
                toSwapFtAssetValue: swapStore.toSwapFtAssetValue!,
                toReceiveFtAsset: swapStore.toReceiveFtAsset!,
                toReceiveFtAssetQuantity: swapStore.toReceiveFtAssetQuantity!,
                toReceiveFtAssetValue: swapStore.toReceiveFtAssetValue!,
                slippage: swapStore.slippage,
                mayFail: swapStore.mayFail,
              }}
            />
          )}
        </BottomSheet>
      </Container>
    </AnimatedLayout>
  );
});
