import styled from "styled-components";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { DiscoverTrendingList } from "./DiscoverTrendingList";
import { AnimatedLayout } from "src/components/tab/AnimatedLayout";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 60px;
`;

export const DiscoverTrendingTab: React.FC = observer(() => {
  const { discoverStore } = useStores();

  return (
    <AnimatedLayout>
      <Container>
        <DiscoverTrendingList
          dapps={discoverStore.dappTrendings}
          hasMore={discoverStore.hasMoreDappTrendings}
          onNext={discoverStore.fetchAndSetDappTrendings}
        />
      </Container>
    </AnimatedLayout>
  );
});
