import { ReactNode } from "react";
import styled from "styled-components";
import starDefaultSvg from "src/assets/icons/star/star-default.svg";
import starHoverSvg from "src/assets/icons/star/star-hover.svg";
import starActiveSvg from "src/assets/icons/star/star-active.svg";

const Container = styled.div`
  .default{
    display: block;
  }
  .hover{
    display: none;
  }
  &:hover{
    .default{
      display: none;
    }
    .hover{
      display: block;
    }
  }
`;


interface Props {
  starStatus: "default" | "active";
  styles?: React.CSSProperties;
}

const Star: React.FC<Props> = ({ starStatus, styles}) => {
  return (
    <Container style={{
      width: '16px',
      height: '16px',
      ...styles
    }}>
      {
        starStatus === "default" && <>
          <img className={'default'} src={starDefaultSvg} alt="star"/>
          <img className={'hover'} src={starHoverSvg} alt="star"/>
        </>
      }
      {
        starStatus === "active" && <img className={'active'} src={starActiveSvg} alt="star" />
      }
    </Container>
  );
};

export default Star;
