import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useMemo, useState } from "react";
import kontosAvatarGoogle from "src/assets/images/kontos-avatar-google.svg";
import kontosAvatar from "src/assets/images/kontos-avatar-normal.svg";
import InputWithIcons from "src/components/input/KontosInputV2";
import HorizontalLine from "src/components/line/HorizontalLine";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import PoweredBy from "src/components/common/powerdBy";
import RegisterStatusIcon, {
  inputStatusMap,
} from "src/components/start/RegisterStatusIcon";
import Tips from "src/components/tips/Tips";
import { TipsTextAddNormal } from "src/components/tips/TipsText";
import { useStores } from "src/hooks/useStore";
import { checkIsAccountExistence } from "src/pages/newAuth/RecoverAccount";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > div {
      width: 100%;
    }

    .description {
      color: var(--Deep-800, #1a2535);
      text-align: center;

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */

      span {
        color: var(--Kontos-Blue, #413dec);
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
    }

    .kontosAvatarGoogle {
      width: 89px;
      height: 86px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    .kontosAvatar {
      width: 86px;
      height: 86px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .buttonWrap {
    width: calc(100% - 40px);
    position: fixed;
    bottom: 16px;
  }
`;

const InputErrorText = styled.div`
  color: var(--error-notice, #ff1e2b);
  text-align: left;
  /* Description */
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  padding-left: 6px;
`;

const RightIconStyle = styled.span`
  padding-right: 10px;
`;

type IProps = {
  submitFunction: () => void;
  kontosName: string;
  setKontosName: (name: string) => void;
  userEmailArr: string[];
};

const RightIcon = () => {
  return <RightIconStyle>.OS</RightIconStyle>;
};

const AddNormal: React.FC<IProps> = ({
  submitFunction,
  kontosName,
  setKontosName,
  userEmailArr,
}) => {
  const [inputStatus, setInputStatus] =
    useState<keyof typeof inputStatusMap>("blur");
  const [showTips, setShowTips] = useState(true);
  const { userStore } = useStores();
  const [inputErrorDesc, setInputErrorDesc] = useState<string>("");

  const isExit = checkIsAccountExistence(kontosName, [], userStore.storageKeys);

  return (
    <Wrapper>
      <div className={"main"}>
        {userEmailArr.length > 0 ? (
          <img
            className={"kontosAvatarGoogle"}
            src={kontosAvatarGoogle}
            alt="kontosAvatarGoogle"
          />
        ) : (
          <img
            className={"kontosAvatar"}
            src={kontosAvatar}
            alt="kontosAvatar"
          />
        )}

        <div className={"inputWrap"}>
          <Tips
            wrapperStyle={{ width: "100%" }}
            contentStyle={{
              left: "0px",
              top: "58px",
              padding: "0px 8px",
              width: "100%",
            }}
            showTips={showTips}
            tipsContent={<TipsTextAddNormal />}
            triggerElement={
              <>
                <InputWithIcons
                  leftIcon={<RegisterStatusIcon status={inputStatus} />}
                  rightIcon={<RightIcon />}
                  placeholder={t("Your account username")}
                  value={kontosName}
                  onChange={(e) => {
                    setKontosName(e.target.value?.toLowerCase());
                  }}
                  setInputStatus={setInputStatus}
                  onFocused={() => setShowTips(false)}
                  isCheckExist={false}
                  inputStatus={inputStatus}
                  setInputErrorDesc={setInputErrorDesc}
                />
                {inputStatus === "error" &&
                  (isExit ? (
                    <InputErrorText>
                      ・{t("This account has been created in your wallet")}
                    </InputErrorText>
                  ) : (
                    inputErrorDesc !== "" && (
                      <InputErrorText>{inputErrorDesc}</InputErrorText>
                    )
                  ))}
              </>
            }
          />
          <div className={"lineWrap"}>
            <HorizontalLine />
          </div>
          <div className={"description"}>
            {t(
              "We recommend that you use a more readable name as the kontos account name."
            )}
          </div>
        </div>
      </div>
      <div className={"buttonWrap"}>
        <Button
          disabled={inputStatus !== "success"}
          onClick={submitFunction}
          text={t("Next")}
          height={"56px"}
        />
        <PoweredBy />
      </div>
    </Wrapper>
  );
};

export default observer(AddNormal);
