import { ReactNode, useCallback, useRef } from "react";
import { observer } from "mobx-react";
import {
  FullPageDisplayWrapper,
  ParitalDisplayWrapper,
  ParitalDisplayBox,
} from "src/components/wrapper/ReactiveWrapper";
import ToastContainer from "../toast/ToastContainer";
import NavigationBar from "../navi-bar/NavigationBar";
import { useStores } from "src/hooks/useStore";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { GlobalSheet } from "src/sheets/GlobalSheet";
import { NoAutoCloseSheetViews } from "src/store/SheetStore";

type ILayout = {
  children: ReactNode;
  toastId?: string;
};

const Layout = observer(({ children, toastId }: ILayout) => {
  const { uiStore, sheetStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;

  const handleMouseDownOutside = useCallback(() => {
    if (sheetStore.latestSheet) {
      if (!NoAutoCloseSheetViews.includes(sheetStore.latestSheet?.view)) {
        sheetStore.closeLatestSheet();
      }
    }
  }, [sheetStore]);

  useMouseDownOutside({
    ref: wrapperRef,
    globalCallback: handleMouseDownOutside,
    shouldClose: true,
  });

  return (
    <div>
      {uiStore.isOverWidth ? (
        <ParitalDisplayWrapper>
          <ParitalDisplayBox ref={wrapperRef}>
            {children}
            <NavigationBar />
            <GlobalSheet mountPoint={domNode} />
          </ParitalDisplayBox>
          {toastId && <ToastContainer id={toastId} />}
        </ParitalDisplayWrapper>
      ) : (
        <FullPageDisplayWrapper ref={wrapperRef}>
          {children}
          <NavigationBar />
          <GlobalSheet mountPoint={domNode} />
        </FullPageDisplayWrapper>
      )}
    </div>
  );
});

export default Layout;

export const NoNavLayout = ({ children, toastId }: ILayout) => {
  const { uiStore } = useStores();

  return (
    <div>
      {uiStore.isOverWidth ? (
        <ParitalDisplayWrapper>
          <ParitalDisplayBox style={{}}>{children}</ParitalDisplayBox>
          {toastId && <ToastContainer id={toastId} />}
        </ParitalDisplayWrapper>
      ) : (
        <FullPageDisplayWrapper style={{}}>{children}</FullPageDisplayWrapper>
      )}
    </div>
  );
};
