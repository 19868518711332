import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Navigation = styled.nav`
  width: 100%;
  user-select: none;
`;

const TabList = styled.ul<{ gap?: number }>`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "16px")};
`;

const TabItem = styled.li`
  text-decoration: none;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  color: var(--Deep-200, #b2b6bc);
  font-family: "HarmonyOS Sans Bold";
  font-size: 16px;
  line-height: 20px;

  &.active {
    color: var(--Deep-800, #1a2535);
    /* @media (hover: hover) {
      &:hover {
        color: var(--Deep-800, #1a2535);
      }
    } */
  }

  @media (hover: hover) {
    &:hover {
      color: var(--Deep-800, #1a2535);
    }
  }
`;

export interface Tab {
  route: string;
  label: string;
}

interface IProps {
  className?: string;
  style?: CSSProperties;
  tabs: Tab[];
  fontStyle?: CSSProperties;
  gap?: number;
}

export const KontosTab: React.FC<IProps> = ({
  className,
  style,
  tabs,
  fontStyle,
  gap,
}) => {
  const { t } = useTranslation();

  return (
    <Navigation className={className} style={style}>
      <TabList gap={gap}>
        {tabs.map((item) => (
          <TabItem key={item.route}>
            <StyledLink style={fontStyle} to={item.route}>
              {t(item.label)}
            </StyledLink>
          </TabItem>
        ))}
      </TabList>
    </Navigation>
  );
};
