import styled, { keyframes } from "styled-components";

const Container = styled.span`
  span {
    opacity: 0;
    animation: blink 1.4s infinite both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

const EllipsisPlaceholder: React.FC<IProps> = ({ className, style }) => {
  return (
    <Container className={className} style={style}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </Container>
  );
};

export default EllipsisPlaceholder;

const ContainerV2 = styled.span`
  font-size: 26px;
  height: 16px;
  line-height: 16px;

  span {
    opacity: 0;
    animation: blink 1.4s infinite both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

export const EllipsisPlaceholderV2: React.FC<IProps> = ({
  className,
  style,
}) => {
  return (
    <ContainerV2 className={className} style={style}>
      <span>·</span>
      <span>·</span>
      <span>·</span>
    </ContainerV2>
  );
};

const ContainerV3 = styled.span`
  font-size: 20px;
  height: 14px;
  line-height: 14px;
`;

const blinkV3 = keyframes`
  0% {
    color: var(--White, #fff);
  }
  25% {
    color: #a7a7a7;
  }
  50% {
    color: #7d7d7d;
  }
  75% {
    color: #a7a7a7;
  }
  100% {
    color: var(--White, #fff);
  }
`;

const LineV3 = styled.span`
  span {
    animation: ${blinkV3} 1.4s infinite both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

interface IPropsV3 {
  className?: string;
  style?: React.CSSProperties;
  count?: number;
}

export const EllipsisPlaceholderV3: React.FC<IPropsV3> = ({
  className,
  style,
  count = 2,
}) => {
  return (
    <ContainerV3 className={className} style={style}>
      {Array.from({ length: count }).map((_, index) => (
        <LineV3 key={index}>
          <span>· </span>
          <span>· </span>
          <span>· </span>
        </LineV3>
      ))}
    </ContainerV3>
  );
};
