import ImageWithFallback from "../images/ImageWithFallback";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";

interface IProps {
  type: "chain" | "token";
  src?: any;
  size: number;
  style?: React.CSSProperties;
  className?: string;
}

export const TokenOrChainIcon: React.FC<IProps> = ({
  type,
  src,
  size,
  style,
  className,
}) => {
  const defaultIcon = type === "chain" ? defaultChainIcon : defaultTokenIcon;

  return (
    <ImageWithFallback
      className={className}
      src={src || defaultIcon}
      fallbackSrc={defaultIcon}
      style={{
        ...style,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: "99px",
      }}
    />
  );
};
