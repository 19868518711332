import { useTranslation } from "react-i18next";
import styled from "styled-components";
import powerByBrandImage from "src/assets/images/brands/0x-scope.svg";

const Container = styled.div`
  border-top: 1px solid var(--Deep-50, #edeef1);
  background: #fff;
  width: 100%;
  padding: 21px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const PowerByText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const PowerByImg = styled.img`
  width: 72.797px;
  height: 18px;
`;

export const AiPowerBy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <PowerByText>{t("Powered by")}</PowerByText>
      <PowerByImg src={powerByBrandImage} />
    </Container>
  );
};
