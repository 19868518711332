import { t } from "i18next";
import { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div``;

const ItemLine = styled.div.attrs(() => ({}))`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.__deep_400};

  &:not(:first-child) {
    margin-top: 16px;
  }

  > span:first-child {
    flex-grow: 1;
  }
`;

export const AvatarIcon = styled.img`
  margin-right: 6px;
  width: 16px;
  height: 16px;
`;

export const StatusIcon = styled.img`
  margin-right: 6px;
  width: 16px;
  height: 16px;
`;

export const CommonText = styled.span`
  color: ${({ theme }) => theme.colors.__deep_400};
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
`;

export const WarningText = styled.span`
  color: ${({ theme }) => theme.colors.__warning};
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
`;

export const SuccessText = styled.span`
  color: ${({ theme }) => theme.colors.__success};
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
`;

interface Props {
  receiver: ReactNode;
  totalPayment: ReactNode;
  myAvailable: ReactNode;
  status: ReactNode;
}

const PaymentKeyValueList: React.FC<Props> = ({
  receiver,
  totalPayment,
  myAvailable,
  status,
}) => {
  return (
    <Container>
      {receiver && (
        <ItemLine>
          <span>{t("Receiver")}:</span>
          {receiver}
        </ItemLine>
      )}
      {totalPayment && (
        <ItemLine>
          <span>{t("Total payment")}:</span>
          {totalPayment}
        </ItemLine>
      )}
      {myAvailable && (
        <ItemLine>
          <span>{t("My available")}:</span>
          {myAvailable}
        </ItemLine>
      )}
      {status && (
        <ItemLine>
          <span>{t("Status")}:</span>
          {status}
        </ItemLine>
      )}
    </Container>
  );
};

export default PaymentKeyValueList;
