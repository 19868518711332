import React, { useMemo, useState } from "react";
import styled from "styled-components";
import arrowIco from "../../assets/icons/arrow1.svg";

const FilterSectionStyle = styled.div`
  max-width: 176px;
  height: 40px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  .main {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--Deep-50, #edeef1);
    background: var(--Deep-25, #f5f5f6);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    > .ico {
      width: 16px;
      height: auto;
      flex-shrink: 0;
      margin-right: 10px;
    }
    > .arrow {
      stroke-width: 2px;
      stroke: var(--Deep-400, #80868f);
    }
    > span {
      color: var(--Deep-800, #1a2535);
      margin-right: 11px;
      user-select: none;

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
    }
  }
  .open {
    border-radius: 20px 20px 0 0;
  }
  .selectedList {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    padding-top: 60px;
    border-radius: 20px;
    border: 1px solid var(--Deep-50, #edeef1);
    background: var(--Deep-25, #f5f5f6);
    z-index: 1;
    box-sizing: border-box;
    filter: drop-shadow(6px 6px 10px rgba(0, 13, 31, 0.1));
    backdrop-filter: blur(5px);

    .item {
      color: var(--Warning, #faad14);

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 18px;
      box-sizing: border-box;
      margin-bottom: 27px;
      img {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin-right: 8px;
      }
    }
  }
`;

export type filterItemType = {
  ico: string;
  text: string;
  color: string;
};

interface KontosSearchProps {
  items: filterItemType[];
  selectedVal: string;
  setSelectedVal: (pram: string) => void;
}

const FilterSection: React.FC<KontosSearchProps> = ({
  items,
  selectedVal,
  setSelectedVal,
}) => {
  const [open, setOpen] = useState(false);
  const selectedItem = useMemo(() => {
    return items.find((item) => item.text === selectedVal);
  }, [items, selectedVal]);

  return (
    <FilterSectionStyle>
      <div className={`main ${open && "open"}`} onClick={() => setOpen(!open)}>
        <img className={"ico"} src={selectedItem?.ico} alt="" />
        <span>{selectedItem?.text}</span>
        <img className={"arrow"} src={arrowIco} alt="" />
      </div>
      {open && (
        <div className={"selectedList"}>
          {items.map(
            (item: filterItemType, index) =>
              item.text !== selectedVal && (
                <div
                  key={index.toString()}
                  className={`item ${selectedVal === item.text && "active"}`}
                  onClick={() => {
                    setOpen(!open);
                    setSelectedVal(item.text);
                  }}
                  style={{ color: item.color }}
                >
                  <img src={item.ico} alt="" />
                  {item.text}
                </div>
              )
          )}
        </div>
      )}
    </FilterSectionStyle>
  );
};

export default FilterSection;
