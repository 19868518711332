import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { DeepButton } from "src/components/button/DeepButton";
import starIco from "src/assets/icons/events/eb/star.svg";
import { BASIC_SHEET_INDEX } from "src/config";

const Container = styled.div`
  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 17px 20px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }

  > .content {
    margin-top: 12px;
    color: var(--Deep-800, rgb(26, 37, 53));
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    text-align: center;
    line-height: 130%;

    > span {
      margin-right: 4px;
      color: var(--Warning, #faad14);
      font-family: "HarmonyOS Sans Bold";
      font-size: 16px;
    }

    > img {
      margin-left: 4px;
      margin-right: 4px;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      position: relative;
      bottom: 1px;
    }
  }

  > .ok-btn {
    margin-top: 26px;
  }
`;

interface Props {
  onClose: (open: boolean) => void;
}

export const EbLackKeyActionPopup: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const title = t("Claim Failed!");

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <div className="title">{title}</div>
        <span className="content">
          <Trans i18nKey={"trans-eb-complete-key-action"}>
            To begin claiming your reward, ensure you have completed the
            <img src={starIco} alt="" />
            <span>Task</span>
            located below the progress bar.
          </Trans>
        </span>
        <DeepButton className="ok-btn" onClick={() => onClose(false)}>
          {t("OK")}
        </DeepButton>
      </Container>
    </Overlay>
  );
};
