import React from "react";
import Slider from "src/components/sliders/Slider";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 15px;
`;

const GRANULARITY = 0.01;

interface SpotAmountSliderProps {
  value: number;
  disabled: boolean;
  onChange?: (percent: number) => void;
}

const SpotAmountSlider: React.FC<SpotAmountSliderProps> = ({
  value,
  disabled,
  onChange,
}) => {
  const handleSliderPercentageChange = (sliderPercentage: number) => {
    if (onChange) {
      onChange(sliderPercentage);
    }
  };

  return (
    <Container>
      <Slider
        controlledPercentage={value}
        onChange={handleSliderPercentageChange}
        granularity={GRANULARITY}
        disabled={disabled}
      />
    </Container>
  );
};

export default SpotAmountSlider;
