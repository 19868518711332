import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import BackIcon from "src/assets/icons/back-btn.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import defaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import AllChainIcon from "src/assets/images/all-chain-icon.png";
import Star from "src/components/star/Star";
import DottedLine from "src/components/line/DottedLine";
import { createChart, ColorType, UTCTimestamp } from "lightweight-charts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStores } from "src/hooks/useStore";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import {
  cutAddress,
  fmDate,
  getDisplayAmount,
  getFluctuate,
  isFavoriteFtAssetId,
} from "src/utils/helper";
import {
  AssetsDetailQuote,
  ChartFilterOption,
  getFtAssetsDetailQuote,
  getFtAssetsRiskInfo,
  getOHLC,
  IFtAssetRiskData,
  OHLCData,
} from "src/apis/markets-apis";
import { loadingStore } from "src/store/loadingStore";
import Copy from "src/components/copy/Copy";
import { ROUTE_SEND, ROUTE_TRADE } from "src/router/router-config";
import { MouseEventParams } from "lightweight-charts";
import homeIco1 from "src/assets/icons/homeIco1.svg";
import toast from "src/components/toast/Toast";
import SelectLight, { OptionType } from "src/components/selects/SelectLight";
import TruncatedText from "src/components/TruncatedText";
import NoDataChart from "src/components/no-data/NoDataChart";
import SkeletonChart from "src/components/skeleton/SkeletonChart";
import { SpotMode } from "src/store/trade/SpotStore";
import warnIco from "src/assets/icons/dapp/wc-warning.svg";
import errorIco from "src/assets/icons/error1.svg";
import RiskIcon from "src/components/risk/RiskIcon";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import SkeletonRiskList from "src/components/skeleton/SkeletonRiskList";
import { AssetSecurityIcon } from "src/pages/trade/asset-select/AssetSecurityIcon";
import PoweredGoPlus from "src/components/powered/PoweredGoPlus";
import HorizontalLine from "src/components/line/HorizontalLine";
import { callFtassetDetail } from "src/apis/asset-apis";
import { AiScoreTab } from "./ai-score/AiScoreTab";
import { AiPowerBy } from "./ai-score/AiPowerBy";

const chartDateFormatter = (time: number) => {
  const date = new Date(time * 1000).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return date;
};

const matchPixiuKey = "Trading Security";

const isOHLCDataBullish = (data: OHLCData): boolean => {
  const closeData = new KontosNumber(data.close, DEFAULT_DECIMAL);
  const openData = new KontosNumber(data.open, DEFAULT_DECIMAL);
  return closeData.gt(openData);
};

const getOHLCDataOpenPosition = (data: OHLCData): number => {
  const openData = new KontosNumber(data.open, DEFAULT_DECIMAL);
  const highData = new KontosNumber(data.height, DEFAULT_DECIMAL);
  const lowData = new KontosNumber(data.low, DEFAULT_DECIMAL);
  return openData
    .minus(lowData)
    .divide(highData.minus(lowData) || 1)
    .multiply(100)
    .toNumber();
};

const getOHLCDataClosePosition = (data: OHLCData): number => {
  const closeData = new KontosNumber(data.close, DEFAULT_DECIMAL);
  const highData = new KontosNumber(data.height, DEFAULT_DECIMAL);
  const lowData = new KontosNumber(data.low, DEFAULT_DECIMAL);
  return closeData
    .minus(lowData)
    .divide(highData.minus(lowData) || 1)
    .multiply(100)
    .toNumber();
};

const getOHLCPopupPosition = (
  data: OHLCData | undefined
): { x: number; w: number } => {
  if (!data) return { x: 0, w: 0 };
  const openPosition = getOHLCDataOpenPosition(data);
  const closePosition = getOHLCDataClosePosition(data);
  const x = openPosition < closePosition ? openPosition : closePosition;
  const w = Math.abs(openPosition - closePosition);
  return { x, w };
};

const getBarWidth = (current: string, min: string, max: string): number => {
  const currentPrice = new KontosNumber(current || "0", DEFAULT_DECIMAL);
  const maxPrice = new KontosNumber(max || "0", DEFAULT_DECIMAL);
  const minPrice = new KontosNumber(min || "0", DEFAULT_DECIMAL);
  const maxDiff = maxPrice.minus(minPrice);
  const currentDiff = currentPrice.minus(minPrice);
  return currentDiff
    .divide(maxDiff.toNumber() === 0 ? 1 : maxDiff)
    .multiply(100)
    .toNumber();
};

/*
 * type: 1: minutes, 2: hour, 0: day
 * */
const simpleDateFormatter = (time: number, type: number) => {
  let date = new Date(time);
  let MM = (date.getMonth() + 1).toString();
  MM = parseInt(MM) < 10 ? "0" + MM : MM;
  let d = date.getDate().toString();
  d = parseInt(d) < 10 ? "0" + d : d;
  let h = date.getHours().toString();
  h = parseInt(h) < 10 ? "0" + h : h;
  let m = date.getMinutes().toString();
  m = parseInt(m) < 10 ? "0" + m : m;
  let s = date.getSeconds().toString();
  s = parseInt(s) < 10 ? "0" + s : s;
  if (type === 1) return h + ":" + m;
  if (type === 2) return MM + "/" + d + " " + h + ":" + m;
  return MM + "/" + d;
};

export const MyBalanceWrapper = styled.div`
  padding: 0 24px 30px;
  position: relative;

  .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 14px;
    margin-bottom: 16px;
  }

  .balance {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;
    margin-bottom: 2px;
  }

  .usd-balance {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  .send {
    position: absolute;
    right: 24px;
    top: 4px;
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 36px;
      height: 36px;
      margin-bottom: 10px;
    }
  }
`;

export const BarWrapper = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;

  .title {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .bar-wrap {
    border-radius: 8px;
    background: var(--Deep-50, #edeef1);
    height: 6px;
    position: relative;
    margin-bottom: 8px;

    .bar-content {
      border-radius: 8px;
      background: var(--Deep-200, #b2b6bc);
      height: 6px;
      position: absolute;
      top: 0;
    }

    .bar-content.bullish {
      background: var(--Success, #10ce5c);
    }

    .bar-content.bearish {
      background: var(--error-notice, #ff1e2b);
    }
  }

  .bar-info {
    display: flex;
    justify-content: space-between;
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 6px;
  }

  .bar-info-1 {
    display: flex;
    justify-content: space-between;
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 22px 0 0 0px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .quote {
    flex: 1;
    overflow-y: scroll;

    .price-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 24px;
      margin-top: 9px;
      margin-bottom: 20px;

      .l,
      .r {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .l {
        align-items: flex-start;
      }

      .r {
        align-items: flex-end;
        padding-top: 2px;
      }

      .price {
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans BOLD";
        font-size: 24px;
        margin-bottom: 4px;

        span {
          color: var(--Deep-400, #80868f);
          font-family: "HarmonyOS Sans SC";
          font-size: 24px;
          font-weight: 400;
        }
      }

      .fluctuate {
        overflow: hidden;
        /* Description */
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 3px;
      }

      .contract {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 12px;
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }

      .chain-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        span {
          color: var(--Kontos-Blue, #413dec);
          font-family: "HarmonyOS Sans SC";
          font-size: 10px;
          font-weight: 400;
          padding: 4px;
          border-radius: 4px;
          background: rgba(65, 61, 236, 0.05);
          margin-left: 8px;
          max-width: 100px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
        }
      }

      .fluctuate.bullish {
        color: var(--Success, #10ce5c);
      }

      .fluctuate.bearish {
        color: var(--error-notice, #ff1e2b);
      }
    }

    .volume-wrap {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 24px;
      position: relative;

      .volume-line {
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: calc(100% - 16px);
        background-color: var(--Deep-50, #edeef1);
      }

      .volume {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        margin-bottom: 16px;
      }

      .volume:nth-child(odd) {
        padding-right: 16px;
      }

      .volume:nth-child(even) {
        padding-left: 16px;
      }

      .key {
        color: var(--Deep-400, #80868f);
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
      }

      .value {
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
      }
    }

    .chart-wrap {
      position: relative;

      .chart-filter {
        position: absolute;
        top: 0;
        left: 24px;
        height: 30px;
        z-index: 10;
      }
    }

    .quote-info {
      padding: 20px 24px;

      .info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        color: var(--Deep-400, #80868f);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;

        .key {
          color: var(--Deep-400, #80868f);
          font-family: "HarmonyOS Sans SC";
          font-size: 14px;
          font-weight: 400;
        }

        .value {
          color: var(--Deep-800, #1a2535);
          font-family: "HarmonyOS Sans SC";
          font-size: 14px;
          font-weight: 400;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }

    #chart {
      //overflow: hidden;
      position: relative;
      height: 300px;

      .tooltip {
        position: absolute;
        display: none;
        padding: 8px;
        box-sizing: border-box;
        font-size: 12px;
        text-align: left;
        z-index: 1000;
        top: 12px;
        left: 12px;
        pointer-events: none;
        border: 1px solid;
        border-radius: 5px;
        //box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.9);
        background-color: rgba(255, 255, 255, 0.9);

        .tooltip-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          span {
            color: var(--Deep-400, #80868f);
            font-family: "HarmonyOS Sans SC";
            font-weight: 400;
          }
        }
      }
    }
  }

  .check {
    flex: 1;
    padding: 9px 24px 20px;
    overflow-y: scroll;
    .check-overview {
      height: 60px;
      .error,
      .warn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px;
        height: 60px;
        width: calc(50% - 4px);
        .number {
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          font-family: "HarmonyOS Sans SC";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 2px;
        }
        .text {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          font-family: "HarmonyOS Sans SC";
          font-size: 14px;
          font-weight: 400;
          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
      }
      .error {
        background: rgba(255, 30, 43, 0.05);
        .number {
          color: var(--error-notice, #ff1e2b);
        }
        .text {
          color: var(--error-notice, #ff1e2b);
        }
      }
      .warn {
        background: rgba(250, 173, 20, 0.05);
        .number {
          color: var(--Warning, #faad14);
        }
        .text {
          color: var(--Warning, #faad14);
        }
      }
    }
    .pixiuRisk {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .pixiuRisk-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(50% - 4px);
        height: 38px;
        border-radius: 8px;
        background: var(--Deep-25, #f5f5f6);
        .key {
          color: var(--Deep-400, #80868f);
          font-family: "HarmonyOS Sans SC";
          font-size: 14px;
          font-weight: 400;
        }
        .val {
          color: var(--Deep-800, #1a2535);
          font-family: "HarmonyOS Sans Bold";
          font-size: 16px;
        }
      }
    }
    .check-list {
      .check-title {
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans BOLD";
        font-size: 16px;
        margin-bottom: 16px;
      }
      .check-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 14px;
        padding: 0 2px;
        line-height: 130%;
        word-break: break-all;
        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          transform: translateY(1px);
        }
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .info {
    flex: 1;
    padding: 9px 24px 20px;
    overflow-y: scroll;

    .info-title {
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans BOLD";
      font-size: 16px;
    }

    .info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;

      .key {
        color: var(--Deep-400, #80868f);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }

      .value {
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .info-description {
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      margin-top: 10px;
    }

    .info-link {
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      margin-top: 10px;
      text-underline: none;

      a {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .info-socials {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }

  .button-wrap {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;

    .button {
      width: calc(50% - 4px);
      height: 42px;
      border-radius: 99px;
      font-family: "HarmonyOS Sans BOLD";
      font-size: 16px;
      cursor: pointer;
      border: none;
    }

    .buy {
      background: #4ddc86;
      color: #fff;
    }

    .full-width {
      width: 100%;
    }

    .sell {
      background: #ff1e2b;
      color: #fff;
    }
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;

  .l {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .icon {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .box {
    position: relative;
    margin-right: 14px;

    .coin {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    .icon {
      position: absolute;
      width: 16px;
      height: 16px;
      bottom: 0;
      left: 24px;
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .symbol {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans Bold";
      font-size: 14px;
    }

    .desc {
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 12px;
      font-weight: 400;
    }

    .verified {
      margin-left: 4px;
      width: 14px;
      height: 14px;
      transform: translateY(-1px);
    }

    .fluctuate {
      text-overflow: ellipsis;
      font-family: "HarmonyOS Sans SC";
      font-size: 12px;
      font-weight: 400;
      margin-right: 6px;
    }

    .fluctuate.bullish {
      color: var(--Success, #10ce5c);
    }

    .fluctuate.bearish {
      color: var(--error-notice, #ff1e2b);
    }

    .desc {
      margin-top: 2px;
      color: #999ea5;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }
  }

  .favorite {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      color: var(--Warning, #faad14);

      .default {
        display: none;
      }

      .hover {
        display: block;
      }
    }
  }

  .favorited {
    color: var(--Warning, #faad14);
  }

  .value {
    margin-left: auto;
    text-align: right;
    font-family: "HarmonyOS Sans Bold";
  }
`;

interface ICandlestick {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
}

const TaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px 10px;
  margin-top: 20px;

  .tab-item {
    color: var(--Deep-200, #b2b6bc);
    font-family: "HarmonyOS Sans BOLD";
    font-size: 16px;
    font-style: normal;
    margin-right: 16px;
    cursor: pointer;
  }

  .tab-item.active {
    color: var(--Deep-800, #1a2535);
  }
`;

function findFirstNonZeroDecimalPlace(value: number): number {
  const scientificString = value.toExponential();
  const [base, exponent] = scientificString.split("e");
  const normalizedBase = base.replace(".", "");
  const normalizedExponent = parseInt(exponent, 10);

  const firstNonZeroMatch = normalizedBase.match(/[^0]/);
  if (!firstNonZeroMatch) {
    return 0;
  }

  const firstNonZeroPosition = firstNonZeroMatch.index! + 1;
  const decimalPosition = firstNonZeroPosition - normalizedExponent;
  return decimalPosition > 0 ? decimalPosition : 1;
}

function findFirstNonZeroDecimalInData(data: ICandlestick[]): number {
  if (data.length === 0) {
    return 0;
  }

  let globalMin = Infinity;

  for (const item of data) {
    globalMin = Math.min(globalMin, item.open, item.high, item.low, item.close);
  }

  return findFirstNonZeroDecimalPlace(globalMin);
}

type IAssetsDetailProps = {
  handleBack?(): void;
};

const volumeConfigArr = [
  {
    key: "24h Vol(ETH)",
  },
  {
    key: "24h Low",
  },
  {
    key: "24h Vol(USD)",
  },
  {
    key: "24h High",
  },
];

const configInfoArr = [
  {
    key: "Price Change 24h",
  },
  {
    key: "Volume (24h)",
  },
  {
    key: "Market cap",
  },
  {
    key: "Fully diluted market cap",
  },
  {
    key: "Circulating supply",
  },
  {
    key: "Max. supply",
  },
  {
    key: "Total supply",
  },
  {
    key: "Market Rank",
  },
];

const chartSelectType: OptionType[] = [
  {
    label: "minutes",
    value: 1,
  },
  {
    label: "hours",
    value: 2,
  },
  {
    label: "day",
    value: 0,
  },
];

function findMinMax(candlesticks: ICandlestick[]): {
  max: number;
  min: number;
} {
  let max = -Infinity;
  let min = Infinity;

  candlesticks.forEach(({ open, high, low, close }) => {
    max = Math.max(max, open, high, low, close);
    min = Math.min(min, open, high, low, close);
  });

  return { max, min };
}

export const AssetDetailQuote: React.FC<IAssetsDetailProps> = observer(
  ({ handleBack }) => {
    const [searchParams] = useSearchParams();
    const ftAssetId = searchParams.get("ftAssetId");
    const chainIndex = searchParams.get("chainIndex");
    const ftAssetAddress = searchParams.get("ftAssetAddress");
    const { marketStore, favStore, userStore, spotStore } = useStores();
    const userHoldings = userStore.userHoldings;
    const currentAssetQuote = marketStore.currentAssetQuote;
    const { coinGeckoCoinId } = currentAssetQuote || {};
    const [activeTab, setActiveTab] = useState<string>(t("Quote"));
    const elementRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>(0);
    const navigate = useNavigate();
    const fluctuateNumber = getFluctuate(
      currentAssetQuote?.h24PriceChangePercentage || "0",
      16
    );
    const dappFavorites = favStore.ftAssetFavoritesWatched;
    const isFav = useMemo(() => {
      if (!parseInt(ftAssetId || "")) return false;
      return isFavoriteFtAssetId(parseInt(ftAssetId || ""), dappFavorites);
    }, [marketStore?.currentAssetQuote, dappFavorites]);
    const [chartFilter, setChartFilter] = useState<ChartFilterOption>(
      chartSelectType[2].value
    );
    const chart = useRef<any>(null);
    const [isGetOHLCFailed, setIsGetOHLCFailed] = useState<boolean>(false);
    const candleSeries = useRef<any>(null);
    const minPriceLine = useRef<any>(null);
    const maxPriceLine = useRef<any>(null);
    const [ftAssetsDetailQuote, setFtAssetsDetailQuote] =
      useState<AssetsDetailQuote | null>(null);
    const [riskInfo, setRiskInfo] = useState<IFtAssetRiskData | null>(null);
    const [loadingRisk, setLoadingRisk] = useState<boolean>(true);
    const currentAssetWebSiteArr = ftAssetsDetailQuote?.medias?.filter(
      (item) => !item.imageUrl
    );
    const currentAssetMediaArr = ftAssetsDetailQuote?.medias?.filter(
      (item) => !!item.imageUrl
    );
    const storeAccountAssetInfo = userHoldings?.find(
      (item) =>
        item.address === currentAssetQuote?.address &&
        item.chainIndex === currentAssetQuote?.chainIndex
    );
    const storeAccountBalance = storeAccountAssetInfo?.balance || "0";
    const storeAccountUsd = storeAccountAssetInfo?.totalUsd || "0";
    const [h24Ohlc, setH24Ohlc] = useState<OHLCData[]>([]);
    const [allTimeOhlc, setAllTimeOhlc] = useState<OHLCData[]>([]);
    const [isLoadingOhlc, setIsLoadingOhlc] = useState<boolean>(true);
    // const barPosition24h = getOHLCPopupPosition(h24Ohlc[0]);
    // const barPositionAllTime = getOHLCPopupPosition(allTimeOhlc[0]);
    const barWidth24h = getBarWidth(
      currentAssetQuote?.usdPrice || "0",
      currentAssetQuote?.h24Low || "0",
      currentAssetQuote?.h24High || "0"
    );
    const barWidthAllTime = getBarWidth(
      currentAssetQuote?.usdPrice || "0",
      currentAssetQuote?.atl || "0",
      currentAssetQuote?.ath || "0"
    );
    const [aiData, setAiData] = useState<string | undefined>(undefined);
    const [aiScore, setAiScore] = useState<number | undefined>(undefined);
    const [requestingAi, setRequestingAi] = useState<boolean>(false);

    useEffect(() => {
      const divElement = elementRef.current;
      if (divElement) {
        const resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            setWidth(entry.contentRect.width);
          }
        });

        resizeObserver.observe(divElement);

        // Cleanup on unmount
        return () => {
          resizeObserver.unobserve(divElement);
        };
      }
    }, []);

    const toggleFavoriteAsset = useCallback(
      async (id: number) => {
        const cli = userStore.kontosCli;
        if (!cli) {
          userStore.unlock(() => toggleFavoriteAsset(id));
          return;
        }
        const isFav = isFavoriteFtAssetId(id, dappFavorites);
        try {
          loadingStore.showLoading();
          isFav
            ? await favStore.removeFavorites({
                ftAssetId: id,
                cli,
              })
            : await favStore.addFavorites({
                ftAssetId: id,
                cli,
              });
        } catch (e) {
          console.log(e);
        } finally {
          loadingStore.hideLoading();
        }
      },
      [dappFavorites, favStore, userStore]
    );

    // risk data
    useEffect(() => {
      const getFtAssetsRiskInfoFunc = async () => {
        setLoadingRisk(true);
        try {
          const rs = await getFtAssetsRiskInfo({
            chainIndex: chainIndex || "",
            assetAddress: ftAssetAddress || "",
          });
          setLoadingRisk(false);
          setRiskInfo(rs);
        } catch (e) {
          setLoadingRisk(false);
        }
      };
      activeTab === t("Check") && !riskInfo && getFtAssetsRiskInfoFunc();
    }, [activeTab]);

    // basic data
    // useEffect(() => {
    //   const getFtAssetsDetailQuoteFunc = async () => {
    //     const rs = await getFtAssetsDetailQuote({
    //       chainIndex: chainIndex || "",
    //       ftAssetAddress: ftAssetAddress || "",
    //     });
    //     setFtAssetsDetailQuote(rs);
    //   };
    //   getFtAssetsDetailQuoteFunc();
    // }, []);

    // ai score data
    useEffect(() => {
      const fetchAndSetAiScore = async () => {
        if (!chainIndex || !ftAssetAddress) {
          return;
        }

        try {
          setRequestingAi(true);
          const { description, medias, scopeChatAiData, scopeChatAiScore } =
            await callFtassetDetail({
              chainIndex: chainIndex,
              ftAssetAddress: ftAssetAddress,
              scopeChatAi: true,
            });
          setFtAssetsDetailQuote({ description, medias });
          setAiData(scopeChatAiData);
          setAiScore(
            scopeChatAiScore >= 0 && scopeChatAiScore <= 100
              ? scopeChatAiScore
              : undefined
          );
        } catch (e) {
          console.warn("Failed to load ai score");
        } finally {
          setRequestingAi(false);
        }
      };

      fetchAndSetAiScore();
    }, [chainIndex, ftAssetAddress]);

    // ohlc data
    useEffect(() => {
      (async () => {
        if (!width) return;
        const initBarSpacing = 8;
        // get OHLC data
        const { ohlc, h24, allTime } = await getOHLC({
          coinGeckoCoinId: coinGeckoCoinId || "",
          chainIndex: chainIndex || "",
          ftAssetAddress: ftAssetAddress || "",
          interval: chartFilter,
        });
        setIsLoadingOhlc(false);
        if (!ohlc || !ohlc?.length) {
          setIsGetOHLCFailed(true);
          return;
        }
        if (h24) setH24Ohlc(h24);
        if (allTime) setAllTimeOhlc(allTime);
        const candleData: ICandlestick[] = ohlc?.map((item: OHLCData) => {
          return {
            // time: fmDate(item.Time, 'yyyy-MM-dd'),
            time: (item.time / 1000) as UTCTimestamp,
            open: parseFloat(getDisplayAmount(item.open, { precision: 18 })),
            high: parseFloat(getDisplayAmount(item.height, { precision: 18 })),
            low: parseFloat(getDisplayAmount(item.low, { precision: 18 })),
            close: parseFloat(getDisplayAmount(item.close, { precision: 18 })),
          };
        });
        const ele = document.getElementById("chart");
        if (!ele) return;
        if (!chart.current) {
          chart.current = createChart(ele, {
            width,
            height: 300,
            autoSize: true,
            handleScroll: {
              mouseWheel: false,
            },
            handleScale: {
              mouseWheel: false,
            },
            layout: {
              background: {
                type: ColorType.Solid,
                color: "#fff",
              },
              textColor: "#80868F",
            },
            grid: {
              vertLines: {
                color: "#f4f8f8",
              },
              horzLines: {
                color: "#f4f8f8",
              },
            },
            rightPriceScale: {
              autoScale: true,
              borderColor: "#f4f8f8",
              mode: 0,
              scaleMargins: {
                top: 0.2,
                bottom: 0.1,
              },
            },
            timeScale: {
              borderColor: "#f4f8f8",
              // minBarSpacing: 0.0001,
              barSpacing: initBarSpacing,
              timeVisible: true,
              secondsVisible: true,
              tickMarkFormatter: (time: number) => {
                return simpleDateFormatter(time * 1000, 0);
              },
            },
            localization: {
              locale: "en-US",
              timeFormatter: chartDateFormatter,
            },
          });

          // chart.current.timeScale().applyOptions({
          //   fixRightEdge: true,
          // });
          candleSeries.current = chart.current.addCandlestickSeries({
            upColor: "#00c2b7",
            borderUpColor: "#00c2b7",
            wickUpColor: "#00c2b7",
            downColor: "#fe5b79",
            borderDownColor: "#fe5b79",
            wickDownColor: "#fe5b79",
          });
          candleSeries.current.setData(candleData);
          candleSeries.current.applyOptions({
            priceFormat: {
              type: "custom",
              formatter: (price: number) => {
                return getDisplayAmount(price.toString(), {
                  decimal: 0,
                  isAutomateProcessAllTypes: true,
                });
              },
              // precision:
              //   findFirstNonZeroDecimalInData(candleData) + 1,
              minMove: 0.1e-18,
            },
          });
          // can set start and end time
          // chart.current.timeScale().fitContent();
          // chart.current.timeScale().setVisibleRange({
          //   from: candleData[0].time,
          //   to: candleData[candleData?.length-1].time
          // });
          // add price line
          const { max: maximumPrice, min: minimumPrice } =
            findMinMax(candleData);
          const minPriceLineConfig = {
            price: minimumPrice,
            color: "#ef5350",
            lineWidth: 1,
            lineStyle: 1,
            axisLabelVisible: true,
            // title: "min price",
          };
          const maxPriceLineConfig = {
            price: maximumPrice,
            color: "#26a69a",
            lineWidth: 1,
            lineStyle: 1,
            axisLabelVisible: true,
          };
          minPriceLine.current =
            candleSeries.current.createPriceLine(minPriceLineConfig);
          maxPriceLine.current =
            candleSeries.current.createPriceLine(maxPriceLineConfig);
          // Create and style the tooltip html element
          const toolTipWidth = 190;
          const toolTipHeight = 210;
          const toolTipMargin = 30;
          let toolTip = document.createElement("div");
          toolTip.style.width = toolTipWidth + "px";
          toolTip.style.height = toolTipHeight + "px";
          toolTip.className = "tooltip";
          ele.appendChild(toolTip);
          // update tooltip
          chart.current.subscribeCrosshairMove((param: MouseEventParams) => {
            if (
              param.point === undefined ||
              !param.time ||
              param.point.x < 0 ||
              param.point.x > ele.clientWidth ||
              param.point.y < 0 ||
              param.point.y > ele.clientHeight
            ) {
              toolTip.style.display = "none";
            } else {
              const dateStr = param.time;
              toolTip.style.display = "block";
              const data: any = param.seriesData.get(candleSeries.current);
              // @ts-ignore
              const priceChange = data?.close - data?.open;
              const priceChangePercentage = (priceChange / data?.open) * 100;
              const amplitudePercentage =
                ((data?.high - data?.low) / data?.open) * 100;
              toolTip.innerHTML = `
                <div class="tooltip-row">
                  <span class="l">${t("Time")}</span>
                  <span class="r">${chartDateFormatter(
                    dateStr as number
                  )}</span>
                </div>
                <div class="tooltip-row">
                  <span class="l">${t("Open")}</span>
                  <span class="r">${getDisplayAmount(data?.open, {
                    isAutomateProcessAllTypes: true,
                    decimal: 0,
                  })}</span>
                </div>
                <div class="tooltip-row">
                  <span class="l">${t("High")}</span>
                  <span class="r">${getDisplayAmount(data?.high, {
                    isAutomateProcessAllTypes: true,
                    decimal: 0,
                  })}</span>
                </div>
                <div class="tooltip-row">
                  <span class="l">${t("Low")}</span>
                  <span class="r">${getDisplayAmount(data?.low, {
                    isAutomateProcessAllTypes: true,
                    decimal: 0,
                  })}</span>
                </div>
                <div class="tooltip-row">
                  <span class="l">${t("Close")}</span>
                  <span class="r">${getDisplayAmount(data?.close, {
                    isAutomateProcessAllTypes: true,
                    decimal: 0,
                  })}</span>
                </div>
                <div class="tooltip-row">
                  <span class="l">${t("Price change")}</span>
                  <span class="r">${getDisplayAmount(priceChange.toString(), {
                    isAutomateProcessAllTypes: true,
                    decimal: 0,
                  })}</span>
                </div>
                <div class="tooltip-row">
                  <span class="l">${t("Price change percentage")}</span>
                  <span class="r">${getDisplayAmount(
                    priceChangePercentage.toString(),
                    { isAutomateProcessAllTypes: true, decimal: 0 }
                  )}%</span>
                </div>
                <div class="tooltip-row">
                  <span class="l">${t("Amplitude of fluctuation")}</span>
                  <span class="r">${getDisplayAmount(
                    amplitudePercentage.toString(),
                    { isAutomateProcessAllTypes: true, decimal: 0 }
                  )}%</span>
                </div>
              `;
              const y = param.point.y;
              let left = param.point.x + toolTipMargin;
              if (left > ele.clientWidth - toolTipWidth) {
                left = param.point.x - toolTipMargin - toolTipWidth;
              }
              let top = y + toolTipMargin;
              if (top > ele.clientHeight - toolTipHeight / 2) {
                top = y - toolTipHeight - toolTipMargin;
              }
              toolTip.style.left = left + "px";
              toolTip.style.top = top + "px";
            }
          });
          // tooltip end
        } else {
          let filter = initBarSpacing;
          if (chartFilter === 1) {
            filter = 8;
          }
          if (chartFilter === 2) {
            filter = 8;
          }
          chart.current.timeScale().applyOptions({
            barSpacing: filter,
            tickMarkFormatter: (time: number) => {
              return simpleDateFormatter(time * 1000, chartFilter);
            },
          });
          const { max: maximumPrice, min: minimumPrice } =
            findMinMax(candleData);
          minPriceLine.current.applyOptions({
            price: minimumPrice,
          });
          maxPriceLine.current.applyOptions({
            price: maximumPrice,
          });
          candleSeries.current.setData(candleData);
        }
      })();
    }, [marketStore.currentAssetQuote, width, chartFilter]);

    if (!currentAssetQuote) {
      navigate(-1);
      return null;
    }
    return (
      <Wrapper>
        <HeadWrapper>
          <div
            className={"l"}
            onClick={(e) => {
              handleBack && handleBack();
              navigate(-1);
            }}
          >
            <div className="icon-box">
              <img src={BackIcon} className="icon" alt="back-icon" />
            </div>
            <div className="box">
              <ImageWithFallback
                className="coin"
                src={currentAssetQuote.imageUrl}
                fallbackSrc={defaultTokenSvg}
              />
              <ImageWithFallback
                src={currentAssetQuote.chainGreyImageUrl}
                className="icon"
                alt="icon"
                fallbackSrc={AllChainIcon}
              />
            </div>
            <div className="label">
              <div className={"symbol"}>
                {currentAssetQuote.symbol}
                <AssetSecurityIcon
                  isGreatLiquidity={currentAssetQuote.isGreatLiquidity}
                  securityLevel={currentAssetQuote.securityLevel}
                  disableHoverToClick
                />
              </div>
              <div className="desc">{currentAssetQuote.chainSymbol}</div>
            </div>
          </div>
          <div
            className={`favorite ${isFav ? "favorited" : ""}`}
            onClick={() => {
              if (!ftAssetId) return;
              toggleFavoriteAsset(parseInt(ftAssetId || "0"));
            }}
          >
            {!isFav ? t("Add Favorites") : t("Favorited")}
            <Star
              starStatus={!isFav ? "default" : "active"}
              styles={{ marginLeft: "6px" }}
            />
          </div>
        </HeadWrapper>
        <TaWrapper>
          {[t("Quote"), t("Check"), t("AI Score"), t("Info")].map(
            (item, index) => {
              return (
                <div
                  key={index}
                  className={`tab-item ${activeTab === item ? "active" : ""}`}
                  onClick={() => {
                    setActiveTab(item);
                  }}
                >
                  {item}
                </div>
              );
            }
          )}
        </TaWrapper>

        {/* Quote Tab */}
        <div
          className="quote"
          style={{ display: activeTab === t("Quote") ? "block" : "none" }}
          ref={elementRef}
        >
          <div className={"price-wrap elips"}>
            <div className={"l"}>
              <div className="price">
                <span>$</span>
                {getDisplayAmount(currentAssetQuote.usdPrice, {
                  isAutomateProcessAllTypes: true,
                })}
              </div>
              {!!fluctuateNumber ? (
                <div
                  className={`fluctuate ${
                    fluctuateNumber > 0 ? "bullish" : "bearish"
                  }`}
                >
                  {fluctuateNumber > 0 ? "↑" : "↓"} {fluctuateNumber}%(24h)
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className={"r"}>
              <div className={"contract"}>
                {cutAddress(currentAssetQuote.address)}
                <Copy text={currentAssetQuote.address} />
              </div>
              <div className={"chain-info"}>
                {currentAssetQuote.categories?.slice(0, 2).map((item) => {
                  return <span key={item}>{item}</span>;
                })}
              </div>
            </div>
          </div>
          {isGetOHLCFailed && (
            <NoDataChart
              text={t("This asset currently has no chart data.")}
              style={{
                height: "300px",
              }}
            />
          )}
          <SkeletonChart
            style={{
              height: "300px",
              display: !isGetOHLCFailed && isLoadingOhlc ? "flex" : "none",
            }}
          />
          <div
            className={"chart-wrap"}
            style={{
              display: !isGetOHLCFailed && !isLoadingOhlc ? "block" : "none",
            }}
          >
            <div className={"chart-filter"}>
              <SelectLight
                options={chartSelectType}
                onChange={(e) => {
                  setChartFilter(e.value);
                }}
                initSelected={chartSelectType.find(
                  (item) => item.value === chartFilter
                )}
              />
            </div>
            <div id={"chart"}></div>
          </div>
          <DottedLine
            styles={{
              margin: "20px 0",
            }}
          />
          <BarWrapper>
            <div className={"title"}>{t("24h Low/High")}</div>
            <div className={"bar-wrap"}>
              <div
                style={{
                  left: 0,
                  width: `${barWidth24h}%`,
                }}
                className={`bar-content`}
              />
            </div>
            <div className={"bar-info"} style={{ marginBottom: 0 }}>
              <span className={"l"}>
                $
                {getDisplayAmount(currentAssetQuote.h24Low || "0", {
                  isAutomateProcessAllTypes: true,
                })}
              </span>
              <span className={"r"}>
                $
                {getDisplayAmount(currentAssetQuote.h24High || "0", {
                  isAutomateProcessAllTypes: true,
                })}
              </span>
            </div>
          </BarWrapper>
          <BarWrapper style={{ marginBottom: 0 }}>
            <div className={"title"}>{t("All Time")}</div>
            <div className={"bar-wrap"}>
              <div
                style={{
                  left: 0,
                  width: `${barWidthAllTime}%`,
                }}
                className={`bar-content`}
              />
            </div>
            <div className={"bar-info"}>
              <span className={"l"}>
                $
                {getDisplayAmount(currentAssetQuote.atl || "0", {
                  isAutomateProcessAllTypes: true,
                })}
              </span>
              <span className={"r"}>
                $
                {getDisplayAmount(currentAssetQuote.ath || "0", {
                  isAutomateProcessAllTypes: true,
                })}
              </span>
            </div>
            <div className={"bar-info-1"}>
              <span className={"l"}>
                {currentAssetQuote.atlDate
                  ? fmDate(parseInt(currentAssetQuote.atlDate as string))
                  : "-"}
              </span>
              <span className={"r"}>
                {currentAssetQuote.athDate
                  ? fmDate(parseInt(currentAssetQuote.athDate as string))
                  : "-"}
              </span>
            </div>
          </BarWrapper>
          <div className={"quote-info"}>
            {configInfoArr.map((item, index) => {
              return (
                <div key={index} className={"info-item"}>
                  <span className={"key"}>{t(item.key)}</span>
                  <span className={"value"}>
                    {item.key === "Price Change 24h" &&
                      (currentAssetQuote?.h24PriceChange === "0"
                        ? "-"
                        : getDisplayAmount(currentAssetQuote?.h24PriceChange, {
                            isAutomateProcessAllTypes: true,
                          }))}
                    {item.key === "Market Rank" &&
                      (currentAssetQuote?.marketCapRank === 0
                        ? "-"
                        : currentAssetQuote?.marketCapRank)}
                    {item.key === "Volume (24h)" &&
                      (currentAssetQuote?.h24VolumeUsd === "0"
                        ? "-"
                        : getDisplayAmount(currentAssetQuote?.h24VolumeUsd, {
                            isAutomateProcessAllTypes: true,
                          }))}
                    {item.key === "Circulating supply" &&
                      (currentAssetQuote?.circulatingSupply === "0"
                        ? "-"
                        : getDisplayAmount(
                            currentAssetQuote?.circulatingSupply,
                            {
                              isAutomateProcessAllTypes: true,
                            }
                          ))}
                    {item.key === "Market cap" &&
                      (currentAssetQuote?.marketCapUsd === "0"
                        ? "-"
                        : getDisplayAmount(currentAssetQuote?.marketCapUsd, {
                            isAutomateProcessAllTypes: true,
                          }))}
                    {item.key === "Total supply" &&
                      (currentAssetQuote?.totalSupply === "0"
                        ? "-"
                        : getDisplayAmount(currentAssetQuote?.totalSupply, {
                            isAutomateProcessAllTypes: true,
                          }))}
                    {item.key === "Max. supply" &&
                      (currentAssetQuote?.maxSupply === "0"
                        ? "-"
                        : getDisplayAmount(currentAssetQuote?.maxSupply, {
                            isAutomateProcessAllTypes: true,
                          }))}
                    {item.key === "Fully diluted market cap" &&
                      (currentAssetQuote?.fdvUsd === "0"
                        ? "-"
                        : getDisplayAmount(currentAssetQuote?.fdvUsd, {
                            isAutomateProcessAllTypes: true,
                          }))}
                  </span>
                </div>
              );
            })}
          </div>
          <DottedLine
            styles={{
              margin: "20px 0",
            }}
          />
          <MyBalanceWrapper>
            <div className={"title"}>{t("My balance")}</div>
            <div className={"balance"}>
              {getDisplayAmount(storeAccountBalance, {
                isAutomateProcessAllTypes: true,
              })}
            </div>
            <div className={"usd-balance"}>
              {getDisplayAmount(storeAccountUsd, {
                isAutomateProcessAllTypes: true,
              })}{" "}
              USD
            </div>
            <div
              className={"send"}
              onClick={() => {
                if (
                  !storeAccountBalance ||
                  storeAccountBalance === "0" ||
                  !storeAccountAssetInfo
                ) {
                  toast({
                    text: t("You do not have sufficient balance.") as string,
                    type: "error",
                  });
                  return;
                }
                marketStore.setSelectedSendAsset(storeAccountAssetInfo);
                navigate(ROUTE_SEND);
              }}
            >
              <img src={homeIco1} alt="" />
              {t("Send")}
            </div>
          </MyBalanceWrapper>
        </div>

        {/* Check Tab */}
        <div
          className="check"
          style={{ display: activeTab === t("Check") ? "block" : "none" }}
        >
          <div className={"check-overview"}>
            <div className={"l error"}>
              <div className={"number"}>
                {loadingRisk && <EllipsisPlaceholder />}
                {!loadingRisk && (riskInfo?.risky || "0")}
              </div>
              <div className={"text"}>
                <img src={errorIco} />
                Risky
              </div>
            </div>
            <div className={"r warn"}>
              <div className={"number"}>
                {loadingRisk && <EllipsisPlaceholder />}
                {!loadingRisk && (riskInfo?.alert || "0")}
              </div>
              <div className={"text"}>
                <img src={warnIco} />
                Alert
              </div>
            </div>
          </div>
          {loadingRisk && <SkeletonRiskList length={2} />}
          {!loadingRisk &&
            riskInfo?.risks?.map((item, index) => {
              return (
                <div key={index}>
                  <DottedLine
                    styles={{
                      margin: "20px 0",
                    }}
                  />
                  <div className={"check-list"}>
                    <div className={"check-title"}>{item.riskTypeName}</div>
                    {item.riskTypeName === matchPixiuKey && (
                      <div
                        className={"pixiuRisk"}
                        style={{
                          marginBottom: "16px",
                        }}
                      >
                        <div className={"pixiuRisk-item l"}>
                          <span className={"key"}>Buy fee: </span>
                          <span className={"val"}>
                            {getDisplayAmount(riskInfo?.buyFee, {
                              decimal: 16,
                            })}
                            %
                          </span>
                        </div>
                        <div className={"pixiuRisk-item r"}>
                          <span className={"key"}>Selling fee: </span>
                          <span className={"val"}>
                            {getDisplayAmount(riskInfo?.sellingFee, {
                              decimal: 16,
                            })}
                            %
                          </span>
                        </div>
                      </div>
                    )}
                    <div className={"check-item-wrap"}>
                      {item.riskDetailList?.map((subItem, subIndex) => {
                        return (
                          <div key={subIndex} className={"check-item"}>
                            <RiskIcon level={subItem.riskCategoryLevel} />
                            {subItem.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          <HorizontalLine
            styles={{
              marginTop: "48px",
              marginBottom: "22px",
            }}
          />
          <PoweredGoPlus />
        </div>

        {/* Ai Score Tab */}
        {activeTab === t("AI Score") && (
          <AiScoreTab
            aiData={aiData}
            aiScore={aiScore}
            requesting={requestingAi}
          />
        )}

        {/* About Tab */}
        <div
          className="info"
          style={{ display: activeTab === t("Info") ? "block" : "none" }}
        >
          <div className={"info-title"}>{t("About")}</div>
          <div className={"info-description"}>
            <TruncatedText
              text={ftAssetsDetailQuote?.description || "-"}
              maxLength={285}
            />
          </div>
          <DottedLine
            styles={{
              margin: "20px 0",
            }}
          />
          <div className={"info-title"}>{t("Official website")}</div>
          <div className={"info-link"}>
            {!currentAssetWebSiteArr && "-"}
            {currentAssetWebSiteArr?.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.mediaUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={"info-description"}
                >
                  {item.mediaUrl}
                </a>
              );
            })}
          </div>
          <DottedLine
            styles={{
              margin: "20px 0",
            }}
          />
          <div className={"info-title"}>{t("Socials")}</div>
          <div className={"info-socials"}>
            {!currentAssetMediaArr && "-"}
            {currentAssetMediaArr?.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.mediaUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={"info-description"}
                >
                  <img src={item.imageUrl} alt="" />
                </a>
              );
            })}
          </div>
        </div>

        {activeTab === t("AI Score") ? (
          <AiPowerBy />
        ) : (
          <div className={"button-wrap"}>
            <button
              className={`button buy ${
                !storeAccountBalance || storeAccountBalance === "0"
                  ? "full-width"
                  : ""
              }`}
              onClick={() => {
                spotStore.resetBuyInput();
                spotStore.switchMode(SpotMode.Buy);
                spotStore.setToBuyFtAsset(currentAssetQuote);
                navigate(ROUTE_TRADE);
              }}
            >
              {t("Buy")}
            </button>
            {storeAccountAssetInfo &&
              storeAccountBalance &&
              storeAccountBalance !== "0" && (
                <button
                  onClick={() => {
                    spotStore.resetSellInput();
                    spotStore.switchMode(SpotMode.Sell);
                    spotStore.setToSellFtAsset(storeAccountAssetInfo);
                    navigate(ROUTE_TRADE);
                  }}
                  className={"button sell"}
                >
                  {t("Sell")}
                </button>
              )}
          </div>
        )}
      </Wrapper>
    );
  }
);
