import { ChainConfig, FtAsset } from "src/type/zkkontos";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import {
  RespQuoteWithBasicRequirement,
  RespTaskDataV3,
} from "@zkkontos/kontos-sdk/src/api";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL, DEFAULT_SLIPPAGE } from "src/config";
import { RootStore } from "../RootStore";
import { UserStore } from "../UserStore";
import * as TradeService from "src/service/trade-service";
import {
  ChainIndex,
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import { ReqTaskChargeAsset } from "@zkkontos/kontos-sdk/src/api/reqTypes";
import sessionKeeper from "../sessionKeeper";
import { isSameFtAsset } from "src/utils/zkkontosHelper";

export enum SpotMode {
  Buy = "buy",
  Sell = "sell",
  BatchSell = "batchSell",
}

const MAX_BUY_VALUE = 10000000;
const MIN_BUY_VALUE = 0.01;
const MIN_SELL_QUANTITY = 0.00000001;
const INIT_ASSET_RETRY_INTERVAL = 60000;

export class SpotStore {
  rootStore: RootStore;
  userStore: UserStore;
  mode: SpotMode = sessionKeeper.getTradeMode() || SpotMode.Buy;
  modeSwitchCount: number = 0;
  taskData?: RespTaskDataV3;
  selectedTaskDataIndex: number = 0;
  receiver?: string;
  slippage: KontosNumber = DEFAULT_SLIPPAGE;
  // SpotMode.Buy
  toBuyFtAsset?: FtAsset; // ft asset user wants to buy
  toBuyFtAssetValue?: KontosNumber; // usd the user wants to purchase ft asset worth
  // SpotMode.Sell
  toSellFtAsset?: FtAsset; // ft asset user wants to sell
  toReceiveFtAsset?: FtAsset; // ft asset user wants to receive for sell
  toSellFtAssetQuantity?: KontosNumber; // quantity the user wants to sell specific ft asset
  // SpotMode.BatchSell
  toBatchSellFtAssets: FtAsset[] = []; // ft asset user wants to batch sell
  toBuyFtAssetForBatchSell?: FtAsset; // ft asset user wants to buy for batch sell
  toBuyFtAssetValueForBatchSell?: KontosNumber; //usd ther user wants to purchase ft asset worth

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.userStore = rootStore.userStore;
    this.startTrackingAccountName();
    this.startTrackingTradeMode();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  startTrackingAccountName = () => {
    reaction(
      () => this.rootStore.userStore.accountName,
      () => {
        this.reset();
      }
    );
  };

  startTrackingTradeMode = () => {
    reaction(
      () => this.mode,
      () => {
        this.modeSwitchCount += 1;
      }
    );
  };

  reset = () => {
    this.receiver = this.userStore.accountName;
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;

    this.slippage = DEFAULT_SLIPPAGE;

    this.toBuyFtAsset = undefined;
    this.toBuyFtAssetValue = undefined;

    this.toSellFtAsset = undefined;
    this.toReceiveFtAsset = undefined;
    this.toSellFtAssetQuantity = undefined;

    this.toBatchSellFtAssets = [];
    this.toBuyFtAssetForBatchSell = undefined;
    this.toBuyFtAssetValueForBatchSell = undefined;
  };

  resetTaskData = () => {
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
  };

  resetAfterRegisteringTask = () => {
    this.resetInput();
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
    this.slippage = DEFAULT_SLIPPAGE;
    this.receiver = this.userStore.accountName;
  };

  resetInput = () => {
    switch (this.mode) {
      case SpotMode.Buy:
        this.resetBuyInput();
        break;
      case SpotMode.Sell:
        this.resetSellInput();
        break;
      case SpotMode.BatchSell:
        this.resetBatchSellInput();
        break;
      default:
        break;
    }
  };

  resetBuyInput = () => {
    this.toBuyFtAssetValue = undefined;
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
  };

  resetSellInput = () => {
    this.toSellFtAssetQuantity = undefined;
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
  };

  resetBatchSellInput = () => {
    this.toBuyFtAssetValueForBatchSell = undefined;
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
  };

  switchMode = (mode: SpotMode) => {
    sessionKeeper.setTradeMode(mode);
    this.slippage = DEFAULT_SLIPPAGE;
    this.receiver = this.userStore.accountName;
    this.taskData = undefined;
    this.selectedTaskDataIndex = 1;
    this.mode = mode;
  };

  setInitFtAssets = (ftAssets: FtAsset[]) => {
    runInAction(() => {
      switch (this.mode) {
        case SpotMode.Buy:
          this.toBuyFtAsset = ftAssets?.[0];
          break;
        default:
          break;
      }
    });
  };

  fetchAndSetInitFtassets = async (retryCount: number = 0) => {
    const resp = await Promise.allSettled([
      TradeService.fetchInitFavoriteFtAssets(this.userStore.accountName!),
      TradeService.fetchInitRecommendedFtAssets(),
    ]);
    const [favoritesResult, recommendationResult] = resp;
    if (
      favoritesResult.status === "fulfilled" &&
      favoritesResult.value.ftAssets?.length > 0
    ) {
      this.setInitFtAssets(favoritesResult.value.ftAssets);
      return favoritesResult.value.ftAssets;
    } else if (
      recommendationResult.status === "fulfilled" &&
      recommendationResult.value.ftAssets?.length > 0
    ) {
      this.setInitFtAssets(recommendationResult.value.ftAssets);
      return recommendationResult.value.ftAssets;
    } else {
      if (retryCount < 10)
        setTimeout(
          () => this.fetchAndSetInitFtassets(retryCount + 1),
          INIT_ASSET_RETRY_INTERVAL
        );
    }
  };

  setTaskDataSync = (taskData: RespTaskDataV3, count: number) => {
    if (count === this.modeSwitchCount)
      runInAction(() => {
        this.taskData = taskData;
        this.selectedTaskDataIndex = 0;
      });
  };

  fetchAndSetTaskData = async (
    chargetAsset?: ReqTaskChargeAsset
  ): Promise<RespTaskDataV3 | undefined> => {
    const currentModeCount = this.modeSwitchCount;
    if (!this.userStore.accountName || !this.receiver) return undefined;

    switch (this.mode) {
      case SpotMode.Buy:
        if (!this.toBuyFtAsset) return undefined;
        const taskDataBuy = await TradeService.fetchBuyTaskDataV3(
          this.userStore.accountName,
          this.receiver,
          this.toBuyFtAsset?.chainIndex,
          this.toBuyFtAsset?.address,
          this.toBuyFtAssetValue || new KontosNumber(0),
          this.slippage,
          chargetAsset
        );
        if (this.toBuyFtAsset && this.receiver) {
          this.setTaskDataSync(taskDataBuy, currentModeCount);
        } else {
          runInAction(() => {
            this.resetBuyInput();
          });
        }
        return taskDataBuy;
      case SpotMode.Sell:
        if (!this.toSellFtAsset || !this.toReceiveFtAsset) return undefined;
        const taskDataSell = await TradeService.fetchSellTaskDataV3(
          this.userStore.accountName,
          this.receiver,
          this.toSellFtAsset?.chainIndex,
          this.toSellFtAsset?.address,
          this.toSellFtAssetQuantity || new KontosNumber(0),
          this.toReceiveFtAsset?.chainIndex,
          this.toReceiveFtAsset?.address,
          this.slippage
        );
        this.setTaskDataSync(taskDataSell, currentModeCount);
        return taskDataSell;
      case SpotMode.BatchSell:
        if (!this.toBuyFtAssetForBatchSell) return undefined;
        const taskDataBatchSell = await TradeService.fetchBatchSellTaskDataV3(
          this.userStore.accountName,
          this.receiver,
          this.toBuyFtAssetForBatchSell?.chainIndex,
          this.toBuyFtAssetForBatchSell?.address,
          this.toBatchSellFtAssets,
          this.toBuyFtAssetValueForBatchSell || new KontosNumber(0),
          this.slippage
        );
        this.setTaskDataSync(taskDataBatchSell, currentModeCount);
        return taskDataBatchSell;
    }
  };

  fetchAndSetDefaultToReceiveFtAsset = async (regexAsset: {
    chainIndex: string;
    address: string;
  }) => {
    if (regexAsset) {
      try {
        const formattedFtAsset = await TradeService.fetchSpecificFtAsset(
          regexAsset?.chainIndex,
          regexAsset?.address
        );
        // User may have already selected the asset himself/herself
        if (
          !this.toReceiveFtAsset &&
          !isSameFtAsset(this.toSellFtAsset, formattedFtAsset)
        )
          runInAction(() => {
            this.toReceiveFtAsset = formattedFtAsset;
          });
        if (
          !this.toBuyFtAssetForBatchSell &&
          !this.toBatchSellFtAssets.some((item) =>
            isSameFtAsset(item, formattedFtAsset)
          )
        )
          runInAction(() => {
            this.toBuyFtAssetForBatchSell = formattedFtAsset;
          });
      } catch (e) {
        console.warn(e);
      }
    }
  };

  get hasValidResultTaskData(): boolean {
    switch (this.mode) {
      case SpotMode.Buy:
        return !!this.taskData?.respQuoteWithBasicRequirement;
      case SpotMode.Sell:
      case SpotMode.BatchSell:
        return !!this.taskData?.newTasksByPaymentPlans;
      default:
        return false;
    }
  }

  get toSellFtAssetTotal(): KontosNumber {
    return new KontosNumber(this.toSellFtAsset?.balance, DEFAULT_DECIMAL);
  }

  get inputValue(): KontosNumber | undefined {
    switch (this.mode) {
      case SpotMode.Buy:
        return this.toBuyFtAssetValue;
      case SpotMode.Sell:
        return this.toSellFtAssetQuantity;
      case SpotMode.BatchSell:
        return this.toBuyFtAssetValueForBatchSell;
      default:
        return undefined;
    }
  }

  get inputMaxValue(): number {
    switch (this.mode) {
      case SpotMode.Buy:
      case SpotMode.BatchSell:
        return MAX_BUY_VALUE;
      case SpotMode.Sell:
        return this.toSellFtAssetTotal.toNumber();
      default:
        return 0;
    }
  }

  get inputMinValue(): number {
    switch (this.mode) {
      case SpotMode.Buy:
      case SpotMode.BatchSell:
        return MIN_BUY_VALUE;
      case SpotMode.Sell:
        return MIN_SELL_QUANTITY;
      default:
        return 0;
    }
  }

  get quote(): RespQuoteWithBasicRequirement | undefined {
    return this.taskData?.respQuoteWithBasicRequirement;
  }

  get fee(): KontosNumber | undefined {
    return this.taskData && this.taskData.newTasksByPaymentPlans?.length > 0
      ? new KontosNumber(
          this.taskData.newTasksByPaymentPlans?.[this.selectedTaskDataIndex]
            ?.totalFeeInUsd,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get orderPrice(): KontosNumber | undefined {
    if (
      this.mode === SpotMode.Buy &&
      this.insufficient &&
      this.taskData?.maxRequiredUsdCost !== "0"
    ) {
      return new KontosNumber(
        this.taskData?.maxRequiredUsdCost,
        DEFAULT_DECIMAL
      );
    }

    return this.taskData && this.taskData.totalRequiredUsdCost !== "0"
      ? new KontosNumber(this.taskData.totalRequiredUsdCost, DEFAULT_DECIMAL)
      : undefined;
  }

  get toBuyFtAssetQuantity(): KontosNumber | undefined {
    return this.quote
      ? new KontosNumber(this.quote?.assetAmount, DEFAULT_DECIMAL)
      : undefined;
  }

  get inputSymbol(): string {
    switch (this.mode) {
      case SpotMode.Buy:
      case SpotMode.BatchSell:
        return "USD";
      case SpotMode.Sell:
        return this.toSellFtAsset?.symbol || "";
      default:
        return "";
    }
  }

  // Intention: Buy, BatchSell
  get maxAvailableForTargetFtAsset(): KontosNumber | null {
    return this.taskData
      ? new KontosNumber(
          this.taskData?.maxAvailableUsdForTargetAsset,
          DEFAULT_DECIMAL
        )
      : null;
  }

  // Intention: Sell
  get minRequiredForTargetFtAsset(): KontosNumber | null {
    return this.toSellFtAsset
      ? new KontosNumber(
          this.taskData?.minRequiredSellAssetAmount,
          DEFAULT_DECIMAL
        )
      : null;
  }

  // Intention: Buy, BatchSell
  get totalBalanceInUsd(): KontosNumber | null {
    return this.rootStore.userStore.accountBalances
      ? new KontosNumber(
          this.rootStore.userStore.accountBalances.totalUsd,
          DEFAULT_DECIMAL
        )
      : null;
  }

  // Intention: Sell
  get balanceOfToSellFtAsset(): KontosNumber | null {
    return this.toSellFtAsset
      ? new KontosNumber(this.toSellFtAsset?.balance, DEFAULT_DECIMAL)
      : null;
  }

  get insufficient(): boolean {
    const flag =
      !this.taskData?.newTasksByPaymentPlans ||
      this.taskData.newTasksByPaymentPlans.length === 0;
    switch (this.mode) {
      case SpotMode.Buy:
        return !!this.toBuyFtAssetValue && flag;
      case SpotMode.Sell:
        return !!this.toSellFtAssetQuantity && flag;
      case SpotMode.BatchSell:
        return (
          !!this.toBuyFtAssetValueForBatchSell &&
          this.toBatchSellFtAssets.length > 0 &&
          flag
        );
      default:
        return true;
    }
  }

  get hasUserInput(): boolean {
    switch (this.mode) {
      case SpotMode.Buy:
        return !!this.toBuyFtAssetValue;
      case SpotMode.Sell:
        return !!this.toSellFtAssetQuantity;
      case SpotMode.BatchSell:
        return (
          !!this.toBuyFtAssetValueForBatchSell &&
          this.toBatchSellFtAssets.length > 0
        );
      default:
        return false;
    }
  }

  get toReceiveFtAssetQuantity(): KontosNumber | undefined {
    return this.taskData
      ? new KontosNumber(
          this.taskData?.maxAvailableOtcAssetAmount,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get toReceiveFtAssetValueEstimated(): KontosNumber | undefined {
    return this.toReceiveFtAsset && this.toReceiveFtAssetQuantity
      ? this.toReceiveFtAssetQuantity.multiply(
          this.toReceiveFtAsset.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get toSellFtassetValueEstimated(): KontosNumber | undefined {
    return this.toSellFtAsset && this.toSellFtAssetQuantity
      ? this.toSellFtAssetQuantity.multiply(
          this.toSellFtAsset.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get toBuyFtAssetValueEstimated(): KontosNumber | undefined {
    return this.toBuyFtAsset && this.toBuyFtAssetQuantity
      ? this.toBuyFtAssetQuantity.multiply(
          this.toBuyFtAsset.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get toBuyFtAssetValueForBatchSellEstimated(): KontosNumber | undefined {
    return this.toBuyFtAssetForBatchSell && this.toBuyFtAssetQuantity
      ? this.toBuyFtAssetQuantity.multiply(
          this.toBuyFtAssetForBatchSell.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get canBatchSellUserholdings(): FtAsset[] {
    return this.userStore.userHoldings.filter(
      (userItem) =>
        !this.toBatchSellFtAssets.some((toItem) =>
          isSameFtAsset(userItem, toItem)
        )
    );
  }

  get canBatchSellChains(): ChainConfig[] {
    return this.rootStore.chainStore.chains.filter((chain) =>
      this.canBatchSellUserholdings.some(
        (item) => item.chainIndex === chain.chainIndex
      )
    );
  }

  get defaultReceiveFtAssetType(): ChainIndex {
    switch (this.mode) {
      case SpotMode.Sell:
        return this.rootStore.chainStore.defaultOptForSellReceive;
      case SpotMode.BatchSell:
        return this.rootStore.chainStore.defaultOptForBatchSellReceive;
      default:
        return NonChainIndex.All;
    }
  }

  get onReceiveChainChange(): (index: ChainIndex) => void {
    switch (this.mode) {
      case SpotMode.Sell:
        return this.rootStore.chainStore.setDefaultOptForSellReceive;
      case SpotMode.BatchSell:
        return this.rootStore.chainStore.setDefaultOptForBatchSellReceive;
      default:
        return () => {};
    }
  }

  get onChainChange(): (index: ChainIndex) => void {
    switch (this.mode) {
      case SpotMode.Buy:
        return this.rootStore.chainStore.setDefaultOptForBuy;
      case SpotMode.Sell:
        return this.rootStore.chainStore.setDefaultOptForSell;
      case SpotMode.BatchSell:
        return this.rootStore.chainStore.setDefaultOptForBatchSell;
      default:
        return () => {};
    }
  }

  get defaultFtAssetType(): ChainIndex {
    switch (this.mode) {
      case SpotMode.Buy:
        return this.rootStore.chainStore.defaultOptForBuy ===
          NonChainIndex.Favorites
          ? this.rootStore.favStore.ftAssetFavorites.length > 0
            ? NonChainIndex.Favorites
            : NonChainIndex.Recommend
          : this.rootStore.chainStore.defaultOptForBuy;
      case SpotMode.Sell:
        return this.rootStore.chainStore.defaultOptForSell !== NonChainIndex.All
          ? this.userStore.userHoldings.some(
              (item) =>
                item.chainIndex === this.rootStore.chainStore.defaultOptForSell
            )
            ? this.rootStore.chainStore.defaultOptForSell
            : NonChainIndex.All
          : NonChainIndex.All;
      case SpotMode.BatchSell:
        return this.rootStore.chainStore.defaultOptForBatchSell !==
          NonChainIndex.All
          ? this.userStore.userHoldings.some(
              (item) =>
                item.chainIndex ===
                this.rootStore.chainStore.defaultOptForBatchSell
            )
            ? this.rootStore.chainStore.defaultOptForBatchSell
            : NonChainIndex.All
          : NonChainIndex.All;
      default:
        return NonChainIndex.All;
    }
  }

  setToBuyFtAsset = (ftAsset: FtAsset) => {
    this.toBuyFtAsset = ftAsset;
  };

  setToBuyFtAssetValue = (value?: KontosNumber) => {
    this.toBuyFtAssetValue = value;
  };

  setToSellFtAssetQuantity = (quantity?: KontosNumber) => {
    this.toSellFtAssetQuantity = quantity;
  };

  setToBuyFtAssetForBatchSell = (asset: FtAsset) => {
    this.toBuyFtAssetForBatchSell = asset;
  };

  setToBuyFtAssetValueForBatchSell = (value?: KontosNumber) => {
    this.toBuyFtAssetValueForBatchSell = value;
  };

  setTaskData = (taskData?: RespTaskDataV3) => {
    this.taskData = taskData;
  };

  setSelectedTaskDataIndex = (index: number) => {
    this.selectedTaskDataIndex = index;
  };

  setReceiver = (_receiver: string) => {
    this.receiver = _receiver;
  };

  setToSellFtAsset = (_ftAsset: FtAsset) => {
    this.toSellFtAsset = _ftAsset;
  };

  setToReceiveFtAsset = (_ftAsset: FtAsset) => {
    this.toReceiveFtAsset = _ftAsset;
  };

  addToBatchSellFtAsset = (asset: FtAsset) => {
    this.toBatchSellFtAssets.push(asset);
  };

  removeBatchSellFtAsset = (asset: FtAsset) => {
    this.toBatchSellFtAssets = this.toBatchSellFtAssets.filter(
      (item) => !isSameFtAsset(item, asset)
    );
  };

  replaceBatchSellFtAsset = (newOne: FtAsset, toReplaceOne: FtAsset) => {
    const index = this.toBatchSellFtAssets.findIndex((item) =>
      isSameFtAsset(item, toReplaceOne)
    );
    if (typeof index === "number") {
      this.toBatchSellFtAssets[index] = newOne;
    }
  };

  setSlippage = (slippage: KontosNumber) => {
    this.slippage = slippage;
  };
}
