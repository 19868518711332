import styled from "styled-components";
import { Overlay } from "../popups/Overlay";
import { ChainConfig } from "@/type/zkkontos";
import { t } from "i18next";
import { ChainItem } from "./ChainItem";
import { CloseButton } from "../button/CloseButton";

const Container = styled.div`
  border-radius: 16px;
  background: var(--White, #fff);
  position: relative;
  padding: 24px 17px;
  width: 70%;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Title = styled.div`
  text-align: center;
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 18px;
  line-height: 22px;
`;

const ItemContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

interface IProps {
  title?: string;
  chains: ChainConfig[];
  onSelect: (chain: ChainConfig) => void;
  onClose: (open: boolean) => void;
}

export const ChainSelectPopup: React.FC<IProps> = ({
  title = t("All Chain"),
  chains,
  onSelect,
  onClose,
}) => {
  return (
    <Overlay>
      <Container>
        <StyledCloseButton
          className="close-btn"
          onClick={() => onClose(false)}
        />

        <Title>{title}</Title>

        <ItemContainer>
          {chains.map((chain) => (
            <ChainItem
              key={chain.chainIndex}
              chain={chain}
              onClick={() => onSelect(chain)}
            />
          ))}
        </ItemContainer>
      </Container>
    </Overlay>
  );
};
