import React, {useState, useRef, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import CSS from 'csstype';
import arrowIco from 'src/assets/icons/arrow13.svg';
import MarketsRankingItem from './MarketsRankingItem';
import {useStores} from "src/hooks/useStore";
import Star from "src/components/star/Star";
import {t} from "i18next";

export const ChainWrapper = styled.div`
  padding-left: 8px;
  margin-right: -16px;

  .itemWrap {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    .scrollItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      .item {
        color: var(--Deep-200, #b2b6bc);
        text-align: center;
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
        padding: 0 10px;
        margin-right: 4px;
        height: 24px;
        line-height: 24px;
        cursor: pointer;
        flex-shrink: 0;
      }

      .item.active {
        border-radius: 4px;
        background: var(--Deep-25, #f5f5f6);
        color: var(--Deep-800, #1a2535);
      }
    }
  }
`;

type IProps = {
  styles?: CSS.Properties;
  itemClick: (item: any) => void;
  selectedChain: string;
}


export function MarketsChainRanking({
                                      styles = {},
                                      itemClick,
                                      selectedChain
                                    }: IProps) {
  const {marketStore} = useStores();

  return (
    <ChainWrapper style={styles}>
      <div className={"itemWrap"}>
        <div className={"scrollItem"}>
          <div
            onClick={() => {
              itemClick({
                chainIndex: "",
              });
            }}
            className={`item ${selectedChain === "" ? "active" : ""}`}
          >
            <span>{t("All")}</span>
          </div>
          {marketStore.chainArr?.map((item, index) => (
            <div
              className={`item ${
                selectedChain === item.chainIndex ? "active" : ""
              }`}
              key={item.symbol}
              onClick={() => {
                itemClick(item);
              }}
            >
              <span>{item.symbol}</span>
            </div>
          ))}
        </div>
      </div>
    </ChainWrapper>
  );
}

export default MarketsChainRanking;
