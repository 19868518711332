import { useTranslation } from "react-i18next";
import styled from "styled-components";
import addIcon from "src/assets/icons/add.svg";
import addActiveIcon from "src/assets/icons/add-active.svg";

const Container = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px dashed var(--Deep-200, #b2b6bc);
  color: var(--Deep-400, #80868f);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  user-select: none;
  cursor: pointer;

  .add-icon {
    width: 16px;
    height: 16px;
    background-image: url(${addIcon});
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center center;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      color: var(--Kontos-Blue, #413dec);
      .add-icon {
        background-image: url(${addActiveIcon});
      }
    }
  }
  &:active {
    border-color: var(--Kontos-Blue, #413dec);
    color: var(--Kontos-Blue, #413dec);
    .add-icon {
      background-image: url(${addActiveIcon});
    }
  }
`;

const Text = styled.span`
  height: 16px;
  line-height: 16px;

  font-family: "HarmonyOS Sans";
  font-size: 14px;
`;

interface IProps {
  onClick: () => void;
}

export const BatchSellPlaceholder: React.FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Container onClick={onClick}>
      <span className="add-icon" />
      <Text>{t("Add assets for sale")}</Text>
    </Container>
  );
};
