import styled from "styled-components";
import { ReactNode, CSSProperties, useRef, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import toast from "src/components/toast/Toast";

const Wrapper = styled.div`
  position: relative;

  .googleLoginWrap {
    position: absolute;
    opacity: 0.0001;
    width: 400px;
    height: 42px;
    z-index: 999;
    left: 0;
    top: 0;
  }
`;

let interval: any = null;

type ButtonProp = {
  children: ReactNode;
  loginSuccessFun: (email: string) => void;
  wrapperStyle?: CSSProperties;
  googleLoginWrapClass?: string;
};

export function isGoogleEmail(email: string): boolean {
  const googleEmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
  return googleEmailRegex.test(email);
}

export const GoogleLoginBtn = ({
  loginSuccessFun,
  children,
  wrapperStyle,
  googleLoginWrapClass,
}: ButtonProp) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isGoogleLoginAvailable, setIsGoogleLoginAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    clearInterval(interval);
    interval = null;
    interval = setInterval(() => {
      // @ts-ignore
      const isSupported = !!window.google?.accounts?.id;
      setIsGoogleLoginAvailable(isSupported);
      if (isSupported) {
        clearInterval(interval);
        interval = null;
      }
    }, 500);
  }, []);

  return (
    <Wrapper style={wrapperStyle} ref={elementRef}>
      {children}
      <div
        className={
          googleLoginWrapClass ? googleLoginWrapClass : "googleLoginWrap"
        }
        onClick={() => {
          if (!isGoogleLoginAvailable) {
            toast({
              type: "warning",
              text: "Google login is being initialized and will be ready in just a few seconds.",
            });
            return;
          }
        }}
      >
        <GoogleLogin
          size={"large"}
          width={elementRef?.current?.offsetWidth + "px" || "400px"}
          onSuccess={(credentialResponses) => {
            const decoded = jwtDecode(credentialResponses?.credential || "");
            // @ts-ignore
            const email = decoded?.email || "";
            if (!isGoogleEmail(email)) {
              toast({
                type: "warning",
                text: "Only Gmail accounts are allowed to login.",
              });
              return;
            }
            loginSuccessFun(email);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap
          ux_mode={"popup"}
        />
      </div>
    </Wrapper>
  );
};
