import ImageWithFallback from "src/components/images/ImageWithFallback";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import defaultDappIcon from "src/assets/icons/dapp/default-dapp.svg";
import { useLongPress } from "react-use";
import deleteIco from "src/assets/icons/dapp/delete-app.svg";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 48px;
  height: 48px;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 15px;
  background-color: var(--Deep-50, #edeef1);
  // border: 1px solid var(--Deep-50, #edeef1);
  user-select: none;
  pointer-events: none;
`;

const Title = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  height: 28px;
  max-width: 79px;
  position: absolute;
  bottom: -32px;
  color: var(--Deep-800, #1a2535);
  text-align: center;
  vertical-align: top;
  font-family: "HarmonyOS Sans SC";
  font-size: 11px;
  font-weight: 400;
`;

const DeleteSign = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: -6px;
  cursor: pointer;
`;

export const DappAppItem: React.FC<{
  className?: string;
  deleteIconClassName?: string;
  icon?: any;
  title?: string;
  showDelete?: boolean;
  onLongPress?: () => void;
  onIconClick?: () => void;
  onDelete?: () => void;
}> = ({
  className,
  deleteIconClassName,
  icon,
  title,
  showDelete = false,
  onLongPress,
  onIconClick,
  onDelete,
}) => {
  const { userStore } = useStores();
  const [longPressTriggered, setLongPressTriggered] = useState(false);

  const handleLongPress = useCallback(
    (e: MouseEvent | TouchEvent) => {
      setLongPressTriggered(true);
      e.stopPropagation();
      onLongPress?.();
    },
    [onLongPress]
  );

  const handleClick = useCallback(() => {
    if (showDelete) {
      return;
    }
    if (longPressTriggered) {
      setLongPressTriggered(false);
      return;
    }
    onIconClick?.();
  }, [longPressTriggered, onIconClick, showDelete]);

  const longPressEvents = useLongPress(handleLongPress, {
    delay: 500,
    isPreventDefault: !userStore.isMobile,
  });

  return (
    <Container {...longPressEvents} className={className} onClick={handleClick}>
      <ImageWithFallback
        src={icon || defaultDappIcon}
        alt="App Icon"
        StyledImg={Icon}
        fallbackSrc={defaultDappIcon}
      />
      {onDelete && showDelete && (
        <DeleteSign
          className={deleteIconClassName}
          src={deleteIco}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        />
      )}
      {title && <Title>{title}</Title>}
    </Container>
  );
};
