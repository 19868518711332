import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ROUTE_SETTINGS_GENERAL_LANG } from "src/router/router-config";
import { observer } from "mobx-react";
import langIcon from "src/assets/icons/settings/language.svg";
import SettingsItem from "src/components/settings/SettingsItem";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  /* padding: 0 16px 76px 16px; */
`;

const Scrollable = styled.div`
  margin-top: 8px;

  flex: 1;
  overflow: auto;
`;

const GeneralSetting: React.FC = () => {
  const navigate = useNavigate();
  const itemList = [
    {
      title: t("Language") as string,
      icon: langIcon,
      onClick: () => {
        navigate(ROUTE_SETTINGS_GENERAL_LANG);
      },
    },
  ];

  return (
    <Container>
      <Header
        title={t("General")}
        enableBack={true}
        // rightBtnText={t("Help?")}
      />
      <Scrollable>
        {itemList.map((item) => (
          <SettingsItem
            key={item.title}
            mainIcon={item.icon}
            title={item.title}
            onClick={item.onClick}
          />
        ))}
      </Scrollable>
    </Container>
  );
};

export default observer(GeneralSetting);
