import styled, {ThemeContext} from "styled-components";
import React, {useState, useEffect, useRef, useContext} from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import CoinbaseSvg from "src/assets/images/trade/coinbase.svg";
import type { CBPayInstanceType, InitOnRampParams } from '@coinbase/cbpay-js';
import { initOnRamp, generateOnRampURL } from '@coinbase/cbpay-js';
import { PopupModal } from "src/components/popups/PopupModal";
import {t} from "i18next";

type InitOnRampOptions = Parameters<typeof initOnRamp>[0];

const Container = styled.div<{ $isOverWidth: boolean }>`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.$isOverWidth
      ? props.theme.colors.__deep_25
      : props.theme.colors.__white} !important;
  #cbpay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .icon {
    margin-left: 50px;
    width: 150px;
    height: auto;
  }
`;

const LoadingGif = styled.img`
  height: 20px;
  width: 100px;
`;

const RampContainer = styled.div<{ $isOverWidth: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  width: min(calc(100vw - 20px), 375px);

  ${({ $isOverWidth }) =>
    $isOverWidth === true
      ? `
      iframe {
          
          border: none;
          box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1), 5px 0 10px rgba(0, 0, 0, 0.1);
        }
      `
      : `
        iframe {
          border: none;
        }
      `}
`;

interface Props {
  address: string;
  quantity: string;
  quantityWithSymbol: string;
  onSuccess: () => void;
  onCanceled?: () => void;
}

const CoinBasePay: React.FC<Props> = ({
  address,
  quantity,
  quantityWithSymbol,
  onSuccess,
  onCanceled,
}) => {
  const { uiStore } = useStores();
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);
  const [onrampInstance, setOnrampInstance] = useState<CBPayInstanceType | undefined>();
  const [url, setUrl] = useState<string>('');
  const theme = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!process.env.REACT_APP_COINBASE_ID)
      throw new Error("REACT_APP_COINBASE_ID is not defined");
    //initOnRamp parameters
    const destinationWallets = [{
      address,
      blockchains: ['polygon'],
      assets: ['USDC']
    }]
    const options: InitOnRampOptions = {
      debug: true,
      appId: process.env.REACT_APP_COINBASE_ID,
      target: "#cbpay-container",
      widgetParameters: {
        destinationWallets
      },
      onSuccess: () => {
        onSuccess();
        // handle navigation when user successfully completes the flow
      },
      onExit: () => {
        // handle navigation from dismiss / exit events due to errors
      },
      onEvent: (event) => {
        // event stream
      },
      experienceLoggedIn: "embedded",
      experienceLoggedOut: "embedded",
    };

    // instance.destroy() should be called before initOnramp if there is already an instance.
    if (onrampInstance) {
      onrampInstance?.destroy();
    }

    // generateOnRampURL parameters by iframe
    // const opts = {
    //   appId: process.env.REACT_APP_COINBASE_ID,
    //   destinationWallets,
    // };
    // const onRampURL = generateOnRampURL(opts);
    // setUrl(onRampURL);
    // setIsReady(true);

    initOnRamp(options, (error, instance) => {
      if (instance) {
        setOnrampInstance(instance);
        setIsReady(true);
        setShowModal(true);
        // instance.open();
      }
    });
  }, [address]);

  const handleClick = () => {
    onrampInstance?.open();
  };

  return (
    <Container $isOverWidth={uiStore.isOverWidth}>
      {uiStore.isOverWidth && (
        <IconContainer>
          <a target={'_blank'} href={url} id="cbpay-button-container">
            <img className="icon" src={CoinbaseSvg} alt=""/>
          </a>
        </IconContainer>
      )}

      <RampContainer $isOverWidth={uiStore.isOverWidth}>
          <LoadingGif src="/static/loading.gif" alt="Loading..." />
      </RampContainer>
      <div onClick={handleClick} style={{height: "10px"}}></div>
      {
        showModal &&
        <PopupModal
          type="warning"
          onConfirm={() => {
            setShowModal(false);
            onSuccess();
          }}
          onCancel={() => {
            setShowModal(false);
            onCanceled && onCanceled();
          }}
          onOutClick={() => {
            setShowModal(false);
          }}
          title={t("Have you finished making the payment?")}
          desc={t("*If you haven't completed the payment, please return to retry.")}
          btnTxt={t("Yes")}
          cancelBtnText={t("No")}
        />
      }
    </Container>
  );
};

export default observer(CoinBasePay);
