import React, { ReactNode } from "react";
import styled from "styled-components";
import syncIcon from "src/assets/icons/sync.svg";
import syncIconHover from "src/assets/icons/sync-hover.svg";
import { t } from "i18next";

interface BackgroundImageProps {
  imageUrl?: string;
}

const Wrapper = styled.div<BackgroundImageProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 4px;
  }

  span {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }
  .sync {
    display: block;
  }
  .syncHover {
    display: none;
  }
  &:hover {
    .sync {
      display: none;
    }
    .syncHover {
      display: block;
    }
    span {
      color: var(--Kontos-Blue, #413dec);
    }
  }
`;

type IProps = {
  click?: () => void;
  styles?: React.CSSProperties;
  className?: string;
  children?: ReactNode;
};

const SyncIcon = ({ click, styles, className, children }: IProps) => {
  return (
    <Wrapper onClick={click} style={styles} className={className}>
      <img src={syncIcon} className={"sync"} alt="icon" />
      <img src={syncIconHover} className={"syncHover"} alt="syncHover icon" />
      <span>{t("Sync")}</span>
      {children}
    </Wrapper>
  );
};

export default SyncIcon;
