import CircleIconPair from "src/components/icons/CircleIconPair";
import { memo } from "react";
import styled from "styled-components";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import switchIcon from "src/assets/icons/trade/switch-receive.svg";
import Skeleton from "react-loading-skeleton";

const Container = styled.div`
  padding: 4px 8px 4px 4px;
  height: 24px;

  border-radius: 99px;
  background: var(--Deep-25, #f5f5f6);

  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .text {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }

  &.active {
    opacity: 0.9;
  }
`;

interface Props {
  mainIcon: any;
  subIcon: any;
  text?: string;
  onClick: () => void;
}

export const ReceivingAssetButtonV2: React.FC<Props> = memo(
  ({ mainIcon, subIcon, text, onClick }) => {
    return (
      <Container onClick={onClick}>
        <CircleIconPair
          mainIcon={mainIcon || defaultTokenIcon}
          mainIconFallbackSrc={defaultTokenIcon}
          subIcon={subIcon || defaultChainIcon}
          subIconFallbackSrc={defaultChainIcon}
          mainWidth={16}
          mainHeight={16}
          subWidth={12}
          subHeight={12}
          totalWidth={22}
          totalHeight={16}
        />
        <div className="text">
          {text ? text : <Skeleton count={1} style={{ width: "50px" }} />}
        </div>
        <img className="icon" src={switchIcon} alt="" />
      </Container>
    );
  }
);
