import styled from "styled-components";
import searchIco from "src/assets/icons/trade/trade-search.svg";

export const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  background-image: url(${searchIco});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 8px 30px 8px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    border-width: 2px;
    outline: none;
    background-color: ${(props) => props.theme.colors.__white};
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

export const ClearBtn = styled.img`
  width: 16px;
  height: 16px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.9;
  }
`;
