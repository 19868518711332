import styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import addIcon from "src/assets/icons/trade/add.svg";
import addActiveIcon from "src/assets/icons/trade/add-active.svg";
import minusIcon from "src/assets/icons/trade/minus.svg";
import minusActiveIcon from "src/assets/icons/trade/minus-active.svg";
import { KontosButton } from "src/components/button/KontosButton";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_SLIPPAGE } from "src/config";

const MAX = 50;
const MIN = 0.1;
const DISPLAY_DECIMAL_PRECISION = 2;
const UNIT = 0.1;
const RATIO = 100;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  margin: 0 24px 8px 24px;
  color: var(--Deep-800, #1a2535);
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 20px;
  line-height: 24px;
`;

const Desc = styled.div`
  margin: 10px 24px;
  color: var(--Deep-400, #80868f);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const SetupBox = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OperateButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: none;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  background-position: center center;
`;

const AddButton = styled(OperateButton)`
  background-image: url(${addIcon});

  @media (hover: hover) {
    &:hover {
      background-image: url(${addActiveIcon});
    }
  }

  &:active {
    background-image: url(${addActiveIcon});
  }
`;

const MinusButton = styled(OperateButton)`
  background-image: url(${minusIcon});

  @media (hover: hover) {
    &:hover {
      background-image: url(${minusActiveIcon});
    }
  }

  &:active {
    background-image: url(${minusActiveIcon});
  }
`;

const InputBox = styled.div<{ $isChanged: boolean }>`
  margin: 0 10px;
  padding: 10px 16px;
  border-radius: 99px;
  border: 1px solid
    ${(props) =>
      props.$isChanged
        ? "var(--Deep-800, #1a2535)"
        : "var(--Deep-100, #cccfd2)"};
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AutoButton = styled.span`
  width: 38px;
  text-align: center;
  color: var(--Kontos-Blue, #413dec);
  font-family: "HarmonyOS Sans Bold";
  font-size: 16px;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }
  &:active {
    opacity: 0.9;
  }
`;

const Input = styled.input<{ $isActive: boolean }>`
  width: 148px;
  outline: none;
  border: none;
  padding: 0;
  text-align: center;
  color: ${(props) =>
    props.$isActive ? "var(--Deep-800, #1A2535)" : "var(--Deep-400, #80868F)"};
  font-family: "HarmonyOS Sans SC";
  font-size: 16px;
`;

const RatioSpan = styled.span`
  width: 38px;
  text-align: center;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 16px;
`;

const MinValueSpan = styled.span`
  margin: 10px 24px;
  color: var(--Deep-400, #80868f);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;

  .number {
    color: var(--Deep-800, #1a2535);
  }

  flex: 1;
`;

const ConfirmButton = styled(KontosButton)`
  margin: 16px 20px;
  width: calc(100% - 40px);
  padding: 17px !important;
`;

interface IProps {
  initSlippage: KontosNumber;
  symbol?: string;
  rawAmount?: KontosNumber;
  onSubmit: (slippage: KontosNumber) => void;
}

export const SlippageSetup: React.FC<IProps> = ({
  initSlippage,
  symbol,
  rawAmount,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(
    initSlippage.multiply(RATIO).toFixed(DISPLAY_DECIMAL_PRECISION)
  );

  const slippage: KontosNumber = useMemo(() => {
    return new KontosNumber(inputValue);
  }, [inputValue]);

  const isActive: boolean = useMemo(() => {
    return !slippage.eq(initSlippage);
  }, [initSlippage, slippage]);

  const amount: KontosNumber | undefined = useMemo(() => {
    return rawAmount?.multiply(
      new KontosNumber(1).minus(slippage.multiply(0.01))
    );
  }, [rawAmount, slippage]);

  const add = useCallback(() => {
    if (slippage.lte(MAX - UNIT)) {
      setInputValue((prev) =>
        (parseFloat(prev) + UNIT).toFixed(DISPLAY_DECIMAL_PRECISION)
      );
    }
  }, [slippage]);

  const minus = useCallback(() => {
    if (slippage.gte(MIN + UNIT)) {
      setInputValue((prev) =>
        (parseFloat(prev) - UNIT).toFixed(DISPLAY_DECIMAL_PRECISION)
      );
    }
  }, [slippage]);

  const reset = useCallback(() => {
    setInputValue(
      DEFAULT_SLIPPAGE.multiply(RATIO).toFixed(DISPLAY_DECIMAL_PRECISION)
    );
  }, []);

  const isChanged = useMemo(() => {
    return !DEFAULT_SLIPPAGE.multiply(100).eq(inputValue);
  }, [inputValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;

      if (!value.match(/^\d*\.?\d*$/)) return;

      if (value.includes(".")) {
        const valueArr = value.split(".");
        setInputValue(valueArr?.[0] + "." + valueArr?.[1]?.slice(0, 2));
        return;
      }

      let numericValue = parseFloat(value);

      if (isNaN(numericValue) || value.trim() === "") {
        setInputValue("");
        return;
      }

      if (numericValue < 0.1 && numericValue !== 0) numericValue = 0.1;
      else if (numericValue > 50) numericValue = 50;

      setInputValue(numericValue.toString());
    },
    []
  );

  const handleBlur = useCallback(() => {
    let numericValue: number = parseFloat(inputValue) || 0;
    if (numericValue > MAX) {
      numericValue = MAX;
    } else if (numericValue < MIN) {
      numericValue = MIN;
    }
    setInputValue(numericValue.toFixed(DISPLAY_DECIMAL_PRECISION - 1) + "0");
  }, [inputValue]);

  return (
    <Wrapper>
      <Title>{t("Slippage Settings")}</Title>

      <Desc>
        {t(
          "Your transaction will revert if the price changes more than the slippage percentage."
        )}
      </Desc>

      <SetupBox>
        <MinusButton onClick={minus} />
        <InputBox $isChanged={isChanged}>
          <AutoButton onClick={reset}>{t("Auto")}</AutoButton>
          <Input
            $isActive={isActive}
            value={inputValue}
            type="text"
            inputMode="decimal"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <RatioSpan>%</RatioSpan>
        </InputBox>

        <AddButton onClick={add} />
      </SetupBox>

      <MinValueSpan>
        {amount && symbol && (
          <Trans i18nKey={"trans-slippage-setup"}>
            Receive at least
            <span className="number">
              {{ amount: amount?.toFormat() } as any}
            </span>
            {{ symbol }}
          </Trans>
        )}
      </MinValueSpan>

      <ConfirmButton onClick={() => onSubmit(slippage.divide(RATIO))}>
        {t("Confirm")}
      </ConfirmButton>
    </Wrapper>
  );
};
