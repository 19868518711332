import React, { forwardRef } from "react";
import styled from "styled-components";
import { TooltipRenderProps } from "react-joyride";
import tipsIcon from "src/assets/icons/tips.svg";
import { useTranslation } from "react-i18next";
import { KontosButton } from "../button/KontosButton";

export const KontosText = styled.span`
  color: var(--Kontos-Blue, #413dec);
`;

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background: var(--White, #fff);

  max-width: 320px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  user-select: none;
`;

const Header = styled.div`
  display: flex;
  gap: 12px;

  .header-icon {
    width: 28px;
    height: 16px;
  }

  .header-title {
    flex: 1;
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 14px;
    line-height: 130%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  line-height: 130%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PageNumber = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

const FooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const SkipButton = styled.span`
  color: var(--Deep-200, #b2b6bc);
  font-family: "HarmonyOS Sans Bold";
  font-size: 12px;
  white-space: nowrap;

  cursor: pointer;
`;

const PrimaryButton = styled(KontosButton)`
  padding: 5px;
  display: flex;
  height: 24px;
  min-width: 80px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
`;

export const JoyRideToolTip: React.FC<TooltipRenderProps> = forwardRef(
  (
    {
      backProps,
      continuous,
      index,
      size,
      isLastStep,
      primaryProps,
      skipProps,
      step,
      tooltipProps,
    }: TooltipRenderProps,
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Container
        {...tooltipProps}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Header>
          <img className="header-icon" src={tipsIcon} alt="tip" />
          <span className="header-title">{step.title}</span>
        </Header>
        <Content>{step.content}</Content>
        <Footer>
          <PageNumber>{`${index + 1}/${size}`}</PageNumber>

          <FooterRight>
            {!isLastStep && <SkipButton {...skipProps}>{t("Skip")}</SkipButton>}

            <PrimaryButton {...primaryProps}>
              {isLastStep ? t("Got it") : t("Next")}
            </PrimaryButton>
          </FooterRight>
        </Footer>
      </Container>
    );
  }
);
