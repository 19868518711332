import Header from "src/components/common/header";
import styled from "styled-components";
import { DiscoverTrendingList } from "../trending/DiscoverTrendingList";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { ClearBtn, SearchInput } from "src/components/input-search/InputSearch";
import clearIcon from "src/components/input/close.svg";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import * as DappService from "src/service/dapp-service";
import { Dapp } from "@/type/dapp";
import { Scrollable } from "src/components/scrollable/Scrollable";
import NoDataV2 from "src/components/no-data/NoDataV2";

const LIMIT = 15;
const DEBOUNCE_TIME = 500;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .dapp-fav-search {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;

    .dapp-fav-search-input {
      height: 40px;
      flex: 1;
      position: relative;

      .dapp-fav-search-input-clear-btn {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .dapp-fav-search-cancel-btn {
      margin-left: 16px;
      color: var(--Kontos-Blue, #413dec);
      text-align: right;
      font-family: "HarmonyOS Sans Bold";
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.9;
      }
    }
  }

  .dapp-fav-content-palceholder {
    margin-top: 56px;
    color: var(--Deep-100, #cccfd2);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
  }
`;

const StyledScrollable = styled(Scrollable)`
  margin-top: 16px;
`;

interface IProps {
  onClose: () => void;
}

export const DiscoverSearch: React.FC<IProps> = observer(({ onClose }) => {
  const { t } = useTranslation();
  const { discoverStore } = useStores();
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<Dapp[]>(discoverStore.dappTrendings);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const fetchMoreData = useCallback(async () => {
    console.log(1);
    try {
      const { dapps, total } = await DappService.searchTrendings(
        query,
        results.length,
        LIMIT
      );
      setResults((prevResults) => [...prevResults, ...dapps] || []);
      setHasMore(results.length + dapps?.length < total);
    } catch (error) {
      console.error("Failed to fetch dapps:", error);
    }
  }, [query, results.length]);

  const [, cancel] = useDebounce(
    async () => {
      try {
        if (query.trim()) {
          const { dapps, total } = await DappService.searchTrendings(
            query,
            0,
            LIMIT
          );
          setResults(dapps || []);
          setHasMore(dapps?.length < total);
        } else {
          setResults(discoverStore.dappTrendings);
          setHasMore(discoverStore.hasMoreDappTrendings);
        }
      } catch (e) {
        console.error("Failed to fetch dapps:", e);
      }
    },
    DEBOUNCE_TIME,
    [query]
  );

  useEffect(() => {
    return () => cancel();
  }, [cancel]);

  useEffect(() => {
    inputRef.current?.focus();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  return (
    <Container>
      <Header
        title={t("Search")}
        padding="2px 24px 0px 24px"
        callBack={onClose}
        rightBtnText={t("Cancel")}
        rightBtnCallBack={onClose}
      />

      <div className="dapp-fav-search">
        <div className="dapp-fav-search-input">
          <SearchInput
            ref={inputRef}
            autoFocus
            type="text"
            value={query}
            placeholder={t("Enter DApp name or URL")}
            onChange={(e) => setQuery(e.target.value)}
          />
          {query.length > 0 && (
            <ClearBtn
              className="dapp-fav-search-input-clear-btn"
              src={clearIcon}
              onClick={(e) => {
                e.stopPropagation();
                setQuery("");
              }}
            />
          )}
        </div>
      </div>

      <StyledScrollable>
        {results.length > 0 ? (
          <DiscoverTrendingList
            dapps={results}
            hasMore={hasMore}
            hasRank={query === "" ? true : false}
            onNext={fetchMoreData}
          />
        ) : (
          <div style={{ height: "300px" }}>
            <NoDataV2 text="No found" />
          </div>
        )}
      </StyledScrollable>
    </Container>
  );
});
