import goodIcon from "src/assets/icons/payment/good.svg";
import secureIcon from "src/assets/icons/payment/secure.svg";
import securePlusIcon from "src/assets/icons/payment/secure-plus.svg";
import alertIcon from "src/assets/icons/payment/alert.svg";
import alertPlusIcon from "src/assets/icons/payment/alert-plus.svg";
import riskyIcon from "src/assets/icons/payment/risky.svg";
import riskyPlusIcon from "src/assets/icons/payment/risky-plus.svg";
import styled from "styled-components";
import { SecurityTip, SecurityTipType } from "src/components/tips/SecurityTip";
// import { useStores } from "src/hooks/useStore";
// import { GlobalTipClass } from "src/pages/GlobalTips";
// import { useState } from "react";
import Floater from "react-floater";

const Icon = styled.img<{ size: number }>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  cursor: pointer;
`;

const getSecurityIcon = (
  isGreatLiquidity: number,
  securityLevel: number
): any => {
  if (isGreatLiquidity === 1 && securityLevel === -1) {
    return goodIcon;
  }
  if (isGreatLiquidity === 0 && securityLevel === 0) {
    return secureIcon;
  }
  if (isGreatLiquidity === 0 && securityLevel === 1) {
    return alertIcon;
  }
  if (isGreatLiquidity === 0 && securityLevel === 2) {
    return riskyIcon;
  }
  if (isGreatLiquidity === 1 && securityLevel === 0) {
    return securePlusIcon;
  }
  if (isGreatLiquidity === 1 && securityLevel === 1) {
    return alertPlusIcon;
  }
  if (isGreatLiquidity === 1 && securityLevel === 2) {
    return riskyPlusIcon;
  }
};

const getSecurityTipType = (
  isGreatLiquidity: number,
  securityLevel: number
): SecurityTipType | undefined => {
  if (isGreatLiquidity === 1 && securityLevel === -1) {
    return SecurityTipType.Good;
  }
  if (isGreatLiquidity === 0 && securityLevel === 0) {
    return SecurityTipType.Secure;
  }
  if (isGreatLiquidity === 0 && securityLevel === 1) {
    return SecurityTipType.Alert;
  }
  if (isGreatLiquidity === 0 && securityLevel === 2) {
    return SecurityTipType.Risky;
  }
  if (isGreatLiquidity === 1 && securityLevel === 0) {
    return SecurityTipType.SecurePlus;
  }
  if (isGreatLiquidity === 1 && securityLevel === 1) {
    return SecurityTipType.AlertPlus;
  }
  if (isGreatLiquidity === 1 && securityLevel === 2) {
    return SecurityTipType.RiskyPlus;
  }
};

interface IProps {
  isGreatLiquidity: number;
  securityLevel: number;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
  disableHoverToClick?: boolean;
}

export const AssetSecurityIcon: React.FC<IProps> = ({
  isGreatLiquidity,
  securityLevel,
  size = 12,
  className,
  style,
  disableHoverToClick = false,
}) => {
  // const { tipStore } = useStores();
  // const [hover, setHover] = useState<boolean>(false);

  const icon = getSecurityIcon(isGreatLiquidity, securityLevel);

  if (!icon) {
    return <></>;
  }

  return (
    <Floater
      event="hover"
      eventDelay={0}
      component={
        <SecurityTip
          type={getSecurityTipType(isGreatLiquidity, securityLevel)}
        />
      }
      styles={{
        options: {
          zIndex: 20,
        },
        arrow: {
          color: "var(--Deep-800, #1a2535)",
          length: 5,
          spread: 11,
        },
      }}
      offset={3}
      placement={"top"}
      disableHoverToClick={disableHoverToClick}
    >
      <Icon
        // className={`${hover ? GlobalTipClass.AssetSecurity : ""} ${
        //   className ? className : ""
        // }`}
        className={className}
        style={style}
        src={icon}
        size={size}
        // onMouseEnter={() => {
        //   setHover(true);
        //   setTimeout(() => {
        //     tipStore.setAssetTip(
        //       getSecurityTipType(isGreatLiquidity, securityLevel)
        //     );
        //   }, 100);
        // }}
        // onMouseLeave={() => {
        //   tipStore.setAssetTip(undefined);
        //   setHover(false);
        // }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    </Floater>
  );
};
