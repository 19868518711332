import { CloseButton } from "src/components/button/CloseButton";
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import powerByBrandImage from "src/assets/images/brands/0x-scope-revert.svg";
import { EllipsisPlaceholderV3 } from "src/components/load-placeholder/EllipsisPlaceholder";
import { TokenOrChainIcon } from "src/components/icons/TokenOrChainIcon";
import { Trans, useTranslation } from "react-i18next";
import sessionKeeper from "src/store/sessionKeeper";
import { isSameFtAsset } from "src/utils/zkkontosHelper";
import { callFtassetDetail } from "src/apis/asset-apis";
import closeIco from "src/assets/icons/close5.svg";
import {
  JoyRideToolTip,
  KontosText,
} from "src/components/onboarding/JoyRideTooltip";
import { useSetState } from "react-use";
import Joyride, { CallBackProps, Step, STATUS } from "react-joyride";
import { useStores } from "src/hooks/useStore";
import { OnboardingType } from "src/store/localKeeper";
import { observer } from "mobx-react";

const BorderBox = styled.div<{ $enableClick?: boolean; $showAi?: boolean }>`
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  ${(props) =>
    !props.$showAi ? "border-bottom: 2px solid #fff; border-radius: 8px" : ""}
`;

const BottomBox = styled.div`
  border-bottom: 2px solid #1a2535;
  border-left: 2px solid #1a2535;
  border-right: 2px solid #1a2535;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #1a2535;

  position: relative;
  padding: 4px 17px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
`;

const Container = styled.div<{ $enableClick?: boolean; $showAi?: boolean }>`
  width: 100%;
  cursor: ${(props) => (props.$enableClick ? "pointer" : "default")};
  @media (hover: hover) {
    &:hover {
      ${BorderBox} {
        border-left-color: ${(props) =>
          props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#fff"};
        border-right-color: ${(props) =>
          props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#fff"};
        border-top-color: ${(props) =>
          props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#fff"};
        border-bottom-color: ${(props) =>
          props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#fff"};
      }
      ${BottomBox} {
        border-left-color: ${(props) =>
          props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#1a2535"};
        border-right-color: ${(props) =>
          props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#1a2535"};
        border-bottom-color: ${(props) =>
          props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#f1a2535ff"};
      }
    }
  }

  &:active {
    ${BorderBox} {
      border-left-color: ${(props) =>
        props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#fff"};
      border-right-color: ${(props) =>
        props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#fff"};
      border-top-color: ${(props) =>
        props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#fff"};
    }
    ${BottomBox} {
      border-left-color: ${(props) =>
        props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#1a2535"};
      border-right-color: ${(props) =>
        props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#1a2535"};
      border-bottom-color: ${(props) =>
        props.$enableClick ? "var(--Kontos-Blue, #413dec)" : "#1a2535"};
    }
  }
`;

const PowerByImg = styled.img`
  width: 66px;
  height: 16px;
`;

const AssetBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  overflow: hidden;
  color: var(--White, #fff);
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans Bold";
  font-size: 10px;

  line-height: 12px;
`;

const ScoreText = styled.span`
  color: var(--White, #fff);
  font-family: "HarmonyOS Sans Bold";
  font-size: 10px;
  line-height: 13px;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
`;

interface IProps {
  enableClick?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
  assetSymbol?: string;
  assetImageUrl?: any;
  assetChainIndex?: string;
  assetAddress?: string;
  showAi?: boolean;
  onBottomClick?: () => void;
  onClose?: () => void;
}

interface AiScoreOnboardingState {
  aiScoreRun: boolean;
  aiScoreSteps: Step[];
  aiScoreSpotLightRadius: string;
}

export const TradeAiPanel: React.FC<IProps> = observer(
  ({
    enableClick,
    className,
    style,
    children,
    assetSymbol,
    assetImageUrl,
    assetChainIndex,
    assetAddress,
    showAi,
    onBottomClick,
    onClose,
  }) => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    const [score, setScore] = useState<number>(-1);
    const [requesting, setRequesting] = useState<boolean>(false);
    const [
      { aiScoreRun, aiScoreSpotLightRadius, aiScoreSteps },
      setAiScoreOnboardingState,
    ] = useSetState<AiScoreOnboardingState>({
      aiScoreRun: false,
      aiScoreSpotLightRadius: "8px",
      aiScoreSteps: [
        {
          content: (
            <div>
              <Trans i18nKey={"trans-ai-score-onboarding-step-1"}>
                Kontos has integrated <KontosText>AI analytics</KontosText> into
                our system! Click here to quickly view AI analysis of the token.
                You can adjust the AI score display settings at any time from
                the top right corner of the trading interface.
              </Trans>
            </div>
          ),
          placement: "bottom",
          target: ".ai-score-onboarding-helper",
          title: t("AI scoring feature is now live!"),
          disableBeacon: true,
          spotlightPadding: 8,
          offset: 7,
        },
      ],
    });

    const handleAiScoreJoyrideCallback = (data: CallBackProps) => {
      const { status } = data;

      if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
        userStore.markOnboardingFinished(OnboardingType.AiScore);
      }
    };

    useEffect(() => {
      const fetchAndSetAiScore = async () => {
        if (!assetChainIndex || !assetAddress) {
          return;
        }

        try {
          setRequesting(true);
          const { scopeChatAiScore } = await callFtassetDetail({
            chainIndex: assetChainIndex,
            ftAssetAddress: assetAddress,
            scopeChatAi: true,
          });
          setScore(scopeChatAiScore);
          sessionKeeper.setFtAssetAiScore({
            chainIndex: assetChainIndex,
            ftAssetAddress: assetAddress,
            score: scopeChatAiScore,
            expiredAt: Date.now() + 5 * 60 * 1000,
          });
        } catch (e) {
          console.warn("Failed to load ai score");
          setScore(-1);
        } finally {
          setRequesting(false);
        }
      };

      if (showAi && assetChainIndex && assetAddress) {
        setAiScoreOnboardingState({ aiScoreRun: true });
        const arr = sessionKeeper.getFtAssetAiScore();
        const existingOne = arr.find((item) =>
          isSameFtAsset(
            { chainIndex: assetChainIndex, address: assetAddress },
            { chainIndex: item.chainIndex, address: item.ftAssetAddress }
          )
        );
        if (existingOne) {
          setScore(existingOne.score);
        } else {
          fetchAndSetAiScore();
        }
      } else {
        setScore(-1);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetAddress, assetChainIndex, showAi]);

    return (
      <Container
        className={className}
        style={style}
        $enableClick={enableClick}
        $showAi={showAi}
      >
        <BorderBox $enableClick={enableClick} $showAi={showAi}>
          {children}
        </BorderBox>
        {showAi && (
          <BottomBox
            className="ai-score-onboarding-helper"
            onClick={(e) => {
              e.stopPropagation();
              onBottomClick?.();
            }}
          >
            <PowerByImg src={powerByBrandImage} />
            {requesting ? (
              <EllipsisPlaceholderV3 />
            ) : (
              <>
                <AssetBox>
                  <TokenOrChainIcon
                    size={12}
                    type="token"
                    src={assetImageUrl}
                  />
                  <span>{assetSymbol || "-"}</span>
                </AssetBox>
                <ScoreText>{`${t("AI Score: ")}${
                  score >= 0 && score <= 100 ? score : "N/A"
                }`}</ScoreText>
              </>
            )}
            <StyledCloseButton
              onClick={(e) => {
                e.stopPropagation();
                onClose?.();
              }}
              size={16}
              closeIco={closeIco}
              $lazy
            />
          </BottomBox>
        )}

        {!userStore.needOnboardingPayment &&
          userStore.needOnboardingAiScore && (
            <Joyride
              callback={handleAiScoreJoyrideCallback}
              disableCloseOnEsc
              disableOverlayClose
              disableScrolling
              continuous
              run={aiScoreRun}
              showProgress
              showSkipButton
              steps={aiScoreSteps}
              floaterProps={{
                styles: {
                  arrow: {
                    color: "#fff",
                    spread: 20,
                    length: 14,
                  },
                },
              }}
              styles={{
                options: {
                  zIndex: 2000000,
                },
                spotlight: {
                  borderRadius: aiScoreSpotLightRadius,
                },
                overlay: {
                  backgroundColor: "rgba(0, 13, 31, 0.30)",
                },
              }}
              tooltipComponent={JoyRideToolTip}
            />
          )}
      </Container>
    );
  }
);
