import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { EXPLORER_KONTOS_URL } from "src/config";
import { API } from "./core/API";
import { MediaInfo } from "./types";

export interface ReqEmailRegisterCode {
  account: string;
  email: string;
}

export interface RespEmailRegisterCode {
  success: boolean;
  retryTime: number;
}

export const callEmailRegisterCode = async (
  data: ReqEmailRegisterCode,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespEmailRegisterCode> => {
  return await request<ReqEmailRegisterCode, RespEmailRegisterCode>(
    API.aa.emailRegisterCode,
    data,
    endpoint
  );
};

export interface ReqEmailRegisterVerify {
  account: string;
  code: string;
}

export interface RespEmailRegisterVerify {
  success: boolean;
  message: string;
}

export const callEmailRegisterVerify = async (
  data: ReqEmailRegisterVerify,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespEmailRegisterVerify> => {
  return await request<ReqEmailRegisterVerify, RespEmailRegisterVerify>(
    API.aa.emailRegisterVerify,
    data,
    endpoint
  );
};

export interface ReqFtAssetDetail {
  coinGeckoCoinId?: string;
  chainIndex: string;
  ftAssetAddress: string;
  scopeChatAi: boolean;
}

export interface RespFtAssetDetail {
  description: string;
  medias: MediaInfo[];
  scopeChatAiData: string;
  scopeChatAiScore: number;
}

export const callFtassetDetail = async (
  data: ReqFtAssetDetail,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespFtAssetDetail> => {
  return await request<ReqFtAssetDetail, RespFtAssetDetail>(
    API.asset.ftassetDetail,
    data,
    endpoint
  );
};
