import { makeAutoObservable } from "mobx";
import { UiStore } from "../UiStore";

export class UiSettingStore {
  uiStore: UiStore;
  isAddAccountModalOpen: boolean = false;
  isRemoveAccountModalOpen: boolean = false;
  isRecoverDetailModalOpen: boolean = false;
  // trade
  showTradeSettingSheet: boolean = false;
  // account
  showAccountSettingSheet: boolean = false;

  get canClose(): boolean {
    return (
      !this.isAddAccountModalOpen &&
      !this.isRemoveAccountModalOpen &&
      !this.isRecoverDetailModalOpen
    );
  }

  constructor(uiStore: UiStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.uiStore = uiStore;
  }

  reset = () => {
    this.isAddAccountModalOpen = false;
    this.isRemoveAccountModalOpen = false;
    this.isRecoverDetailModalOpen = false;
  };

  showAddAccountModal = () => {
    this.isAddAccountModalOpen = true;
  };
  closeAddAccountModal = () => {
    this.isAddAccountModalOpen = false;
  };

  showRemoveAccountModal = () => {
    this.isRemoveAccountModalOpen = true;
  };
  closeRemoveAccountModal = () => {
    this.isRemoveAccountModalOpen = false;
  };

  showRecoverDetailModall = () => {
    this.isRecoverDetailModalOpen = true;
  };
  closeRecoverDetailModal = () => {
    this.isRecoverDetailModalOpen = false;
  };

  openTradeSettingSheet = () => {
    this.showTradeSettingSheet = true;
  };
  closeTradeSettingSheet = () => {
    this.showTradeSettingSheet = false;
  };

  openAccountSettingSheet = () => {
    this.showAccountSettingSheet = true;
  };
  closeAccountSettingSheet = () => {
    this.showAccountSettingSheet = false;
  };
}
