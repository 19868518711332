import React from 'react';
import styled from 'styled-components';
import CSS from 'csstype';
import poweredGoPlus from "src/assets/images/PoweredGoPlus.png";

const Wrapper = styled.div`
  color: var(--Deep-400, #80868F);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 80px;
    height: 16px;
    margin-left: 10px;
  }
`;

type IProps = {
  styles?: CSS.Properties
}

export function PoweredGoPlus({styles = {}}: IProps) {
  return (
    <Wrapper style={styles}>
      Powered by
      <img src={poweredGoPlus} />
    </Wrapper>
  );
}

export default PoweredGoPlus;
