import { motion } from "framer-motion";
import React from "react";

const INTERVAL_SECOND = 1;

interface IProps {
  seconds: number;
  radius?: number;
  strokeWidth?: number;
  color?: string;
  bgColor?: string;
  loading?: boolean;
}

export const Timer: React.FC<IProps> = ({
  seconds,
  radius = 7, // Circle radius
  strokeWidth = 2, // Circle line width
  color = "#CCCFD2",
  bgColor = "#80868F",
  loading,
}) => {
  const normalizedRadius = radius - strokeWidth * 0.5;
  const circumference = normalizedRadius * 2 * Math.PI;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        stroke={bgColor}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <motion.circle
        strokeDasharray={circumference}
        strokeDashoffset={loading ? 0 : circumference}
        animate={!loading ? { strokeDashoffset: 0 } : undefined}
        transition={
          !loading
            ? {
                duration: (seconds - 1.5) * INTERVAL_SECOND,
                ease: "linear",
              }
            : undefined
        }
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        style={{ transform: "rotate(-90deg)", transformOrigin: "center" }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};
