import styled from "styled-components";
import warnIco from "src/assets/icons/warn1.svg"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: rgba(250, 173, 20, 0.05);
  padding: 8px 20px;
  
  img {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  p {
    color: var(--Warning, #FAAD14);
    font-family: "HarmonyOS Sans SC";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 15.4px */
  }
`;


interface IProps {
  styles?: React.CSSProperties;
  text: string;
}

const Warn = ({ text, styles}: IProps)=> {
  return (
    <Wrapper style={{
      ...styles
    }}>
      <img src={warnIco} alt="star" />
      <p>{text}</p>
    </Wrapper>
  );
};

export default Warn;
