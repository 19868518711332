import styled from "styled-components";
import guardianInfoIco from "src/assets/icons/guardianInfo.svg";
import GuardianItem from "src/components/guardian/GuardianItem";
import guardianAvatarIco from "src/assets/images/guardian-avatar.svg";
import { useCallback } from "react";
import { GuardianEditType } from "./EditGuardians";
import { isSameName } from "src/utils/zkkontosHelper";
import { KontosButton } from "src/components/button/KontosButton";
import { t } from "i18next";
import AddButton from "./AddButton";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  flex: 1;
  height: 100%;
  position: relative;
  .header {
  }
`;

const GuardianDescription = styled.div`
  color: ${(props) => props.theme.colors.__deep_800};
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-align: center;

  padding: 20px 30px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > .icon {
    margin-bottom: 7px;
    width: 29px;
    height: 17px;
  }

  > div .keyword {
    color: var(--Kontos-Blue, #413dec);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
  }
`;

const Scrollable = styled.div`
  overflow: auto;
  margin: 16px 0;
  padding: 0 20px;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
`;

const StyledKontosButton = styled(KontosButton)`
  position: absolute;
  bottom: 16px;
  left: 20px;

  z-index: 1;
  width: calc(100% - 40px);
`;

interface Props {
  guardians: GuardianEditType[];
  rawGuardianList: string[];
  onEdit: (guardian: GuardianEditType) => void;
  onRemove: (guardian: GuardianEditType) => void;
  onAdd: () => void;
  onNext: () => void;
}

const EditGuardiansView: React.FC<Props> = ({
  guardians,
  rawGuardianList,
  onEdit,
  onRemove,
  onAdd,
  onNext,
}) => {
  const { userStore } = useStores();
  const rawThresHolds: number =
    userStore?.accountInfo?.actionThreshold &&
    userStore.accountInfo.actionThreshold >= 2
      ? userStore.accountInfo.actionThreshold
      : 2;

  const handleEditClick = useCallback(
    (guardian: GuardianEditType) => {
      onEdit(guardian);
    },
    [onEdit]
  );

  const handleRemoveClick = useCallback(
    (guardian: GuardianEditType) => {
      onRemove(guardian);
    },
    [onRemove]
  );

  const handleAddClick = useCallback(() => {
    onAdd();
  }, [onAdd]);

  const handleNextClick = useCallback(() => {
    onNext();
  }, [onNext]);

  return (
    <Container>
      <GuardianDescription>
        <img className="icon" src={guardianInfoIco} alt="" />
        <Trans i18nKey={"trans-rawThresHolds"}>
          <div>
            Recover your account requires approval from any{" "}
            <span className="keyword">{{ rawThresHolds } as any}</span> of the
            following Guardians.
          </div>
        </Trans>
      </GuardianDescription>
      <Scrollable>
        {guardians.map((guardian) => (
          <GuardianItem
            key={guardian.name}
            name={guardian.name}
            avatar={guardianAvatarIco}
            isNew={
              !rawGuardianList.find((rawName) =>
                isSameName(rawName, guardian.name)
              )
            }
            onEdit={() => {
              handleEditClick(guardian);
            }}
            onRemove={() => {
              handleRemoveClick(guardian);
            }}
          />
        ))}
      </Scrollable>
      <AddButtonWrapper>
        <AddButton onClick={handleAddClick}>{t("Add Guardians")}</AddButton>
      </AddButtonWrapper>
      <StyledKontosButton onClick={handleNextClick}>
        {t("Next")}
      </StyledKontosButton>
    </Container>
  );
};

export default EditGuardiansView;
