import React from "react";
import styled from "styled-components";
import warn3Ico from "src/assets/icons/warn3.svg";
import noDataBgIco from "src/assets/icons/no-data-bg.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${noDataBgIco}) repeat-x left center;
  .no-data__image{
    width: 16px;
    height: 16px;
    margin-bottom: 8px;
  }
  .no-data__text{
    color: var(--Deep-100, #CCCFD2);
    /* Smaller */
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }
`;

interface Props {
  style?: React.CSSProperties;
  className?: string;
  text?: string;
}

export default function NoDataChart({ style, className, text }: Props) {
  return (
    <Wrapper style={{ ...style }} className={className}>
      <img src={warn3Ico} className="no-data__image" alt="no data" />
      <div className="no-data__text">{text}</div>
    </Wrapper>
  );
}
