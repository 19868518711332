import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import fireworksBgImg from "src/assets/images/event/eb/fireworks-bg.png";
import { KontosButton } from "src/components/button/KontosButton";
import { BASIC_SHEET_INDEX } from "src/config";

const Container = styled.div`
  background-image: url(${fireworksBgImg});
  background-repeat: no-repeat;
  background-size: 125% 80%;
  background-position: -25px 0;

  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }

  > .desc {
    margin-top: 12px;
    padding: 0 20px;
    color: var(--Deep-800, #1a2535);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;

    > span {
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans Bold";
      font-size: 14px;
      line-height: 140%;
    }
  }

  > .img {
    margin-top: 34px;
    width: 72px;
    height: 72px;
  }

  > .title {
    margin-top: 14px;
    height: 24px;
    color: var(--Kontos-Blue, #413dec);
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 20px;
    line-height: 24px;
  }

  > .sub-title {
    margin-top: 2px;
    color: var(--Deep-400, #80868f);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  > .ok-btn {
    margin-top: 16px;
  }
`;

interface Props {
  title: string;
  subTitle?: string;
  img: any;
  onClose: (open: boolean) => void;
}

export const EbOpenOrbSuccessPopup: React.FC<Props> = ({
  title,
  subTitle,
  img,
  onClose,
}) => {
  const { t } = useTranslation();
  const mainTitle = subTitle ? title + " " + subTitle : title;

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <div className="title">{t("Congratulations!")}</div>
        <div className="desc">
          <Trans i18nKey={"trans-eb-open-orb-congratulation"}>
            You've received a reward of <span>{{ mainTitle } as any}</span> from
            the energy orbs.
          </Trans>
        </div>
        <img className="img" src={img} alt="" />
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
        <KontosButton className="ok-btn" onClick={() => onClose(false)}>
          {t("Claim")}
        </KontosButton>
      </Container>
    </Overlay>
  );
};
