export const newFetch = async (
  url: string,
  config: RequestInit
): Promise<any> => {
  try {
    const res = await fetch(url, config);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const contentType = res.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await res.json();
    } else {
      return await res.text();
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// export const newFetch = async <T>(
//   url: string,
//   config: RequestInit
// ): Promise<T> => {
//   try {
//     const res = await fetch(url, config);
//     if (!res.ok) {
//       throw new Error(`HTTP error! status: ${res.status}`);
//     }
//     const contentType = res.headers.get("content-type");
//     if (contentType && contentType.includes("application/json")) {
//       return await res.json();
//     } else {
//       throw new Error("Expected JSON response");
//     }
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };
