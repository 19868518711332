import React from 'react';
import styled from 'styled-components';
import warnIco from "src/assets/icons/dapp/wc-warning.svg";
import errorIco from "src/assets/icons/error1.svg";
import successIco from "src/components/toast/icons/success.svg"

interface BackgroundImageProps {
  level: number;
}

const WrapperImg = styled.img`
`;

const RiskIcon: React.FC<BackgroundImageProps> = ({
                                                    level
}) => {
  const getRiskIcon = (level: number) => {
    switch (level) {
      case 0:
        return successIco;
      case 1:
        return warnIco;
      case 2:
        return errorIco;
      default:
        return successIco;
    }
  }
  return <WrapperImg src={getRiskIcon(level)} />;
};

export default RiskIcon;
