import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import SearchSvg from "src/assets/icons/trade/trade-search.svg";
import HorizontalScrollableElements, {
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import CircleIconPair from "src/components/icons/CircleIconPair";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL, DEFAULT_DISPLAY_PRECESION } from "src/config";
import {
  calcFtAssetBalanceInUSD,
  getChainIcon,
} from "src/utils/zkkontosHelper";
import { FtAsset } from "src/type/zkkontos";
import { t } from "i18next";
import { KontosButton } from "src/components/button/KontosButton";
import toast from "src/components/toast/Toast";
import {
  KontosClient,
  KontosQueryCli,
  getDefaultClientPolymorphism,
} from "@zkkontos/kontos-sdk";
import { useStores } from "src/hooks/useStore";
import { EndMessage } from "src/components/no-data/EndMessage";
import { observer } from "mobx-react";
import { loadingStore } from "src/store/loadingStore";
import { MarkPaymentAssetsSignData } from "src/service/trade-service";
import { base64ToUint8Array, uint8ArrayToBase64 } from "src/utils/helper";
import { keccak256 } from "ethers/lib/utils";
import { enhancedFromHex } from "@zkkontos/kontos-sdk/src/core/utils";
import { callMarkPaymentAssets } from "src/apis/explorer-apis";
import { PaymentTip } from "./PaymentTip";
import forbidIcon from "src/assets/icons/trade/forbid.svg";
import Header from "src/components/common/header";
import { useNavigate } from "react-router-dom";

const Container = styled.div<{ paddingBottom?: number }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  padding-bottom: ${(props) => `${props.paddingBottom}px`};
`;

const MainContainer = styled.div`
  padding: 0 16px 16px 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 11px 10px 10px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  margin-bottom: 8px;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

const RadioButton = styled.div<{ $isSelected: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.colors.__kontos_blue : theme.colors.__deep_200};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.__kontos_blue};
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  }
`;

const ForbidIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const ItemTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  justify-content: center;
  align-items: flex-start;
`;

const ItemTextContainer2 = styled.div`
  flex: 1;
  display: flex;
  margin-left: 4px;
  justify-content: flex-end;
  align-items: center;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const ItemText = styled.div`
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;

  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
`;

const ItemText2 = styled.div`
  margin-top: 2px;
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans;
  font-size: 12px;
  font-weight: 400;
`;

const RadioBox = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemContainer = styled.div<{ $isSelected?: boolean; $disable?: boolean }>`
  margin-right: 2px;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;

  border-radius: 8px;
  background: ${(props) => props.theme.colors.__white};

  &:not(:first-child) {
    margin-top: 8px;
  }

  ${(props) =>
    !props.$disable &&
    `
    @media (hover: hover) {
    &:hover {
      background-color: var(--Deep-25, #f5f5f6);

      ${RadioButton} {
        border-color: ${props.theme.colors.__kontos_blue};
      }
    }
  }
  `};

  ${(props) =>
    props.$disable &&
    `${ItemText2},${ItemText} { color:  ${props.theme.colors.__deep_200} } `};

  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
  -webkit-tap-highlight-color: transparent;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Spliter = styled.div`
  margin-top: 8px;
  height: 1px;
  background: #cccfd2;
`;

const LineContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;

  .text-1 {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  .text-2 {
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans Bold;
    font-size: 16px;
  }
`;

const PaymentTipWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomContainer = styled.div`
  background-color: #fff;
  width: calc(100% - 32px);
  position: fixed;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ITEMS_TO_INIT = 15;
const ITEMS_TO_FETCH = 10;

const filterAndSortAssetsBySearchTerm = (
  assets: FtAsset[],
  searchTerm: string,
  chainIndex: string
) => {
  let arr = [];
  if (chainIndex === "all") {
    arr = assets;
  } else {
    arr = assets.filter((asset) => asset.chainIndex === chainIndex);
  }

  return arr
    .filter(
      (asset) =>
        asset.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.address.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const aMatchExact = a.symbol.toLowerCase() === searchTerm.toLowerCase();
      const bMatchExact = b.symbol.toLowerCase() === searchTerm.toLowerCase();

      if (aMatchExact && !bMatchExact) {
        return -1;
      } else if (!aMatchExact && bMatchExact) {
        return 1;
      }

      const aIndex = Math.min(
        a.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
      );
      const bIndex = Math.min(
        b.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
      );

      if (aIndex !== bIndex) {
        return aIndex - bIndex;
      }

      const aLength = a.symbol.length;
      const bLength = b.symbol.length;
      return aLength - bLength;
    });
};

interface IProps {
  onDone: () => void;
  isFullPage?: boolean;
}

export const PaymentMethod: React.FC<IProps> = observer(
  ({ onDone, isFullPage }) => {
    const { userStore, chainStore, tradeStore } = useStores();
    const navigate = useNavigate();
    const bottomRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [chainIndex, setChainIndex] = useState<string>(NonChainIndex.All);
    const [filteredWhitelistAssets, setFilteredWhitelistAssets] = useState<
      FtAsset[]
    >(userStore.userHoldingsInWhitelist);
    const [filteredBlacklistAssets, setFilteredBlacklistAssets] = useState<
      FtAsset[]
    >(userStore.userHoldingsInBlacklist);
    const [visibleItemCount, setVisibleItemCount] =
      useState<number>(ITEMS_TO_INIT);
    const [usedMap, setUsedMap] = useState<{ [key: number]: boolean }>(() => {
      let tempBanMap: { [key: number]: boolean } = {};
      userStore.userHoldingsInWhitelist.forEach((asset) => {
        tempBanMap[asset.balanceId] = asset.isUsed;
      });
      return tempBanMap;
    });
    const rawBanMap = useMemo(() => {
      return userStore.userHoldingsInWhitelist.reduce(
        (rMap, asset) => {
          rMap[asset.balanceId] = asset.isUsed;
          return rMap;
        },
        {} as { [key: number]: boolean }
      );
    }, [userStore.userHoldingsInWhitelist]);

    const totalAssets: FtAsset[] = useMemo(() => {
      return filteredWhitelistAssets.concat(filteredBlacklistAssets);
    }, [filteredBlacklistAssets, filteredWhitelistAssets]);

    const getMoreData = () => {
      setVisibleItemCount((prev) => prev + ITEMS_TO_FETCH);
    };

    const handleChooseAsset = (asset: FtAsset) => {
      if (usedMap?.[asset.balanceId] === false && asset.balanceId === 0) {
        toast({
          type: "warning",
          text: t("You cannot move native assets out of the payment list"),
        });
        return;
      }
      const tempBanMap = { ...usedMap };
      tempBanMap[asset.balanceId] = !tempBanMap[asset.balanceId];
      setUsedMap(tempBanMap);
    };

    const filterFtAssetsByChainIndex = useCallback(
      (chainIndex: string) => {
        if (chainIndex === "all") {
          return userStore.userHoldings;
        } else {
          return userStore.userHoldings.filter(
            (asset) => asset.chainIndex === chainIndex
          );
        }
      },
      [userStore.userHoldings]
    );

    const filterUsedMapByChainIndex = useCallback(
      (chainIndex: string) => {
        if (chainIndex === "all") {
          return usedMap;
        } else {
          const toFilterAssets = filterFtAssetsByChainIndex(chainIndex);
          return Object.keys(usedMap).reduce(
            (rMap, key) => {
              if (
                toFilterAssets.find((asset) => asset.balanceId === Number(key))
              ) {
                rMap[Number(key)] = usedMap[Number(key)];
              }
              return rMap;
            },
            {} as { [key: number]: boolean }
          );
        }
      },
      [filterFtAssetsByChainIndex, usedMap]
    );

    const isAllSelected = useMemo(() => {
      return Object.keys(filterUsedMapByChainIndex(chainIndex)).every(
        (key) => usedMap[Number(key)]
      );
    }, [chainIndex, filterUsedMapByChainIndex, usedMap]);

    const toolBtnText = useMemo(() => {
      if (Object.keys(filterUsedMapByChainIndex(chainIndex)).length === 0) {
        return "";
      }
      if (isAllSelected) {
        return t("Clear");
      } else {
        return t("Select All");
      }
    }, [chainIndex, filterUsedMapByChainIndex, isAllSelected]);

    const handleBatchChooseAsset = () => {
      if (Object.keys(filterUsedMapByChainIndex(chainIndex)).length === 0) {
        return;
      }
      const tempBanMap = {
        ...filterUsedMapByChainIndex(chainIndex),
      };
      Object.keys(tempBanMap).forEach((key) => {
        if (Number(key) !== 0) {
          tempBanMap[Number(key)] = !isAllSelected;
        }
      });
      const combinedMap = { ...usedMap, ...tempBanMap };
      setUsedMap(combinedMap);
    };

    useEffect(() => {
      setVisibleItemCount(ITEMS_TO_INIT);
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }

      setFilteredWhitelistAssets(
        filterAndSortAssetsBySearchTerm(
          userStore.userHoldingsInWhitelist,
          searchTerm,
          chainIndex
        )
      );
      setFilteredBlacklistAssets(
        filterAndSortAssetsBySearchTerm(
          userStore.userHoldingsInBlacklist,
          searchTerm,
          chainIndex
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, chainIndex]);

    const handleConfirm = async () => {
      const needSync = JSON.stringify(rawBanMap) !== JSON.stringify(usedMap);
      const toBanIds = Object.keys(usedMap)
        .filter((key) => usedMap[Number(key)] === false)
        .map((key) => Number(key));
      if (needSync) {
        try {
          const cli = await userStore.getKontosCli();
          await execute(cli, toBanIds);
        } catch (e) {
          console.log(e);
          userStore.unlock(handleConfirm);
          return;
        }
      } else {
        onDone();
      }
    };

    const isAssetSelected = useCallback(
      (asset: FtAsset): boolean => {
        return (
          (usedMap.hasOwnProperty(asset.balanceId) &&
            usedMap[asset.balanceId]) ||
          asset.balanceId === 0
        );
      },
      [usedMap]
    );

    const realTimeAvailableBalance = useMemo((): KontosNumber => {
      const availableAssets = userStore.userHoldings.filter((asset) =>
        isAssetSelected(asset)
      );
      return availableAssets.reduce((accumulator, currentItem) => {
        return accumulator.add(currentItem.totalUsd, DEFAULT_DECIMAL);
      }, new KontosNumber(0));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAssetSelected, totalAssets]);

    const execute = useCallback(
      async (cli: KontosClient, ids: number[]) => {
        loadingStore.showLoading();
        let flag = false;
        try {
          const { sequence } = await cli.signingCli.getSequence(
            userStore.accountInfo?.kontosAddress ||
              KontosQueryCli.kontosAddress(userStore.accountName!)
          );
          const nonce = sequence + 1;
          const signData: MarkPaymentAssetsSignData = {
            ids: ids,
            nonce: nonce,
          };
          const signDataStr = btoa(JSON.stringify(signData));
          const signDataBytes = base64ToUint8Array(signDataStr);
          const signDataHash = keccak256(signDataBytes);
          const kontosClientPolymorphism = getDefaultClientPolymorphism();
          const privateKey = cli.key.privateKey as CryptoKey;
          const sig = await kontosClientPolymorphism.sign(
            userStore.accountName!,
            privateKey,
            enhancedFromHex(signDataHash)
          );
          try {
            await callMarkPaymentAssets(
              userStore.accountName!,
              ids,
              nonce,
              uint8ArrayToBase64(sig)
            );
            flag = true;
          } catch (e) {
            console.log(e);
          }
        } catch (e) {
          console.log(e);
          const errorMessage =
            e instanceof Error ? e.message : t("Failed to record your options");
          toast({
            type: "error",
            text: errorMessage,
          });
        } finally {
          loadingStore.hideLoading();
        }

        if (flag) {
          loadingStore.showLoading();
          try {
            await userStore.fetchAndUpdateAccountBalances();
            toast({
              type: "success",
              text: t("Update successful"),
            });
          } catch (e) {
            console.log(e);
            const errorMessage =
              e instanceof Error ? e.message : t("Failed to get balance");
            toast({
              type: "error",
              text: errorMessage,
            });
          } finally {
            loadingStore.hideLoading();
            tradeStore.changePayableAssets();
            onDone();
          }
        }
      },
      [onDone, tradeStore, userStore]
    );

    return (
      <Container paddingBottom={bottomRef.current?.offsetHeight}>
        <Header
          callBack={() => {
            navigate(-1);
          }}
          enableBack={true}
          title={t("Manage Payment")}
          padding={isFullPage ? "20px" : "8px 20px"}
          rightBtnText={toolBtnText}
          rightBtnCallBack={handleBatchChooseAsset}
        />

        <MainContainer>
          <SearchInput
            style={{ marginTop: "10px" }}
            ref={inputRef}
            placeholder={t("Search assets")}
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
          <HorizontalScrollableElements
            chains={chainStore.userHoldingsChains}
            onSelect={(index) => {
              setChainIndex(index);
            }}
            chainIndex={chainIndex}
            hasAll
          />
          <Scrollable id="scrollableDiv" ref={scrollRef}>
            <InfiniteScroll
              dataLength={visibleItemCount} // The current length of displayed data
              next={getMoreData} // The function to trigger to load more
              hasMore={visibleItemCount < totalAssets.length} // Whether there are more items to load
              loader={<h4>{t("Loading...")}</h4>}
              endMessage={<EndMessage />}
              scrollableTarget="scrollableDiv"
              style={{ overflowX: "hidden" }}
            >
              {filteredWhitelistAssets
                .slice(0, visibleItemCount)
                .map((item, index) => (
                  <ItemContainer
                    key={index}
                    $isSelected={isAssetSelected(item)}
                    onClick={() => handleChooseAsset(item)}
                  >
                    <CircleIconPair
                      mainIcon={item.imageUrl}
                      mainIconFallbackSrc={DefaultTokenSvg}
                      subIcon={getChainIcon(
                        chainStore.chains,
                        item.chainIndex,
                        item.address
                      )}
                      subIconFallbackSrc={DefaultChainSvg}
                      mainWidth={32}
                      mainHeight={32}
                      subWidth={16}
                      subHeight={16}
                      totalWidth={40}
                      totalHeight={32}
                    />
                    <ItemTextContainer>
                      <ItemText>{item.symbol}</ItemText>
                      <ItemText2>{item.chainSymbol}</ItemText2>
                    </ItemTextContainer>

                    <ItemTextContainer2>
                      <AmountBox>
                        <ItemText>
                          {new KontosNumber(
                            item.balance,
                            DEFAULT_DECIMAL
                          ).toFixed(DEFAULT_DISPLAY_PRECESION)}
                        </ItemText>
                        <ItemText2>
                          {calcFtAssetBalanceInUSD(
                            new KontosNumber(item.usdPrice, DEFAULT_DECIMAL),
                            new KontosNumber(item.balance, DEFAULT_DECIMAL)
                          ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
                        </ItemText2>
                      </AmountBox>
                      <RadioBox>
                        <RadioButton $isSelected={isAssetSelected(item)} />
                      </RadioBox>
                    </ItemTextContainer2>
                  </ItemContainer>
                ))}
              {visibleItemCount > filteredWhitelistAssets.length &&
                filteredBlacklistAssets.length > 0 && (
                  <>
                    <PaymentTipWrapper>
                      <PaymentTip />
                    </PaymentTipWrapper>
                    {filteredBlacklistAssets
                      .slice(
                        0,
                        visibleItemCount - filteredWhitelistAssets.length
                      )
                      .map((item, index) => (
                        <ItemContainer key={index} $disable>
                          <CircleIconPair
                            mainIcon={item.imageUrl}
                            mainIconFallbackSrc={DefaultTokenSvg}
                            subIcon={getChainIcon(
                              chainStore.chains,
                              item.chainIndex,
                              item.address
                            )}
                            subIconFallbackSrc={DefaultChainSvg}
                            mainWidth={32}
                            mainHeight={32}
                            subWidth={16}
                            subHeight={16}
                            totalWidth={40}
                            totalHeight={32}
                          />
                          <ItemTextContainer>
                            <ItemText>{item.symbol}</ItemText>
                            <ItemText2>{item.chainSymbol}</ItemText2>
                          </ItemTextContainer>

                          <ItemTextContainer2>
                            <AmountBox>
                              <ItemText>
                                {new KontosNumber(
                                  item.balance,
                                  DEFAULT_DECIMAL
                                ).toFixed(DEFAULT_DISPLAY_PRECESION)}
                              </ItemText>
                              <ItemText2>
                                {calcFtAssetBalanceInUSD(
                                  new KontosNumber(
                                    item.usdPrice,
                                    DEFAULT_DECIMAL
                                  ),
                                  new KontosNumber(
                                    item.balance,
                                    DEFAULT_DECIMAL
                                  )
                                ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
                              </ItemText2>
                            </AmountBox>
                            <RadioBox>
                              <ForbidIcon src={forbidIcon} />
                            </RadioBox>
                          </ItemTextContainer2>
                        </ItemContainer>
                      ))}
                  </>
                )}
            </InfiniteScroll>
          </Scrollable>
          <BottomContainer ref={bottomRef}>
            <Spliter />
            <LineContainer>
              <LineItem>
                <div className="text-1">{t("Allowed to pay:")}</div>
                <div className="text-2">
                  {realTimeAvailableBalance.toFormat() + " USD"}
                </div>
              </LineItem>
              <LineItem>
                <div className="text-1">{t("My balance:")}</div>
                <div className="text-2">
                  {new KontosNumber(
                    userStore.accountBalances?.totalUsd,
                    DEFAULT_DECIMAL
                  ).toFormat() + " USD"}
                </div>
              </LineItem>
            </LineContainer>
            <KontosButton
              onClick={() => {
                handleConfirm();
              }}
            >
              {t("Done")}
            </KontosButton>
          </BottomContainer>
        </MainContainer>
      </Container>
    );
  }
);
