import styled from "styled-components";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { useCallback, useEffect, useRef, useState } from "react";
import { DappAppItem } from "../common/DappAppItem";
import defaultDappIcon from "src/assets/icons/dapp/default-dapp.svg";
import useClickOutside from "src/hooks/useClickOutside";
import { DappFavoritesItem, DappWatched } from "src/type/dapp";
import { useStores } from "src/hooks/useStore";
import { useDebounce } from "react-use";
import { calculateItemsPerRow, openUrl } from "src/utils/helper";
import {
  BothEndsHeader,
  BothEndsHeaderLeft,
  BothEndsHeaderRight,
  BothEndsHeaderRightHighlight,
} from "src/components/header/BothEndsHeader";
import { t } from "i18next";
import { observer } from "mobx-react";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { useNavigate } from "react-router-dom";
import { PREV, ROUTE_DISCOVER_TRENDINGS } from "src/router/router-config";
import addIcon from "src/assets/icons/discover/add.svg";

const DappSize = 80;
const DappGap = 5.5;
const DappLineGap = 10;
const DEBOUNCE_TIME = 500;
const APP_ZONE_PADDING_TOP = 16;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const AppContainer = styled.div`
  flex: 1;
  width: 100%;
  padding-top: ${APP_ZONE_PADDING_TOP}px;
  text-align: center;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }

  .dapp-fav-desktop-grid-item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
`;

const LoadingGif = styled.img`
  margin-top: 30px;
  height: 20px;
  width: 100px;
`;

const AddButton = styled.div`
  margin-top: 28px;
  cursor: pointer;
  width: 138px;
  height: 32px;
  border-radius: 99px;
  border: 1px solid var(--Kontos-Blue, #413dec);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
    height: 16px;
  }

  div {
    margin-left: 6px;
    color: var(--Kontos-Blue, #413dec);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }
`;

const needUpdateSort = (arr: number[], rawArr: number[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== rawArr[i]) {
      return true;
    }
  }
  return false;
};

interface IProps {
  dapps: DappWatched[];
  onAppManageStart?: () => void;
  onAppManageEnd?: (ids?: number[]) => Promise<void | boolean>;
  onDelete: (id: number) => Promise<void | boolean>;
}

const dappAppClassName = "dapp-app-item";
const dappAppDeleteIconClassName = "dapp-app-item-delete-icon";

export const DappFavoritesDesktop: React.FC<IProps> = observer(
  ({ dapps, onAppManageStart, onAppManageEnd, onDelete }) => {
    const navigate = useNavigate();
    const { sheetStore, favStore, discoverStore } = useStores();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const innerRef = useRef<HTMLDivElement | null>(null);
    const [items, setItems] = useState<number[]>(
      dapps.map((dapp) => dapp.watchId) || []
    );
    const [disableDrag, setDisableDrag] = useState(true);
    const [boxesPerRow, setBoxesPerRow] = useState<number | undefined>(
      discoverStore.dappDesktopBoxesPerRow
    );
    const [innerHeight, setInnerHeight] = useState<number | undefined>(
      discoverStore.dappDesktopInnerHeight
    );
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
      if (
        items.length === 0 &&
        dapps.length > 0 &&
        !favStore.removingFavorites &&
        !favStore.sortingFavorites
      ) {
        setItems(dapps.map((dapp) => dapp.watchId) || []);
      }
    }, [
      dapps,
      favStore.sortingFavorites,
      favStore.removingFavorites,
      items.length,
    ]);

    // target id will only be set if dragging from one dropzone to another.
    const onChange = useCallback(
      (
        sourceId: string,
        sourceIndex: number,
        targetIndex: number,
        targetId?: string | undefined
      ) => {
        const nextState = swap(items, sourceIndex, targetIndex);
        setItems(nextState);
      },
      [items]
    );

    const appOnLongPress = useCallback(() => {
      onAppManageStart?.();
      setDisableDrag(false);
      setShowDelete(true);
    }, [onAppManageStart]);

    const reset = useCallback(async () => {
      const needUpdate = needUpdateSort(
        items,
        dapps.map((dapp) => dapp.watchId) || []
      );
      if (needUpdate) {
        onAppManageEnd?.(items);
      } else {
        onAppManageEnd?.();
      }
      setDisableDrag(true);
      setShowDelete(false);
    }, [dapps, items, onAppManageEnd]);

    const onInnerClick = useCallback(
      (event: React.MouseEvent) => {
        const target = event.target as HTMLElement;

        if (
          target.classList.contains(dappAppClassName) ||
          target.classList.contains(dappAppDeleteIconClassName)
        ) {
          return;
        }
        reset();
      },
      [reset]
    );

    const appOnDelete = useCallback(
      (watchId: number, id: number) => {
        const newItems = items.filter((item) => item !== watchId);
        setItems(newItems);
        onDelete(id);
      },
      [items, onDelete]
    );

    const appOnClick = useCallback((dapp: DappFavoritesItem) => {
      openUrl(dapp.appUrl, null);
    }, []);

    useClickOutside({
      ref: wrapperRef,
      callback: () => {
        if (!sheetStore.showOuterPin) reset();
      },
      shouldExecute: true,
    });

    const [, cancel] = useDebounce(
      async () => {
        const calculateDappDisplayCountAndHeight = () => {
          const rowWidth = innerRef.current?.offsetWidth;
          const height = innerRef.current?.offsetHeight;

          if (rowWidth) {
            const itemsNumPerRow = calculateItemsPerRow(
              DappSize,
              DappGap,
              rowWidth
            );
            setBoxesPerRow(itemsNumPerRow);
            discoverStore.setDappDesktopBoxesPerRow(itemsNumPerRow);
          }

          if (height) {
            const realHeight = height - APP_ZONE_PADDING_TOP;
            setInnerHeight(realHeight);
            discoverStore.setDappDesktopInnerHeight(realHeight);
          }
        };

        if (innerHeight === undefined || boxesPerRow === undefined)
          calculateDappDisplayCountAndHeight();

        window.addEventListener("resize", calculateDappDisplayCountAndHeight);

        return () =>
          window.removeEventListener(
            "resize",
            calculateDappDisplayCountAndHeight
          );
      },
      DEBOUNCE_TIME,
      [dapps]
    );

    useEffect(() => {
      return () => cancel();
    }, [cancel]);

    return (
      <Container ref={wrapperRef} onClick={onInnerClick}>
        <BothEndsHeader>
          <BothEndsHeaderLeft>{t("My Favorites")}</BothEndsHeaderLeft>
          {!showDelete ? (
            <BothEndsHeaderRight
              onClick={(e) => {
                e.stopPropagation();
                setShowDelete(true);
                setDisableDrag(false);
              }}
            >
              {t("Manage")}
            </BothEndsHeaderRight>
          ) : (
            <BothEndsHeaderRightHighlight
              onClick={(e) => {
                e.stopPropagation();
                setShowDelete(false);
                setDisableDrag(true);
                setItems(dapps.map((dapp) => dapp.watchId) || []);
              }}
            >
              {t("Cancel")}
            </BothEndsHeaderRightHighlight>
          )}
        </BothEndsHeader>

        <AppContainer ref={innerRef}>
          {boxesPerRow === undefined || innerHeight === undefined ? (
            <LoadingGif src="/static/loading.gif" alt="Loading..." />
          ) : items.length === 0 ? (
            <div style={{ height: "320px" }}>
              <NoDataV2 text={t("You have not added any favorite DApp yet!")}>
                <AddButton
                  onClick={() => navigate(PREV + ROUTE_DISCOVER_TRENDINGS)}
                >
                  <img src={addIcon} alt="" />
                  <div>{t("Add Favorites")}</div>
                </AddButton>
              </NoDataV2>
            </div>
          ) : (
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                id="items"
                boxesPerRow={boxesPerRow}
                rowHeight={DappSize + DappLineGap}
                style={{ height: `${innerHeight}px` }}
                disableDrag={disableDrag}
                className=""
              >
                {items.map((item) => {
                  const dapp = dapps.find((dapp) => dapp.watchId === item);

                  return (
                    <GridItem
                      className={"dapp-fav-desktop-grid-item"}
                      key={item}
                    >
                      <DappAppItem
                        className={dappAppClassName}
                        deleteIconClassName={dappAppDeleteIconClassName}
                        icon={dapp?.dapp?.imageUrl || defaultDappIcon}
                        title={dapp?.dapp?.name}
                        showDelete={showDelete}
                        onIconClick={() => dapp && appOnClick(dapp.dapp)}
                        onLongPress={appOnLongPress}
                        onDelete={() =>
                          dapp?.dapp?.id &&
                          appOnDelete(dapp.watchId, dapp.dapp.id)
                        }
                      />
                    </GridItem>
                  );
                })}
              </GridDropZone>
            </GridContextProvider>
          )}
        </AppContainer>
      </Container>
    );
  }
);
