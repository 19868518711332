import { ReactNode } from "react";
import styled from "styled-components";

const IconContainer = styled.div<{
  icon: any;
  size?: number;
}>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};

  background-image: ${(props) => `url(${props.icon})`};
  background-repeat: no-repeat;
  background-size: ${(props) => `${props.size}px ${props.size}px`};
  background-position: center center;
`;

const Text = styled.span`
  height: 16px;
  line-height: 16px;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const Container = styled.div<{
  activeIcon: any;
}>`
  padding: 6px 12px;
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--White, #fff);

  display: flex;
  align-items: center;
  gap: 6px;

  cursor: pointer;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      ${IconContainer} {
        background-image: ${(props) => `url(${props.activeIcon})`} !important;
      }
      ${Text} {
        color: var(--Kontos-Blue, #413dec) !important;
      }
    }
  }
  &:active {
    border-color: var(--Kontos-Blue, #413dec);
    ${IconContainer} {
      background-image: ${(props) => `url(${props.activeIcon})`} !important;
    }
    ${Text} {
      color: var(--Kontos-Blue, #413dec) !important;
    }
  }
`;

interface IProps {
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  size?: number;
  icon?: any;
  activeIcon?: any;
  content?: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

export const SingleIconWithTextButton: React.FC<IProps> = ({
  wrapperClassName,
  wrapperStyle,
  activeIcon,
  icon,
  size = 16,
  content,
  onClick,
}) => {
  return (
    <Container
      className={wrapperClassName}
      style={wrapperStyle}
      activeIcon={activeIcon}
      onClick={onClick}
    >
      <IconContainer size={size} icon={icon} />
      <Text>{content}</Text>
    </Container>
  );
};
