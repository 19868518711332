import { ReactNode } from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import hornIco from "src/assets/icons/components/horn.svg";

const Container = styled.div`
  width: 100%;
  padding: 8px 14px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-top: 1px;
`;

interface Props {
  contents: ReactNode[];
  onCycleComplete?: () => void;
}

const Ticker: React.FC<Props> = ({ contents, onCycleComplete }) => {
  return (
    <Container>
      <Icon src={hornIco} />
      <Marquee
        pauseOnHover={true}
        loop={0}
        delay={1}
        style={{
          height: "20px",
          lineHeight: "20px",
          overflow: "hidden",
          verticalAlign: "middle",
        }}
        onCycleComplete={onCycleComplete}
      >
        {contents.map((content, index) => (
          <div key={index} style={{ margin: "0 40px 0 0" }}>
            {content}
          </div>
        ))}
      </Marquee>
    </Container>
  );
};

export default Ticker;
