import styled from "styled-components";
import TipsIcon from "src/assets/images/info-tips.svg";
import { t } from "i18next";
import { Trans } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  width: 18px;
  :hover {
    .tips {
      display: flex;
    }
  }
  .tips {
    display: none;
    position: absolute;
    bottom: 25px;
    right: -30px;
  }
  .content {
    position: relative;
    width: 300px;
    height: auto;
    min-height: auto;
    font-weight: 700;
    font-size: 12px;
    padding: 14px;
    background: #ffffff;
    border: 1px solid #413dec;
    border-radius: 20px;
    color: #1a2535;
    text-align: left;
    .title {
      font-family: "HarmonyOS Sans Bold";
      font-size: 14px;
      color: #413dec;
      line-height: normal;
    }
    .text {
      line-height: normal;
      margin-top: 8px;
    }
  }
  .icon {
    width: 14px;
    height: 14px;
  }
  .content:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #413dec;
    right: 29px;
    bottom: -16px;
  }
  .content:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #fff;
    right: 29px;
    bottom: -15px;
  }
`;

export const UnlockTips: React.FC = () => {
  return (
    <Wrapper>
      <img src={TipsIcon} className="icon" alt="" />
      <div className="tips">
        <div className="content">
          <div className="title">{t("Friendly Reminder")} </div>
          <div className="text">
            {t(
              "Please note that you only have 10 chances to unlock your account."
            )}
          </div>
          <div className="text">
            {t(
              "If you enter your PIN Code incorrectly 10 times in a row, your accounts will be automatically reset!"
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
