import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

// const draw = {
//   hidden: { pathLength: 0 },
//   visible: (percent: number) => {
//     return {
//       pathLength: percent,
//       transition: {
//         pathLength: { type: "spring", duration: 2, bounce: 0 },
//       },
//     };
//   },
// };

export const EbBarSvg: React.FC<{
  percent: number;
  animationDuraion: number;
  className?: string;
  onAnimationComplete?: () => void;
}> = ({ percent, animationDuraion, className, onAnimationComplete }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [pathD, setPathD] = useState<string>("M5 5.00003L322 5");

  const draw = {
    hidden: { pathLength: 0 },
    visible: {
      pathLength: percent,
      transition: {
        pathLength: { type: "spring", duration: animationDuraion, bounce: 0 },
      },
    },
  };

  useEffect(() => {
    const updatePathWidth = () => {
      if (svgRef.current) {
        const width = svgRef.current.clientWidth;
        const newPathD = `M5 5.00003L${width - 5} 5`;
        setPathD(newPathD);
      }
    };

    window.addEventListener("resize", updatePathWidth);
    updatePathWidth();

    return () => window.removeEventListener("resize", updatePathWidth);
  }, []);

  return (
    <motion.svg
      ref={svgRef}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="10"
      viewBox={`0 0 ${svgRef.current ? svgRef.current.clientWidth : 327} 10`}
      fill="none"
      style={{ width: "100%", height: "10px" }}
      variants={draw}
      initial="hidden"
      animate="visible"
      onAnimationComplete={onAnimationComplete}
    >
      <path
        d={pathD}
        stroke="#CCCFD2"
        strokeWidth="10"
        width="100%"
        strokeLinecap="round"
      />
      <motion.path
        d={pathD}
        strokeWidth="10"
        strokeLinecap="round"
        stroke="url(#paint0_linear_295_16434)"
        variants={draw}
        custom={percent}
      />
      <defs>
        <motion.linearGradient
          id="paint0_linear_295_16434"
          x1="5"
          y1="6.00006"
          x2="322"
          y2="6.00004"
          gradientUnits="userSpaceOnUse"
        >
          <motion.stop stopColor="#A8FFCB" />
          <motion.stop offset="0.43" stopColor="#90EBFF" />
          <motion.stop offset="0.705" stopColor="#413DEC" />
          <motion.stop offset="1" stopColor="#FF00E6" />
        </motion.linearGradient>
      </defs>
    </motion.svg>
  );
};
