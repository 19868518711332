import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import kontosAvatarGoogle from "src/assets/images/kontos-avatar-google.svg";
import kontosAvatar from "src/assets/images/kontos-avatar-normal.svg";
import success from "src/assets/images/success.svg";
import HorizontalLine from "src/components/line/HorizontalLine";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import PoweredBy from "src/components/common/powerdBy";
import {useNavigate, useSearchParams} from "react-router-dom";
import EditIcon from "src/assets/icons/trade/editor.svg";
import {statusRouterMap} from "src/pages/newAuth/CreateAccount";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > div {
      width: 100%;
    }

    .description {
      color: var(--Deep-800, #1a2535);
      text-align: center;

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */

      span {
        color: var(--Kontos-Blue, #413dec);
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
    }

    .kontosAvatarGoogle,
    .kontosAvatar {
      width: 89px;
      height: 86px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;

      .securityEmail,
      .setUpGuardian {
        box-sizing: border-box;
        display: inline-flex;
        height: 56px;
        padding: 17px 20px 18px 30px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 99px;
        border: 1px solid var(--Deep-50, #edeef1);
        background: var(--Deep-25, #f5f5f6);
        margin-bottom: 12px;
        width: 100%;
        color: var(--Deep-800, #1a2535);

        /* H5 */
        font-family: HarmonyOS Sans SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        .title {
          color: var(--Deep-800, #1a2535);

          /* H5 */
          font-family: HarmonyOS Sans SC;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-right: 8px;
        }

        .text {
          color: var(--Deep-400, #80868f);

          /* Smaller */
          font-family: HarmonyOS Sans SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .editIcon{
          width: 20px;
          height: 20px;
          margin-left: 11px;
          cursor: pointer;
        }
        .successIcon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }

      .securityEmailActive,
      .setUpGuardianActive {
        width: 100%;
        position: relative;

        .arrow {
          width: 14px;
          height: auto;
          right: 30px;
          top: 20px;
          position: absolute;
        }
      }

      .securityEmailActive {
        margin-bottom: 12px;
      }
    }
  }
  .buttonWrap {
    width: calc(100% - 40px);
    position: fixed;
    bottom: 16px;
  }
`;

export const securityInfoText = {
  mustBeSet: t(
    "Please choose any of the following options to add security measures to your account."
  ),
  canBeSet: t(
    "You can either complete the account creation directly or continue to add security settings to your account "
  ),
  fullySet: t("You have added complete security protection to your account: "),
};

type IProps = {
  submitFunction: () => void;
  kontosName?: string;
  userEmailArr: string[];
  setRouteStatus: (status: string) => void;
  canConfirm: boolean;
  securityInfoKey: keyof typeof securityInfoText;
  currentThreshold: number;
  guardianArr: string[];
  securitySettingsType: string;
};

const SecuritySettings: React.FC<IProps> = ({
  submitFunction,
  kontosName,
  userEmailArr,
  setRouteStatus,
  canConfirm,
  securityInfoKey,
  currentThreshold,
  guardianArr,
  securitySettingsType
}) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className={"main"}>
        {userEmailArr.length > 0 ? (
          <img
            className={"kontosAvatarGoogle"}
            src={kontosAvatarGoogle}
            alt="kontosAvatarGoogle"
          />
        ) : (
          <img
            className={"kontosAvatar"}
            src={kontosAvatar}
            alt="kontosAvatar"
          />
        )}
        <div className={"description"}>
          {t(securityInfoText[securityInfoKey])}
          <span>{kontosName}.os</span>
        </div>
        <div className={"lineWrap"}>
          <HorizontalLine />
        </div>
        <div className={"inputWrap"}>
          {securitySettingsType === statusRouterMap.google && userEmailArr.length > 0 && (
            <div className={"securityEmail"}>
              <img className={'successIcon'} src={success}/>
              <span className={"title"}>{t("Security Email")}</span>
              <span className={"text"}>
                {userEmailArr.length} {userEmailArr.length >= 1 ? "emails" : "email"}, threshold {currentThreshold}
              </span>
              <img
                className={'editIcon'}
                src={EditIcon}
                onClick={() => {
                  setRouteStatus(statusRouterMap.google);
                }}
              />
            </div>
          )}
          {securitySettingsType === statusRouterMap.setUpGuardians && guardianArr.length > 0 && (
            <div className={"setUpGuardian"}>
              <img className={'successIcon'} src={success}/>
              <span className={"title"}>{t("Guardians")}</span>
              <span className={"text"}>
                {guardianArr.length} Guardians, threshold {currentThreshold}
              </span>
              <img
                className={'editIcon'}
                src={EditIcon}
                onClick={() => {
                  setRouteStatus(statusRouterMap.setUpGuardians);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className={"buttonWrap"}>
        {canConfirm && (
          <Button
            onClick={submitFunction}
            text={t("Confirm Creation")}
            height={"56px"}
          />
        )}
        <PoweredBy />
      </div>
    </Wrapper>
  );
};

export default observer(SecuritySettings);
