import { ReactNode, useCallback, useEffect, useState } from "react";
// import { Trans } from "react-i18next";
import Ticker from "src/components/ticker/Ticker";
import styled, { css } from "styled-components";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { motion } from "framer-motion";
import darkBgImg from "src/assets/images/event/eb/dark-notification-bg.png";

const normalStyle = css`
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
`;

const darkStyle = css`
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const Container = styled(motion.div)<{ $darkMode?: boolean }>`
  margin: 0 24px 16px 24px;
  height: 36px;
  background-image: ${(props) => (props.$darkMode ? `url(${darkBgImg})` : "")};

  ${(props) => (props.$darkMode ? `${darkStyle}` : `${normalStyle}`)}
`;

const NormalText = styled.div<{ $darkMode?: boolean }>`
  color: ${(props) =>
    props.$darkMode ? "var(--White, #FFF)" : "var(--Deep-800, #1a2535)"};
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  height: 20px;
`;

// const StrongText = styled.span`
//   color: var(--Kontos-Blue, #413dec);
//   font-family: "HarmonyOS Sans SC";
//   font-size: 12px;
//   font-weight: 700;
// `;

interface Props {
  notifications: ebTypes.Notification[];
  className?: string;
  darkMode?: boolean;
  style?: React.CSSProperties;
}

const EnergyBoostingTicker: React.FC<Props> = ({
  notifications,
  className,
  darkMode = false,
  style,
}) => {
  const [contents, setContents] = useState<ReactNode[]>([]);

  const loadContentsFromStore = useCallback(async () => {
    const newArr: ReactNode[] = [];
    const storeArr = notifications;
    for (let i = 0; i < storeArr?.length; i++) {
      const messgae = storeArr?.[i]?.message;
      // const accountName = storeArr?.[i]?.message?.[0] || undefined;
      // const rewardName = storeArr?.[i]?.message?.[1] || undefined;
      if (messgae) {
        newArr.push(
          <NormalText $darkMode={darkMode}>{messgae}</NormalText>
          // <Trans i18nKey={"trans-energy-ball-ticker"}>
          //   <NormalText>
          //     {{ accountName } as any}.os obtained{" "}
          //     <StrongText>{{ rewardName } as any}</StrongText> in Energy ball.
          //     Congratulations!!!!!!!!!!!!!!
          //   </NormalText>
          // </Trans>
        );
      }
    }
    setContents(newArr);
  }, [darkMode, notifications]);

  useEffect(() => {
    if (contents?.length === 0 && notifications?.length > 0) {
      loadContentsFromStore();
    }
  }, [contents?.length, notifications?.length, loadContentsFromStore]);

  if (contents?.length === 0) {
    return <></>;
  } else {
    return (
      // TODO: eb anime
      <Container
        className={className}
        style={style}
        $darkMode={darkMode}
        // initial={{ y: -100, opacity: 0 }}
        // animate={{ y: 0, opacity: 1 }}
      >
        <Ticker contents={contents} onCycleComplete={loadContentsFromStore} />
      </Container>
    );
  }
};

export default EnergyBoostingTicker;
