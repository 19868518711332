import { makeAutoObservable, reaction, runInAction } from "mobx";
import { RootStore } from "../RootStore";
import { fetchAllScraperChains } from "src/utils/zkkontosHelper";
import { ChainConfig } from "src/type/zkkontos";
import {
  KONTOS_CHAIN_INDEX,
  XLAYER_CHAIN_INDEX,
  ZETA_CHAIN_INDEX,
} from "src/config";
import {
  ChainIndex,
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";

const RETRY_INTERVAL = 45000;

export class ChainStore {
  rootStore: RootStore;
  chains: ChainConfig[] = [];
  defaultOptForSend: ChainIndex = NonChainIndex.All;
  defaultOptForBuy: ChainIndex = NonChainIndex.Favorites;
  defaultOptForSell: ChainIndex = NonChainIndex.All;
  defaultOptForSellReceive: ChainIndex = NonChainIndex.Recommend;
  defaultOptForBatchSell: ChainIndex = NonChainIndex.All;
  defaultOptForBatchSellReceive: ChainIndex = NonChainIndex.Recommend;
  defaultOptForSwap: ChainIndex = NonChainIndex.MyAssets;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.fetchAndSetChains();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  startTrackingAccountName = () => {
    reaction(
      () => this.rootStore.userStore.accountName,
      () => {
        this.resetDefaultOpts();
      }
    );
  };

  resetDefaultOpts = () => {
    this.defaultOptForSend = NonChainIndex.All;
    this.defaultOptForBuy = NonChainIndex.Favorites;
    this.defaultOptForSell = NonChainIndex.All;
    this.defaultOptForSellReceive = NonChainIndex.Recommend;
    this.defaultOptForBatchSell = NonChainIndex.All;
    this.defaultOptForBatchSellReceive = NonChainIndex.Recommend;
    this.defaultOptForSwap = NonChainIndex.MyAssets;
  };
  setDefaultOptForSend = (index: ChainIndex) => {
    this.defaultOptForSend = index;
  };
  setDefaultOptForBuy = (index: ChainIndex) => {
    this.defaultOptForBuy = index;
  };
  setDefaultOptForSell = (index: ChainIndex) => {
    this.defaultOptForSell = index;
  };
  setDefaultOptForSellReceive = (index: ChainIndex) => {
    this.defaultOptForSellReceive = index;
  };
  setDefaultOptForBatchSell = (index: ChainIndex) => {
    this.defaultOptForBatchSell = index;
  };
  setDefaultOptForBatchSellReceive = (index: ChainIndex) => {
    this.defaultOptForBatchSellReceive = index;
  };
  setDefaultOptForSwap = (index: ChainIndex) => {
    this.defaultOptForSwap = index;
  };

  fetchAndSetChains = async (retryCount: number = 2) => {
    try {
      const { chains } = await fetchAllScraperChains();
      if (chains.length === 0) {
        throw new Error("no chains");
      }
      runInAction(() => {
        this.chains = chains || [];
      });
      return chains;
    } catch (e) {
      console.log("Failed to fetch scraper chains", e);
      if (retryCount > 0) {
        this.fetchAndSetChains(retryCount - 1);
      } else {
        setTimeout(() => this.fetchAndSetChains(), RETRY_INTERVAL);
      }
    }
  };

  fetchSpecificChain = async (
    index: string
  ): Promise<ChainConfig | undefined> => {
    const matchingOne = this.chains.find((item) => item.chainIndex === index);
    if (matchingOne) {
      return matchingOne;
    }

    const { chains } = await fetchAllScraperChains();
    if (chains.length === 0) {
      throw new Error("no chains");
    }
    runInAction(() => {
      this.chains = chains || [];
    });
    return chains.find((item) => item.chainIndex === index);
  };

  get noKontosChains(): ChainConfig[] {
    return this.chains.filter(
      (chain) => chain.chainIndex !== KONTOS_CHAIN_INDEX
    );
  }

  get allowSwapChains(): ChainConfig[] {
    return this.chains.filter(
      (chain) =>
        chain.chainIndex !== KONTOS_CHAIN_INDEX &&
        chain.chainIndex !== ZETA_CHAIN_INDEX &&
        chain.chainIndex !== XLAYER_CHAIN_INDEX
    );
  }

  get userHoldingsChains(): ChainConfig[] {
    return this.chains.filter((chain) =>
      this.rootStore.userStore.userHoldings.some(
        (asset) => asset.chainIndex === chain.chainIndex
      )
    );
  }

  get noUserHoldingsChains(): ChainConfig[] {
    return this.chains.filter(
      (chain) =>
        !this.rootStore.userStore.userHoldings.some(
          (asset) => asset.chainIndex === chain.chainIndex
        )
    );
  }

  get userHoldingsNoKontosChains(): ChainConfig[] {
    return this.chains.filter(
      (chain) =>
        this.rootStore.userStore.userHoldings.some(
          (asset) => asset.chainIndex === chain.chainIndex
        ) && chain.chainIndex !== KONTOS_CHAIN_INDEX
    );
  }

  get chainsSortedByUserHoldings(): ChainConfig[] {
    return this.userHoldingsChains.concat(this.noUserHoldingsChains);
  }
}
