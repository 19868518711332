import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import tradeBlackUpArrowIcon from "src/assets/icons/trade/trade-black-up-arrow.svg";
import { observer } from "mobx-react";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import toast from "src/components/toast/Toast";
import { loadingStore } from "src/store/loadingStore";
import {
  BuyErrorType,
  getBuyErrorText,
  parseApiErrorMessage,
  isSameFtAsset,
} from "src/utils/zkkontosHelper";
import KontosNumber from "src/utils/KontosNumber";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import {
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
  DEFAULT_SLIPPAGE,
  DEFAULT_TO_BUY_FTASSET,
  DEFAULT_TO_BUY_FTASSET_2_STANDARD,
  DEFAULT_TO_BUY_FTASSET_STANDARD,
  TRADE_DEFAULT_OTC_FTASSET,
} from "src/config";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import SpotAmountSlider from "src/pages/trade/spot/SpotAmountSlider";
import DarkWhiteLoadingSvg from "src/assets/icons/dark-white-loading.svg";
import { FtAsset } from "src/type/zkkontos";
import { fetchSpecificFtAsset } from "src/service/trade-service";
import {
  calculateBySlippage,
  isFavoriteFtAsset,
  shortAddress,
  sleep,
} from "src/utils/helper";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import { useTranslation } from "react-i18next";
import questionIco from "src/assets/icons/trade/question.svg";
import { TipsBuyMaxAvailableV2 } from "src/components/tips/TipsText";
import { useSearchParams } from "react-router-dom";
import { useStores } from "src/hooks/useStore";
import { AnimatedLayout } from "src/components/tab/AnimatedLayout";
import { ContractInteraction } from "src/pages/contract-interaction/ContractInteraction";
import { ContractInteractionType } from "src/pages/contract-interaction/types";
import FloatingButton from "../FloatingButton";
import { SpotAssetPanelV2 } from "./SpotAssetPanelV2";
import { SpotMode } from "src/store/trade/SpotStore";
import { debounce } from "lodash";
import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "../asset-select/ToBuyAssetSelector";
import { useTradeViewAsset } from "../TradeDashboard";
import { PriceChangeButton } from "./PriceChangeButton";
import { TradeInput } from "../TradeInput";
import { runInAction } from "mobx";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { SlippageSetup } from "../common/SlippageSetup";
import { TradeBreakdownViewV2 } from "../common/TradeBreakdownViewV2";
import {
  BreakDownAssetIcon,
  BreakDownLineWrapper,
  DarkBreakDownText,
  LightBreakDownText,
  TradeBreakdownEditableItem,
} from "../common/TradeBreakdownEditableItem";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import { ReceiverSelectModal } from "../common/ReceiverSelectModal";
import { SpotSwitch } from "./SpotSwitch";
import { ReceivingAssetButtonV2 } from "./ReceivingAssetButtonV2";
import { PaymentData, PaymentMode } from "src/store/trade/PaymentStore";
import SelectAssetsModal from "../SelectAssetsModal";
import { BatchSellItem } from "./batch-sell/BatchSellItem";
import { BatchSellPlaceholder } from "./batch-sell/BatchSellPlaceholder";
import { ethers } from "ethers";
import Floater from "react-floater";
import { UserBalance } from "./UserBalance";
import { TradeAiPanel } from "../common/TradeAiPanel";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useSetState } from "react-use";
import { JoyRideToolTip } from "src/components/onboarding/JoyRideTooltip";
import { OnboardingType } from "src/store/localKeeper";

export enum CallTaskDataPurpose {
  Input,
  Result,
  Both,
}

const MAX_BATCH_SELL = 10;

const getInteractionTarget = (mode: SpotMode): string => {
  switch (mode) {
    case SpotMode.Buy:
      return "Kontos Trade · Buy";
    case SpotMode.Sell:
      return "Kontos Trade · Sell";
    case SpotMode.BatchSell:
      return "Kontos Trade · Batch Sell";
  }
};

const getInteractionType = (mode: SpotMode): ContractInteractionType => {
  switch (mode) {
    case SpotMode.Buy:
      return ContractInteractionType.BuyToken;
    case SpotMode.Sell:
      return ContractInteractionType.SellToken;
    case SpotMode.BatchSell:
      return ContractInteractionType.BatchSell;
  }
};

const Wrapper = styled.div<{ $isOverWidth?: boolean; $enabelAiBox?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: visible;
  padding: 0 16px;

  /* common style for box */
  .trade-box {
    background: var(--White, #fff);
  }

  .text-up {
    color: var(--Success, #10ce5c);
  }

  .text-down {
    color: var(--error-notice, #ff1e2b);
  }

  .trade-amount {
    z-index: 2;
    /* margin-top: 8px; */
    padding: 12px 16px 14px 16px;
    display: flex;
    flex-direction: column;

    .trade-amount-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // align-items: flex-start;

      .trade-amount-top-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: var(--Deep-400, #80868f);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }

      .trade-amount-top-market {
        align-self: flex-start;
      }
    }

    .trade-amount-bottom {
      margin: 20px 9.5px 0 9.5px;
    }
  }

  .trade-limit {
    margin-top: 8px;
  }

  .trade-tpsl {
    margin-top: 8px;
  }

  .trade-breakdown {
    margin-top: 8px;
    margin-bottom: 96px;
  }

  .trade-open {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(16px + var(--navi-height));
    width: 95%;
    z-index: 101;
  }
`;

const MainAssetsArea = styled.div`
  margin-top: 10px;
`;

const BatchSellPanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const TradeAmountValueText = styled.span`
  height: 16px;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const BalanceBox = styled.div<{ $clickable: boolean }>`
  z-index: 20;
  margin-top: -2px;
  padding: 20px 0 0 0;
  text-align: right;

  /* border-radius: 0px 0px 8px 8px; */
  background: var(--White, #fff);

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 12px;

  .trade-balance {
    display: flex;
    align-items: center;

    .balance-detail-icon {
      margin-left: 6px;
      width: 12.386px;
      height: 12px;
      flex-shrink: 0;
    }
  }

  .trade-question {
    margin-left: 6px;

    width: 12px;
    height: 12px;

    cursor: help;
  }

  .clickable {
    ${(props) =>
      props.$clickable
        ? "cursor: pointer; -webkit-tap-highlight-color: transparent;"
        : ""}

    @media (hover: hover) {
      &:hover {
        img {
          margin-top: -2px;
        }
      }
    }

    &:active {
      img {
        margin-top: -2px;
      }
    }
  }
`;

const CommonText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const StrongText = styled.span`
  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

export const BottomPlaceholder = styled.div`
  width: 100%;
`;

export const BlankFilledArea = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const DarkBtnText = styled.span`
  color: var(--Deep-600, #4d5662);
  font-family: HarmonyOS Sans Bold;
  font-size: 18px;
`;

export const LightBtnText = styled.span`
  color: var(--White, #fff);
  font-family: HarmonyOS Sans Bold;
  font-size: 18px;
`;

const ModuleWrapper = styled.div`
  margin-top: 10px;
`;

const precision = 7;
const displayPrecision = DEFAULT_DISPLAY_PRECESION;
const DEBOUNCE_TIME = 500;
const REFRESH_TIME = 60000;
const MIN_SPOT_INPUT = 0.01;
interface SellOnboardingState {
  run: boolean;
  steps: Step[];
  spotLightRadius: string;
}
const SELL_ONBOARDING_TARGET = "trade-amount-top-market";

export const TradeSpot: React.FC = observer(() => {
  const {
    userStore,
    uiStore,
    spotStore,
    chainStore,
    favStore,
    paymentStore,
    swapStore,
    tradeStore,
  } = useStores();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [showSelectAssets, setShowSelectAssets] = useState(false);
  const [showSelectReceiveAssets, setShowSelectReceiveAssets] = useState(false);
  const [showContractInteraction, setShowContractInteraction] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [innerModalOpen, setInnerModalOpen] = useState(false);
  const [showTips, setShowTips] = useState<boolean>(false);
  const { viewAssetInfo } = useTradeViewAsset();
  // Input Loading
  const [inputLoadingCount, setInputLoadingCount] = useState(0);
  const addInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount - 1);
  }, []);
  // Result Loading
  const [resultLoadingCount, setResultLoadingCount] = useState(0);
  const addResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount - 1);
  }, []);
  const [showSlippage, setShowSlippage] = useState<boolean>(false);
  const [showReceiver, setShowReceiver] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [sliderValue, setSliderValue] = useState<KontosNumber>(
    new KontosNumber(0)
  );
  const [toReplaceAsset, setToReplaceAsset] = useState<FtAsset | undefined>();
  const [showBalanceSheet, setShowBalanceSheet] = useState<boolean>(false);
  const [showAiScore, setShowAiScore] = useState<boolean>(true);
  const [showSellOnboarding] = useState(!tradeStore.fromAi);
  const [{ run, steps, spotLightRadius }] = useSetState<SellOnboardingState>({
    run: true,
    spotLightRadius: "99px",
    steps: [
      {
        content: (
          <>
            <span>{t("You can choose any assets you'd like to receive")}</span>
          </>
        ),
        target: `.${SELL_ONBOARDING_TARGET}`,
        title: t("Select Your Received Asset!"),
        disableBeacon: true,
        spotlightPadding: 0,
        offset: 0,
      },
    ],
  });
  const handleSellJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      userStore.markOnboardingFinished(OnboardingType.Sell);
    }
  };

  const openSelectAssetsSheet = useCallback((toReplace?: FtAsset) => {
    setToReplaceAsset(toReplace);
    setShowSelectAssets(true);
  }, []);

  const closeSelectAssetsSheet = useCallback(() => {
    setToReplaceAsset(undefined);
    setShowSelectAssets(false);
  }, []);

  const closeModals = useCallback(() => {
    closeSelectAssetsSheet();
    setShowSelectReceiveAssets(false);
    setShowContractInteraction(false);
    setShowReceiver(false);
    setShowBalanceSheet(false);
    setShowSlippage(false);
    setShowReceiver(false);
  }, [closeSelectAssetsSheet]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: !innerModalOpen && !searchParams.get("showQuote"),
  });

  const onInnerModalChange = useCallback((isOpen: boolean) => {
    setInnerModalOpen(isOpen);
  }, []);

  const handleSpotModeChange = useCallback(
    (mode: SpotMode) => {
      if (mode === spotStore.mode) {
        return;
      }
      spotStore.switchMode(mode);
      setSliderValue(new KontosNumber(0));
    },
    [spotStore]
  );

  const initToBuyFtAssetAndValue = useCallback(
    async (_ftAsset: FtAsset) => {
      const copyTradeObj = {
        chainIndex: searchParams.get("chainIndex") as string,
        address: searchParams.get("address") as string,
        usdValue: searchParams.get("usdValue") as string,
      };
      let _targetFtAsset = _ftAsset;
      if (
        !!copyTradeObj.address &&
        !!copyTradeObj.chainIndex &&
        copyTradeObj.usdValue
      ) {
        try {
          const respToken = await fetchSpecificFtAsset(
            copyTradeObj.chainIndex,
            copyTradeObj.address
          );
          _targetFtAsset = respToken || _ftAsset;
          runInAction(() => {
            spotStore.setToBuyFtAsset(_targetFtAsset);
          });
        } catch (e) {
          toast({
            text: t("Failed to get target token info"),
            type: "error",
          });
        }

        const toPayUsd = new KontosNumber(
          copyTradeObj.usdValue,
          DEFAULT_DECIMAL
        );
        if (toPayUsd.gt(0)) {
          spotStore.setToBuyFtAssetValue(toPayUsd.round(precision));
        }
      } else {
        if (!spotStore.toBuyFtAsset) {
          spotStore.setToBuyFtAsset(_targetFtAsset);
          return true;
        }
      }
    },
    [searchParams, spotStore, t]
  );

  const init = useCallback(
    async (retryCount: number = 3) => {
      switch (spotStore.mode) {
        case SpotMode.Buy:
          if (!spotStore.toBuyFtAsset) {
            const assets = await spotStore.fetchAndSetInitFtassets();
            if (assets !== undefined && assets.length > 0) {
              await initToBuyFtAssetAndValue(assets[0]);
            }
          }
          break;
        case SpotMode.Sell:
          if (
            spotStore.toSellFtAsset &&
            !isSameFtAsset(
              spotStore.toSellFtAsset,
              spotStore.toReceiveFtAsset
            ) &&
            new KontosNumber(
              spotStore.toSellFtAsset.balance,
              DEFAULT_DECIMAL
            ).gt(0)
          ) {
            return;
          }

          if (
            spotStore.toSellFtAsset &&
            new KontosNumber(
              spotStore.toSellFtAsset.balance,
              DEFAULT_DECIMAL
            ).lt(spotStore.toSellFtAssetQuantity || 0)
          ) {
            spotStore.resetSellInput();
          }

          if (userStore.accountBalances) {
            const userHoldings = userStore.userHoldingsInWhitelist;
            let realTimeToSellFtAsset = spotStore.toSellFtAsset;
            // Check to sell
            if (!spotStore.toSellFtAsset) {
              realTimeToSellFtAsset = userHoldings?.[0];
              spotStore.resetSellInput();
              spotStore.setToSellFtAsset(realTimeToSellFtAsset);
            }
            // Check to receive
            if (
              !spotStore.toReceiveFtAsset ||
              isSameFtAsset(realTimeToSellFtAsset, spotStore.toReceiveFtAsset)
            ) {
              // Local data
              const toSearchReceiveAsset = !isSameFtAsset(
                userHoldings?.[0],
                DEFAULT_TO_BUY_FTASSET
              )
                ? DEFAULT_TO_BUY_FTASSET_STANDARD
                : DEFAULT_TO_BUY_FTASSET_2_STANDARD;
              // spotStore.setToReceiveFtAsset(toSearchReceiveAsset);
              // Async but true data
              await spotStore.fetchAndSetDefaultToReceiveFtAsset(
                toSearchReceiveAsset
              );
            }
          } else {
            if (retryCount > 0) {
              await userStore.fetchAndUpdateAccountBalances();
              init(retryCount - 1);
            }
          }
          break;
        case SpotMode.BatchSell:
          if (!spotStore.toBuyFtAssetForBatchSell) {
            const toReceiveAsset = DEFAULT_TO_BUY_FTASSET_STANDARD;
            spotStore.fetchAndSetDefaultToReceiveFtAsset(toReceiveAsset);
          }
      }
    },
    [initToBuyFtAssetAndValue, spotStore, userStore]
  );

  // Init & mode switch effect
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotStore.mode]);

  const startTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          break;
        case CallTaskDataPurpose.Both:
          loadingStore.showLoading();
          break;
        default:
          break;
      }
    },
    [addInputLoadingCount, addResultLoadingCount]
  );

  const endTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          minusInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          minusResultLoadingCount();
          break;
        case CallTaskDataPurpose.Both:
          loadingStore.hideLoading();
          break;
        default:
          break;
      }
    },
    [minusInputLoadingCount, minusResultLoadingCount]
  );

  const prepareTaskDataLoading = useCallback(
    (
      purpose: CallTaskDataPurpose,
      debounceTime: number = DEBOUNCE_TIME + 50
    ) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          setTimeout(() => {
            minusInputLoadingCount();
          }, debounceTime);
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          setTimeout(() => {
            minusResultLoadingCount();
          }, debounceTime);
          break;
        default:
          break;
      }
    },
    [
      addInputLoadingCount,
      addResultLoadingCount,
      minusInputLoadingCount,
      minusResultLoadingCount,
    ]
  );

  const callFetchAndSetTaskData = useCallback(
    async (purpose: CallTaskDataPurpose, successCallback?: () => void) => {
      try {
        startTaskDataLoading(purpose);
        const resp = await spotStore.fetchAndSetTaskData();
        if (resp?.isChainLiquidityInsufficient === true) {
          toast({
            type: "error",
            text: t("Chain liquidity insufficient, please try other chains"),
          });
          return;
        }
        !!resp && successCallback?.();
        return resp;
      } catch (e) {
        console.log("Failed to fetch taskdata", e);
        const errorMessage =
          e instanceof Error
            ? parseApiErrorMessage(e).message
            : "An unknown error occurred when calculating task data";
        if (errorMessage.toLocaleLowerCase() !== "canceled") {
          toast({ type: "error", text: errorMessage });
        }
        runInAction(() => {
          spotStore.resetTaskData();
        });
      } finally {
        endTaskDataLoading(purpose);
      }
    },
    [endTaskDataLoading, spotStore, startTaskDataLoading, t]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchTaskData = useCallback(
    debounce((purpose: CallTaskDataPurpose, successCallback?: () => void) => {
      callFetchAndSetTaskData(purpose, successCallback);
    }, DEBOUNCE_TIME),
    []
  );

  const prepareAndDebouncedFetchTaskData = useCallback(
    (purpose: CallTaskDataPurpose, successCallback?: () => void) => {
      prepareTaskDataLoading(purpose);
      debouncedFetchTaskData(purpose, successCallback);
    },
    [debouncedFetchTaskData, prepareTaskDataLoading]
  );

  const hanldeUpdateFtAssetFavs = useCallback(
    async (ftAsset: FtAsset) => {
      const cli = userStore.kontosCli;
      if (!cli) {
        userStore.unlock(() => hanldeUpdateFtAssetFavs(ftAsset));
        return;
      }

      try {
        loadingStore.showLoading();
        if (!isFavoriteFtAsset(ftAsset, favStore.ftAssetFavorites))
          await favStore.addFavorites({ ftAssetId: ftAsset.ftAssetId, cli });
        else
          await favStore.removeFavorites({ ftAssetId: ftAsset.ftAssetId, cli });
      } catch (e) {
        console.log("Failed to update favorites", e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : t("Failed to save. Please try again later.");
        toast({
          type: "error",
          text: errorMessage,
        });
      } finally {
        loadingStore.hideLoading();
      }
    },
    [favStore, t, userStore]
  );

  const handleViewAssetInfo = useCallback(
    (asset: FtAsset) => {
      viewAssetInfo(asset);
    },
    [viewAssetInfo]
  );

  // Since maxavailable is fetched from taskdata
  // Fetch taskdata each time buy/batchsell target asset changes
  useEffect(() => {
    switch (spotStore.mode) {
      case SpotMode.Buy:
        if (spotStore.toBuyFtAsset && !spotStore.toBuyFtAssetValue)
          prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Input);
        break;
      case SpotMode.Sell:
        if (
          spotStore.toSellFtAsset &&
          spotStore.toReceiveFtAsset &&
          !spotStore.toSellFtAssetQuantity
        )
          prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Input);
        break;
      case SpotMode.BatchSell:
        if (
          spotStore.toBuyFtAssetForBatchSell &&
          spotStore.toBatchSellFtAssets.length >= 0 &&
          !spotStore.toBuyFtAssetValueForBatchSell
        ) {
          if (spotStore.toBatchSellFtAssets.length > 0)
            prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Input);
          else spotStore.resetTaskData();
        }
        break;
      default:
        break;
    }
  }, [
    spotStore.toBuyFtAsset,
    spotStore.toBuyFtAssetValue,
    spotStore.toBuyFtAssetForBatchSell,
    spotStore.mode,
    prepareAndDebouncedFetchTaskData,
    spotStore.toSellFtAsset,
    spotStore.toSellFtAssetQuantity,
    spotStore.toReceiveFtAsset,
    spotStore.toBatchSellFtAssets.length,
    spotStore.toBuyFtAssetValueForBatchSell,
    spotStore,
    tradeStore.payableChangeCount,
  ]);

  // specific hook for batch sell
  // When input value exists and user changes asstes to sell
  useEffect(() => {
    if (
      spotStore.mode === SpotMode.BatchSell &&
      spotStore.toBuyFtAssetValueForBatchSell
    ) {
      if (spotStore.toBatchSellFtAssets.length > 0)
        prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Input);
      else spotStore.resetTaskData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotStore.mode, spotStore.toBatchSellFtAssets.length]);

  // Update task data automatically
  useEffect(() => {
    const cronjob = (): boolean => {
      if (!spotStore.receiver || !spotStore.slippage) {
        return false;
      }
      switch (spotStore.mode) {
        case SpotMode.Buy:
          if (spotStore.toBuyFtAsset && spotStore.toBuyFtAssetValue?.gt(0)) {
            if (tradeStore.fromAi) {
              prepareAndDebouncedFetchTaskData(
                CallTaskDataPurpose.Both,
                handleConfirm
              );
              tradeStore.resetAiFields();
            } else prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Result);
            return true;
          } else {
            return false;
          }
        case SpotMode.Sell:
          if (
            spotStore.toSellFtAsset &&
            spotStore.toReceiveFtAsset &&
            spotStore.toSellFtAssetQuantity?.gt(0)
          ) {
            prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Result);
            return true;
          } else {
            return false;
          }
        case SpotMode.BatchSell:
          if (
            spotStore.toBuyFtAssetForBatchSell &&
            spotStore.toBatchSellFtAssets.length > 0 &&
            spotStore.toBuyFtAssetValueForBatchSell?.gt(0)
          ) {
            prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Result);
            return true;
          } else {
            return false;
          }
        default:
          return false;
      }
    };

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (!showContractInteraction) {
      const res = cronjob();
      if (res) {
        timerRef.current = setInterval(() => {
          const flag = cronjob();
          if (!flag && timerRef.current) {
            clearInterval(timerRef.current);
          }
        }, REFRESH_TIME);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedFetchTaskData,
    spotStore.receiver,
    spotStore.toBuyFtAssetForBatchSell,
    spotStore.toBatchSellFtAssets.length,
    spotStore.toBuyFtAsset,
    spotStore.toBuyFtAssetValue,
    spotStore.toReceiveFtAsset,
    spotStore.toSellFtAsset,
    spotStore.toSellFtAssetQuantity,
    spotStore.mode,
    spotStore.slippage,
    prepareAndDebouncedFetchTaskData,
    showContractInteraction,
    spotStore.toBuyFtAssetValueForBatchSell,
    tradeStore.payableChangeCount,
    tradeStore,
  ]);

  // Check can execute
  const checkState = useMemo<true | BuyErrorType>(() => {
    if (!userStore.accountName) {
      return BuyErrorType.NoAccount;
    }
    return true;
  }, [userStore.accountName]);

  const handleInputChange = useCallback(
    (amount: KontosNumber) => {
      setSliderValue(new KontosNumber(0));
      const toSetValue = amount.eq(0) ? undefined : amount;
      switch (spotStore.mode) {
        case SpotMode.Buy:
          spotStore.setToBuyFtAssetValue(toSetValue);
          break;
        case SpotMode.Sell:
          spotStore.setToSellFtAssetQuantity(toSetValue);
          break;
        case SpotMode.BatchSell:
          spotStore.setToBuyFtAssetValueForBatchSell(toSetValue);
          break;
        default:
          break;
      }
    },
    [spotStore]
  );

  const handleSliderChange = useCallback(
    (percent: number) => {
      const formattedPercent = new KontosNumber(percent).round(2);
      setSliderValue(formattedPercent);

      switch (spotStore.mode) {
        case SpotMode.Buy:
        case SpotMode.BatchSell:
          const buyValue = spotStore.maxAvailableForTargetFtAsset
            ?.multiply(formattedPercent)
            ?.round(precision);
          const buyValueFormatted =
            !buyValue || buyValue.gt(MIN_SPOT_INPUT)
              ? buyValue
              : new KontosNumber(MIN_SPOT_INPUT);
          if (spotStore.mode === SpotMode.Buy) {
            spotStore.setToBuyFtAssetValue(buyValueFormatted);
            return;
          }
          if (spotStore.mode === SpotMode.BatchSell) {
            spotStore.setToBuyFtAssetValueForBatchSell(buyValueFormatted);
            return;
          }
          break;
        case SpotMode.Sell:
          const sellQuantity = spotStore.balanceOfToSellFtAsset
            ?.multiply(formattedPercent)
            ?.round(precision);
          spotStore.setToSellFtAssetQuantity(sellQuantity);
          break;
        default:
          break;
      }
    },
    [spotStore]
  );

  const handleSelectToBuyFtAsset = useCallback(
    (asset: FtAsset) => {
      setSliderValue(new KontosNumber(0));
      switch (spotStore.mode) {
        case SpotMode.Buy:
          spotStore.setToBuyFtAsset(asset);
          spotStore.resetBuyInput();
          closeSelectAssetsSheet();
          break;
        case SpotMode.BatchSell:
          spotStore.setToBuyFtAssetForBatchSell(asset);
          spotStore.resetBatchSellInput();
          closeSelectAssetsSheet();
          break;
        default:
          break;
      }
    },
    [closeSelectAssetsSheet, spotStore]
  );

  const handleSelectToSellFtAsset = useCallback(
    (asset: FtAsset) => {
      if (isSameFtAsset(asset, spotStore.toReceiveFtAsset)) {
        toast({
          text: t(
            "You cannot sell the asset you wish to receive. Please select a different asset to sell or change the asset you wish to receive."
          ),
          type: "warning",
        });
        return;
      }
      setSliderValue(new KontosNumber(0));
      spotStore.setToSellFtAsset(asset);
      spotStore.resetSellInput();
      closeSelectAssetsSheet();
    },
    [closeSelectAssetsSheet, spotStore, t]
  );

  const handleSelectToBatchSellFtAsset = useCallback(
    (asset: FtAsset) => {
      if (isSameFtAsset(asset, spotStore.setToBuyFtAssetForBatchSell)) {
        toast({
          text: t(
            "You cannot sell the asset you wish to receive. Please select a different asset to sell or change the asset you wish to receive."
          ),
          type: "warning",
        });
        return;
      }
      if (
        spotStore.toBatchSellFtAssets.some((item) => isSameFtAsset(item, asset))
      ) {
        toast({
          text: t("You have chosen this asset."),
          type: "warning",
        });
        return;
      }
      if (toReplaceAsset) {
        spotStore.replaceBatchSellFtAsset(asset, toReplaceAsset);
      } else {
        spotStore.addToBatchSellFtAsset(asset);
      }
      closeSelectAssetsSheet();
    },
    [closeSelectAssetsSheet, spotStore, t, toReplaceAsset]
  );

  const handleChooseSubAssets = useCallback(
    (asset: FtAsset) => {
      switch (spotStore.mode) {
        case SpotMode.Sell:
          if (isSameFtAsset(asset, spotStore.toSellFtAsset)) {
            toast({
              text: t(
                "You cannot sell and receive the same token. Please select two different tokens."
              ),
              type: "warning",
            });
            return;
          }
          spotStore.setToReceiveFtAsset(asset);
          setShowSelectReceiveAssets(false);
          return;
        case SpotMode.BatchSell:
          if (
            spotStore.toBatchSellFtAssets.some((item) =>
              isSameFtAsset(item, asset)
            )
          ) {
            toast({
              text: t(
                "You cannot buy a token that is in your sell list. Please select two different tokens."
              ),
              type: "warning",
            });
            return;
          }
          spotStore.setToBuyFtAssetForBatchSell(asset);
          setShowSelectReceiveAssets(false);
          return;
      }
    },
    [spotStore, t]
  );

  const handleConfirm = useCallback(async () => {
    if (checkState !== true) {
      toast({
        type: "warning",
        text: <span>{t(getBuyErrorText(checkState))}</span>,
      });
      return;
    }

    if (spotStore.mode !== SpotMode.Buy && spotStore.insufficient) {
      return;
    }

    let taskdata = spotStore.taskData;

    if (!spotStore.orderPrice || spotStore.orderPrice.eq(0)) {
      loadingStore.showLoading();
      taskdata = await callFetchAndSetTaskData(CallTaskDataPurpose.Result);
      loadingStore.hideLoading();
      if (!taskdata) return;
    }

    if (taskdata?.isChainUnderSyncing) {
      toast({
        text: t(
          "Your previous transaction is still syncing on the blockchain. Please try again later."
        ),
        type: "warning",
      });
      return;
    }

    switch (spotStore.mode) {
      case SpotMode.Sell:
      case SpotMode.BatchSell:
        if (!spotStore.insufficient) {
          paymentStore.generateByPlan();
        }
        break;
      case SpotMode.Buy:
        if (spotStore.insufficient) {
          const paymentData: PaymentData = {
            mode: isSameFtAsset(
              spotStore.toBuyFtAsset,
              TRADE_DEFAULT_OTC_FTASSET
            )
              ? PaymentMode.Receive
              : PaymentMode.OTC,
            fetchAndSetTaskData: spotStore.fetchAndSetTaskData,
            targetFtAsset: spotStore.toBuyFtAsset!,
            targetFtAssetQuantity: spotStore.toBuyFtAssetQuantity!,
            targetFtAssetValue: spotStore.toBuyFtAssetValue!,
            receiveScope: {
              enable: true,
            },
            otcScope: {
              enable: !isSameFtAsset(
                spotStore.toBuyFtAsset,
                TRADE_DEFAULT_OTC_FTASSET
              ),
            },
          };
          paymentStore.generateByOthers(paymentData);
        } else {
          paymentStore.generateByPlan();
        }
    }

    setShowContractInteraction(true);
    return;
  }, [
    checkState,
    spotStore.mode,
    spotStore.insufficient,
    spotStore.taskData,
    spotStore.orderPrice,
    spotStore.toBuyFtAsset,
    spotStore.fetchAndSetTaskData,
    spotStore.toBuyFtAssetQuantity,
    spotStore.toBuyFtAssetValue,
    t,
    callFetchAndSetTaskData,
    paymentStore,
  ]);

  const handleInteractionFail = useCallback((e: any) => {
    setShowContractInteraction(false);
  }, []);

  const handleInteractionCancel = useCallback(() => {
    setShowContractInteraction(false);
  }, []);

  const handleInteractionSuccess = useCallback(async () => {
    setShowContractInteraction(false);
    spotStore.resetAfterRegisteringTask();
    swapStore.reset();
    await sleep(1000);
    // await userStore.fetchAndUpdateAccountBalances();
    await init();
    debouncedFetchTaskData(CallTaskDataPurpose.Input);
  }, [debouncedFetchTaskData, init, spotStore, swapStore]);

  // View Logic
  const inputAttachment:
    | {
        chainIcon: string;
        quantity: KontosNumber | undefined;
        symbol: string;
        requesting: boolean;
      }
    | undefined = useMemo(() => {
    switch (spotStore.mode) {
      case SpotMode.Buy:
        return (
          spotStore?.toBuyFtAsset && {
            chainIcon: spotStore.toBuyFtAsset.imageUrl,
            quantity: spotStore.toBuyFtAssetQuantity,
            symbol: spotStore.toBuyFtAsset.symbol,
            requesting: resultLoadingCount > 0,
          }
        );
      case SpotMode.Sell:
        return (
          spotStore?.toReceiveFtAsset && {
            chainIcon: spotStore.toReceiveFtAsset.imageUrl,
            quantity: spotStore.toReceiveFtAssetQuantity,
            symbol: spotStore.toReceiveFtAsset.symbol,
            requesting: resultLoadingCount > 0,
          }
        );
      case SpotMode.BatchSell:
        return (
          spotStore?.toBuyFtAssetForBatchSell && {
            chainIcon: spotStore.toBuyFtAssetForBatchSell.imageUrl,
            quantity: spotStore.toBuyFtAssetQuantity,
            symbol: spotStore.toBuyFtAssetForBatchSell.symbol,
            requesting: resultLoadingCount > 0,
          }
        );
    }
  }, [
    spotStore.mode,
    spotStore.toBuyFtAsset,
    spotStore.toBuyFtAssetQuantity,
    spotStore.toReceiveFtAsset,
    spotStore.toReceiveFtAssetQuantity,
    spotStore.toBuyFtAssetForBatchSell,
    resultLoadingCount,
  ]);

  const spotLabel: { inputIntro: string; maxAvailableOrMinRequired: string } =
    useMemo(() => {
      switch (spotStore.mode) {
        case SpotMode.Buy:
          return {
            inputIntro: t("Purchase value: "),
            maxAvailableOrMinRequired: t("Maximum available: "),
          };
        case SpotMode.Sell:
          return {
            inputIntro: t("Receiving assets: "),
            maxAvailableOrMinRequired: t("Min required: "),
          };
        case SpotMode.BatchSell:
          return {
            inputIntro: t("Receiving assets: "),
            maxAvailableOrMinRequired: t("Maximum available: "),
          };
        default:
          return {
            inputIntro: "",
            maxAvailableOrMinRequired: "",
          };
      }
    }, [spotStore.mode, t]);

  const sliderDisabled: boolean = useMemo(() => {
    switch (spotStore.mode) {
      case SpotMode.Buy:
      case SpotMode.BatchSell:
        return !spotStore.maxAvailableForTargetFtAsset?.gt(0);
      case SpotMode.Sell:
        return !spotStore.balanceOfToSellFtAsset?.gt(0);
      default:
        return false;
    }
  }, [
    spotStore.balanceOfToSellFtAsset,
    spotStore.maxAvailableForTargetFtAsset,
    spotStore.mode,
  ]);

  const maxAvailableOrMinRequiredValueView: ReactNode = useMemo(() => {
    switch (spotStore.mode) {
      case SpotMode.Buy:
        return inputLoadingCount > 0 ||
          !spotStore.toBuyFtAsset ||
          !spotStore.maxAvailableForTargetFtAsset ? (
          <EllipsisPlaceholder />
        ) : (
          spotStore.maxAvailableForTargetFtAsset.toFormat() +
            " " +
            spotStore.inputSymbol
        );
      case SpotMode.BatchSell:
        return inputLoadingCount > 0 ? (
          <EllipsisPlaceholder />
        ) : !spotStore.toBuyFtAssetForBatchSell ? (
          "-"
        ) : !spotStore.maxAvailableForTargetFtAsset ? (
          "0.00"
        ) : (
          spotStore.maxAvailableForTargetFtAsset.toFormat() +
          " " +
          spotStore.inputSymbol
        );
      case SpotMode.Sell:
        return inputLoadingCount > 0 ? (
          <EllipsisPlaceholder />
        ) : !spotStore.toSellFtAsset ||
          !spotStore.minRequiredForTargetFtAsset ? (
          "-"
        ) : (
          spotStore.minRequiredForTargetFtAsset.toFormat() +
          " " +
          spotStore.inputSymbol
        );
      default:
        return "-";
    }
  }, [
    inputLoadingCount,
    spotStore.inputSymbol,
    spotStore.maxAvailableForTargetFtAsset,
    spotStore.minRequiredForTargetFtAsset,
    spotStore.toBuyFtAsset,
    spotStore.toBuyFtAssetForBatchSell,
    spotStore.toSellFtAsset,
    spotStore.mode,
  ]);

  const balanceValueView: ReactNode = useMemo(() => {
    switch (spotStore.mode) {
      case SpotMode.Buy:
      case SpotMode.BatchSell:
        return (
          spotStore.totalBalanceInUsd?.toFormat() + " " + spotStore.inputSymbol
        );
      case SpotMode.Sell:
        return spotStore.balanceOfToSellFtAsset
          ? spotStore.balanceOfToSellFtAsset?.toFormat() +
              " " +
              spotStore.inputSymbol
          : "-";
      default:
        return "-";
    }
  }, [
    spotStore.balanceOfToSellFtAsset,
    spotStore.inputSymbol,
    spotStore.totalBalanceInUsd,
    spotStore.mode,
  ]);

  const mainBtnContext: {
    children?: ReactNode;
    disabled?: boolean;
    icon?: any;
    loading?: boolean;
    callback?: () => void;
  } = useMemo(() => {
    switch (spotStore.mode) {
      case SpotMode.Buy:
        if (!spotStore.toBuyFtAssetValue) {
          return {
            children: <DarkBtnText>{t("Input Value to Continue")}</DarkBtnText>,
            disabled: true,
            icon: null,
            loading: false,
          };
        }
        if (resultLoadingCount > 0) {
          return {
            children: <DarkBtnText>{t("Calculating...")}</DarkBtnText>,
            disabled: true,
            icon: DarkWhiteLoadingSvg,
            loading: true,
          };
        }
        if (
          spotStore.hasValidResultTaskData &&
          resultLoadingCount === 0 &&
          inputLoadingCount === 0
        ) {
          return {
            children: <LightBtnText>{t("Confirm")}</LightBtnText>,
            disabled: false,
            icon: null,
            loading: false,
            callback: handleConfirm,
          };
        }
        break;
      case SpotMode.Sell:
        if (!spotStore.toReceiveFtAsset) {
          return {
            children: (
              <LightBtnText>{t("Select asset to receive")}</LightBtnText>
            ),
            disabled: false,
            icon: null,
            loading: false,
            callback: () => {
              setShowSelectReceiveAssets(true);
            },
          };
        }
        if (!spotStore.toSellFtAssetQuantity) {
          return {
            children: (
              <DarkBtnText>{t("Input Quantity to Continue")}</DarkBtnText>
            ),
            disabled: true,
            icon: null,
            loading: false,
          };
        }

        if (resultLoadingCount > 0) {
          return {
            children: <DarkBtnText>{t("Calculating...")}</DarkBtnText>,
            disabled: true,
            icon: DarkWhiteLoadingSvg,
            loading: true,
          };
        }
        if (spotStore.insufficient) {
          return {
            children: <DarkBtnText>{t("Insufficient quantity")}</DarkBtnText>,
            disabled: true,
            icon: null,
            loading: false,
          };
        }
        // mark if resut taskdata returned
        if (
          spotStore.hasValidResultTaskData &&
          resultLoadingCount === 0 &&
          inputLoadingCount === 0
        ) {
          return {
            children: <LightBtnText>{t("Confirm")}</LightBtnText>,
            disabled: false,
            icon: null,
            loading: false,
            callback: handleConfirm,
          };
        }
        break;
      case SpotMode.BatchSell:
        if (spotStore.toBatchSellFtAssets.length === 0) {
          return {
            children: <DarkBtnText>{t("Choose assets to sell")}</DarkBtnText>,
            disabled: true,
            icon: null,
            loading: false,
          };
        }
        if (!spotStore.toBuyFtAssetForBatchSell) {
          return {
            children: (
              <LightBtnText>{t("Select asset to receive")}</LightBtnText>
            ),
            disabled: false,
            icon: null,
            loading: false,
            callback: () => {
              setShowSelectReceiveAssets(true);
            },
          };
        }
        if (!spotStore.toBuyFtAssetValueForBatchSell) {
          return {
            children: <DarkBtnText>{t("Input Value to Continue")}</DarkBtnText>,
            disabled: true,
            icon: null,
            loading: false,
          };
        }
        if (resultLoadingCount > 0) {
          return {
            children: <DarkBtnText>{t("Calculating...")}</DarkBtnText>,
            disabled: true,
            icon: DarkWhiteLoadingSvg,
            loading: true,
          };
        }
        if (spotStore.insufficient) {
          return {
            children: <DarkBtnText>{t("Insufficient quantity")}</DarkBtnText>,
            disabled: true,
            icon: null,
            loading: false,
          };
        }
        if (
          spotStore.hasValidResultTaskData &&
          resultLoadingCount === 0 &&
          inputLoadingCount === 0
        ) {
          return {
            children: <LightBtnText>{t("Confirm")}</LightBtnText>,
            disabled: false,
            icon: null,
            loading: false,
            callback: handleConfirm,
          };
        }
    }

    if (
      spotStore.taskData &&
      spotStore.taskData.isChainLiquidityInsufficient === true
    ) {
      return {
        children: <DarkBtnText>{t("Liquidity Insufficient")}</DarkBtnText>,
        disabled: true,
        icon: DarkWhiteLoadingSvg,
        loading: false,
      };
    }

    return {
      children: <DarkBtnText>...</DarkBtnText>,
      disabled: true,
      icon: DarkWhiteLoadingSvg,
      loading: true,
    };
  }, [
    spotStore.taskData,
    spotStore.mode,
    spotStore.toBuyFtAssetValue,
    spotStore.hasValidResultTaskData,
    spotStore.toReceiveFtAsset,
    spotStore.toSellFtAssetQuantity,
    spotStore.insufficient,
    spotStore.toBatchSellFtAssets.length,
    spotStore.toBuyFtAssetForBatchSell,
    spotStore.toBuyFtAssetValueForBatchSell,
    t,
    resultLoadingCount,
    inputLoadingCount,
    handleConfirm,
  ]);

  // useEffect(() => {
  //   // If the user did not provide a usdAmount, the confirm sheet should not open automatically
  //   if (!spotStore.toBuyFtAssetValue || spotStore.toBuyFtAssetValue.eq(0)) {
  //     tradeStore.setFromAi(false);
  //   }
  //   if (
  //     spotStore.mode === SpotMode.Buy &&
  //     !mainBtnContext.disabled &&
  //     tradeStore.fromAi
  //   ) {
  //     tradeStore.setFromAi(false);
  //     handleConfirm();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mainBtnContext, spotStore.toBuyFtAssetValue]);

  // useEffect(() => {
  //   const executeAiTaskData = async () => {
  //     if (
  //       tradeStore.fromAi &&
  //       spotStore.mode === SpotMode.Buy &&
  //       spotStore.toBuyFtAsset &&
  //       spotStore.toBuyFtAssetValue?.gt(0) &&
  //       spotStore.receiver &&
  //       spotStore.slippage.gt(0)
  //     ) {
  //       loadingStore.showLoading();
  //       await callFetchAndSetTaskData(CallTaskDataPurpose.Both);
  //       loadingStore.hideLoading();
  //       handleConfirm();
  //     }
  //   };

  //   executeAiTaskData();

  //   return () => {
  //     tradeStore.resetAiFields();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const breakdownList: {
    label: string;
    value: ReactNode;
  }[] = useMemo(() => {
    const slippage = (
      <TradeBreakdownEditableItem
        text={
          spotStore.slippage.eq(DEFAULT_SLIPPAGE)
            ? "Auto (" + spotStore.slippage.multiply(100) + "%)"
            : spotStore.slippage.multiply(100) + "%"
        }
        onClick={() => setShowSlippage(true)}
      />
    );
    const receivingAddress = (
      <TradeBreakdownEditableItem
        prefixIcon={avatarIcon}
        text={
          spotStore.receiver
            ? ethers.utils.isAddress(spotStore.receiver)
              ? shortAddress(spotStore.receiver)
              : spotStore.receiver.replaceAll(".os", "") + ".os"
            : "-"
        }
        onClick={() => setShowReceiver(true)}
      />
    );
    const fee = (
      <LightBreakDownText>
        {/* fee */}
        {resultLoadingCount > 0 ? (
          <EllipsisPlaceholder />
        ) : (
          spotStore?.fee?.toFormat() || "-"
        )}
        {/* symbol */}
        {" USD"}
      </LightBreakDownText>
    );
    const orderPrice = (
      <DarkBreakDownText>
        {/* fee */}
        {resultLoadingCount > 0 ? (
          <>
            <EllipsisPlaceholder />{" "}
          </>
        ) : spotStore?.orderPrice?.gt(0) && spotStore.insufficient ? (
          "≈ " + spotStore?.orderPrice?.toFormatV2({ zeroPlaceholder: "-" }) ||
          "-"
        ) : (
          spotStore?.orderPrice?.toFormatV2({ zeroPlaceholder: "-" }) || "-"
        )}
        {" USD"}
      </DarkBreakDownText>
    );
    const estimatedExecutionTime = (
      <LightBreakDownText>{t("<10s")}</LightBreakDownText>
    );
    switch (spotStore.mode) {
      case SpotMode.Buy:
        return [
          {
            label: t("Slippage") + ": ",
            value: slippage,
          },
          {
            label: t("Minimum received") + ": ",
            value: (
              <BreakDownLineWrapper>
                <ImageWithFallback
                  src={spotStore.toBuyFtAsset?.imageUrl || defaultTokenIcon}
                  fallbackSrc={defaultTokenIcon}
                  StyledImg={BreakDownAssetIcon}
                />
                <DarkBreakDownText style={{ marginLeft: "4px" }}>
                  {/* quantity */}
                  {resultLoadingCount > 0 ? (
                    <EllipsisPlaceholder />
                  ) : spotStore.toBuyFtAssetQuantity ? (
                    calculateBySlippage(
                      spotStore.toBuyFtAssetQuantity,
                      spotStore.slippage
                    ).toFormat()
                  ) : (
                    "-"
                  )}
                  {/* symbol */}
                  {spotStore.toBuyFtAsset
                    ? " " + spotStore.toBuyFtAsset?.symbol
                    : ""}
                </DarkBreakDownText>
              </BreakDownLineWrapper>
            ),
          },
          {
            label: t("Receiving address") + ": ",
            value: receivingAddress,
          },
          {
            label: t("Fee") + ": ",
            value: fee,
          },
          {
            label: t("Order price"),
            value: orderPrice,
          },
          {
            label: t("Estimated execution time") + ": ",
            value: estimatedExecutionTime,
          },
        ];
      case SpotMode.Sell:
        return [
          {
            label: t("Slippage") + ": ",
            value: slippage,
          },
          {
            label: t("Minimum received") + ": ",
            value: (
              <BreakDownLineWrapper>
                <ImageWithFallback
                  src={spotStore.toReceiveFtAsset?.imageUrl || defaultTokenIcon}
                  fallbackSrc={defaultTokenIcon}
                  StyledImg={BreakDownAssetIcon}
                />
                <DarkBreakDownText style={{ marginLeft: "4px" }}>
                  {/* quantity */}
                  {resultLoadingCount > 0 ? (
                    <EllipsisPlaceholder />
                  ) : spotStore.toReceiveFtAssetQuantity &&
                    spotStore.toSellFtAssetQuantity?.gt(0) ? (
                    spotStore.toReceiveFtAssetQuantity
                      .multiply(spotStore.slippage.multiply(-1).add(1))
                      .toFormat()
                  ) : (
                    "-"
                  )}
                  {/* symbol */}
                  {spotStore.toReceiveFtAsset
                    ? " " + spotStore.toReceiveFtAsset?.symbol
                    : ""}
                </DarkBreakDownText>
              </BreakDownLineWrapper>
            ),
          },
          {
            label: t("Receiving address") + ": ",
            value: receivingAddress,
          },
          {
            label: t("Fee") + ": ",
            value: fee,
          },
          {
            label: t("Estimated execution time") + ": ",
            value: estimatedExecutionTime,
          },
        ];
      case SpotMode.BatchSell:
        return [
          {
            label: t("Slippage") + ": ",
            value: slippage,
          },
          {
            label: t("Minimum received") + ": ",
            value: (
              <BreakDownLineWrapper>
                <ImageWithFallback
                  src={
                    spotStore.toBuyFtAssetForBatchSell?.imageUrl ||
                    defaultTokenIcon
                  }
                  fallbackSrc={defaultTokenIcon}
                  StyledImg={BreakDownAssetIcon}
                />
                <DarkBreakDownText style={{ marginLeft: "4px" }}>
                  {/* quantity */}
                  {resultLoadingCount > 0 ? (
                    <EllipsisPlaceholder />
                  ) : spotStore.toBuyFtAssetQuantity ? (
                    calculateBySlippage(
                      spotStore.toBuyFtAssetQuantity,
                      spotStore.slippage
                    ).toFormat()
                  ) : (
                    "-"
                  )}
                  {/* symbol */}
                  {spotStore.toBuyFtAssetForBatchSell
                    ? " " + spotStore.toBuyFtAssetForBatchSell?.symbol
                    : ""}
                </DarkBreakDownText>
              </BreakDownLineWrapper>
            ),
          },
          {
            label: t("Receiving address") + ": ",
            value: receivingAddress,
          },
          {
            label: t("Fee") + ": ",
            value: fee,
          },
          {
            label: t("Order price"),
            value: orderPrice,
          },
          {
            label: t("Estimated execution time") + ": ",
            value: estimatedExecutionTime,
          },
        ];
      default:
        return [];
    }
  }, [
    spotStore.slippage,
    spotStore.receiver,
    spotStore?.fee,
    spotStore?.orderPrice,
    spotStore.insufficient,
    spotStore.mode,
    spotStore.toBuyFtAsset,
    spotStore.toBuyFtAssetQuantity,
    spotStore.toReceiveFtAsset,
    spotStore.toReceiveFtAssetQuantity,
    spotStore.toSellFtAssetQuantity,
    spotStore.toBuyFtAssetForBatchSell,
    resultLoadingCount,
    t,
  ]);

  const slippageSetupProps = useMemo(() => {
    switch (spotStore.mode) {
      case SpotMode.Buy:
        return {
          symbol: spotStore.toBuyFtAsset?.symbol,
          rawAmount: spotStore.toBuyFtAssetQuantity,
        };
      case SpotMode.Sell:
        return {
          symbol: spotStore.toReceiveFtAsset?.symbol,
          rawAmount: spotStore.toReceiveFtAssetQuantity,
        };
      case SpotMode.BatchSell:
        return {
          symbol: spotStore.toBuyFtAssetForBatchSell?.symbol,
          rawAmount: spotStore.toBuyFtAssetQuantity,
        };
    }
  }, [
    spotStore.mode,
    spotStore.toBuyFtAsset?.symbol,
    spotStore.toBuyFtAssetForBatchSell?.symbol,
    spotStore.toBuyFtAssetQuantity,
    spotStore.toReceiveFtAsset?.symbol,
    spotStore.toReceiveFtAssetQuantity,
  ]);

  useEffect(() => {
    uiStore.setTradeRouteSpot();
  }, [uiStore]);

  // Main
  return (
    <AnimatedLayout>
      <Wrapper
        $isOverWidth={uiStore.isOverWidth}
        ref={wrapperRef}
        $enabelAiBox={!tradeStore.disableAiScore && showAiScore}
      >
        {/* Buy/Sell Switch */}
        <SpotSwitch mode={spotStore.mode} onSwitch={handleSpotModeChange} />

        {/* Main asset/s selector */}
        <MainAssetsArea>
          {/* Spot FtAssets Panel */}
          {spotStore.mode === SpotMode.Buy && (
            <TradeAiPanel
              enableClick={true}
              assetSymbol={spotStore.toBuyFtAsset?.symbol}
              assetImageUrl={spotStore.toBuyFtAsset?.imageUrl}
              assetChainIndex={spotStore.toBuyFtAsset?.chainIndex}
              assetAddress={spotStore.toBuyFtAsset?.address}
              showAi={
                spotStore.mode === SpotMode.Buy &&
                !tradeStore.disableAiScore &&
                showAiScore
              }
              onBottomClick={() => openSelectAssetsSheet()}
              onClose={() => {
                setShowAiScore(false);
              }}
            >
              <SpotAssetPanelV2
                ftAsset={spotStore.toBuyFtAsset}
                requesting={!spotStore.toBuyFtAsset}
                favRequesting={favStore.initingFtAssetFav}
                isFavorite={isFavoriteFtAsset(
                  spotStore.toBuyFtAsset,
                  favStore.ftAssetFavorites
                )}
                onClick={() => openSelectAssetsSheet()}
                onFavClick={hanldeUpdateFtAssetFavs}
                onDetailClick={() =>
                  handleViewAssetInfo(spotStore.toBuyFtAsset!)
                }
              />
            </TradeAiPanel>
          )}

          {spotStore.mode === SpotMode.Sell && (
            <SpotAssetPanelV2
              ftAsset={spotStore.toSellFtAsset}
              requesting={false}
              favRequesting={false}
              isFavorite={isFavoriteFtAsset(
                spotStore.toSellFtAsset,
                favStore.ftAssetFavorites
              )}
              onClick={() => openSelectAssetsSheet()}
              onFavClick={hanldeUpdateFtAssetFavs}
              onDetailClick={() =>
                handleViewAssetInfo(spotStore.toSellFtAsset!)
              }
              isBorderReactive
            />
          )}

          {spotStore.mode === SpotMode.BatchSell && (
            <BatchSellPanel>
              {spotStore.toBatchSellFtAssets.map((asset) => (
                <BatchSellItem
                  key={asset.chainIndex + asset.address}
                  asset={asset}
                  onEdit={() => openSelectAssetsSheet(asset)}
                  onDelete={() => spotStore.removeBatchSellFtAsset(asset)}
                />
              ))}

              {spotStore.toBatchSellFtAssets.length === 0 && <div />}

              {spotStore.toBatchSellFtAssets.length < MAX_BATCH_SELL && (
                <BatchSellPlaceholder onClick={() => openSelectAssetsSheet()} />
              )}
            </BatchSellPanel>
          )}
        </MainAssetsArea>

        <TradeAiPanel
          style={{ marginTop: "10px" }}
          assetSymbol={
            spotStore.mode === SpotMode.Sell
              ? spotStore.toReceiveFtAsset?.symbol
              : spotStore.toBuyFtAssetForBatchSell?.symbol
          }
          assetImageUrl={
            spotStore.mode === SpotMode.Sell
              ? spotStore.toReceiveFtAsset?.imageUrl
              : spotStore.toBuyFtAssetForBatchSell?.imageUrl
          }
          assetChainIndex={
            spotStore.mode === SpotMode.Sell
              ? spotStore.toReceiveFtAsset?.chainIndex
              : spotStore.toBuyFtAssetForBatchSell?.chainIndex
          }
          assetAddress={
            spotStore.mode === SpotMode.Sell
              ? spotStore.toReceiveFtAsset?.address
              : spotStore.toBuyFtAssetForBatchSell?.address
          }
          showAi={
            (spotStore.mode === SpotMode.Sell ||
              spotStore.mode === SpotMode.BatchSell) &&
            !tradeStore.disableAiScore &&
            showAiScore
          }
          onClose={() => {
            setShowAiScore(false);
          }}
        >
          {/* Amount */}
          <div className={`trade-amount trade-box trade-box-hover`}>
            {/* Line 1 */}
            <div className="trade-amount-top">
              {/* Amount Spots*/}
              <div className="trade-amount-top-text">
                {/* Purchase value Text */}
                <TradeAmountValueText>
                  {spotLabel.inputIntro}
                </TradeAmountValueText>
                {/* Purchase value Input */}
                <TradeInput
                  unit={spotStore.inputSymbol}
                  precision={precision}
                  maxValue={spotStore.inputMaxValue}
                  minValue={spotStore.inputMinValue}
                  amountOnChange={handleInputChange}
                  value={spotStore.inputValue}
                  attachmentProps={inputAttachment}
                />
              </div>

              {/* Price Switch Button */}
              {spotStore.mode === SpotMode.Buy && (
                <div className="trade-amount-top-market">
                  <PriceChangeButton />
                </div>
              )}

              {/* Sell - Receive Select Button */}
              {spotStore.mode === SpotMode.Sell && (
                <div className={SELL_ONBOARDING_TARGET}>
                  <ReceivingAssetButtonV2
                    mainIcon={spotStore.toReceiveFtAsset?.imageUrl}
                    subIcon={spotStore.toReceiveFtAsset?.chainGreyImageUrl}
                    text={spotStore.toReceiveFtAsset?.symbol}
                    onClick={() => setShowSelectReceiveAssets(true)}
                  />
                </div>
              )}

              {/* Batch-sell - Receive Select Button */}
              {spotStore.mode === SpotMode.BatchSell && (
                <div className={SELL_ONBOARDING_TARGET}>
                  <ReceivingAssetButtonV2
                    mainIcon={spotStore.toBuyFtAssetForBatchSell?.imageUrl}
                    subIcon={
                      spotStore.toBuyFtAssetForBatchSell?.chainGreyImageUrl
                    }
                    text={spotStore.toBuyFtAssetForBatchSell?.symbol}
                    onClick={() => setShowSelectReceiveAssets(true)}
                  />
                </div>
              )}
            </div>

            {/* Spot Amount Slider */}
            <div className="trade-amount-bottom">
              <SpotAmountSlider
                value={sliderValue.round(2).toNumber()}
                onChange={handleSliderChange}
                disabled={sliderDisabled}
              />
            </div>

            {/* -Spot-Trading- Balance */}
            <BalanceBox $clickable={spotStore.mode === SpotMode.Buy}>
              {/* Line 1 */}
              <div className="trade-balance">
                <CommonText>{spotLabel.maxAvailableOrMinRequired}</CommonText>
                &nbsp;
                <StrongText>{maxAvailableOrMinRequiredValueView}</StrongText>
                {spotStore.mode === SpotMode.Buy && (
                  <img
                    className="trade-question"
                    src={questionIco}
                    alt="?"
                    onMouseEnter={() => setShowTips(true)}
                    onMouseLeave={() => setShowTips(false)}
                  />
                )}
              </div>
              {/* Line 2 */}
              <div
                className={`trade-balance clickable`}
                onClick={() => {
                  setShowBalanceSheet(true);
                }}
              >
                <CommonText>{t("My balance:")}</CommonText>
                &nbsp;
                <StrongText>{balanceValueView}</StrongText>
                {spotStore.mode === SpotMode.Buy && (
                  <img
                    className="balance-detail-icon"
                    src={tradeBlackUpArrowIcon}
                    alt=""
                  />
                )}
              </div>
            </BalanceBox>
          </div>
        </TradeAiPanel>

        {/* Breakdown */}
        <ModuleWrapper>
          {breakdownList.length > 0 && (
            <TradeBreakdownViewV2 list={breakdownList} />
          )}
        </ModuleWrapper>

        <BottomPlaceholder>
          <div style={{ height: "calc(80px + var(--navi-height))" }}></div>
        </BottomPlaceholder>

        {/* Blank filled area */}
        <BlankFilledArea />

        {/* Spot - Confirm to open contract-interaction modal */}
        <div className="trade-open">
          <FloatingButton
            onClick={mainBtnContext?.callback}
            icon={mainBtnContext.icon}
            loading={mainBtnContext.loading}
            disabled={mainBtnContext.disabled}
          >
            {mainBtnContext.children}
          </FloatingButton>
        </div>

        {/* -Spot-Trading- Select Assets*/}
        <BottomSheet
          isOpen={showSelectAssets}
          onClose={() => closeSelectAssetsSheet()}
          mountPoint={domNode}
        >
          {spotStore.mode === SpotMode.Buy && (
            <ToBuyAssetSelector
              onChoose={handleSelectToBuyFtAsset}
              chains={chainStore.noKontosChains}
              hasRecommend={true}
              hasAll={true}
              hasFavorites={true}
              showAssetType={ShowAssetType.Detail}
              initAssetType={spotStore.defaultFtAssetType}
              onChainChange={spotStore.onChainChange}
            />
          )}
          {spotStore.mode === SpotMode.Sell && (
            <SelectAssetsModal
              allFtAssets={userStore.userHoldings}
              chains={chainStore.userHoldingsNoKontosChains}
              showBalance={true}
              allowSearch={false}
              displayPrecision={displayPrecision}
              onChoose={handleSelectToSellFtAsset}
              enableWhitelist
              initChainIndex={spotStore.defaultFtAssetType}
              onChainChange={spotStore.onChainChange}
            />
          )}
          {spotStore.mode === SpotMode.BatchSell && (
            <SelectAssetsModal
              allFtAssets={spotStore.canBatchSellUserholdings}
              chains={spotStore.canBatchSellChains}
              showBalance={true}
              allowSearch={false}
              displayPrecision={displayPrecision}
              onChoose={handleSelectToBatchSellFtAsset}
              enableWhitelist
              initChainIndex={spotStore.defaultFtAssetType}
              onChainChange={spotStore.onChainChange}
            />
          )}
        </BottomSheet>

        {/* Receive Asset Selector */}
        <BottomSheet
          isOpen={showSelectReceiveAssets}
          onClose={() => setShowSelectReceiveAssets(false)}
          mountPoint={domNode}
        >
          <ToBuyAssetSelector
            onChoose={(item) => {
              handleChooseSubAssets(item);
            }}
            chains={chainStore.noKontosChains}
            hasRecommend={true}
            hasAll={true}
            hasFavorites={true}
            showAssetType={ShowAssetType.Detail}
            initAssetType={spotStore.defaultReceiveFtAssetType}
            onChainChange={spotStore.onReceiveChainChange}
          />
        </BottomSheet>

        {/* Contract Interaction Modal*/}
        <BottomSheet
          isOpen={showContractInteraction}
          onClose={() => {
            setShowContractInteraction(false);
          }}
          mountPoint={domNode}
        >
          {spotStore.taskData && (
            <ContractInteraction
              interactionType={getInteractionType(spotStore.mode)}
              target={getInteractionTarget(spotStore.mode)}
              onInnerModalChange={onInnerModalChange}
              onCancel={handleInteractionCancel}
              onSuccess={handleInteractionSuccess}
              onFail={handleInteractionFail}
              onInnerIndexChange={spotStore.setSelectedTaskDataIndex}
              selectedTaskDataIndex={spotStore.selectedTaskDataIndex}
              wallet={userStore.accountName!}
              receiver={spotStore.receiver}
              buyFtAssetProps={{
                toBuyFtAsset: spotStore.toBuyFtAsset!,
                toBuyFtAssetValue: spotStore.toBuyFtAssetValue!,
                amount: spotStore.toBuyFtAssetQuantity!,
                taskData: spotStore.taskData,
                isInSufficient: spotStore.insufficient,
                slippage: spotStore.slippage,
              }}
              sellFtAssetProps={{
                taskData: spotStore.taskData,
                toSellFtAssetQuantity: spotStore.toSellFtAssetQuantity!,
                toSellFtAsset: spotStore.toSellFtAsset!,
                toSellFtassetValueEstimated:
                  spotStore.toSellFtassetValueEstimated,
                toReceiveFtAssetQuantity: spotStore.toReceiveFtAssetQuantity!,
                toReceiveFtAsset: spotStore.toReceiveFtAsset!,
                toReceiveFtAssetValueEstimated:
                  spotStore.toReceiveFtAssetValueEstimated,
                slippage: spotStore.slippage,
              }}
              batchSellProps={{
                taskData: spotStore.taskData,
                toBatchSellAssets: spotStore.toBatchSellFtAssets,
                toBuyAsset: spotStore.toBuyFtAssetForBatchSell!,
                toBuyAssetAmount: spotStore.toBuyFtAssetQuantity!,
                toBuyFtAssetValueEstimated:
                  spotStore.toBuyFtAssetValueForBatchSellEstimated!,
                slippage: spotStore.slippage,
                isSameChain:
                  spotStore.toBatchSellFtAssets.length === 1 &&
                  spotStore.toBatchSellFtAssets[0].chainIndex ===
                    spotStore.toBuyFtAssetForBatchSell?.chainIndex,
              }}
              displayPrecision={displayPrecision}
            />
          )}
        </BottomSheet>

        {/* Slippage Sheet */}
        <BottomSheet
          isOpen={showSlippage}
          mountPoint={domNode}
          customHeight={330}
          disableScrollLocking
          onClose={() => setShowSlippage(false)}
        >
          <SlippageSetup
            initSlippage={spotStore.slippage}
            symbol={slippageSetupProps?.symbol}
            rawAmount={slippageSetupProps?.rawAmount}
            onSubmit={(slippage) => {
              spotStore.setSlippage(slippage);
              setShowSlippage(false);
            }}
          />
        </BottomSheet>

        {/* Receiving Address Sheet */}
        {spotStore.receiver && (
          <BottomSheet
            isOpen={showReceiver}
            onClose={() => setShowReceiver(false)}
            mountPoint={domNode}
            disableScrollLocking
          >
            <ReceiverSelectModal
              rawAddress={spotStore.receiver}
              onConfirm={(address) => {
                spotStore.setReceiver(address);
                setShowReceiver(false);
              }}
              onCancel={() => setShowReceiver(false)}
            />
          </BottomSheet>
        )}

        {/* User Balance Sheet */}
        <BottomSheet
          isOpen={showBalanceSheet}
          onClose={() => setShowBalanceSheet(false)}
          mountPoint={domNode}
          disableScrollLocking
        >
          <UserBalance onDone={() => setShowBalanceSheet(false)} />
        </BottomSheet>

        {showTips && (
          <Floater
            eventDelay={0}
            open={showTips}
            component={TipsBuyMaxAvailableV2}
            target={".trade-question"}
            placement="top"
          />
        )}

        {(spotStore.mode === SpotMode.Sell ||
          spotStore.mode === SpotMode.BatchSell) &&
          userStore.needOnboardingSell &&
          !userStore.needOnboardingPayment &&
          !userStore.needOnboardingAiScore &&
          showSellOnboarding && (
            <Joyride
              callback={handleSellJoyrideCallback}
              disableCloseOnEsc
              disableOverlayClose
              disableScrolling
              continuous
              run={run}
              showProgress
              showSkipButton
              steps={steps}
              floaterProps={{
                styles: {
                  arrow: {
                    color: "#fff",
                    spread: 20,
                    length: 14,
                  },
                },
              }}
              styles={{
                options: {
                  zIndex: 2000000,
                },
                spotlight: {
                  borderRadius: spotLightRadius,
                },
                overlay: {
                  backgroundColor: "rgba(0, 13, 31, 0.30)",
                },
              }}
              tooltipComponent={JoyRideToolTip}
            />
          )}
      </Wrapper>
    </AnimatedLayout>
  );
});
