import { DEFAULT_DECIMAL } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import { callTaskDataV3WithAbortAndMemory } from "./trade-service";

export const fetchMaxFtAssetToSend = async (
  account: string,
  payer: string,
  chainIndex: string,
  assetAddress: string
): Promise<KontosNumber> => {
  const transferData = {
    chainIndex,
    receiver: "",
    assetAddress,
    assetAmount: new KontosNumber(0).toStringWithDecimal(DEFAULT_DECIMAL),
  };
  const resp = await callTaskDataV3WithAbortAndMemory(
    {
      account,
      payer,
      reqTaskTransferData: transferData,
    },
    false
  );
  return new KontosNumber(resp.maxAvailableBalanceAmount, DEFAULT_DECIMAL);
};
