import i18n, { LanguageDetectorAsyncModule } from "i18next";
import { initReactI18next } from "react-i18next";
import { STORAGE_KEYS } from "src/config";
import { get } from "src/store/local";
import { i18nextPlugin } from "translation-check";

import enResource from "./locales/en/translation.json";
import zhResource from "./locales/zh/translation.json";
import zhtwResource from "./locales/zh_tw/translation.json";
import jaResource from "./locales/ja/translation.json";
import krResource from "./locales/kr/translation.json";
import ruResource from "./locales/ru/translation.json";

import { LANG, getLangDisplayName } from "./lang";

export { LANG, getLangDisplayName };

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  [LANG.en.name]: { translation: enResource },
  [LANG.zh_cn.name]: { translation: zhResource },
  [LANG.zh_tw.name]: { translation: zhtwResource },
  [LANG.ja.name]: { translation: jaResource },
  [LANG.kr.name]: { translation: krResource },
  [LANG.ru.name]: { translation: ruResource },
};

const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: async () => {
    const language = await get(STORAGE_KEYS.store_lang);
    return language || LANG.en.name;
  },
  // init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(i18nextPlugin)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init({
    resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: false, // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnEmptyString: false, // allow an empty value to count as invalid (by default is true)
    react: {
      bindI18n: "loaded languageChanged",
      bindI18nStore: "added",
      useSuspense: true,
    },
  });

export default i18n;
