import styled from 'styled-components';

const MarketsRankingsItem = styled.div`
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #EDEEF1);
  background: var(--White, #FFF);
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  color: #1C1B1F;
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 8px;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-basis: auto;
  .img{
    width: 22px;
    height: 22px;
  }
  span{
    padding: 3px 0;
  }
  @media (hover: hover) and (pointer: fine) {
    &.item:hover{
      border: 1px solid var(--Kontos-Blue, #413DEC);
    }
  }
  &.item-active{
    border: 1px solid var(--Kontos-Blue, #413DEC);
  }
`

export default MarketsRankingsItem;
