import { useEffect } from "react";
import { loadingStore } from "src/store/loadingStore";
import { useStores } from "./useStore";

type UseMouseDownOutsideParams = {
  ref: React.RefObject<HTMLElement | null>;
  callback?: (event?: MouseEvent) => void;
  globalCallback?: (event?: MouseEvent) => void;
  shouldClose: boolean;
};

const useMouseDownOutside = ({
  ref,
  callback,
  globalCallback,
  shouldClose,
}: UseMouseDownOutsideParams) => {
  const { sheetStore } = useStores();

  useEffect(() => {
    const handleMouseDownOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        shouldClose &&
        !loadingStore.isLoading
      ) {
        if (sheetStore.latestSheet) {
          globalCallback?.(event);
        } else {
          callback?.(event);
        }
      }
    };

    document.addEventListener("mousedown", handleMouseDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleMouseDownOutside);
    };
  }, [ref, callback, shouldClose, sheetStore.latestSheet, globalCallback]);
};

export default useMouseDownOutside;
