import styled from "styled-components";
import TipsSvg from "src/assets/icons/trade/tip.svg";
import { t } from "i18next";
import { KontosButton } from "src/components/button/KontosButton";
import { useState } from "react";
import { Trans } from "react-i18next";
import AddressInputSearch from "src/components/input-search/AddressInputSearch";

const Container = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .top-12 {
    margin-top: 12px;
  }
`;

const Title = styled.div`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  font-family: HarmonyOS Sans SC;
  font-size: 24px;
  font-weight: 700;
`;

const Desc = styled.div`
  margin-top: 20px;
  text-align: center;

  .icon-1 {
    width: 29px;
    height: 17px;
  }

  .text-1 {
    padding: 7px 19px 20px 19px;
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }

  .text-2 {
    color: var(--Kontos-Blue, #413dec);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 700;
  }
`;

const AddressInputContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex; // 新增
  flex-direction: column; // 新增
`;

interface ReceiveAddressModalProps {
  rawAddress: string;
  onConfirm: (address: string) => void;
  onCancel: () => void;
}

export const ReceiverSelectModal: React.FC<ReceiveAddressModalProps> = ({
  rawAddress,
  onConfirm,
  onCancel,
}) => {
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [address, setAddress] = useState(rawAddress);

  return (
    <Container>
      <Title>{t("Set Receiving Address")}</Title>
      <Desc>
        <img className="icon-1" src={TipsSvg} alt="" />
        <Trans i18nKey="trans-setReceivingAddress">
          <div className="text-1">
            You can change the receiving address for your assets here, please
            <span className="text-2"> ensure</span> that the address supports
            receiving this asset.
          </div>
        </Trans>
      </Desc>
      <AddressInputContainer>
        <AddressInputSearch
          onFinish={(success, address) => {
            setIsAddressValid(success);
            setAddress(address);
          }}
          rawAddress={rawAddress}
          allowEOA
          rawAddressValid
        />
      </AddressInputContainer>
      <KontosButton
        disabled={!isAddressValid}
        onClick={() => {
          if (!isAddressValid) {
            return;
          }
          onConfirm(address);
        }}
      >
        {t("Confirm")}
      </KontosButton>
      <KontosButton className="top-12" $isOutlined={true} onClick={onCancel}>
        {t("Cancel")}
      </KontosButton>
    </Container>
  );
};
