import styled from "styled-components";
import blueTriIco from "src/assets/icons/events/eb/blue-top-triangle.svg";
import greyTriIco from "src/assets/icons/events/eb/grey-top-triangle.svg";
import { useTranslation } from "react-i18next";
import reachedIco from "src/assets/icons/events/eb/checkpoint-reached.svg";
import notReachedIco from "src/assets/icons/events/eb/checkpoint-not-reached.svg";
import { motion } from "framer-motion";

const Container = styled(motion.div)<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;

  > .top {
    height: 12.8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .middle {
    position: relative;
    margin-top: 2.6px;
    margin-right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    .orb-wrapper {
      height: 24px;
      flex: none;
      position: relative;
    }

    .orb-img {
      width: 24px;
      height: 24px;
    }

    .orb-count {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 4px;

      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans SC";
      font-size: 12px;
      font-weight: 400;
    }
  }

  > .bottom {
    margin-top: 5px;
    position: relative;
    padding: 4px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => (props.$isActive ? "pointer" : "default")};
    border-radius: 99px;
    background: ${(props) =>
      props.$isActive
        ? "var(--Kontos-Blue, #413dec)"
        : "var(--Deep-100, #CCCFD2);"};

    &:hover {
      opacity: ${(props) => props.$isActive && "0.9"};
    }

    &:active {
      opacity: ${(props) => props.$isActive && "0.9"};
    }

    .claim-text {
      height: 14px;
      line-height: 14px;
      color: var(--White, #fff);
      font-family: "HarmonyOS Sans Bold";
      font-size: 12px;
    }

    .triangle {
      position: absolute;
      top: -3px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

interface Props {
  orbImg: any;
  reached: boolean;
  claimed: boolean;
  canClaim?: boolean;
  ballNum: number;
  onClaim?: () => void;
  simplified?: boolean;
}

export const EbBarStage: React.FC<Props> = ({
  orbImg,
  reached,
  claimed,
  canClaim = true,
  ballNum,
  onClaim,
  simplified = false,
}) => {
  const { t } = useTranslation();
  const claimText = claimed ? t("Claimed") : t("Claim");
  const triangleIco = claimed ? greyTriIco : blueTriIco;
  const checkpointIco = reached ? reachedIco : notReachedIco;

  if (simplified)
    return (
      <Container
        $isActive={!claimed || !canClaim}
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: "-50%", opacity: 1 }}
        transition={{ type: "spring", duration: 2, bounce: 0 }}
      >
        {/* Checkpoint */}
        <div className="top">
          <img className="checkpoint-ico" src={notReachedIco} alt="" />
        </div>
      </Container>
    );
  else
    return (
      // TODO: eb anime
      <Container
        $isActive={!claimed}
        // initial={{ x: -100, opacity: 0 }}
        // animate={{ x: "-50%", opacity: 1 }}
        // transition={{ type: "spring", duration: 2, bounce: 0 }}
        initial={{ x: "-50%", opacity: 1 }}
      >
        {/* Checkpoint */}
        <div className="top">
          <img className="checkpoint-ico" src={checkpointIco} alt="" />
        </div>

        {/* Orb Icon & Num */}
        <div className="middle">
          <div className="orb-wrapper">
            <img className="orb-img" src={orbImg} alt="orb" />
            {/* TODO: Eb */}
            {ballNum === 0 ? (
              <span className="orb-count">x&nbsp;?</span>
            ) : (
              <span className="orb-count">x{ballNum.toString()}</span>
            )}
          </div>
        </div>

        {/* Claim Btn */}
        {/* TODO: Eb */}
        {/* {reached && (
          <div
            className="bottom"
            onClick={() => {
              !claimed && onClaim?.();
            }}
          >
            <span className="claim-text">{claimText}</span>
            <img className="triangle" src={triangleIco} alt="" />
          </div>
        )} */}
      </Container>
    );
};
