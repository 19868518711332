import styled from "styled-components";
import PoweredBy from "src/assets/icons/powered-by.svg";
import WarnTriSvg from "./warnTri.svg";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";

const Container = styled.div<{ $isOverWidth?: boolean }>`
  height: 100vh;
  padding: 0 36px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--Deep-800, #1a2535);
`;

const Main = styled.div`
  flex: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    width: 45px;
    height: 45px;
  }

  .title {
    margin-top: 8px;

    text-align: center;

    color: var(--Warning, #faad14);
    font-family: HarmonyOS Sans SC;
    font-size: 20px;
    font-weight: 700;
  }

  .content {
    margin-top: 8px;

    text-align: center;

    color: var(--Deep-400, #80868f);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }

  .order {
    margin-top: 28px;

    text-align: center;

    border-radius: 8px;
    background: var(--Deep-700, #333d4c);

    padding: 9px 11px;

    color: var(--White, #fff);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
`;

const Footer = styled.div`
  display: flex;

  margin-bottom: 38px;

  .text {
    color: var(--Deep-400, #80868f);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }

  .icon {
    width: 58.717px;
    height: 25.165px;
    margin-left: 7.32px;
  }
`;

const BindWarning: React.FC = () => {
  const { userStore, uiStore } = useStores();

  const getPlatName = (name: string) => {
    switch (name) {
      case "discord":
        return "Discord";
      case "telegram":
        return "Telegram";
      default:
        return "Discord/Telegram";
    }
  };

  return (
    <Container $isOverWidth={uiStore.isOverWidth}>
      {/* Main */}
      <Main>
        <img className="icon" src={WarnTriSvg} alt="warn" />
        <div className="title">Your account status is abnormal!</div>
        <div className="content">
          {`Please enter the following command in the ${getPlatName(
            userStore.kontosCtx?.platform || ""
          )} chat box to bind
          your ${
            userStore.toBindAccount
              ? userStore.toBindAccount + ".os"
              : "new created/revoverd"
          } account.`}
        </div>
        <div
          className="order"
          onClick={async () => {
            await navigator.clipboard.writeText("/bind ");
            toast({
              text: "Copied to clipboard!",
              type: "success",
            });
          }}
        >
          {"/bind <kontos-account-name>"}
        </div>
      </Main>
      {/* Footer */}
      <Footer>
        <div className="text">Powered by</div>
        <img className="icon" src={PoweredBy} alt="Kontos" />
      </Footer>
    </Container>
  );
};

export default BindWarning;
