import { SingleIconButton } from "src/components/button/SingleIconButton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PrefixIcon = styled.img`
  margin-right: 6px;
  width: 16px;
  height: 16px;
`;

const Text = styled.span`
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
`;

const EditIcon = styled(SingleIconButton)`
  margin-left: 6px;
`;

interface IProps {
  prefixIcon?: any;
  text: string;
  onClick: () => void;
}

export const TradeBreakdownEditableItem: React.FC<IProps> = ({
  prefixIcon,
  text,
  onClick,
}) => {
  return (
    <Container onClick={onClick}>
      {prefixIcon && <PrefixIcon src={prefixIcon} />}
      <Text>{text}</Text>
      <EditIcon type="edit" />
    </Container>
  );
};

export const BreakDownLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DarkBreakDownText = styled.span`
  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

export const LightBreakDownText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

export const BreakDownAssetIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
