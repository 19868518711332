import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { throttle } from "src/utils/helper";
import styled from "styled-components";

const SliderContainer = styled.div<{ $disable: boolean }>`
  /* margin-left: 13px; */
  position: relative;
  width: 100%;
  height: 20px;
  padding: 10px 0;
  z-index: 1;
  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
  -webkit-tap-highlight-color: transparent;
`;

const SliderLine = styled.div<{ percentage: number }>`
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  height: 1px;
  background-color: var(--Deep-100, #cccfd2);
  z-index: -2;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${(props) => props.percentage * 100}%;
    height: 1px;
    background-color: var(--Deep-800, #1a2535);
  }
`;

const TextTooltip = styled.div<{ $active: boolean; $forcedDisplay?: boolean }>`
  visibility: ${(props) => (props.$forcedDisplay ? "visible" : "hidden")};
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  width: 32px;
  height: 14px;
  border-radius: 99px;
  background: ${(props) =>
    props.$active ? "var(--Deep-800, #1A2535)" : "#CCCFD2"};
  color: var(--White, #fff);

  overflow: hidden;
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC;
  font-size: 10px;
  font-weight: 400;
`;

const Circle = styled.div<{ filled?: boolean; active?: boolean; pos: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) =>
    `calc(${props.pos * 100}% - ${props.filled ? "7.5px" : "3.5px"})`};
  margin: auto 0;
  width: ${(props) => (props.filled ? "15px" : "7px")};
  height: ${(props) => (props.filled ? "15px" : "7px")};
  border: ${(props) => (props.filled ? "2px solid" : "1px solid")};
  border-color: ${(props) =>
    props.active ? "var(--Deep-25, #F5F5F6)" : "var(--Deep-100, #CCCFD2)"};
  background-color: ${(props) =>
    props.filled || props.active ? "var(--Deep-800, #1A2535)" : "transparent"};
  border-radius: 50%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 100%;
    height: 100%;
    background-color: var(--Deep-25, #f5f5f6);
    border-radius: 50%;
    z-index: -1;
  }

  @media (hover: hover) {
    &:hover {
      border: ${(props) => (props.filled ? "6px solid" : "2px solid")};
      left: ${(props) =>
        `calc(${props.pos * 100}% - ${props.filled ? "10.5px" : "7.5px"})`};
      border-color: ${(props) =>
        props.active ? "var(--Deep-25, #F5F5F6)" : "var(--Deep-100, #CCCFD2)"};

      width: ${(props) => (props.filled ? "23px" : "15px")};
      height: ${(props) => (props.filled ? "23px" : "15px")};

      ${TextTooltip} {
        visibility: visible;
      }
    }
  }
`;

interface SliderProps {
  onChange: (value: number) => void;
  granularity?: number;
  delay?: number;
  controlledPercentage?: number;
  disabled?: boolean;
}

const CustomSlider: React.FC<SliderProps> = ({
  onChange,
  granularity = 0.01,
  delay = 0,
  controlledPercentage = 0.25,
  disabled = false,
}) => {
  const [percentage, setPercentage] = useState(controlledPercentage);
  const [dragging, setDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const setPercentageWithCheck = useCallback(
    (_percentage: number) => {
      if (!disabled) {
        setPercentage(_percentage);
      }
    },
    [disabled]
  );

  useEffect(() => {
    setPercentage(controlledPercentage);
  }, [controlledPercentage]);

  // Calculate the percentage, only called when dragging and touching
  const calculatePercentage = useCallback(
    (clientX: number) => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const x = clientX - rect.left;
        let rawPercentage = x / rect.width;

        // Adjust percentage with granularity
        const adjustedPercentage =
          Math.round(rawPercentage / granularity) * granularity;

        return Math.min(Math.max(adjustedPercentage, 0), 1);
      }
      return 0;
    },
    [granularity]
  );

  // Update percentage and call onChange
  const updateValue = useCallback(
    (newPercentage: number) => {
      setPercentageWithCheck(newPercentage);
      !dragging && onChange(newPercentage);
    },
    [dragging, onChange, setPercentageWithCheck]
  );

  // Click
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    const newPercentage = calculatePercentage(e.clientX);
    setPercentageWithCheck(newPercentage);
    updateValue(newPercentage);
  };

  // Drag
  const handleDragStart = () => {
    setDragging(true);
  };

  const handleDragMove = useCallback(
    (e: globalThis.MouseEvent) => {
      e.preventDefault();
      if (dragging) {
        const newPercentage = calculatePercentage(e.clientX);
        setPercentageWithCheck(newPercentage);
        updateValue(newPercentage);
      }
    },
    [dragging, calculatePercentage, setPercentageWithCheck, updateValue]
  );

  const throttledHandleDragMove = useMemo(() => {
    return throttle((e: globalThis.MouseEvent) => {
      e.preventDefault();
      handleDragMove(e);
    }, delay);
  }, [handleDragMove, delay]);

  const handleDragEnd = useCallback(() => {
    setDragging(false);
    onChange(percentage);
  }, [onChange, percentage]);

  React.useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", throttledHandleDragMove);
      window.addEventListener("mouseup", handleDragEnd);
    } else {
      document.removeEventListener("mousemove", throttledHandleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
    }
    return () => {
      document.removeEventListener("mousemove", throttledHandleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragging, handleDragEnd, throttledHandleDragMove]);

  // Touch
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (dragging) {
        const newPercentage = calculatePercentage(e.touches[0].clientX);
        setPercentageWithCheck(newPercentage);
        updateValue(newPercentage);
      }
    },
    [dragging, calculatePercentage, setPercentageWithCheck, updateValue]
  );

  const throttledHandleTouchMove = useMemo(() => {
    return throttle((e: React.TouchEvent<HTMLDivElement>) => {
      handleTouchMove(e);
    }, delay);
  }, [handleTouchMove, delay]);

  const handleTouchEnd = useCallback(() => {
    setDragging(false);
    onChange(percentage);
  }, [onChange, percentage]);

  React.useEffect(() => {
    if (dragging) {
      document.addEventListener("touchmove", throttledHandleTouchMove as any);
      window.addEventListener("touchend", handleTouchEnd as any);
    } else {
      document.removeEventListener(
        "touchmove",
        throttledHandleTouchMove as any
      );
      window.removeEventListener("touchend", handleTouchEnd as any);
    }
    return () => {
      document.removeEventListener(
        "touchmove",
        throttledHandleTouchMove as any
      );
      window.removeEventListener("touchend", handleTouchEnd as any);
    };
  }, [dragging, handleTouchEnd, throttledHandleTouchMove]);

  return (
    <SliderContainer
      $disable={disabled}
      ref={containerRef}
      onClick={(e) => {
        !disabled && handleClick(e);
      }}
      draggable={false}
    >
      <SliderLine percentage={percentage} draggable={false} />
      {[0, 0.25, 0.5, 0.75, 1].map((pos) => (
        <Circle
          key={pos}
          pos={pos}
          active={pos <= percentage}
          draggable={false}
        >
          <TextTooltip $active={pos <= percentage}>
            {pos * 100 + "%"}
          </TextTooltip>
        </Circle>
      ))}
      <Circle
        filled
        pos={percentage}
        onMouseDown={() => {
          !disabled && handleDragStart();
        }}
        onTouchStart={(e) => {
          !disabled && handleTouchStart(e);
        }}
        draggable={false}
      >
        <TextTooltip $active={true} $forcedDisplay={dragging}>
          {Math.round(Math.round(percentage / 0.01) * 0.01 * 100) + "%"}
        </TextTooltip>
      </Circle>
    </SliderContainer>
  );
};

export default CustomSlider;
