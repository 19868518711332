import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchSvg from "src/assets/icons/trade/trade-search.svg";
import { ChainConfig } from "@zkkontos/kontos-sdk/src/api/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { TokenOrChainIcon } from "../icons/TokenOrChainIcon";

const SearchContainer = styled.div`
  margin: 0 16px 16px 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 11px 10px 10px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  margin-bottom: 14px;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
`;

const ItemContainer = styled.div`
  margin-right: 5px;
  padding: 8px 25px 8px 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.__white};

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:active {
    background: var(--Deep-25, #f5f5f6);
  }

  @media (hover: hover) {
    &:hover {
      background: var(--Deep-25, #f5f5f6);
    }
  }

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const LeftItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .chain-symbol {
    margin-left: 10px;

    color: ${(props) => props.theme.colors.__deep_800};
    font-size: 16px;
    font-family: "HarmonyOS Sans Bold";
  }
`;

const RightItem = styled.div`
  .arrow-icon {
  }
`;

interface Props {
  onChoose?: (chain: ChainConfig) => void;
  chains: ChainConfig[];
}

const ITEMS_TO_INIT = 15;
const ITEMS_TO_FETCH = 10;

const ChainSelect: React.FC<Props> = ({ onChoose, chains }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [filteredChains, setFilteredChains] = useState<ChainConfig[]>(chains);
  const [visibleChains, setVisibleChains] = useState<number>(ITEMS_TO_INIT);
  const [needEndText, setNeedEndText] = useState<boolean>(false);
  const { t } = useTranslation();

  const fetchMoreData = () => {
    !needEndText && setNeedEndText(true);
    setVisibleChains((prev) => prev + ITEMS_TO_FETCH);
  };

  const handleChooseChain = (chain: ChainConfig) => {
    onChoose?.(chain);
  };

  // This focuses on filter input
  useEffect(() => {
    setVisibleChains(ITEMS_TO_INIT);
    setNeedEndText(false);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    let filtered = chains;

    filtered = filtered
      .filter(
        (chain) =>
          chain.chainSymbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
          chain.chainIndex.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const aMatchExact =
          a.chainSymbol.toLowerCase() === searchTerm.toLowerCase();
        const bMatchExact =
          b.chainSymbol.toLowerCase() === searchTerm.toLowerCase();

        if (aMatchExact && !bMatchExact) {
          return -1;
        } else if (!aMatchExact && bMatchExact) {
          return 1;
        }

        const aIndex = Math.min(
          a.chainSymbol.toLowerCase().indexOf(searchTerm.toLowerCase())
        );
        const bIndex = Math.min(
          b.chainSymbol.toLowerCase().indexOf(searchTerm.toLowerCase())
        );

        if (aIndex !== bIndex) {
          return aIndex - bIndex;
        }

        const aLength = a.chainSymbol.length;
        const bLength = b.chainSymbol.length;
        return aLength - bLength;
      });
    setFilteredChains(filtered);
  }, [chains, searchTerm]);

  return (
    <SearchContainer>
      <SearchInput
        ref={inputRef}
        placeholder={t("Search network")}
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(e.target.value)
        }
      />
      <Scrollable id="scrollableDiv" ref={scrollRef}>
        <InfiniteScroll
          dataLength={visibleChains} // The current length of displayed data
          next={fetchMoreData} // The function to trigger to load more
          hasMore={visibleChains < filteredChains.length} // Whether there are more items to load
          loader={<h4>Loading...</h4>}
          endMessage={
            needEndText && (
              <p style={{ textAlign: "center" }}>
                <b>{t("You have seen it all")}</b>
              </p>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {filteredChains.slice(0, visibleChains).map((item, index) => (
            <ItemContainer key={index} onClick={() => handleChooseChain(item)}>
              <LeftItem>
                <TokenOrChainIcon type="chain" size={45} src={item.imageURL} />
                <span className="chain-symbol">{item.chainSymbol}</span>
              </LeftItem>
              <RightItem>
                {/* <img className="arrow-icon" src={arrowIcon} alt="" /> */}
              </RightItem>
            </ItemContainer>
          ))}
        </InfiniteScroll>
      </Scrollable>
    </SearchContainer>
  );
};

export default ChainSelect;
