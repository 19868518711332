import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SelectArrowSvg from "src/assets/icons/arrow14.svg";
import { isEmoji } from "src/utils/helper";
import { t } from "i18next";

const DropdownWrapper = styled.div`
  position: relative;
`;

const ArrowIcon = styled.img<{ isOpen: boolean }>`
  margin-left: 4px;
  transition: transform 0s ease-in-out;
  transform: rotate(${(props) => (props.isOpen ? "0" : "180deg")});
`;

const SelectedItem = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  width: ${(props) => (props.isOpen ? "100%" : "auto")};
  padding: 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: ${(props) => (props.isOpen ? "flex" : "inline-flex")};
  align-items: center;
  transition: border-radius 0s ease-in-out;
  border-radius: 8px;
  border: 1px solid var(--Deep-50, #EDEEF1);
  background: var(--White, #FFF);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
  color: var(--Deep-800, #1A2535);

  /* Smaller */
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  span{
    color: var(--Deep-400, #80868F);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  transition: max-height 0s ease-in-out;

  border: 1px solid var(--Deep-50, #EDEEF1);
  background: var(--White, #FFF);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
  color: var(--Deep-400, #80868F);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px;
`;

const DropdownItem = styled.div`
  cursor: pointer;
  border-radius: 4px;
  line-height: 30px;

  &:hover {
    color: var(--Kontos-Blue, #413DEC);
    background: var(--Deep-25, #F5F5F6);
  }
`;

export interface OptionType {
  label: string;
  value: number;
  toast?: () => void; // To prevent user from selecting this option, and show a toast instead.
}

interface SelectProps {
  options: OptionType[];
  onChange?: (selected: OptionType) => void;
  className?: string;
  initSelected?: OptionType;
}

const SelectLight: React.FC<SelectProps> = ({ options, onChange, className, initSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(initSelected || options[0]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: OptionType) => {
    if (option.toast) {
      option.toast();
      return;
    }

    setSelected(option);

    if (onChange) {
      onChange(option);
    }

    setIsOpen(false);
  };

  return (
    <DropdownWrapper ref={wrapperRef} className={className}>
      {/* Selected One */}
      <SelectedItem onClick={toggleDropdown} isOpen={isOpen}>
        <span>{t("Interval: ")}</span>&nbsp;{t(selected.label)}
        <ArrowIcon src={SelectArrowSvg} isOpen={isOpen} />
      </SelectedItem>
      {/* Options */}
      {
        isOpen &&
        <DropdownMenu>
          {options
            .filter((option) => option.value !== selected.value)
            .map((option) => (
              <DropdownItem
                key={option.value}
                onClick={() => handleSelect(option)}
              >
                {isEmoji(option.label) ? t(option.label) : `${t(option.label)}`}
              </DropdownItem>
            ))}
        </DropdownMenu>
      }
    </DropdownWrapper>
  );
};

export default SelectLight;
