import { theme } from "src/App";
import styled from "styled-components";

const StyledSvg = styled.svg<{ $isAvtive: boolean; $disable: boolean }>`
  .path-1 {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_100};
  }

  .path-2 {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_200};
  }

  cursor: ${(props) => (!props.$disable ? "pointer" : "not-allowed")};

  @media (hover: hover) {
    &:hover {
      .path-1 {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }

      .path-2 {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }
    }
  }
`;

interface IProps {
  className?: string;
  isActive?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

const DiscoverIcon: React.FC<IProps> = ({
  className,
  isActive = false,
  disable = false,
  onClick,
}) => {
  return (
    <StyledSvg
      $isAvtive={isActive}
      $disable={disable}
      className={className}
      onClick={onClick}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-1"
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 14C26.5 20.7655 21.0155 26.25 14.25 26.25C11.1582 26.25 8.33384 25.1046 6.17827 23.2149L10.1723 21.6031C11.3865 22.2556 12.775 22.6258 14.25 22.6258C19.014 22.6258 22.876 18.7638 22.876 13.9999C22.876 12.525 22.5058 11.1366 21.8533 9.92243L23.4651 5.92851C25.3546 8.08405 26.5 10.9083 26.5 14ZM18.3302 6.39802L22.3234 4.78658C20.1676 2.89603 17.3426 1.75 14.25 1.75C7.48451 1.75 2 7.23451 2 14C2 17.0928 3.14612 19.9178 5.03679 22.0736L6.64829 18.0803C5.99481 16.8655 5.62404 15.476 5.62404 13.9999C5.62404 9.23587 9.48602 5.3739 14.25 5.3739C15.726 5.3739 17.1154 5.74462 18.3302 6.39802Z"
        fill={theme.colors.__deep_100}
      />
      <path
        className="path-2"
        id="Subtract_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9981 6.25346L11.7786 11.5281L6.50391 21.7477L16.7235 16.473L21.9981 6.25346ZM14.2512 15.8183C15.2551 15.8183 16.0689 15.0045 16.0689 14.0006C16.0689 12.9967 15.2551 12.1829 14.2512 12.1829C13.2473 12.1829 12.4335 12.9967 12.4335 14.0006C12.4335 15.0045 13.2473 15.8183 14.2512 15.8183Z"
        fill={theme.colors.__deep_200}
      />
    </StyledSvg>
  );
};

export default DiscoverIcon;
