import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import SearchSvg from "src/assets/icons/trade/trade-search.svg";
import HorizontalScrollableElements, {
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import { ChainConfig, FtAsset } from "src/type/zkkontos";
import { t } from "i18next";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { isFavoriteFtAsset } from "src/utils/helper";
import Header from "src/components/common/header";
import { ChainAssetList } from "src/components/asset-list/ChainAssetList";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { GeneralFtAssetItem } from "src/components/asset-item/GeneralFtAssetItem";
import { useSearchParams } from "react-router-dom";

const Container = styled.div<{ paddingBottom?: number }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  padding-bottom: ${(props) => `${props.paddingBottom}px`};
`;

const MainContainer = styled.div`
  padding: 0 16px 16px 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 11px 10px 10px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  margin-bottom: 8px;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const AssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

interface IProps {
  onDone: () => void;
}

export const UserBalance: React.FC<IProps> = observer(({ onDone }) => {
  const { userStore, chainStore, favStore, marketStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const bottomRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [chainIndex, setChainIndex] = useState<string>(NonChainIndex.All);

  const assets = useMemo((): FtAsset[] => {
    let filtered = userStore.userHoldings;

    switch (chainIndex) {
      case NonChainIndex.Favorites:
        filtered = filtered.filter((item) =>
          isFavoriteFtAsset(item, favStore.ftAssetFavorites)
        );
        break;
      case NonChainIndex.All:
        break;
      default:
        filtered = filtered.filter((item) => item.chainIndex === chainIndex);
    }

    if (searchTerm !== "") {
      filtered = filtered
        .filter(
          (item) =>
            item.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.address.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
          const aMatchExact =
            a.symbol.toLowerCase() === searchTerm.toLowerCase();
          const bMatchExact =
            b.symbol.toLowerCase() === searchTerm.toLowerCase();

          if (aMatchExact && !bMatchExact) {
            return -1;
          } else if (!aMatchExact && bMatchExact) {
            return 1;
          }

          const aIndex = Math.min(
            a.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
          );
          const bIndex = Math.min(
            b.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
          );

          if (aIndex !== bIndex) {
            return aIndex - bIndex;
          }

          const aLength = a.symbol.length;
          const bLength = b.symbol.length;
          return aLength - bLength;
        });
    }

    return filtered;
  }, [
    userStore.userHoldings,
    chainIndex,
    searchTerm,
    favStore.ftAssetFavorites,
  ]);

  const chains = useMemo((): ChainConfig[] => {
    let filtered = chainStore.userHoldingsChains;

    switch (chainIndex) {
      case NonChainIndex.All:
      case NonChainIndex.Favorites:
        filtered = filtered.filter((item) =>
          assets.some((asset) => asset.chainIndex === item.chainIndex)
        );
        break;
    }

    return filtered;
  }, [assets, chainIndex, chainStore.userHoldingsChains]);

  const viewAssetInfo = useCallback(
    (asset: FtAsset) => {
      marketStore.setCurrentAssetQuote(asset);
      setSearchParams({
        ...searchParams,
        ftAssetId: asset.ftAssetId.toString(),
        chainIndex: asset.chainIndex,
        ftAssetAddress: asset.address,
        showQuote: "1",
        hideNavigationBar: "1",
      });
    },
    [marketStore, searchParams, setSearchParams]
  );

  return (
    <Container paddingBottom={bottomRef.current?.offsetHeight}>
      <Header
        callBack={onDone}
        enableBack={true}
        title={t("Balance")}
        padding={"8px 24px"}
        rightBtnText={t("Done")}
        rightBtnCallBack={onDone}
      />

      <MainContainer>
        <SearchInput
          style={{ marginTop: "10px" }}
          ref={inputRef}
          placeholder={t("Search assets")}
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
        />
        <HorizontalScrollableElements
          chains={chainStore.userHoldingsChains}
          chainIndex={chainIndex}
          onSelect={(index) => {
            setChainIndex(index);
          }}
          hasFavorites
          hasAll
        />

        <Scrollable id="scrollableDiv" ref={scrollRef}>
          {chainIndex === NonChainIndex.All ||
          chainIndex === NonChainIndex.Favorites ? (
            chains.length > 0 ? (
              <ChainAssetList assets={assets} chains={chains} expandAll />
            ) : (
              <NoDataV2
                text={t("No Data")}
                style={{
                  marginTop: "-50px",
                }}
              />
            )
          ) : (
            <AssetContainer>
              {assets.map((asset) => (
                <GeneralFtAssetItem
                  key={chainIndex + asset.address}
                  asset={asset}
                  showAssetAddress
                  canCopyAddress
                  showAvailableLabel
                  showSecurityIcon
                  showBalance
                  showUsd
                  onClick={viewAssetInfo}
                />
              ))}
            </AssetContainer>
          )}
        </Scrollable>
      </MainContainer>
    </Container>
  );
});
