import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import React, { ReactNode, useState } from "react";
import LeftBlueArrowSvg from "src/assets/icons/contract/left-blue-arrow.svg";
import Tips from "src/components/tips/Tips";

export const HeaderBackBtnWrapper = styled.div`
  position: absolute;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 8px;

  &:active {
    background-color: ${(props) => props.theme.colors.__deep_50};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.__deep_50};
  }

  .icon {
    transform: translate(4px);
    width: 24px;
    height: 28px;
  }
`;

const Wrapper = styled.div<{ $transparent: boolean; $isTieleRed: boolean }>`
  width: 100%;
  padding: 20px;
  background: transparent;

  ${(props) =>
    props.$transparent &&
    `
    background: transparent;
  `}
  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    width: 100%;
    color: ${(props) =>
      props.$isTieleRed
        ? props.theme.colors.__error
        : props.theme.colors.__deep_800};
    font-size: 20px;
    font-family: HarmonyOS Sans Bold;

    .right-btn {
      position: absolute;
      right: 0;

      color: ${(props) => props.theme.colors.__kontos_blue};
      font-size: 16px;
      font-family: HarmonyOS Sans Bold;

      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
type IHeaderProps = {
  title: string;
  isTitleRed?: boolean;
  transparent?: boolean;
  enableBack?: boolean;
  callBack?(): void;
  padding?: string;
  ignored?: boolean;
  costDetailShow?: boolean;
  headStyle?: any;
  rightBtnText?: string;
  rightTipText?: string;
  rightTipContent?: ReactNode;
  rightBtnCallBack?: () => void;
  rightBtnOnMouseEnter?: () => void;
  rightBtnOnMouseLeave?: () => void;
  rightBtnOnTouchStart?: () => void;
};
const Header = React.forwardRef<HTMLDivElement | null, IHeaderProps>(
  (
    {
      title,
      isTitleRed = false,
      transparent = false,
      ignored = false,
      enableBack = false,
      callBack,
      padding = "20px",
      costDetailShow = false,
      headStyle = {},
      rightBtnText,
      rightTipText,
      rightTipContent,
      rightBtnCallBack,
      rightBtnOnMouseEnter,
      rightBtnOnMouseLeave,
      rightBtnOnTouchStart,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const [showTips, setShowTips] = useState<boolean>(false);

    const handleBack = () => {
      if (callBack) {
        callBack();
      } else {
        navigate(-1);
      }
    };

    return (
      <Wrapper
        $transparent={transparent}
        $isTieleRed={isTitleRed}
        style={{ padding }}
        ref={ref}
      >
        <div style={{ ...headStyle }} className={`head `}>
          {(enableBack || callBack) && (
            <HeaderBackBtnWrapper onClick={handleBack}>
              <img src={LeftBlueArrowSvg} className="icon" alt="back" />
            </HeaderBackBtnWrapper>
          )}
          <span>{title}</span>
          {rightBtnText && (
            <div
              className="right-btn"
              onClick={() => {
                rightBtnCallBack?.();
              }}
              onMouseEnter={rightBtnOnMouseEnter}
              onMouseLeave={rightBtnOnMouseLeave}
              onTouchStart={rightBtnOnTouchStart}
            >
              {rightBtnText}
            </div>
          )}
          {rightTipText && (
            <Tips
              className="right-btn"
              contentStyle={{
                right: "0px",
                top: "40px",
                padding: "0px 8px",
                width: "300px",
              }}
              showTips={showTips}
              showTitle={false}
              tipsContent={rightTipContent}
              triangleLeft={"255px"}
              triggerElement={
                <span
                  onMouseOver={() => {
                    setShowTips(true);
                  }}
                  onMouseOut={() => {
                    setShowTips(false);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTips(!showTips);
                  }}
                >
                  {rightTipText}
                </span>
              }
            />
          )}
        </div>
      </Wrapper>
    );
  }
);

export default Header;
