import { useTranslation } from "react-i18next";
import styled from "styled-components";
import switchIcon from "src/assets/icons/trade/market-switch.svg";
import toast from "src/components/toast/Toast";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Text = styled.span`
  color: var(--Deep-100, #cccfd2);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const SwitchIcon = styled.img`
  margin-left: 4px;
  width: 16px;
  height: 16px;
`;

interface IProps {
  className?: string;
}

export const PriceChangeButton: React.FC<IProps> = ({ className }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    toast({
      text: "Currently, only market price orders are supported",
      type: "warning",
    });
  };

  return (
    <Container className={className} onClick={handleClick}>
      <Text>{t("Market")}</Text>
      <SwitchIcon src={switchIcon} />
    </Container>
  );
};
