import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BASIC_SHEET_INDEX, EXPLORER_URL } from "src/config";
import { KontosButton } from "../button/KontosButton";
import FloatingButton from "src/pages/trade/FloatingButton";
import openUrlIcon from "src/assets/icons/task/open-url.svg";
import { forwardRef, useCallback, useRef, useState } from "react";
import toast from "src/components/toast/Toast";
import { openUrl, sleep } from "src/utils/helper";
import spinIco from "src/assets/icons/dapp/spin.png";
import kontosRedirectIcon from "src/assets/icons/kontos-redirect.svg";
import errorIcon from "src/assets/icons/error1.svg";

const Container = styled.div`
  border-radius: 16px;
  background: var(--White, #fff);
  max-width: 343px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .header-icon {
    width: 24px;
    height: 24px;
  }

  .header-text {
    color: var(--error-notice, #ff1e2b);
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }
`;

const Desc = styled.div`
  text-align: center;

  .desc-normal {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    line-height: 130%;
  }

  .desc-highlight {
    color: var(--Kontos-Blue, #413dec);
    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;
    line-height: 130%;
  }
`;

const Tip = styled.div`
  color: var(--Deep-400, #80868f);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  button {
    flex-grow: 1;
    height: 34px;
    line-height: 16px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;

    .my-loading {
      width: 24px;
      height: 24px;
      animation: dapp-connection-spin 1s linear infinite;

      @keyframes dapp-connection-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    .my-redirect {
      margin-left: 6px;
      width: 24px;
      height: 24px;
    }
  }
`;

const StyledFloatingButton = styled(FloatingButton)`
  height: 40px;
  font-size: 16px;
`;

interface IProps {
  accountName?: string;
  onRetry: () => Promise<void>;
  onClose: (open: boolean) => void;
}

export const NoNetworkPopup = forwardRef<HTMLDivElement, IProps>(
  ({ accountName, onClose, onRetry }, ref) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const handleHelp = useCallback(() => {
      const url = "https://discord.gg/zecrey";
      openUrl(url);
    }, []);

    const handleView = useCallback(() => {
      const url = accountName
        ? EXPLORER_URL + "account-detail?evm_addr=" + accountName
        : EXPLORER_URL;
      openUrl(url);
    }, [accountName]);

    const handleRetry = useCallback(async () => {
      try {
        setLoading(true);
        await sleep(1000);
        await onRetry();
        toast({
          text: t("Reconnect successful"),
          type: "success",
        });
        onClose(false);
      } catch (e) {
        toast({
          text: t(
            "The chain has not been restored yet. Please try again later"
          ),
          type: "warning",
        });
      } finally {
        setLoading(false);
      }
    }, [onClose, onRetry, t]);

    return (
      <Overlay
        zIndex={BASIC_SHEET_INDEX}
        outerOpacity={0.5}
        onClick={(e) => {
          if (!wrapperRef.current?.contains(e.target as Node)) onClose(false);
        }}
      >
        <Container ref={wrapperRef}>
          <Header>
            <img className="header-icon" src={errorIcon} alt="" />
            <span className="header-text">{t("Warning!")}</span>
          </Header>

          <Desc>
            <span className="desc-normal">
              {t(
                "An unknown error has occurred on the blockchain. Our team is urgently working to resolve it."
              )}
            </span>
            <br />
            <span className="desc-highlight">
              {t("Rest assured, your assets are safe.")}
            </span>
          </Desc>

          <Tip>
            {t(
              'Please wait patiently and try reconnecting by clicking the "Retry" button.'
            )}
          </Tip>

          <BtnGroup>
            <KontosButton onClick={handleRetry} $loading={loading}>
              {loading ? (
                <img className="my-loading" src={spinIco} alt="loading..." />
              ) : (
                t("Retry")
              )}
            </KontosButton>
            <KontosButton $isOutlined onClick={handleHelp}>
              {t("I Need Help!")}
              <img className="my-redirect" src={kontosRedirectIcon} alt="" />
            </KontosButton>
          </BtnGroup>

          <StyledFloatingButton onClick={handleView} fixedIcon={openUrlIcon}>
            {t("View on Kontos Explorer")}
          </StyledFloatingButton>
        </Container>
      </Overlay>
    );
  }
);
