import { observer } from "mobx-react";
import styled from "styled-components";
import { t } from "i18next";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import React, { useRef, useState, useEffect, useCallback } from "react";
import Sheet from "react-modal-sheet";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import { useNavigate } from "react-router";
import SecuritySettingsRecover from "src/components/start/SecuritySettingsRecover";
import { useSearchParams } from "react-router-dom";
import Congratulations from "src/components/start/Congratulations";
import PinCode from "src/components/start/PinCode";
import NormalRecover from "src/components/start/NormalRecover";
import { SmartAccount } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/aa";
import WaitingAuthorizeKontos from "src/components/start/WaitingAuthorizeKontos";
import WaitingAuthorizeMail from "src/components/start/WaitingAuthorizeMail";
import { KontosKey, StorageKontosKey } from "@zkkontos/kontos-sdk";
import { debounce } from "lodash";
import HeaderRightComponent from "src/components/start/HeaderRightComponent";
import { statusRouterMap } from "src/components/start/routerMap";
import { ROUTE_MAIN } from "src/router/router-config";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";
import { getStorageKontosValList } from "src/store/localKeeper";
import { getNoSuffixKontosName } from "src/utils/helper";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 20px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const WrapperExistToast = styled.div`
  span {
    color: #413dec;
    text-decoration: underline;
  }
`;

const kontosNameValidatePageArr = [
  statusRouterMap.pinCode,
  statusRouterMap.securitySettingsRecover,
  statusRouterMap.waitingAuthorizeKontos,
  statusRouterMap.waitingAuthorizeMail,
  statusRouterMap.congratulations,
];

const seedValidatePageArr = [
  statusRouterMap.securitySettingsRecover,
  statusRouterMap.waitingAuthorizeKontos,
  statusRouterMap.waitingAuthorizeMail,
  statusRouterMap.congratulations,
];

const withOutHeaderPageArr = [statusRouterMap.congratulations];

let interval: any = null;

export function checkIsAccountExistence(
  accountName: string,
  dataA: string[],
  dataB: StorageKontosKey[]
): boolean {
  const isInA = dataA.includes(accountName);
  const isInB = dataB.some(
    (accountData) => accountData.accountName === accountName
  );
  return !isInA && isInB;
}

const RecoverAccount: React.FC = () => {
  const { userStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [showBottomPopup, setShowBottomPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [guardianArr, setGuardianArr] = useState<string[]>([]);
  const [kontosName, setKontosName] = useState(searchParams.get("name") || "");
  const setKontosNameTrimed = useCallback((newValue: string) => {
    setKontosName(newValue.trim());
  }, []);
  const [hasSeed, setHasSeed] = useState(false);
  const navigate = useNavigate();
  const [seed, setSeed] = useState("");
  const [kontosNameInfo, setKontosNameInfo] = useState<
    SmartAccount | undefined
  >(undefined);
  const [kontosKey, setKontosKey] = useState<KontosKey | undefined>(undefined);
  const [nonce, setNonce] = useState(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);

  const routeStatus = searchParams.get("type") || statusRouterMap.normal;

  const getKontosNameInfo = async (kontosName: string) => {
    const cli = await userStore.getKontosQueryCli();
    const account = await cli.getSmartAccountByName(kontosName);
    setKontosNameInfo(account);
    return account;
  };

  const existToastNodeElement = () => {
    return (
      <WrapperExistToast>
        {t("This account has been recovered in your wallet.")}
        <a
          onClick={() => {
            navigate(ROUTE_MAIN);
          }}
          dangerouslySetInnerHTML={{
            __html: t("Click here to go to your dashboard."),
          }}
        />
      </WrapperExistToast>
    );
  };

  // If PIN is already set, skip PIN setup
  const checkSeed = async () => {
    const storageKey = userStore.getStorageKontosKey();
    const seed = userStore.getPin();
    if (storageKey && seed) {
      setSeed(seed);
      setHasSeed(true);
    }
  };

  // check recover success
  useEffect(() => {
    const checkIsRecoverSuccess = async () => {
      if (!kontosKey || !kontosNameInfo) return;
      const compressedPubKey = kontosKey.pubKeyData.compressedPubKey;
      const pubKey = kontosNameInfo.pubKey;
      // recover is success
      if (compressedPubKey === pubKey) {
        // go to congratulations page
        setSearchParams({ type: statusRouterMap.congratulations });
        // clear interval and reset recover status
        clearInterval(interval);
        // remove kontosName from accountsRecovering
        const accountsRecovering = userStore.accountsRecovering || [];
        const index = accountsRecovering.indexOf(kontosName);
        if (index > -1) {
          accountsRecovering.splice(index, 1);
          userStore.updateAccountsRecovering(accountsRecovering);
        }
      }
    };
    const refreshKontosNameInfo = async () => {
      if (
        routeStatus === statusRouterMap.waitingAuthorizeKontos ||
        routeStatus === statusRouterMap.waitingAuthorizeMail
      ) {
        getKontosNameInfo(kontosName).then((account) => {
          checkIsRecoverSuccess();
        });
      }
    };
    clearInterval(interval);
    interval = null;
    interval = setInterval(() => {
      refreshKontosNameInfo();
    }, 10000);
  }, [kontosKey, kontosNameInfo, routeStatus, setSearchParams, kontosName]);

  useEffect(() => {
    checkSeed();
  }, []);

  useEffect(() => {
    // check route is in kontosNameValidatePageArr
    if (kontosNameValidatePageArr.includes(routeStatus) && kontosName === "") {
      setSearchParams({
        type: statusRouterMap.normal,
      });
    }
    if (!seed && seedValidatePageArr.includes(routeStatus)) {
      setSearchParams({
        type: statusRouterMap.normal,
      });
    }
  }, [kontosName, routeStatus, setSearchParams]);

  const handleGetKontosNameInfo = useCallback(
    debounce(async (kontosName) => {
      console.log("request", kontosName);
      if (!kontosName) {
        return;
      }
      try {
        return await getKontosNameInfo(kontosName);
      } catch (e) {
        console.warn(e);
        setKontosNameInfo(undefined);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (!kontosName) return;
    handleGetKontosNameInfo(kontosName);
  }, [kontosName]);

  return (
    <Wrapper ref={wrapperRef}>
      {!withOutHeaderPageArr.includes(routeStatus) && (
        <SheetUpHeader
          handleBack={() => {
            navigate(-1);
          }}
          headStyle={{ justifyContent: "center" }}
          title={t("Recover Wallet")}
          padding="8px"
          rightComponent={
            <HeaderRightComponent type={"recover"} kontosName={kontosName} />
          }
        />
      )}

      {routeStatus === statusRouterMap.normal && (
        <NormalRecover
          submitFunction={() => {
            // check if this kontos is in recovering or not
            const isExit = checkIsAccountExistence(
              kontosName,
              userStore.accountsRecovering,
              userStore.storageKeys
            );

            if (isExit) {
              const storeKontosValList = getStorageKontosValList();
              const matchAccountExitInLocalIndex = storeKontosValList.findIndex(
                (item: StorageKontosKey) => item.accountName === kontosName
              );
              matchAccountExitInLocalIndex !== -1 &&
                userStore.switchAccount(matchAccountExitInLocalIndex);
              toast({
                type: "warning",
                text: existToastNodeElement(),
              });
              return;
            }
            if (hasSeed) {
              setSearchParams({
                type: statusRouterMap.securitySettingsRecover,
              });
            } else {
              setShowPinCodeModal(true);
            }
          }}
          kontosName={kontosName}
          setKontosName={setKontosNameTrimed}
        />
      )}

      {routeStatus === statusRouterMap.waitingAuthorizeKontos && (
        <WaitingAuthorizeKontos
          kontosName={kontosName}
          guardianArr={guardianArr}
          setGuardianArr={setGuardianArr}
          actionThreshold={kontosNameInfo?.actionThreshold || 0}
          submitFunction={() => {
            setShowBottomPopup(true);
          }}
          kontosKey={kontosKey}
        />
      )}

      {routeStatus === statusRouterMap.waitingAuthorizeMail && (
        <WaitingAuthorizeMail
          kontosName={kontosName}
          userEmailArr={
            kontosNameInfo?.emailGuardians.map((item) => item.mailHash) || []
          }
          submitFunction={() => {
            setShowBottomPopup(true);
          }}
          nonce={nonce}
          kontosKey={kontosKey}
          actionThreshold={kontosNameInfo?.actionThreshold || 0}
        />
      )}

      {routeStatus === statusRouterMap.securitySettingsRecover && (
        <SecuritySettingsRecover
          userEmailArr={
            kontosNameInfo?.emailGuardians.map((item) => item.mailHash) || []
          }
          guardianArr={kontosNameInfo?.guardians || []}
          kontosName={getNoSuffixKontosName(kontosName)}
          seed={seed}
          setRouteStatus={(params) => {
            setSearchParams({
              type: params,
            });
          }}
          setKontosKey={setKontosKey}
          setNonce={setNonce}
        />
      )}

      {routeStatus === statusRouterMap.congratulations && (
        <Congratulations congratulationType={"recovered"} />
      )}

      <NoScrollerBarSheet
        isOpen={showPinCodeModal}
        onClose={() => setShowPinCodeModal(false)}
        mountPoint={domNode}
        // disableDrag={true}
        disableScrollLocking={true}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <PinCode
              submitFunction={() => {
                setShowPinCodeModal(false);
                setSearchParams({
                  type: statusRouterMap.securitySettingsRecover,
                });
              }}
              seed={seed}
              setSeed={setSeed}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setShowPinCodeModal(false)} />
      </NoScrollerBarSheet>
    </Wrapper>
  );
};

export default observer(RecoverAccount);
