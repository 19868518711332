import {
  ROUTE_MAIN_RECEIVE,
  ROUTE_MAIN_RECEIVE_CHOOSE,
} from "src/router/router-config";
import { Route, Routes } from "react-router-dom";
import Receive from "./Receive";
import ReceiveChoose from "./ReceiveChoose";

const ReceiveRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTE_MAIN_RECEIVE} element={<Receive />} />
      <Route path={ROUTE_MAIN_RECEIVE_CHOOSE} element={<ReceiveChoose />} />
    </Routes>
  );
};

export default ReceiveRouter;
