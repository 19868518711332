import toast from "src/components/toast/Toast";
import { rootStore } from "..";

export enum RouterBotType {
  Transaction,
  Other,
}

export const backToBot = (
  guild: string | undefined,
  channel: string | undefined,
  type: RouterBotType = RouterBotType.Other
) => {
  if (rootStore.userStore.kontosCtx?.cmd_source === "discord") {
    try {
      if (rootStore.userStore.kontosCtx?.platform === "desktop") {
        guild && channel
          ? (window.location.href = `discord://-/channels/${guild}/${channel}`)
          : (window.location.href = `discord://-/channels/@me`);
      } else if (rootStore.userStore.kontosCtx.platform === "web") {
        guild && channel
          ? (window.location.href = `https://discord.com/channels/${guild}/${channel}`)
          : (window.location.href = `https://discord.com/channels/@me`);
      }
    } catch (e) {
      toast({
        type: "info",
        text: `If you need to ${
          type === RouterBotType.Transaction
            ? "check the transaction hash or"
            : ""
        }  continue using KontosBot, close this page and return to Discord.`,
      });
    }
  } else if (rootStore.userStore.kontosCtx?.cmd_source === "telegram") {
    toast({
      type: "info",
      text: `If you need to ${
        type === RouterBotType.Transaction
          ? "check the transaction hash or"
          : ""
      } continue using KontosBot, close this page and return to Telegram.`,
    });
  } else {
    toast({
      type: "info",
      text: "Please close this page and continue",
    });
  }
};

export const getBtnText = (): string => {
  if (rootStore.userStore.kontosCtx?.cmd_source === "discord") {
    return "Back to Discord";
  }
  // else if (statusKeeper.kontosCtx.cmd_source === "telegram") {
  //   return "Back to Telegram";
  // }
  else {
    return "OK";
  }
};
