export const API = {
  aa: {
    emailRegisterCode: "/api/v1/aa/emailregistercode",
    emailRegisterVerify: "/api/v1/aa/emailregisterverify",
    accountsNameRegex: "/api/v1/aa/accountsnameregex",
  },
  wallet: {
    account: "/api/v1/wallet/account",
    backpack: "/api/v1/wallet/backpack",
    openEnergyOrb: "/api/v1/wallet/openenergyorb",
    boost: "/api/v1/wallet/boost",
    boostHistory: "/api/v1/wallet/boosthistory",
    notifications: "/api/v1/wallet/notifications",
    claim: "/api/v1/wallet/claim",
    boostBoard: "/api/v1/wallet/boostboard",
    twitterAuth: "/api/v1/wallet/twitterauth",
    discordAuth: "/api/v1/wallet/discordauth",
    errorCatch: "/api/v1/wallet/errorcatch",
    leaderboard: "/api/v1/wallet/leaderboard",
  },
  dapp: {
    dapps: "/api/v1/dapp/dapps",
    watchlist: "/api/v1/dapp/watchlist",
    watchdapporftasset: "/api/v1/dapp/watchdapporftasset",
    searchdapp: "/api/v1/dapp/searchdapp",
    sort: "/api/v1/dapp/sort",
  },
  markets: {
    ftAssetsLabel: "/api/v1/asset/ftassetslabel",
    ftAssetsV2: "/api/v1/asset/ftassetsv2",
    ftAssetsV3: "/api/v1/asset/ftassetsv3",
    OHLC: "/api/v1/asset/ohlc",
    ftAssetDetail: "/api/v1/asset/ftassetdetail",
    vote: "/api/v1/asset/marketpredict",
    marketPredictInfo: "/api/v1/asset/marketpredictinfo",
    ftAssetsRiskInfo: "/api/v1/asset/ftassetsecurity",
  },
  account: {
    referralInfo: "/api/v1/stats/inviters",
    syncAccount: "/api/v1/asset/syncsinglebalance",
  },
  kontosChain: {
    signerNativeTxsAssetsChange:
      "/api/v1/kontoschain/signernativetxsassetschange",
  },
  asset: {
    ftassetDetail: "/api/v1/asset/ftassetdetail",
  },
};
