import styled from "styled-components";
import NoDataV2 from "./NoDataV2";
import addIcon from "src/assets/icons/discover/add.svg";

const Container = styled.div``;

const AddButton = styled.div`
  margin-top: 20px;
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 99px;
  border: 1px solid var(--Kontos-Blue, #413dec);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
    height: 16px;
  }

  div {
    margin-left: 6px;
    color: var(--Kontos-Blue, #413dec);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }
`;

interface IProps {
  className?: string;

  noDataText: string;
  btnText: string;
  addCallback: () => void;
}

export const NoDataAndAdd: React.FC<IProps> = ({
  className,
  noDataText,
  btnText,
  addCallback,
}) => {
  return (
    <Container className={className}>
      <NoDataV2 text={noDataText}>
        <AddButton onClick={addCallback}>
          <img src={addIcon} alt="" />
          <div>{btnText}</div>
        </AddButton>
      </NoDataV2>
    </Container>
  );
};
