import styled from "styled-components";
import React from "react";
import { t } from "i18next";
import PoweredBy from "src/components/common/powerdBy";
import { Button } from "src/components/button/Button";
import blockchainlogo from "src/assets/images/blockchainlogo.png";
import congratulation from "src/assets/images/congratulations.png";
import { ROUTE_ENERGYBOOSTING, ROUTE_HOME } from "src/router/router-config";
import { useNavigate } from "react-router-dom";
import { useStores } from "src/hooks/useStore";
import { Flag } from "src/configs/featureFlags";

const Wrapper = styled.div`
  min-height: 100%;
  flex: 1;
  display: flex;
  background-image: url(/static/bg-light-new.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  margin: -20px;

  .main {
    min-height: 100%;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px 16px 0;
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    .content {
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      z-index: 2;
      //padding: 0 16px;
    }
    .congratulation {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 230px;
      > img {
        width: 96px;
        height: 96px;
      }
      .congratulationImg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        img {
          width: 353px;
          height: 230px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
      }
    }
    .title {
      color: var(--Kontos-Blue, #413dec);
      text-align: center;
      margin-top: -40px;

      /* H2 */
      font-family: HarmonyOS Sans SC;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 20px;
    }
    .description {
      color: var(--Kontos-Blue, #413dec);
      text-align: center;
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.7);
      padding: 15px 21px;
      box-sizing: border-box;
      margin-bottom: 45px;
    }
    .buttonWrap {
      width: calc(100% - 40px);
      position: fixed;
      bottom: 16px;
      z-index: 3;
    }
  }
`;

type IProps = {
  congratulationType: string;
};

const Congratulations = ({ congratulationType }: IProps) => {
  const { userStore } = useStores();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="main">
        <div className={"content"}>
          <div className={"congratulation"}>
            <img
              className={"logoIcoSmall"}
              src="/static/kontos-app.svg"
              alt="kontos-app"
            />
            <div className={"congratulationImg"}>
              <img
                src={congratulation}
                className="congratulation"
                alt="congratulation"
              />
            </div>
          </div>
          <div className={"title"}>{t("Congratulations!")}</div>
          <div className={"description"}>
            {congratulationType === "created" &&
              t("Your Kontos wallet has been successfully created.")}
            {congratulationType === "recovered" &&
              t("Your Kontos wallet has been successfully recovered.")}
          </div>
          <img
            src={blockchainlogo}
            className="blockchainlogo"
            alt="blockchainlogo"
          />
        </div>
        <div className="buttonWrap">
          <Button
            wrapperStyle={{ marginBottom: "39px" }}
            onClick={async () => {
              await userStore.restore(true);
              if (userStore.routeAfterAuth) {
                navigate(userStore.routeAfterAuth);
                userStore.updateRouteAfterAuth(undefined);
              } else {
                const toRoute =
                  Flag.eb && userStore.storageKeys.length < 2
                    ? ROUTE_ENERGYBOOSTING
                    : ROUTE_HOME;
                navigate(toRoute);
              }
            }}
            text={t("Let's go")}
            height={"56px"}
          />
          <div style={{ marginTop: "-20px", paddingBottom: "40px" }}>
            <PoweredBy />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Congratulations;
