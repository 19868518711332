import styled from "styled-components";
import React, { useEffect, useMemo } from "react";
import { t } from "i18next";
import { PINCodeInput } from "src/components/images/PINCodeInput";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(/static/bg-light.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  /* margin: -20px;
  padding: 20px 16px;
  flex: 1; */

  box-sizing: border-box;

  width: 100%;
  height: 100%;
  padding: 20px;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 375px;
  }

  .app-icon {
    width: 96px;
    height: 96px;
    margin-top: -70px;
    margin-bottom: 16px;
  }

  .title {
    color: #413dec;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    font-family: "HarmonyOS Sans SC";
    margin-bottom: 6px;
  }

  .desc {
    border-radius: 8px;
    border: 1px solid var(--Deep-25, #f5f5f6);
    background: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Kontos-Blue, #413dec);
    text-align: center;
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 68px;
    width: 100%;
    margin-bottom: 20px;
  }

  .footer {
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    transform: translateY(23px);

    .logo {
      height: auto;
      width: 65px;
      margin-left: 8px;
      transform: translateY(2px);
    }

    .radio {
      margin: 0;
      margin-right: 10px;
      appearance: none;
      position: relative;

      :before {
        content: "";
        width: 15px;
        height: 15px;
        border: 1px solid #413dec;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
      }
    }

    .checked:after {
      content: "";
      width: 11px;
      height: 11px;
      background: #413dec;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      position: absolute;
    }

    .link {
      color: #413dec;
      cursor: pointer;
      margin-left: 4px;
    }

    .logo {
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    margin-bottom: 40px;

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #1a2535;
      text-align: center;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  .buttonWrap {
    max-width: 375px;
    width: 100%;
    // padding-bottom: 65px;
  }
`;

export const MAX_UNLOCK_COUNT = 9;

type IPinCodeProps = {
  seed: string;
  setSeed: (seed: string) => void;
  submitFunction: () => void;
};

const PinCode: React.FC<IPinCodeProps> = ({
  seed,
  setSeed,
  submitFunction,
}) => {
  const unlockConfirm = useMemo(() => {
    return seed !== "" && seed.length === 6;
  }, [seed]);

  useEffect(() => {
    setSeed("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unlockConfirm) {
      submitFunction();
    }
  }, [unlockConfirm]);

  return (
    <Wrapper>
      <div className="main">
        <img
          className="app-icon"
          src="/static/kontos-app.svg"
          alt="kontos-app"
        />
        <div className="title">{t("Kontos Wallet")}</div>
        <div className="desc">{t("Please enter the PIN code to confirm.")}</div>
        <div className="input-wrapper">
          <PINCodeInput size="small" onPINChange={setSeed} />
        </div>
      </div>
      {/*<div className={"buttonWrap"}>*/}
      {/*  <Button*/}
      {/*    text={t("Confirm")}*/}
      {/*    fullWidth*/}
      {/*    disabled={!unlockConfirm}*/}
      {/*    onClick={() => {*/}
      {/*      setTimeout(() => {*/}
      {/*        submitFunction();*/}
      {/*      }, 400);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <PoweredBy />*/}
      {/*</div>*/}
    </Wrapper>
  );
};

export default PinCode;
