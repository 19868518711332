import React from "react";
import styled from "styled-components";
import skeletonChartIco from "src/assets/icons/skeleton/skeleton-chart-ico.svg";
import noDataBgIco from "src/assets/icons/no-data-bg.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${noDataBgIco}) repeat-x left center;
  position: relative;
  .ico{
    width: 234px;
    height: 40px;
  }
`;

const MaskWrapper = styled.div`
  --animation-duration: 1.5s;
  --animation-direction: normal;
  width: 100%;
  display: inline-flex;
  line-height: 1;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, .7) 30%,
        rgba(255, 255, 255, .9) 50%,
        rgba(255, 255, 255, .7) 70%,
        rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(-100%);
    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`

interface Props {
  style?: React.CSSProperties;
  className?: string;
  text?: string;
}

export default function SkeletonChart({ style, className, text }: Props) {
  return (
    <Wrapper style={{ ...style }} className={className}>
      <img src={skeletonChartIco} className="ico" />
      <MaskWrapper />
    </Wrapper>
  );
}
