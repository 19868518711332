import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchSvg from "src/assets/icons/trade/trade-search.svg";
import { t } from "i18next";
import { useStores } from "src/hooks/useStore";
import { AssetsList } from "src/components/list/assetList";
import Skeleton from "react-loading-skeleton";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { getFtAssetsV3 } from "src/apis/markets-apis";
import { useNavigate, useSearchParams } from "react-router-dom";
import defaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import Header from "src/components/common/header";
import { debounce } from "lodash";
import SkeletonMarketAssetList from "src/components/skeleton/SkeletonMarketAssetList";
import { FtAsset } from "@/type/zkkontos";
import { isFavoriteFtAsset } from "src/utils/helper";
import { loadingStore } from "src/store/loadingStore";
import toast from "src/components/toast/Toast";
import { observer } from "mobx-react";
import { TokenOrChainIcon } from "src/components/icons/TokenOrChainIcon";

const SearchContainer = styled.div`
  margin: 0 16px 0 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  //height: 100%;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 11px 10px 10px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  margin-bottom: 8px;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

const ListWrapper = styled.div`
  padding: 0 4px;
  width: 100%;
  // height: 347px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div,
  > span {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 130px);

  > div {
    width: 100%;
  }

  .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    padding-top: 6px;
    margin-bottom: 10px;
  }

  .groupTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 14px;
    margin-bottom: 13px;

    .img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }

  .scroll {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    flex: 1;
    padding-bottom: 30px;
  }
`;

interface ISelectFtAssetsModalProps {
  onClose?: () => void;
}

interface AssetGroupByChain {
  chainIndex: string;
  chainImageUrl: string;
  chainSymbol: string;
  data: FtAsset[];
}

function groupAssetsByChainIndex(assets: FtAsset[]): AssetGroupByChain[] {
  return assets.reduce((accumulator: AssetGroupByChain[], current: FtAsset) => {
    let group = accumulator.find((g) => g.chainIndex === current.chainIndex);
    if (!group) {
      group = {
        chainIndex: current.chainIndex,
        chainSymbol: current.chainSymbol,
        chainImageUrl: current.chainImageUrl, // 假设第一个找到的 imageURL 代表整个分组
        data: [],
      };
      accumulator.push(group);
    }
    group.data.push(current);
    return accumulator;
  }, []);
}

const SelectFtAssetsModal: React.FC<ISelectFtAssetsModalProps> = ({
  onClose,
}) => {
  const { marketStore, favStore, userStore } = useStores();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDisplayTerm, setSearchDisplayTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [ftAssets, setFtAssets] = useState<FtAsset[]>([]);
  const [groupedAssets, setGroupedAssets] = useState<AssetGroupByChain[]>([]);
  const [loadingFtAssets, setLoadingFtAssets] = useState<boolean>(false);
  const [marketPageCount, setMarketPageCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // quote modal
  const [searchParams, setSearchParams] = useSearchParams();
  const showQuote = searchParams.get("showQuote");

  useEffect(() => {
    function handleScroll() {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        // load data
        if (
          !loadingFtAssets &&
          hasMore &&
          scrollHeight - scrollTop - clientHeight < 50
        ) {
          setMarketPageCount(marketPageCount + 1);
        }
      }
    }

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, loadingFtAssets]);

  const loadMore = useCallback(async () => {
    try {
      setLoadingFtAssets(true);
      const { ftAssets } = await getFtAssetsV3({
        chainIndex: "",
        category: !searchTerm ? 4 : -1,
        subcategory: "",
        offset: (marketPageCount - 1) * pageSize,
        limit: pageSize,
        // account: userStore.accountInfo?.name,
        regex: searchTerm,
      });
      setLoadingFtAssets(false);
      setFtAssets((prevItems) => [...prevItems, ...(ftAssets || [])]);
      if (!ftAssets || ftAssets.length < pageSize) {
        setHasMore(false);
        return;
      }
    } catch (e) {
      console.log("get market data err: ", e);
      setLoadingFtAssets(false);
    }
  }, [marketPageCount, searchTerm]);

  useEffect(() => {
    loadMore();
  }, [marketPageCount, searchTerm]);

  useEffect(() => {
    setGroupedAssets(groupAssetsByChainIndex(ftAssets || []));
  }, [ftAssets]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDisplayTerm(e.target.value);
    handleChangeDebunce(e.target.value);
  };

  // Debounce the onChange handler
  const handleChangeDebunce = useCallback(
    debounce((term: string) => {
      if (term === searchTerm) return;
      setFtAssets([]);
      setMarketPageCount(1);
      setHasMore(true);
      setSearchTerm(term);
    }, 800),
    [searchTerm]
  );

  const hanldeUpdateFtAssetFavs = useCallback(
    async (ftAsset: FtAsset) => {
      const cli = userStore.kontosCli;
      if (!cli) {
        userStore.unlock(() => hanldeUpdateFtAssetFavs(ftAsset));
        return;
      }

      try {
        loadingStore.showLoading();
        if (!isFavoriteFtAsset(ftAsset, favStore.ftAssetFavorites))
          await favStore.addFavorites({ ftAssetId: ftAsset.ftAssetId, cli });
        else
          await favStore.removeFavorites({ ftAssetId: ftAsset.ftAssetId, cli });
      } catch (e) {
        console.log("Failed to update favorites", e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : t("Failed to save. Please try again later.");
        toast({
          type: "error",
          text: errorMessage,
        });
      } finally {
        loadingStore.hideLoading();
      }
    },
    [favStore, userStore]
  );

  return (
    <SearchContainer>
      <Header
        padding={"15px 5px 15px"}
        callBack={() => {
          onClose && onClose();
        }}
        title={t("Search")}
        enableBack={true}
      />
      <SearchInput
        ref={inputRef}
        placeholder={t("Search assets name / contract address")}
        value={searchDisplayTerm}
        onChange={handleChange}
      />
      <ContentWrapper>
        {!searchTerm && <div className="title">{t("Top Searches")}</div>}
        <div className={"scroll"} ref={scrollContainerRef}>
          <ListWrapper>
            {!searchTerm &&
              ftAssets.map((item, index) => (
                <AssetsList
                  item={{
                    name: item.name,
                    symbol: item.symbol,
                    imageUrl: item.imageUrl,
                    desc: "",
                    assetType: 0,
                    ftAssets: [],
                    totalUsd: item.h24Volume,
                  }}
                  chainUrl={item.chainGreyImageUrl}
                  fluctuate={item.h24PriceChangePercentage}
                  isMarket={true}
                  usdPrice={item.usdPrice}
                  chainSymbol={item.chainSymbol}
                  key={index.toString()}
                  handleClick={() => {
                    marketStore.setCurrentAssetQuote(item);
                    setSearchParams({
                      ...searchParams,
                      ftAssetId: item.ftAssetId.toString(),
                      chainIndex: item.chainIndex,
                      ftAssetAddress: item.address,
                      showQuote: "1",
                      hideNavigationBar: "1",
                    });
                  }}
                  index={index}
                  showRanking={true}
                  isVerified={item.isGreatLiquidity === 1}
                  volumeUnit={t("Vol: ")}
                  isGreatLiquidity={item.isGreatLiquidity}
                  securityLevel={item.securityLevel}
                />
              ))}
            {searchTerm && <div style={{ height: "14px" }} />}
            {searchTerm &&
              groupedAssets.map((group, index) => (
                <div key={index.toString()}>
                  <div className="groupTitle">
                    <TokenOrChainIcon
                      className="img"
                      size={22}
                      src={group.chainImageUrl}
                      type="chain"
                    />

                    {group.chainSymbol || "-"}
                  </div>
                  {group.data.map((item, index) => (
                    <AssetsList
                      item={{
                        name: item.name,
                        symbol: item.symbol,
                        imageUrl: item.imageUrl,
                        desc: "",
                        assetType: 0,
                        ftAssets: [],
                        totalUsd: item.h24Volume,
                      }}
                      chainUrl={item.chainGreyImageUrl}
                      fluctuate={item.h24PriceChangePercentage}
                      isMarket={true}
                      usdPrice={item.usdPrice}
                      chainSymbol={item.chainSymbol}
                      key={index.toString()}
                      handleClick={() => {
                        marketStore.setCurrentAssetQuote(item);
                        setSearchParams({
                          ...searchParams,
                          ftAssetId: item.ftAssetId.toString(),
                          chainIndex: item.chainIndex,
                          ftAssetAddress: item.address,
                          showQuote: "1",
                          hideNavigationBar: "1",
                        });
                      }}
                      index={index}
                      showStar={true}
                      isStared={isFavoriteFtAsset(
                        item,
                        favStore.ftAssetFavorites
                      )}
                      isVerified={item.isGreatLiquidity === 1}
                      volumeUnit={t("Vol: ")}
                      onFavClick={() => {
                        hanldeUpdateFtAssetFavs(item);
                      }}
                    />
                  ))}
                </div>
              ))}
            {loadingFtAssets && (
              <>
                <div style={{ height: "18px" }} />
                <SkeletonMarketAssetList
                  length={ftAssets.length === 0 ? 6 : 3}
                />
              </>
            )}
            {!loadingFtAssets && !hasMore && <></>}
            {!loadingFtAssets && ftAssets.length === 0 && (
              <NoDataV2
                style={{
                  paddingTop: "50px",
                  alignItems: "center",
                }}
                text={t("No data")}
              />
            )}
          </ListWrapper>
        </div>
      </ContentWrapper>
    </SearchContainer>
  );
};

export default observer(SelectFtAssetsModal);
