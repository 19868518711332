import { SearchInput } from "src/components/input-search/InputSearch";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { KontosTab, Tab } from "src/components/tab/KontosTab";
import scanIcon from "src/assets/icons/discover/scan.svg";
import scanActiveIcon from "src/assets/icons/discover/scan-active.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  height: 112px;
  width: 100%;
  background: var(--White, #fff);
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
`;

const PlaceHolder = styled.div`
  height: 20px;
  width: 100%;
`;

const SearchScanContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledSearchInput = styled(SearchInput)`
  height: 40px;
`;

const ScanButton = styled.div`
  margin-left: 16px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: var(--White, #fff);
  background-image: url(${scanIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;

  @media (hover: hover) {
    &:hover {
      background-color: var(--Deep-25, #f5f5f6);
      background-image: url(${scanActiveIcon});
    }
  }
`;

const StyledTab = styled(KontosTab)`
  height: 20px;
  margin-top: 16px;
  padding: 0 8px;
`;

interface IProps {
  tabs: Tab[];
  onFocus: () => void;
  onScan: () => void;
}

export const DiscoverHeader: React.FC<IProps> = ({ tabs, onFocus, onScan }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <PlaceHolder />
      <SearchScanContainer>
        <StyledSearchInput
          type="text"
          readOnly
          placeholder={t("Enter DApp name or URL")}
          onFocus={(e) => {
            e.target.blur();
            onFocus();
          }}
        />
        <ScanButton onClick={onScan} />
      </SearchScanContainer>
      <StyledTab tabs={tabs} />
    </Container>
  );
};
