import styled from "styled-components";

export const Overlay = styled.div<{ zIndex?: number; outerOpacity?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.zIndex || 999999};
  background-color: ${(props) => `rgba(0, 0, 0, ${props.outerOpacity || 0.7})`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  /* cursor: ${(props) => (props.onClick ? "pointer" : "default")}; */
`;
