import styled from "styled-components";
import Markdown from "react-markdown";
import SkeletonRiskList from "src/components/skeleton/SkeletonRiskList";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { useTranslation } from "react-i18next";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";

const Container = styled.div``;

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
}

interface IProps {
  markdown?: string;
  requesting?: boolean;
}

export const AiScoreMarkdown: React.FC<IProps> = ({ markdown, requesting }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {markdown ? (
        <Markdown
          remarkPlugins={[remarkGfm, remarkEmoji]}
          components={{ a: LinkRenderer }}
        >
          {JSON.parse(markdown)}
        </Markdown>
      ) : requesting ? (
        <SkeletonRiskList length={2} showDivider={false} />
      ) : (
        <NoDataV2 text={t("No data")} />
      )}
    </Container>
  );
};
