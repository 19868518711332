import styled from "styled-components";
import QrScanImg from "src/assets/images/qr-view-finder.svg";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const ScanIcon = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
`;

const UpShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 21%;
  background: linear-gradient(180deg, #fff 16.5%, rgba(255, 255, 255, 0) 100%);
`;

const BottomShadow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 21%;
  background: linear-gradient(0deg, #fff 16.5%, rgba(255, 255, 255, 0) 100%);
`;

export const ViewFinder = (props: any) => {
  return (
    <Container>
      <UpShadow />
      <ScanIcon src={QrScanImg} />
      <BottomShadow />
    </Container>
  );
};
