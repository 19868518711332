import styled from "styled-components";
import bgImage from "src/assets/images/event/eb/eb-bg.jpg";
import leaderboardUpBgImage from "src/assets/images/event/eb/leaderboard-up.png";
import leaderboardDownBgImage from "src/assets/images/event/eb/leaderboard-down.png";
import EventHeader from "src/components/common/header/EventHeader";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  ROUTE_DEFAULT,
  ROUTE_ENERGYBOOSTING,
  ROUTE_ENERGYBOOSTING_LEADERBOARD,
} from "src/router/router-config";
import { observer } from "mobx-react";
import { Outlet, useLocation } from "react-router-dom";
import { useStores } from "src/hooks/useStore";
import EnergyBoostingTicker from "./EnergyBoostingTicker";
import { EbBoostHistoryPopup } from "./boost-history/EbBoostHistoryPopup";

const Conatiner = styled.div<{ $dark: boolean }>`
  background-color: ${(props) =>
    props.$dark ? `var(--Deep-900, #000D1F)` : `var(--White, #fff)`};
  background-image: ${(props) => (props.$dark ? `` : `url(${bgImage})`)};
  background-repeat: no-repeat;
  background-size: cover;

  height: 100%;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 111px; /* Height of bg image */
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    pointer-events: none;
  }

  &:before {
    top: 0;
    background-image: ${(props) =>
      props.$dark ? `url(${leaderboardUpBgImage})` : ``};
    background-position: center bottom;
  }

  &:after {
    bottom: 0;
    background-image: ${(props) =>
      props.$dark ? `url(${leaderboardDownBgImage})` : ``};
    background-position: center top;
  }
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

const EnergyBoosting: React.FC = () => {
  const { ebStore, userStore } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  const [showBoostHistory, setShowBoostHistory] = useState<boolean>(false);

  const handleOnBack = useCallback(() => {
    if (pathname.includes(ROUTE_ENERGYBOOSTING_LEADERBOARD)) {
      navigate(ROUTE_ENERGYBOOSTING);
    } else {
      navigate(ROUTE_DEFAULT);
    }
  }, [navigate, pathname]);

  const handleViewHistory = useCallback(() => {
    setShowBoostHistory(true);
  }, []);

  useEffect(() => {
    ebStore.startTrackingAccountInfo(userStore);
    ebStore.fetchAndSetEbNotifications();
    ebStore.startCronjob();
    return () => {
      ebStore.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { leftTitle, enableDarkMode } = useMemo(() => {
    if (pathname.includes(ROUTE_ENERGYBOOSTING_LEADERBOARD)) {
      return { leftTitle: t("Back"), enableDarkMode: true };
    }
    return { leftTitle: t("Home"), enableDarkMode: false };
  }, [pathname, t]);

  return (
    <Conatiner ref={wrapperRef} $dark={enableDarkMode}>
      <Scrollable>
        {/* Header */}
        <EventHeader
          leftTitle={leftTitle}
          leftOnClick={handleOnBack}
          rightTitle={t("Boost History")}
          onViewBoostHistory={handleViewHistory}
          enableDarkMode={enableDarkMode}
        />

        {!ebStore.showHelp && !enableDarkMode && (
          <EnergyBoostingTicker notifications={ebStore.ebNotifications} />
        )}

        <Outlet />
      </Scrollable>

      {/* Boosting History */}
      {showBoostHistory && (
        <EbBoostHistoryPopup
          onClose={setShowBoostHistory}
          boostMeList={ebStore.ebBoostMeList}
          boostMeListLoading={ebStore.ebBoostMeLoading}
          boostOthersList={ebStore.ebBoostOthersList}
          boostOthersListLoading={ebStore.ebBoostOthersLoading}
          darkMode={enableDarkMode}
        />
      )}
    </Conatiner>
  );
};

export default observer(EnergyBoosting);
