import { useRef } from "react";
import { Overlay } from "src/components/popups/Overlay";
import { OrbType } from "src/apis/types/energyBoostingTypes";
import { getRewardVideo } from "src/utils/events/ebHelper";

interface Props {
  orbType: OrbType;
  rewardRarity: "normal" | "rare";
  onVideoEnd: () => void;
}

export const EbOpenOrbOverlay: React.FC<Props> = ({
  orbType,
  rewardRarity,
  onVideoEnd,
}) => {
  const videoSrc = getRewardVideo(orbType, rewardRarity);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <Overlay zIndex={999999999} outerOpacity={1}>
      {/* <img src={openOrbGif} alt="opening..." /> */}
      <video
        id="js__protocol-video"
        muted
        autoPlay
        playsInline
        controls={false}
        ref={videoRef}
        className="protocol__introduce-advantage-video"
        src={videoSrc}
        onEnded={onVideoEnd}
      />
    </Overlay>
  );
};
