import PoweredBy from "src/components/common/powerdBy";
import { t } from "i18next";
import Header from "src/components/common/header";
import styled from "styled-components";
import avatarIcon from "src/assets/icons/settings/avatar.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import arrowIcon from "src/assets/icons/receive/right-black-arrow.svg";
import QRCode from "qrcode.react";
import Divider from "src/components/divider/Divider";
import { observer } from "mobx-react";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PREV, ROUTE_MAIN_RECEIVE_CHOOSE } from "src/router/router-config";
import copyIcon from "src/assets/icons/receive/copy.svg";
import toast from "src/components/toast/Toast";
import copy from "copy-to-clipboard";
import Tips from "src/components/tips/Tips";
import { isKontosChain, shortAddress } from "src/utils/helper";
import warningIco from "src/assets/icons/receive/warning.svg";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { TokenOrChainIcon } from "src/components/icons/TokenOrChainIcon";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  align-items: center;
  padding-bottom: 20px;

  background-image: url(/static/bg-receive.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
`;

const Body = styled.div`
  margin: 2px 16px 2px 16px;
  flex: 1;
  overflow-y: auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Panel = styled.div`
  padding: 8px 8px 21px 8px;
  max-width: 375px;
  min-width: 340px;

  overflow-y: auto;

  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.__deep_100};
  background: ${(props) => props.theme.colors.__white};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .warning {
    width: 100%;
    padding: 8px 20px;

    border-radius: 8px;
    background: rgba(250, 173, 20, 0.05);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .warning-icon {
      width: 16px;
      height: 16px;
    }

    .warning-text {
      margin-left: 16px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;

      color: var(--Warning, #faad14);
      font-family: "HarmonyOS Sans SC";
      font-size: 11px;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .account {
    margin-top: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    .account-avatar {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
    }

    .account-name {
      margin-left: 9px;

      color: ${(props) => props.theme.colors.__deep_800};
      font-family: HarmonyOS Sans;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .choose-tip {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;

    .strong {
      color: var(--error-notice, #ff1e2b);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 700;
      line-height: 130%;
    }
  }

  .chain-icon {
    margin-top: 14px;

    width: 64px;
    height: 64px;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .choose {
    margin-top: 12px;
    padding: 6px 24px;

    border-radius: 99px;
    background: var(--Deep-50, #edeef1);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    .chain-symbol {
      color: ${(props) => props.theme.colors.__deep_800};
      font-family: HarmonyOS Sans SC;
      font-size: 18px;
      font-weight: 700;
    }

    .arrow-icon {
      margin-left: 8px;
    }
  }

  .scan-tip {
    margin-top: 13px;

    color: ${(props) => props.theme.colors.__deep_400};
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }

  .qrcode {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .qrcode-text {
      margin-top: 8px;

      color: ${(props) => props.theme.colors.__deep_400};
      text-align: center;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }
  }

  .copy-address {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 0.9;
    }

    .copy-icon {
      width: 15px;
      height: 16px;
      flex-shrink: 0;
    }

    .copy-text {
      margin-left: 9px;

      color: ${(props) => props.theme.colors.__deep_800};
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const Receive: React.FC = () => {
  const { userStore, receiveStore } = useStores();
  const navigate = useNavigate();
  const helpRef = useRef<HTMLDivElement | null>(null);
  const [showTips, setShowTips] = useState<boolean>(true);
  const selectedChainSymbol: string = receiveStore.chain?.chainSymbol || "";

  const handleChooseNetworkClick = useCallback(() => {
    navigate(PREV + ROUTE_MAIN_RECEIVE_CHOOSE);
  }, [navigate]);

  const handleCopyClick = useCallback(() => {
    if (!receiveStore.toReceiveAaAddress) {
      toast({
        text: `Please wait for the address to load`,
        type: "warning",
      });
      return;
    }
    if (isKontosChain(receiveStore.chain?.chainIndex || "-1")) {
      copy(userStore.accountInfo?.name + ".os");
    } else {
      copy(receiveStore.toReceiveAaAddress);
    }

    toast({
      text: `Successfully copied address`,
      type: "success",
    });
  }, [
    receiveStore.chain?.chainIndex,
    receiveStore.toReceiveAaAddress,
    userStore.accountInfo?.name,
  ]);

  const handleGlobalMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (helpRef.current && !helpRef.current.contains(event.target as Node)) {
        setShowTips(false);
      }
    },
    []
  );

  return (
    <Container onClick={handleGlobalMouseDown}>
      <Header
        ref={helpRef}
        title={t("Receive")}
        rightBtnText={t("Help?") as string}
        rightBtnOnMouseEnter={() => setShowTips(true)}
        rightBtnOnMouseLeave={() => setShowTips(false)}
        rightBtnOnTouchStart={() => setShowTips(true)}
        enableBack={true}
      />
      <Body>
        <Panel>
          {/* Warning */}
          <div className="warning">
            <img className="warning-icon" src={warningIco} alt="" />
            <div className="warning-text">
              <Trans
                i18nKey={"trans-only-support-specific-assets-to-this-address"}
              >
                Only supports sending {{ selectedChainSymbol }} assets to this
                address. Other assets will be lost forever!
              </Trans>
            </div>
          </div>

          {/* Account Name */}
          <div className="account">
            <img className="account-avatar" src={avatarIcon} alt="" />
            <span className="account-name">
              {userStore.accountInfo?.name}.os
            </span>
          </div>

          {/* Chain Icon */}
          <Tips
            wrapperStyle={{ width: "100%" }}
            contentStyle={{
              left: "0px",
              top: "95px",
              padding: "0px 8px",
              width: "100%",
            }}
            showTips={showTips}
            showTitle={false}
            tipsContent={
              <div className="choose-tip">
                <Trans i18nKey={"trans-choose-chain-tip"}>
                  This address only supports
                  <span className="strong">
                    {{ selectedChainSymbol } as any}
                  </span>
                  assets. Sending others will result in permanent loss.
                </Trans>
              </div>
            }
            triangleLeft={"155px"}
            triggerElement={
              <TokenOrChainIcon
                className="chain-icon"
                type="chain"
                size={64}
                src={receiveStore.chain?.imageURL}
              />
            }
          />

          {/* Chain Symbol & clickable */}
          <div className="choose">
            {receiveStore.chain?.chainSymbol ? (
              <div onClick={handleChooseNetworkClick}>
                <span className="chain-symbol">
                  {receiveStore.chain?.chainSymbol}
                </span>
                <img className="arrow-icon" src={arrowIcon} alt="" />
              </div>
            ) : (
              <Skeleton count={1} style={{ width: "120px" }} />
            )}
          </div>

          {/* QR Code */}
          <div className="qrcode">
            {receiveStore.toReceiveAaAddress ? (
              <>
                <QRCode
                  value={receiveStore.toReceiveAaAddress}
                  size={150}
                  renderAs="canvas"
                  fgColor="#2B2B2B"
                />
                <div className="qrcode-text">
                  {isKontosChain(receiveStore.chain?.chainIndex || "-1")
                    ? userStore.accountInfo?.name + ".os"
                    : shortAddress(receiveStore.toReceiveAaAddress, 6, 6)}
                </div>
              </>
            ) : (
              <>
                <Skeleton style={{ width: "150px", height: "150px" }} />
                <Skeleton
                  count={1}
                  style={{ width: "120px", marginTop: "8px" }}
                />
              </>
            )}
          </div>

          {/* Divider */}
          <Divider top={10} bottom={16} left={14} right={14} />

          {/* Copy Address */}
          <div className="copy-address" onClick={handleCopyClick}>
            <img className="copy-icon" src={copyIcon} alt="" />
            <span className="copy-text">{t("Copy Address")}</span>
          </div>
        </Panel>
      </Body>
      <PoweredBy />
    </Container>
  );
};

export default observer(Receive);
