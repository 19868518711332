import React, { ReactNode } from "react";
import styled from "styled-components";

const RadioButton = styled.div<{ $isSelected: boolean }>`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 17px;

  width: 13px;
  height: 13px;
  border: 1px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.colors.__kontos_blue : theme.colors.__deep_200};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: "";
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.__kontos_blue};
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  }
`;

const Container = styled.div<{ $isSelected: boolean }>`
  width: 145px;
  height: 150px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 17px 17px;
  height: 150px;

  border-radius: 8px;
  border: ${(props) =>
    props.$isSelected
      ? `2px solid` + props.theme.colors.__kontos_blue
      : `1px solid` + props.theme.colors.__deep_50};
  background-color: ${(props) => props.theme.colors.__deep_25};

  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.__kontos_blue : theme.colors.__deep_400};
  text-align: center;

  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: ${(props) => (props.$isSelected ? "700" : "400")};
  line-height: 130%;

  &:not(:first-child) {
    margin-left: 19px;
  }

  @media (hover: hover) {
    &:hover {
      border: ${(props) =>
        props.$isSelected
          ? `2px solid` + props.theme.colors.__kontos_blue
          : `1px solid` + props.theme.colors.__kontos_blue};
      background-color: ${(props) => props.theme.colors.__white};

      ${RadioButton} {
        &:after {
          background-color: ${({ $isSelected, theme }) =>
            $isSelected ? theme.colors.__kontos_blue : theme.colors.__deep_200};
          opacity: 1;
        }
      }
    }
  }

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .text {
    margin-bottom: 15px;
  }
`;

interface RadioButtonProps {
  selected: boolean;
  text: ReactNode;
  onClick: () => void;
}

const RadioButtonV3: React.FC<RadioButtonProps> = ({
  selected,
  text,
  onClick,
}) => {
  return (
    <Container $isSelected={selected} onClick={onClick}>
      <div className="text">{text}</div>
      <RadioButton $isSelected={selected} />
    </Container>
  );
};

export default RadioButtonV3;
