import styled from "styled-components";
import {OtcType} from "../payment-methods/PaymentOTC";
import GreyRightArrowSvg from "src/assets/icons/trade/grey-right-arrow.svg";

const Container = styled.div`
  border-radius: 8px;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

const Item = styled.div`
  padding: 0 18px 0 20px;
  height: 76px;

  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid ${(props) => props.theme.colors.__deep_50};
  background: ${(props) => props.theme.colors.__deep_25};

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .left-img {
  }

  .right-img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${(props) => props.theme.colors.__kontos_blue};
    }
  }
`;

export interface OtcOpt {
  image: any;
  alt: string;
  value: OtcType;
}

interface Props {
  opts: OtcOpt[];
  onChoose: (opt: OtcOpt) => void;
  coinbaseUrl?: string;
}

const PaymentOtcList: React.FC<Props> = ({opts, onChoose, coinbaseUrl}) => {

  return (
    <Container>
      {opts.map((opt) => (
        opt.value !== OtcType.CoinBasePay ?
          <Item
            key={opt.value}
            onClick={() => {
              onChoose(opt);
            }}
          >
            <img className="left-img" src={opt.image} alt={opt.alt}/>
            <img className="right-img" src={GreyRightArrowSvg} alt=""/>
          </Item>
          :
          <a key={opt.value} href={coinbaseUrl} target={"_blank"}>
            <Item
              key={opt.value}
              onClick={() => {
                onChoose(opt);
              }}
            >
              <img className="left-img" src={opt.image} alt={opt.alt}/>
              <img className="right-img" src={GreyRightArrowSvg} alt=""/>
            </Item>
          </a>
      ))}
    </Container>
  );
};

export default PaymentOtcList;
