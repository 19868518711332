import { motion } from "framer-motion";
import styled from "styled-components";

const Container = styled.div<{ $isOn: boolean }>`
  width: 48px;
  height: 24px;
  background: ${(props) => (props.$isOn ? "#10CE5C" : "#B2B6BC")};
  padding: 2px;
  display: flex;
  justify-content: ${(props) => (props.$isOn ? "flex-end" : "flex-start")};
  border-radius: 50px;
  cursor: pointer;
`;

const Toggle = styled(motion.div)`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 99px;
  background-color: #fff;
`;

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

interface IProps {
  isOn: boolean;
  toggleSwitch: () => void;
}

export const ToggleButton: React.FC<IProps> = ({ isOn, toggleSwitch }) => {
  return (
    <Container $isOn={isOn} onClick={toggleSwitch}>
      <Toggle className="handle" layout transition={spring} />
    </Container>
  );
};
