import { DEFAULT_ASSET_ICON_OMIT, EXPLORER_KONTOS_URL } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import {
  chainassets,
  ftassetsV3 as callFtAssets,
  RespFtAssetsV3,
  ReqFtAssetsV3,
} from "@zkkontos/kontos-sdk/src/api/assetApi";
import {
  RespToken,
  quote,
  transferTaskData,
} from "@zkkontos/kontos-sdk/src/api/brokerApi";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import {
  CostDetail,
  Gas,
  Payment,
  TaskDetail,
} from "@zkkontos/kontos-sdk/src/codec/kontos/cc/v1/cc";
import { UniqueAssetDataWithCost } from "@zkkontos/kontos-sdk/src/codec/kontos/evmHelper/v1/evmHelper";
import { scarperChains } from "@zkkontos/kontos-sdk/src/api/scraperApi";
import AssetOmitSvg from "src/assets/icons/asset-omit.svg";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import Long from "long";
import { task, tasks } from "@zkkontos/kontos-sdk/src/api/taskApi";
import { AccountBalances, ChainConfig, FtAsset } from "src/type/zkkontos";
import {
  KontosClient,
  getDefaultClientPolymorphism,
} from "@zkkontos/kontos-sdk";
import { base64ToUint8Array, uint8ArrayToBase64 } from "./helper";
import { keccak256 } from "@ethersproject/keccak256";
import { enhancedFromHex } from "@zkkontos/kontos-sdk/src/core/utils";
import { BeSignData } from "src/type/common";
import { serialize, UnsignedTransaction } from "@ethersproject/transactions";
import { arrayify } from "ethers/lib/utils";
import { callAccountsNameRegex } from "src/apis/explorer-apis";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";

export const isBuy = true;

export const getFtAssetsToBuy = (
  recommendedOnes: FtAsset[],
  userHoldOnes: FtAsset[]
): FtAsset[] => {
  // If user holds only one asset, then we will recommend all other assets
  if (userHoldOnes.length === 1) {
    return recommendedOnes.filter((asset) => {
      return (
        asset.address !== userHoldOnes[0].address ||
        asset.chainIndex !== userHoldOnes[0].chainIndex
      );
    });
  }
  // Else, user can buy any recommeded assets and their already hold assets
  const assetMap = new Map<string, FtAsset>();
  recommendedOnes.forEach((asset) => {
    assetMap.set(asset.chainIndex + ":" + asset.address, asset);
  });
  userHoldOnes.forEach((asset) => {
    assetMap.set(asset.chainIndex + ":" + asset.address, asset);
  });
  return Array.from(assetMap.values());
};

export const getFtAssetsFromBalances = (
  _balances: AccountBalances
): FtAsset[] => {
  return (
    _balances?.ftAssetTypes?.reduce<FtAsset[]>(
      (arr, current) => arr.concat(current.ftAssets),
      []
    ) || []
  );
};

export const getFtAsset = (
  ftAssets: FtAsset[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): FtAsset | undefined => {
  return ftAssets.find(
    (asset) =>
      asset.chainIndex === chainIndex &&
      asset.address.toLowerCase() === assetAddress?.toLowerCase()
  );
};

export const getGreyChainImgUrl = (
  chainIndex: string | undefined,
  chains: ChainConfig[]
): string | undefined => {
  if (chainIndex === undefined) {
    return undefined;
  }
  const chain = chains.find(
    (chain) =>
      chain.chainIndex.toLocaleLowerCase() === chainIndex.toLocaleLowerCase()
  );
  return chain?.greyImageURL;
};

export const calcFtAssetBalanceInUSD = (
  price: KontosNumber,
  amount: KontosNumber
) => {
  return price.multiply(amount);
};

export const getQuote = (ftAsset: FtAsset, usdAmount: string) => {
  return quote(
    {
      chainIndex: ftAsset.chainIndex,
      assetAddress: ftAsset.address,
      usdAmount: usdAmount,
    },
    EXPLORER_KONTOS_URL
  );
};

export const getTransferTaskData = (
  account: string,
  payer: string,
  receiver: string,
  chainIndex: string,
  assetAddress: string,
  assetAmount: string
) => {
  return transferTaskData(
    {
      account,
      payer,
      receiver,
      chainIndex,
      assetAddress,
      assetAmount,
    },
    EXPLORER_KONTOS_URL
  );
};

export enum BuyErrorType {
  AmountEmpty,
  QuoteFailed,
  TaskDataFailed,
  NoAccount,
  NoAsset,
  OtherNoNeedNotify,
}

export const getBuyErrorText = (errorType: BuyErrorType) => {
  switch (errorType) {
    case BuyErrorType.NoAsset:
      return "Please choose an asset";
    case BuyErrorType.AmountEmpty:
      return "Please input amount";
    case BuyErrorType.QuoteFailed:
      return "Quote failed";
    case BuyErrorType.TaskDataFailed:
      return "Generate payment failed, please try again later or choose another asset to buy";
    case BuyErrorType.NoAccount:
      return "No account info, please refresh the page and try again";
    default:
      return "Unknown error";
  }
};

const transferGas = (apiG: any): Gas | undefined => {
  if (!apiG) {
    return undefined;
  }
  return {
    gasCost: apiG.gas_cost,
    gasPrice: apiG.gas_price,
    chainIndex: apiG.chain_index,
  };
};

const tranferCostDetail = (apiCD: any): CostDetail => {
  return {
    costType: apiCD.cost_type,
    desc: apiCD.desc,
    asset: apiCD.asset,
    gas: transferGas(apiCD.gas),
    required_USDAmount: apiCD.required_USD_amount,
  };
};

export const transferTaskDetail = (apiTD: any): TaskDetail => {
  return {
    costDetails: apiTD.cost_details.map(tranferCostDetail),
    totalRequiredUSDAmount: apiTD.total_required_uSD_amount,
    totalCallGasCost: apiTD.total_call_gas_cost,
    totalCallGasCostRequired_USDAmount:
      apiTD.total_call_gas_cost_required_USD_amount,
  };
};

const transferUniqueAssetDataWithCost = (
  apiU: any
): UniqueAssetDataWithCost => {
  return {
    chainIndex: apiU.chain_index,
    assetAddress: apiU.asset_address,
    assetAmount: apiU.asset_amount,
    usdAmount: apiU.usd_amount,
  };
};

export const transferPayment = (apiP: any): Payment => {
  return {
    paymentAsset: transferUniqueAssetDataWithCost(apiP.payment_asset),
    payer: apiP.payer,
    status: apiP.status,
  };
};

export const getIcon = (
  ftAssets: FtAsset[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): string | undefined => {
  if (!chainIndex || !assetAddress) {
    return undefined;
  }

  const matchingAsset = ftAssets.find(
    (ftAsset) =>
      ftAsset.chainIndex === chainIndex &&
      ftAsset.address.toLowerCase() === assetAddress.toLowerCase()
  );

  return matchingAsset ? matchingAsset.imageUrl : DefaultTokenSvg;
};

export const getIconListWithOmit = (
  payments: UniformedPayment[],
  omitLmit: number
) => {
  const iconList = payments.map((payment) => {
    return payment.assetImageUrl;
  });
  const res = iconList.slice(0, omitLmit);
  if (iconList.length > DEFAULT_ASSET_ICON_OMIT) {
    res.push(AssetOmitSvg);
  }
  return res;
};

export const getChainIcon = (
  chains: ChainConfig[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): string | any => {
  if (!chainIndex || !assetAddress) {
    return DefaultChainSvg;
  }
  const greyChainImg = getGreyChainImgUrl(chainIndex, chains);
  return greyChainImg ? greyChainImg : DefaultChainSvg;
};

export const getTokenName = (
  ftAssets: FtAsset[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): string | undefined => {
  if (!chainIndex || !assetAddress) {
    return undefined;
  }

  const matchingAsset = ftAssets.find(
    (ftAsset) =>
      ftAsset.chainIndex === chainIndex &&
      ftAsset.address.toLowerCase() === assetAddress.toLowerCase()
  );

  return matchingAsset ? matchingAsset.symbol : undefined;
};

export const getChainName = (
  ftAssets: FtAsset[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): string | undefined => {
  if (!chainIndex || !assetAddress) {
    return undefined;
  }
  const matchingAsset = ftAssets.find(
    (ftAsset) =>
      ftAsset.chainIndex === chainIndex &&
      ftAsset.address.toLowerCase() === assetAddress.toLowerCase()
  );

  return matchingAsset ? matchingAsset.chainSymbol : undefined;
};

export const getAllIndex = (arr: any[]): number[] => {
  const lengthArray = [];
  for (let i = 0; i < arr.length; i++) {
    lengthArray.push(i);
  }
  return lengthArray;
};

export const fetchAllScraperChains = () => {
  return scarperChains({}, EXPLORER_KONTOS_URL);
};

export const getFtAssetsByChain = (chainIndex: string) => {
  return chainassets({ chainIndex: chainIndex }, EXPLORER_KONTOS_URL);
};

export interface ReqFtAssets extends ReqFtAssetsV3 {}

export const fetchFtAssets = async (param: ReqFtAssets) => {
  if (
    param.chainIndex &&
    Object.values(NonChainIndex).some((item) => item === param.chainIndex)
  ) {
    param.chainIndex = "";
  }
  const { ftAssets, total } = await callFtAssets(param, EXPLORER_KONTOS_URL);
  return {
    ftAssets: ftAssets || [],
    total: total || 0,
  } as RespFtAssetsV3;
};

export const getChainByChainIndex = (
  chains: ChainConfig[],
  chainIndex: string
): ChainConfig | undefined => {
  const res = chains.find((chain) => chain.chainIndex === chainIndex);
  return res;
};

export const filterNoKontosFtAssets = (ftAssets: FtAsset[]) => {
  return ftAssets?.filter((ftAsset) => ftAsset?.chainIndex !== "0");
};

export const filterSupportedChainsFtAssets = (
  ftAssets: FtAsset[],
  supportedChains: ChainConfig[]
) => {
  return ftAssets?.filter(
    (ftAsset) =>
      supportedChains?.find(
        (chain) =>
          chain.chainIndex.toLocaleLowerCase() ===
            ftAsset?.chainIndex.toLocaleLowerCase() && chain.status === 1
      )
  );
};

export const getAccountsNameRegex = (character: string) => {
  return callAccountsNameRegex({ character: character }, EXPLORER_KONTOS_URL);
};

export const getAccountsNameRegexAsNameArray = async (character: string) => {
  const accountsNameRegex = await getAccountsNameRegex(character);
  return accountsNameRegex.accounts.map((account) => account.name);
};

export const convertNanosecondsToMilliseconds = (nanoseconds: Long): Long => {
  const temp = new KontosNumber(nanoseconds.toString());
  const res = temp.divide(new KontosNumber(1000000)).integerValue().toString();
  return Long.fromString(res);
};

export const filterEventNewTaskOpHash = (resp: any): string => {
  let opHash = "";
  resp?.events?.forEach((event: any) => {
    if (event.type === "kontos.cc.v1.EventNewTask") {
      const taskRegistered = event.attributes.find(
        (attr: any) => attr.key === "op_hash"
      );
      if (taskRegistered) {
        opHash = taskRegistered?.value?.replaceAll('"', "");
        return;
      }
      return;
    }
  });
  return opHash;
};

export const transRespTokenToFtAsset = (
  resp: RespToken,
  chain?: ChainConfig
): FtAsset => {
  return {
    balanceId: -1,
    chainIndex: resp.chainIndex,
    chainSymbol: resp?.chainSymbol || chain?.chainSymbol || "",
    chainImageUrl: resp?.chainImageUrl || chain?.imageURL || "",
    chainGreyImageUrl: chain?.greyImageURL || resp?.chainImageUrl || "",
    address: resp.assetAddress,
    name: resp.assetName,
    symbol: resp.assetSymbol,
    decimals: resp.assetDecimals,
    imageUrl: resp?.assetImageUrl || "",
    usdPrice: resp.usdPrice,
    assetType: -1,
    balance: "",
    totalUsd: "",
    chainExplorerLinkURL: "",
    chainStatus: chain?.status || 1,
    reserveIdUsd: "",
    isUsed: true,
    isGreatLiquidity: 0,
    h24Volume: "",
    ftAssetId: -1,
    marketCapUsd: "",
    marketCapRank: -1,
    fdvUsd: "",
    h24VolumeUsd: "",
    h24High: "",
    h24Low: "",
    h24PriceChange: "",
    h24PriceChangePercentage: "",
    h24MarketCapChange: "",
    h24MarketCapChangePercentage: "",
    circulatingSupply: "",
    totalSupply: "",
    maxSupply: "",
    ath: "",
    athChangePercentage: "",
    athDate: "",
    atl: "",
    atlChangePercentage: "",
    atlDate: "",
    coinGeckoCoinId: "",
    mappingOriginAssetId: -1,
    categories: [],
    isWhitelist: false,
    securityLevel: -1,
  };
};

export const getAccountTasks = async (
  account: string,
  chainIndex: string,
  offset: number,
  limit: number
) => {
  return tasks({
    account: account,
    chainIndex: chainIndex,
    offset: offset,
    limit: limit,
  });
};

export const parseApiErrorMessage = (
  error: Error
): { message: string; errCode: number } => {
  const errorMessageRegex = /API error: (.+) \(code (\d+)\)/;
  const match = errorMessageRegex.exec(error.message);

  if (match && match.length === 3) {
    const message = match[1];
    const errCode = parseInt(match[2], 10);
    return { message, errCode };
  }

  return {
    message: error.message,
    errCode: -1,
  };
};

export const classifyTaskStatus = (
  status: number
): "success" | "warning" | "error" => {
  switch (status) {
    case 0:
      return "warning";
    case 1:
      return "error";
    case 2:
      return "warning";
    case 3:
      return "error";
    case 4:
      return "error";
    case 5:
      return "success";
    case 6:
      return "error";
    case 7:
      return "error";
    case 8:
      return "error";
    case 9:
      return "error";
    case 10:
      return "warning";
    default:
      return "error";
  }
};

export const filterUniqueFtAssets = (
  existingFtAssets: FtAsset[],
  newFtAssets: FtAsset[]
): FtAsset[] => {
  if (newFtAssets?.length === 0) {
    return [];
  }
  const uniqueFtAssets = new Map();
  existingFtAssets?.forEach((ftAsset) => {
    const key = `${ftAsset.address}-${ftAsset.chainIndex}`;
    uniqueFtAssets.set(key, ftAsset);
  });

  newFtAssets?.forEach((ftAsset) => {
    const key = `${ftAsset.address}-${ftAsset.chainIndex}`;
    if (!uniqueFtAssets.has(key)) {
      uniqueFtAssets.set(key, ftAsset);
    }
  });

  return Array.from(uniqueFtAssets.values());
};

export const callTask = async (_opHash: string) => {
  return await task({ opHash: _opHash });
};

export const isSameKontosName = (nameA: string, nameB: any) => {
  return (
    nameA.toLowerCase().replaceAll(".os", "").trim() ===
    nameB.toLowerCase().replaceAll(".os", "").trim()
  );
};

export const isSameAddress = (addressA: any, addressB: any) => {
  return addressA?.toLowerCase() === addressB?.toLowerCase();
};

export const isSameFtAsset = (assetA: any, assetB: any) => {
  return (
    assetA?.chainIndex === assetB?.chainIndex &&
    assetA?.address?.toLowerCase() === assetB?.address?.toLowerCase()
  );
};

export const isSameName = (nameA: string, nameB: string) => {
  return (
    nameA.toLowerCase().replaceAll(".os", "") ===
    nameB.toLowerCase().replaceAll(".os", "")
  );
};

export const stringArrayEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

export const isTimestampInPast = (targetTimestamp: number): boolean => {
  const currentTimestamp = Date.now();

  if (targetTimestamp.toString().length === 10) {
    targetTimestamp *= 1000;
  }

  return targetTimestamp < currentTimestamp;
};

export const getTimeDifference = (
  targetTimestamp: number,
  translateFn: Function
): string => {
  const currentTimestamp = Date.now();

  if (targetTimestamp.toString().length === 10) {
    targetTimestamp *= 1000;
  }

  if (targetTimestamp <= currentTimestamp) {
    return "";
  }

  const differenceInMilliseconds = targetTimestamp - currentTimestamp;

  const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );

  if (hours > 0) {
    if (hours > 1) {
      return `${hours}${translateFn(" hours", { count: hours })}`;
    }
    return `${hours}${translateFn(" hour", { count: hours })}`;
  } else if (minutes > 0) {
    if (minutes > 1) {
      return `${minutes}${translateFn(" minutes", { count: minutes })}`;
    }
    return `${minutes}${translateFn(" minute", { count: minutes })}`;
  } else {
    return "";
  }
};

export const deduplicateArrayByKey = <
  T extends Record<K, any>,
  K extends keyof T,
>(
  array: T[],
  key: K
): T[] => {
  const uniqueItems = new Map<T[K], T>();
  array.forEach((item) => {
    if (!uniqueItems.has(item[key])) {
      uniqueItems.set(item[key], item);
    }
  });
  return Array.from(uniqueItems.values());
};

export const prioritizeItems = <T extends { name: string }>(
  items: T[],
  nameToPrioritize: string
): T[] => {
  const priorityItems = items.filter((item) => item.name === nameToPrioritize);
  const otherItems = items.filter((item) => item.name !== nameToPrioritize);
  return [...priorityItems, ...otherItems];
};

export const getNonce = async (kontosAddress: string, cli: KontosClient) => {
  const { sequence } = await cli.signingCli.getSequence(kontosAddress);
  const nonce = sequence + 1;
  return nonce;
};

export const signMessage = async (
  accountName: string,
  message: string,
  cli: KontosClient
) => {
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = cli.key.privateKey as CryptoKey;
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(message)
  );
  return sig;
};

export const signTypedData = async (
  accountName: string,
  typedData: Object,
  cli: KontosClient
) => {
  const signDataStr = btoa(JSON.stringify(typedData));
  const signDataBytes = base64ToUint8Array(signDataStr);
  const signDataHash = keccak256(signDataBytes);
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = cli.key.privateKey as CryptoKey;
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(signDataHash)
  );
  return uint8ArrayToBase64(sig);
};

export const signTx = async (
  accountName: string,
  tx: any,
  cli: KontosClient
) => {
  const txHash = keccak256(serialize(tx as UnsignedTransaction));
  const txBytes = arrayify(txHash);
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = cli.key.privateKey as CryptoKey;
  const signedTx = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    txBytes
  );
  const u = new Uint8Array(65);
  u.set(new Uint8Array(signedTx));
  u.set(new Uint8Array([1]), 64);
  return serialize(tx as UnsignedTransaction, u);
};

export const getSignature = async (
  accountName: string,
  signData: Object,
  cli: KontosClient
) => {
  const signDataStr = btoa(JSON.stringify(signData));
  const signDataBytes = base64ToUint8Array(signDataStr);
  const signDataHash = keccak256(signDataBytes);
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = cli.key.privateKey as CryptoKey;
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(signDataHash)
  );
  return uint8ArrayToBase64(sig);
};

export const signForBe = async (
  accountName: string,
  kontosAddress: string,
  cli: KontosClient,
  data: string,
  expiration: number
) => {
  const nonce = await getNonce(kontosAddress, cli);
  const expiredAt = Date.now() + expiration;
  const signData: BeSignData = {
    data: data,
    expiredAt: expiredAt,
    nonce: nonce,
  };
  console.log("signData", signData);
  const signature = await getSignature(accountName, signData, cli);
  return { signature, expiredAt };
};
