import { t } from "i18next";
import Header from "src/components/common/header";
import styled from "styled-components";
import ChainSelect from "src/components/chain-select/ChainSelect";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ChainConfig } from "@zkkontos/kontos-sdk/src/api/types";
import { PREV, ROUTE_MAIN_RECEIVE } from "src/router/router-config";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ReceiveChoose: React.FC = () => {
  const { receiveStore, chainStore } = useStores();
  const navigate = useNavigate();

  const handleChooseChain = useCallback(
    (chain: ChainConfig) => {
      receiveStore.updateChain(chain);
      navigate(PREV + ROUTE_MAIN_RECEIVE);
    },
    [navigate, receiveStore]
  );

  return (
    <Container>
      <Header title={t("Choose Network")} enableBack={true} />
      <ChainSelect chains={chainStore.chains} onChoose={handleChooseChain} />
    </Container>
  );
};

export default ReceiveChoose;
