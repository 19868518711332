import { Dapp } from "@/type/zkkontos";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { loadingStore } from "src/store/loadingStore";
import { DiscoverTrendingCard } from "./DiscoverTrendingCard";
import { EndMessage } from "src/components/no-data/EndMessage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 0 16px;

  .dapp-fav-list-infinite-scroll {
    overflow-x: hidden;
  }
`;

const InfiniteLoading = styled.img`
  width: 80px;
  text-align: center;
`;

const isFavorite = (dapp: Dapp, favorites: Dapp[]) => {
  return favorites.some((item) => item.id === dapp.id);
};

interface IProps {
  className?: string;
  dapps: Dapp[];
  hasMore: boolean;
  hasRank?: boolean;
  onNext: () => void;
}

export const DiscoverTrendingList: React.FC<IProps> = observer(
  ({ className, dapps, hasMore, hasRank = true, onNext }) => {
    const { favStore, userStore } = useStores();
    const dappFavorites = favStore.dappFavorites;

    const favoriteOrUnfavoriteDapp = useCallback(
      async (dapp: Dapp) => {
        const cli = userStore.kontosCli;
        if (!cli) {
          userStore.unlock(() => favoriteOrUnfavoriteDapp(dapp));
          return;
        }
        const isFav = isFavorite(dapp, dappFavorites);
        try {
          loadingStore.showLoading();
          isFav
            ? await favStore.removeFavorites({
                dappId: dapp.id,
                cli,
              })
            : await favStore.addFavorites({
                dappId: dapp.id,
                cli,
              });
        } catch (e) {
          console.log(e);
        } finally {
          loadingStore.hideLoading();
        }
      },
      [dappFavorites, favStore, userStore]
    );

    return (
      <InfiniteScroll
        className="dapp-fav-list-infinite-scroll"
        dataLength={dapps.length} // The current length of displayed data
        next={onNext} // The function to trigger to load more
        hasMore={hasMore} // Whether there are more items to load
        loader={
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <InfiniteLoading src="/static/loading.gif" alt="Loading..." />
          </div>
        }
        endMessage={<EndMessage />}
        scrollableTarget="DappFavScrollableDiv"
      >
        <Container className={className}>
          {dapps.map((dapp, index) => (
            <DiscoverTrendingCard
              key={dapp.id}
              dapp={dapp}
              isFavorite={isFavorite(dapp, dappFavorites)}
              rank={hasRank ? index + 1 : undefined}
              onRightClick={() => favoriteOrUnfavoriteDapp(dapp)}
            />
          ))}
        </Container>
      </InfiniteScroll>
    );
  }
);
