import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BASIC_SHEET_INDEX } from "src/config";
import kontosGoogleAvatar from "src/assets/images/kontos-avatar-google.svg";
import { Instruction } from "src/components/instructions/Instruction";
import KontosInputV2 from "src/components/input/KontosInputV2";
import { useCallback, useEffect, useRef, useState } from "react";
import RegisterStatusIcon, { inputStatusMap } from "../RegisterStatusIcon";
import { KontosButton } from "src/components/button/KontosButton";
import toast from "src/components/toast/Toast";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: var(--White, #fff);
  max-width: 375px;
  border-radius: 16px;
`;

const AvatarIcon = styled.img`
  width: 64px;
  height: 64px;
`;

const BottomArea = styled.div`
  height: 34px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const FinishButton = styled(KontosButton)`
  margin: 0 4px;
  width: calc(100% - 8px);
  padding: 7px;
  color: var(--White, #fff);
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 16px;
  line-height: 20px;
`;

const InputErrorText = styled.div`
  margin: -8px 0;
  width: calc(100% - 8px);
  color: var(--error-notice, #ff1e2b);
  text-align: left;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  padding-left: 6px;
`;

interface Props {
  existedEmails: string[];
  onAddEmail: (email: string) => void;
  onClose: () => void;
}

export const DirectEmailInputPopup: React.FC<Props> = ({
  existedEmails,
  onAddEmail,
  onClose,
}) => {
  const { t } = useTranslation();
  const [inputStatus, setInputStatus] =
    useState<keyof typeof inputStatusMap>("blur");
  const [inputErrorDesc, setInputErrorDesc] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const inputRef = useRef<{ focus: () => void }>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: onClose,
    shouldClose: inputStatus === "error" || inputStatus === "loading",
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleAdd = useCallback(() => {
    if (email === "") {
      onClose();
      return;
    }
    if (existedEmails.includes(email)) {
      toast({
        type: "warning",
        text: t("The email already exists"),
      });
      onClose();
      return;
    }
    onAddEmail(email);
    onClose();
  }, [email, existedEmails, onAddEmail, onClose, t]);

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.2}>
      <Container ref={wrapperRef}>
        <AvatarIcon src={kontosGoogleAvatar} />

        <KontosInputV2
          leftIcon={<RegisterStatusIcon status={inputStatus} type="email" />}
          placeholder={t("Please enter Gmail")}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value?.toLowerCase());
          }}
          setInputStatus={setInputStatus}
          setInputErrorDesc={setInputErrorDesc}
          inputStatus={inputStatus}
          isCheckExist={true}
          checkType="email"
          rightDistance={4}
          inputStyle={{ paddingRight: "5px" }}
          wrapperStyle={{ marginBottom: "0" }}
          ref={inputRef}
          onSubmit={() => {
            if (inputStatus !== "error" && inputStatus !== "loading") {
              handleAdd();
            }
          }}
        />
        {inputStatus === "error" && inputErrorDesc !== "" && (
          <InputErrorText>{inputErrorDesc}</InputErrorText>
        )}

        <Instruction
          type={"warning"}
          title={t("Currently, only Gmail is accepted!")}
          contents={[
            t(
              "If you choose to enter a Gmail address directly without verification, we cannot ensure that your email meets the requirements, which may lead to unknown errors preventing you from receiving emails from Kontos. An incorrect email address could potentially cause your account to be permanently lost."
            ),
          ]}
        />

        <BottomArea>
          <FinishButton
            disabled={inputStatus === "error" || inputStatus === "loading"}
            onClick={handleAdd}
          >
            {t("Done")}
          </FinishButton>
        </BottomArea>
      </Container>
    </Overlay>
  );
};
