import styled from "styled-components";
import PaymentChargeItem from "../payment-items/PaymentChargeItem";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FtAsset } from "src/type/zkkontos";
import { isSameFtAsset } from "src/utils/zkkontosHelper";
import {
  chainIdMap,
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
  TRADE_DEFAULT_OTC_FTASSET,
  TRADE_DEFAULT_OTC_FTASSET_2,
} from "src/config";
import { RespTaskDataV3 } from "@zkkontos/kontos-sdk/src/api";
import debounce from "lodash/debounce";
import KontosNumber from "src/utils/KontosNumber";
import Divider from "src/components/divider/Divider";
import AvatarSvg from "src/assets/icons/trade/trade-avatar.svg";
import PaymentKeyValueList, {
  AvatarIcon,
  CommonText,
  SuccessText,
  WarningText,
} from "../payment-items/PaymentKeyValueList";
import PendingSvg from "src/assets/images/pending.svg";
import SuccessSvg from "src/assets/images/success.svg";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import "react-loading-skeleton/dist/skeleton.css";
import PaymentOtcList, { OtcOpt } from "../payment-items/PaymentOtcList";
import CoinbaseSvg from "src/assets/images/trade/coinbase.svg";
import CoinBasePay from "../otc/CoinBasePay";
import { loadingStore } from "src/store/loadingStore";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";
import UnLimitPay from "../otc/UnLimitPay";
import { callAaaccounts } from "src/apis/explorer-apis";
import { generateOnRampURL } from "@coinbase/cbpay-js";
import { PaymentMode } from "src/store/trade/PaymentStore";
import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "src/pages/trade/asset-select/ToBuyAssetSelector";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";
import { fetchSpecificFtAsset } from "src/service/trade-service";

const Container = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  flex: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-ms-scrollbar {
    width: 0;
  }
`;

const MainContainer = styled.div`
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  flex: 1;
  overflow-y: auto;
`;

const KeyValueListContainer = styled.div``;

export const OTCMemberContainer = styled.div`
  margin-top: 32px;

  flex: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export enum OtcType {
  AlchemyPay,
  CoinBasePay,
  UnLimitPay,
}

export const OTC_OPTS: OtcOpt[] = [
  // {
  //   image: OtcAlchemySvg,
  //   alt: "Alchemy Pay",
  //   value: OtcType.AlchemyPay,
  // },
  {
    image: CoinbaseSvg,
    alt: "Coinbase",
    value: OtcType.CoinBasePay,
  },
  // {
  //   image: UnlimitSvg,
  //   alt: "Unlimit",
  //   value: OtcType.UnLimitPay,
  // },
];

interface Props {
  taskData: RespTaskDataV3;
  choosing: boolean;
  backClickCount: number;
  onChoosing: (isOpen: boolean) => void;
  onSuccess: () => void;
}

type IPaymentFrame = {
  source: "Trade" | "Home";
  selectOtcType: OtcType | undefined;
  accountName: string;
  toReceiveAmountForAlchemy: string;
  toReceiveAmountText: string | undefined;
  handleOtcSuccess: () => void;
  receiveAAAddress: string;
  handleCancelCoinbasePay?: () => void;
};

export const RenderPaymentFrame = ({
  source,
  selectOtcType,
  accountName,
  toReceiveAmountForAlchemy,
  toReceiveAmountText,
  handleOtcSuccess,
  receiveAAAddress,
  handleCancelCoinbasePay,
}: IPaymentFrame) => {
  return (
    <>
      {/* {selectOtcType === OtcType.AlchemyPay && (
        <AlchemyPay
          source={source}
          address={accountName}
          quantity={toReceiveAmountForAlchemy}
          quantityWithSymbol={toReceiveAmountText || toReceiveAmountForAlchemy}
          onSuccess={handleOtcSuccess}
        />
      )} */}

      {selectOtcType === OtcType.CoinBasePay && (
        <CoinBasePay
          address={receiveAAAddress}
          quantity={toReceiveAmountForAlchemy}
          quantityWithSymbol={toReceiveAmountText || toReceiveAmountForAlchemy}
          onSuccess={handleOtcSuccess}
          onCanceled={() => {
            handleCancelCoinbasePay?.();
          }}
        />
      )}

      {selectOtcType === OtcType.UnLimitPay && (
        <UnLimitPay
          address={receiveAAAddress}
          quantity={toReceiveAmountForAlchemy}
          quantityWithSymbol={toReceiveAmountText || toReceiveAmountForAlchemy}
          onSuccess={handleOtcSuccess}
        />
      )}
    </>
  );
};

const PaymentOTC: React.FC<Props> = ({
  taskData,
  choosing,
  backClickCount,
  onChoosing,
  onSuccess,
}) => {
  const { userStore, paymentStore, chainStore } = useStores();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [toReceiveFtAsset, setToReceiveFtAsset] = useState<FtAsset | undefined>(
    paymentStore.paymentOtcFtAsset
  );
  const toReceiveAmount: KontosNumber = new KontosNumber(
    taskData.minRequiredAssetAmountForChargeAsset,
    DEFAULT_DECIMAL
  );
  const toReceiveAmountForAlchemy: string = toReceiveAmount.gt(2000)
    ? "2000"
    : toReceiveAmount.lt(15)
      ? "15"
      : toReceiveAmount.toFixed(DEFAULT_DISPLAY_PRECESION);
  const toReceiveAmountText: string | undefined = toReceiveAmount.gt(0)
    ? toReceiveAmount.toFixed(DEFAULT_DISPLAY_PRECESION) +
      " " +
      toReceiveFtAsset?.symbol
    : undefined;
  const [taskDataLoading, setTaskDataLoading] = useState<boolean>(true);
  const [assetLoading, setAssetLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<"success" | "pending">("pending");
  const [showOTC, setShowOTC] = useState<boolean>(false);
  const [selectOtcType, setSelectOtcType] = useState<OtcType | undefined>(
    undefined
  );
  const [receiveAAAddress, setReceiveAAAddress] = useState<string>("");
  // generateOnRampURL parameters by iframe
  const [coinbaseUrl, setCoinbaseUrl] = useState<string>("");
  useEffect(() => {
    if (!receiveAAAddress) return;
    const destinationWallets = [
      {
        address: receiveAAAddress,
        blockchains: ["polygon"],
        assets: ["USDC"],
      },
    ];
    const opts = {
      appId: process.env.REACT_APP_COINBASE_ID,
      destinationWallets,
    };
    const onRampURL = generateOnRampURL(opts);
    setCoinbaseUrl(onRampURL);
  }, [receiveAAAddress]);

  const executeTaskData = useCallback(async () => {
    const debouncedFunction = debounce(async () => {
      try {
        setTaskDataLoading(true);
        await paymentStore.wrappedFetchAndSetTaskData?.({
          chainIndex: toReceiveFtAsset?.chainIndex!,
          assetAddress: toReceiveFtAsset?.address!,
        });
      } catch (e) {
        console.warn(e);
      } finally {
        setTaskDataLoading(false);
      }
    }, 0);
    debouncedFunction();
  }, [paymentStore, toReceiveFtAsset?.address, toReceiveFtAsset?.chainIndex]);

  const paymentKeyValueListCtx = useMemo(() => {
    if (taskDataLoading) {
      return {
        receiver: (
          <>
            <AvatarIcon src={AvatarSvg} />
            <CommonText>{userStore.accountNameWithOs}</CommonText>
          </>
        ),
        totalPayment: <EllipsisPlaceholder />,
        myAvailable: <EllipsisPlaceholder />,
        status: (
          <>
            <AvatarIcon src={PendingSvg} />
            <WarningText>{t("Waiting for OTC buy...")}</WarningText>
          </>
        ),
      };
    }
    return {
      receiver: (
        <>
          <AvatarIcon src={AvatarSvg} />
          <CommonText>{userStore.accountNameWithOs}</CommonText>
        </>
      ),
      totalPayment: (
        <CommonText>
          {new KontosNumber(
            taskData.maxRequiredUsdCost,
            DEFAULT_DECIMAL
          ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
        </CommonText>
      ),
      myAvailable: (
        <CommonText>
          {new KontosNumber(
            taskData.maxAvailableUsdForPayment,
            DEFAULT_DECIMAL
          ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
        </CommonText>
      ),
      status: (
        <>
          <AvatarIcon src={status === "pending" ? PendingSvg : SuccessSvg} />
          {status === "pending" ? (
            <WarningText>{t("Waiting for OTC buy...")}</WarningText>
          ) : (
            <SuccessText>{t("Purchase successful")}</SuccessText>
          )}
        </>
      ),
    };
  }, [
    status,
    taskData.maxAvailableUsdForPayment,
    taskData.maxRequiredUsdCost,
    taskDataLoading,
    userStore.accountNameWithOs,
  ]);

  const handleOtcChoose = (opt: OtcOpt) => {
    if (!toReceiveAmountText || !receiveAAAddress) {
      toast({
        text: t("Please wait for calculation of your charge"),
        type: "warning",
      });
      return;
    } else {
      // toast({
      //   text: `You need to charge at least ${toReceiveAmountText}`,
      //   type: "info",
      // });
      onChoosing(true);
      setShowOTC(true);
      setSelectOtcType(opt.value);
    }
  };

  const handleOtcSuccess = async () => {
    setShowOTC(false);

    const fetchData = async () => {
      const resp = await paymentStore.wrappedFetchAndSetTaskData?.();
      return resp;
    };

    try {
      loadingStore.showLoading();
      await fetchData();
    } catch (e) {
      try {
        await fetchData();
      } catch (e) {
        console.warn(e);
        const newTimer = setTimeout(() => {
          executeTaskData();
        }, 30000);
        setTimer(newTimer);
      }
    } finally {
      loadingStore.hideLoading();
    }
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    setShowOTC(false);
  }, [backClickCount]);

  useEffect(() => {
    if (taskData?.newTasksByPaymentPlans?.length > 0) {
      setStatus("success");
      setTimeout(() => {
        paymentStore.setPaymentMode(PaymentMode.PaymentPlan);
        onSuccess();
      }, 2000);
    } else {
      setStatus("pending");
    }
  }, [onSuccess, paymentStore, taskData?.newTasksByPaymentPlans?.length]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      try {
        setAssetLoading(true);
        let toSearchToken = TRADE_DEFAULT_OTC_FTASSET;
        if (
          isSameFtAsset(
            paymentStore.paymentTargetFtAsset,
            TRADE_DEFAULT_OTC_FTASSET
          )
        ) {
          toSearchToken = TRADE_DEFAULT_OTC_FTASSET_2;
        }
        const respToken = await fetchSpecificFtAsset(
          toSearchToken.chainIndex,
          toSearchToken.address
        );
        paymentStore.setPaymentReceiveFtAsset(respToken);
        setToReceiveFtAsset(respToken);
      } catch (e) {
        console.warn(e);
      } finally {
        setAssetLoading(false);
      }
    };

    const fetchAAData = async () => {
      const resp = await callAaaccounts(userStore.accountName!);
      // The current receiving address is fixed to the Polygon AA address.
      setReceiveAAAddress(resp.aaAccounts[chainIdMap.POLYGON]);
    };

    if (
      !paymentStore.paymentOtcFtAsset ||
      isSameFtAsset(
        paymentStore.paymentOtcFtAsset,
        paymentStore.paymentTargetFtAsset
      )
    ) {
      fetchAndSetData();
    } else {
      setAssetLoading(false);
    }
    fetchAAData();
  }, [paymentStore, userStore.accountName]);

  useEffect(() => {
    if (toReceiveFtAsset) {
      executeTaskData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toReceiveFtAsset]);

  return (
    <Container>
      {!showOTC && !choosing && (
        <MainContainer>
          <PaymentChargeItem
            currentFtAsset={toReceiveFtAsset}
            text={t("You need to OTC buy") as string}
            amountText={toReceiveAmountText}
            isFixed={true}
            assetLoading={assetLoading}
            taskDataLoading={taskDataLoading}
            onClick={() => {
              onChoosing(true);
            }}
          />

          <Divider isDashed={true} top={16} bottom={16} />

          <KeyValueListContainer>
            <PaymentKeyValueList
              receiver={paymentKeyValueListCtx?.receiver}
              totalPayment={paymentKeyValueListCtx?.totalPayment}
              myAvailable={paymentKeyValueListCtx?.myAvailable}
              status={paymentKeyValueListCtx?.status}
            />
          </KeyValueListContainer>

          <OTCMemberContainer>
            <PaymentOtcList
              coinbaseUrl={coinbaseUrl}
              opts={OTC_OPTS}
              onChoose={handleOtcChoose}
            />
          </OTCMemberContainer>
        </MainContainer>
      )}

      {!showOTC && choosing && (
        <ToBuyAssetSelector
          onChoose={(item) => {
            onChoosing(false);
            setToReceiveFtAsset(item);
          }}
          chains={chainStore.chains}
          hasRecommend={true}
          hasAll={true}
          hasFavorites={true}
          showAssetType={ShowAssetType.Detail}
          initAssetType={NonChainIndex.Recommend}
          isWhitelist
        />
      )}

      {showOTC && choosing && toReceiveAmount.gt(0) && (
        <RenderPaymentFrame
          source="Trade"
          selectOtcType={selectOtcType}
          accountName={userStore.accountNameWithOs!}
          toReceiveAmountForAlchemy={toReceiveAmountForAlchemy}
          toReceiveAmountText={toReceiveAmountText}
          handleOtcSuccess={handleOtcSuccess}
          receiveAAAddress={receiveAAAddress}
        />
      )}
    </Container>
  );
};

export default PaymentOTC;
