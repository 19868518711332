import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import PaymentOtcList, {
  OtcOpt,
} from "src/pages/contract-interaction/payment-items/PaymentOtcList";
import {
  OTCMemberContainer,
  OTC_OPTS,
  RenderPaymentFrame,
  OtcType,
} from "src/pages/contract-interaction/payment-methods/PaymentOTC";
import toast from "src/components/toast/Toast";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import { useStores } from "src/hooks/useStore";
import { loadingStore } from "src/store/loadingStore";
import { callAaaccounts } from "src/apis/explorer-apis";
import { chainIdMap } from "src/config";
import { generateOnRampURL } from "@coinbase/cbpay-js";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
`;

type IProps = {
  handleBack: () => void;
};

export const OTCModal: React.FC<IProps> = observer(({ handleBack }) => {
  const { userStore } = useStores();
  const [showOTC, setShowOTC] = useState<boolean>(false);
  const [selectOtcType, setSelectOtcType] = useState<OtcType | undefined>(
    undefined
  );
  const [receiveAAAddress, setReceiveAAAddress] = useState<string>("");
  const [coinbaseUrl, setCoinbaseUrl] = useState<string>("");

  useEffect(() => {
    if (!receiveAAAddress) return;
    const destinationWallets = [
      {
        address: receiveAAAddress,
        blockchains: ["polygon"],
        assets: ["USDC"],
      },
    ];
    const opts = {
      appId: process.env.REACT_APP_COINBASE_ID,
      destinationWallets,
    };
    const onRampURL = generateOnRampURL(opts);
    setCoinbaseUrl(onRampURL);
  }, [receiveAAAddress]);

  useEffect(() => {
    if (!userStore?.accountInfo?.name) return;
    fetchAAData(userStore?.accountInfo?.name);
  }, [userStore?.accountInfo?.name]);
  const fetchAAData = async (name: string) => {
    const resp = await callAaaccounts(name);
    // The current receiving address is fixed to the Polygon AA address.
    setReceiveAAAddress(resp.aaAccounts[chainIdMap.POLYGON]);
  };
  const handleOtcChoose = (opt: OtcOpt) => {
    if (!receiveAAAddress) {
      toast({
        text: t("Please wait for calculation of your charge"),
        type: "warning",
      });
      return;
    } else {
      setShowOTC(true);
      setSelectOtcType(opt.value);
    }
  };

  const handleOtcSuccess = useCallback(async () => {
    setShowOTC(false);
    loadingStore.hideLoading();
  }, []);

  return (
    <Wrapper>
      <SheetUpHeader
        handleBack={() => {
          handleBack && handleBack();
        }}
        headStyle={{ justifyContent: "center" }}
        title={t("OTC")}
        padding="8px"
      />
      <div style={{ height: "20px" }} />
      {!showOTC && (
        <PaymentOtcList
          coinbaseUrl={coinbaseUrl}
          opts={OTC_OPTS}
          onChoose={handleOtcChoose}
        />
      )}

      {showOTC && userStore?.accountInfo?.name && (
        <RenderPaymentFrame
          source="Home"
          selectOtcType={selectOtcType}
          accountName={userStore?.accountInfo?.name + ".os"}
          toReceiveAmountForAlchemy={""}
          toReceiveAmountText={""}
          handleOtcSuccess={handleOtcSuccess}
          receiveAAAddress={receiveAAAddress}
          handleCancelCoinbasePay={() => {
            handleBack();
          }}
        />
      )}
    </Wrapper>
  );
});
