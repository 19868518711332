import { theme } from "src/App";
import styled from "styled-components";

const StyledSvg = styled.svg`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      circle,
      path {
        stroke: ${(props) => props.theme.colors.__error};
      }
    }
  }
`;

interface IProps {
  className?: string;
  onClick?: () => void;
}

const RemoveIcon: React.FC<IProps> = ({ className, onClick }) => {
  return (
    <StyledSvg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="8" stroke={theme.colors.__deep_400} />
      <path
        d="M6.23486 10H13.7648"
        stroke={theme.colors.__deep_400}
        strokeLinecap="round"
      />
    </StyledSvg>
  );
};

export default RemoveIcon;
