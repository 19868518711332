import { useTranslation } from "react-i18next";
import styled from "styled-components";
import warnIcon from "src/assets/icons/warn1.svg";

const Container = styled.div`
  padding: 8px 20px;
  width: 100%;
  border-radius: 8px;
  background: rgba(250, 173, 20, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const WarnIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const Text = styled.span`
  color: var(--Warning, #faad14);
  font-family: "HarmonyOS Sans SC";
  font-size: 11px;
  line-height: 140%;
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export const TaskFailWarn: React.FC<IProps> = ({ className, style }) => {
  const { t } = useTranslation();

  return (
    <Container className={className} style={style}>
      <WarnIcon src={warnIcon} />
      <Text>{t("This task may fail.")}</Text>
    </Container>
  );
};
