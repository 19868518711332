import React from 'react';
import styled from 'styled-components';

interface StrikethroughTextProps {
  text: string;
}

const StrikethroughContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  line-height: 1;
  text-align: center;
`;

const Text = styled.span`
  background: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 1;
  color: #80868F;
  font-size: 14px;
  font-family: HarmonyOS Sans SC;
  font-weight: 400;
`;

const Line = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #EDEEF1;
  transform: translateY(-50%);
`;

const StrikethroughText: React.FC<StrikethroughTextProps> = ({ text }) => {
  return (
    <StrikethroughContainer>
      <Line />
      <Text>{text}</Text>
    </StrikethroughContainer>
  );
};

export default StrikethroughText;
