import styled from "styled-components";
import tipsIcon from "src/assets/icons/tips.svg";
import { useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  user-select: none;
  max-width: 320px;
  padding: 8px;
  border-radius: 8px;
  background: var(--Deep-800, #1a2535);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const TipsIcon = styled.img`
  width: 21px;
  height: 12px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const LightText = styled.span`
  color: var(--White, #fff);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

export const ManageAssetTip: React.FC = () => {
  const { tradeStore } = useStores();
  const { t } = useTranslation();

  return (
    <Container onClick={() => tradeStore.setShowManageTip(false)}>
      <TipsIcon src={tipsIcon} />
      <Content>
        <LightText>{t("You can adjust your payment strategy here")}</LightText>
      </Content>
    </Container>
  );
};
