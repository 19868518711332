import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { EXPLORER_KONTOS_URL } from "src/config";
import { API } from "./core/API";
import * as dappTypes from "./types/DappTypes";
import { FtAssetWatched } from "src/type/ftAsset";

export interface ReqDapps {
  category: number; // Default: -1
  chainIndex: string;
  regex: string;
  offset: number;
  limit: number;
}

export interface RespDapps {
  dapps: dappTypes.Dapp[];
  total: number;
}

export const dapps = async (
  data: ReqDapps,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespDapps> => {
  return request<ReqDapps, RespDapps>(API.dapp.dapps, data, endpoint);
};

export interface ReqWatchlist {
  account: string;
}

export interface RespWatchlist {
  dapps: { watchId: number; dapp: dappTypes.Dapp }[];
  ftAssets: FtAssetWatched[];
}

export const watchList = async (
  data: ReqWatchlist,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespWatchlist> => {
  return request<ReqWatchlist, RespWatchlist>(
    API.dapp.watchlist,
    data,
    endpoint
  );
};

export enum WatchAction {
  Collect = 0,
  UnCollect = 1,
}
/**
 * Interface for the request payload to watch or unwatch DApps or FT assets.
 *
 * @param account - The user's account address.
 * @param action - Operation type: 0 for collect, 1 for uncollect.
 * @param dappName - The name of the DApp, obtainable from /api/v1/dapp/dapps.
 * @param chainIndex - Index of the chain, details available from /api/v1/dapp/dapps and /api/v1/asset/ftassets.
 * @param ftAssetAddress - Address of the FT asset, sourced from /api/v1/asset/ftassets.
 * @param expiredAt - Timestamp when the request expires.
 * @param signature - A string generated by signing the SignData structure. This proves ownership and request authenticity.
 *
 * The signature is generated from the SignData structure, which includes:
 * - Data: The account address.
 * - ExpiredAt: The expiration timestamp of the request.
 * - Nonce: A unique number for each transaction, similar to the one used in the Claim interface.
 */
export interface ReqWatchDappOrFtAsset {
  account: string;
  action: WatchAction;
  dappId: number;
  ftAssetId: number;
  expiredAt: number;
  signature: string;
}

export interface RespWatchDapp {}

/**
 * Watches or unwatches a DApp or FT asset based on the provided data.
 *
 * This function sends a request to the API to either watch (collect) or unwatch (uncollect)
 * a DApp or fungible token (FT) asset. The operation is determined by the 'action' parameter
 * in the data payload. A signature must be provided in the payload to authenticate the request.
 *
 * @param data - The data payload for the request, conforming to ReqWatchDappOrFtAsset interface.
 * @param endpoint - The API endpoint URL. Defaults to EXPLORER_KONTOS_URL.
 * @returns A promise that resolves to the response of the watch or unwatch operation.
 */
export const watchDappOrFtAsset = async (
  data: ReqWatchDappOrFtAsset,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespWatchDapp> => {
  return request<ReqWatchDappOrFtAsset, RespWatchDapp>(
    API.dapp.watchdapporftasset,
    data,
    endpoint
  );
};

export interface ReqSearchDapp {
  offset: number;
  limit: number;
  character: string;
}

export interface RespSearchDapp {
  dapps: dappTypes.Dapp[];
  total: number;
}

export const searchDapp = async (
  data: ReqSearchDapp,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespSearchDapp> => {
  return request<ReqSearchDapp, RespSearchDapp>(
    API.dapp.searchdapp,
    data,
    endpoint
  );
};

export enum SortAction {
  Dapp = 0,
  FtAsset = 1,
}

export interface ReqSort {
  account: string;
  ids: number[];
  action: SortAction;
  expiredAt: number;
  signature: string;
}

export interface RespSort {
  account: string;
  ids: number[];
}

export const sort = async (
  data: ReqSort,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespSort> => {
  return request<ReqSort, RespSort>(API.dapp.sort, data, endpoint);
};
