import styled from "styled-components";
import successIcon from "src/assets/icons/task/success.svg";
import errorIcon from "src/assets/icons/task/error.svg";
import warningIcon from "src/assets/icons/receive/warning.svg";

const Container = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: rgba(250, 173, 20, 0.05);
`;

const TypeIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: var(--Warning, #faad14);
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
`;

const Content = styled.span`
  color: var(--Warning, #faad14);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const getTypeIcon = (type: "error" | "success" | "warning") => {
  switch (type) {
    case "error":
      return errorIcon;
    case "success":
      return successIcon;
    case "warning":
      return warningIcon;
    default:
      return errorIcon;
  }
};

interface IProps {
  type: "success" | "warning" | "error";
  title: string;
  contents: string[];
  className?: string;
  style?: React.CSSProperties;
}

export const Instruction: React.FC<IProps> = ({
  type,
  title,
  contents,
  className,
  style,
}) => {
  return (
    <Container className={className} style={style}>
      <TypeIcon src={getTypeIcon(type)} />
      <RightBox>
        <Title>{title}</Title>
        <br />
        <Content>{contents}</Content>
      </RightBox>
    </Container>
  );
};
