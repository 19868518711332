import Header from "src/components/common/header";
import {t} from "i18next";
import styled from "styled-components";
import {useStores} from "src/hooks/useStore";
import HeaderRightComponent from "src/components/start/HeaderRightComponent";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import React, {useCallback, useMemo, useEffect} from "react";
import {useNavigate} from "react-router";
import totalReferralBg from "src/assets/images/referral/totalReferralBg.png";
import avatarIco from "src/assets/icons/settings/avatar.svg";
import copy from "copy-to-clipboard";
import toast from "src/components/toast/Toast";
import {getReferralInfo, IInvite, RespReferralInfo} from "src/apis/explorer-apis";
import {getDisplayAmount} from "src/utils/helper";
import KontosNumber from "src/utils/KontosNumber";
import {DEFAULT_DECIMAL} from "src/config";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  padding: 0 16px 16px 16px;
`;

const Line = styled.div`
  width: 1px;
  height: 12px;
  background: #CCCFD2;
`

const ReferralItemWrapper = styled.div`
  margin-top: 12px;

  .itemInfo {
    background: var(--White, #FFF);
    border-radius: 8px;
    border: 1px solid var(--Deep-50, #EDEEF1);
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    position: relative;
    z-index: 2;

    .itemInfoL {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 22px;
        height: 22px;
        margin-right: 7px;
      }

      .itemInfoLName {
        color: var(--Deep-800, #1A2535);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        max-width: 150px;
      }
    }

    .itemInfoR {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .line {
        margin: 0 11px;
      }

      .earnings, .increase {
        color: var(--Kontos-Blue, #413DEC);
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .moreEarnings {
    color: var(--Deep-400, #80868F);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--Deep-50, #EDEEF1);
    border-radius: 8px;
    padding: 27px 16px 9px;
    margin-top: -18px;

    span.increase {
      color: var(--Kontos-Blue, #413DEC);
    }
  }

`
const TotalReferralWrapper = styled.div`
  background: url(${totalReferralBg}) no-repeat center center;
  background-size: 100% 100%;
  padding: 20px 10px 13px;
  margin-bottom: 12px;

  .referralTitle {
    color: var(--Deep-400, #80868F);
    text-align: center;

    /* Smaller */
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .totalReferralNumber {
    color: var(--White, #FFF);
    font-family: "HarmonyOS Sans Bold";
    font-size: 28px;
    margin-bottom: 16px;
    text-align: center;
  }

  .totalEarningsNumber {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: var(--Success, #10CE5C);
    text-align: center;

    font-family: "HarmonyOS Sans Bold";
    font-size: 28px;

    span.symbol {
      color: var(--Success, #10CE5C);
      font-size: 18px;
      margin-left: 7px;
      transform: translateY(-4px);
    }
  }
`

const ReferralLinkWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Deep-50, #EDEEF1);
  background: var(--White, #FFF);
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  .link {
    overflow: hidden;
    color: var(--Deep-800, #1A2535);
    text-overflow: ellipsis;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    flex: 1;
    margin-right: 11px;
  }
`

const BtnCopyWrapper = styled.div`
  width: 145px;
  height: 29px;
  line-height: 29px;
  border-radius: 99px;
  background: var(--Kontos-Blue, #413DEC);
  color: var(--White, #FFF);
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
  flex-shrink: 0;
  cursor: pointer;
`

const BtnCopy = ({
                   cpText
                 }: {
  cpText: string;
}) => {
  const _copy = useCallback(
    (copyText: string) => {
      copy(copyText);
      toast({
        text: "Copied to clipboard!",
        type: "success",
      });
    },
    []
  );
  return <BtnCopyWrapper onClick={() => _copy(cpText)}>
    Copy Referral Link
  </BtnCopyWrapper>
}

const getPercentage = (itemAmount: string, totalAmount: string) => {
  if (!itemAmount || !totalAmount || totalAmount === "0") {
    return "0";
  }
  const itemAmountNum = new KontosNumber(itemAmount, DEFAULT_DECIMAL);
  const totalAmountNum = new KontosNumber(totalAmount, DEFAULT_DECIMAL);
  const percentage = itemAmountNum.divide(totalAmountNum).multiply(100);
  return getDisplayAmount(percentage.toString(), {isAutomateProcessAllTypes: true, decimal: 0});
}

type IReferralItemProps = {
  inviteInfo: IInvite;
  percentage: string;
}

const ReferralItem = ({
                        inviteInfo,
                        percentage
                      }: IReferralItemProps) => {
  return (
    <ReferralItemWrapper>
      <div className={"itemInfo"}>
        <div className={"itemInfoL l"}>
          <img src={avatarIco} alt=""/>
          <span className={"itemInfoLName"}>
                {inviteInfo.invitee_name}.os
              </span>
        </div>
        <div className={"itemInfoR r"}>
          <span
            className={"earnings"}>+{getDisplayAmount(inviteInfo.asset_usd_amount, {isAutomateProcessAllTypes: true})} USD</span>
          <Line className={"line"}/>
          <span className={"increase"}>{percentage}%</span>
        </div>
      </div>
      <div className={"moreEarnings"}>
        More earnings
        <span
          className={'increase'}>+{getDisplayAmount(inviteInfo.indect_asset_usd_amount, {isAutomateProcessAllTypes: true})} USD</span>
      </div>
    </ReferralItemWrapper>
  )
}


const getReferralLink = ({
                           referralCode
                         }: {
  referralCode: string;
}) => {
  return `${window.location.origin}/createAccount?referralCode=${referralCode}`
}

const AccountReferral: React.FC = () => {
  const {userStore} = useStores();
  const navigate = useNavigate();
  const accountInfo = userStore.accountInfo;
  const referralLink = useMemo(() => {
    if (!accountInfo?.name) {
      console.error("account name is empty")
      return "";
    }
    return getReferralLink({
      referralCode: accountInfo?.name
    });
  }, [accountInfo?.name])
  const [earningAmount, setEarningAmount] = React.useState("");
  const [invite, setInvite] = React.useState<IInvite[]>([]);

  useEffect(() => {
    if (!accountInfo?.name) {
      console.error("account name is empty")
      return;
    }
    //  todo wyf
    getReferralInfo({name: accountInfo?.name}).then((res: RespReferralInfo) => {
    // getReferralInfo({name: "sher"}).then((res: RespReferralInfo) => {
      setEarningAmount(res.earning_amount);
      setInvite(res.invitee);
    }).catch((err) => {
      console.error(err);
    })
  }, [accountInfo?.name])

  return (
    <Wrapper>
      <SheetUpHeader
        handleBack={() => {
          navigate(-1);
        }}
        headStyle={{
          justifyContent: "center",
          marginBottom: "10px"
      }}
        title={t("Referral center")}
        padding="8px"
        rightComponent={
          <HeaderRightComponent type={"referral"} kontosName={accountInfo?.name || ""} />
        }
      />
      <TotalReferralWrapper>
        <div className={"referralTitle"}>
          {t("Total referrals")}
        </div>
        <div className={"totalReferralNumber"}>
          {invite.length}
        </div>
        <div className={"referralTitle"}>
          {t("Your Total Earnings")}
        </div>
        <div className={"totalEarningsNumber"}>
          {earningAmount && "+"}
          {getDisplayAmount(earningAmount, {isAutomateProcessAllTypes: true})} <span className={"symbol"}>USD</span>
        </div>
      </TotalReferralWrapper>
      <ReferralLinkWrapper>
        <div className={"link elips"}>{referralLink}</div>
        <BtnCopy cpText={referralLink}/>
      </ReferralLinkWrapper>
      <div className={"list"}>
        {
          invite.map((item, index) => {
            return <ReferralItem
              key={index}
              inviteInfo={item}
              percentage={getPercentage(item.asset_usd_amount, earningAmount)}
            />
          })
        }
      </div>
    </Wrapper>
  );
};

export default AccountReferral;
