import { observer } from "mobx-react";
import styled from "styled-components";
import { t } from "i18next";
import React from "react";
import kontosAvatar from "src/assets/images/kontos-avatar1.svg";
import googleSvg from "src/assets/icons/google.svg";
import arrow10 from "src/assets/icons/arrow10.svg";
import arrow11 from "src/assets/icons/arrow11.svg";
import PoweredBy from "src/components/common/powerdBy";
import { statusRouterMap } from "src/pages/newAuth/CreateAccount";
import { GoogleLoginBtn } from "src/components/button/GoogleLoginBtn";
import tgManager from "src/store/managers/tgManager";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 86px;
      height: 86px;
      flex-shrink: 0;
    }
  }

  .buttonWrapper {
    max-width: 400px;
    margin: 0 auto 0;
    overflow-x: hidden;
  }

  .button {
    color: var(--White, #fff);
    box-sizing: border-box;
    cursor: pointer;

    /* H5 */
    font-family: HarmonyOS Sans SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    background: var(--Kontos-Blue, #413dec);
    height: 56px;
    padding: 13px 30px 13px 46px;
    flex-shrink: 0;
    position: relative;

    .img0 {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      margin-right: 12px;
    }

    .img1 {
      width: 14px;
      height: 16px;
      flex-shrink: 0;
      position: absolute;
      right: 30px;
      top: 18px;
    }

    .img1PlaceHolder {
      width: 14px;
      height: 16px;
    }
  }

  .buttonGoogle {
    position: relative;
    max-width: 400px;
    margin: 60px auto 0;
    cursor: pointer;
  }

  .buttonRegister {
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    max-width: 400px;
    margin: 0 auto;

    /* H5 */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 13px 30px 13px 46px;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 120px;
    border: 1px solid var(--Kontos-Blue, #413dec);
    color: var(--Kontos-Blue, #413dec);
    text-align: center;

    /* H5 */
    font-family: HarmonyOS Sans SC;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    .img1 {
      width: 14px;
      height: 16px;
      flex-shrink: 0;
      position: absolute;
      right: 30px;
      top: 18px;
    }
  }

  .lineOr {
    position: relative;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // max-width: 400px;
    margin: 23px auto 16px;

    .line {
      background: var(--Deep-50, #edeef1);
      position: absolute;
      left: 0;
      top: 14px;
      width: 100%;
      height: 1px;
      z-index: 1;
    }

    span {
      width: 30px;
      height: 30px;
      line-height: 30px;
      flex-shrink: 0;
      background: var(--White, #fff);
      position: relative;
      z-index: 2;
      text-align: center;
    }
  }
`;

type iProps = {
  setRouteStatus: (status: string) => void;
  loginSuccess: (param0: string) => void;
};

const CreateSection: React.FC<iProps> = ({ setRouteStatus, loginSuccess }) => {
  return (
    <Wrapper>
      <div className={"main"}>
        <div className={"avatar"}>
          <img src={kontosAvatar} alt="" />
        </div>

        {!tgManager.isTg() && (
          <div className={"buttonWrapper"}>
            <GoogleLoginBtn
              loginSuccessFun={(email) => {
                loginSuccess(email);
              }}
            >
              <div className={"button buttonGoogle"}>
                <img className={"img0"} src={googleSvg} alt="" />
                {t("Continue with Google")}
                <div className={"img1PlaceHolder"} />
                <img className={"img1"} src={arrow10} alt="" />
              </div>
            </GoogleLoginBtn>
          </div>
        )}

        <div className={"lineOr"}>
          <div className={"line"} />
          <span>OR</span>
        </div>
        <div
          style={
            {
              // marginTop: isGoogleLoginAvailable ? 0 : '100px'
            }
          }
          className={"buttonRegister"}
          onClick={() => {
            setRouteStatus(statusRouterMap.addNormal);
          }}
        >
          {t("Register Now")}

          <img className={"img1"} src={arrow11} alt="" />
        </div>
      </div>
      <div className={"buttonWrap"}>
        <PoweredBy />
      </div>
    </Wrapper>
  );
};

export default observer(CreateSection);
