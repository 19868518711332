import Header from "src/components/common/header";
import { t } from "i18next";
import styled, { ThemeContext } from "styled-components";
import AccountPanel from "./AccountPanel";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import Sheet from "react-modal-sheet";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import SettingsRouter from "./SettingsRouter";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ROUTE_MAIN,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_PAYMENT,
} from "src/router/router-config";
import { observer } from "mobx-react";
import generalIcon from "src/assets/icons/settings/general.svg";
import SettingsItem from "src/components/settings/SettingsItem";
import { useStores } from "src/hooks/useStore";
import paymentIcon from "src/assets/icons/settings/payment.svg";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  padding: 0 16px 76px 16px;
`;

const Scrollable = styled.div`
  margin-top: 8px;

  flex: 1;
  overflow: auto;
`;

const Settings: React.FC = () => {
  const { uiSettingStore } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const theme = useContext(ThemeContext);
  const [showAccountSettingsModal, setShowAccountSettingsModal] =
    useState<boolean>(false);
  const itemList = [
    {
      title: t("General") as string,
      icon: generalIcon,
      onClick: () => {
        navigate(ROUTE_SETTINGS_GENERAL);
      },
    },
    // {
    //   title: t("Manage Payment") as string,
    //   icon: paymentIcon,
    //   onClick: () => {
    //     navigate(ROUTE_SETTINGS_PAYMENT);
    //   },
    // },
  ];

  useEffect(() => {
    if (!showAccountSettingsModal) {
      navigate(ROUTE_SETTINGS);
    }
  }, [navigate, showAccountSettingsModal]);

  useEffect(() => {
    if (location.pathname === ROUTE_SETTINGS) {
      setShowAccountSettingsModal(false);
    }
  }, [location.pathname]);

  const closeModals = useCallback(() => {
    setShowAccountSettingsModal(false);
  }, []);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: uiSettingStore.canClose,
  });

  return (
    <>
      <Header
        callBack={() => {
          navigate(ROUTE_MAIN);
        }}
        enableBack={true}
        title={t("Settings")}
      />
      <Container ref={wrapperRef}>
        <AccountPanel
          leftText={t("Account setting")}
          leftColor={theme.colors.__kontos_blue}
          onClick={() => {
            setShowAccountSettingsModal(true);
            navigate(ROUTE_SETTINGS_ACCOUNT);
          }}
        />
        <Scrollable>
          {itemList.map((item) => (
            <SettingsItem
              key={item.title}
              mainIcon={item.icon}
              title={item.title}
              onClick={item.onClick}
            />
          ))}
        </Scrollable>

        <NoScrollerBarSheet
          isOpen={showAccountSettingsModal}
          onClose={() => setShowAccountSettingsModal(false)}
          mountPoint={domNode}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <SettingsRouter />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setShowAccountSettingsModal(false)} />
        </NoScrollerBarSheet>
      </Container>
    </>
  );
};

export default observer(Settings);
