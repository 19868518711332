import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import { KontosButton } from "src/components/button/KontosButton";
import {
  RespAccountGuardians,
  ThresholdAction,
} from "@zkkontos/kontos-sdk/src/api/types";
import { RespAccountThresholdActions } from "@zkkontos/kontos-sdk/src/api";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  accountGuardians as actionAccountGuardians,
  accountThresholdActions,
} from "@zkkontos/kontos-sdk/src/api/aaApi";
import InputWithIcons from "src/components/input/KontosInputV2";
import { ReactComponent as SecurityGoogle } from "src/assets/icons/security-google.svg";
import RecoverInputStatusText from "src/components/start/RecoverInputStatusText";
import { isSameKontosName } from "src/utils/zkkontosHelper";
import guardianInfoIco from "src/assets/icons/guardianInfo.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { SmartAccount } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/aa";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 0 16px 16px 16px;
`;

const GuardianDescription = styled.div`
  color: ${(props) => props.theme.colors.__deep_800};
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-align: center;

  padding: 14px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > .icon {
    margin-bottom: 7px;
    width: 29px;
    height: 17px;
  }

  > div .keyword {
    color: var(--Kontos-Blue, #413dec);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
  }
`;

const Scrollable = styled.div`
  padding: 0 14px;
  flex: 1;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 89%;
    margin: 0 auto;
    margin-bottom: 16px;
  }
`;

interface Props {
  name: string;
  onSuccess: () => void;
  onCancel: () => void;
}

const isActionApprovedAndPubKeyMatched = (
  guardianAddress: string,
  localPubkey: string | undefined,
  threshHoldActions: RespAccountThresholdActions | undefined
) => {
  if (!threshHoldActions || !localPubkey) return false;

  const matchedAction = Object.values(
    (
      (threshHoldActions?.thresholdAction as ThresholdAction[]) ||
      ([] as ThresholdAction[])
    )?.reduce(
      (acc, cur) => {
        const existing = acc[cur.sender];
        if (!existing || existing.nonce < cur.nonce) {
          acc[cur.sender] = cur;
        }
        return acc;
      },
      {} as { [key: string]: ThresholdAction }
    )
  )?.find(
    (item) =>
      item.newPubKey === localPubkey &&
      Object.keys(item.approvals).includes(guardianAddress)
  );

  return matchedAction;
};

const RecAccount: React.FC<Props> = ({ name, onSuccess, onCancel }) => {
  const { userStore } = useStores();
  const allStorageKeys = userStore.storageKeys;
  const kontosKey = allStorageKeys.find((key) =>
    isSameKontosName(key.accountName, name)
  );
  const [accountGuardians, setAccountGuardians] = useState<
    RespAccountGuardians | undefined
  >(undefined);
  const [threshHoldActions, setThreshHoldActions] = useState<
    RespAccountThresholdActions | undefined
  >(undefined);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [smartAccount, setSmartAccount] = useState<SmartAccount>();
  const [actionThreshold, setActionThreshold] = useState<number>();

  const canSubmit: boolean = useMemo(() => {
    let count = 0;
    if (accountGuardians?.guardians && actionThreshold) {
      accountGuardians.guardians.forEach((item) => {
        const res = isActionApprovedAndPubKeyMatched(
          item.guardianAddress,
          kontosKey?.pubKeyData?.compressedPubKey,
          threshHoldActions
        );
        if (!!res) {
          count++;
        }
      });
      return count >= actionThreshold;
    } else {
      return false;
    }
  }, [
    accountGuardians?.guardians,
    actionThreshold,
    kontosKey?.pubKeyData?.compressedPubKey,
    threshHoldActions,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cli = await userStore.getKontosQueryCli();
        const smartAccount = await cli.getSmartAccountByName(name);
        console.log(smartAccount.pubKey);
        setSmartAccount(smartAccount);
        setActionThreshold(smartAccount.actionThreshold);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [name, userStore]);

  useEffect(() => {
    const cleanup = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    actionAccountGuardians({ account: name })
      .then((rs) => {
        rs && setAccountGuardians(rs);
      })
      .catch((e) => {
        console.warn("get account guardians failed", e);
      });

    accountThresholdActions({ account: name })
      .then((rs) => {
        setThreshHoldActions(rs);
      })
      .catch((e) => {
        console.warn("get account threshold actions failed", e);
      });

    intervalRef.current = setInterval(() => {
      actionAccountGuardians({ account: name })
        .then((rs) => {
          rs && setAccountGuardians(rs);
        })
        .catch((e) => {
          console.warn("get account guardians failed", e);
        });

      accountThresholdActions({ account: name })
        .then((rs) => {
          setThreshHoldActions(rs);
        })
        .catch((e) => {
          console.warn("get account threshold actions failed", e);
        });
    }, 10000);

    return cleanup;
  }, [name]);

  return (
    <Container>
      <Header title={t("Recovery Progress")} padding="0 20px" />
      <GuardianDescription>
        <img className="icon" src={guardianInfoIco} alt="" />
        <Trans i18nKey={"trans-actionThreshold"}>
          <div>
            Recover your account requires approval from any{" "}
            <span className="keyword">{{ actionThreshold } as any}</span> of the
            following Guardians.
          </div>
        </Trans>
      </GuardianDescription>
      <Scrollable>
        {accountGuardians?.guardians ? (
          accountGuardians?.guardians?.map((item, index) => (
            <InputWithIcons
              key={index}
              inputStyle={{
                border: "none",
                background: "transparent",
              }}
              leftIcon={<SecurityGoogle />}
              rightIcon={
                <RecoverInputStatusText
                  status={
                    isActionApprovedAndPubKeyMatched(
                      item.guardianAddress,
                      kontosKey?.pubKeyData?.compressedPubKey,
                      threshHoldActions
                    )
                      ? "success"
                      : "normal"
                  }
                />
              }
              placeholder={t("")}
              value={
                accountGuardians?.accounts[item.guardianAddress]?.name || ""
              }
              disabled={false}
              isDelete={true}
            />
          ))
        ) : (
          <Skeleton
            style={{ height: "50px", marginBottom: "12px" }}
            count={smartAccount?.guardians?.length || 1}
          />
        )}
      </Scrollable>
      <ButtonWrapper>
        <KontosButton disabled={!canSubmit} onClick={onSuccess}>
          {t("Confirm Adding")}
        </KontosButton>
      </ButtonWrapper>
    </Container>
  );
};

export default RecAccount;
