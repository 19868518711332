import {
  RespAccountsBalances,
  accountsBalances,
} from "@zkkontos/kontos-sdk/src/api/assetApi";

export const getMultiAccountsBalances = async (
  addresses: string[]
): Promise<RespAccountsBalances> => {
  return await accountsBalances({ accounts: addresses });
};
