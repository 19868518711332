export const set = (key: string, value: any) => {
  const stringValue = typeof value === "string" ? value : JSON.stringify(value);
  sessionStorage.setItem(key, stringValue);
};

export const get = (key: string) => {
  const stringValue = sessionStorage.getItem(key);

  if (!stringValue) {
    return null;
  }

  try {
    return JSON.parse(stringValue);
  } catch (error) {
    return stringValue;
  }
};

export const remove = (key: string) => {
  sessionStorage.removeItem(key);
};
