import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { SheetView } from "src/store/SheetStore";
import { useCallback, useState } from "react";
import { ContractInteraction } from "src/pages/contract-interaction/ContractInteraction";
import { ContractInteractionType } from "src/pages/contract-interaction/types";
import { getChain } from "src/utils/storeUtils";
import { convertChainId, getDefaultTxHash } from "src/utils/helper";
import { SignClientTypes } from "@walletconnect/types";
import { SessionTypes } from "@walletconnect/types/dist/types/sign-client/session";
import { RespTaskDataV3 } from "@zkkontos/kontos-sdk/src/api";
import toast from "src/components/toast/Toast";
import { loadingStore } from "src/store/loadingStore";
import { useTranslation } from "react-i18next";
import { ChainConfig } from "src/type/zkkontos";

interface WrapperProps {
  onClose: () => void;
  requestEvent: SignClientTypes.EventArguments["session_request"];
  requestSession: SessionTypes.Struct;
  taskData: RespTaskDataV3;
  accountName: string;
  chain: ChainConfig;
}

const ContractInteractionWrapper: React.FC<WrapperProps> = observer(
  ({ onClose, requestEvent, requestSession, taskData, accountName, chain }) => {
    const { t } = useTranslation();
    const { userStore, dappConnectStore } = useStores();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const { topic, params } = requestEvent;
    const targetDapp = requestSession.peer.metadata.name;

    const onApprove = useCallback(async () => {
      const chainId = convertChainId(params.chainId || "1", "toDecimal");
      const txHash = getDefaultTxHash(chainId);

      try {
        const response = await dappConnectStore.approveEIP155Request(
          requestEvent!,
          userStore.kontosCli!,
          {
            txHash,
          }
        );
        try {
          await dappConnectStore?.client?.respondSessionRequest({
            topic,
            response,
          });
          toast({
            text: t(
              "Successfully sent transaction! Dapp may display errors because Kontos sends your transaction through a broker, which can be viewed in the task list"
            ),
            type: "success",
          });
        } catch (e) {
          console.log(e);
        } finally {
          dappConnectStore.markUsedRequest(requestEvent.id, true);
          onClose();
        }
      } catch (e) {
        console.log("Unknown error when signing", e);
      } finally {
        loadingStore.hideLoading();
      }
    }, [
      dappConnectStore,
      onClose,
      params.chainId,
      requestEvent,
      t,
      topic,
      userStore.kontosCli,
    ]);

    const onReject = useCallback(async () => {
      if (requestEvent) {
        loadingStore.showLoading();
        const response = dappConnectStore.rejectEIP155Request(requestEvent);
        try {
          await dappConnectStore?.client?.respondSessionRequest({
            topic,
            response,
          });
        } catch (e) {
          console.log(
            `Failed to respond to ${requestSession?.peer.metadata.name}`,
            e
          );
        } finally {
          dappConnectStore.markUsedRequest(requestEvent.id, false);
          loadingStore.hideLoading();
          onClose();
        }
      }
    }, [
      dappConnectStore,
      onClose,
      requestEvent,
      requestSession?.peer.metadata.name,
      topic,
    ]);

    return (
      <ContractInteraction
        interactionType={ContractInteractionType.Dapp}
        target={targetDapp}
        onCancel={onReject}
        onSuccess={onApprove}
        onInnerIndexChange={setSelectedIndex}
        selectedTaskDataIndex={selectedIndex}
        wallet={accountName}
        dappProps={{
          taskData,
          chain,
        }}
      />
    );
  }
);

interface Props {
  mountPoint?: Element;
}

export const ContractInteractionSheet: React.FC<Props> = observer(
  ({ mountPoint }) => {
    const { sheetStore, userStore } = useStores();
    const isOpen = sheetStore.sheetVisibility.get(
      SheetView.ContractInteraction
    ) as boolean;
    const sheet = sheetStore.getLatestSheetForView(
      SheetView.ContractInteraction
    );
    const requestEvent = sheet?.data?.requestEvent;
    const requestSession = sheet?.data?.requestSession;
    const chain = getChain(requestEvent?.params.chainId || "");
    const accountName = userStore.accountName;
    const taskData = sheet?.data?.taskData;
    const condition = !(
      !requestEvent ||
      !requestSession ||
      !accountName ||
      !taskData ||
      !chain
    );

    const onClose = useCallback(() => {
      sheet?.id && sheetStore.closeSheetById(sheet.id);
    }, [sheet?.id, sheetStore]);

    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={onClose}
        mountPoint={mountPoint}
        zExtraIndex={2}
      >
        {condition ? (
          <ContractInteractionWrapper
            onClose={onClose}
            requestEvent={requestEvent}
            requestSession={requestSession}
            taskData={taskData}
            accountName={accountName}
            chain={chain}
          />
        ) : (
          <></>
        )}
      </BottomSheet>
    );
  }
);
