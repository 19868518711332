import { FtAsset } from "@/type/zkkontos";
import styled from "styled-components";
import CircleIconPair from "../icons/CircleIconPair";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import { shortAddress } from "src/utils/helper";
import { AssetSecurityIcon } from "src/pages/trade/asset-select/AssetSecurityIcon";
import { useTranslation } from "react-i18next";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import Copy from "../copy/Copy";

const Container = styled.div<{ $hoverable?: boolean }>`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  background: var(--White, #fff);

  @media (hover: hover) {
    &:hover {
      background: ${(props) =>
        props.$hoverable ? `var(--Deep-25, #F5F5F6)` : `var(--White, #fff)`};
      cursor: ${(props) => (props.$hoverable ? "pointer" : "normal")};
    }
  }

  white-space: nowrap;
  user-select: none;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const LeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

const LeftInfoLineOne = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LeftInfoLineTwo = styled.div`
  display: flex;
  align-items: center;

  .copy {
    visibility: hidden;
  }
  @media (hover: hover) {
    &:hover {
      .copy {
        visibility: visible;
      }
    }
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

const RightLineOne = styled.div`
  display: flex;
  align-items: center;
`;

const RightLineTwo = styled.div`
  display: flex;
  align-items: center;
`;

const DarkText = styled.span`
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
`;

const LightText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

const AvailableLabel = styled.span`
  padding: 2px 6px;

  border-radius: 99px;
  background: var(--Deep-50, #edeef1);

  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 10px;
`;

interface IProps {
  asset: FtAsset;
  showAssetAddress?: boolean;
  canCopyAddress?: boolean;
  showAvailableLabel?: boolean;
  showSecurityIcon?: boolean;
  showBalance?: boolean;
  showUsd?: boolean;
  disableHoverToClick?: boolean;
  onClick?: (asset: FtAsset) => void;
}

export const GeneralFtAssetItem: React.FC<IProps> = ({
  asset,
  showAssetAddress,
  canCopyAddress,
  showAvailableLabel,
  showSecurityIcon,
  showBalance,
  showUsd,
  disableHoverToClick,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Container $hoverable={!!onClick} onClick={() => onClick?.(asset)}>
      <Left>
        <CircleIconPair
          mainIcon={asset.imageUrl || defaultTokenIcon}
          mainIconFallbackSrc={defaultTokenIcon}
          subIcon={asset.chainGreyImageUrl || defaultChainIcon}
          subIconFallbackSrc={defaultChainIcon}
          mainWidth={32}
          mainHeight={32}
          subWidth={16}
          subHeight={16}
          totalWidth={40}
          totalHeight={32}
        />

        <LeftInfo>
          <LeftInfoLineOne>
            {/* Symbol */}
            <DarkText>{asset.symbol}</DarkText>

            {/* Security Level */}
            {showSecurityIcon && (
              <AssetSecurityIcon
                isGreatLiquidity={asset.isGreatLiquidity}
                securityLevel={asset.securityLevel}
                disableHoverToClick={disableHoverToClick}
              />
            )}

            {/* Available Label */}
            {showAvailableLabel && asset.isWhitelist && (
              <AvailableLabel>{t("Available")}</AvailableLabel>
            )}
          </LeftInfoLineOne>

          <LeftInfoLineTwo>
            {/* Address */}
            {showAssetAddress && (
              <LightText>{shortAddress(asset.address)}</LightText>
            )}
            {canCopyAddress && <Copy className="copy" text={asset.address} />}
          </LeftInfoLineTwo>
        </LeftInfo>
      </Left>

      <Right>
        <RightLineOne>
          {/* Balance */}
          {showBalance && (
            <DarkText>
              {new KontosNumber(asset.balance, DEFAULT_DECIMAL).toFormat()}
            </DarkText>
          )}
        </RightLineOne>

        <RightLineTwo>
          {/* Equivalent to USD */}
          {showUsd && (
            <LightText>
              ${new KontosNumber(asset.totalUsd, DEFAULT_DECIMAL).toFormat()}
            </LightText>
          )}
        </RightLineTwo>
      </Right>
    </Container>
  );
};
