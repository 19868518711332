import styled from "styled-components";
import LeftBlueArrowSvg from "src/assets/icons/contract/left-blue-arrow.svg";
import { t } from "i18next";
import { memo, useEffect, useMemo, useState } from "react";
import { callTask, classifyTaskStatus } from "src/utils/zkkontosHelper";
import { TaskFull, TaskPreview } from "src/type/zkkontos";
import successIcon from "src/assets/icons/task/success.svg";
import errorIcon from "src/assets/icons/task/error.svg";
import warningIcon from "src/assets/icons/task/warning.svg";
import { DEFAULT_DECIMAL, EXPLORER_URL } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import {
  beautifyISOTimestamp,
  formatAddress,
  getDisplayAmount,
  openUrl,
  shortAddress,
} from "src/utils/helper";
import Long from "long";
import Divider from "src/components/divider/Divider";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import Skeleton from "react-loading-skeleton";
import FloatingButton from "src/pages/trade/FloatingButton";
import OpenUrlIcon from "src/assets/icons/task/open-url.svg";
import arrowIco from "src/assets/icons/arrow16.svg";
import { ethers } from "ethers";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .success {
    color: ${({ theme }) => theme.colors.__success};
  }
  .error {
    color: ${({ theme }) => theme.colors.__error};
  }
  .warning {
    color: ${({ theme }) => theme.colors.__warning};
  }
  .strong {
    color: ${({ theme }) => theme.colors.__deep_800};
  }
  .kontos {
    color: ${({ theme }) => theme.colors.__kontos_blue};
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 23px;
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: HarmonyOS Sans Bold;
  font-size: 24px;

  .back-icon {
    width: 22px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .spacer {
    width: 22px;
  }
`;

const StatusBox = styled.div`
  margin-top: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .status-box-icon {
    width: 45px;
    height: 45px;
  }

  .status-box-text {
    font-family: HarmonyOS Sans Bold;
    font-size: 14px;
  }

  .status-box-amount {
    font-family: HarmonyOS Sans Bold;
    font-size: 18px;
  }
`;

const Scrollable = styled.div`
  padding: 0 32px 48px 32px;
  flex: 1;
  overflow: auto;
`;

const ItemLine = styled.div.attrs(() => ({}))`
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: HarmonyOS Sans;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.__deep_400};

  .strong {
    color: var(--Deep-800, #1a2535);
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  > span:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .blue {
    color: ${({ theme }) => theme.colors.__kontos_blue};
  }
`;

const ItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.__deep_800};
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
`;

const ItemLine3 = styled.div`
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.__deep_400};
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;

  word-break: break-all;
  white-space: normal;

  .item1,
  .item2,
  .item3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
  }

  .item1 {
    width: 35%;
  }

  .spacer {
    flex-grow: 1;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
`;

const FloatingBtnWraper = styled.div`
  position: sticky;
  bottom: 16px;
  width: 89%;
  z-index: 1;
  margin: 0 auto;
`;

const FailMessageBoxWrapper = styled.div`
  padding: 0 16px;
  margin: 10px 0 -10px 0;
`;

const FailMessageBox = styled.div`
  padding: 8px 20px;
  border-radius: 8px;
  background: rgba(255, 30, 43, 0.05);

  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: var(--error-notice, #ff1e2b);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
  }
`;

const getStatusClassName = (status: "error" | "success" | "warning") => {
  switch (status) {
    case "error":
      return "error";
    case "success":
      return "success";
    case "warning":
      return "warning";
    default:
      return "warning";
  }
};

const getStatusIcon = (status: "error" | "success" | "warning") => {
  switch (status) {
    case "error":
      return errorIcon;
    case "success":
      return successIcon;
    case "warning":
      return warningIcon;
    default:
      return errorIcon;
  }
};

export const classifyTaskStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return t("Task is pending");
    case 1:
      return t("Task is cancelled");
    case 2:
      return t("Task is claimed");
    case 3:
      return t("Task is timeout");
    case 4:
      return t("Task failed");
    case 5:
      return t("Task succeeded");
    case 6:
      return t("Task failed due to an account error");
    case 7:
      return t("Task failed at the pre-payment stage");
    case 8:
      return t("Transaction execution failed");
    case 9:
      return t("Failed to lock assets");
    case 10:
      return t("Payment isn't confirmed by the payer");
    default:
      return t("Unknown");
  }
};

interface IDisplayTask {
  opHash: string;
  status: "error" | "success" | "warning";
  statusCode: number;
  amount: string;
  taskType: string;
  chainImgUrl: string;
  chainSymbol: string;
  sender: string;
  receiver: string;
  broker: string;
  payments: {
    assetSymbol: string;
    assetImgUrl: string;
    chainSymbol: string;
    chainImgUrl: string;
    usdValue: string;
  }[];
  requstedAssets: {
    assetSymbol: string;
    assetImgUrl: string;
    amount: string;
  }[];
  totalFee: string;
  syncAAFee: string;
  createTime: string;
  callDataDetail?: TaskFull["callDataDetail"];
  brokerCanceledDescription: string;
  opCallDestAddrs: string[];
}

interface IProps {
  accountName: string;
  taskPreview: TaskPreview;
  onBack: () => void;
  onUpdate?: (newTask: TaskFull) => void;
}

const REFRESH_INTERVAL = 40000;

export const TaskDetail: React.FC<IProps> = memo(
  ({ accountName, taskPreview, onBack, onUpdate }) => {
    const [taskFull, setTaskFull] = useState<TaskFull>();
    const [requesting, setRequesting] = useState(false);

    useEffect(() => {
      const reFetchData = async () => {
        try {
          const respTask = await callTask(taskPreview.opHash);
          setTaskFull(respTask);
          onUpdate?.(respTask);
        } catch (e) {
          console.warn(e);
        }
      };

      const intervalId = setInterval(reFetchData, REFRESH_INTERVAL);

      return () => clearInterval(intervalId);
    }, [onUpdate, taskPreview.opHash]);

    useEffect(() => {
      const fetchData = async () => {
        let _taskFull = undefined;
        setRequesting(true);
        try {
          const respTask = await callTask(taskPreview.opHash);
          _taskFull = respTask;
          onUpdate?.(respTask);
        } catch (e) {
          console.warn(e);
        } finally {
          setTaskFull(_taskFull);
          setRequesting(false);
        }
      };
      taskPreview?.opHash && fetchData();
    }, [onUpdate, taskPreview.opHash]);

    const taskDisplay: IDisplayTask = useMemo(() => {
      if (taskFull) {
        return {
          opHash: taskFull.opHash,
          status: classifyTaskStatus(taskFull.taskStatus),
          statusCode: taskFull.taskStatus,
          amount:
            "-" +
            new KontosNumber(
              taskFull.totalUsdCost,
              DEFAULT_DECIMAL
            ).toFormat() +
            " USD",
          taskType: taskFull.taskType,
          chainImgUrl: taskFull.chainImageUrl,
          chainSymbol: taskFull.chainSymbol,
          receiver: taskFull.executorName,
          sender: accountName,
          broker: taskFull.brokerName,
          payments:
            taskFull.payments?.map((payment) => {
              return {
                assetSymbol: payment.assetSymbol,
                assetImgUrl: payment.assetImageUrl,
                chainSymbol: payment.chainSymbol,
                chainImgUrl: payment.chainImageUrl,
                usdValue:
                  new KontosNumber(
                    payment.usdAmount,
                    DEFAULT_DECIMAL
                  ).toFormat() + " USD",
              };
            }) || [],
          requstedAssets:
            taskFull.requiredAssets?.map((asset) => {
              return {
                assetSymbol: asset.symbol,
                assetImgUrl: asset.imageUrl,
                amount:
                  new KontosNumber(asset.amount, DEFAULT_DECIMAL).toFormat() +
                  " " +
                  asset.symbol,
              };
            }) || [],
          totalFeeText: taskPreview.includeSyncAccount
            ? t("Total fee (Sync AA fee):")
            : t("Total fee:"),
          totalFee:
            new KontosNumber(taskFull.totalFee, DEFAULT_DECIMAL).toFormat() +
            " USD",
          syncAAFee:
            taskFull?.syncAccountFee !== "0"
              ? new KontosNumber(
                  taskFull.syncAccountFee,
                  DEFAULT_DECIMAL
                ).toFormat() + " USD"
              : "",
          createTime: beautifyISOTimestamp(
            Long.fromValue(taskFull.createdAt).toNumber()
          ),
          callDataDetail: taskFull.callDataDetail,
          brokerCanceledDescription: taskFull.brokerCanceledDescription,
          opCallDestAddrs: taskFull.opCallDestAddrs || [],
        };
      } else {
        return {
          opHash: taskPreview.opHash,
          status: classifyTaskStatus(taskPreview.taskStatus),
          statusCode: taskPreview.taskStatus,
          amount:
            "-" +
            new KontosNumber(
              taskPreview.totalUsdCost,
              DEFAULT_DECIMAL
            ).toFormat() +
            " USD",
          taskType: taskPreview.taskType,
          chainImgUrl: taskPreview.chainImageUrl,
          chainSymbol: taskPreview.chainSymbol,
          sender: accountName,
          receiver: taskPreview.executorName,
          broker: taskPreview.brokerName,
          payments: [],
          requstedAssets:
            taskPreview.requiredAssets?.map((asset) => {
              return {
                assetSymbol: asset.symbol,
                assetImgUrl: asset.imageUrl,
                amount:
                  new KontosNumber(asset.amount, DEFAULT_DECIMAL).toFormat() +
                  " " +
                  asset.symbol,
              };
            }) || [],
          totalFee: "",
          syncAAFee: "",
          createTime: beautifyISOTimestamp(
            Long.fromValue(taskPreview.createdAt).toNumber()
          ),
          brokerCanceledDescription: "",
          opCallDestAddrs: taskPreview.opCallDestAddrs || [],
        };
      }
    }, [taskFull, taskPreview, accountName]);
    const callDataDetailReceiver = useMemo(() => {
      if (!taskDisplay?.callDataDetail?.receiver) {
        return "-";
      }
      if (ethers.utils.isAddress(taskDisplay.callDataDetail.receiver)) {
        return shortAddress(taskDisplay.callDataDetail.receiver);
      }
      return taskDisplay.callDataDetail.receiver.replaceAll(".os", "") + ".os";
    }, [taskDisplay.callDataDetail?.receiver]);

    return (
      <Container>
        <TitleBox>
          <img
            className="back-icon"
            src={LeftBlueArrowSvg}
            alt="back"
            onClick={() => onBack()}
          />
          <span>{t("Task Details")}</span>
          <div className="spacer"></div>
        </TitleBox>

        <StatusBox>
          <img
            className="status-box-icon"
            src={getStatusIcon(taskDisplay?.status)}
            alt="status"
          />
          <span
            className={`status-box-text ${getStatusClassName(
              taskDisplay?.status
            )}`}
          >
            {classifyTaskStatusText(taskDisplay?.statusCode)}
          </span>
          <span
            className={`status-box-amount ${getStatusClassName(
              taskDisplay?.status
            )}`}
          >
            {taskDisplay.amount}
          </span>
        </StatusBox>

        {/* Cancel Desc */}
        {classifyTaskStatus(taskPreview.taskStatus) === "error" &&
          taskDisplay.brokerCanceledDescription && (
            <FailMessageBoxWrapper>
              <FailMessageBox>
                <img src={errorIcon} alt="" />
                <span>{taskDisplay.brokerCanceledDescription}</span>
              </FailMessageBox>
            </FailMessageBoxWrapper>
          )}

        <div style={{ padding: "0 32px" }}>
          <Divider isDashed={true} top={24} bottom={12} />
        </div>

        <Scrollable>
          {/* Overview */}

          <ItemLine>
            <span>{t("Task type:")}</span>
            <span className="blue">
              {taskDisplay.syncAAFee !== ""
                ? taskDisplay.taskType + " + Sync AA"
                : taskDisplay.taskType}
            </span>
          </ItemLine>
          <ItemLine>
            <span>{t("Cross-chain to:")}</span>
            <ImageWithFallback
              src={taskDisplay.chainImgUrl || defaultTokenIcon}
              fallbackSrc={defaultTokenIcon}
              StyledImg={Icon}
            />
            <span>{taskDisplay.chainSymbol}</span>
          </ItemLine>
          {taskDisplay.taskType.toLowerCase() === "opcall" ? (
            <ItemLine>
              <span>{t("Sender")}:</span>

              {taskDisplay?.sender ? (
                <>
                  <Icon src={avatarIcon} />
                  <span>{formatAddress(taskDisplay.sender)}</span>
                </>
              ) : (
                "-"
              )}
            </ItemLine>
          ) : (
            <ItemLine>
              <span>{t("Receiver")}:</span>

              {taskDisplay?.receiver ? (
                <>
                  <Icon src={avatarIcon} />
                  <span>{formatAddress(taskDisplay.receiver)}</span>
                </>
              ) : (
                "-"
              )}
            </ItemLine>
          )}
          <ItemLine>
            <span>{t("Broker")}:</span>
            {taskDisplay?.receiver ? (
              <span>{formatAddress(taskDisplay.broker)}</span>
            ) : (
              "-"
            )}
          </ItemLine>

          {/* swap data */}
          {taskDisplay.taskType.toLowerCase() === "swap" && (
            <>
              <Divider isDashed={true} top={24} bottom={24} />
              <ItemTitle> {t("Swap")}</ItemTitle>
              <ItemLine3>
                <span className="item1">
                  <ImageWithFallback
                    src={
                      taskDisplay.callDataDetail?.ftAsset?.imageUrl ||
                      defaultTokenIcon
                    }
                    fallbackSrc={defaultTokenIcon}
                    StyledImg={Icon}
                  />
                  {taskDisplay.callDataDetail?.ftAsset?.symbol}
                </span>
                <span className="item2">
                  <ImageWithFallback
                    src={taskDisplay.chainImgUrl || defaultChainIcon}
                    fallbackSrc={defaultChainIcon}
                    StyledImg={Icon}
                  />
                  <span>{taskDisplay.chainSymbol}</span>
                </span>
                <span className="spacer"></span>
                <span className={`item3 strong`}>
                  -
                  {getDisplayAmount(
                    taskDisplay.callDataDetail?.ftAsset?.amount,
                    { isAutomateProcessAllTypes: true }
                  ) +
                    " " +
                    taskDisplay.callDataDetail?.ftAsset?.symbol}
                </span>
              </ItemLine3>
              <ItemLine style={{ marginTop: "10px" }}>
                <img style={{ marginLeft: "3px" }} src={arrowIco} alt="arrow" />
              </ItemLine>
              <ItemLine3
                style={{
                  marginTop: "10px",
                }}
              >
                <span className="item1">
                  <ImageWithFallback
                    src={
                      taskDisplay.callDataDetail?.outFtAsset?.imageUrl ||
                      defaultTokenIcon
                    }
                    fallbackSrc={defaultTokenIcon}
                    StyledImg={Icon}
                  />
                  {taskDisplay.callDataDetail?.outFtAsset?.symbol || " "}
                </span>
                <span className="item2">
                  <ImageWithFallback
                    src={taskDisplay.chainImgUrl || defaultChainIcon}
                    fallbackSrc={defaultChainIcon}
                    StyledImg={Icon}
                  />
                  <span>{taskDisplay.chainSymbol}</span>
                </span>
                <span className="spacer"></span>
                <span
                  className={`item3 strong`}
                  style={{
                    color: "var(--Kontos-Blue, #413DEC)",
                  }}
                >
                  +
                  {getDisplayAmount(
                    taskDisplay.callDataDetail?.outFtAsset?.amount,
                    { isAutomateProcessAllTypes: true }
                  ) +
                    " " +
                    taskDisplay.callDataDetail?.outFtAsset?.symbol}
                </span>
              </ItemLine3>
            </>
          )}

          {/* transfer data */}
          {taskDisplay.taskType.toLowerCase() === "transfer" && (
            <>
              <Divider isDashed={true} top={24} bottom={24} />
              <ItemTitle> {t("Transfer")}</ItemTitle>
              <ItemLine>
                <span>{t("Receiver")}:</span>
                <Icon src={avatarIcon} />
                <span>{callDataDetailReceiver}</span>
              </ItemLine>
              <ItemLine3>
                <span className="item1">
                  <ImageWithFallback
                    src={
                      taskDisplay.callDataDetail?.ftAsset?.imageUrl ||
                      defaultTokenIcon
                    }
                    fallbackSrc={defaultTokenIcon}
                    StyledImg={Icon}
                  />
                  {taskDisplay.callDataDetail?.ftAsset?.symbol}
                </span>
                <span className="item2">
                  <ImageWithFallback
                    src={taskDisplay.chainImgUrl || defaultChainIcon}
                    fallbackSrc={defaultChainIcon}
                    StyledImg={Icon}
                  />
                  <span>{taskDisplay.chainSymbol}</span>
                </span>
                <span className="spacer"></span>
                <span className={`item3 strong`}>
                  -
                  {getDisplayAmount(
                    taskDisplay.callDataDetail?.ftAsset?.amount,
                    { isAutomateProcessAllTypes: true }
                  )}
                </span>
              </ItemLine3>
            </>
          )}

          {/* Payment */}
          <Divider isDashed={true} top={24} bottom={24} />
          <ItemTitle> {t("Payment")}</ItemTitle>
          {taskDisplay.payments?.length > 0 ? (
            taskDisplay.payments?.map((payment) => {
              return (
                <ItemLine3 key={payment.assetSymbol + payment.chainSymbol}>
                  <span className="item1">
                    <ImageWithFallback
                      src={payment.assetImgUrl || defaultChainIcon}
                      fallbackSrc={defaultChainIcon}
                      StyledImg={Icon}
                    />
                    <span>{payment.assetSymbol}</span>
                  </span>
                  <span className="item2">
                    <ImageWithFallback
                      src={payment.chainImgUrl || defaultChainIcon}
                      fallbackSrc={defaultChainIcon}
                      StyledImg={Icon}
                    />
                    <span>{payment.chainSymbol}</span>
                  </span>
                  <span className="spacer"></span>
                  <span
                    className={`item3 ${
                      taskDisplay?.status === "error"
                        ? getStatusClassName(taskDisplay?.status)
                        : "strong"
                    }`}
                  >
                    -{payment.usdValue}
                  </span>
                </ItemLine3>
              );
            })
          ) : (
            <Skeleton count={2} style={{ marginTop: "16px" }} />
          )}

          {/* Contract Address */}
          {taskDisplay.taskType.toLowerCase() === "opcall" &&
            taskDisplay.opCallDestAddrs.length > 0 && (
              <>
                <Divider isDashed={true} top={24} bottom={24} />
                <ItemTitle> {t("Contract Address")}</ItemTitle>
                {taskDisplay.opCallDestAddrs.map((add) => (
                  <ItemLine
                    key={add}
                    style={{ whiteSpace: "normal", wordBreak: "break-all" }}
                  >
                    {add}
                  </ItemLine>
                ))}
              </>
            )}

          {/* Requested Assets */}
          {taskDisplay.requstedAssets.length > 0 && (
            <>
              <Divider isDashed={true} top={24} bottom={24} />
              <ItemTitle> {t("Requested Assets")}</ItemTitle>
              {taskDisplay.requstedAssets?.map((asset) => {
                return (
                  <ItemLine key={asset.assetSymbol}>
                    <span>
                      <ImageWithFallback
                        src={asset.assetImgUrl || defaultTokenIcon}
                        fallbackSrc={defaultTokenIcon}
                        StyledImg={Icon}
                      />
                      {asset.assetSymbol}
                    </span>
                    <span
                      className={`${
                        taskDisplay?.status === "error"
                          ? getStatusClassName(taskDisplay?.status)
                          : "kontos"
                      }`}
                    >
                      +{asset.amount}
                    </span>
                  </ItemLine>
                );
              })}
            </>
          )}

          {/* Others */}
          <Divider isDashed={true} top={24} bottom={24} />

          {/* {(requesting || !!taskDisplay.syncAAFee) && (
            <ItemLine>
              <span style={{ maxWidth: "50%" }}>{t("Sync AA fee:")}</span>
              {!!taskDisplay.totalFee ? (
                <span>{taskDisplay.syncAAFee}</span>
              ) : (
                <div style={{ width: "50%", textAlign: "right" }}>
                  <Skeleton />
                </div>
              )}
            </ItemLine>
          )} */}

          <ItemLine>
            <span style={{ maxWidth: "50%" }}>
              {!!taskDisplay.syncAAFee
                ? t("Total fee (Sync AA fee):")
                : t("Total fee:")}
            </span>
            {!!taskDisplay.totalFee ? (
              <span>{taskDisplay.totalFee}</span>
            ) : (
              <div style={{ width: "50%", textAlign: "right" }}>
                <Skeleton />
              </div>
            )}
          </ItemLine>
          <ItemLine>
            <span>{t("Creating time:")}</span>
            <span>{taskDisplay.createTime}</span>
          </ItemLine>
        </Scrollable>

        <FloatingBtnWraper>
          <FloatingButton
            onClick={() => {
              const url =
                EXPLORER_URL + "task-detail?hash=" + taskPreview.opHash;
              openUrl(url);
            }}
            fixedIcon={OpenUrlIcon}
          >
            {t("View on Kontos Explorer")}
          </FloatingButton>
        </FloatingBtnWraper>
      </Container>
    );
  }
);
