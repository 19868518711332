import {observer} from "mobx-react";
import styled from "styled-components";
import React, {useState} from 'react';
import kontosAvatar from "src/assets/images/kontos-avatar-recover.svg";
import loading from "src/assets/images/loading2.svg";
import success from "src/assets/images/success.svg";
import errorIco from "src/assets/images/fail.svg";

const Wrapper = styled.div`
 width: 42px;
 height: 42px;
 flex-shrink: 0;
 position: relative;

 img {
  position: absolute;
 }

 .img0 {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
 }

 .img1 {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  left: 31px;
  top: 26px;
 }

 .img1Loading {
  animation: rotate 1s linear infinite;
 }

 @keyframes rotate {
  0% {
   transform: rotate(0deg);
  }
  100% {
   transform: rotate(359deg);
  }
 }
`;

export const inputStatusMap = {
  success: 'success',
  active: 'active',
  error: 'error',
}

type IProps = {
  status: keyof typeof inputStatusMap;
}

const RecoverStatusIcon: React.FC<IProps> = ({
                                               status
                                             }) => {
  const Img0 = () => {
    return <img className={'img0'} src={kontosAvatar}/>
  }

  const Img1 = () => {
    switch (status) {
      case 'success':
        return <img className={'img1'} src={success}/>
        break;
      case 'error':
        return <img className={'img1'} src={errorIco}/>
        break;

      default:
        return null
    }
  }

  return (
    <Wrapper>
      <Img0/>
      <Img1/>
    </Wrapper>
  );
};

export default observer(RecoverStatusIcon);
