import * as dappApi from "src/apis/dapp-apis";
import { KontosClient, KontosQueryCli } from "@zkkontos/kontos-sdk";
import { signForBe } from "src/utils/zkkontosHelper";
import { EB_SIGN_EXPIREDAT } from "src/config";
import { RespWatchlist, WatchAction } from "src/apis/dapp-apis";

interface IUpdateFavoritesParams {
  account: string;
  cli: KontosClient;
  dappId?: number;
  expiration?: number;
  action?: WatchAction;
  ftAssetId?: number;
}

export const getFavorites = async (account: string): Promise<RespWatchlist> => {
  const { dapps, ftAssets } = await dappApi.watchList({ account });
  return {
    dapps: dapps || [],
    ftAssets: ftAssets || [],
  };
};

const updateFavorites = async ({
  account,
  cli,
  dappId = 0,
  expiration = EB_SIGN_EXPIREDAT,
  action = WatchAction.Collect,
  ftAssetId = 0,
}: IUpdateFavoritesParams) => {
  const kontosAddress = KontosQueryCli.kontosAddress(account);
  const { signature, expiredAt } = await signForBe(
    account,
    kontosAddress,
    cli,
    account,
    expiration
  );
  return await dappApi.watchDappOrFtAsset({
    account,
    action,
    dappId,
    expiredAt,
    signature,
    ftAssetId,
  });
};

export const addFavorites = async ({
  account,
  cli,
  dappId,
  expiration,
  ftAssetId,
}: IUpdateFavoritesParams) => {
  return await updateFavorites({
    account,
    cli,
    dappId,
    expiration,
    action: WatchAction.Collect,
    ftAssetId,
  });
};

export const removeFavorites = async ({
  account,
  cli,
  dappId = 0,
  expiration,
  ftAssetId,
}: IUpdateFavoritesParams) => {
  return await updateFavorites({
    account,
    cli,
    dappId,
    expiration,
    action: WatchAction.UnCollect,
    ftAssetId,
  });
};

export const sortFavorites = async (
  account: string,
  ids: number[],
  cli: KontosClient,
  expiration: number = EB_SIGN_EXPIREDAT
) => {
  const kontosAddress = KontosQueryCli.kontosAddress(account);
  const { signature, expiredAt } = await signForBe(
    account,
    kontosAddress,
    cli,
    account,
    expiration
  );
  return await dappApi.sort({
    account,
    ids,
    action: dappApi.SortAction.Dapp,
    expiredAt,
    signature,
  });
};
