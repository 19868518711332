import { UnlockSheet } from "src/pages/sign-up/unlock/UnlockSheet";
import { DappConnectionsSheet } from "./dapps/DappConnectionsSheet";
import SignSheet from "./dapps/SignSheet";
import { WcConnectConfirmSheet } from "./dapps/WcConnectConfirmSheet";
import { ContractInteractionSheet } from "./contract-interaction/ContractInteractionSheet";
import { QrReaderSheet } from "./qr-code/QrReaderSheet";

interface Props {
  mountPoint: Element | undefined;
}

export const GlobalSheet: React.FC<Props> = ({ mountPoint }) => {
  return (
    <>
      {/* DApp Connection Sheet */}
      <DappConnectionsSheet mountPoint={mountPoint} />

      {/* WcConnect Sheet */}
      <WcConnectConfirmSheet mountPoint={mountPoint} />

      {/* Unlock */}
      <UnlockSheet mountPoint={mountPoint} />

      {/* Sign */}
      <SignSheet mountPoint={mountPoint} />

      {/* Contract Interaction */}
      <ContractInteractionSheet mountPoint={mountPoint} />

      {/* Qr Reader */}
      <QrReaderSheet mountPoint={mountPoint} />
    </>
  );
};
