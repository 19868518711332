import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;
    line-height: 20px;
  }

  .right {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans";
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    &:active {
      color: var(--Kontos-Blue, #413dec);
    }
    @media (hover: hover) {
      &:hover {
        color: var(--Kontos-Blue, #413dec);
      }
    }
  }
`;

interface Props {
  className?: string;
  onClearClick?: () => void;
}

export const DappHistoryHeader: React.FC<Props> = ({
  className,
  onClearClick,
}) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <div className="left">{t("History")}</div>
      <div className="right" onClick={onClearClick}>
        {t("Clear")}
      </div>
    </Container>
  );
};
