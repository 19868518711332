import * as dappApi from "src/apis/dapp-apis";
import { DappFavoritesItem, Dapp } from "src/type/dapp";

export const searchDappPaged = async (
  character: string,
  offset: number = 0,
  limit: number = 10
): Promise<{
  dapps: DappFavoritesItem[];
  total: number;
}> => {
  const { dapps, total } = await dappApi.searchDapp({
    offset,
    limit,
    character,
  });
  return { dapps: dapps || [], total };
};

export const fetchTrendings = async (
  offset: number = 0,
  limit: number = 15
): Promise<{
  dapps: Dapp[];
  total: number;
}> => {
  const { dapps, total } = await dappApi.dapps({
    category: -1,
    chainIndex: "",
    regex: "",
    offset,
    limit,
  });
  return { dapps: dapps || [], total };
};

export const searchTrendings = async (
  query: string,
  offset: number = 0,
  limit: number = 15
): Promise<{
  dapps: Dapp[];
  total: number;
}> => {
  const { dapps, total } = await dappApi.dapps({
    category: -1,
    chainIndex: "",
    regex: query,
    offset,
    limit,
  });
  return { dapps: dapps || [], total };
};
