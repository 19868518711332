import styled from "styled-components";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { observer } from "mobx-react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";
import { FtAsset } from "src/type/zkkontos";
import { useStores } from "src/hooks/useStore";
import { AssetDetailQuote } from "src/components/assets-detail/AssetDetailQuote";
import { QuoteWrapper } from "src/components/assets-detail/QuoteWrapper";
import { TradeNavigator } from "./TradeNavigator";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import SettingsRouter from "../settings/SettingsRouter";
import {
  ROUTE_SETTINGS_TRADE,
  ROUTE_TRADE,
  ROUTE_TRADE_SPOT,
  ROUTE_TRADE_SWAP,
} from "src/router/router-config";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import sessionKeeper from "src/store/sessionKeeper";

const Container = styled.div`
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #f1f5ff 0%, #edeef1 100%);
`;

const StyledScrollable = styled(Scrollable)`
  position: relative;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

type ContextType = { viewAssetInfo: (asset: FtAsset) => void };

export const TradeDashboard: React.FC = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const {
    marketStore,
    uiSettingStore,
    spotStore,
    swapStore,
    userStore,
    tradeStore,
  } = useStores();
  const showQuote = searchParams.get("showQuote");
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const navigate = useNavigate();

  const isSpotPage = pathname.includes(ROUTE_TRADE_SPOT);
  const isSwapPage = pathname.includes(ROUTE_TRADE_SWAP);

  const insufficient = (() => {
    if (spotStore.insufficient && isSpotPage) return true;
    if (swapStore.insufficient && isSwapPage) return true;
    return false;
  })();

  useEffect(() => {
    const showed = sessionKeeper.getShowedManageAssetTip();

    if (!showed && insufficient && userStore.userHoldingsAvailableInUsd.gt(0)) {
      tradeStore.setShowManageTip(true);
      sessionKeeper.setShowedManageAssetTip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insufficient, userStore.userHoldingsAvailableInUsd]);

  const viewAssetInfo = useCallback(
    (asset: FtAsset) => {
      marketStore.setCurrentAssetQuote(asset);
      setSearchParams({
        ...searchParams,
        ftAssetId: asset.ftAssetId.toString(),
        chainIndex: asset.chainIndex,
        ftAssetAddress: asset.address,
        showQuote: "1",
        hideNavigationBar: "1",
      });
    },
    [marketStore, searchParams, setSearchParams]
  );

  const handleBtnClick = useCallback(() => {
    uiSettingStore.openTradeSettingSheet();
    navigate(pathname + "/" + ROUTE_SETTINGS_TRADE);
  }, [navigate, pathname, uiSettingStore]);

  const closeModals = useCallback(() => {
    uiSettingStore.closeTradeSettingSheet();
  }, [uiSettingStore]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: true,
  });

  useEffect(() => {
    // handleLoadMoreItems();
    if (!pathname.includes(ROUTE_SETTINGS_TRADE)) {
      uiSettingStore.closeTradeSettingSheet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (
      !uiSettingStore.showTradeSettingSheet &&
      pathname.includes(ROUTE_SETTINGS_TRADE)
    ) {
      navigate(ROUTE_TRADE + "/" + pathname.split("/")?.[2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiSettingStore.showTradeSettingSheet]);

  useEffect(() => {
    return () => {
      tradeStore.resetAiFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container ref={wrapperRef}>
      {/* When the quote view is displayed, the header is hidden to allow for a full-page view */}
      <TradeNavigator onClick={handleBtnClick} />

      <StyledScrollable>
        <Outlet context={{ viewAssetInfo } satisfies ContextType} />
      </StyledScrollable>

      {/* Quote Modal */}
      {showQuote && (
        <QuoteWrapper>
          <AssetDetailQuote />
        </QuoteWrapper>
      )}

      {/* Trade Setting Sheet */}
      <BottomSheet
        isOpen={uiSettingStore.showTradeSettingSheet}
        onClose={() => uiSettingStore.closeTradeSettingSheet()}
        mountPoint={domNode}
      >
        <SettingsRouter />
      </BottomSheet>
    </Container>
  );
});

export const useTradeViewAsset = () => {
  return useOutletContext<ContextType>();
};
