import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const Line = styled.div`
  height: 16px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftItem = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const RightItem = styled.div``;

export interface IProps {
  list: {
    label: string;
    value: ReactNode;
  }[];
}

export const TradeBreakdownViewV2: React.FC<IProps> = ({ list }) => {
  return (
    <Container>
      {list.map((item) => (
        <Line key={item.label}>
          <LeftItem>{item.label}</LeftItem>
          <RightItem>{item.value}</RightItem>
        </Line>
      ))}
    </Container>
  );
};
