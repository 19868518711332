import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import OtcAlchemySvg from "src/assets/images/trade/otc-alchemy.svg";
import { useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import {
  GateFiDisplayModeEnum,
  GateFiLangEnum,
  GateFiSDK,
} from "@gatefi/js-sdk";
import { observer } from "mobx-react";

const Container = styled.div<{ $isOverWidth: boolean }>`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.$isOverWidth
      ? props.theme.colors.__deep_25
      : props.theme.colors.__white} !important;
`;

const IconContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;

  .icon {
    margin-left: 50px;
    height: 70px;
  }
`;

const LoadingGif = styled.img`
  height: 20px;
  width: 100px;
`;

const RampContainer = styled.div<{ $isOverWidth: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  width: min(calc(100vw - 20px), 375px);

  ${({ $isOverWidth }) =>
    $isOverWidth === true
      ? `
      iframe {
          
          border: none;
          box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1), 5px 0 10px rgba(0, 0, 0, 0.1);
        }
      `
      : `
        iframe {
          border: none;
        }
      `}
`;

interface AlchemyPriceRes {
  success: boolean;
  returnCode: string;
  returnMsg: string;
  extend: string;
  data: AlchemyPriceData;
}

interface AlchemyPriceData {
  cryptoPrice: string;
  side: string;
  fiatAmount: string;
  cryptoQuantity: string;
  rampFee: string;
  networkFee: string;
  fiat: string;
  crypto: string;
  payWayCode: string;
}

interface Props {
  address: string;
  quantity: string;
  quantityWithSymbol: string;
  onSuccess: () => void;
}

const UnLimitPay: React.FC<Props> = ({
  address,
  quantity,
  quantityWithSymbol,
  onSuccess,
}) => {
  const { uiStore } = useStores();
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);
  const onrampInstance = useRef();

  useEffect(() => {
    // const randomString = crypto.randomBytes(32).toString('hex');
    if (!process.env.REACT_APP_UNLIMIT_ID)
      throw new Error("REACT_APP_UNLIMIT_ID is not defined");
    if (!address) throw new Error("address is not defined");
    const initUnlimitPayFunc = () => {
      const instance = new GateFiSDK({
        merchantId: process.env.REACT_APP_UNLIMIT_ID || "",
        displayMode: GateFiDisplayModeEnum.Embedded,
        nodeSelector: "#cbpay-button-container",
        isSandbox: true,
        walletAddress: address,
        styles: {
          type: "light",
        },
        // email: "",
        // externalId: randomString,
        defaultFiat: {
          currency: "USD",
          amount: quantity,
        },
        defaultCrypto: {
          currency: "USD",
        },
        // availableCrypto: ["USDT"]
      });
      instance.show();
    };

    return () => {
      initUnlimitPayFunc();
    };
  }, []);

  return (
    <Container $isOverWidth={uiStore.isOverWidth}>
      {uiStore.isOverWidth && (
        <IconContainer>
          <img className="icon" src={OtcAlchemySvg} alt="" />
        </IconContainer>
      )}

      <RampContainer
        id="cbpay-button-container"
        $isOverWidth={uiStore.isOverWidth}
      ></RampContainer>

      <div style={{ height: "10px" }}></div>
    </Container>
  );
};

export default observer(UnLimitPay);
