import styled from "styled-components";
import { ReactNode, useState } from "react";
import EyeOpen from "./eye-open.svg";
import EyeClose from "./eye-close.svg";
import EyeOpenGrey from "./eye-open-grey.svg";
import EyeCloseGrey from "./eye-close-grey.svg";

const Wrap = styled.div`
  position: relative;
  .eye-icon {
    position: absolute;
    right: 24px;
    top: 16px;
    cursor: pointer;
    width: 19px;
    height: 17px;
  }

  .eye-icon-v2 {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
const InputS = styled.input`
  font-family: "HarmonyOS Sans";
  background: #f7f7f8;
  border: 1px solid #dfe1e3;
  border-radius: 54px;
  height: 48px;
  line-height: 48px;
  box-sizing: border-box;
  padding: 0 24px;
  width: 100%;
  color: #80868f;
  margin-bottom: 11px;
  font-size: 18px;
  &::placeholder {
    color: #80868f;
  }
  &:focus {
    border: 2px solid #413dec;
    background: #fff;
  }
`;

const InputPasswordS = styled(InputS)`
  color: rgba(128, 134, 143, 0.4);
  padding-right: 50px;
  &::placeholder {
    color: rgba(128, 134, 143, 0.4);
  }
  &:focus {
    border: 2px solid #413dec;
  }
`;

const InputPasswordSV2 = styled.input`
  font-family: "HarmonyOS Sans";
  border-radius: 8px;
  border: 1px solid #dfe1e3;
  height: 52px;
  line-height: 52px;
  box-sizing: border-box;
  padding: 0 21px;
  width: 100%;
  font-size: 18px;
  color: #1a2535;
  background: #f7f7f8;
  padding-right: 50px;
  caret-color: #413dec;

  &[type="password"] {
    color: #1a2535;
    letter-spacing: 0;
  }

  &::placeholder {
    color: #bbbfc3;
    font-family: HarmonyOS Sans;
    font-size: 14px;
    font-weight: 500;
  }

  &:focus {
    border: 1px solid #413dec;
  }
`;

const Input = (props: {
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  placeHolder: string;
  style?: object;
  slot?: ReactNode;
}) => {
  return (
    <Wrap>
      <InputS
        style={props.style}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={() => {
          props.onFocus && props.onFocus();
        }}
        onBlur={() => {
          props.onBlur && props.onBlur();
        }}
        value={props.value}
        placeholder={props.placeHolder}
      />
    </Wrap>
  );
};

const InputPassword = (props: {
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  placeHolder: string;
  style?: object;
  slot?: ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  if (show) {
    return (
      <Wrap>
        <InputPasswordS
          type={"text"}
          style={props.style}
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={() => {
            props.onFocus && props.onFocus();
          }}
          onBlur={() => {
            props.onBlur && props.onBlur();
          }}
          value={props.value}
          placeholder={props.placeHolder}
        />
        <img src={EyeClose} className="eye-icon" onClick={handleShow} alt="" />
      </Wrap>
    );
  }
  return (
    <Wrap>
      <InputPasswordS
        type={"password"}
        style={props.style}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={() => {
          props.onFocus && props.onFocus();
        }}
        onBlur={() => {
          props.onBlur && props.onBlur();
        }}
        value={props.value}
        placeholder={props.placeHolder}
      />
      <img src={EyeOpen} className="eye-icon" onClick={handleShow} alt="" />
    </Wrap>
  );
};

const InputPasswordV2 = (props: {
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  placeHolder: string;
  style?: object;
  slot?: ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  if (show) {
    return (
      <Wrap>
        <InputPasswordSV2
          type={"text"}
          style={props.style}
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={() => {
            props.onFocus && props.onFocus();
          }}
          onBlur={() => {
            props.onBlur && props.onBlur();
          }}
          value={props.value}
          placeholder={props.placeHolder}
        />
        <img
          src={EyeCloseGrey}
          className="eye-icon-v2"
          onClick={handleShow}
          alt=""
        />
      </Wrap>
    );
  }
  return (
    <Wrap>
      <InputPasswordSV2
        type={"password"}
        style={props.style}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={() => {
          props.onFocus && props.onFocus();
        }}
        onBlur={() => {
          props.onBlur && props.onBlur();
        }}
        value={props.value}
        placeholder={props.placeHolder}
      />
      <img
        src={EyeOpenGrey}
        className="eye-icon-v2"
        onClick={handleShow}
        alt=""
      />
    </Wrap>
  );
};

export { Input, InputPassword, InputPasswordV2 };
