import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import waitingRecoverEmailIco from "src/assets/images/waiting-recover-emai.svg";
import triangleIco from "src/assets/icons/triangle.svg";
import info from "src/assets/icons/info1.svg";
import InputWithIcons from "src/components/input/KontosInputV2";
import HorizontalLine from "src/components/line/HorizontalLine";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import RecoverStatusIcon from "src/components/start/RecoverStatusIcon";
import {
  convertToLowercaseIfGmail,
  isMobileDevice,
  isValidGoogleEmail,
} from "src/utils/helper";
import { KONTOS_SUPPORT_EMAIL } from "src/config";
import { KontosKey } from "@zkkontos/kontos-sdk/src/index";
import {
  accountNewPubKeyAction,
  RespAccountNewPubKeyAction,
} from "@zkkontos/kontos-sdk/src/api/aaApi";
import { kecca256String } from "src/pages/newAuth/CreateAccount";
import RecoverInputStatusText from "src/components/start/RecoverInputStatusText";
import Sheet from "react-modal-sheet";
import CopyRecoveryEmailInfo from "src/components/start/CopyRecoveryEmailInfo";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";

const MoreOptionsWrapper = styled.div`
  width: 167px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  span {
    color: var(--Deep-400, #80868f);

    /* Main text */
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-right: 3px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;

  .main {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    .description {
      display: flex;
      justify-content: center;
      color: var(--Warning, #faad14);
      border-radius: 8px;
      border: 1px solid var(--Warning, #faad14);
      background: rgba(250, 173, 20, 0.1);
      /* Description */
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      padding: 23px 16px;

      .info {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin-right: 8px;
      }

      span {
        color: var(--Warning, #faad14);
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .waitingRecoverEmailIco {
      width: 200px;
      height: 86px;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }

    .buttonWrap {
      width: calc(100% - 40px);
      position: fixed;
      bottom: 16px;
    }
  }
`;

const WrapperRightIco = styled.div`
  width: 106px;
  height: 32px;
  flex-shrink: 0;
  > a {
    display: block;
    width: 100%;
    height: 100%;
  }
  span {
    color: #fff !important;
  }
`;

type IProps = {
  submitFunction: () => void;
  userEmailArr: string[];
  kontosName: string;
  nonce: number;
  kontosKey: KontosKey | undefined;
  actionThreshold: number;
};

type IRightIcoProps = {
  index: number;
};

let interval: any = null;

const WaitingAuthorizeMail: React.FC<IProps> = ({
  submitFunction,
  userEmailArr,
  kontosName,
  nonce,
  kontosKey,
  actionThreshold,
}) => {
  const [eMailArr, setEMailArr] = useState<string[]>(
    Array(userEmailArr.length).fill("")
  );
  const [newPubKeyAction, setNewPubKeyAction] = useState<
    RespAccountNewPubKeyAction | undefined
  >(undefined);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;

  const isActionApprovedAndPubKeyMatched = (
    guardianAddress: string,
    localPubkey: string | undefined,
    threshHoldActions: RespAccountNewPubKeyAction | undefined
  ) => {
    if (!guardianAddress || !threshHoldActions || !localPubkey) return false;
    return (
      threshHoldActions?.action.newPubKey === localPubkey &&
      Object.keys(threshHoldActions?.action.approvals).includes(guardianAddress)
    );
  };

  const successEmailLength = useMemo(() => {
    return userEmailArr.filter((item, index) => {
      return isActionApprovedAndPubKeyMatched(
        item,
        kontosKey?.pubKeyData?.compressedPubKey,
        newPubKeyAction
      );
    }).length;
  }, [userEmailArr, kontosKey, newPubKeyAction]);

  useEffect(() => {
    clearInterval(interval);
    interval = null;
    interval = setInterval(() => {
      accountNewPubKeyAction({
        account: kontosName,
      })
        .then((rs) => {
          setNewPubKeyAction(rs);
        })
        .catch((e) => {
          console.warn("get account guardians fail");
        });
    }, 10000);
  }, [kontosName]);

  const RightIco = ({ index }: IRightIcoProps) => {
    const mailObj = {
      account: `${kontosName.replaceAll(".os", "")}.os`,
      pkStr: kontosKey?.pubKeyData.compressedPubKey,
      nonce,
    };

    return (
      <WrapperRightIco>
        {isValidGoogleEmail(eMailArr[index]) ? (
          <a
            style={{ textDecoration: "none" }}
            href={
              isMobileDevice()
                ? `mailto:${KONTOS_SUPPORT_EMAIL}?subject=Kontos Account Recovery&body=${JSON.stringify(
                    mailObj
                  )}`
                : `https://mail.google.com/mail/?view=cm&fs=1&to=${KONTOS_SUPPORT_EMAIL}&su=Kontos Account Recovery&body=${encodeURIComponent(
                    JSON.stringify(mailObj)
                  )}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button
              text={t("Send Email")}
              fullWidth={true}
              height={"32px"}
              disabled={userEmailArr.length <= 0}
            />
          </a>
        ) : null}
      </WrapperRightIco>
    );
  };

  return (
    <Wrapper ref={wrapperRef}>
      <div className={"main"}>
        <img
          className={"waitingRecoverEmailIco"}
          src={waitingRecoverEmailIco}
          alt="waitingRecoverEmailIco"
        />
        <div className={"description"}>
          <img className={"info"} src={info} alt="info" />
          Waiting for email confirmed {successEmailLength}/{actionThreshold}...
        </div>
        <div className={"lineWrap"}>
          <HorizontalLine />
        </div>
        <div className={"inputWrap"}>
          {eMailArr?.map((item, index) => (
            <InputWithIcons
              inputStatus={
                !item || isValidGoogleEmail(item) ? "success" : "error"
              }
              key={index}
              leftIcon={
                <RecoverStatusIcon
                  status={
                    isActionApprovedAndPubKeyMatched(
                      kecca256String(eMailArr[index]),
                      kontosKey?.pubKeyData?.compressedPubKey,
                      newPubKeyAction
                    )
                      ? "success"
                      : !item || isValidGoogleEmail(item)
                        ? "active"
                        : "error"
                  }
                />
              }
              rightIcon={
                isActionApprovedAndPubKeyMatched(
                  kecca256String(eMailArr[index] || ""),
                  kontosKey?.pubKeyData?.compressedPubKey,
                  newPubKeyAction
                ) ? (
                  <RecoverInputStatusText status={"success"} />
                ) : (
                  <RightIco index={index} />
                )
              }
              onChange={(e) => {
                const str = convertToLowercaseIfGmail(e.target.value);
                setEMailArr(
                  eMailArr.map((item, i) => {
                    if (i === index) {
                      return str;
                    } else {
                      return item;
                    }
                  })
                );
              }}
              placeholder={t("Enter your security email")}
              value={item}
              isDelete={false}
            />
          ))}
          <MoreOptionsWrapper
            onClick={() => {
              setShowCopyModal(true);
            }}
          >
            <span>{t("More Options")}</span>
            <img src={triangleIco} alt="triangleIco" />
          </MoreOptionsWrapper>
        </div>
      </div>
      <NoScrollerBarSheet
        isOpen={showCopyModal}
        onClose={() => setShowCopyModal(false)}
        mountPoint={domNode}
        snapPoints={[350]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <CopyRecoveryEmailInfo
              mailMap={{
                mailto: KONTOS_SUPPORT_EMAIL,
                subject: "Kontos Account Recovery",
                body: JSON.stringify({
                  account: `${kontosName.replaceAll(".os", "")}.os`,
                  pkStr: kontosKey?.pubKeyData.compressedPubKey,
                  nonce,
                }),
              }}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setShowCopyModal(false)} />
      </NoScrollerBarSheet>
    </Wrapper>
  );
};

export default observer(WaitingAuthorizeMail);
