import styled from "styled-components";
import EditIcon from "../icons/EditIcon";
import RemoveIcon from "../icons/RemoveIcon";
import { t } from "i18next";
import { observer } from "mobx-react";

const GuardianItemStyle = styled.div<{ $clickable: boolean }>`
  display: flex;
  justify-content: space-between;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.colors.__deep_50};
  background: ${(props) => props.theme.colors.__deep_25};
  margin-bottom: 8px;
  padding: 7px;
  cursor: ${(props) => props.$clickable && "pointer"};

  .left {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    .ava-box {
      width: 42px;
      height: 42px;
      position: relative;
      margin-right: 12px;

      .ava {
        width: 40px;
        height: 40px;
      }

      .ava-text {
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);

        padding: 0 6px;
        border-radius: 99px;
        background: ${(props) => props.theme.colors.__kontos_blue};

        color: ${(props) => props.theme.colors.__white};
        text-align: center;
        font-family: "HarmonyOS Sans";
        font-size: 11px;
      }
    }

    span {
      color: ${(props) => props.theme.colors.__deep_800};
      font-family: "HarmonyOS Sans";
      font-size: 18px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .right {
    margin-left: 14px;
    margin-right: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;

    .tool-ico {
      width: 20px;
      height: 20px;
    }
  }
`;

interface Props {
  name: string;
  avatar: any;
  isNew?: boolean;
  hasSuffix?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
  onClick?: () => void;
}

const GuardianItem = ({
  name,
  avatar,
  isNew = false,
  hasSuffix = true,
  onEdit,
  onRemove,
  onClick,
}: Props) => {
  return (
    <GuardianItemStyle
      $clickable={!!onClick}
      onClick={async () => {
        onClick && onClick();
      }}
    >
      <div className="left">
        <div className="ava-box">
          <img className={"ava"} src={avatar} alt="" />
          {isNew && <div className="ava-text">{t("New")}</div>}
        </div>
        <span>{name}</span>
        {hasSuffix && <span>.os</span>}
      </div>
      <div className="right">
        {onEdit && <EditIcon className={"tool-ico"} onClick={onEdit} />}
        {onRemove && <RemoveIcon className={"tool-ico"} onClick={onRemove} />}
      </div>
    </GuardianItemStyle>
  );
};

export default observer(GuardianItem);
