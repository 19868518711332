import React, {useState, useRef, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import CSS from 'csstype';
import arrowIco from 'src/assets/icons/arrow13.svg';
import MarketsRankingItem from './MarketsRankingItem';
import {useStores} from "src/hooks/useStore";
import Star from "src/components/star/Star";

const Wrapper = styled.div`
 padding: 0 24px;
  position: relative;
  .itemWrap{
    height: auto;
    overflow-y: hidden;
    overflow-x: scroll;
    position: relative;
    width: calc(100% - 10px);
    .scrollItem{
      height: auto;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
    &::-webkit-scrollbar {
      height: 3px;
      display: none;
    }
  }
  .arrow{
    width: 56px;
    height: 35px;
    position: absolute;
    right: 0;
    top: -0px;
    cursor: pointer;
    z-index: 2;
    background: #fff;
  }
`;

type IProps = {
  styles?: CSS.Properties;
  wrapperStyles?: CSS.Properties;
  itemClick: (item: any) => void;
  moreBtnClick: () => void;
  selectedCategory?: number;
}

export function MarketsRanking({
                                 styles = {},
                                 wrapperStyles={},
                                 itemClick,
                                 moreBtnClick,
                                 selectedCategory,
}: IProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [totalWidth, setTotalWidth] = useState(0);
  const { marketStore} = useStores();
  const calculateTotalWidth = () => {
    const container = containerRef.current;
    let width = 0;

    if (container) {
      Array.from(container.children).forEach((child) => {
        if (child instanceof HTMLElement) {
          width += (child.offsetWidth + 8);
        }
      });

      setTotalWidth(width + 10);
    }
  };
  useEffect(() => {
    calculateTotalWidth();
  }, [])
  return (
    <Wrapper style={wrapperStyles}>
      <div style={styles} className={'itemWrap'}>
        <div className={'scrollItem'} style={{width: totalWidth + 'px'}} ref={containerRef}>
          {marketStore.categoryArr?.map((item, index) => (
            <MarketsRankingItem
              className={`item ${selectedCategory === item.category ? 'item-active' : ''}`}
              key={item.symbol}
              onClick={() => {
                itemClick(item)
              }}
            >
              {
                (item.symbol === 'Favorites') && <Star starStatus={"active"} />
              }
              {
                ((item.symbol === 'Recommended' || item.symbol === 'HOT')) && <img className={'img'} src={item.imageUrl} />
              }
              {
                (!(item.symbol === 'Favorites')! && !((item.symbol === 'Recommended' || item.symbol === 'HOT'))) && <span>{item.symbol}</span>
              }
            </MarketsRankingItem>
          ))}
        </div>
      </div>
      <img
        onClick={() => {moreBtnClick()}}
        className={'arrow'} src={arrowIco} />
    </Wrapper>
  );
}

export default MarketsRanking;
