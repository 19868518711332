import styled from "styled-components";
import PaymentChargeItem from "../payment-items/PaymentChargeItem";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FtAsset } from "src/type/zkkontos";
import {
  isSameFtAsset,
  transRespTokenToFtAsset,
} from "src/utils/zkkontosHelper";
import {
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
  KONTOS_CHAIN_INDEX,
  KONTOS_GATE,
  TRADE_DEFAULT_BRIDGE_FTASSET,
  TRADE_DEFAULT_BRIDGE_FTASSET_2,
} from "src/config";
import { RespTaskDataV3 } from "@zkkontos/kontos-sdk/src/api";
import debounce from "lodash/debounce";
import { getToken } from "src/service/trade-service";
import KontosNumber from "src/utils/KontosNumber";
import Divider from "src/components/divider/Divider";
import AvatarSvg from "src/assets/icons/trade/trade-avatar.svg";
import PaymentKeyValueList, {
  AvatarIcon,
  CommonText,
  SuccessText,
  WarningText,
} from "../payment-items/PaymentKeyValueList";
import PendingSvg from "src/assets/images/pending.svg";
import SuccessSvg from "src/assets/images/success.svg";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import "react-loading-skeleton/dist/skeleton.css";
import FloatingButton from "src/pages/trade/FloatingButton";
import OpenUrlIcon from "src/assets/icons/task/open-url.svg";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";
import { PaymentMode } from "src/store/trade/PaymentStore";
import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "src/pages/trade/asset-select/ToBuyAssetSelector";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";
import { openUrl } from "src/utils/helper";

const REFRESH_INTERVAL = 20000;

const Container = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

const MainContainer = styled.div`
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  flex: 1;
  overflow-y: auto;
`;

const KeyValueListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const FloatingBtnContainer = styled.div`
  position: sticky;
  bottom: 16px;
  width: 89%;
  z-index: 1;
  margin: 0 auto;
`;

interface Props {
  taskData: RespTaskDataV3;
  choosing: boolean;
  onChoosing: (isOpen: boolean) => void;
  onSuccess: () => void;
}

const PaymentBridge: React.FC<Props> = ({
  taskData,
  choosing,
  onChoosing,
  onSuccess,
}) => {
  const { paymentStore, userStore, chainStore } = useStores();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [toReceiveFtAsset, setToReceiveFtAsset] = useState<FtAsset | undefined>(
    paymentStore.paymentBridgeFtAsset
  );
  const toReceiveAmount: KontosNumber = new KontosNumber(
    taskData.minRequiredAssetAmountForChargeAsset,
    DEFAULT_DECIMAL
  );
  const toReceiveAmountText: string | undefined = toReceiveAmount.gt(0)
    ? toReceiveAmount.toFixed(DEFAULT_DISPLAY_PRECESION) +
      " " +
      toReceiveFtAsset?.symbol
    : undefined;
  const [taskDataLoading, setTaskDataLoading] = useState<boolean>(true);
  const [assetLoading, setAssetLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<"success" | "pending">("pending");
  const [allowChangeReceive, setAllowChangeReceive] = useState<boolean>(true);
  const toReceiveChainIndex = toReceiveFtAsset?.chainIndex;

  const executeTaskData = useCallback(
    async (_targetFtAsset: FtAsset) => {
      const debouncedFunction = debounce(async () => {
        try {
          setTaskDataLoading(true);
          paymentStore.wrappedFetchAndSetTaskData?.({
            chainIndex: toReceiveFtAsset?.chainIndex!,
            assetAddress: toReceiveFtAsset?.address!,
          });
        } catch (e) {
          console.warn(e);
        } finally {
          setTaskDataLoading(false);
        }
      }, 0);
      debouncedFunction();
    },
    [paymentStore, toReceiveFtAsset?.address, toReceiveFtAsset?.chainIndex]
  );

  const paymentKeyValueListCtx = useMemo(() => {
    if (taskDataLoading) {
      return {
        receiver: (
          <>
            <AvatarIcon src={AvatarSvg} />
            <CommonText>{userStore.accountNameWithOs}</CommonText>
          </>
        ),
        totalPayment: <EllipsisPlaceholder />,
        myAvailable: <EllipsisPlaceholder />,
        status: (
          <>
            <AvatarIcon src={PendingSvg} />
            <WarningText>{t("Waiting for deposit...")}</WarningText>
          </>
        ),
      };
    }
    return {
      receiver: (
        <>
          <AvatarIcon src={AvatarSvg} />
          <CommonText>{userStore.accountNameWithOs}</CommonText>
        </>
      ),
      totalPayment: (
        <CommonText>
          {new KontosNumber(
            taskData.maxRequiredUsdCost,
            DEFAULT_DECIMAL
          ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
        </CommonText>
      ),
      myAvailable: (
        <CommonText>
          {new KontosNumber(
            taskData.maxAvailableUsdForPayment,
            DEFAULT_DECIMAL
          ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
        </CommonText>
      ),
      status: (
        <>
          <AvatarIcon src={status === "pending" ? PendingSvg : SuccessSvg} />
          {status === "pending" ? (
            <WarningText>{t("Waiting for deposit...")}</WarningText>
          ) : (
            <SuccessText>{t("Deposit successful")}</SuccessText>
          )}
        </>
      ),
    };
  }, [
    status,
    taskData.maxAvailableUsdForPayment,
    taskData.maxRequiredUsdCost,
    taskDataLoading,
    userStore.accountName,
  ]);

  useEffect(() => {
    // const required = new KontosNumber(
    //   taskData?.totalRequiredUsdCost,
    //   DEFAULT_DECIMAL
    // );
    // const available = new KontosNumber(
    //   taskData?.maxAvailableUsdForPayment,
    //   DEFAULT_DECIMAL
    // );
    if (taskData?.newTasksByPaymentPlans?.length > 0) {
      setStatus("success");
      setAllowChangeReceive(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setTimeout(() => {
        paymentStore.setPaymentMode(PaymentMode.PaymentPlan);
        onSuccess();
      }, 2000);
    } else {
      setStatus("pending");
    }
  }, [onSuccess, paymentStore, taskData?.newTasksByPaymentPlans?.length]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      try {
        setAssetLoading(true);
        let toSearchToken = TRADE_DEFAULT_BRIDGE_FTASSET;
        if (
          isSameFtAsset(
            paymentStore.paymentTargetFtAsset,
            TRADE_DEFAULT_BRIDGE_FTASSET
          )
        ) {
          toSearchToken = TRADE_DEFAULT_BRIDGE_FTASSET_2;
        }
        const respToken = await getToken(
          toSearchToken.chainIndex,
          toSearchToken.address
        );
        const formattedData = transRespTokenToFtAsset(respToken);
        paymentStore.setPaymentBridgeFtAsset(formattedData);
        setToReceiveFtAsset(formattedData);
      } catch (e) {
        console.warn(e);
      } finally {
        setAssetLoading(false);
      }
    };

    if (
      !paymentStore.paymentBridgeFtAsset ||
      isSameFtAsset(
        paymentStore.paymentBridgeFtAsset,
        paymentStore.paymentTargetFtAsset
      )
    ) {
      fetchAndSetData();
    } else {
      setAssetLoading(false);
    }
  }, [paymentStore]);

  useEffect(() => {
    if (toReceiveFtAsset) {
      timerRef.current = setInterval(() => {
        executeTaskData(paymentStore.paymentTargetFtAsset!);
      }, REFRESH_INTERVAL);
      executeTaskData(paymentStore.paymentTargetFtAsset!);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toReceiveFtAsset]);

  useEffect(() => {
    if (toReceiveChainIndex) {
    }
  }, [toReceiveChainIndex]);

  return (
    <Container>
      {choosing === false && (
        <MainContainer>
          <PaymentChargeItem
            currentFtAsset={toReceiveFtAsset}
            text={t("You need to deposit") as string}
            amountText={toReceiveAmountText}
            isFixed={!allowChangeReceive}
            assetLoading={assetLoading}
            taskDataLoading={taskDataLoading}
            onClick={() => {
              onChoosing(true);
            }}
          />

          <Divider isDashed={true} top={16} bottom={16} />

          <KeyValueListContainer>
            <PaymentKeyValueList
              receiver={paymentKeyValueListCtx?.receiver}
              totalPayment={paymentKeyValueListCtx?.totalPayment}
              myAvailable={paymentKeyValueListCtx?.myAvailable}
              status={paymentKeyValueListCtx?.status}
            />
          </KeyValueListContainer>

          <FloatingBtnContainer>
            <FloatingButton
              onClick={() => {
                const url =
                  KONTOS_GATE +
                  "?account=" +
                  userStore.accountName +
                  "&chainIndex=" +
                  toReceiveChainIndex;

                openUrl(url);
              }}
              fixedIcon={OpenUrlIcon}
            >
              {t("Open on Kontos Bridge")}
            </FloatingButton>
          </FloatingBtnContainer>
        </MainContainer>
      )}

      {choosing === true && (
        <ToBuyAssetSelector
          onChoose={(item) => {
            if (isSameFtAsset(item, paymentStore.paymentTargetFtAsset!)) {
              toast({
                text: t(
                  "Please do not receive transfers of the same type as the asset you intend to purchase."
                ),
                type: "warning",
              });
              return;
            }
            onChoosing(false);
            setToReceiveFtAsset(item);
          }}
          chains={chainStore.chains.filter(
            (chain) => chain.chainIndex !== KONTOS_CHAIN_INDEX
          )}
          hasRecommend={true}
          hasAll={true}
          hasFavorites={true}
          showAssetType={ShowAssetType.Detail}
          initAssetType={NonChainIndex.Recommend}
          isWhitelist
        />
      )}
    </Container>
  );
};

export default PaymentBridge;
