import AddressInputSearch from "src/components/input-search/AddressInputSearch";
import styled from "styled-components";
import { useCallback, useEffect } from "react";
import { t } from "i18next";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  padding: 10px 16px;
`;

interface Props {
  rawAddress?: string;
  onDone: (name: string | undefined) => void;
}

const EditGuardiansSearch: React.FC<Props> = ({ rawAddress, onDone }) => {
  const { userStore } = useStores();

  useEffect(() => {
    rawAddress && onDone(rawAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputFinish = useCallback(
    (isSuccess: boolean, value: string) => {
      if (isSuccess) {
        onDone(value.replaceAll(".os", ""));
      } else {
        console.log(";;;");
        onDone(undefined);
      }
    },
    [onDone]
  );

  return (
    <Container>
      <AddressInputSearch
        rawAddress={rawAddress}
        allowEOA={false}
        userWallet={userStore.accountInfo?.name}
        onFinish={handleInputFinish}
        placeholder={t("Search kontos account") as string}
        initNumber={25}
      />
    </Container>
  );
};

export default EditGuardiansSearch;
