import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import allIcon from "src/components/selects/all.svg";
import recommendIcon from "src/components/selects/recommend.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import { ChainConfig } from "@/type/zkkontos";
import ImageWithFallback from "../images/ImageWithFallback";
import { t } from "i18next";
import favoriteIcon from "src/assets/icons/dapp/favorite.svg";
import memberIcon from "src/assets/icons/member.svg";

const Wrapper = styled.div`
  text-align: center;

  .top-16 {
    margin-top: 16px;
  }
`;

const Container = styled.div<{ $isExpanded: boolean }>`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  flex-wrap: ${(props) => (props.$isExpanded ? "wrap" : "nowrap")};

  ::-webkit-scrollbar {
    height: 0;
  }
  ::-ms-scrollbar {
    height: 0;
  }
`;

const ElementContainer = styled.div<{ $isSelected: boolean }>`
  margin-right: 10px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  padding: 6px;
  height: 34px;
  border-radius: 99px;
  border: 1px solid
    ${(props) =>
      props.$isSelected
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_50};
  background: ${(props) => props.theme.colors.__white};
  box-sizing: border-box;

  &:hover {
    border-color: ${(props) => props.theme.colors.__kontos_blue};
    //background-color: ${(props) => props.theme.colors.__white};
  }

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const Icon = styled.img`
  width: 22px;
  border-radius: 50%;
`;

const Text = styled.div`
  margin: 0 6px;
  color: #1c1b1f;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const ToggleButton = styled.button<{ $isExpanded: boolean }>`
  background-color: transparent;
  border: none;

  font-size: 14px;
  color: ${(props) => props.theme.colors.__kontos_blue};
  margin: 14px 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export enum NonChainIndex {
  Favorites = "favorites",
  Recommend = "recommend",
  MyAssets = "myAssets",
  All = "all",
}

export type ChainIndex = NonChainIndex | string;

interface ChainItem {
  chainSymbol: string;
  chainIndex: string;
  imageURL: any;
}

const HorizontalScrollableElements: React.FC<{
  chains: ChainConfig[];
  chainIndex: ChainIndex;
  onSelect: (chainIndex: string) => void;
  hasRecommend?: boolean;
  hasAll?: boolean;
  hasFavorites?: boolean;
  hasMyAssets?: boolean;
}> = ({
  chains,
  onSelect,
  chainIndex,
  hasRecommend = false,
  hasAll = false,
  hasFavorites = false,
  hasMyAssets = false,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const selectedRef = useRef<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSelect = (chainIndex: string) => {
    onSelect(chainIndex);
  };

  useEffect(() => {
    const checkScroll = () => {
      if (scrollRef.current) {
        setHasHorizontalScroll(
          scrollRef.current.scrollWidth > scrollRef.current.clientWidth
        );
      }
    };

    checkScroll();

    window.addEventListener("resize", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
    };
  }, [chains]);

  useEffect(() => {
    if (!isExpanded && chainIndex && scrollRef.current && selectedRef.current) {
      const container = scrollRef.current;
      const selected = selectedRef.current;
      const containerWidth = container.offsetWidth;
      const selectedOffsetLeft = selected.offsetLeft;
      const selectedWidth = selected.offsetWidth;

      container.scrollLeft =
        selectedOffsetLeft - containerWidth / 2 + selectedWidth / 2;
    }
  }, [chainIndex, isExpanded]);

  const displayItems = useMemo(() => {
    let arr: ChainItem[] = [];
    if (hasMyAssets)
      arr.push({
        chainSymbol: t("My Assets"),
        chainIndex: NonChainIndex.MyAssets,
        imageURL: memberIcon,
      });
    if (hasFavorites)
      arr.push({
        chainSymbol: "",
        chainIndex: NonChainIndex.Favorites,
        imageURL: favoriteIcon,
      });
    if (hasRecommend)
      arr.push({
        chainSymbol: t("Recommend"),
        chainIndex: NonChainIndex.Recommend,
        imageURL: recommendIcon,
      });
    if (hasAll)
      arr.push({
        chainSymbol: t("All"),
        chainIndex: NonChainIndex.All,
        imageURL: allIcon,
      });
    return arr.concat(
      chains.map((chain) => {
        return {
          chainSymbol: chain.chainSymbol,
          chainIndex: chain.chainIndex,
          imageURL: chain.imageURL,
        };
      })
    );
  }, [chains, hasAll, hasFavorites, hasMyAssets, hasRecommend]);

  return (
    <Wrapper>
      <Container $isExpanded={isExpanded} ref={scrollRef}>
        {displayItems.map((item) => (
          <ElementContainer
            key={item.chainIndex}
            $isSelected={chainIndex === item.chainIndex}
            ref={chainIndex === item.chainIndex ? selectedRef : null}
            onClick={() => {
              handleSelect(item.chainIndex);
            }}
          >
            <ImageWithFallback
              src={item.imageURL || DefaultChainSvg}
              fallbackSrc={DefaultChainSvg}
              StyledImg={Icon}
            />
            {item.chainSymbol !== "" && <Text>{item.chainSymbol}</Text>}
          </ElementContainer>
        ))}
      </Container>

      {hasHorizontalScroll && (
        <ToggleButton onClick={toggleExpansion} $isExpanded={isExpanded}>
          {isExpanded ? t("Show less") : t("Show more")}
        </ToggleButton>
      )}

      {!hasHorizontalScroll && <div className="top-16"></div>}
    </Wrapper>
  );
};

export default HorizontalScrollableElements;
