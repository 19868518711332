import styled from "styled-components";
import tipsIcon from "src/assets/icons/tips.svg";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { useTranslation } from "react-i18next";
import { TokenOrChainIcon } from "src/components/icons/TokenOrChainIcon";
import { useCallback, useState } from "react";
import arrowImg from "src/assets/icons/trade/swap/swap-helper.svg";
import questionIco from "src/assets/icons/trade/question.svg";
import { EllipsisPlaceholderV2 } from "src/components/load-placeholder/EllipsisPlaceholder";
import Floater from "react-floater";
import { TipsSwapMaxAvailableV2 } from "src/components/tips/TipsText";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const HelperIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const HintBox = styled.div`
  white-space: nowrap;
  height: 32px;
  line-height: 16px;
  position: relative;
  padding: 8px 10px;
  border-radius: 8px;
  background: var(--White, #fff);

  display: flex;
  align-items: center;
  gap: 4px;

  color: var(--Deep-400, #80868f);
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const Arrow = styled.img`
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);

  width: 8px;
  height: 16px;
`;

const DeepText = styled.span`
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const MaxButton = styled.span`
  color: var(--Kontos-Blue, #413dec);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }

  &:active {
    opacity: 0.9;
  }
`;

const TipIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: help;
`;

interface IProps {
  inputLoading: boolean;
}

export const AvailableHelper: React.FC<IProps> = observer(
  ({ inputLoading }) => {
    const { t } = useTranslation();
    const { swapStore } = useStores();
    const [showTips, setShowTips] = useState<boolean>(false);

    const handleMaxClick = useCallback(() => {
      if (swapStore.toSwapFtAssetMaxAvailable) {
        swapStore.setToSwapFtAssetQuantity(swapStore.toSwapFtAssetMaxAvailable);
        return;
      }
      if (swapStore.toSwapFtAssetBalance) {
        swapStore.setToSwapFtAssetQuantity(swapStore.toSwapFtAssetBalance);
      }
    }, [swapStore]);

    return (
      <Container>
        <HelperIcon src={tipsIcon} />

        <HintBox>
          {!swapStore.toSwapFtAsset || !swapStore.toReceiveFtAsset ? (
            t("Please select the assets")
          ) : inputLoading || !swapStore.toSwapFtAssetMaxAvailable ? (
            <EllipsisPlaceholderV2 style={{ margin: "0 20px" }} />
          ) : (
            <>
              <span>{t("Available")}:</span>
              <TokenOrChainIcon
                type={"token"}
                size={16}
                src={swapStore.toSwapFtAsset.imageUrl}
              />
              <DeepText>
                {swapStore.toSwapFtAssetMaxAvailable.toFormat() || "-"}
              </DeepText>
              {swapStore.toSwapFtAssetMaxAvailable.gt(0) && (
                <MaxButton onClick={handleMaxClick}>{t("Max")}</MaxButton>
              )}

              <TipIcon
                className="swap-question"
                src={questionIco}
                alt="?"
                onMouseEnter={() => setShowTips(true)}
                onMouseLeave={() => setShowTips(false)}
              />
            </>
          )}

          <Arrow src={arrowImg} />
        </HintBox>

        {showTips && (
          <Floater
            eventDelay={0}
            open={showTips}
            component={TipsSwapMaxAvailableV2}
            target={".swap-question"}
          />
        )}
      </Container>
    );
  }
);
