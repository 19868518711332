import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { SecurityTipType } from "src/components/tips/SecurityTip";

export class TipStore {
  rootStore: RootStore;
  assetTip?: SecurityTipType;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setAssetTip = (type?: SecurityTipType) => {
    this.assetTip = type;
  };

  getAssetTip = (): SecurityTipType | undefined => {
    return this.assetTip;
  };
}
