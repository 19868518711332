import styled from "styled-components";
import infoIcon from "src/assets/icons/trade/info.svg";
import infoActiveIcon from "src/assets/icons/trade/info-active.svg";
import editIcon from "src/assets/icons/edit2.svg";
import editActiveIcon from "src/assets/icons/edit2-active.svg";
import deleteIcon from "src/assets/icons/delete2.svg";
import deleteActiveIcon from "src/assets/icons/delete2-active.svg";
import managePaymentIcon from "src/assets/icons/trade/payment-manage.svg";
import managePaymentActiveIcon from "src/assets/icons/trade/payment-manage-active.svg";
import { forwardRef } from "react";

const Container = styled.div<{
  icon: any;
  activeIcon: any;
  size?: number;
}>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: ${(props) => `url(${props.icon})`};
  background-repeat: no-repeat;
  background-size: ${(props) => `${props.size}px ${props.size}px`};
  background-position: center center;

  @media (hover: hover) {
    &:hover {
      background-image: ${(props) => `url(${props.activeIcon})`};
    }
  }
  &:active {
    background-image: ${(props) => `url(${props.activeIcon})`};
  }
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  size?: number;
  icon?: any;
  activeIcon?: any;
  type?: "info" | "edit" | "delete" | "paymentAssetsManage";
  onClick?: (e: React.MouseEvent) => void;
}

export const SingleIconButton = forwardRef<HTMLDivElement, IProps>(
  (
    { className, style, size = 16, type, icon, activeIcon, onClick }: IProps,
    ref
  ) => {
    let realIcon = null;
    let realActiveIcon = null;

    if (icon && activeIcon) {
      realIcon = icon;
      realActiveIcon = activeIcon;
    } else if (type) {
      switch (type) {
        case "info":
          realIcon = infoIcon;
          realActiveIcon = infoActiveIcon;
          break;
        case "edit":
          realIcon = editIcon;
          realActiveIcon = editActiveIcon;
          break;
        case "delete":
          realIcon = deleteIcon;
          realActiveIcon = deleteActiveIcon;
          break;
        case "paymentAssetsManage":
          realIcon = managePaymentIcon;
          realActiveIcon = managePaymentActiveIcon;
          break;
        default:
          break;
      }
    }

    return (
      <Container
        ref={ref}
        className={className}
        style={style}
        size={size}
        icon={realIcon}
        activeIcon={realActiveIcon}
        onClick={(e) => onClick?.(e)}
      />
    );
  }
);
