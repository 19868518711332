import { parseApiErrorMessage } from "src/utils/zkkontosHelper";
import * as WalletApi from "src/apis/wallet-apis";

export const uploadErrorOnce = async (
  account: string,
  apiUrl: string,
  params: Object,
  error: any,
  retryCount: number = 2
) => {
  try {
    const errMessage =
      error instanceof Error
        ? parseApiErrorMessage(error).message
        : error?.response
          ? `${error.response.status}: ${error.response.statusText}`
          : error?.message
            ? error.message
            : JSON.stringify(error);
    const reqParams = JSON.stringify(params);
    await WalletApi.callErrorCatch({
      account,
      apiUrl,
      reqParams,
      errMessage,
    });
  } catch (e) {
    if (retryCount > 0) {
      uploadErrorOnce(account, apiUrl, params, error, retryCount - 1);
    }
  }
};
