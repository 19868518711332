import { GateFiDisplayModeEnum } from "./types";
import { OVERLAY_ID } from "./layouts";
import { domControlService } from "./services/DOMControlService";
import { isValidUrl } from "./utils";
var ConfigValidator = (function () {
    function ConfigValidator() {
    }
    ConfigValidator.prototype.validate = function (config) {
        var _a;
        if (!config.merchantId && typeof config.merchantId !== "string") {
            return "merchantId should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.isSandbox) && typeof config.isSandbox !== "boolean") {
            return "isSandbox should be a boolean";
        }
        if (!Object.values(GateFiDisplayModeEnum).includes(config.displayMode)) {
            return "displayMode: ".concat(config.displayMode, " is not exist");
        }
        if (config.displayMode === GateFiDisplayModeEnum.Embedded && !config.nodeSelector) {
            return "nodeSelector is a required field for embedded mode";
        }
        if (config.displayMode === GateFiDisplayModeEnum.Overlay && domControlService.isNodeExist("#".concat(OVERLAY_ID))) {
            return "GateFiSDK with 'overlay' display mode already exist";
        }
        if (config.nodeSelector && !domControlService.isNodeExist(config.nodeSelector)) {
            return "node with id ".concat(config.nodeSelector, " does not exist");
        }
        if (config.successUrl && !isValidUrl(config.successUrl)) {
            return "successUrl should be a valid URL";
        }
        if (config.cancelUrl && !isValidUrl(config.cancelUrl)) {
            return "cancelUrl should be a valid URL";
        }
        if (config.declineUrl && !isValidUrl(config.declineUrl)) {
            return "declineUrl should be a valid URL";
        }
        if (config.inprocessUrl && !isValidUrl(config.inprocessUrl)) {
            return "inprocessUrl should be a valid URL";
        }
        if ((config === null || config === void 0 ? void 0 : config.externalId) && typeof config.externalId !== "string") {
            return "externalId should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.externalId) && config.externalId.length > 64) {
            return "externalId length should not be more 64 chars";
        }
        if ((config === null || config === void 0 ? void 0 : config.hideAsset) && typeof config.hideAsset !== "boolean") {
            return "hideAsset should be a boolean";
        }
        if (config.assetConfirmation) {
            if (typeof config.assetConfirmation !== "string")
                return "assetConfirmation should be a string";
            if (config.assetConfirmation.length > 8)
                return "assetConfirmation should be less 8 chars";
        }
        if (config.networkConfirmation && typeof config.networkConfirmation !== "string") {
            return "networkConfirmation should be a string";
        }
        if (config.walletConfirmation && typeof config.walletConfirmation !== "string") {
            return "walletConfirmation should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.availablePayments) && !Array.isArray(config === null || config === void 0 ? void 0 : config.availablePayments)) {
            return "availablePayments should be an array of strings";
        }
        if ((config === null || config === void 0 ? void 0 : config.payment) && typeof (config === null || config === void 0 ? void 0 : config.payment) !== "string") {
            return "payment should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.region) && typeof (config === null || config === void 0 ? void 0 : config.region) !== "string") {
            return "region should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.lang) && typeof (config === null || config === void 0 ? void 0 : config.lang) !== "string") {
            return "lang should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.hideBrand) && typeof config.hideBrand !== "boolean") {
            return "hideBrand should be a boolean";
        }
        if ((config === null || config === void 0 ? void 0 : config.redirectUrl) && typeof (config === null || config === void 0 ? void 0 : config.redirectUrl) !== "string") {
            return "redirectUrl should be a string";
        }
        if (config === null || config === void 0 ? void 0 : config.customTexts) {
            if ((_a = config.customTexts) === null || _a === void 0 ? void 0 : _a.backToButtonLabel) {
                if (typeof config.customTexts.backToButtonLabel !== "string") {
                    return "backToButtonLabel should be a string";
                }
                if (config.customTexts.backToButtonLabel.length > 30) {
                    return "backToButtonLabel length should not be more 30 chars";
                }
            }
        }
        return null;
    };
    return ConfigValidator;
}());
export { ConfigValidator };
export var configValidator = new ConfigValidator();
