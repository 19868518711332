import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useState } from "react";
import kontosAvatarGoogle from "src/assets/images/kontos-avatar-google.svg";
import deleteIco from "src/assets/icons/delete1.svg";
import InputWithIcons from "src/components/input/KontosInputV2";
import HorizontalLine from "src/components/line/HorizontalLine";
import AddNewBtn from "src/components/start/AddNewBtn";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import deleteIcoGrey from "src/assets/icons/delete-grey.svg";
import TransformingIcon from "src/components/icons/TransformingIcon";
import { Email, isValidGoogleEmail, pushEmail } from "src/utils/helper";
import { Trans } from "react-i18next";
import { BottomSheet } from "../bottom-sheet/BottomSheet";
import { EmailSelector } from "./EmailSelector";
import { DirectEmailInputPopup } from "./popups/DirectEmailInputPopup";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { EmailVerificationPopup } from "./popups/EmailVerificationPopup";
import RegisterStatusIcon from "./RegisterStatusIcon";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .description {
      color: var(--Deep-800, #1a2535);
      text-align: center;

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */

      span {
        color: var(--Kontos-Blue, #413dec);
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
    }

    .kontosAvatarGoogle {
      width: 89px;
      height: 86px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .buttonWrap {
    width: calc(100% - 40px);
    position: fixed;
    bottom: 16px;
  }
`;

type IProps = {
  submitFunction: () => void;
  userEmailArr: Email[];
  setUserEmailArr: (arr: Email[]) => void;
  kontosName: string;
  parentRef: React.RefObject<HTMLDivElement | null>;
  domNode?: Element;
};

const AddSecurityEmail: React.FC<IProps> = ({
  submitFunction,
  userEmailArr,
  setUserEmailArr = () => {},
  kontosName,
  domNode,
  parentRef,
}) => {
  const [showEmailSelector, setShowEmailSelector] = useState<boolean>(false);
  const [showDirectWarningPopup, setShowDirectWarningPopup] =
    useState<boolean>(false);
  const [showEmailVerificationPopup, setShowEmailVerificationPopup] =
    useState<boolean>(false);

  useMouseDownOutside({
    ref: parentRef,
    callback: () => {
      setShowEmailSelector(false);
    },
    shouldClose: true,
  });

  return (
    <Wrapper>
      <div className={"main"}>
        <img
          className={"kontosAvatarGoogle"}
          src={kontosAvatarGoogle}
          alt="kontosAvatarGoogle"
        />
        <div className={"description"}>
          <Trans
            i18nKey={
              "trans-You can add more Gmail addresses to your kontos account"
            }
            kontosName={kontosName}
          >
            You can add more Gmail addresses to your kontos account{" "}
            <span>{{ kontosName } as any}.os</span> as an additional security
            measure
          </Trans>
        </div>
        <div className={"lineWrap"}>
          <HorizontalLine />
        </div>
        <div className={"inputWrap"}>
          {/* {userEmailArr[0] && (
            <InputWithIcons
              leftIcon={<SecurityGoogle />}
              rightIcon={<Lock />}
              placeholder={t("")}
              value={userEmailArr[0]}
              disabled={true}
            />
          )} */}

          {userEmailArr.map((item, index) => (
            <InputWithIcons
              key={item.address}
              leftIcon={<RegisterStatusIcon status={"success"} type="email" />}
              rightIcon={
                <TransformingIcon
                  defaultIcon={deleteIcoGrey}
                  hoverIcon={deleteIco}
                  onClick={() => {
                    setUserEmailArr(
                      userEmailArr.filter(
                        (item1) => item1.address !== item.address
                      )
                    );
                  }}
                />
              }
              placeholder={t("")}
              value={item.address}
              inputStyle={{
                color: "var(--Deep-800, #1A2535)",
                borderColor: item.isVerified
                  ? "var(--Success, #10CE5C)"
                  : "var(--Deep-100, #CCCFD2)",
              }}
              readonly
              disabled
            />
          ))}

          {userEmailArr.length < 5 && (
            <AddNewBtn onClick={() => setShowEmailSelector(true)} />
          )}

          {/* <GoogleLoginBtn
            loginSuccessFun={(email) => {
              if (!email || !isValidGoogleEmail(email)) return;
              setUserEmailArr(pushEmail(email, userEmailArr));
            }}
            wrapperStyle={{
              marginTop: "16px",
            }}
          >
            <AddNewBtn />
          </GoogleLoginBtn> */}
        </div>
      </div>
      <div className={"buttonWrap"}>
        <Button
          // disabled={userEmailArr.length === 0}
          onClick={() => {
            if (userEmailArr.length === 0) {
              setShowEmailSelector(true);
              return;
            }
            submitFunction();
          }}
          text={t("Next")}
          height={"56px"}
        />
      </div>

      {/* Show email setting methods */}
      <BottomSheet
        isOpen={showEmailSelector}
        onClose={() => setShowEmailSelector(false)}
        mountPoint={domNode}
        customHeight={330}
        disableScrollLocking={true}
      >
        <EmailSelector
          googleAddCallback={(email) => {
            if (!email || !isValidGoogleEmail(email)) return;
            setUserEmailArr(pushEmail(email, userEmailArr, true));
            setShowEmailSelector(false);
          }}
          onVerifyMethodChosen={function (): void {
            setShowEmailSelector(false);
            setTimeout(() => {
              setShowEmailVerificationPopup(true);
            }, 500);
          }}
          onDirectMethodChosen={() => {
            setShowEmailSelector(false);
            setTimeout(() => {
              setShowDirectWarningPopup(true);
            }, 500);
          }}
        />
      </BottomSheet>

      {showDirectWarningPopup && (
        <DirectEmailInputPopup
          existedEmails={userEmailArr.map((item) => item.address)}
          onAddEmail={(email) => {
            if (!email || !isValidGoogleEmail(email)) return;
            setUserEmailArr(pushEmail(email, userEmailArr, false));
          }}
          onClose={() => {
            setShowDirectWarningPopup(false);
          }}
        />
      )}

      {showEmailVerificationPopup && (
        <EmailVerificationPopup
          existedEmails={userEmailArr.map((item) => item.address)}
          accountName={kontosName}
          onAddEmail={(email) => {
            if (!email || !isValidGoogleEmail(email)) return;
            setUserEmailArr(pushEmail(email, userEmailArr, true));
          }}
          onClose={() => {
            setShowEmailVerificationPopup(false);
          }}
        />
      )}
    </Wrapper>
  );
};

export default observer(AddSecurityEmail);
