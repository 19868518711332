import {
  CTX_EXPIRE_TIME,
  CTX_EXPIRE_TIME_LONG,
  KONTOS_BOT_URL,
} from "src/config";
import {
  AccountBalancesRes,
  AssetsRes,
  KontosCtx,
  KontosNotify,
} from "./types";
import { newFetch } from "src/network/fetch";
import { longExpirationCmds } from "src/router/router-map";

export const command_ctx = async (ctx_key: string): Promise<KontosCtx> => {
  const url = `${KONTOS_BOT_URL}command_ctx?ctx_key=${ctx_key}`;

  const ctxPartial = await newFetch(url, {});
  console.log("rawCtx", ctxPartial);

  let expire_at = Date.now() + CTX_EXPIRE_TIME;
  if (longExpirationCmds.includes(ctxPartial.cmd_type)) {
    expire_at = Date.now() + CTX_EXPIRE_TIME_LONG;
  }

  return {
    ctx_key: ctx_key,
    isSuccess: false,
    isFail: false,
    expire_at,
    ...ctxPartial,
  };
};

export const notify = async (
  notify: KontosNotify,
  needToSaveStatus: boolean = true
): Promise<void> => {
  // if (needToSaveStatus && notify.msg_status === "success") {
  //   statusKeeper.updateKontosCtxSuccess(true);
  // } else if (needToSaveStatus && notify.msg_status === "fail") {
  //   statusKeeper.updateKontosCtxFail(true);
  // }
  const url = `${KONTOS_BOT_URL}notify`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(notify),
  };
  try {
    await newFetch(url, config);
  } catch (e) {
    throw e;
  }
};

// export const callAccountbalances = async (): Promise<AccountBalancesRes[]> => {
//   const url = `https://explorer-v2.kontos.io/api/v1/asset/accountbalances`;
//   const config = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ account: "kontos" }),
//   };
//   const response = await newFetch(url, config);

//   if (!response || !Array.isArray(response)) {
//     throw new Error("Invalid response from the server");
//   }

//   return response as AccountBalancesRes[];
// };

export const callAccountbalances = async (): Promise<AccountBalancesRes[]> => {
  const url = `https://explorer-v2.kontos.io/api/v1/asset/accountbalances`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ account: "kontos" }),
  };

  const MAX_RETRIES = 7;
  let attempts = 0;

  while (attempts < MAX_RETRIES) {
    const response = await newFetch(url, config);
    attempts++;

    if (!response || !Array.isArray(response)) {
      throw new Error("Invalid response from the server");
    }

    if (response[0]?.Balance !== "<nil>") {
      return response as AccountBalancesRes[];
    }
  }

  throw new Error("Max retries reached. Balance is still <nil>.");
};

export const callAssets = async (): Promise<AssetsRes[]> => {
  const url = `https://explorer-v2.kontos.io/api/v1/asset/assets`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await newFetch(url, config);

  if (!response || !Array.isArray(response)) {
    throw new Error("Invalid response from the server");
  }

  return response as AssetsRes[];
};
