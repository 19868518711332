import { theme } from "src/App";
import styled from "styled-components";

const StyledSvg = styled.svg<{ $isAvtive: boolean; $disable: boolean }>`
  rect {
    fill: ${(props) =>
  props.$isAvtive
    ? props.theme.colors.__kontos_blue
    : props.theme.colors.__deep_100};
  }


  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      rect {
        fill: ${(props) =>
  !props.$disable && props.theme.colors.__kontos_blue};
      }
    }
  }
`;

interface IProps {
  className?: string;
  size?: number;
  isActive?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

const MarketsIcon: React.FC<IProps> = ({
                                      className,
                                      size,
                                      isActive = false,
                                      disable = false,
                                      onClick,
                                    }) => {
  return (
    <StyledSvg
      $isAvtive={isActive}
      $disable={disable}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size || 28}
      height={size || 28}
      viewBox="0 0 28 28"
      fill="none"
    >
      <g id="Component 13">
        <g id="Group 1321319653">
          <rect id="Rectangle 34624895" x="3.66699" y="11" width="6" height="14" fill="#CCCFD2"/>
          <rect id="Rectangle 34624896" x="11.667" y="4" width="6" height="21" fill="#B2B6BC"/>
          <rect id="Rectangle 34624897" x="19.667" y="7" width="6" height="18" fill="#CCCFD2"/>
        </g>
      </g>
    </StyledSvg>
  );
};

export default MarketsIcon;
