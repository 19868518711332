import React, { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingGif = styled.img`
  text-align: center;
  height: 20px;
  width: 100px;
`;

interface Props {
  style?: React.CSSProperties;
  className?: string;
  loadingPlaceholder?: ReactNode;
}

export const LocalLoading: React.FC<Props> = ({
  style,
  className,
  loadingPlaceholder,
}) => {
  return (
    <Wrapper style={{ ...style }} className={className}>
      {loadingPlaceholder ? (
        loadingPlaceholder
      ) : (
        <LoadingGif src="/static/loading.gif" alt="Loading..." />
      )}
    </Wrapper>
  );
};
