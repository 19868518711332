import { loadingStore } from "src/store/loadingStore";
import { useEffect } from "react";

type UseClickOutsideParams = {
  ref: React.RefObject<HTMLElement | null>;
  callback: () => void;
  shouldExecute: boolean;
  deps?: React.DependencyList;
};

const useClickOutside = ({
  ref,
  callback,
  shouldExecute,
  deps,
}: UseClickOutsideParams) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        shouldExecute &&
        !loadingStore.isLoading
      ) {
        callback();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, callback, shouldExecute, ...(deps ?? [])]);
};

export default useClickOutside;
