import styled from "styled-components";
import React, { useCallback, useRef, useState } from "react";
import backIco from "src/assets/icons/left-blue-arrow.svg";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_ENERGYBOOSTING,
  ROUTE_ENERGYBOOSTING_LEADERBOARD,
} from "src/router/router-config";

export const EB_HEADER_HEIGHT = "45px";
export const EB_HEADER_MARGIN = "16px";
const ANIMATION_DURATION = 100; // ms

const Wrapper = styled.div`
  /* position: fixed;
  top: 0;
  left: 0; */
  height: ${EB_HEADER_HEIGHT};

  .ant-drawer-content-wrapper {
    transition: all ${ANIMATION_DURATION}ms !important;
  }

  width: 100%;
  padding: 13px 24px 11px 24px;
  margin-bottom: ${EB_HEADER_MARGIN};
  background: transparent;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;

    .left-box {
      z-index: 2;
      cursor: pointer;
      display: flex;
      justify-content: center;

      &:active {
        opacity: 0.9;
      }

      &:hover {
        opacity: 0.9;
      }

      .back-icon {
        width: 16px;
        height: 18px;
      }

      .left-text {
        overflow: hidden;
        color: var(--Kontos-Blue, #413dec);
        text-align: center;
        text-overflow: ellipsis;
        font-family: "HarmonyOS Sans Bold";
        font-size: 16px;
        line-height: 20px; /* 125% */
      }
    }
  }
`;

interface Sticky {
  $isSticky: boolean;
  height?: number;
  $darkMode?: boolean;
}

const StickyMenu = styled.div<Sticky>`
  z-index: 9999;
  transition: height 0.2s ease-in-out;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  .menu__placeholder {
    width: 30px;
    height: 21px;
  }

  .menu__item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .menu__item-line {
      width: 21px;
      height: 2px;
      background-color: ${(props) => (props.$darkMode ? "white" : "#1a2535")};
      transform-origin: left;
      border-radius: 99px;
    }

    @keyframes line1-animation-open {
      to {
        transform: translate(-5px, 13.5px) rotate(-135deg);
      }
    }

    @keyframes line2-animation-open {
      to {
        width: 0;
      }
    }

    @keyframes line3-animation-open {
      to {
        transform: translate(-5px, -13.5px) rotate(135deg);
      }
    }

    .menu__item-line-1-open {
      animation: line1-animation-open ${ANIMATION_DURATION * 1.5}ms forwards;
    }

    .menu__item-line-2-open {
      animation: line2-animation-open ${ANIMATION_DURATION}ms linear forwards;
    }

    .menu__item-line-3-open {
      animation: line3-animation-open ${ANIMATION_DURATION * 1.5}ms forwards;
    }

    @keyframes line1-animation-close {
      from {
        transform: translate(-5px, 13.5px) rotate(-135deg);
      }
      to {
        transform: none;
      }
    }

    @keyframes line2-animation-close {
      0% {
        width: 0;
      }
      to {
        transform: none;
      }
    }

    @keyframes line3-animation-close {
      from {
        transform: translate(-5px, -13.5px) rotate(135deg);
      }
      to {
        transform: none;
      }
    }

    .menu__item-line-1-close {
      animation: line1-animation-close ${ANIMATION_DURATION * 1.5}ms forwards;
    }

    .menu__item-line-2-close {
      animation: line2-animation-close ${ANIMATION_DURATION}ms linear forwards;
    }

    .menu__item-line-3-close {
      animation: line3-animation-close ${ANIMATION_DURATION * 1.5}ms forwards;
    }
  }
`;

const DrawerContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 52px;
  padding-right: 23px;
  font-size: 18px;
  font-family: "HarmonyOS Sans Bold" !important;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DrawerA = styled.a<{ $darkMode?: boolean }>`
  font-family: "HarmonyOS Sans Bold" !important;
  color: ${(props) =>
    props.$darkMode ? "var(--White, #FFF)" : "var(--Deep-800, #1a2535)"};

  &:hover {
    color: #413dec;
  }
`;

export const DrawerSeperator = styled.div`
  margin: 19px 0;
  background: #cccfd2;
  float: right;
  width: 54px;
  height: 1.5px;
`;

type IHeaderProps = {
  headStyle?: React.CSSProperties;
  leftTitle?: string;
  leftOnClick?: () => void;
  rightTitle?: string;
  onViewBoostHistory?: () => void;
  enableDarkMode?: boolean;
};

const EventHeader: React.FC<IHeaderProps> = (
  {
    headStyle,
    leftTitle,
    leftOnClick,
    rightTitle,
    onViewBoostHistory,
    enableDarkMode,
  },
  ref
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [open, setOpen] = useState(false);
  const [init, setInit] = useState(false);
  const [afterOpen, setAfterOpen] = useState<(() => void) | null>(null);

  const showOrHideDrawer = useCallback(() => {
    if (!open) {
      window.document.body.style.overflowY = "hidden";
      window.document.documentElement.style.overflowY = "hidden";
    }
    setOpen(!open);
    setInit(true);
  }, [open]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleViewBoostHistory = useCallback(() => {
    setAfterOpen(() => () => setTimeout(() => onViewBoostHistory?.(), 100));
    onClose();
  }, [onClose, onViewBoostHistory]);

  const handleNavigateToHome = useCallback(() => {
    setAfterOpen(() => () => navigate(ROUTE_ENERGYBOOSTING));
    onClose();
  }, [navigate, onClose]);

  const handleNavigateToLeaderBoard = useCallback(() => {
    setAfterOpen(() => () => navigate(ROUTE_ENERGYBOOSTING_LEADERBOARD));
    onClose();
  }, [navigate, onClose]);

  const handleAfterOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        afterOpen?.();
        setAfterOpen(null);
      } else {
        setAfterOpen(null);
      }
    },
    [afterOpen]
  );

  return (
    <Wrapper ref={wrapperRef}>
      <div style={{ ...headStyle }} className={`head `}>
        {leftTitle && (
          <div className="left-box" onClick={leftOnClick}>
            <img src={backIco} alt="" />
            <span className="left-text">{leftTitle}</span>
          </div>
        )}
        {rightTitle && (
          <StickyMenu
            $isSticky={false}
            onClick={showOrHideDrawer}
            $darkMode={enableDarkMode}
          >
            <div className="menu__placeholder" />
            <div className="menu__item">
              <div
                className={`menu__item-line ${
                  open
                    ? "menu__item-line-1-open"
                    : init
                      ? "menu__item-line-1-close"
                      : ""
                }`}
              />
              <div
                className={`menu__item-line ${
                  open
                    ? "menu__item-line-2-open"
                    : init
                      ? "menu__item-line-2-close"
                      : ""
                }`}
              />
              <div
                className={`menu__item-line ${
                  open
                    ? "menu__item-line-3-open"
                    : init
                      ? "menu__item-line-3-close"
                      : ""
                }`}
              />
            </div>
          </StickyMenu>
        )}
      </div>

      <Drawer
        placement="right"
        style={{
          transition: "none",
          backgroundColor: enableDarkMode
            ? "var(--Deep-800, #1A2535)"
            : "var(--White, #FFF)",
        }}
        onClose={onClose}
        open={open}
        width="60%"
        closable={false}
        forceRender
        destroyOnClose
        getContainer={domNode}
        afterOpenChange={handleAfterOpenChange}
      >
        <DrawerContainer>
          <DrawerA
            $darkMode={enableDarkMode}
            onClick={handleNavigateToHome}
          >{t`Home`}</DrawerA>

          <DrawerSeperator />

          <DrawerA
            $darkMode={enableDarkMode}
            onClick={handleViewBoostHistory}
          >{t`Boost History`}</DrawerA>

          <DrawerSeperator />

          <DrawerA
            $darkMode={enableDarkMode}
            onClick={handleNavigateToLeaderBoard}
          >{t`Leaderboard`}</DrawerA>
        </DrawerContainer>
      </Drawer>
    </Wrapper>
  );
};

export default EventHeader;
