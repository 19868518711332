import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { debounce } from "src/utils/helper";
import LoadingGif from "src/components/input/loading.gif";
import { ethers } from "ethers";
import InfiniteScroll from "react-infinite-scroll-component";
import AddressInputV2, { AddressCheckResult } from "../input/AddressInputV2";
import {
  getAccountsNameRegexAsNameArray,
  isSameName,
} from "src/utils/zkkontosHelper";
import { loadingStore } from "src/store/loadingStore";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import { SmartAccount } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/aa";
import { t } from "i18next";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  padding-bottom: 16px;
`;

const Suggestions = styled.div`
  margin-top: 17px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const SuggestionItem = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: #f5f5f6;
  }

  .text-1 {
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 400;
  }

  .text-2 {
    color: var(--Deep-400, #80868f);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 400;
  }
`;

const Loading = styled.img`
  margin: 30px auto;
  width: 80px;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
`;

interface AddressInputSearchProps {
  rawAddress?: string;
  allowEOA?: boolean;
  userWallet?: string;
  requireAA?: boolean;
  chainIndex?: string;
  chainSymbol?: string;
  validateReminder?: number;
  initNumber?: number;
  fetchNumber?: number;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onFinish?: (isValid: boolean, address: string) => void;
  inputStyle?: React.CSSProperties;
  onOkTextChange?: (okText: string[] | undefined) => void;
  onErrTextChange?: (errText: string[] | undefined) => void;
  onSuggestionsOnClick?: (suggestion: string) => void;
  needValidateAfterRender?: boolean;
  rawAddressValid?: boolean;
  showLoading?: boolean;
}

const ITEMS_TO_INIT = 15;
const ITEMS_TO_FETCH = 10;

const AddressInputSearch: React.FC<AddressInputSearchProps> = ({
  rawAddress,
  allowEOA,
  userWallet,
  requireAA,
  chainIndex,
  chainSymbol,
  validateReminder,
  initNumber = ITEMS_TO_INIT,
  fetchNumber = ITEMS_TO_FETCH,
  placeholder = t("Enter recipient's address") as string,
  onFocus,
  onBlur,
  onFinish,
  inputStyle,
  onOkTextChange,
  onErrTextChange,
  onSuggestionsOnClick,
  needValidateAfterRender,
  rawAddressValid,
  showLoading = true
}) => {
  const { userStore } = useStores();
  const [errText, setErrText] = useState<string[] | undefined>(undefined);
  const [okText, setOkText] = useState<string[] | undefined>(undefined);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [visibleSuggestionsNumber, setVisibleSuggestionsNumber] =
    useState<number>(initNumber);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>(rawAddress || "");
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const callSetOkText = useCallback((text: string[] | undefined) => {
    onOkTextChange?.(text);
    setOkText(text);
  }, [])

  const callSetErrText = useCallback((text: string[] | undefined) => {
    onErrTextChange?.(text);
    setErrText(text);
  }, [])

  useEffect(() => {
    if (needValidateAfterRender) validateAddress(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    setVisibleSuggestionsNumber((prev) => prev + fetchNumber);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchAccounts = useCallback(
    debounce((str: string) => {
      if (str === "") {
        return;
      }
      setLoading(true);
      getAccountsNameRegexAsNameArray(str.toLowerCase())
        .then((res) => {
          const accounts = res as string[];
          setSuggestions(accounts);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500),
    []
  );

  const handleInputOnChange = (str: string) => {
    setValue(str);
    debouncedFetchAccounts(str);
    setVisibleSuggestionsNumber(initNumber);
  };

  const handleSuggestionsOnClick = async (suggestion: string) => {
    setValue(suggestion + ".os");
    await validateAddress(suggestion);
    onSuggestionsOnClick?.(suggestion);
  };

  useEffect(() => {
    if (!!validateReminder && !!value) {
      validateAddress(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateReminder]);

  const checkAA = async (
    accountName?: string,
    existedSmartAccount?: SmartAccount
  ) => {
    showLoading && loadingStore.showLoading();
    try {
      let smartAccount: SmartAccount;
      if (existedSmartAccount) {
        smartAccount = existedSmartAccount;
      } else {
        const queryCli: KontosQueryCli = await userStore.getKontosQueryCli();
        smartAccount = await queryCli.getSmartAccountByName(accountName!);
      }
      if (!!smartAccount?.blockchainAccounts[chainIndex!]) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      showLoading && loadingStore.hideLoading();
    }
  };

  const validateAddress = async (address: string) => {
    const formattedAddress = address.replace(".os", "");
    callSetErrText(undefined);
    callSetOkText(undefined);
    if (
      rawAddressValid &&
      formattedAddress === rawAddress?.replaceAll(".os", "")
    ) {
      onFinish?.(true, address);
      callSetOkText([t("This address is valid.")]);
      return;
    }
    if (formattedAddress === "") {
      onFinish?.(false, "");
      callSetErrText([t("Address cannot be empty")]);
      return;
    }
    if (formattedAddress === userWallet?.replaceAll(".os", "")) {
      onFinish?.(false, "");
      callSetErrText([t("Cannot choose yourself")]);
      return;
    }
    if (suggestions.includes(formattedAddress)) {
      if (requireAA === true) {
        const flag = await checkAA(formattedAddress);
        if (flag === false) {
          onFinish?.(false, address);
          const palceholder = t("this chain");
          callSetErrText([
            t("inter_receiver_no_wallet", {
              chain: chainSymbol || palceholder,
            }),
          ]);
          return;
        }
      }
      onFinish?.(true, address);
      callSetOkText([t("This address is valid.")]);
      return;
    }
    if (ethers.utils.isAddress(formattedAddress)) {
      if (allowEOA === true) {
        onFinish?.(true, address);
        callSetOkText([t("This address is valid.")]);
      } else {
        onFinish?.(false, address);
        callSetErrText([t("Only Kontos address is allowed for this asset")]);
      }
      return;
    }
    try {
      showLoading && loadingStore.showLoading();
      const queryCli = await userStore.getKontosQueryCli();
      const smartAccount =
        await queryCli.getSmartAccountByName(formattedAddress);
      if (requireAA === true) {
        const flag = await checkAA(formattedAddress, smartAccount);
        if (flag === false) {
          onFinish?.(false, address);
          const palceholder = t("this chain");
          callSetErrText([
            t("inter_receiver_no_wallet", {
              chain: chainSymbol || palceholder,
            }),
          ]);
          return;
        }
      }
      onFinish?.(true, address);
      callSetOkText([t("This address is valid.")]);
    } catch (e) {
      console.log(e);
      callSetErrText([t("Invalid address")]);
      onFinish?.(false, address);
    } finally {
      showLoading && loadingStore.hideLoading();
    }
    return;
  };

  return (
    <Container>
      <AddressInputV2
        inputStyle={inputStyle}
        value={value}
        errText={errText}
        okText={okText}
        placeholder={placeholder}
        onFocus={() => {
          setIsFocus(true);
          onFocus?.();
        }}
        onBlur={() => {
          setIsFocus(false);
          validateAddress(value);
          onBlur?.();
        }}
        onChange={handleInputOnChange}
      />
      {loading && isFocus && <Loading src={LoadingGif} />}
      <Scrollable id="scrollableDiv">
        <InfiniteScroll
          dataLength={visibleSuggestionsNumber} // The current length of displayed data
          next={fetchMoreData} // The function to trigger to load more
          hasMore={visibleSuggestionsNumber < suggestions.length} // Whether there are more items to load
          loader={isFocus && <div />}
          scrollableTarget="scrollableDiv"
        >
          {suggestions.length > 0 && !loading && isFocus && (
            <Suggestions>
              {suggestions
                .slice(0, visibleSuggestionsNumber)
                .map((suggestion, index) => (
                  <SuggestionItem
                    key={suggestion + index}
                    onMouseDown={() => handleSuggestionsOnClick(suggestion)}
                  >
                    <div className="text-1">{suggestion}</div>
                    <div className="text-2">{".OS"}</div>
                  </SuggestionItem>
                ))}
            </Suggestions>
          )}
        </InfiniteScroll>
      </Scrollable>
    </Container>
  );
};

export default AddressInputSearch;
