import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import DottedLine from "src/components/line/DottedLine";

type IProps = {
  length?: number;
  showDivider?: boolean;
};

export const Wrapper = styled.div`
  width: 100%;

  .item {
    position: relative;
    .title {
      width: 112px;
      height: 22px;
      margin-bottom: 20px;
    }
    .item1 {
      height: 14px;
      margin-bottom: 20px;
    }
    .item2 {
      width: 249px;
      height: 14px;
      margin-bottom: 20px;
    }

    span[aria-live="polite"] {
      border-radius: 8px;
    }
  }
`;

const SkeletonRiskList: React.FC<IProps> = ({
  length = 1,
  showDivider = true,
}) => {
  return (
    <Wrapper>
      {Array.from({ length }).map((_, index) => (
        <div className="item" key={index}>
          {showDivider && (
            <DottedLine
              styles={{
                margin: "20px 0",
              }}
            />
          )}

          <Skeleton className={"title"} />
          <Skeleton className={"item1"} />
          <Skeleton className={"item2"} />
          <Skeleton className={"item1"} />
          <Skeleton className={"item2"} />
          <Skeleton className={"item1"} />
          <Skeleton className={"item2"} />
        </div>
      ))}
    </Wrapper>
  );
};

export default SkeletonRiskList;
