import { observer } from "mobx-react";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import BackArrowSvg from "src/assets/icons/auth/left-blue-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import QuestionSvg from "src/assets/icons/auth/question.svg";
import DefaultAvatarSvg from "src/assets/icons/auth/default-avatar.svg";
import { Input, InputPassword } from "src/components/input/KontosInput";
import { loadingStore } from "src/store/loadingStore";
import { KontosButton } from "src/components/button/KontosButton";
import RightArrowSvg from "src/assets/icons/auth/right-blue-arrow.svg";
import toast from "src/components/toast/Toast";
import NameNormalSvg from "src/assets/icons/auth/name-normal.svg";
import NameSuccessSvg from "src/assets/icons/auth/name-success.svg";
import NameSuccessSubSvg from "src/assets/icons/auth/name-success-sub.svg";
import NameErrorSvg from "src/assets/icons/auth/name-error.svg";
import NameErrorSubSvg from "src/assets/icons/auth/name-error-sub.svg";
import { useStores } from "src/hooks/useStore";

const Container = styled.div<{ $isOverWidth?: boolean }>`
  height: ${(props) => (props.$isOverWidth ? "800px" : "100%")};
  display: flex;
  flex-direction: column;

  padding: 26px 20px 23px 20px;
  box-sizing: border-box;
`;

const TitleBox = styled.div`
  position: sticky;
  top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  .back-icon {
    width: 18.385px;
    height: 18.385px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .title {
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans SC;
    font-size: 24px;
    font-weight: 700;
  }

  .question-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
`;

const Avatar = styled.img`
  margin: 0 auto;
  margin-top: 25px;
  width: 86px;
  height: 86px;
`;

const NameInputBox = styled.div`
  margin-top: 23px;

  .nameErr {
    margin-left: 20px;
    color: var(--error-notice, #ff1e2b);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }
`;

const Spliter = styled.div`
  margin: 14px 25px 0 25px;
  height: 1px;
  background-color: var(--Deep-50, #edeef1);
  flex-shrink: 0;
`;

const PasswordInputBox = styled.div`
  margin-top: 21px;
`;

const BlankFilledArea = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ContinueButton = styled(KontosButton)`
  margin-top: 30px;
`;

const CreateMultisigAccountBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 20px;

  color: var(--Kontos-Blue, #413dec);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 700;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .arrow-icon {
    margin-left: 14px;
    width: 14px;
  }
`;

const CreateAccountFlow: React.FC = () => {
  const { userStore, uiStore } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const fromWelcome = location.state && location.state.fromWelcome;
  const [name, setName] = useState("");
  const [isExitOnChain, setIsExitOnChain] = useState<boolean | null>(null);
  const [inputBlur, setInputBlur] = useState(false);
  const [nameError, setNameError] = useState("");
  const [password, setPassword] = useState("");
  const [inputPasswordBlur, setInputPasswordBlur] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isInit, setIsInit] = useState(false);

  // Name stuff
  const handleCheckName = async (name: string) => {
    if (!name) return;
    try {
      loadingStore.showLoading();
      setIsChecking(true);
      setIsExitOnChain(null);
      const cli = await userStore.getKontosQueryCli();
      const account = await cli.getSmartAccountByName(name);
      console.log(account);
      setIsExitOnChain(true);
      return account;
    } catch (e) {
      console.warn(e);
      setIsExitOnChain(false);
    } finally {
      setIsChecking(false);
      loadingStore.hideLoading();
    }
  };

  const handleNameBlur = async (str: string) => {
    setInputBlur(true);
    const regex = /^[a-z0-9_]{4,}$/;
    if (!str || str?.length <= 3) {
      setNameError("· At least 4 Characters Required.");
      return;
    }
    if (str?.length >= 65) {
      setNameError("· No more than 64 characters.");
      return;
    }
    if (!regex.test(str)) {
      setNameError("· Name verification failed.");
      return;
    }
    setNameError("");
    await handleCheckName(str);
  };

  const getNameErrText = useCallback(() => {
    if (inputBlur && nameError) {
      return <div className="nameErr">{nameError}</div>;
    }
    if (isExitOnChain === true && name && inputBlur) {
      return (
        <div className="nameErr">{t("· Account name already taken!")}</div>
      );
    }
  }, [isExitOnChain, name, inputBlur, nameError]);

  const isCreateNameErr: boolean = useMemo((): boolean => {
    if (inputBlur && nameError && isInit) return true;
    if (isExitOnChain === true && inputBlur && name) return true;
    return false;
  }, [inputBlur, nameError, isInit, isExitOnChain, name]);

  const nameInputState = useMemo(() => {
    if (isCreateNameErr)
      return {
        state: "error",
        mainIcon: NameErrorSvg,
        subIcon: NameErrorSubSvg,
      };
    if (!!name && !isExitOnChain && !nameError && !!inputBlur && !isChecking)
      return {
        state: "success",
        mainIcon: NameSuccessSvg,
        subIcon: NameSuccessSubSvg,
      };
    return {
      state: "normal",
      mainIcon: NameNormalSvg,
      subIcon: undefined,
    };
  }, [inputBlur, isChecking, isCreateNameErr, isExitOnChain, name, nameError]);

  const handlePasswordBlur = (str: string) => {
    setInputPasswordBlur(true);
    let errText = "";
    if (str?.length < 8) {
      errText += t("· At least 8 characters required") + "<br />";
    }
    if (str?.length > 32) {
      errText += t("· No more than 32 characters") + "<br />";
    }
    if (!/\d/.test(str)) {
      errText += t("· Must contain at least one digit") + "<br />";
    }
    if (!/(?=.*?[A-Z])/.test(str) || !/(?=.*?[a-z])/.test(str)) {
      errText += t("· Must contain uppercase and lowercase") + "<br />";
    }
    if (!/(?=.*?[#?!@$%^&_*-\.])/.test(str)) {
      errText += t("· Must contain symbols (#@$!%_*?-.&)") + "<br />";
    }
    setPasswordError(errText);
  };

  return (
    <Container $isOverWidth={uiStore.isOverWidth}>
      <TitleBox>
        {fromWelcome && (
          <img
            className="back-icon"
            src={BackArrowSvg}
            alt="back"
            onClick={() => {
              navigate(-1);
            }}
          />
        )}
        <div className="title">{t("Create Account")}</div>
        <img className="question-icon" src={QuestionSvg} alt="help" />
      </TitleBox>

      {/* Avatar */}
      <Avatar src={DefaultAvatarSvg} />

      {/* AccountNameInput */}
      <NameInputBox>
        <Input
          onChange={(e) => {
            setName(e);
          }}
          onFocus={() => {
            setIsInit(true);
            setInputBlur(false);
          }}
          onBlur={() => handleNameBlur(name)}
          value={name}
          placeHolder={t("Your account username")}
          mainIcon={nameInputState.mainIcon}
          subIcon={nameInputState.subIcon}
          state={nameInputState.state as "normal" | "success" | "error"}
        />
        {getNameErrText()}
      </NameInputBox>

      {/* Spliter */}
      <Spliter />

      {/* RecoveryPasswordInput */}
      <PasswordInputBox>
        <InputPassword
          onChange={(e) => {
            setPassword(e);
          }}
          onFocus={() => {
            setInputPasswordBlur(false);
          }}
          onBlur={() => handlePasswordBlur(password)}
          value={password}
          placeHolder={t("Set recovery password")}
        />
        <InputPassword
          onChange={(e) => {
            setConfirmPassword(e);
          }}
          onFocus={() => {
            setInputPasswordBlur(false);
          }}
          onBlur={() => handlePasswordBlur(confirmPassword)}
          value={confirmPassword}
          placeHolder={t("Re-enter recovery password")}
        />
      </PasswordInputBox>

      {/* Blank filled area */}
      <BlankFilledArea />

      {/* Continue */}
      <ContinueButton disabled={true}>{t("Continue")}</ContinueButton>

      {/* Create Multisig Account */}
      <CreateMultisigAccountBox
        onClick={() => {
          toast({
            text: t("Coming soon!"),
            type: "info",
          });
        }}
      >
        {t("Create Multisig Account")}
        <img className="arrow-icon" src={RightArrowSvg} alt="" />
      </CreateMultisigAccountBox>
    </Container>
  );
};

export default observer(CreateAccountFlow);
