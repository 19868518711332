import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";

const OuterEllipse = styled.ellipse<{ isHovered: boolean }>`
  ${({ isHovered }) => css`
    rx: ${isHovered ? 18 : 16}px;
    ry: ${isHovered ? 18 : 16}px;
    transition: all 0.2s ease-in-out;
  `}
`;

const InnerEllipse = styled.ellipse<{ isHovered: boolean }>`
  ${({ isHovered }) => css`
    rx: ${isHovered ? 12.6555 : 12.6555}px;
    ry: ${isHovered ? 12.6555 : 12.6555}px;
    transition: all 0.2s ease-in-out;
  `}
`;

const SvgWrapper = styled.svg`
  width: 32px;
  height: 32px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  overflow: visible;
`;

interface SvgIconProps {
  className?: string;
  onClick?: () => void;
}

export const ExchangeButton: React.FC<SvgIconProps> = ({
  className,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const handleClickDesktop = useCallback(() => {
    setIsHovered(false);
    setTimeout(() => {
      setIsHovered(true);
      onClick?.();
    }, 200);
  }, [onClick]);

  const handleClickMobile = useCallback(() => {
    setIsHovered(true);
    setTimeout(() => {
      setIsHovered(false);
      onClick?.();
    }, 200);
  }, [onClick]);

  const handleClick = isMobile ? handleClickMobile : handleClickDesktop;

  return (
    <SvgWrapper
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      onClick={handleClick}
    >
      <OuterEllipse
        cx="16"
        cy="16"
        isHovered={isHovered}
        transform="rotate(-90 16 16)"
        fill="#F5F5F6"
      />
      <InnerEllipse
        cx="15.9965"
        cy="15.9985"
        isHovered={isHovered}
        transform="rotate(-90 15.9965 15.9985)"
        fill="white"
        stroke="#F5F5F6"
        stroke-width="1.14286"
      />
      <path
        d="M10.8544 21.7143C10.8544 22.0299 11.1102 22.2858 11.4258 22.2858C11.7414 22.2858 11.9972 22.0299 11.9972 21.7143L10.8544 21.7143ZM11.8298 9.88169C11.6067 9.65854 11.2449 9.65854 11.0217 9.88169L7.38517 13.5182C7.16201 13.7414 7.16201 14.1032 7.38517 14.3264C7.60833 14.5495 7.97014 14.5495 8.19329 14.3264L11.4258 11.0939L14.6583 14.3264C14.8814 14.5495 15.2432 14.5495 15.4664 14.3264C15.6895 14.1032 15.6895 13.7414 15.4664 13.5182L11.8298 9.88169ZM11.9972 21.7143L11.9972 10.2858L10.8544 10.2858L10.8544 21.7143L11.9972 21.7143Z"
        fill="#1A2535"
      />
      <path
        d="M19.9989 10.2858C19.9989 9.97018 20.2547 9.71434 20.5703 9.71434C20.8859 9.71434 21.1417 9.97018 21.1417 10.2858L19.9989 10.2858ZM20.9744 22.1184C20.7512 22.3416 20.3894 22.3416 20.1663 22.1184L16.5297 18.4818C16.3065 18.2587 16.3065 17.8969 16.5297 17.6737C16.7529 17.4506 17.1147 17.4506 17.3378 17.6737L20.5703 20.9062L23.8028 17.6737C24.026 17.4506 24.3878 17.4506 24.6109 17.6737C24.8341 17.8969 24.8341 18.2587 24.6109 18.4818L20.9744 22.1184ZM21.1417 10.2858L21.1417 21.7143L19.9989 21.7143L19.9989 10.2858L21.1417 10.2858Z"
        fill="#1A2535"
      />
    </SvgWrapper>
  );
};
