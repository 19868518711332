interface Props {
  id: string;
  className?: string;
  stroke?: string;
  style?: React.CSSProperties;
}

export const AiScorePath: React.FC<Props> = ({
  id,
  className,
  stroke,
  style,
}) => {
  return (
    <div className={className} style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="123"
        viewBox="0 0 150 123"
        fill="none"
      >
        <path
          d="M22.947 112C17.68 104.654 14.011 96.2434 12.23 87.3074C9.76 74.9216 11.028 62.0833 15.872 50.4161C20.716 38.7489 28.919 28.7768 39.444 21.7608C49.968 14.7448 62.342 11 75 11C87.658 11 100.032 14.7448 110.556 21.7608C121.081 28.7768 129.284 38.7489 134.128 50.4161C138.972 62.0833 140.24 74.9216 137.77 87.3074C135.989 96.2434 132.32 104.654 127.053 112"
          stroke="#EDEEF1"
          fillOpacity="0"
          strokeWidth="21"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={id}
          d="M22.947 112C17.68 104.654 14.011 96.2434 12.23 87.3074C9.76 74.9216 11.028 62.0833 15.872 50.4161C20.716 38.7489 28.919 28.7768 39.444 21.7608C49.968 14.7448 62.342 11 75 11C87.658 11 100.032 14.7448 110.556 21.7608C121.081 28.7768 129.284 38.7489 134.128 50.4161C138.972 62.0833 140.24 74.9216 137.77 87.3074C135.989 96.2434 132.32 104.654 127.053 112"
          stroke={stroke ? stroke : "#EDEEF1"}
          fillOpacity="0"
          strokeWidth="21"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
