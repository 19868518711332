import { FtAssetV2 } from "@zkkontos/kontos-sdk/src/api/types";

export enum MSGTYPE {
  Register = "register",
  Recover = "recover",
  TradeFutures = "futuresTrading",
  TradeSpots = "spotTrading",
  Send = "send",
}

export enum MSGSTATUS {
  Success = "success",
  Fail = "fail",
}

export interface KontosCtx {
  ctx_key: string;
  cmd_type: string;
  cmd_source: string;
  user_handle: string;
  platform: string;
  isSuccess: boolean;
  isFail: boolean;
  expire_at: string;
  current_kontos_account: string;
  guild: string;
  channel: string;
  path: string;
  trade_opt?: {
    chain_index: string;
    asset_address: string;
    usd_amount: string;
  };
}

export interface KontosNotify {
  ctx_key: string;
  msg_type: MSGTYPE;
  msg_status: MSGSTATUS;
  msg: string; // Operation hash
  data: string; // Specific data, e.g. account name for register
}

export function isKontosCtxExpired(kontosCtx: KontosCtx): boolean {
  const expireAt = new Date(kontosCtx.expire_at);
  const now = new Date();
  return now > expireAt;
}

export function isKontosCtxSuccess(kontosCtx: KontosCtx): boolean {
  return kontosCtx.isSuccess;
}

export function isKontosCtxFail(kontosCtx: KontosCtx): boolean {
  return kontosCtx.isFail;
}

export interface AccountBalancesRes {
  ChainIndex: string;
  ChainName: string;
  ChainImageUrl: string;
  Address: string;
  Balance: string;
}

export interface AssetsRes {
  ChainIndex: string;
  ChainName: string;
  ChainImageUrl: string;
  Address: string;
  Name: string;
  Symbol: string;
  Decimals: string;
  ImageUrl: string;
  UsdPrice: string;
}

export interface BalanceAsset {
  ChainIndex: string;
  ChainName: string;
  ChainImageUrl: string;
  Address: string;
  Balance: string;

  Name: string;
  Symbol: string;
  Decimals: string;
  UsdPrice: string;
  ImageUrl: string;
}

export const getAccountBalanceAsset = (
  abr: AccountBalancesRes[],
  ar: AssetsRes[]
): BalanceAsset[] => {
  let res: BalanceAsset[] = [];

  abr.forEach((a) => {
    const asset = ar.find((r) => {
      const isEqual =
        r.Address.toLowerCase() === a.Address.toLowerCase() &&
        r.ChainIndex.toLowerCase() === a.ChainIndex.toLowerCase();
      return isEqual;
    });

    if (asset) {
      console.log(a);
      const newAsset: BalanceAsset = { ...asset, ...a };
      res.push(newAsset);
    }
  });

  return res;
};

export const findAssetsResByBalanceAsset = (
  balanceAsset: BalanceAsset,
  assetsResArray: AssetsRes[]
): AssetsRes | undefined => {
  return assetsResArray.find(
    (asset) =>
      asset.Address.toLowerCase() === balanceAsset.Address.toLowerCase() &&
      asset.ChainIndex === balanceAsset.ChainIndex
  );
};

export const findAssetsResByBalanceAssets = (
  balanceAssets: BalanceAsset[],
  assetsResArray: AssetsRes[]
): AssetsRes[] => {
  return balanceAssets.reduce((acc: AssetsRes[], balanceAsset) => {
    const matchedAsset = assetsResArray.find(
      (asset) =>
        asset.Address.toLowerCase() === balanceAsset.Address.toLowerCase() &&
        asset.ChainIndex === balanceAsset.ChainIndex
    );
    if (matchedAsset) {
      acc.push(matchedAsset);
    }
    return acc;
  }, []);
};

export const getBalanceAssetByAssetsRes = (
  balanceAssets: BalanceAsset[],
  assetRes: AssetsRes
): BalanceAsset | undefined => {
  return balanceAssets.find(
    (balanceAsset) =>
      balanceAsset.Address.toLowerCase() === assetRes.Address.toLowerCase() &&
      balanceAsset.ChainIndex === assetRes.ChainIndex
  );
};

export const getBalanceAssetsByAssetsRes = (
  balanceAssets: BalanceAsset[],
  assetsRes: AssetsRes[]
): BalanceAsset[] => {
  return balanceAssets.filter((balanceAsset) =>
    assetsRes.some(
      (assetRes) =>
        balanceAsset.Address.toLowerCase() === assetRes.Address.toLowerCase() &&
        balanceAsset.ChainIndex === assetRes.ChainIndex
    )
  );
};

export enum AssetType {
  AssetTypeNone = 0,
  AssetTypeNative = 1,
  AssetTypeERC20 = 2,
  AssetTypeERC721 = 3,
  AssetTypeERC1155 = 4,
}

export enum SingerSendOrReceive {
  Receive = 0,
  Send = 1,
}

export interface AssetChange {
  assetChangeId: number;
  txId: number;
  height: number;
  txHash: string;
  msgTypeUrls: string;
  transferFrom: string;
  transferFromName: string;
  transferTo: string;
  transferToName: string;
  transferAssetAddress: string;
  transferAmount: string;
  transferErc721Index: string;
  transferErc1155: { [key: string]: string };
  transferType: AssetType;
  assetDetail: FtAssetV2;
  singerSendOrReceive: SingerSendOrReceive;
  blockTime: number;
  gasUsed: number;
}

export interface MediaInfo {
  mediaUrl: string;
  imageUrl: string;
}
