import {EB_SIGN_EXPIREDAT} from "src/config";
import {KontosQueryCli} from "@zkkontos/kontos-sdk";
import {signForBe} from "src/utils/zkkontosHelper";
import {callVoteAction, IVote, VoteAction} from "src/apis/markets-apis";

export const vote = async ({
                      account,
                      cli,
                      expiration = EB_SIGN_EXPIREDAT,
                      action = VoteAction.Bullish,
                    }: IVote
) => {
  const kontosAddress = KontosQueryCli.kontosAddress(account);
  const {signature, expiredAt} = await signForBe(
    account,
    kontosAddress,
    cli,
    account,
    expiration
  );
  return await callVoteAction({
    account,
    action,
    expiredAt,
    signature,
  });
};
