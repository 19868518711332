import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DeepButton } from "src/components/button/DeepButton";
import { BASIC_SHEET_INDEX } from "src/config";

const Container = styled.div`
  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 17px 20px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }

  > .content {
    margin-top: 12px;
    color: var(--Deep-800, #1a2535);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }

  > .tip {
    margin-top: 12px;
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  > .ok-btn {
    margin-top: 16px;
  }
`;

interface Props {
  onClose: (open: boolean) => void;
}

export const EbCopyPopup: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const title = t("Copied Successfully!");
  const content = t("Invite your friends to boost your energy right away.");
  const tip = t("*New users can help you earn more energy.");

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <div className="tip">{tip}</div>
        <DeepButton className="ok-btn" onClick={() => onClose(false)}>
          {t("OK")}
        </DeepButton>
      </Container>
    </Overlay>
  );
};
