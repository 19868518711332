import React from 'react';
import styled from 'styled-components';
import Skeleton from "react-loading-skeleton";

type IProps = {
  length?: number;
}

export const Wrapper = styled.div`
  width: 100%;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 16px;
    padding: 8px 8px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #000d1f;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 8px;
    span[aria-live="polite"] {
      border-radius: 8px;
    }

    .box {
      position: relative;
      margin-right: 18px;
      height: 32px;

      .coin {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .icon {
        position: absolute;
        width: 16px;
        height: 16px;
        bottom: 0;
        left: 23px;
        border-radius: 50%;
      }
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .label-0 {
        width: 80px;
        height: 11px;
        flex-shrink: 0;
      }
      
      .label-1 {
        width: 80px;
        height: 8px;
        flex-shrink: 0;
      }
      
      .label-2 {
        width: 124px;
        height: 11px;
        flex-shrink: 0;
      }
      
      .label-3 {
        width: 95px;
        height: 8px;
        flex-shrink: 0;
      }
    }

  }

  .value {
    margin-left: auto;
    text-align: right;
  }
`;

const SkeletonMarketAssetList: React.FC<IProps> = ({
  length = 1
                                                   }) => {
  return <Wrapper>
    {
      Array.from({ length }).map((_, index) => (
        <div className="item" key={index}>
          <div className="box">
            <Skeleton className={"coin"} />
            <Skeleton className={"icon"} />
          </div>
          <div className="label">
            <Skeleton className={"label-0"} />
            <Skeleton className={"label-1"} />
          </div>
          <div className="label value">
            <Skeleton className={"label-2"} />
            <Skeleton className={"label-3"} />
          </div>
        </div>
      ))
    }
  </Wrapper>
};

export default SkeletonMarketAssetList;
