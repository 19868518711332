// TransformingIcon.tsx
import React from 'react';
import styled from 'styled-components';

interface IconProps {
  defaultIcon: string;
  hoverIcon: string;
  onClick: () => void;
}

interface IconWrapperProps {
  icon: string;
  hoverIcon: string;
}

const IconWrapper = styled.div<IconWrapperProps>`
  width: 20px;
  height: 20px;
  background-image: url(${props => props.icon});
  background-size: cover;
  cursor: pointer;
  transition: background-image 0.3s ease;

  &:hover {
    background-image: url(${props => props.hoverIcon});
  }
`;

const TransformingIcon: React.FC<IconProps> = ({ defaultIcon, hoverIcon, onClick }) => {
  return <IconWrapper icon={defaultIcon} hoverIcon={hoverIcon} onClick={onClick} />;
};

export default TransformingIcon;
