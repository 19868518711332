import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import { RespTaskDataV3 } from "@zkkontos/kontos-sdk/src/api";
import KontosNumber from "src/utils/KontosNumber";
import { RootStore } from "../RootStore";
import { ChainConfig, FtAsset } from "@/type/zkkontos";
import { DEFAULT_DECIMAL, DEFAULT_SLIPPAGE } from "src/config";
import * as TradeService from "src/service/trade-service";
import { UserStore } from "../UserStore";

export class SwapStore {
  rootStore: RootStore;
  userStore: UserStore;
  chain?: ChainConfig;
  toSwapFtAsset?: FtAsset;
  toSwapFtAssetQuantity?: KontosNumber;
  toReceiveFtAsset?: FtAsset;
  receiver?: string;
  taskData?: RespTaskDataV3;
  selectedTaskDataIndex: number = 0;
  slippage: KontosNumber = DEFAULT_SLIPPAGE;
  chainsReactionDisposer?: IReactionDisposer;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.userStore = rootStore.userStore;
    this.startTrackingAccountName();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  startTrackingAccountName = () => {
    reaction(
      () => this.rootStore.userStore.accountName,
      () => {
        this.reset();
      }
    );
  };

  checkChain = () => {
    if (!this.chain) {
      if (this.rootStore.chainStore.userHoldingsNoKontosChains.length > 0) {
        const targetChain =
          this.rootStore.chainStore.userHoldingsNoKontosChains[0];
        this.chain = targetChain;
        this.toSwapFtAsset = this.rootStore.userStore.userHoldings.find(
          (item) => item.chainIndex === targetChain.chainIndex
        );
      } else {
        this.chain = this.rootStore.chainStore.allowSwapChains[0];
      }
    }
  };

  startTrackingChains = () => {
    this.chainsReactionDisposer = reaction(
      () => [this.rootStore.chainStore.allowSwapChains, this.chain],
      () => {
        this.checkChain();
      }
    );
  };

  stopTrackingChains = () => {
    this.chainsReactionDisposer?.();
  };

  get toReceiveFtAssetQuantity(): KontosNumber | undefined {
    return this.toSwapFtAssetQuantity && this.taskData
      ? new KontosNumber(
          this.taskData.respQuoteWithBasicRequirement?.assetAmount,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get fee(): KontosNumber | undefined {
    return this.taskData && this.taskData.newTasksByPaymentPlans?.length > 0
      ? new KontosNumber(
          this.taskData.newTasksByPaymentPlans?.[this.selectedTaskDataIndex]
            ?.totalFeeInUsd,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get insufficient(): boolean {
    const flag =
      !this.taskData?.newTasksByPaymentPlans ||
      this.taskData.newTasksByPaymentPlans.length === 0;
    return (
      flag &&
      !!this.toSwapFtAssetValue &&
      !!this.toSwapFtAsset &&
      !!this.toReceiveFtAsset
    );
  }

  get mayFail(): boolean {
    return this.taskData ? this.taskData.willRevert : false;
  }

  get orderPrice(): KontosNumber | undefined {
    return this.taskData && this.taskData.totalRequiredUsdCost !== "0"
      ? new KontosNumber(this.taskData.totalRequiredUsdCost, DEFAULT_DECIMAL)
      : undefined;
  }

  get toSwapFtAssetBalance(): KontosNumber | undefined {
    return this.toSwapFtAsset
      ? new KontosNumber(this.toSwapFtAsset.balance, DEFAULT_DECIMAL)
      : undefined;
  }

  get toSwapFtAssetMaxAvailable(): KontosNumber | undefined {
    return this.taskData
      ? new KontosNumber(
          this.taskData.maxAvailableBalanceAmount,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get toReceiveFtAssetBalance(): KontosNumber | undefined {
    return this.toReceiveFtAsset
      ? new KontosNumber(this.toReceiveFtAsset.balance, DEFAULT_DECIMAL)
      : undefined;
  }

  get toSwapFtAssetValue(): KontosNumber | undefined {
    return this.toSwapFtAsset && this.toSwapFtAssetQuantity
      ? this.toSwapFtAssetQuantity.multiply(
          this.toSwapFtAsset.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get toReceiveFtAssetValue(): KontosNumber | undefined {
    return this.toReceiveFtAsset && this.toReceiveFtAssetQuantity
      ? this.toReceiveFtAssetQuantity.multiply(
          this.toReceiveFtAsset.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  resetTaskData = () => {
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
  };

  resetSwapInput = () => {
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
    this.toSwapFtAssetQuantity = undefined;
  };

  reset = () => {
    this.chain = undefined;
    this.toSwapFtAsset = undefined;
    this.toSwapFtAssetQuantity = undefined;
    this.toReceiveFtAsset = undefined;
    this.taskData = undefined;
    this.selectedTaskDataIndex = 0;
    this.slippage = DEFAULT_SLIPPAGE;
    this.receiver = this.userStore.accountName;
  };

  fetchAndSetTaskData = async (): Promise<RespTaskDataV3 | undefined> => {
    if (
      !this.userStore.accountName ||
      !this.receiver ||
      !this.chain ||
      !this.toSwapFtAsset ||
      !this.toReceiveFtAsset
    ) {
      return undefined;
    }

    const taskDataSwap = await TradeService.fetchSwapTaskDataV3(
      this.userStore.accountName!,
      this.receiver!,
      this.chain?.chainIndex!,
      this.toSwapFtAsset?.address!,
      this.toReceiveFtAsset?.address!,
      this.toSwapFtAssetQuantity || new KontosNumber(0),
      this.slippage
    );
    if (this.chain && this.toSwapFtAsset && this.toReceiveFtAsset) {
      runInAction(() => {
        this.taskData = taskDataSwap;
      });
    } else {
      runInAction(() => {
        this.resetSwapInput();
      });
    }
    return taskDataSwap;
  };

  setChain = (chain?: ChainConfig) => {
    this.chain = chain;
  };

  setToSwapFtAsset = (asset?: FtAsset) => {
    this.toSwapFtAsset = asset;
  };

  setToSwapFtAssetQuantity = (quantity?: KontosNumber) => {
    this.toSwapFtAssetQuantity = quantity;
  };

  setToReceiveFtAsset = (asset?: FtAsset) => {
    this.toReceiveFtAsset = asset;
  };

  setSlippage = (slippage: KontosNumber) => {
    this.slippage = slippage;
  };

  setReceiver = (_receiver: string) => {
    this.receiver = _receiver;
  };

  setSelectedTaskDataIndex = (index: number) => {
    this.selectedTaskDataIndex = index;
  };
}
