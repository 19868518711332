import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const Container = styled.div`
  width: 50%;
  height: 108px;
  border-radius: 8px;
  background: var(--Deep-25, #f5f5f6);
  position: relative;

  .eb-help-surprise {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    border-radius: 8px 0px;
    background: var(--Kontos-Blue, #413dec);
    padding: 3px 14px;

    color: var(--White, #fff);
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 12px;
  }

  .eb-help-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .eb-help-surprise-icon {
      margin-top: 15px;
      margin-bottom: 9px;
      width: 48px;
      height: 48px;
    }

    .eb-help-normal-icon {
      margin-top: 8px;
      margin-bottom: 10px;
      width: 60px;
      height: 60px;
    }

    .eb-help-title {
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans Bold";
      font-size: 14px;
      height: 16px;
    }

    .eb-help-sub-title {
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 10px;
      font-weight: 400;
      height: 12px;
    }
  }
`;

interface Props {
  items: {
    icon: any;
    title: string;
    subTitle?: string;
  }[];
}

export const EbHelpItem: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {items.length > 1 ? (
        <>
          <div className="eb-help-surprise">{t("Surprise")}</div>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
          >
            {items.map((item) => (
              <SwiperSlide key={item.title}>
                <div className="eb-help-item-wrapper">
                  <img
                    className="eb-help-surprise-icon"
                    src={item.icon}
                    alt=""
                  />
                  <div className="eb-help-title">{item.title}</div>
                  <div className="eb-help-sub-title">{item.subTitle}</div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <div className="eb-help-item-wrapper">
          <img className="eb-help-normal-icon" src={items[0]?.icon} alt="" />
          <div className="eb-help-title">{items[0]?.title}</div>
        </div>
      )}
    </Container>
  );
};
