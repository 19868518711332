export const ROUTE_DEFAULT = "/";
export const ROUTE_AUTH = "/auth";
export const ROUTE_CREATE_ACCOUNT = "/createAccount";
export const ROUTE_RECOVER_ACCOUNT = "/recoverAccount";
export const ROUTE_AUTH_CREATE = "/auth/create";
export const ROUTE_AUTH_RECOVER = "/auth/recover";
export const ROUTE_ERROR = "/error";
export const ROUTE_PIN = "/PIN_code";
export const ROUTE_AUTH_NEW = "/auth/new";
export const ROUTE_AUTH_NEW_REGISTER = "/auth/new/register";
export const ROUTE_UNLOCK = "/unlock";
export const ROUTE_BIND_WARN = "/warn/bind";
export const ROUTE_SEND = "/send";
export const ROUTE_H5_SIGN = "/sign";

export const ROUTE_H5_APPROVE = "/approve";

export const BALANCE_ROUTES = [ROUTE_SEND];

export const PREV = "../";

// for webapp
export const ROUTE_MAIN = "/assets";
export const ROUTE_ASSET_DETAIL_QUOTE = "/assets/quote";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_GUARDIAN = "/guardian";
export const ROUTE_SECURITY__EMAIL = "/security-email";

// new ui
export const ROUTE_HOME = "/home";
export const ROUTE_MARKETS = "/markets";

// main/..
export const ROUTE_MAIN_RECEIVE = "receive/";
export const ROUTE_MAIN_RECEIVE_CHOOSE = "receive/choose";

// settings/..
export const ROUTE_SETTINGS_ACCOUNT = "account/";
export const ROUTE_SETTINGS_ACCOUNT_SWITCH = "switch";
export const ROUTE_SETTINGS_ACCOUNT_REFERRAL = "referral";

export const ROUTE_SETTINGS_GENERAL = "/settings/general";
export const ROUTE_SETTINGS_GENERAL_LANG = "/settings/general/lang";

export const ROUTE_SETTINGS_TRADE = "settings/";
export const ROUTE_SETTINGS_PAYMENT = "payment";

// trade
export const ROUTE_TRADE = "/trade";
export const ROUTE_TRADE_SPOT = "spot";
export const ROUTE_TRADE_SWAP = "swap";

// discover
export const ROUTE_DISCOVER = "/discover";
export const ROUTE_DISCOVER_EXPLORE = "explore";
export const ROUTE_DISCOVER_FAVORITES = "favorites";
export const ROUTE_DISCOVER_TRENDINGS = "trendings";
export const ROUTE_DISCOVER_SEARCH = "discoverSearch";

// H5 events
export const ROUTE_ENERGYBOOSTING = "/energyBoosting";
export const ROUTE_ENERGYBOOSTING_LEADERBOARD = "leaderboard";
// scan
export const ROUTE_QR_READER = "qr-reader";

// ai
export const ROUTE_AI = "/ai";
