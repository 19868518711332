import styled from "styled-components";
import CopySvg from "src/assets/icons/copy.svg";
import CopyHoverSvg from "src/assets/icons/copy-hover.svg";
import CopySuccessSvg from "src/assets/icons/copy-success.svg";
import CopyTextSvg from "src/assets/icons/copy-text.svg";
import CopyTextSuccessSvg from "src/assets/icons/copy-text-success.svg";
import { useCallback, useState } from "react";
import copy from "copy-to-clipboard";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .copy-able-account__copy-icon,
  .copy-able-account__success-icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .copy-able-account__copy-icon:hover {
    //background-color: ${(props) => props.theme.grey9};
  }

  .copy-text {
    position: absolute;
    left: -35px;
    top: -29px;
    display: none;
  }

  .copy-text-normal {
    width: 92px;
    height: 23px;
  }

  .copy-text-success {
    width: 92px;
    height: 23px;
  }

  .hover {
    display: none;
  }

  .normal {
    display: block;
  }

  &:hover {
    .hover {
      display: block;
    }

    .normal {
      display: none;
    }

    .copy-text {
      display: block;
    }
  }
`;

type ICopyProps = {
  text: string;
  className?: string;
};
export default function Copy({ text, className }: ICopyProps) {
  const [copySuccess, setCopySuccess] = useState(false);
  const _copy = useCallback(
    (copyText: string) => {
      if (copySuccess) return;
      copy(copyText);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    },
    [copySuccess]
  );
  return (
    <Wrapper className={className}>
      <img
        src={CopySvg}
        className={`normal ${
          copySuccess
            ? "copy-able-account__success-icon"
            : "copy-able-account__copy-icon"
        }`}
        alt="copy"
        onClick={(e) => {
          e.stopPropagation();
          _copy(text);
        }}
      />
      <img
        src={copySuccess ? CopySuccessSvg : CopyHoverSvg}
        className={`hover ${
          copySuccess
            ? "copy-able-account__success-icon"
            : "copy-able-account__copy-icon"
        }`}
        alt="copy"
        onClick={(e) => {
          e.stopPropagation();
          _copy(text);
        }}
      />
      {copySuccess ? (
        <img
          className={"copy-text copy-text-success"}
          src={CopyTextSuccessSvg}
          alt=""
        />
      ) : (
        <img
          className={"copy-text copy-text-normal"}
          src={CopyTextSvg}
          alt=""
        />
      )}
    </Wrapper>
  );
}
