import styled from "styled-components";
import { DappFavoritesDesktop } from "./DappFavoritesDesktop";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { useCallback } from "react";
import { AnimatedLayout } from "src/components/tab/AnimatedLayout";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px 60px 24px;
`;

export const DiscoverFavoritesTab: React.FC = observer(() => {
  const { favStore, userStore } = useStores();

  const handleAppManagedEnd = useCallback(
    async (ids?: number[]) => {
      if (!!ids) {
        if (!userStore.kontosCli) {
          userStore.unlock(() => handleAppManagedEnd(ids));
          return false;
        }
        try {
          await favStore.sortFavorites(ids, userStore.kontosCli);
        } catch (e) {
          console.log("Failed to sort favorites dapps");
        }
      }
    },
    [favStore, userStore]
  );

  const handleDelete = useCallback(
    async (id: number) => {
      if (!userStore.kontosCli) {
        userStore.unlock(() => handleDelete(id));
        return false;
      }
      try {
        await favStore.removeFavorites({
          dappId: id,
          cli: userStore.kontosCli,
        });
      } catch (e) {
        console.log("Failed to remove favorites dapp");
      }
    },
    [favStore, userStore]
  );

  return (
    <AnimatedLayout>
      <Container>
        <DappFavoritesDesktop
          dapps={favStore.dappFavoritesWatched}
          onAppManageEnd={handleAppManagedEnd}
          onDelete={handleDelete}
        />
      </Container>
    </AnimatedLayout>
  );
});
