import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { DeepButton } from "src/components/button/DeepButton";
import { BASIC_SHEET_INDEX } from "src/config";

const Container = styled.div`
  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 17px 20px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }

  > .content {
    margin-top: 12px;
    color: var(--Deep-800, #1a2535);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }

  > .ratio {
    margin-top: 6px;
    color: var(--Kontos-Blue, #413dec);
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 24px;
  }

  > .tip {
    margin-top: 6px;
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  > .ok-btn {
    margin-top: 12px;
  }
`;

function getFormattedEquivalentUTCZeroTime() {
  const now = new Date();
  const utcEquivalentOfLocalMidnight = new Date(
    now.setHours(24, 0, 0, 0) + now.getTimezoneOffset() * 60000
  );
  const hours = utcEquivalentOfLocalMidnight
    .getUTCHours()
    .toString()
    .padStart(2, "0");
  const minutes = utcEquivalentOfLocalMidnight
    .getUTCMinutes()
    .toString()
    .padStart(2, "0");

  return `${hours}:${minutes}`;
}

interface Props {
  dailyLimit: number;
  onClose: (open: boolean) => void;
}

export const EbNoBoostTimesPopup: React.FC<Props> = ({
  dailyLimit,
  onClose,
}) => {
  const { t } = useTranslation();
  const title = t("Oops!");
  const content = t("It seems you have used up your daily limit");
  const resetTime = getFormattedEquivalentUTCZeroTime();

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <div className="ratio">0/{dailyLimit}</div>
        <div className="tip">
          <Trans i18nKey={"trans-no-boost-times"}>
            *Your limit will reset at {{ resetTime }}
          </Trans>
        </div>
        <DeepButton className="ok-btn" onClick={() => onClose(false)}>
          {t("OK")}
        </DeepButton>
      </Container>
    </Overlay>
  );
};
