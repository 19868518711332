import { SelectAssetButton } from "src/components/asset-item/SelectAssetButton";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { ExchangeButton } from "src/components/button/ExchangeButton";
import { useCallback } from "react";
import balanceIcon from "src/assets/icons/balance.svg";
import KontosNumber from "src/utils/KontosNumber";
import NumericInputV2 from "src/components/input/NumericInputV2";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";

const Container = styled.div<{ $enabelAiBox?: boolean }>`
  width: 100%;
  position: relative;
  background: var(--White, #fff);

  display: flex;
  flex-direction: column;
  gap: 1px;

  /* ${(props) =>
    !props.$enabelAiBox
      ? "border-radius:8px; box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);"
      : ""} */
`;

const StyledExchangeButton = styled(ExchangeButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Divider = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background: #edeef1;
`;

const BalanceIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const BalanceText = styled.span`
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const UsdText = styled.span`
  height: 14px;
  line-height: 14px;
  margin-top: 2px;
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-align: right;
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

const SrcTradeInput = styled(NumericInputV2)`
  margin-top: 17px;
`;

const DstBalanceBox = styled.div`
  margin-top: 17px;
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-align: right;
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans Bold";
  font-size: 18px;
  line-height: 22px;
  height: 22px;

  .placeholder {
    color: var(--Deep-400, #80868f);
  }
`;

const UpBox = styled.div`
  padding: 10px 16px 30px 10px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const UpLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UpLeftLine1 = styled.div`
  margin-left: 6px;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  user-select: none;
`;

const UpLeftSelectAssetButton = styled(SelectAssetButton)`
  margin-top: 12px;
`;

const UpRightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const UpRightBoxLine1 = styled.div`
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DownBox = styled.div`
  padding: 22px 16px 10px 10px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const DownLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DownLeftLine1 = styled.div`
  margin-left: 6px;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  user-select: none;
`;

const DownLeftSelectAssetButton = styled(SelectAssetButton)`
  margin-top: 12px;
`;

const DownRightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const DownRightBoxLine1 = styled.div`
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface IProps {
  resultLoading: boolean;
  enabelAiBox?: boolean;
  onFromClick: () => void;
  onToClick: () => void;
}

export const SwapPanel: React.FC<IProps> = observer(
  ({ resultLoading, enabelAiBox, onFromClick, onToClick }) => {
    const { t } = useTranslation();
    const { swapStore } = useStores();

    const handleInputChange = useCallback(
      (amount: KontosNumber) => {
        const toSetValue = amount.eq(0) ? undefined : amount;
        swapStore.setToSwapFtAssetQuantity(toSetValue);
      },
      [swapStore]
    );

    const handleExchange = useCallback(() => {
      if (!swapStore.toSwapFtAsset && !swapStore.toReceiveFtAsset) {
        return;
      }
      swapStore.resetTaskData();

      swapStore.setToSwapFtAssetQuantity(undefined);
      const oldSwapAsset = swapStore.toSwapFtAsset;
      swapStore.setToSwapFtAsset(swapStore.toReceiveFtAsset);
      swapStore.setToReceiveFtAsset(oldSwapAsset);
    }, [swapStore]);

    return (
      <Container $enabelAiBox={enabelAiBox}>
        {/* Up */}
        <UpBox>
          {/* Up left */}
          <UpLeftBox>
            <UpLeftLine1>{t("From")}</UpLeftLine1>
            <UpLeftSelectAssetButton
              asset={swapStore.toSwapFtAsset}
              chain={swapStore.chain}
              onClick={onFromClick}
            />
          </UpLeftBox>

          {/* Up Right */}
          <UpRightBox>
            <UpRightBoxLine1>
              {swapStore.toSwapFtAssetBalance && (
                <>
                  <BalanceIcon src={balanceIcon} />
                  <BalanceText>
                    {swapStore.toSwapFtAssetBalance.toFormat()}
                  </BalanceText>
                </>
              )}
            </UpRightBoxLine1>
            <SrcTradeInput
              placeholder="0.00"
              precision={7}
              maxValue={99999999}
              minValue={0}
              onChange={handleInputChange}
              value={swapStore.toSwapFtAssetQuantity}
              alignRight
              readonly={!swapStore.toSwapFtAsset}
              fontSize="18px"
              height={22}
            />
            <UsdText>
              {swapStore.toSwapFtAssetValue &&
                "≈ " + swapStore.toSwapFtAssetValue.toFormat() + " USD"}
            </UsdText>
          </UpRightBox>
        </UpBox>

        <Divider />

        <StyledExchangeButton onClick={handleExchange} />

        {/* Down */}
        <DownBox>
          {/* Down left */}
          <DownLeftBox>
            <DownLeftLine1>{t("To")}</DownLeftLine1>
            <DownLeftSelectAssetButton
              asset={swapStore.toReceiveFtAsset}
              chain={swapStore.chain}
              onClick={onToClick}
            />
          </DownLeftBox>

          {/* Down Right */}
          <DownRightBox>
            <DownRightBoxLine1>
              {swapStore.toReceiveFtAssetBalance && (
                <>
                  <BalanceIcon src={balanceIcon} />
                  <BalanceText>
                    {swapStore.toReceiveFtAssetBalance.toFormat()}
                  </BalanceText>
                </>
              )}
            </DownRightBoxLine1>
            <DstBalanceBox>
              {resultLoading ? (
                <EllipsisPlaceholder />
              ) : swapStore.toReceiveFtAssetQuantity ? (
                swapStore.toReceiveFtAssetQuantity.toFormat()
              ) : (
                <span className="placeholder">0.00</span>
              )}
            </DstBalanceBox>
            <UsdText>
              {resultLoading ? (
                <>
                  <EllipsisPlaceholder /> USD
                </>
              ) : (
                swapStore.toReceiveFtAssetValue &&
                "≈ " + swapStore.toReceiveFtAssetValue.toFormat() + " USD"
              )}
            </UsdText>
          </DownRightBox>
        </DownBox>
      </Container>
    );
  }
);
