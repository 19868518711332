import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import { RespAccountBalancesV2 } from "@zkkontos/kontos-sdk/src/api/assetApi";
import { SmartAccount } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/aa";
import {
  FtAssetV2 as KontosFtAsset,
  TaskPreview as KontosTaskPreview,
  TaskFull as KontosTaskFull,
  Account as KontosAccount,
  ChainConfig as KontosChainConfig,
  UniformedPayment,
} from "@zkkontos/kontos-sdk/src/api/types";
import { Dapp as KontosDapp } from "src/apis/types/DappTypes";
import Long from "long";
import {
  AssetChange as KontosAssetChange,
  SingerSendOrReceive,
} from "src/apis/types";

function isSmartAccount(
  account: KontosAccount | SmartAccount
): account is SmartAccount {
  return (account as SmartAccount).name !== undefined;
}

export const toKontosAccount = (
  account: KontosAccount | SmartAccount | string
): Account => {
  if (typeof account === "string") {
    return {
      id: -1,
      createdAt: "",
      updatedAt: "",
      name: account,
      pubKey: "",
      futurePubKey: "",
      recoveryPubKey: "",
      blockChainAccounts: {},
      guardians: [],
      futureGuardians: [],
      actionThreshold: 0,
      futureThreshold: 0,
      updateGuardiansDeadline: Long.fromValue(0).toNumber(),
      accountType: 0,
      accountStatus: 0,
      inviter: "",
      nameAddress: "",
      kontosAddress: "",
      blockHeight: new Long(0),
      blockTime: new Long(0),
      txHash: "",
      blockChainAccountsWithTime: {},
      inviterDetail: null,
      emailGuardians: [],
      futureEmailGuardians: [],
      scraperBlockChainAccounts: {},
    };
  }
  if (isSmartAccount(account)) {
    return {
      id: -1,
      createdAt: "",
      updatedAt: "",
      name: account.name,
      pubKey: account.pubKey,
      futurePubKey: account.futurePubKey,
      recoveryPubKey: account.recoveryPubKey,
      blockChainAccounts: account.blockchainAccounts,
      guardians: account.guardians,
      futureGuardians: account.futureGuardians,
      actionThreshold: account.actionThreshold,
      futureThreshold: account.futureThreshold,
      updateGuardiansDeadline: Long.fromValue(
        account.updateGuardiansDeadline
      ).toNumber(),
      accountType: account.accountType,
      accountStatus: account.accountStatus,
      inviter: account.inviter,
      nameAddress: KontosQueryCli.nameAddress(account.name),
      kontosAddress: KontosQueryCli.kontosAddress(account.name),
      blockHeight: new Long(0),
      blockTime: new Long(0),
      txHash: "",
      blockChainAccountsWithTime: {},
      inviterDetail: null,
      emailGuardians: account.emailGuardians,
      futureEmailGuardians: account.futureEmailGuardians,
      scraperBlockChainAccounts: {},
    };
  } else {
    return account as Account;
  }
};

export const toFtAsset = (data: UniformedPayment): FtAsset => {
  return {
    balanceId: -1,
    chainIndex: data.chainIndex,
    chainSymbol: data.chainSymbol,
    chainImageUrl: data.chainImageUrl,
    chainGreyImageUrl: data.chainGreyImageUrl,
    chainExplorerLinkURL: "",
    chainStatus: -1,
    address: data.assetAddress,
    name: data.assetName,
    symbol: data.assetSymbol,
    decimals: data.assetDecimals,
    imageUrl: data.assetImageUrl,
    usdPrice: data.assetUsdPrice,
    reserveIdUsd: "",
    h24Volume: "",
    assetType: -1,
    ftAssetId: -1,
    marketCapUsd: "",
    marketCapRank: -1,
    fdvUsd: "",
    h24VolumeUsd: "",
    h24High: "",
    h24Low: "",
    h24PriceChange: "",
    h24PriceChangePercentage: "",
    h24MarketCapChange: "",
    h24MarketCapChangePercentage: "",
    circulatingSupply: "",
    totalSupply: "",
    maxSupply: "",
    ath: "",
    athChangePercentage: "",
    athDate: "",
    atl: "",
    atlChangePercentage: "",
    atlDate: "",
    balance: "",
    totalUsd: "",
    isUsed: false,
    isGreatLiquidity: -1,
    coinGeckoCoinId: "",
    mappingOriginAssetId: -1,
    categories: [],
    isWhitelist: false,
    securityLevel: -1,
  };
};

export interface Account extends KontosAccount {}

export interface AccountBalances extends RespAccountBalancesV2 {}

export interface FtAsset extends KontosFtAsset {}

export interface ChainConfig extends KontosChainConfig {}

export interface TaskPreview extends KontosTaskPreview {}

export interface TaskFull extends KontosTaskFull {}

export enum TaskType {
  Swap = "Swap",
  Intention = "Intention",
  Transfer = "Transfer",
  OpCall = "OpCall",
  Proof = "Proof",
}

export enum ActivityType {
  Send = "Send",
  Receive = "Receive",
}

export const toActivityType = (
  singerSendOrReceive: SingerSendOrReceive
): ActivityType => {
  if (singerSendOrReceive === SingerSendOrReceive.Send)
    return ActivityType.Send;
  return ActivityType.Receive;
};

export interface Dapp extends KontosDapp {}

export interface AssetChange extends KontosAssetChange {}
