import styled from "styled-components";
import seperatorIco from "src/assets/icons/dapp/seperator.svg";
import pasteIco from "src/assets/icons/dapp/paste.svg";
import pasteActiveIco from "src/assets/icons/dapp/paste-active.svg";
import scanIco from "src/assets/icons/dapp/scan.svg";
import scanActiveIco from "src/assets/icons/dapp/scan-active.svg";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react-lite";

const Container = styled.div`
  position: relative;
  width: 88px;
  height: 32px;
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--White, #fff);

  display: flex;
  align-items: center;

  > .btn {
    height: 100%;
    flex: 1;
    cursor: pointer;

    &:hover {
      background-color: var(--Deep-50, #edeef1);
    }
    &:active {
      background-color: var(--Deep-50, #edeef1);
    }
  }

  > .left {
    position: relative;
    border-bottom-left-radius: 99px;
    border-top-left-radius: 99px;
    background-image: url(${pasteIco});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;

    &:hover {
      background-image: url(${pasteActiveIco});
    }

    > .active-signal {
      position: absolute;
      top: 5px;
      right: 10px;
      width: 4px;
      height: 4px;
      border-radius: 99px;
      background-color: var(--Success, #10ce5c);
    }
  }

  > .right {
    border-bottom-right-radius: 99px;
    border-top-right-radius: 99px;
    background-image: url(${scanIco});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;

    &:hover {
      background-image: url(${scanActiveIco});
    }
  }

  > .seperator {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface Props {
  className?: string;
  onPasteClick?: () => void;
  onScanClick?: () => void;
  onWrapperClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const LinkButton: React.FC<Props> = observer(
  ({ className, onPasteClick, onScanClick, onWrapperClick }) => {
    const { dappConnectStore } = useStores();
    const isActive = dappConnectStore.isActive;

    return (
      <Container className={className} onClick={onWrapperClick}>
        <div className="btn left" onClick={onPasteClick}>
          {isActive && <div className="active-signal" />}
        </div>
        <img className="seperator" src={seperatorIco} alt="" />
        <div className="btn right" onClick={onScanClick}></div>
      </Container>
    );
  }
);

export default LinkButton;
