import { theme } from "src/App";
import styled from "styled-components";

const StyledSvg = styled.svg<{ $isAvtive: boolean; $disable: boolean }>`
  path {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_100};
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      path {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }
    }
  }
`;

interface IProps {
  className?: string;
  size?: number;
  isActive?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

const HomeIcon: React.FC<IProps> = ({
  className,
  size,
  isActive = false,
  disable = false,
  onClick,
}) => {
  return (
    <StyledSvg
      $isAvtive={isActive}
      $disable={disable}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox={`0 0 28 28`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 12L14 4L1 12V25H10.75V19.5834C10.75 17.7885 12.2051 16.3334 14 16.3334C15.7949 16.3334 17.25 17.7885 17.25 19.5834V25H27L27 12Z"
        fill={theme.colors.__deep_100}
      />
    </StyledSvg>
  );
};

export default HomeIcon;
