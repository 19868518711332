import { useContext } from "react";
import { StoreContext } from "..";

export const useStores = () => {
  const {
    chainStore,
    uiStore,
    userStore,
    receiveStore,
    spotStore,
    swapStore,
    ebStore,
    dappConnectStore,
    discoverStore,
    sheetStore,
    marketStore,
    favStore,
    paymentStore,
    tradeStore,
    tipStore,
  } = useContext(StoreContext);
  const uiSettingStore = uiStore.uiSettingStore;
  return {
    chainStore,
    uiStore,
    userStore,
    receiveStore,
    swapStore,
    spotStore,
    uiSettingStore,
    ebStore,
    dappConnectStore,
    discoverStore,
    sheetStore,
    marketStore,
    favStore,
    paymentStore,
    tradeStore,
    tipStore,
  };
};
