import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import closeSvg from "src/assets/icons/close3.svg";
import MarketsRankingItem from "src/components/markets-ranking/MarketsRankingItem";
import {useStores} from "src/hooks/useStore";
import Star from "src/components/star/Star";

const Wrapper = styled.div<{}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999;

  .popup-modal__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.7;
  }

  .popup-modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 336px;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 10px 24px 24px;

    .close {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }

  .popup-modal__content-title {
    color: var(--Deep-800, #1A2535);
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    font-style: normal;
    margin-bottom: 16px;
  }

  .popup-modal__content-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .item {
      margin-bottom: 8px;
    }
  }

`;

type Props = {
  onConfirm: (item: any) => void;
  onCancel: () => void;
  onOutClick: () => void;
  title: string;
  selectedCategory?: number;
};

export const PopupMarketRankings: React.FC<Props> = ({
  onConfirm,
  onCancel,
  onOutClick,
  title,
  selectedCategory,
}) => {
  const theme = useContext(ThemeContext);
  const { marketStore} =
    useStores();

  useEffect(() => {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Wrapper>
      <div className="popup-modal__mask" onClick={() => onOutClick()}></div>
      <div className="popup-modal__content">
        <img
          className={'close'}
          src={closeSvg}
          onClick={() => onCancel()}
        />
        <div className="popup-modal__content-title">{title}</div>
        <div className="popup-modal__content-options">
          {marketStore.categoryArr?.map((item) => (
            <MarketsRankingItem className={`item ${selectedCategory === item.category ? 'item-active' : ''}`} key={item.symbol} onClick={() => {
              onConfirm(item)
            }}>
              {
                (item.symbol === 'Favorites') && <Star starStatus={"active"} />
              }
              {
                ((item.symbol === 'Recommended' || item.symbol === 'HOT')) && <img className={'img'} src={item.imageUrl} />
              }
              {
                (!(item.symbol === 'Favorites')! && !((item.symbol === 'Recommended' || item.symbol === 'HOT'))) && <span>{item.symbol}</span>
              }
            </MarketsRankingItem>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
