import * as ebApi from "src/apis/energy-boosting-apis";
import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { Account } from "src/type/zkkontos";
import { KontosClient } from "@zkkontos/kontos-sdk";
import { signForBe } from "src/utils/zkkontosHelper";
import { BE_CONNECTOR } from "src/type/common";
import {
  callBoost,
  callClaim,
  callDsicordAuth,
  callOpenEnergyOrb,
  callTwitterAuth,
} from "src/apis/energy-boosting-apis";
import { EB_SIGN_EXPIREDAT } from "src/config";
import { RootStore } from "../RootStore";
import { UserStore } from "../UserStore";

const CRON_INTERVAL = 30000;
export const AUTH_TIMEOUT = 100000;
type EbSignType =
  | "claim"
  | "boost"
  | "openEnergyOrb"
  | "authDiscord"
  | "authTwitter";

export class EbStore {
  rootStore: RootStore;
  accountNameCache?: string;
  ebAccount?: ebApi.RespEbAccount;
  ebAccountLoading: boolean = false;
  ebNotifications: ebTypes.Notification[] = [
    // {
    //   id: 1,
    //   account: "alexprod1",
    //   message: "kont125655577.os obtained 1501111 KOS in Energy ball",
    //   messageType: 1,
    //   messageTypeDesc: "hay",
    // },
  ];
  ebNotificationsLoading: boolean = false;
  ebInviterName?: string;
  ebInviterAccount?: ebApi.RespEbAccount;
  ebInviterAccountLoading: boolean = false;
  ebBoostMeList: ebTypes.BoostRecord[] = [];
  ebBoostMeLoading: boolean = false;
  ebBoostOthersList: ebTypes.BoostRecord[] = [];
  ebBoostOthersLoading: boolean = false;
  ebBackpack?: ebApi.RespBackpack;
  ebBackpackLoading: boolean = false;
  showHelp: boolean = false;

  inviterNameReactionDisposer?: IReactionDisposer;
  accountReactionDisposer?: IReactionDisposer;
  clientReactionDisposer?: IReactionDisposer;
  cronjobId: NodeJS.Timer | null = null;

  inviterEnergyIncreasement: number = 0;
  signType?: EbSignType;

  twitterPending: boolean = false;
  discordPending: boolean = false;

  startCronjob = async () => {
    this.cronjobId = setInterval(() => {
      this.fetchAndSetEbAccount();
      this.fetchAndSetEbNotifications();
      this.fetchAndSetEbBoostMeList();
      this.fetchAndSetEbBoostOthersList();
      this.fetchAndSetEbBackpack();
    }, CRON_INTERVAL);
  };

  stopCronjob = () => {
    if (this.cronjobId !== null) {
      clearInterval(this.cronjobId);
      this.cronjobId = null;
    }
  };

  startTrackingAccountInfo(userStore: UserStore) {
    // If statusKeeper already loaded, execute at once
    if (userStore.accountInfo) {
      this.accountNameCache = userStore.accountInfo.name;
      this.fetchAndSetEbAccount();
      this.fetchAndSetEbBoostMeList();
      this.fetchAndSetEbBoostOthersList();
      this.fetchAndSetEbBackpack();
    }

    this.accountReactionDisposer = reaction(
      () => userStore.accountName,
      (accountName) => {
        //Need to init other data at once when first time account info is loaded
        if (this.accountNameCache !== accountName) {
          this.accountNameCache = accountName;
          this.fetchAndSetEbAccount();
          this.fetchAndSetEbBoostMeList();
          this.fetchAndSetEbBoostOthersList();
          this.fetchAndSetEbBackpack();
        } else {
          this.accountNameCache = accountName;
        }
        this.discordPending = false;
        this.twitterPending = false;
      }
    );
  }

  startTrackingInviterName() {
    this.inviterNameReactionDisposer = reaction(
      () => this.ebInviterName,
      () => {
        this.fetchAndSetEbInviterAccount();
      }
    );
  }

  stopObserving() {
    this.accountReactionDisposer?.();
    this.clientReactionDisposer?.();
    this.inviterNameReactionDisposer?.();
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  clear() {
    this.stopObserving();
    this.stopCronjob();
    this.ebAccount = undefined;
    this.ebAccountLoading = false;
    this.ebNotifications = [];
    this.ebNotificationsLoading = false;
    this.ebBoostMeList = [];
    this.ebBoostMeLoading = false;
    this.ebBoostOthersList = [];
    this.ebBoostOthersLoading = false;
    this.ebBackpack = undefined;
    this.ebBackpackLoading = false;
    this.inviterNameReactionDisposer = undefined;
    this.accountReactionDisposer = undefined;
    this.clientReactionDisposer = undefined;
    this.cronjobId = null;
    this.signType = undefined;
  }

  fetchAndSetEbInviterAccount = async () => {
    if (!this.ebInviterName) {
      return;
    }
    try {
      this.ebInviterAccountLoading = true;
      const resp = await ebApi.callEbAccount({
        account: this.ebInviterName,
      });
      runInAction(() => {
        this.ebInviterAccount = resp;
        this.ebInviterAccountLoading = false;
      });
    } catch (e) {
      this.ebInviterAccountLoading = false;
      console.log("Failed to load Energy Boosting Account", e);
    }
  };

  // This is to mock energy increasement for inviter
  // addEnergyToEbInviterAccount = (energyValue: number) => {
  //   if (!this.ebInviterAccount) return;
  //   const deepCopiedInviter = cloneDeep(this.ebInviterAccount);
  //   deepCopiedInviter.energyValue += energyValue;
  // };

  // This method can only be called once
  addEnergyToEbInviterAccount = (energyValue: number) => {
    if (this.inviterEnergyIncreasement === 0) {
      this.inviterEnergyIncreasement += energyValue;
    }
  };

  fetchAndSetEbAccount = async () => {
    if (!this.rootStore.userStore.accountInfo) {
      return;
    }
    try {
      this.ebAccountLoading = true;
      const resp = await ebApi.callEbAccount({
        account: this.rootStore.userStore.accountInfo.name,
      });
      runInAction(() => {
        this.ebAccount = resp;
        this.ebAccountLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.ebAccountLoading = false;
      });
      console.log("Failed to load Energy Boosting Account", e);
    }
  };

  fetchAndSetEbNotifications = async (limit = 10) => {
    try {
      this.ebNotificationsLoading = true;
      const resp = await ebApi.callEbNotification({
        account: "",
        limit: limit,
      });
      runInAction(() => {
        this.ebNotifications = resp.notifications || [];
        this.ebNotificationsLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.ebNotificationsLoading = false;
      });
      console.log("Failed to load Energy Boosting Notifications", e);
    }
  };

  fetchAndSetEbBoostMeList = async () => {
    if (!this.rootStore.userStore.accountInfo) {
      return;
    }
    try {
      this.ebBoostMeLoading = true;
      const resp = await ebApi.callBoostHistory({
        account: this.rootStore.userStore.accountInfo.name,
        isBoostee: 1,
      });
      runInAction(() => {
        this.ebBoostMeList = resp.boostRecords;
        this.ebBoostMeLoading = false;
      });
    } catch (e) {
      this.ebBoostMeLoading = false;
      console.log("Failed to load boostee history", e);
    }
  };

  fetchAndSetEbBoostOthersList = async () => {
    if (!this.rootStore.userStore.accountInfo) {
      return;
    }
    try {
      this.ebBoostOthersLoading = true;
      const resp = await ebApi.callBoostHistory({
        account: this.rootStore.userStore.accountInfo.name,
        isBoostee: 0,
      });
      runInAction(() => {
        this.ebBoostOthersList = resp.boostRecords;
        this.ebBoostOthersLoading = false;
      });
    } catch (e) {
      this.ebBoostOthersLoading = false;
      console.log("Failed to load booster history", e);
    }
  };

  fetchAndSetEbBackpack = async () => {
    if (!this.rootStore.userStore.accountInfo) {
      return;
    }
    try {
      this.ebBackpackLoading = true;
      const resp = await ebApi.callBackpack({
        account: this.rootStore.userStore.accountInfo.name,
      });
      runInAction(() => {
        this.ebBackpack = resp;
        this.ebBackpackLoading = false;
      });
    } catch (e) {
      this.ebBackpackLoading = false;
      console.log("Failed to load backpack", e);
    }
  };

  setInviterName = (name: string | undefined) => {
    this.ebInviterName = name;
  };

  // fallback should throw an error
  checkBeforeSign = (
    signType: EbSignType,
    noAccountFallback: () => void,
    noCliFallback: () => void
  ): { account: Account; cli: KontosClient } => {
    if (!this.rootStore.userStore.accountInfo) {
      noAccountFallback();
    }
    if (!this.rootStore.userStore.kontosCli) {
      this.signType = signType;
      noCliFallback();
    }
    return {
      account: this.rootStore.userStore.accountInfo!,
      cli: this.rootStore.userStore.kontosCli!,
    };
  };

  openEnergyOrb = async (
    energyOrbType: number,
    noAccountFallback: () => void,
    noCliFallback: () => void
  ): Promise<ebTypes.Reward> => {
    const checkRes = this.checkBeforeSign(
      "openEnergyOrb",
      noAccountFallback,
      noCliFallback
    );

    const { account, cli } = checkRes;

    const data = account.name + BE_CONNECTOR + energyOrbType.toString();
    const { signature, expiredAt } = await signForBe(
      this.rootStore.userStore.accountInfo?.name!,
      account.kontosAddress,
      cli,
      data,
      EB_SIGN_EXPIREDAT
    );
    const resp = await callOpenEnergyOrb({
      account: account.name,
      energyOrbType: energyOrbType,
      expiredAt: expiredAt,
      signature: signature,
    });
    return resp.reward;
  };

  claim = async (
    threshold: number,
    noAccountFallback: () => void,
    noCliFallback: () => void
  ) => {
    const checkRes = this.checkBeforeSign(
      "claim",
      noAccountFallback,
      noCliFallback
    );
    if (!checkRes) {
      return;
    }
    const { account, cli } = checkRes;

    const data = account.name + BE_CONNECTOR + threshold.toString();
    const { signature, expiredAt } = await signForBe(
      this.rootStore.userStore.accountInfo?.name!,
      account.kontosAddress,
      cli,
      data,
      EB_SIGN_EXPIREDAT
    );
    await callClaim({
      account: account.name,
      threshold: threshold,
      expiredAt: expiredAt,
      signature: signature,
    });
  };

  boost = async (
    noInviterFallback: () => void,
    noAccountFallback: () => void,
    noCliFallback: () => void
  ): Promise<number> => {
    if (!this.ebInviterName) {
      noInviterFallback();
    }

    const checkRes = this.checkBeforeSign(
      "boost",
      noAccountFallback,
      noCliFallback
    );

    const { account, cli } = checkRes;

    const data = account.name + BE_CONNECTOR + this.ebInviterName!.toString();
    const { signature, expiredAt } = await signForBe(
      this.rootStore.userStore.accountInfo?.name!,
      account.kontosAddress,
      cli,
      data,
      EB_SIGN_EXPIREDAT
    );
    const resp = await callBoost({
      booster: account.name,
      boostee: this.ebInviterName!,
      expiredAt: expiredAt,
      signature: signature,
    });
    return resp.boostValue;
  };

  authTwitter = async (
    noAccountFallback: () => void,
    noCliFallback: () => void
  ): Promise<string> => {
    const checkRes = this.checkBeforeSign(
      "authTwitter",
      noAccountFallback,
      noCliFallback
    );

    const { account, cli } = checkRes;

    const data = account.name;
    const { signature, expiredAt } = await signForBe(
      this.rootStore.userStore.accountInfo?.name!,
      account.kontosAddress,
      cli,
      data,
      EB_SIGN_EXPIREDAT
    );

    const resp = await callTwitterAuth({
      account: account.name,
      expiredAt: expiredAt,
      signature: signature,
    });
    return resp.authUrl;
  };

  authDiscord = async (
    noAccountFallback: () => void,
    noCliFallback: () => void
  ): Promise<string> => {
    const checkRes = this.checkBeforeSign(
      "authDiscord",
      noAccountFallback,
      noCliFallback
    );

    const { account, cli } = checkRes;

    const data = account.name;
    const { signature, expiredAt } = await signForBe(
      this.rootStore.userStore.accountInfo?.name!,
      account.kontosAddress,
      cli,
      data,
      EB_SIGN_EXPIREDAT
    );

    const resp = await callDsicordAuth({
      account: account.name,
      expiredAt: expiredAt,
      signature: signature,
    });
    return resp.authUrl;
  };

  get credit(): string | undefined {
    return this.ebAccount?.credit?.toString() || undefined;
  }

  get creditLevel(): string | undefined {
    return this.ebAccount?.creditLevel?.toString() || undefined;
  }

  get creditPercentage(): number | undefined {
    return this.ebAccount?.credit && this.ebAccount?.maxStageCreditValue
      ? this.ebAccount.credit / this.ebAccount.maxStageCreditValue
      : undefined;
  }

  get energyBallCount(): string | undefined {
    return this.ebBackpack
      ? this.ebBackpack?.energyOrbs?.length?.toString() || "0"
      : undefined;
  }

  get currentBarStage(): ebTypes.EnergyBarStage | undefined {
    return this.ebAccount?.energyBar?.stages
      ?.filter((item) =>
        item.thresholds.some((subItem) => subItem.claimStatus === 0)
      )
      ?.sort((itemA, itemB) => itemA.start - itemB.start)?.[0];
  }

  get currentThreshold(): ebTypes.EnergyThreshold | undefined {
    if (!this.ebAccount || !this.currentBarStage) return undefined;
    return this.currentBarStage.thresholds
      .slice()
      .reverse()
      .find((item) => this.ebAccount!.energyValue >= item.value);
  }

  get inviterCurrentBarStage(): ebTypes.EnergyBarStage | undefined {
    return this.ebInviterAccount?.energyBar?.stages
      ?.filter((item) =>
        item.thresholds.some((subItem) => subItem.claimStatus === 0)
      )
      ?.sort((itemA, itemB) => itemA.start - itemB.start)?.[0];
  }

  get inviterNextOrb(): ebTypes.EnergyThreshold | undefined {
    return (
      this.ebInviterAccount?.energyBar?.stages
        ?.filter((item) => item.end > (this.ebInviterAccount?.energyValue || 0))
        ?.sort((itemA, itemB) => itemA.start - itemB.start)?.[0]
        ?.thresholds?.find(
          (threshold) =>
            threshold?.value > (this.ebInviterAccount?.energyValue || 0)
        ) || undefined
    );
  }

  get inviterNextOrbNumber(): number | undefined {
    return this.inviterNextOrb?.energyOrbsRewardCount || undefined;
  }

  get inviterNextOrbType(): number | undefined {
    return this.inviterNextOrb?.energyOrbRewardType || undefined;
  }

  get inviterEnergyValue(): number | undefined {
    return this.ebInviterAccount?.energyValue;
  }

  get inviterEnergyValueWithIncreasement(): number | undefined {
    return this.ebInviterAccount
      ? this.ebInviterAccount.energyValue + this.inviterEnergyIncreasement
      : undefined;
  }

  get inviterEnergyPercent(): number | undefined {
    return this.ebInviterAccount && this.inviterCurrentBarStage
      ? Math.min(
          Math.floor(
            (this.ebInviterAccount?.energyValue /
              (this.inviterCurrentBarStage?.end -
                this.inviterCurrentBarStage.start)) *
              100
          ),
          100
        )
      : undefined;
  }

  get inviterEnergyPercentV2(): number | undefined {
    if (!this.inviterCurrentBarStage) return undefined;
    if (!this.ebInviterAccount) return undefined;
    const lastIndex = this.inviterCurrentBarStage.thresholds
      .slice()
      .reverse()
      .findIndex((item) => this.ebInviterAccount!.energyValue >= item.value);
    const currentThresholdIndex =
      lastIndex >= 0
        ? this.inviterCurrentBarStage.thresholds.length - 1 - lastIndex
        : 0;
    // reach max
    if (
      this.inviterCurrentBarStage.thresholds.length - 1 ===
      currentThresholdIndex
    ) {
      return 100;
    }
    // not reach min
    if (
      this.ebInviterAccount!.energyValue <
      this.inviterCurrentBarStage.thresholds[currentThresholdIndex].value
    ) {
      return Math.min(
        Math.floor(
          (100 * this.ebInviterAccount!.energyValue) /
            this.inviterCurrentBarStage.thresholds[currentThresholdIndex].value
        ),
        100
      );
    }
    return Math.min(
      Math.floor(
        (100 *
          (this.ebInviterAccount!.energyValue -
            this.inviterCurrentBarStage.thresholds[currentThresholdIndex]
              .value)) /
          (this.inviterCurrentBarStage.thresholds[currentThresholdIndex + 1]
            .value -
            this.inviterCurrentBarStage.thresholds[currentThresholdIndex].value)
      ),
      100
    );
  }

  get inviterEnergyPercentWithIncreasement(): number | undefined {
    return this.ebInviterAccount && this.inviterCurrentBarStage
      ? Math.min(
          Math.floor(
            ((this.ebInviterAccount?.energyValue +
              this.inviterEnergyIncreasement) /
              (this.inviterCurrentBarStage?.end -
                this.inviterCurrentBarStage.start)) *
              100
          ),
          100
        )
      : undefined;
  }

  get inviterEnergyPercentWithIncreasementV2(): number | undefined {
    if (!this.inviterCurrentBarStage) return undefined;
    if (!this.ebInviterAccount) return undefined;
    const lastIndex = this.inviterCurrentBarStage.thresholds
      .slice()
      .reverse()
      .findIndex((item) => this.ebInviterAccount!.energyValue >= item.value);
    const currentThresholdIndex =
      lastIndex >= 0
        ? this.inviterCurrentBarStage.thresholds.length - 1 - lastIndex
        : 0;
    // reach max
    if (
      this.inviterCurrentBarStage.thresholds.length - 1 ===
      currentThresholdIndex
    ) {
      return 100;
    }
    // not reach min
    if (
      this.ebInviterAccount!.energyValue + this.inviterEnergyIncreasement <
      this.inviterCurrentBarStage.thresholds[currentThresholdIndex].value
    ) {
      const res = Math.min(
        Math.floor(
          (100 *
            (this.ebInviterAccount!.energyValue +
              this.inviterEnergyIncreasement)) /
            this.inviterCurrentBarStage.thresholds[currentThresholdIndex].value
        ),
        100
      );
      return res;
    }
    return Math.min(
      Math.floor(
        (100 *
          (this.ebInviterAccount?.energyValue +
            this.inviterEnergyIncreasement -
            this.inviterCurrentBarStage.thresholds?.[currentThresholdIndex]
              ?.value || 0)) /
          (this.inviterCurrentBarStage.thresholds[currentThresholdIndex + 1]
            .value -
            this.inviterCurrentBarStage.thresholds?.[currentThresholdIndex]
              .value || 0)
      ),
      100
    );
  }

  get realBarStage(): ebTypes.EnergyBarStage | undefined {
    return (
      this.ebAccount?.energyBar?.stages
        ?.filter((item) => item.end > (this.ebAccount?.energyValue || 0))
        ?.sort((itemA, itemB) => itemA.start - itemB.start)
        ?.find((item) =>
          item.thresholds.some((subItem) => subItem.claimStatus === 0)
        ) || undefined
    );
  }

  get isDiscordSuccess(): boolean {
    return this.ebAccount
      ? this.ebAccount.accountActions.actions.some((action) =>
          action.actionItems.some(
            (item) =>
              item.actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD &&
              item.actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS
          )
        )
      : false;
  }

  get isTwitterSuccess(): boolean {
    return this.ebAccount
      ? this.ebAccount.accountActions.actions.some((action) =>
          action.actionItems.some(
            (item) =>
              item.actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER &&
              item.actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS
          )
        )
      : false;
  }

  get isAnySocialAccountLinked(): boolean {
    return this.isDiscordSuccess || this.isTwitterSuccess;
  }

  get sortedActions(): ebTypes.Actions[] | undefined {
    if (!this.ebAccount) return undefined;
    const copy = [...this.ebAccount.accountActions.actions];
    return copy.sort((a, b) => {
      const aHasSend = a.categoryDesc.toLocaleLowerCase().includes("send");
      const bHasSend = b.categoryDesc.toLocaleLowerCase().includes("send");
      if (aHasSend && !bHasSend) {
        return 1;
      } else if (!aHasSend && bHasSend) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  setShowHelp = (show: boolean) => {
    this.showHelp = show;
  };

  setTwitterPending = (pending: boolean) => {
    this.twitterPending = pending;
  };

  setDiscordPending = (pending: boolean) => {
    this.discordPending = pending;
  };
}
