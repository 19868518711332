import React, { CSSProperties } from "react";
import styled from "styled-components";
import CSS from 'csstype';

const Line = styled.div<LineProps>`
  width: 100%;
  height: ${({ height }) => height || "1px"};
  background-color: #edeef1;
`;

interface LineProps {
  height?: string;
  styles?: CSS.Properties
}

const HorizontalLine = ({ height = "1px", styles }: LineProps) => {
  return <Line style={{
    ...styles
  }} height={height} />;
};

export default HorizontalLine;
