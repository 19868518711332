import React, { useEffect, useState } from "react";
import styled from "styled-components";
import arrowIco from "../../assets/icons/arrow9.svg";
import kontosAvatarIco from "../../assets/images/kontos-avatar.svg";
import dotGreenIco from "../../assets/icons/dotGreen.svg";
import rejectIco from "../../assets/icons/reject.svg";
import { Button } from "../../components/button/Button";
import { fmDate } from "../../utils/helper";
import { ThresholdAction } from "@zkkontos/kontos-sdk/src/api/types";

const GuardianItemMain = styled.div`
  height: 126px;
  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);
  box-sizing: border-box;
  padding: 11px 12px 12px 12px;
  transition: all 0.3s;
  width: 100%;
  transform: translateX(0);

  .recoverAccount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .l {
      span {
        color: var(--Kontos-Blue, #413dec);

        /* Description */
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .r {
      cursor: pointer;

      span {
        margin-left: 4px;
      }

      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--error-notice, #ff1e2b);
      text-align: right;

      /* Description */
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .accountInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;

    img {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      margin-right: 12px;
    }

    span {
      overflow: hidden;
      color: var(--Deep-800, #1a2535);
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: HarmonyOS Sans SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .approve {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time {
      color: var(--Deep-400, #80868f);
      /* Description */
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .btn {
      width: 107px;
      height: 29px;
      flex-shrink: 0;
    }
    .approveStatus {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-top: 10px;
      img {
        margin-right: 7px;
      }
      text-align: right;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const GuardianItemStyle = styled.div`
  position: relative;
  margin-bottom: 20px;

  .rejectBtn {
    position: absolute;
    right: 0;
    top: 0;
    width: 82px;
    height: 126px;
    flex-shrink: 0;
    transition: width 0.3s;
    overflow: hidden;
    cursor: pointer;

    > img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

interface iProps {
  itemData: ThresholdAction;
  name: string;
  onDelete: (params0: ThresholdAction) => void;
  onApprove: (params0: ThresholdAction) => void;
}

const ApproveItem: React.FC<iProps> = ({
  itemData,
  name,
  onDelete,
  onApprove,
}) => {
  const [openReject, setOpenReject] = useState(false);
  useEffect(() => {
    if (itemData.status === 1) setOpenReject(false);
  }, [itemData.status]);

  return (
    <GuardianItemStyle>
      <GuardianItemMain
        style={{
          transform: openReject ? "translateX(-92px)" : "translateX(0)",
        }}
      >
        <div className={"recoverAccount"}>
          <div className={"l"}>
            <span>· Recover account</span>
          </div>
          {itemData.status === 0 ? (
            <div className={"r"} onClick={() => setOpenReject(!openReject)}>
              <img src={arrowIco} />
              <img src={arrowIco} />
              <span>Reject</span>
            </div>
          ) : (
            <div className={"r"}>
              <span style={{ color: "var(--Deep-400, #80868F)" }}>-</span>
            </div>
          )}
        </div>
        <div className={"accountInfo"}>
          <img src={kontosAvatarIco} />
          <span>{name}.os</span>
        </div>
        <div className={"approve"}>
          <div className={"time"}>
            {fmDate(parseInt(itemData?.blockTime.toString()))}
          </div>
          {itemData.status === 0 && (
            <div className={"btn"}>
              <Button
                onClick={() => {
                  onApprove(itemData);
                }}
                wrapperStyle={{
                  borderRadius: "99px",
                  fontSize: "14px",
                  height: "30px",
                  lineHeight: "30px",
                }}
                text={"Approve"}
              />
            </div>
          )}
          {itemData.status === 1 && (
            <div
              className={"approveStatus"}
              style={{ color: "var(--Deep-400, #80868F)" }}
            >
              <img src={dotGreenIco} />
              Completed
            </div>
          )}
        </div>
      </GuardianItemMain>
      <div className={"rejectBtn"} style={{ width: openReject ? "82px" : "0" }}>
        <img
          src={rejectIco}
          onClick={() => {
            setOpenReject(false);
            onDelete(itemData);
          }}
        />
      </div>
    </GuardianItemStyle>
  );
};

export default ApproveItem;
