import CircleIconPair from "src/components/icons/CircleIconPair";
import { FtAsset } from "@/type/zkkontos";
import styled from "styled-components";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import { SingleIconButton } from "src/components/button/SingleIconButton";

const Container = styled.div`
  width: 100%;
  height: 48px;
  padding: 8px 24px 8px 8px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const LeftTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  white-space: nowrap;
`;

const AssetSymbol = styled.span`
  height: 16px;
  line-height: 16px;
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
`;

const ChainSymbol = styled.span`
  height: 14px;
  line-height: 14px;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans";
  font-size: 12px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

interface IProps {
  asset: FtAsset;
  onEdit: () => void;
  onDelete: () => void;
}

export const BatchSellItem: React.FC<IProps> = ({
  asset,
  onEdit,
  onDelete,
}) => {
  return (
    <Container>
      <Left>
        <CircleIconPair
          mainIcon={asset?.imageUrl || defaultTokenIcon}
          mainIconFallbackSrc={defaultTokenIcon}
          subIcon={asset?.chainGreyImageUrl || defaultChainIcon}
          subIconFallbackSrc={defaultChainIcon}
          mainWidth={32}
          mainHeight={32}
          subWidth={16}
          subHeight={16}
          totalWidth={40}
          totalHeight={32}
        />

        <LeftTextBox>
          <AssetSymbol>{asset.symbol}</AssetSymbol>
          <ChainSymbol>{asset.chainSymbol}</ChainSymbol>
        </LeftTextBox>
      </Left>

      <Right>
        <SingleIconButton type="edit" onClick={onEdit} />
        <SingleIconButton type="delete" onClick={onDelete} />
      </Right>
    </Container>
  );
};
