import styled from "styled-components";

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 10px;
  box-sizing: border-box;
  gap: 24px;
  position: relative;
  user-select: none;

  .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: auto;
    cursor: pointer;
    color: #80868f;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    .ico {
      width: 19px;
      height: 19px;
      margin-right: 4px;
    }

    .icon-selected {
      display: none;
      width: 19px;
      height: 19px;
      margin-right: 4px;
    }

    :hover {
      color: var(--Kontos-Blue, #413dec);

      .icon-selected {
        display: block;
      }

      .icon {
        display: none;
      }
    }
  }

  .current {
    position: relative;
    color: var(--Kontos-Blue, #413dec);
    font-weight: 700;

    .icon-selected {
      display: block;
    }

    .icon {
      display: none;
    }

    :after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 24px;
      height: 4px;
      background: var(--Kontos-Blue, #413dec);
      border-radius: 99px;
    }
  }
`;
