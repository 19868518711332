import styled from "styled-components";
import AllChainIcon from "../../../assets/images/all-chain-icon.png";
import { t } from "i18next";
import React from "react";
import { FtAssetTypeV2 } from "@zkkontos/kontos-sdk/src/api/types";
import { FtAsset } from "@/type/zkkontos";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import defaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import tickSvg from "src/assets/icons/tick.svg";
import { getDisplayAmount, getFluctuate } from "src/utils/helper";
import Star from "src/components/star/Star";
import { AssetSecurityIcon } from "src/pages/trade/asset-select/AssetSecurityIcon";
import FavoriteButton from "src/components/button/FavoriteButton";

export const Wrapper = styled.div`
  width: 100%;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 16px;
    padding: 8px 8px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #000d1f;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 8px;

    @media (hover: hover) {
      :hover {
        background: var(--Deep-25, #f5f5f6);
      }
    }

    .rank {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      width: 14px;

      > div {
        color: var(--Deep-200, #b2b6bc);
        text-align: center;
        font-family: "HarmonyOS Sans Bold";
        font-size: 14px;
      }

      .rankIco {
      }

      .rankText {
      }
    }

    .rank-1,
    .rank-2 {
      > div {
        color: var(--error-notice, #ff1e2b);
      }
    }

    .rank-3 {
      > div {
        color: var(--Warning, #faad14);
      }
    }

    .box {
      position: relative;
      margin-right: 18px;
      height: 32px;

      .coin {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .icon {
        position: absolute;
        width: 16px;
        height: 16px;
        bottom: 0;
        left: 23px;
        border-radius: 50%;
      }
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .symbol {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .verified {
        margin-left: 4px;
      }

      .fluctuate {
        text-overflow: ellipsis;
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
        margin-right: 6px;
      }

      .fluctuate.bullish {
        color: var(--Success, #10ce5c);
      }

      .fluctuate.bearish {
        color: var(--error-notice, #ff1e2b);
      }

      .desc {
        margin-top: 2px;
        color: #999ea5;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
      }
    }

    .value {
      margin-left: auto;
      text-align: right;
      font-weight: 700;
    }
  }

  .no-data {
    margin-top: 72px;
    width: 100%;
    height: 200px;
  }
`;

type IProps = {
  item: FtAssetTypeV2;
  handleClick?(): void;
  index?: number;
  showRanking?: boolean;
  showStar?: boolean;
  isStared?: boolean;
  isVerified?: boolean;
  isMarket?: boolean;
  usdPrice?: string;
  chainUrl?: string;
  fluctuate?: string;
  chainSymbol?: string;
  volumeUnit?: string;
  isGreatLiquidity?: number;
  securityLevel?: number;
  onFavClick?: () => void;
};

export const getTotalAmountFromData = (ftAssets: FtAsset[]) => {
  return ftAssets?.reduce((acc, asset) => {
    return acc + BigInt(asset.balance);
  }, BigInt(0));
};

export const AssetsList = ({
  handleClick,
  item: { ftAssets, imageUrl, symbol, totalUsd },
  index,
  showRanking,
  showStar,
  isStared,
  isVerified,
  isMarket,
  usdPrice,
  chainUrl,
  chainSymbol,
  fluctuate,
  volumeUnit,
  isGreatLiquidity,
  securityLevel,
  onFavClick,
}: IProps) => {
  const totalAmount = getTotalAmountFromData(ftAssets);
  const fluctuateNumber = getFluctuate(fluctuate || "0", 16);

  return (
    <Wrapper>
      <div
        className="item"
        onClick={() => {
          handleClick && handleClick();
        }}
      >
        {showRanking && (
          <div className={`rank rank-${(index || 0) + 1}`}>
            <div className={"rankIco"}>{index === 0 ? "🔥" : " "}</div>
            <div className={"rankText"}>{(index || 0) + 1}</div>
          </div>
        )}
        {showStar && (
          // <Star
          //   styles={{ marginRight: "12px" }}
          //   starStatus={!isStared ? "default" : "active"}
          // />
          <FavoriteButton
            style={{ marginRight: "12px" }}
            isFavorite={isStared || false}
            onClick={(e) => {
              onFavClick?.();
              e.stopPropagation();
            }}
          />
        )}
        <div className="box">
          <ImageWithFallback
            className="coin"
            src={imageUrl || defaultTokenSvg}
            fallbackSrc={defaultTokenSvg}
          />
          <ImageWithFallback
            src={chainUrl || AllChainIcon}
            fallbackSrc={AllChainIcon}
            className="icon"
            alt="icon"
          />
        </div>
        <div className="label">
          <div className={"symbol"}>
            {symbol}
            {/* {isVerified && <img className={"verified"} src={tickSvg} />} */}
            {typeof isGreatLiquidity === "number" &&
              typeof securityLevel === "number" && (
                <AssetSecurityIcon
                  style={{ marginLeft: "4px" }}
                  isGreatLiquidity={isGreatLiquidity}
                  securityLevel={securityLevel}
                  disableHoverToClick
                />
              )}
          </div>
          <div className="desc">{chainSymbol || t("Multi-chain")}</div>
        </div>
        <div className="label value">
          <div>
            {!!fluctuate && (
              <span
                className={`fluctuate ${
                  fluctuateNumber > 0 ? "bullish" : "bearish"
                }`}
              >
                {fluctuateNumber > 0 ? "↑" : "↓"} {fluctuateNumber}%
              </span>
            )}
            {!!fluctuate && "$"}
            {getDisplayAmount(
              !isMarket ? totalAmount?.toString() : usdPrice?.toString(),
              {
                isAutomateProcessAllTypes: true,
              }
            )}
          </div>
          {volumeUnit ? (
            <div className="desc">
              {volumeUnit}
              {getDisplayAmount(totalUsd, {
                isAutomateProcessAllTypes: true,
              })}
              &nbsp;USD
            </div>
          ) : (
            <div className="desc">
              $
              {getDisplayAmount(totalUsd, {
                isAutomateProcessAllTypes: true,
              })}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
