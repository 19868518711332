import styled from "styled-components";

// Colors
export const colors = {
  kontos: "var(--Kontos-Blue)",
  white: "var(--White)",
  deep25: "var(--Deep-25)",
  deep50: "var(--Deep-50)",
  deep100: "var(--Deep-100)",
  deep200: "var(--Deep-200)",
  deep400: "var(--Deep-400)",
  deep800: "var(--Deep-800)",
  success: "var(--Success)",
  error: "var(--Error)",
  warning: "var(--Warning)",
};

// Font families
export const fonts = {
  regular: "HarmonyOS Sans",
  bold: "HarmonyOS Sans Bold",
};

// Base text component
export const BaseText = styled.span<{ color?: string; fontFamily?: string }>`
  color: ${(props) => props.color || colors.deep400};
  font-family: ${(props) => props.fontFamily || fonts.regular};
`;

// Text components with specific styles
export const SmallerText = styled(BaseText)`
  font-size: 12px;
  line-height: 14px;
`;

export const DescriptionText = styled(BaseText)`
  font-size: 14px;
  line-height: 16px;
`;

export const MainText = styled(BaseText)`
  font-size: 16px;
  line-height: 21px;
`;

export const SubtitleText = styled(BaseText)`
  font-size: 18px;
  line-height: 22px;
`;

export const H8Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 12px;
  line-height: 14px;
`;

export const H7Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 14px;
  line-height: 16px;
`;

export const H6Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 16px;
  line-height: 20px;
`;

export const H5Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 18px;
  line-height: 22px;
`;

export const H4Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 20px;
  line-height: 24px;
`;

export const H3Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 24px;
  line-height: 28px;
`;

export const H2Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 28px;
  line-height: 34px;
`;

export const H1Text = styled(BaseText)`
  color: ${(props) => props.color || colors.deep800};
  font-family: ${(props) => props.fontFamily || fonts.bold};
  font-size: 36px;
  line-height: 42px;
`;
