import styled from "styled-components";
import CountUp from "react-countup";
import lightningIco from "src/assets/icons/events/eb/lightning.svg";
import subscriptIco from "src/assets/icons/events/eb/subscript.svg";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import pinIco from "src/assets/icons/events/eb/pin.svg";

const Container = styled(motion.div)`
  position: relative;
  width: 200px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  > .pinIco {
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translate(-50%, 0);
  }
`;

const SubContainer = styled(motion.div)<{ $isPercentage: boolean }>`
  border-radius: 99px;
  background: var(--Deep-800, #1a2535);
  padding: ${(props) => (props.$isPercentage ? "1px 12px" : "4px 12px")};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > .number {
    height: 14px;
    line-height: 14px;
    color: var(--White, #fff);
    font-family: ${(props) =>
      props.$isPercentage ? "HarmonyOS Sans" : "HarmonyOS Sans Bold"};
    font-size: 12px;
    font-weight: 400;
  }

  > .percent {
    height: 14px;
    line-height: 14px;
    color: var(--White, #fff);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }

  > .lightningIco {
    margin-left: 2px;
    width: 9px;
    height: ${(props) => (props.$isPercentage ? "16px" : "14px")};
  }

  > .subscriptIco {
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
  }
`;

interface Props {
  className?: string;
  leftDistance: number;
  animationDuraion: number;
  countUpStart: number;
  countUpEnd: number;
  countUpDuration: number;
  finished?: boolean;
  isPercentage?: boolean;
}

const EbBarTip: React.FC<Props> = memo(
  ({
    className,
    leftDistance,
    animationDuraion,
    countUpStart,
    countUpEnd,
    countUpDuration,
    finished = true,
    isPercentage = false,
  }) => {
    const controller = useAnimationControls();
    const subController = useAnimationControls();
    const ref = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const [animationEnd, setAnimationEnd] = useState<boolean>(false);

    const draw = useMemo(() => {
      return {
        init: {
          x: "0",
        },
        animate: {
          x: `calc(${leftDistance}px - 50%)`,
          transition: {
            x: { type: "spring", duration: animationDuraion, bounce: 0 },
          },
        },
        subInit: {
          rotate: -60,
        },
        subAnimate: {
          rotate: 0,
          transition: {
            rotate: { type: "spring", duration: animationDuraion },
          },
        },
      };
    }, [animationDuraion, leftDistance]);

    useEffect(() => {
      if (leftDistance > 0) {
        // controller.set(draw.init);
        controller.start(draw.animate);
        // subController.set(draw.subInit);
        // subController.start(draw.subAnimate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leftDistance]);

    const handleCountEnd = useCallback(() => {
      setAnimationEnd(true);
    }, []);

    return (
      <Container
        ref={ref}
        className={className}
        animate={controller}
        initial={{ x: "-50%" }}
      >
        <SubContainer
          $isPercentage={isPercentage}
          style={{ transformOrigin: "bottom center" }}
          animate={subController}
        >
          {finished && animationEnd ? (
            <div>{t("Finished")}</div>
          ) : (
            <>
              <CountUp
                className="number"
                useGrouping={false}
                start={countUpStart}
                end={countUpEnd}
                duration={countUpDuration}
                onEnd={handleCountEnd}
              />
              {isPercentage && <span className="percent">%</span>}
              <img className="lightningIco" src={lightningIco} alt="" />
            </>
          )}

          <img className="subscriptIco" src={subscriptIco} alt="" />
        </SubContainer>
        <img className="pinIco" src={pinIco} alt="" />
      </Container>
    );
  }
);

export default EbBarTip;
