import { resolveProperties } from "@ethersproject/properties";
import { TransactionRequest } from "@ethersproject/abstract-provider";
import { keccak256 } from "@ethersproject/keccak256";
import { serialize, UnsignedTransaction } from "@ethersproject/transactions";
import { arrayify } from "@ethersproject/bytes";
import { rootStore } from "src/index";

export const signTransaction = async function (
  transaction: TransactionRequest
) {
  return resolveProperties(transaction)
    .then((tx) => {
      return rootStore.userStore.getKontosCli().then((cli) => {
        const privateKey = cli.key.privateKey as CryptoKey;
        const message = keccak256(serialize(tx as UnsignedTransaction));
        // let enc = new TextEncoder();
        const digestBytes = arrayify(message);
        return window.crypto.subtle
          .sign(
            {
              name: "ECDSA",
              hash: { name: "SHA-256" },
            },
            privateKey,
            digestBytes
          )
          .then((resp) => {
            const u = new Uint8Array(65);
            u.set(new Uint8Array(resp));
            u.set(new Uint8Array([1]), 64);
            return serialize(tx as UnsignedTransaction, u);
          });
        // const signature = privateKey.sign();
        // return serialize(tx, signature);
      });
    })
    .then((res) => res);
};

export const prepareRequest = function (
  oldPrepareRequest: any,
  from: string
): (method: string, params: any) => [string, Array<any>] {
  return function (method: string, params: any): [string, Array<any>] {
    if (method === "sendTransaction") {
      return [
        "eth_sendRawTransactionWithFrom",
        [from, params.signedTransaction],
      ];
    } else {
      // @ts-ignore: ignore this error
      return oldPrepareRequest.call(this, method, params);
    }
  };
};
