import styled from "styled-components";
import { observer } from "mobx-react";
import checkIcon from "src/assets/icons/settings/check.svg";

const Wrapper = styled.div`
  .selected {
    border-radius: 8px;
    border: 1px solid var(--Kontos-Blue, #413dec);

    color: var(--Kontos-Blue, #413dec);
    font-family: "HarmonyOS Sans SC";
    font-size: 18px;
    font-weight: 400;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  border-radius: 8px;
  background: ${(props) => props.theme.colors.__white};

  margin-bottom: 8px;
  padding: 13px 29px 13px 20px;

  cursor: pointer;

  &:hover {
    // background: ${(props) => props.theme.colors.__deep_25};
    border: 1px solid var(--Kontos-Blue, #413dec);
  }

  .left {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    .title {
      color: ${(props) => props.theme.colors.__deep_800};
      font-family: HarmonyOS Sans SC;
      font-size: 18px;
      font-weight: 400;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .right {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .check-icon {
    }
  }
`;

interface Props {
  selected: boolean;
  title: string;
  onClick?: () => void;
}

const GeneralItem = ({ selected, title, onClick }: Props) => {
  return (
    <Wrapper>
      <Container
        className={selected ? "selected" : ""}
        onClick={async () => {
          onClick && onClick();
        }}
      >
        <div className="left">
          <span className="title">{title}</span>
        </div>
        <div className="right">
          {selected && <img className="check-icon" src={checkIcon} alt="" />}
        </div>
      </Container>
    </Wrapper>
  );
};

export default observer(GeneralItem);
