import styled from "styled-components";

export const BothEndsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BothEndsHeaderLeft = styled.div`
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 16px;
  line-height: 20px;
`;

export const BothEndsHeaderRight = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: var(--Kontos-Blue, #413dec);
    }
  }
`;

export const BothEndsHeaderRightHighlight = styled.div`
  color: var(--Kontos-Blue, #413dec);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;
