class TgManager {
  isTg = (): boolean => {
    return Object.keys(window).includes("TelegramWebviewProxy");
  };
}

const tgManager = new TgManager();
Object.freeze(tgManager);

export default tgManager;
