import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import { KontosButton } from "src/components/button/KontosButton";
import AccountItem from "./AccountItem";
import avatarIcon from "src/assets/icons/settings/avatar.svg";

const Container = styled.div`
  margin-top: -20px;

  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 0 16px 16px 16px;
`;

const AccountItemWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 14px;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 89%;
    margin: 0 auto;
    margin-bottom: 16px;
  }
`;

interface Props {
  name: string;
  balanceText: string | undefined;
  onRemove: () => void;
  onCancel: () => void;
}

const DelAccount: React.FC<Props> = ({
  name,
  balanceText,
  onRemove,
  onCancel,
}) => {
  return (
    <Container>
      <Header title={t("Confirm Removal?")} isTitleRed={true} />
      <AccountItemWrapper>
        <AccountItem
          recovering={false}
          balanceText={balanceText}
          disable={true}
          name={name}
          avatar={avatarIcon}
          selected={false}
        />
      </AccountItemWrapper>
      <Scrollable></Scrollable>
      <ButtonWrapper>
        <KontosButton $isRed={true} onClick={onRemove}>
          {t("Remove")}
        </KontosButton>
        <KontosButton $isOutlined={true} onClick={onCancel}>
          {t("Cancel")}
        </KontosButton>
      </ButtonWrapper>
    </Container>
  );
};

export default DelAccount;
