import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { SheetView } from "src/store/SheetStore";
import { useCallback } from "react";
import { DiscoverSearch } from "./DiscoverSearch";

interface Props {
  mountPoint: Element | undefined;
}

export const DiscoverSearchSheet: React.FC<Props> = observer(
  ({ mountPoint }) => {
    const { sheetStore } = useStores();
    const isOpen = sheetStore.sheetVisibility.get(
      SheetView.DiscoverSearch
    ) as boolean;

    const onClose = useCallback(() => {
      sheetStore.closeSheetByView(SheetView.DiscoverSearch);
    }, [sheetStore]);

    return (
      <BottomSheet isOpen={isOpen} mountPoint={mountPoint} onClose={onClose}>
        <DiscoverSearch onClose={onClose} />
      </BottomSheet>
    );
  }
);
