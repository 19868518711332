import { useTranslation } from "react-i18next";
import styled from "styled-components";
import defaultTokenIco from "src/assets/icons/trade/default-token.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";

const Container = styled.div`
  //flex: 0 1 32%;
  width: calc((100% - 12.2px) / 3);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .box {
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--Deep-25, #f5f5f6);

    > .img {
      margin-top: 18px;
      width: 48px;
      height: 48px;
    }

    > .title {
      margin-top: 12px;
      height: 14px;
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans Bold";
      font-size: 12px;
    }

    > .sub-title {
      margin-top: 2px;
      height: 12px;
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 10px;
      font-weight: 400;
    }
  }

  > .claim {
    width: 100%;
    margin-top: 6px;
    border-radius: 4px;
    border: 1px solid var(--Deep-50, #edeef1);
    padding: 5px;

    color: var(--Deep-100, #cccfd2);
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    font-size: 12px;
  }
`;

interface Props {
  img: any;
  title: string;
  subTitle?: string;
}

export const EbBackpackItem: React.FC<Props> = ({ img, title, subTitle }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="box">
        <ImageWithFallback
          className="img"
          src={img || defaultTokenIco}
          alt=""
          fallbackSrc={defaultTokenIco}
        />
        <span className="title">{title}</span>
        {subTitle && <span className="sub-title">{subTitle}</span>}
      </div>
      <div className="claim">{t("Claimed")}</div>
    </Container>
  );
};
