import React, {useState} from 'react';
import styled from 'styled-components';
import arrowIco from "src/assets/icons/arrow15.svg";

interface ViewAllButtonProps {
  isTruncated: boolean;
  onClick: () => void;
}

const Button = styled.button`
  color: var(--Deep-400, #80868F);
  /* Smaller */
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const Icon = styled.img`
  width: 10px;
  height: 14px;
  margin-left: 6px;
`;

const ViewAllButton: React.FC<ViewAllButtonProps> = ({isTruncated, onClick}) => {
  return (
    isTruncated ?
      <Button onClick={onClick}>
        {isTruncated ? 'View All' : 'Show Less'}
        <Icon style={{
          transform: isTruncated ? 'rotate(0deg)' : 'rotate(180deg)',
        }} src={arrowIco} alt="Toggle"/>
      </Button>
      : null
  );
};

interface TruncatedTextProps {
  text: string;
  maxLength: number;
}

const TextContainer = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 10px;
  position: relative;
`;

const ViewAllLink = styled.a`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;

const TruncatedText: React.FC<TruncatedTextProps> = ({text, maxLength}) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const displayText = isTruncated && text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  return (
    <TextContainer style={{
      paddingBottom: isTruncated ? "30px" : 0
    }}>
      {displayText}
      {text.length > maxLength && (
        <ViewAllButton isTruncated={isTruncated} onClick={toggleTruncate}/>
      )}
    </TextContainer>
  );
};

export default TruncatedText;
