import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { FtAsset } from "@/type/zkkontos";
import SelectAssetsModal from "../trade/SelectAssetsModal";
import { observer } from "mobx-react";
import { t } from "i18next";
import { loadingStore } from "src/store/loadingStore";
import StepSend from "./StepSend";
import { DEFAULT_DISPLAY_PRECESION } from "src/config";
import { ChainConfig } from "src/type/zkkontos";
import Header from "src/components/common/header";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME, ROUTE_MAIN } from "src/router/router-config";
import { isKontosChain } from "src/utils/helper";
import { fetchMaxFtAssetToSend } from "src/service/send-service";
import KontosNumber from "src/utils/KontosNumber";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";

const Container = styled.div`
  //height: calc(100%-60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  padding: 40px 0 0 0;

  box-sizing: border-box;
`;

export enum SendH5Step {
  ChooseAssets,
  Send,
}

const displayPrecision = DEFAULT_DISPLAY_PRECESION;

const SendH5: React.FC = () => {
  const { userStore, chainStore, marketStore, tradeStore } = useStores();
  const [chains, setChains] = useState<ChainConfig[]>(
    userStore.userHoldingsChains || []
  );
  const [step, setStep] = useState<SendH5Step>(
    marketStore.selectedSendAsset ||
      (tradeStore.toSendFtAsset && tradeStore.fromAi)
      ? SendH5Step.Send
      : SendH5Step.ChooseAssets
  );
  const [toSendFtAsset, setToSendFtAsset] = useState<FtAsset | undefined>(
    tradeStore.toSendFtAsset
  );
  const [maxAvailable, setMaxAvailable] = useState<KontosNumber | undefined>(
    tradeStore.fromAi ? tradeStore.toSendMax : undefined
  );
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    loadingStore.showLoading();
    Promise.allSettled([chainStore.fetchAndSetChains()])
      .then((results) => {
        const [chainsResult] = results;

        if (chainsResult.status === "fulfilled") {
          setChains(userStore.userHoldingsChains);
        } else {
          console.warn("Failed to load chains", chainsResult.reason);
        }
      })
      .catch((e) => {
        console.warn(e);
      })
      .finally(() => {
        loadingStore.hideLoading();
      });
  }, [chainStore, userStore.userHoldingsChains]);

  useEffect(() => {
    if (!chainStore?.chains || chainStore?.chains?.length === 0) {
      fetchData();
    }
  }, [fetchData, chainStore.chains]);

  useEffect(() => {
    if (marketStore.selectedSendAsset) {
      handleFtAssetChoose(marketStore.selectedSendAsset!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketStore.selectedSendAsset]);

  const handleFtAssetChoose = async (ftAsset: FtAsset) => {
    if (isKontosChain(ftAsset.chainIndex)) {
      setMaxAvailable(undefined);
      setToSendFtAsset(ftAsset);
      setStep(SendH5Step.Send);
    } else {
      loadingStore.showLoading();
      try {
        const resp = await fetchMaxFtAssetToSend(
          userStore.accountInfo?.name!,
          userStore.accountInfo?.name!,
          ftAsset.chainIndex,
          ftAsset.address
        );
        setMaxAvailable(resp);
        setToSendFtAsset(ftAsset);
        setStep(SendH5Step.Send);
      } catch (e) {
        toast({
          type: "error",
          text: t("Failed to obtain max available balance"),
        });
      } finally {
        loadingStore.hideLoading();
      }
    }
  };

  return (
    <Container>
      {step === SendH5Step.ChooseAssets && (
        <>
          <Header
            title={t("Choose Assets to Send")}
            padding="0 20px 20px 20px"
            enableBack
            callBack={() => {
              if (marketStore.selectedSendAsset) {
                marketStore.setSelectedSendAsset(null);
                navigate(ROUTE_HOME);
              } else {
                navigate(-1);
              }
            }}
          />
          {/* <TitleBox>
            <div />
            <Title>{t("Choose Assets to Send")}</Title>
            <div />
          </TitleBox> */}
          <SelectAssetsModal
            allFtAssets={userStore.userHoldings}
            chains={chains}
            showBalance={true}
            allowSearch={false}
            displayPrecision={displayPrecision}
            onChoose={handleFtAssetChoose}
            initChainIndex={
              chainStore.defaultOptForSend !== NonChainIndex.All
                ? userStore.userHoldings.some(
                    (item) => item.chainIndex === chainStore.defaultOptForSend
                  )
                  ? chainStore.defaultOptForSend
                  : NonChainIndex.All
                : NonChainIndex.All
            }
            onChainChange={chainStore.setDefaultOptForSend}
          />
        </>
      )}{" "}
      {step === SendH5Step.Send && (
        <>
          <StepSend
            maxAvailable={maxAvailable ? maxAvailable : undefined}
            toSendFtAsset={toSendFtAsset}
            displayPrecision={displayPrecision}
            onBack={() => {
              setStep(SendH5Step.ChooseAssets);
            }}
            onSuccess={() => {
              navigate(ROUTE_MAIN);
              // userStore.fetchAndUpdateAccountBalances();
            }}
          />
        </>
      )}
    </Container>
  );
};

export default observer(SendH5);
