import SuccessSvg from "src/components/toast/icons/success.svg";
import ErrorSvg from "src/components/toast/icons/error.svg";
import WarningSvg from "src/components/toast/icons/warning.svg";
import InfoSvg from "src/components/toast/icons/info.svg";
import PendingSvg from "src/components/toast/icons/pending.svg";
import { toast as rawToast } from "react-toastify";
import { TOAST_CONTAINER_ID } from "./ToastContainer";
import { ReactNode } from "react";

type ToastType = "success" | "error" | "warning" | "info" | "default";

export interface NewToastProps {
  text: string | ReactNode;
  type?: ToastType;
  containerId?: string;
  autoClose?: number;
}

const typeIcon: { [key in ToastType]: any } = {
  success: <img src={SuccessSvg} alt="success" />,
  error: <img src={ErrorSvg} alt="error" />,
  warning: <img src={WarningSvg} alt="warning" />,
  info: <img src={InfoSvg} alt="info" />,
  default: <img src={PendingSvg} alt="pending" />,
};

const toast = ({
  text = "",
  type = "success",
  containerId = TOAST_CONTAINER_ID,
  autoClose = 5000,
}: NewToastProps) => {
  return rawToast(text, {
    type,
    icon: () => <>{typeIcon[type]}</>,
    containerId: containerId,
    // toastId: text?.toString(),
    autoClose: autoClose,
  });
};

export default toast;
