import {t} from "i18next";
import {useContext, useEffect, MouseEvent} from "react";
import styled, {ThemeContext} from "styled-components";
import successIcon from "src/assets/images/success.svg";
import errorIcon from "src/assets/icons/error1.svg";
import warnIcon from "src/assets/icons/warn1.svg";

const Wrapper = styled.div<{
  themeColor: string;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999;

  .popup-modal__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.7;
  }

  .popup-modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 16px;
    width: 336px;
    background: #ffffff;
    border-radius: 16px;
  }

  .popup-modal__content-icon {
    display: block;
    margin: 16px auto 0;
    width: 24px;
    height: 24px;
  }

  .popup-modal__content-title {
    margin-top: 6px;
    color: ${(props) => props.themeColor};
    font-size: 18px;
    text-align: center;
    font-family: "HarmonyOS Sans Bold";
    margin-bottom: 16px;
  }

  .popup-modal__content-text {
    margin-top: 8px;
    padding: 0 16px;
    color: var(--Deep-800, #1A2535);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 16px;
  }

  .popup-modal__content-sub-text {
    color: var(--Deep-400, #80868F);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .popup-modal__content-btns {
    display: flex;
    justify-content: space-between;
    padding:  0 20px;
  }
  
  .popup-modal__content-confirm-btn, .popup-modal__content-cancel-btn{
    width: calc(50% - 5px);
    flex-shrink: 0;
  }

  .popup-modal__content-confirm-btn {
    height: 34px;
    background: ${(props) => props.themeColor};
    border-radius: 99px;
    font-size: 16px;
    font-family: "HarmonyOS Sans Bold";
    line-height: 34px;
    text-align: center;
    color: ${(props) => props.theme.colors.__white};

    &:hover {
      cursor: pointer;
    }
  }

  .popup-modal__content-cancel-btn {
    height: 34px;
    background: ${(props) => props.theme.colors.__white};
    border: 1px solid ${(props) => props.themeColor};
    border-radius: 99px;
    font-size: 16px;
    font-family: "HarmonyOS Sans Bold";
    line-height: 34px;
    text-align: center;
    color: ${(props) => props.themeColor};

    &:hover {
      cursor: pointer;
    }
  }
`;

type Props = {
  type: "success" | "warning" | "error";
  onConfirm: () => void;
  onCancel: (event?: MouseEvent) => void;
  onOutClick: (event?: MouseEvent) => void;
  title: string;
  desc: string;
  subDesc?: string;
  btnTxt: string;
  cancelBtnText?: string;
  iconCustom?: string;
};

export const PopupModal: React.FC<Props> = ({
                                              type,
                                              onConfirm,
                                              onCancel,
                                              onOutClick,
                                              title,
                                              desc,
                                              subDesc,
                                              btnTxt,
                                              cancelBtnText = t("Cancel"),
                                              iconCustom
                                            }) => {
  const theme = useContext(ThemeContext);
  const color =
    type === "success"
      ? theme.colors.__success
      : type === "warning"
        ? theme.colors.__warning
        : theme.colors.__error;
  const icon =
    type === "success"
      ? successIcon
      : type === "warning"
        ? warnIcon
        : errorIcon;

  useEffect(() => {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Wrapper themeColor={color}>
      <div className="popup-modal__mask" onClick={(event) => onOutClick(event)}></div>
      <div className="popup-modal__content">
        {
          iconCustom ||
          <img
            className={'popup-modal__content-icon'}
            src={icon}
            alt="reset account icon"
          />
        }
        <div className="popup-modal__content-title">{title}</div>
        <div className="popup-modal__content-text">{desc}</div>
        {
          subDesc &&
          <div className="popup-modal__content-sub-text">{subDesc}</div>
        }

        <div className="popup-modal__content-btns">
          <div
            className="popup-modal__content-confirm-btn"
            onClick={() => onConfirm()}
            style={{background: type === "error" ? theme.colors.__error : theme.colors.__deep_800}}
          >
            {btnTxt}
          </div>
          <div
            className="popup-modal__content-cancel-btn"
            onClick={(event) => onCancel(event)}
            style={{
              color: type === "error" ? theme.colors.__error : theme.colors.__deep_800,
              borderColor: type === "error" ? theme.colors.__error : theme.colors.__deep_800
            }}
          >
            {cancelBtnText}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
