import { useCallback } from "react";
import styled from "styled-components";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { DappHistoryList } from "./DappHistoryList";
import { DappHistoryHeader } from "../DappHeader";

const Container = styled.div`
  min-height: 300px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin-bottom: 60px;
  padding-top: 16px;

  .dapp-header {
    padding: 0 24px;
  }
`;

export const DappHistoryPanel: React.FC = observer(() => {
  const { t } = useTranslation();
  const { discoverStore } = useStores();
  const dappHistroy = discoverStore.dappHistory;

  const handleClearOnClick = useCallback(() => {
    discoverStore.clearDappHistory();
  }, [discoverStore]);

  return (
    <Container>
      <DappHistoryHeader
        className="dapp-header"
        onClearClick={handleClearOnClick}
      />

      {dappHistroy.length > 0 ? (
        <Scrollable>
          <DappHistoryList
            dapps={dappHistroy}
            onRemove={discoverStore.removeDappHistory}
          />
        </Scrollable>
      ) : (
        <NoDataV2 text={t("There is no history currently.")} />
      )}
    </Container>
  );
});
