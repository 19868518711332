import { ChainConfig } from "@/type/zkkontos";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import ImageWithFallback from "../images/ImageWithFallback";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import arrowIcon from "src/assets/icons/chain-select-arrow.svg";

const Container = styled.div`
  user-select: none;
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--White, #fff);
  padding: 6px 12px 6px 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
    }
  }

  &:active {
    border-color: var(--Kontos-Blue, #413dec);
  }
`;

const ChainIcon = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 50%;
`;

const ChainSymbol = styled.span`
  color: #1c1b1f;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const ArrowIcon = styled.img`
  width: 10px;
  height: 14px;
`;

interface IProps {
  chain?: ChainConfig;
  hasArrow?: boolean;
  requesting?: boolean;
  onClick?: () => void;
}

export const ChainItem: React.FC<IProps> = ({
  chain,
  hasArrow,
  requesting,
  onClick,
}) => {
  return (
    <Container onClick={onClick}>
      <ImageWithFallback
        src={chain?.imageURL || defaultChainIcon}
        fallbackSrc={defaultChainIcon}
        StyledImg={ChainIcon}
      />

      <ChainSymbol>
        {requesting ? (
          <Skeleton count={1} style={{ width: "30px" }} />
        ) : chain ? (
          chain.chainSymbol
        ) : (
          "-"
        )}
      </ChainSymbol>

      {hasArrow && <ArrowIcon src={arrowIcon} />}
    </Container>
  );
};
