import { observer } from "mobx-react";
import styled from "styled-components";
import React, { ReactNode, useState } from "react";
import subDisable from "src/assets/icons/sub-disabled.svg";
import subNormal from "src/assets/icons/sub-normal.svg";
import addDisable from "src/assets/icons/add-disabled.svg";
import addNormal from "src/assets/icons/add-normal.svg";
import { t } from "i18next";
import { Button } from "src/components/button/Button";
import { ButtonCancel } from "src/components/button/ButtonCancel";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  padding: 0 20px;
  padding-bottom: 140px;

  .head {
    color: var(--Deep-800, #1a2535);
    text-align: center;

    /* H3 */
    font-family: HarmonyOS Sans SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 25px;
  }

  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    .l,
    .r {
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
      }
    }
    span {
      color: var(--Kontos-Blue, #413dec);

      /* H3 */
      font-family: HarmonyOS Sans SC;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0 24px;
    }
  }

  .description {
    color: var(--Deep-400, #80868f);
    text-align: center;

    /* Description */
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }

  .buttonWrap {
    position: absolute;
    bottom: 16px;
    left: 20px;
    width: calc(100% - 40px);
  }
`;

type IProps = {
  submit: () => void;
  cancel: () => void;
  maxThreshold: number;
  minThreshold?: number;
  currentThreshold: number;
  setCurrentThreshold: (value: number) => void;
  firstBtnText?: string;
  text?: ReactNode;
};

const SetUpThreshold: React.FC<IProps> = ({
  submit,
  cancel,
  maxThreshold,
  minThreshold = 1,
  currentThreshold,
  setCurrentThreshold,
  firstBtnText = t("Next") as string,
  text,
}) => {
  return (
    <Wrapper>
      <div className="head">{t("Set up Threshold")}</div>
      <div className="control">
        <div
          className="l"
          onClick={() => {
            setCurrentThreshold(currentThreshold - 1);
          }}
        >
          <img
            src={currentThreshold <= minThreshold ? subDisable : subNormal}
          />
        </div>
        <span>{currentThreshold}</span>
        <div
          className="r"
          onClick={() => {
            setCurrentThreshold(currentThreshold + 1);
          }}
        >
          <img
            src={currentThreshold >= maxThreshold ? addDisable : addNormal}
          />
        </div>
      </div>
      <div className={"description"}>
        {text
          ? text
          : t(
              "*Please set the verification threshold for your account recovery."
            )}
      </div>
      <div className={"buttonWrap"}>
        <Button onClick={submit} text={firstBtnText} height={"56px"} />
        <div style={{ height: "12px" }} />
        <ButtonCancel onClick={cancel} text={t("Cancel")} height={"56px"} />
      </div>
    </Wrapper>
  );
};

export default observer(SetUpThreshold);
