import PoweredBy from "src/components/common/powerdBy";
import styled from "styled-components";
import logo from "src/assets/images/first-screen-logo.svg";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decodeBase64ToJson } from "src/utils/helper";
import { useCallback, useEffect } from "react";
import { AI_QUERY_PARAM, DEFAULT_DECIMAL } from "src/config";
import { ReqTaskDataV3 } from "@zkkontos/kontos-sdk/src/api";
import {
  ROUTE_HOME,
  ROUTE_SEND,
  ROUTE_TRADE,
  ROUTE_TRADE_SPOT,
  ROUTE_TRADE_SWAP,
} from "src/router/router-config";
import toast from "src/components/toast/Toast";
import { useTranslation } from "react-i18next";
import { fetchSpecificFtAsset } from "src/service/trade-service";
// import tgManager from "src/store/managers/tgManager";
import KontosNumber from "src/utils/KontosNumber";
import { SpotMode } from "src/store/trade/SpotStore";
import { fetchMaxFtAssetToSend } from "src/service/send-service";
import { loadingStore } from "src/store/loadingStore";

const Container = styled.div<{ $isMobile: boolean }>`
  flex: 1;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(/static/first-screen-bg.jpg);
  background-size: ${(props) => (props.$isMobile ? "160% auto" : "120% auto")};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: ${(props) => (props.$isMobile ? "center" : "center")};
`;

const Body = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 96px;
  height: 96px;
`;

const Title = styled.span`
  margin-top: 16px;

  color: ${(props) => props.theme.colors.__kontos_blue};
  font-family: "HarmonyOS Sans Bold";
  font-size: 28px;
  margin-bottom: 16px;
`;

const PowerByWrapper = styled.div`
  position: absolute;
  bottom: 20px;
`;

export const AiScreen: React.FC = observer(() => {
  const { t } = useTranslation();
  const { uiStore, swapStore, spotStore, tradeStore, chainStore, userStore } =
    useStores();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const parseReqTaskDataFailCallback = useCallback(
    (reason?: string) => {
      navigate(ROUTE_HOME);
      toast({
        text: reason
          ? `${t("Failed to parse AI parameters")}: ${reason}`
          : t("Failed to parse AI parameters"),
        type: "warning",
      });
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const analyzeReqTaskDataAndRedirect = useCallback(
    async (req: ReqTaskDataV3) => {
      // Buy
      if (req?.reqTaskIntentionData) {
        const data = req.reqTaskIntentionData;
        console.log("data", data);
        // Filter valid data
        if (!data.assetAddress || !data.chainIndex || !data.slippage) {
          parseReqTaskDataFailCallback(t("Insufficient data"));
          return;
        }
        // Fetch asset
        try {
          const asset = await fetchSpecificFtAsset(
            data.chainIndex,
            data.assetAddress
          );
          if (!asset) {
            throw new Error();
          }
          spotStore.switchMode(SpotMode.Buy);
          spotStore.resetBuyInput();
          spotStore.setToBuyFtAsset(asset);
          spotStore.setSlippage(
            new KontosNumber(data.slippage, DEFAULT_DECIMAL)
          );
          spotStore.setToBuyFtAssetValue(
            data.usdAmount !== "0"
              ? new KontosNumber(data?.usdAmount || 0, DEFAULT_DECIMAL)
              : undefined
          );
          tradeStore.setFromAi(true);
          navigate(`${ROUTE_TRADE}/${ROUTE_TRADE_SPOT}`);
        } catch (e) {
          spotStore.reset();
          tradeStore.setFromAi(false);
          parseReqTaskDataFailCallback(t("Missing matching asset"));
          return;
        }
      }
      // Swap
      if (req?.reqTaskSwapData) {
        const data = req.reqTaskSwapData;
        console.log("data", data);
        // Filter valid data
        if (!data.chainIndex || !data.slippage || !data.src || !data.dst) {
          parseReqTaskDataFailCallback(t("Insufficient data"));
          return;
        }
        // Fetch asset
        try {
          const [chain, srcAsset, dstAsset] = await Promise.all([
            (async () => {
              return chainStore.fetchSpecificChain(data.chainIndex);
            })(),
            (async () => {
              return await fetchSpecificFtAsset(data.chainIndex, data.src);
            })(),
            (async () => {
              return await fetchSpecificFtAsset(data.chainIndex, data.dst);
            })(),
          ]);
          if (!chain || !srcAsset || dstAsset) {
            throw new Error();
          }
          swapStore.setSlippage(
            new KontosNumber(data.slippage, DEFAULT_DECIMAL)
          );
          swapStore.resetSwapInput();
          swapStore.setChain(chain);
          swapStore.setToSwapFtAsset(srcAsset);
          swapStore.setToReceiveFtAsset(dstAsset);
          swapStore.setToSwapFtAssetQuantity(
            data.srcAmount !== "0"
              ? new KontosNumber(data?.srcAmount || 0, DEFAULT_DECIMAL)
              : undefined
          );
          tradeStore.setFromAi(true);
          navigate(`${ROUTE_TRADE}/${ROUTE_TRADE_SWAP}`);
        } catch (e) {
          swapStore.reset();
          tradeStore.setFromAi(false);
          parseReqTaskDataFailCallback(t("Missing matching asset"));
          return;
        }
      }
      //Transfer
      if (req?.reqTaskTransferData) {
        const data = req.reqTaskTransferData;
        console.log("data", data);
        // Filter valid data
        if (!data.chainIndex || !data.assetAddress) {
          parseReqTaskDataFailCallback(t("Insufficient data"));
          return;
        }
        // Fetch asset
        try {
          const [asset, maxAvailable] = await Promise.all([
            (async () => {
              return await fetchSpecificFtAsset(
                data.chainIndex,
                data.assetAddress
              );
            })(),
            (async () => {
              return await fetchMaxFtAssetToSend(
                userStore.accountInfo?.name!,
                userStore.accountInfo?.name!,
                data.chainIndex,
                data.assetAddress
              );
            })(),
          ]);
          if (!asset) {
            throw new Error();
          }
          tradeStore.resetSendInput();
          tradeStore.setToSendFtAsset(asset);
          tradeStore.setToSendMax(maxAvailable);
          tradeStore.setFromAi(true);
          navigate(ROUTE_SEND);
        } catch (e) {
          tradeStore.resetSendInput();
          tradeStore.setFromAi(false);
          parseReqTaskDataFailCallback(t("Missing matching asset"));
          return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const init = async () => {
      const aiParamBase64 = searchParams.get(AI_QUERY_PARAM);
      if (typeof aiParamBase64 === "string") {
        try {
          const req = decodeBase64ToJson<ReqTaskDataV3>(aiParamBase64);
          await analyzeReqTaskDataAndRedirect(req);
        } catch (e) {
          return parseReqTaskDataFailCallback(t("Decoding failed"));
        }
      } else {
        navigate(ROUTE_HOME);
      }
    };

    loadingStore.showLoading();
    init();

    return () => {
      loadingStore.hideLoading();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container $isMobile={!uiStore.isOverWidth}>
      <Body>
        <Logo src={logo} />
        <Title>Kontos Wallet</Title>
        <PowerByWrapper>
          <PoweredBy />
        </PowerByWrapper>
      </Body>
    </Container>
  );
});
