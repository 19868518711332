import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { DeepButton } from "src/components/button/DeepButton";
import fireworksBgImg from "src/assets/images/event/eb/fireworks-bg.png";
import { getOrbImg } from "src/utils/events/ebHelper";
import { BASIC_SHEET_INDEX } from "src/config";

const Container = styled.div`
  background-image: url(${fireworksBgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 780%;

  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 25px 20px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }

  > .value {
    margin-top: 4px;
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    > img {
      margin-right: 6px;
      width: 48px;
      height: 48px;
    }
  }

  > .content {
    margin-top: 1px;
    color: var(--Deep-400, #80868f);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;

    > span {
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans Bold";
      font-size: 14px;
    }
  }

  > .ok-btn {
    margin-top: 16px;
  }
`;

interface Props {
  orbCount?: string;
  orbType?: number;
  onClose: (open: boolean) => void;
}

export const EbBarClaimPopup: React.FC<Props> = ({
  orbCount,
  orbType,
  onClose,
}) => {
  const { t } = useTranslation();
  const title = t("Congratulations!");
  const content = (
    <Trans i18nKey={"trans-eb-claim"}>
      You've got {{ orbCount }} energy orbs with rich rewards in{" "}
      <span>"My Backpack"</span>
    </Trans>
  );
  const orbImg = getOrbImg(orbType);

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <div className="title">{title}</div>
        <div className="value">
          <img src={orbImg} alt="" />x{orbCount || "?"}
        </div>
        <div className="content">{content}</div>
        <DeepButton className="ok-btn" onClick={() => onClose(false)}>
          {t("OK")}
        </DeepButton>
      </Container>
    </Overlay>
  );
};
