import ImageWithFallback from "../images/ImageWithFallback";

interface IProps {
  src: any;
  fallbackSrc?: any;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const Icon: React.FC<IProps> = ({
  src,
  fallbackSrc,
  size,
  className,
  style,
}) => {
  return (
    <ImageWithFallback
      src={src || fallbackSrc}
      fallbackSrc={fallbackSrc}
      style={{ ...style, width: `${size}px`, height: `${size}px` }}
      className={className}
    />
  );
};
