import styled from "styled-components";
import React from "react";
import { t } from "i18next";
import { Button } from "src/components/button/Button";
import { copy } from "src/utils/helper";

const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 16px;
  .text {
    overflow: hidden;
    color: var(--Deep-800, #1a2535);
    text-overflow: ellipsis;
    max-width: 550px;

    /* Main text */
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    display: flex;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 11px;
  }
  .btn {
    width: 134px;
    height: 29px;
    flex-shrink: 0;
    span {
      color: #fff !important;
      font-size: 14px;
    }
  }
`;

type IProps = {
  copyDataString: string;
};

const RecoveryCopySection: React.FC<IProps> = ({ copyDataString }) => {
  return (
    <Wrapper>
      <div className={"text"}>{copyDataString}</div>
      <div className={"btn"}>
        <Button
          onClick={() => {
            console.log("click");
            copy(copyDataString);
          }}
          text={t("Copy Public Key")}
          fullWidth={true}
          height={"29px"}
          disabled={false}
        />
      </div>
    </Wrapper>
  );
};

export default RecoveryCopySection;
