import { motion, useAnimate } from "framer-motion";
import { t } from "i18next";
import { useCallback, useMemo } from "react";
import styled from "styled-components";

const FONT_COLOR = "var(--Deep-400, #80868F)";
const FONT_ACTIVE_COLOR = "var(--Deep-800, #1A2535)";
const FONT_ACTIVE_COLOR_DARK = "var(--White, #fff)";

const Container = styled.div<{ $darkMode?: boolean }>`
  width: 100%;
  height: 32px;
  border-radius: 8px;
  background: ${(props) =>
    props.$darkMode ? "var(--Deep-700, #333D4C)" : "var(--Deep-50, #edeef1)"};
  padding: 2px;
  display: flex;
  justify-content: center;
  gap: 2px;
  user-select: none;
`;

const FixedBlock = styled.div<{ $active?: boolean; $darkMode?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 6px;
  background: transparent;
  position: relative;

  color: ${(props) =>
    props.$active
      ? props.$darkMode
        ? FONT_ACTIVE_COLOR_DARK
        : FONT_ACTIVE_COLOR
      : FONT_COLOR};
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;

  cursor: pointer;
`;

const BlockText = styled(motion.span)`
  z-index: 2;
`;

const Slider = styled(motion.div)<{ $darkMode?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${(props) =>
    props.$darkMode ? "var(--Deep-800, #1A2535)" : "var(--White, #fff)"};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  color: ${(props) =>
    props.$darkMode ? "var(--White, #FFF)" : "var(--Deep-800, #1a2535)"};
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
`;

interface Props {
  className?: string;
  boostType: "me" | "others";
  onTypeChange: (newType: "me" | "others") => void;
  darkMode?: boolean;
}

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 40,
};

const tabs: { name: "me" | "others"; value: string }[] = [
  { name: "me", value: t("Boosts Received") },
  { name: "others", value: t("Boosts Given") },
];

const DURATION = {
  duration: 0.25,
};

export const EbBoostHistoryTab: React.FC<Props> = ({
  className,
  boostType,
  onTypeChange,
  darkMode = false,
}) => {
  const [leftScope, leftAnimate] = useAnimate();
  const [rightScope, rightAnimate] = useAnimate();

  const activeColor = useMemo(() => {
    return darkMode ? FONT_ACTIVE_COLOR_DARK : FONT_ACTIVE_COLOR;
  }, [darkMode]);

  const handleClick = useCallback(
    (newType: "me" | "others", target: "left" | "right") => {
      if (target === "left") {
        rightAnimate(rightScope.current, { color: FONT_COLOR }, DURATION);
        leftAnimate(leftScope.current, { color: activeColor }, DURATION);
      } else {
        rightAnimate(rightScope.current, { color: activeColor }, DURATION);
        leftAnimate(leftScope.current, { color: FONT_COLOR }, DURATION);
      }
      onTypeChange(newType);
    },
    [
      activeColor,
      leftAnimate,
      leftScope,
      onTypeChange,
      rightAnimate,
      rightScope,
    ]
  );

  return (
    <Container className={className} $darkMode={darkMode}>
      {tabs.map((tab) => (
        <FixedBlock
          key={tab.name}
          $active={tab.name === boostType}
          $darkMode={darkMode}
          onClick={() =>
            handleClick(tab.name, tab.name === tabs[0].name ? "left" : "right")
          }
        >
          <BlockText ref={tab.name === tabs[0].name ? leftScope : rightScope}>
            {tab.value}
          </BlockText>
          {tab.name === boostType && (
            <Slider
              $darkMode={darkMode}
              layoutId="slider"
              transition={spring}
            />
          )}
        </FixedBlock>
      ))}
    </Container>
  );
};
