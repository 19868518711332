import React, { ReactNode } from "react";
import styled from "styled-components";
import noAssetsImg from "src/assets/images/trade/no-assets.svg";

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-data__image {
    width: 100px;
    height: 100px;
  }

  .no-data__text {
    margin-top: 12px;

    color: ${({ theme }) => theme.colors.__deep_400};
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }
`;

interface Props {
  style?: React.CSSProperties;
  className?: string;
  text?: string;
  children?: ReactNode;
}

export default function NoDataV2({ style, className, text, children }: Props) {
  return (
    <Wrapper style={{ ...style }} className={className}>
      <img src={noAssetsImg} className="no-data__image" alt="no data" />
      <div className="no-data__text">{text}</div>
      {children}
    </Wrapper>
  );
}
