import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PaymentSwitch from "src/pages/contract-interaction/payment-items/PaymentSwitch";
import styled from "styled-components";
import ChooseAssetSvg from "src/assets/icons/contract/choose-asset.svg";
import LeftBlueArrowSvg from "src/assets/icons/contract/left-blue-arrow.svg";
import { RespTaskDataV3 } from "@zkkontos/kontos-sdk/src/api/brokerApi";
import { PaymentOptionType } from "./payment-items/PaymentSelect";
import PaymentPlan from "./payment-methods/PaymentPlan";
import PaymentBridge from "./payment-methods/PaymentBridge";
import PaymentReceive from "./payment-methods/PaymentReceive";
import PaymentOTC from "./payment-methods/PaymentOTC";
import { useStores } from "src/hooks/useStore";
import { PaymentMode } from "src/store/trade/PaymentStore";
import { observer } from "mobx-react";
import { openUrl } from "src/utils/helper";

const Container = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  height: 100%;

  ${Array.from({ length: 30 })
    .map(
      (_, i) => `
    .top-${i} {
      margin-top: ${i}px;
    }
    .bottom-${i} {
      margin-bottom: ${i}px;
    }
    .left-${i} {
      margin-left: ${i}px;
    }
    .right-${i} {
      margin-right: ${i}px;
    }
  `
    )
    .join("")}

  .tips {
    margin-top: 24px;
    margin-bottom: 24px;

    > img {
      display: block;
      margin: 0 auto;
    }

    > div {
      margin-top: 7px;
      line-height: 130%;
      text-align: center;
    }
  }

  .text-normal {
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 400;
  }

  .text-strong {
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 700;
  }

  .asset-item {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  .scrollable {
    flex: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-ms-scrollbar {
      width: 0;
    }
  }

  .height-482 {
    height: 482px !important;
  }

  .hidden {
    display: none;
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 8px;

  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: "HarmonyOS Sans Bold";
  font-size: 20px;

  .icon-container {
    text-align: left;
    width: 60px;
    .back-icon {
      width: 24px;
      height: 28px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .spacer {
    width: 60px;
    color: ${({ theme }) => theme.colors.__kontos_blue};

    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
`;

interface PaymentProps {
  isBatchSell?: boolean;
  isInSufficient: boolean;
  onBack?: () => void;
  respTaskData: RespTaskDataV3;
  selectedTaskDataIndex: number;
  onChoosePaymentPlan?: (index: number) => void;
}

const Payment: React.FC<PaymentProps> = observer(
  ({
    isBatchSell = false,
    isInSufficient,
    onBack,
    respTaskData,
    selectedTaskDataIndex,
    onChoosePaymentPlan,
  }) => {
    const { paymentStore } = useStores();
    const paymentOpts: PaymentOptionType[] = useMemo(
      () => [
        {
          label: t("Payment Plan") as string,
          value: PaymentMode.PaymentPlan,
          toastObj: {
            text: <span>{t("You don't have enough balance")}</span>,
            type: "warning",
          },
          isAllowed: !isInSufficient,
        },
        {
          label: t("Receive") as string,
          value: PaymentMode.Receive,
          toastObj: {
            text: (
              <span>
                {t(
                  "You have enough balance and don't need to receive to execute your intention"
                )}
              </span>
            ),
            type: "warning",
          },
          isAllowed:
            isInSufficient &&
            paymentStore.paymentScopes.includes(PaymentMode.Receive),
        },
        {
          label: t("Bridge") as string,
          value: PaymentMode.Bridge,
          toastObj: {
            text: (
              <span>
                {t(
                  "You have enough balance and don't need bridge to execute your intention"
                )}
              </span>
            ),
            type: "warning",
          },
          isAllowed:
            isInSufficient &&
            paymentStore.paymentScopes.includes(PaymentMode.Bridge),
        },
        {
          label: t("OTC") as string,
          value: PaymentMode.OTC,
          toastObj: {
            text: (
              <span>
                {t(
                  "You have enough balance and don't need OTC to execute your intention"
                )}
              </span>
            ),
            type: "warning",
          },
          isAllowed:
            isInSufficient &&
            paymentStore.paymentScopes.includes(PaymentMode.OTC),
        },
      ],
      [isInSufficient, paymentStore.paymentScopes]
    );
    const realPaymentOpts: PaymentOptionType[] = useMemo(() => {
      return paymentOpts.filter((opt) => opt.isAllowed);
    }, [paymentOpts]);
    const getPaymentOpt = useCallback(
      (_mode: PaymentMode): PaymentOptionType => {
        return paymentOpts.find((opt) => opt.value === _mode) || paymentOpts[0];
      },
      [paymentOpts]
    );
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
      useState<PaymentOptionType>(getPaymentOpt(paymentStore.paymentMode));
    const handlePaymentMethodSelect = (option: PaymentOptionType) => {
      setSelectedPaymentMethod(option);
      paymentStore.setPaymentMode(option.value);
    };
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [innerChoosing, setInnerChoosing] = useState<boolean>(false);
    const [backClickCount, setBackClickCount] = useState<number>(0);

    const handleChoosing = (isOpen: boolean) => {
      setInnerChoosing(isOpen);
    };

    const handleChangeSuccess = () => {
      onBack?.();
    };

    useEffect(() => {
      if (isInSufficient) {
      } else {
      }
    }, [isInSufficient]);

    return (
      <Container ref={wrapperRef}>
        {/* Title */}
        <TitleBox>
          <div className="icon-container">
            <img
              className="back-icon"
              src={LeftBlueArrowSvg}
              alt="back"
              onClick={() => {
                if (innerChoosing) {
                  setInnerChoosing(false);
                  setBackClickCount((prev) => prev + 1);
                } else {
                  onBack && onBack();
                }
              }}
            />
          </div>
          <span>{isBatchSell ? t("Batch Sell") : t("Payment")}</span>
          <div
            className="spacer"
            onClick={() => {
              const url =
                "https://docs.kontos.io/product-handbook/faq-for-kontos-bots";
              openUrl(url);
            }}
          >
            {t("Help?")}
          </div>
        </TitleBox>

        {/* Select */}
        {!isBatchSell && (
          <div className={`top-16 ${innerChoosing && "hidden"} `}>
            <PaymentSwitch
              icon={ChooseAssetSvg}
              text={t("Switch pay mode")}
              selected={selectedPaymentMethod}
              options={realPaymentOpts}
              onChange={handlePaymentMethodSelect}
            />
          </div>
        )}

        {/* Payment Plan Items */}
        {paymentStore.paymentMode === PaymentMode.PaymentPlan && (
          <PaymentPlan
            newTasksByPaymentPlans={respTaskData.newTasksByPaymentPlans}
            selectedTaskDataIndex={selectedTaskDataIndex}
            onSelectPaymentPlan={(index) => {
              onChoosePaymentPlan?.(index);
            }}
          />
        )}

        {paymentStore.paymentMode === PaymentMode.Bridge && (
          <PaymentBridge
            taskData={respTaskData}
            choosing={innerChoosing}
            onChoosing={handleChoosing}
            onSuccess={handleChangeSuccess}
          />
        )}

        {paymentStore.paymentMode === PaymentMode.Receive && (
          <PaymentReceive
            taskData={respTaskData}
            choosing={innerChoosing}
            onChoosing={handleChoosing}
            onSuccess={handleChangeSuccess}
          />
        )}

        {paymentStore.paymentMode === PaymentMode.OTC && (
          <>
            <PaymentOTC
              taskData={respTaskData}
              choosing={innerChoosing}
              backClickCount={backClickCount}
              onChoosing={handleChoosing}
              onSuccess={handleChangeSuccess}
            />
          </>
        )}
      </Container>
    );
  }
);

export default Payment;
