import { ToSendFtAssetType } from "../send-h5/StepSend";

export enum ContractInteractionType {
  BuyToken = "buyToken",
  SellToken = "sellToken",
  SendKontosNative = "sendKontosNative",
  SendKontosOthers = "sendKontosOthers",
  SendOthers = "sendOthers",
  Dapp = "dapp",
  Swap = "swap",
  BatchSell = "batchSell",
}

export const transferToSendFtAssetTypeToInteractionType = (
  type: ToSendFtAssetType
): ContractInteractionType => {
  switch (type) {
    case ToSendFtAssetType.KontosNative:
      return ContractInteractionType.SendKontosNative;
    case ToSendFtAssetType.KontosOthers:
      return ContractInteractionType.SendKontosOthers;
    case ToSendFtAssetType.Others:
      return ContractInteractionType.SendOthers;
  }
};
