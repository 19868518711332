import styled from "styled-components";
import goodIcon from "src/assets/icons/payment/good.svg";
import secureIcon from "src/assets/icons/payment/secure.svg";
import securePlusIcon from "src/assets/icons/payment/secure-plus.svg";
import alertIcon from "src/assets/icons/payment/alert.svg";
import alertPlusIcon from "src/assets/icons/payment/alert-plus.svg";
import riskyIcon from "src/assets/icons/payment/risky.svg";
import riskyPlusIcon from "src/assets/icons/payment/risky-plus.svg";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  user-select: none;
  max-width: 320px;
  padding: 8px;
  border-radius: 8px;
  background: var(--Deep-800, #1a2535);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const TipsIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const LightText = styled.span`
  color: var(--White, #fff);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

const DeepText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

export enum SecurityTipType {
  Good = "good",
  Secure = "secure",
  Alert = "alery",
  Risky = "risky",
  SecurePlus = "securePlus",
  AlertPlus = "alertPlus",
  RiskyPlus = "riskyPlus",
}

interface IProps {
  type?: SecurityTipType;
}

export const SecurityTip: React.FC<IProps> = ({ type }) => {
  const { t } = useTranslation();

  switch (type) {
    case SecurityTipType.Good:
      return (
        <Container>
          <TipsIcon src={goodIcon} />
          <Content>
            <LightText>
              {t("This asset has passed the liquidity certification.")}
            </LightText>
          </Content>
        </Container>
      );
    case SecurityTipType.Secure:
      return (
        <Container>
          <TipsIcon src={secureIcon} />
          <Content>
            <LightText>{t("This asset is secure.")}</LightText>
          </Content>
        </Container>
      );
    case SecurityTipType.Alert:
      return (
        <Container>
          <TipsIcon src={alertIcon} />
          <Content>
            <LightText>{t("This asset carries some risk!")}</LightText>
          </Content>
        </Container>
      );
    case SecurityTipType.Risky:
      return (
        <Container>
          <TipsIcon src={riskyIcon} />
          <Content>
            <LightText>
              {t(
                "This asset carries a high level of risk. Please take necessary precautions!"
              )}
            </LightText>
          </Content>
        </Container>
      );
    case SecurityTipType.SecurePlus:
      return (
        <Container>
          <TipsIcon src={securePlusIcon} />
          <Content>
            <LightText>{t("This asset is secure.")}</LightText>
            <DeepText>{t("*Passed the liquidity certification.")}</DeepText>
          </Content>
        </Container>
      );
    case SecurityTipType.AlertPlus:
      return (
        <Container>
          <TipsIcon src={alertPlusIcon} />
          <Content>
            <LightText>{t("This asset carries some risk!")}</LightText>
            <DeepText>{t("*Passed the liquidity certification.")}</DeepText>
          </Content>
        </Container>
      );
    case SecurityTipType.RiskyPlus:
      return (
        <Container>
          <TipsIcon src={riskyPlusIcon} />
          <Content>
            <LightText>
              {t(
                "This asset carries a high level of risk. Please take necessary precautions!"
              )}
            </LightText>
            <DeepText>{t("*Passed the liquidity certification.")}</DeepText>
          </Content>
        </Container>
      );
    default:
      return <></>;
  }
};

export const CantSellTip: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <LightText>
          {t(
            "Unable to select this asset because it is not on Kontos' token whitelist!"
          )}
        </LightText>
      </Content>
    </Container>
  );
};
