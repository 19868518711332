import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL_URL } from '../../config';

const authLink = setContext((_, { headers }) => {
  if (!headers) headers = {};

  return {
    headers,
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: GRAPHQL_URL,
  // link: authLink.concat(createHttpLink({ uri: GRAPHQL_URL })),
});
