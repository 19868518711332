import { VerifyStatus, verify } from "src/store/DappConnectStore";
import { DappFavoritesItem, DappHistoryItem } from "src/type/dapp";
import styled from "styled-components";
import matchIco from "src/assets/icons/dapp/wc-match.svg";
import { DappAppItem } from "./DappAppItem";
import { beautifyISOTimestamp, openUrl } from "src/utils/helper";
import { CloseButton } from "src/components/button/CloseButton";
import FavoriteButton from "src/components/button/FavoriteButton";
import closeIcon from "src/assets/icons/close4.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dapp-history-card-left {
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .dapp-history-card-left-icon {
      display: flex;
      align-items: center;
      width: 56px;
      height: 56px;
    }

    .dapp-history-card-left-content {
      overflow-x: hidden;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .dapp-history-card-left-content-line-1 {
        display: flex;
        align-items: center;

        .dapp-history-card-left-content-line-1-text {
          color: var(--Deep-800, #1a2535);
          font-family: "HarmonyOS Sans Bold";
          font-size: 18px;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .dapp-history-card-left-content-line-1-icon {
          margin-left: 5px;
          width: 15.743px;
          height: 15.743px;
        }
      }

      .dapp-history-card-left-content-line-2 {
        margin-top: 7px;
        overflow: hidden;
        color: var(--Deep-400, #80868f);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .dapp-history-card-right {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  width: 16px;
  height: 16px;
`;

interface IDisplayProps {
  icon: any;
  statusIcon: any;
  title: string;
  subTitle: string;
  url: string;
}

interface BaseProps {
  className?: string;
  onRightClick: () => void;
}

interface HistoryProps extends BaseProps {
  historyItem: DappHistoryItem;
  favoritesItem?: never;
  isFavorite?: never;
}

interface FavoritesProps extends BaseProps {
  historyItem?: never;
  favoritesItem: DappFavoritesItem;
  isFavorite: boolean;
}

type IProps = HistoryProps | FavoritesProps;

export const DappCard: React.FC<IProps> = ({
  className,
  historyItem,
  favoritesItem,
  isFavorite,
  onRightClick,
}) => {
  let iDisplayItem: IDisplayProps | undefined = undefined;

  if (!!favoritesItem) {
    const statusIcon = favoritesItem.verified ? matchIco : undefined;

    iDisplayItem = {
      icon: favoritesItem.imageUrl,
      statusIcon,
      title: favoritesItem.name,
      subTitle: favoritesItem.desc,
      url: favoritesItem.appUrl,
    };
  }

  if (!!historyItem) {
    const type = verify(
      historyItem.verifyContext.verified.isScam,
      historyItem.verifyContext.verified.validation
    );
    const statusIcon = type === VerifyStatus.DomainMatch ? matchIco : undefined;
    iDisplayItem = {
      icon: historyItem.metadata.icons?.[0],
      statusIcon,
      title: historyItem.metadata.name,
      subTitle: beautifyISOTimestamp(historyItem.timestamp),
      url: historyItem.metadata.url,
    };
  }

  if (!iDisplayItem) {
    return <></>;
  }

  return (
    <Container className={className}>
      <div
        className="dapp-history-card-left"
        onClick={() => openUrl(iDisplayItem?.url, null)}
      >
        <div className="dapp-history-card-left-icon">
          <DappAppItem icon={iDisplayItem.icon} />
        </div>
        <div className="dapp-history-card-left-content">
          <div className="dapp-history-card-left-content-line-1">
            <span className="dapp-history-card-left-content-line-1-text">
              {iDisplayItem.title}
            </span>

            {iDisplayItem.statusIcon && (
              <img
                className="dapp-history-card-left-content-line-1-icon"
                src={iDisplayItem.statusIcon}
                alt=""
              />
            )}
          </div>
          <div className="dapp-history-card-left-content-line-2">
            {iDisplayItem.subTitle}
          </div>
        </div>
      </div>
      <div className="dapp-history-card-right">
        {!!historyItem && (
          <StyledCloseButton
            closeIco={closeIcon}
            onClick={onRightClick}
            $lazy
          />
        )}
        {!!favoritesItem && (
          <FavoriteButton isFavorite={isFavorite} onClick={onRightClick} />
        )}
      </div>
    </Container>
  );
};
