import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.p`
  text-align: center;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans";
  font-size: 14px;
  font-weight: 400;
`;

export const EndMessage: React.FC = () => {
  const { t } = useTranslation();

  return <Container>{t("You have seen it all")}</Container>;
};
