import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import styled from "styled-components";
import { useStores } from "src/hooks/useStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchInput } from "src/components/input-search/InputSearch";
import Long from "long";
import { AssetsList } from "src/components/list/assetList";
import MarketsRanking from "src/components/markets-ranking/MarketsRanking";
import { PopupMarketRankings } from "src/components/popups/PopupMarketRankings";
import { ISortParams } from "src/components/filter/FilterSectionMarkets";
import {
  AssetLabel,
  getFtAssetsLabel,
  getFtAssetsV3,
  getMarketPredictInfo,
} from "src/apis/markets-apis";
import Skeleton from "react-loading-skeleton";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { loadingStore } from "src/store/loadingStore";
import Sheet from "react-modal-sheet";
import SelectFtAssetsModal from "src/pages/markets/SelectFtAssetsModal";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import { AssetDetailQuote } from "src/components/assets-detail/AssetDetailQuote";
import { QuoteWrapper } from "src/components/assets-detail/QuoteWrapper";
import { VoteAction } from "src/apis/markets-apis";
import MarketsChainRanking from "src/components/markets-ranking/MarketsChainRanking";
import SkeletonMarketAssetList from "src/components/skeleton/SkeletonMarketAssetList";
import { FtAsset } from "@/type/zkkontos";
import { openUrl } from "src/utils/helper";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  margin: 0 auto 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;

  .markets-header {
    padding: 20px 0 16px 0;
    position: sticky;
    top: 0;
    z-index: 10;

    .markets-header-top {
      margin: 0 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .markets-header-top-left {
        font-family: "HarmonyOS Sans Bold";
        font-size: 28px;
        color: #1a2535;
      }

      .markets-header-top-right {
        font-family: "HarmonyOS Sans Bold";
        font-size: 16px;
        color: ${({ theme }) => theme.colors.__deep_400};
        cursor: pointer;
      }
    }
  }
`;

const SearchWrapper = styled.div`
  position: relative;

  .transparent-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const VoteWrapper = styled.div`
  padding: 8px 8px 0;
  position: relative;
  z-index: 2;
  margin-bottom: 16px;

  .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 14px;
  }

  .btnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      height: 30px;
      border-radius: 8px;
      text-align: center;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      margin: 0 3px;
      display: inline-block;
      width: auto;
      flex: 1;
      cursor: pointer;
    }

    .btn.bullish {
      border: 1px solid rgba(0, 174, 70, 0.1);
      background: rgba(0, 238, 95, 0.1);
      color: #03c14f;
      margin-left: -3px;
    }

    .btn.bearish {
      border: 1px solid rgba(255, 30, 43, 0.1);
      background: rgba(243, 95, 104, 0.1);
      color: #ff6972;
      margin-right: -3px;
    }
  }
`;

const VotedWrapper = styled.div`
  padding: 8px 8px 0;
  position: relative;
  z-index: 2;
  margin-bottom: 16px;

  .title {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 14px;
  }

  .voted-content {
    .value {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      .l,
      .r {
        color: var(--Deep-400, #80868f);
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
      }
    }

    .progress {
      width: 100%;
      height: 10px;
      position: relative;

      .progress-bar {
        height: 10px;
        border-radius: 8px;
        position: absolute;
        top: 0;
        transition: width 0.7s ease; /* 定义动画持续时间和缓动函数 */

        &.bullish {
          background: rgba(0, 238, 95, 0.5);
          left: 0;
        }

        &.bearish {
          background: rgba(255, 64, 76, 0.5);
          right: 0;
        }
      }
    }
  }
`;

const ListWrapper = styled.div`
  padding: 0 0 80px;
  width: 100%;
  // height: 347px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  display: flex;

  > div,
  > span {
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const initSortParams: ISortParams = {
  sortName: 0,
  sortPriceChange: 0,
  sortLastPrice: 0,
  sortH24Vol: 0,
};

const MarketsPage: React.FC = observer(() => {
  const { userStore, marketStore } = useStores();
  useState<boolean>(false);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPopupMarketsRanking, setShowPopupMarketsRanking] =
    useState<boolean>(false);
  const isVoted =
    marketStore.votedStatus === 0 || marketStore.votedStatus === 1;
  let bullish = parseFloat(
    (
      (marketStore.bullishVotes /
        (marketStore.bullishVotes + marketStore.bearishVotes)) *
      100
    ).toFixed(2)
  );
  let bearish = parseFloat((100 - bullish).toFixed(2));
  if (
    marketStore.bullishVotes === marketStore.bearishVotes &&
    marketStore.bullishVotes === 0
  ) {
    bullish = 50;
    bearish = 50;
  }
  const [loadingCategory, setLoadingCategory] = useState<boolean>(false);
  const [ftAssets, setFtAssets] = useState<FtAsset[]>([]);
  const [loadingFtAssets, setLoadingFtAssets] = useState<boolean>(false);
  const selectedCategory = marketStore.selectedCategory;
  const [selectedChain, setSelectedChain] = useState<string>("");
  const [sortParams, setSortParams] = useState<ISortParams>(initSortParams);
  const [marketPageCount, setMarketPageCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // quote modal
  const [searchParams, setSearchParams] = useSearchParams();
  const showQuote = searchParams.get("showQuote");
  // search modal
  const [isSelectAssetsModalOpen, setIsSelectAssetsModalOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const vote = async (voteType: VoteAction) => {
    const cli = userStore.kontosCli;
    if (!cli) {
      userStore.unlock(() => vote(voteType));
      return;
    }
    loadingStore.showLoading();
    try {
      await marketStore.vote({
        account: userStore.accountInfo?.name || "",
        cli,
        action: voteType,
      });
      loadingStore.hideLoading();
    } catch (e) {
      console.log("vote err: ", e);
      loadingStore.hideLoading();
    }
  };

  useEffect(() => {
    const getMarketPredictInfoFunc = async () => {
      if (!userStore.accountInfo?.name) return;
      const { marketPredict } = await getMarketPredictInfo({
        account: userStore.accountInfo?.name,
      });
      marketStore.updateVotes({
        bullishVotes: marketPredict.bullish,
        bearishVotes: marketPredict.bearish,
        action: marketPredict.action,
      });
    };
    getMarketPredictInfoFunc();
  }, [userStore.accountInfo?.name]);

  useEffect(() => {
    function handleScroll() {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        // load data
        if (
          !loadingFtAssets &&
          hasMore &&
          scrollHeight - scrollTop - clientHeight < 100
        ) {
          setMarketPageCount(marketPageCount + 1);
        }
      }
    }

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, loadingFtAssets]);

  useEffect(() => {
    const loadMore = async () => {
      try {
        setLoadingFtAssets(true);
        if (marketStore.categoryArr.length === 0) {
          setLoadingCategory(true);
          const rs = await getFtAssetsLabel({});
          const ftAssetsCategory = rs.assetLabels.filter(
            (item) => item.chainIndex === ""
          );
          const chainArr = rs.assetLabels.filter(
            (item) => item.chainIndex !== ""
          );
          marketStore.setCategoryArr(ftAssetsCategory);
          marketStore.setChainArr(chainArr);
          setLoadingCategory(false);
          return;
        }
        const { ftAssets } = await getFtAssetsV3({
          chainIndex: "",
          category: selectedCategory,
          subcategory: selectedChain,
          offset: (marketPageCount - 1) * pageSize,
          limit: pageSize,
          isRecommended: selectedCategory === 1,
          inWhoseWatchlist:
            selectedCategory === 0 ? userStore.accountInfo?.name : undefined,
        });
        setLoadingFtAssets(false);
        setFtAssets((prevItems) => [...prevItems, ...(ftAssets || [])]);
        if (!ftAssets || ftAssets.length < pageSize) {
          setHasMore(false);
          return;
        }
      } catch (e) {
        console.log("get market data err: ", e);
        setLoadingFtAssets(false);
        setLoadingCategory(false);
      }
    };
    loadMore();
  }, [
    selectedCategory,
    marketStore.categoryArr,
    selectedChain,
    sortParams,
    marketPageCount,
    userStore.accountInfo?.name,
  ]);

  const initListData = () => {
    setFtAssets([]);
    setMarketPageCount(1);
    setHasMore(true);
  };

  const handleRankingItemClick = (item: AssetLabel) => {
    if (item.category === selectedCategory || loadingFtAssets) return;
    initListData();
    marketStore.setSelectedCategory(item.category);
    // if (item.category === 6) {
    //   setSortParams({
    //     ...initSortParams,
    //     sortH24Vol: 2,
    //   });
    // }
  };

  return (
    <Wrapper ref={wrapperRef}>
      <div className="markets-header">
        {/* Header Text */}
        <div className="markets-header-top">
          <span className="markets-header-top-left">{t("Markets")}</span>
          <span
            className="markets-header-top-right"
            onClick={() => {
              openUrl("https://docs.kontos.io/");
            }}
          >
            {t("Help?")}
          </span>
        </div>
      </div>
      <SearchWrapper>
        <SearchInput
          ref={inputRef}
          placeholder={t("Search Assets")}
          value={""}
          disabled={true}
        />
        <div
          onClick={() => {
            setIsSelectAssetsModalOpen(true);
          }}
          className={"transparent-mask"}
        />
      </SearchWrapper>
      {/* {!isVoted ? (
        <VoteWrapper>
          <div className={"title"}>
            {t("How do you feel about the market today?")}
          </div>
          <div className={"btnWrap"}>
            <div
              className={"btn bullish"}
              onClick={() => {
                vote(0);
              }}
            >
              {t("Bullish")}
            </div>
            <div
              className={"btn bearish"}
              onClick={() => {
                vote(1);
              }}
            >
              {t("Bearish")}
            </div>
          </div>
        </VoteWrapper>
      ) : (
        <VotedWrapper>
          <div className={"title"}>{t("Voted! Join again tomorrow.")}</div>
          <div className={"voted-content"}>
            <div className={"value"}>
              <div className={"l"}>
                {t("Bullish")} {bullish}%
              </div>
              <div className={"r"}>
                {t("Bearish")} {bearish}%
              </div>
            </div>
            <div className={"progress"}>
              <div
                className={"progress-bar bullish"}
                style={{ width: `${bullish - 0.5}%` }}
              />
              <div
                className={"progress-bar bearish"}
                style={{ width: `${bearish - 0.5}%` }}
              />
            </div>
          </div>
        </VotedWrapper>
      )} */}

      <div style={{ marginTop: "16px" }}></div>

      {loadingCategory && (
        <Skeleton
          count={1}
          style={{
            display: "inline-block",
            height: "30px",
            width: "70px",
            margin: "0 24px",
          }}
        />
      )}
      {!loadingCategory && (
        <MarketsRanking
          selectedCategory={selectedCategory}
          itemClick={(item) => {
            handleRankingItemClick(item);
          }}
          wrapperStyles={{
            padding: "0 8px",
            marginBottom: "16px",
          }}
          styles={{}}
          moreBtnClick={() => {
            setShowPopupMarketsRanking(true);
          }}
        />
      )}
      {!loadingCategory && (
        <MarketsChainRanking
          selectedChain={selectedChain}
          itemClick={(item) => {
            if (loadingFtAssets) return;
            initListData();
            setSelectedChain(item.chainIndex);
          }}
        />
      )}

      {/*<FilterSectionMarkets*/}
      {/*  sortParams={sortParams}*/}
      {/*  setSortParams={(sortParams) => {*/}
      {/*    if (loadingFtAssets) return;*/}
      {/*    initListData();*/}
      {/*    setSortParams(sortParams);*/}
      {/*  }}*/}
      {/*  styles={{ padding: "0 8px" }}*/}
      {/*/>*/}
      <ListWrapper ref={scrollContainerRef}>
        {ftAssets.map((item, index) => (
          <AssetsList
            item={{
              name: item.name,
              symbol: item.symbol,
              imageUrl: item.imageUrl,
              desc: "",
              assetType: 0,
              ftAssets: [],
              totalUsd: item.h24Volume,
            }}
            chainUrl={item.chainGreyImageUrl}
            fluctuate={item.h24PriceChangePercentage}
            isMarket={true}
            usdPrice={item.usdPrice}
            chainSymbol={item.chainSymbol}
            key={index.toString()}
            handleClick={() => {
              marketStore.setCurrentAssetQuote(item);
              setSearchParams({
                ...searchParams,
                ftAssetId: item.ftAssetId.toString(),
                chainIndex: item.chainIndex,
                ftAssetAddress: item.address,
                showQuote: "1",
                hideNavigationBar: "1",
              });
            }}
            index={index}
            showRanking={false}
            isVerified={item.isGreatLiquidity === 1}
            volumeUnit={t("Vol: ")}
            isGreatLiquidity={item.isGreatLiquidity}
            securityLevel={item.securityLevel}
          />
        ))}
        {loadingFtAssets && (
          <>
            <div style={{ height: "18px" }} />
            <SkeletonMarketAssetList length={ftAssets.length === 0 ? 6 : 3} />
          </>
        )}
        {!loadingFtAssets && !hasMore && <></>}
        {!loadingFtAssets && ftAssets.length === 0 && (
          <NoDataV2
            style={{
              paddingTop: "50px",
              alignItems: "center",
            }}
            text={t("No data")}
          />
        )}
      </ListWrapper>
      {showPopupMarketsRanking && (
        <PopupMarketRankings
          selectedCategory={selectedCategory}
          onConfirm={(item) => {
            handleRankingItemClick(item);
            setShowPopupMarketsRanking(false);
          }}
          onCancel={() => {
            setShowPopupMarketsRanking(false);
          }}
          onOutClick={() => {
            setShowPopupMarketsRanking(false);
          }}
          title={t("All Rankings")}
        />
      )}
      <NoScrollerBarSheet
        isOpen={isSelectAssetsModalOpen}
        onClose={() => setIsSelectAssetsModalOpen(false)}
        mountPoint={domNode}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <SelectFtAssetsModal
              onClose={() => setIsSelectAssetsModalOpen(false)}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setIsSelectAssetsModalOpen(false)} />
      </NoScrollerBarSheet>
      {showQuote && (
        <QuoteWrapper>
          <AssetDetailQuote />
        </QuoteWrapper>
      )}
    </Wrapper>
  );
});

export default MarketsPage;
