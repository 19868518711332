import styled from "styled-components";
import React from "react";
import poweredBy from "src/assets/icons/powered-by1.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  padding-top: 20px;
  left: 0;
  width: 100%;
  img {
    width: 141px;
    height: 25px;
    flex-shrink: 0;
  }
`;

interface IProps {
  className?: string;
  style?: any;
}

const PoweredBy: React.FC<IProps> = ({ className, style }) => {
  return (
    <Wrapper className={className} style={style}>
      <img src={poweredBy} alt="powerdBy" />
    </Wrapper>
  );
};

export default PoweredBy;
