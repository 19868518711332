import styled from "styled-components";
import ArrowSvg from "src/assets/icons/contract/right-black-arrow.svg";
import AssetOmitSvg from "src/assets/icons/asset-omit.svg";
import { DEFAULT_ASSET_ICON_OMIT } from "src/config";
import defaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import { useEffect, useState } from "react";
import { t } from "i18next";
import ImageWithFallback from "src/components/images/ImageWithFallback";

const Container = styled.div`
  padding: 4px 9px 4px 5px;

  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const IconContainer = styled.div`
  display: flex;
`;

const Icon = styled.img<{ index: number }>`
  width: 22px;
  height: 22px;
  z-index: ${(props) => props.index};

  border-radius: 50%;

  &:not(:first-child) {
    margin-left: -7px;
  }
`;

const Text = styled.span`
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.__deep_800};
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
`;

const Arrow = styled.img`
  margin-left: 8px;
  height: 11px;
`;

interface PlanButtonProps {
  iconList: string[];
  max: number;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const defaultIconList = [defaultTokenSvg];
const defaultText = t("Recommend Plan");

const PlanButton: React.FC<PlanButtonProps> = ({
  iconList = defaultIconList,
  max = DEFAULT_ASSET_ICON_OMIT,
  text = defaultText,
  onClick,
}) => {
  const [thisIconList, setThisIconList] = useState(iconList);

  useEffect(() => {
    let updatedIconList = [...iconList];
    if (iconList.length > max) {
      updatedIconList[max - 1] = AssetOmitSvg;
      updatedIconList = updatedIconList.slice(0, max);
    }
    setThisIconList(updatedIconList);
  }, [iconList, max]);

  return (
    <Container onClick={(event) => onClick && onClick(event)}>
      <IconContainer>
        {thisIconList.map((icon, index) => {
          return (
            <ImageWithFallback
              key={index}
              src={icon}
              fallbackSrc={defaultTokenSvg}
              StyledImg={Icon}
              extraProps={{ index: index }}
            />
          );
        })}
      </IconContainer>
      <Text>{text}</Text>
      <Arrow src={ArrowSvg} />
    </Container>
  );
};

export default PlanButton;
