import { ROUTE_AUTH_NEW } from "src/router/router-config";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { KontosButton } from "src/components/button/KontosButton";
import { useTranslation } from "react-i18next";
import { BASIC_SHEET_INDEX } from "src/config";

const Container = styled.div`
  max-width: 343px;
  border-radius: 16px;
  background: var(--White, #fff);
  margin: 16px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans Bold";
    font-size: 18px;
    line-height: 22px;
  }

  > .desc {
    margin-top: 11px;
    padding: 0 11px;
    color: var(--Deep-800, #1a2535);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  .eb-help-login-connect-btn {
    margin-top: 22px;
    padding: 16px !important;
  }

  > .later {
    margin-top: 22px;
    color: var(--Deep-400, #80868f);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;

    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.9;
    }
  }
`;

interface Props {
  inviterName: string;
  onClose: (open: boolean) => void;
}

export const EbHelpLoginPopup: React.FC<Props> = ({ inviterName, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleConnect = useCallback(() => {
    navigate(
      ROUTE_AUTH_NEW + `?referralCode=${inviterName.replaceAll(".os", "")}`
    );
  }, [inviterName, navigate]);

  return (
    <Overlay
      zIndex={BASIC_SHEET_INDEX}
      outerOpacity={0.5}
      onClick={() => onClose(false)}
    >
      <Container>
        <div className="title">{t("Oops!")}</div>
        <div className="desc">
          {t("You must connect to Kontos to recharge for the user!")}
        </div>
        <KontosButton
          className="eb-help-login-connect-btn"
          onClick={handleConnect}
        >
          {t("Connect to Kontos")}
        </KontosButton>
        <div className="later" onClick={() => onClose(false)}>
          {t("Later")}
        </div>
      </Container>
    </Overlay>
  );
};
