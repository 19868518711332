import styled from "styled-components";
import { useTranslation } from "react-i18next";
import avatarIco from "src/assets/icons/dapp/wc-avatar.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import { getChainIcon, getChainSymbol } from "src/utils/storeUtils";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WcConnectWalletItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .left {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  > .right {
    display: flex;
    align-items: center;

    > .item-icon {
      width: 22px;
      height: 22px;
    }

    > span {
      margin-left: 6px;
      overflow: hidden;
      color: var(--Deep-400, #80868f);
      text-align: right;
      text-overflow: ellipsis;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

interface Props {
  className?: string;
  wallet: string;
  chainId?: string;
}

export const WcConnectItems: React.FC<Props> = ({
  className,
  wallet,
  chainId,
}) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      {/* Network */}
      {chainId && (
        <WcConnectWalletItem>
          <div className="left">{t("Network: ")}</div>
          <div className="right">
            <ImageWithFallback
              className="item-icon"
              src={getChainIcon(chainId)}
              alt=""
            />
            <span>{getChainSymbol(chainId)}</span>
          </div>
        </WcConnectWalletItem>
      )}

      {/* Account */}
      <WcConnectWalletItem>
        <div className="left">{t("Wallet: ")}</div>
        <div className="right">
          <img className="item-icon" src={avatarIco} alt="" />
          <span>{wallet.replaceAll(".os", "") + ".os"}</span>
        </div>
      </WcConnectWalletItem>
    </Container>
  );
};
