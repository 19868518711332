import styled from "styled-components";
import { observer } from "mobx-react";
import arrowIcon from "src/assets/icons/settings/deep-right-arrow.svg";
import { ReactNode } from "react";
import { ToggleButton } from "../toggle/ToggleButton";

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  border-radius: 8px;
  background: ${(props) => props.theme.colors.__white};

  margin-bottom: 8px;
  padding: 13px 25px 13px 20px;

  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.__deep_25};
  }

  .left {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    .main-icon {
      width: 30px;
      height: 30px;

      margin-right: 19px;
    }

    .title {
      color: ${(props) => props.theme.colors.__deep_800};
      font-family: HarmonyOS Sans SC;
      font-size: 18px;
      font-weight: 400;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .right {
    margin-left: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow-icon {
    }
  }
`;

interface Props {
  mainIcon: any;
  title: string;
  isOn?: boolean;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onToggle?: () => void;
}

const SettingsItem = ({
  mainIcon,
  title,
  children,
  isOn,
  onClick,
  onToggle,
}: Props) => {
  return (
    <Container
      onClick={async (event) => {
        onClick && onClick(event);
      }}
    >
      <div className="left">
        <img className="main-icon" src={mainIcon} alt="" />
        <span className="title">{title}</span>
      </div>
      {children}
      <div className="right">
        {onToggle && typeof isOn === "boolean" ? (
          <ToggleButton isOn={isOn} toggleSwitch={onToggle} />
        ) : (
          <img className="arrow-icon" src={arrowIcon} alt="" />
        )}
      </div>
    </Container>
  );
};

export default observer(SettingsItem);
