import React from "react";
import styled from "styled-components";
import ImageWithFallback from "src/components/images/ImageWithFallback";

const IconBox = styled.div<{
  totalWidth: number;
  totalHeight: number;
  mainWidth: number;
  mainHeight: number;
  subWidth: number;
  subHeight: number;
  horizontalOffset: number;
  verticalOffset: number;
}>`
  position: relative;

  width: ${($props) => $props.totalWidth}px;
  height: ${($props) => $props.totalHeight}px;

  .main-icon {
    width: ${($props) => $props.mainWidth}px;
    height: ${($props) => $props.mainHeight}px;

    position: absolute;
    left: 0;
    top: 0;

    border-radius: 50%;
  }

  .sub-icon {
    width: ${($props) => $props.subWidth}px;
    height: ${($props) => $props.subHeight}px;

    position: absolute;
    left: ${($props) => $props.horizontalOffset}px;
    top: ${($props) => $props.verticalOffset}px;

    border-radius: 50%;
  }
`;

interface CircleIconProps {
  mainIcon: any;
  mainIconFallbackSrc?: any;
  subIcon: any;
  subIconFallbackSrc?: any;
  mainWidth: number;
  mainHeight: number;
  subWidth: number;
  subHeight: number;
  totalWidth: number;
  totalHeight: number;
  className?: string;
  style?: React.CSSProperties;
}

const CircleIconPair: React.FC<CircleIconProps> = ({
  mainIcon,
  mainIconFallbackSrc,
  subIcon,
  subIconFallbackSrc,
  mainWidth,
  mainHeight,
  subWidth,
  subHeight,
  totalWidth,
  totalHeight,
  className,
  style,
}) => {
  const horizontalOffset = totalWidth - subWidth;
  const verticalOffset = totalHeight - subHeight;

  return (
    <IconBox
      totalWidth={totalWidth}
      totalHeight={totalHeight}
      mainWidth={mainWidth}
      mainHeight={mainHeight}
      subWidth={subWidth}
      subHeight={subHeight}
      horizontalOffset={horizontalOffset}
      verticalOffset={verticalOffset}
      className={className}
      style={style}
    >
      <ImageWithFallback
        className="main-icon"
        src={mainIcon}
        fallbackSrc={mainIconFallbackSrc}
        alt=""
      />
      {(subIcon || subIconFallbackSrc) && (
        <ImageWithFallback
          className="sub-icon"
          src={subIcon}
          fallbackSrc={subIconFallbackSrc}
          alt=""
        />
      )}
    </IconBox>
  );
};

export default CircleIconPair;
