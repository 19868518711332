import CircleIconPair from "src/components/icons/CircleIconPair";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import { t } from "i18next";
import TokensIcon from "src/components/icons/TokensIcon";
import styled from "styled-components";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import { DEFAULT_DECIMAL, DEFAULT_DISPLAY_PRECESION } from "src/config";
import KontosNumber from "src/utils/KontosNumber";

const GeneralInfoBox = styled.div<{ $isOpen: boolean; $isSelected: boolean }>`
  position: relative;
  z-index: 1;

  border-radius: 8px;
  border: 1px solid
    ${({ theme, $isOpen }) => ($isOpen ? theme.colors.__deep_50 : "none")};

  border-bottom: ${({ $isOpen }) => ($isOpen ? "none" : "")};
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.__white : theme.colors.__deep_25};

  display: flex;
  flex-direction: column;
`;

const SubBox = styled.div<{ $isSelected: boolean }>`
  z-index: -1;
  margin-top: -76px;
  padding-bottom: 5px;
  height: 110px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.__deep_50};
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.__white : theme.colors.__deep_25};

  display: flex;
  align-items: flex-end;
  justify-content: center;

  font-family: HarmonyOS Sans;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.__deep_400};
`;

const NormalContainter = styled.div<{ $isOpen: boolean; $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px 10px 16px 10px;

  border-radius: 8px;
  border: ${({ $isSelected, $isOpen, theme }) =>
    $isSelected
      ? "2px solid " + theme.colors.__kontos_blue
      : $isOpen
        ? "none"
        : "1px solid" + theme.colors.__deep_50};

  &:hover {
    border: ${({ $isSelected, $isOpen, theme }) =>
      $isSelected
        ? "2px solid " + theme.colors.__kontos_blue
        : "1px solid" + theme.colors.__kontos_blue};
  }
`;

const Container = styled.div`
  min-height: 110px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const RightItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .line-1 {
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 700;
  }

  .line-2 {
    color: var(--Deep-400, #80868f);
    font-family: HarmonyOS Sans SC;
    font-size: 12px;
    font-weight: 400;
  }
`;

const ExpandContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 10px 12px 10px;

  /* background: ${({ theme }) => theme.colors.__deep_25}; */

  &:not(:first-child) {
    margin-top: 8px;
  }

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const ItemTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-start;
`;

const ItemTextContainer2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-end;
`;

const ItemText = styled.div`
  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 700;
`;

const ItemText2 = styled.div`
  margin-top: 5px;
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans;
  font-size: 12px;
  font-weight: 400;
`;

interface Props {
  uniformedPayments: UniformedPayment[];
  tokenIcons: any[];
  title: string;
  subTitle: string;
  isOpen: boolean;
  isSelected: boolean;
  onExpand: () => void;
  onSelect: () => void;
}

const PaymentPlanItem: React.FC<Props> = ({
  uniformedPayments,
  tokenIcons,
  title,
  subTitle,
  isOpen,
  isSelected,
  onExpand,
  onSelect,
}) => {
  return (
    <Container>
      <GeneralInfoBox $isOpen={isOpen} $isSelected={isSelected}>
        <NormalContainter
          $isOpen={isOpen}
          $isSelected={isSelected}
          onClick={onSelect}
        >
          <TokensIcon iconList={tokenIcons} size={45} offset={15} max={5} />
          <RightItem>
            <div className="line-1">{title}</div>
            <div className="line-2">{subTitle}</div>
          </RightItem>
        </NormalContainter>
        <ExpandContainer>
          {isOpen &&
            uniformedPayments.map((payment, index) => {
              return (
                <ItemContainer key={index}>
                  <CircleIconPair
                    mainIcon={payment.assetImageUrl || DefaultTokenSvg}
                    mainIconFallbackSrc={DefaultTokenSvg}
                    subIcon={payment.chainGreyImageUrl || DefaultChainSvg}
                    subIconFallbackSrc={DefaultChainSvg}
                    mainWidth={45}
                    mainHeight={45}
                    subWidth={26}
                    subHeight={26}
                    totalWidth={63}
                    totalHeight={49}
                  />
                  <ItemTextContainer>
                    <ItemText>{payment.assetSymbol}</ItemText>
                    <ItemText2>{payment.chainSymbol}</ItemText2>
                  </ItemTextContainer>
                  <ItemTextContainer2>
                    <ItemText>
                      {new KontosNumber(
                        payment.assetAmount,
                        DEFAULT_DECIMAL
                      ).toFormat(DEFAULT_DISPLAY_PRECESION)}
                    </ItemText>
                    <ItemText2>
                      {new KontosNumber(
                        payment.usdAmount,
                        DEFAULT_DECIMAL
                      ).toFormat(DEFAULT_DISPLAY_PRECESION) + " USD"}
                    </ItemText2>
                  </ItemTextContainer2>
                </ItemContainer>
              );
            })}
        </ExpandContainer>
      </GeneralInfoBox>
      <SubBox $isSelected={isSelected} onClick={onExpand}>
        {isOpen ? t("Collapse") : t("Check detail")}
      </SubBox>
    </Container>
  );
};

export default PaymentPlanItem;
