import styled from "styled-components";

export const CenterFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlatBtn = styled.button`
  border: none;
  background: none;
  padding: 0;
`;

export const ModalWrap = styled.div<{ dark: boolean }>`
  position: fixed;
  width: 360px;
  height: 505px;
  left: 0;
  top: 45px;
  background: ${(props) =>
    props.dark ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.5)"};
  z-index: 1000;
  & .bg {
    width: 100%;
    height: 100%;
  }

  & .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 290px;
    background: ${(props) =>
      props.dark ? "#383838" : "rgba(228,228,228,0.95)"};
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
`;

export const Modal = styled(ModalWrap)`
  backdrop-filter: blur(${(props) => (props.dark ? 5 : 1)}px);
  .header {
    position: relative;
    span {
      padding: 8px 0 10px 0;
      display: block;
      text-align: center;
      font-family: "Lexend bold";
      font-size: 14px;
      color: ${(props) => (props.dark ? "#2ad4d9" : "#01ABB2")};
      cursor: default;
    }
    button {
      position: absolute;
      top: 6px;
      right: 10px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      border-radius: 50%;
      background: none;

      svg {
        width: 12px;
        height: 12px;
        color: ${(props) =>
          props.dark ? "rgba(255, 255, 255, 0.5)" : "rgba(90, 90, 90, 0.5)"};
      }

      &:hover {
        background: ${(props) =>
          props.dark ? "rgba(255, 255, 255, 0.2)" : "#F6F6F6"};

        svg {
          color: ${(props) => (props.dark ? "#fff" : "#5A5A5A")};
        }
      }
    }
  }

  .container {
    width: 320px;
    height: 355px;
    border-radius: 5px;
  }
`;

export const Error = styled.div`
  position: absolute;
  bottom: 70px;
  width: 320px;
  min-height: 26px;
  background: #3f3f3f;
  border-top: 0.5px solid #888888;
  text-align: center;
  font-family: "Lexend semibold";
  font-size: 12px;
  color: #fff;
  line-height: 26px;
  cursor: default;
`;

export const scrollbar = `
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const buttonHover = `
  position: relative;
  cursor: pointer;
  &:hover::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    opacity: 0.1;
    border-radius: 54px;
  }
`;

export const buttonClick = `
  position: relative;
  &:active::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.2;
    border-radius: 54px;
  }
`;

export const buttonDisabled = `
  &--disabled,
  &.disabled {
    background: #F5F5F6;
    cursor: not-allowed;
    color: #CCCFD2;
  }
  &.disabled .text,
  &--disabled .text {
    color: #CCCFD2;
  }
`;

export const inputHover = `
  &:hover {
    border: 1px solid #413dec;
    cursor: pointer;
  }
`;
export const inputError = `
  &--hover {
    border: 1px solid #FC5555;
  }
`;
