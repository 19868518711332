import styled from "styled-components";
import { GoogleLoginBtn } from "../button/GoogleLoginBtn";
import tgManager from "src/store/managers/tgManager";
import { useTranslation } from "react-i18next";
import whiteArrowIcon from "src/assets/icons/arrow10.svg";
import blackAddIcon from "src/assets/icons/add-black.svg";
import googleIcon from "src/assets/icons/google.svg";
import tipsIcon from "src/assets/icons/tips.svg";
import Divider from "../divider/Divider";
import { buttonClick, buttonHover } from "../global-styles";

const Container = styled.div`
  height: 100%;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .google-login {
    margin-top: 8px;
    position: absolute;
    opacity: 0.0001;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
  }
`;

const OrLineBox = styled.div`
  padding: 0 25px;
  width: 100%;
  flex: 1;
  margin: auto 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrLine = styled.span`
  background: var(--Deep-50, #edeef1);
  width: 100%;
  max-width: 350px;
  height: 1px;
  //z-index: 1;
`;

const OrTextBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

const TipsBox = styled.div`
  max-width: 350px;
  /* padding: 0 15px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const TipsIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const TipsText = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 16px;
  line-height: 130%;
`;

const DividerBox = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  width: 100%;
  max-width: 400px;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${buttonClick}
  ${buttonHover}
`;

const GoogleButton = styled(Button)`
  padding: 13px 20px 13px 37px;
  background: var(--Kontos-Blue, #413dec);
  cursor: default;
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
  &:active {
    opacity: 1;
  }
`;

const GoogleIcon = styled.img`
  margin-right: 12px;
  width: 30px;
  height: 30px;
`;

const GoogleText = styled.span`
  margin-right: 25px;
  color: var(--White, #fff);
  font-family: "HarmonyOS Sans Bold";
  font-size: 18px;
  line-height: 22px;
`;

const VerificationButton = styled(Button)`
  padding: 16px 20px;
  background: var(--Deep-800, #1a2535);
  color: var(--White, #fff);
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 18px;
  line-height: 22px;
`;

const DirectButton = styled(Button)`
  margin-top: 16px;
  padding: 16px 20px;
  border: 1px solid var(--Deep-100, #cccfd2);
  background: var(--White, #fff);
  color: var(--Deep-800, #1a2535);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 18px;
  line-height: 22px;

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue);
    }
  }
  &:active {
    border-color: var(--Kontos-Blue);
  }
`;

const SideIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
`;

interface IProps {
  googleAddCallback: (email: string) => void;
  onVerifyMethodChosen: () => void;
  onDirectMethodChosen: () => void;
}

export const EmailSelector: React.FC<IProps> = ({
  googleAddCallback,
  onVerifyMethodChosen,
  onDirectMethodChosen,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      {tgManager.isTg() ? (
        <>
          <TipsBox>
            <TipsIcon src={tipsIcon} />
            <TipsText>
              {t(
                "Please choose one of the following methods to add your security email address."
              )}
            </TipsText>
          </TipsBox>
          <DividerBox>
            <Divider left={25} right={25} maxWidth={350} />
          </DividerBox>
        </>
      ) : (
        <>
          {/* Google Login Btn */}
          <GoogleLoginBtn
            wrapperStyle={{ width: "100%", maxWidth: "400px" }}
            googleLoginWrapClass={"google-login"}
            loginSuccessFun={googleAddCallback}
          >
            <GoogleButton>
              <GoogleIcon src={googleIcon} />
              <GoogleText>{t("Continue with Google")}</GoogleText>
              <SideIcon src={whiteArrowIcon} />
            </GoogleButton>
          </GoogleLoginBtn>

          <OrLineBox>
            <OrLine />
            <OrTextBox>{t("OR")}</OrTextBox>
          </OrLineBox>
        </>
      )}

      <VerificationButton onClick={onVerifyMethodChosen}>
        <span>{t("Secure Email Verification")}</span>
        <SideIcon src={whiteArrowIcon} />
      </VerificationButton>

      <DirectButton onClick={onDirectMethodChosen}>
        <span>{t("Direct Email Entry")}</span>
        <SideIcon src={blackAddIcon} />
      </DirectButton>
    </Container>
  );
};
