interface Props {
  className?: string;
}

const CreditPath: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="123"
        viewBox="0 0 150 123"
        fill="none"
        transform="scale(-1, 1)"
      >
        <path
          d="M127.053 112C132.32 104.654 135.989 96.2434 137.77 87.3074C140.24 74.9216 138.972 62.0833 134.128 50.4161C129.284 38.7489 121.081 28.7768 110.556 21.7608C100.032 14.7448 87.658 11 75 11C62.342 11 49.9683 14.7448 39.4435 21.7608C28.9188 28.7768 20.7157 38.7489 15.8717 50.4161C11.0277 62.0833 9.76032 74.9216 12.2298 87.3074C14.0114 96.2434 17.6804 104.654 22.9474 112"
          stroke="#EDEEF1"
          fillOpacity="0"
          strokeWidth="21"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          id="credit-path"
          d="M127.053 112C132.32 104.654 135.989 96.2434 137.77 87.3074C140.24 74.9216 138.972 62.0833 134.128 50.4161C129.284 38.7489 121.081 28.7768 110.556 21.7608C100.032 14.7448 87.658 11 75 11C62.342 11 49.9683 14.7448 39.4435 21.7608C28.9188 28.7768 20.7157 38.7489 15.8717 50.4161C11.0277 62.0833 9.76032 74.9216 12.2298 87.3074C14.0114 96.2434 17.6804 104.654 22.9474 112"
          // stroke="url(#gradient)"
          stroke="#413DEC"
          fillOpacity="0"
          strokeWidth="21"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* <defs>
          <radialGradient
            id="gradient"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(75 37.9999) rotate(90) scale(85.5 84.1272)"
          >
            <stop offset="0.134394" stop-color="#413DEC" />
            <stop offset="0.67666" stop-color="#E47AFF" />
            <stop offset="0.857623" stop-color="#FF19BF" />
          </radialGradient>
        </defs>
        <foreignObject
          width="100%"
          height="100%"
          style={{ overflow: "hidden" }}
          clip-path="url(#credit-path)"
        >
          <div
            style={{
              width: "150px",
              height: "123px",
              backgroundImage: "conic-gradient(#413DEC, #E47AFF, #FF19BF)",
            }}
          ></div>
        </foreignObject> */}
      </svg>
    </div>
  );
};

export default CreditPath;
