import { account } from "@zkkontos/kontos-sdk/src/api";
import { SmartAccount } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/aa";
import { rootStore } from "..";

export const getCurrentAndFutureGuardianNames = async (
  _name: string
): Promise<{ currentNames: string[]; futureNames: string[] }> => {
  const resp = await account({ account: _name });
  return {
    currentNames: resp.guardianNames || [],
    futureNames: resp.futureGuardianNames || [],
  };
};

export const pollSmartAccount = async (
  isRevert: boolean,
  maxAttempts: number = 10,
  interval: number = 1000
): Promise<SmartAccount | null> => {
  let attempts = 0;
  const queryCli = await rootStore.userStore.getKontosQueryCli();

  const fetchData = async (): Promise<SmartAccount | null> => {
    try {
      console.log("attempts", attempts);
      const resp = await queryCli.getSmartAccountByName(
        rootStore.userStore.accountInfo?.name!
      );
      return resp;
    } catch (error) {
      console.error("refresh smart account failed:", error);
      return null;
    }
  };

  const poll = async (
    resolve: (arg0: SmartAccount | null) => void,
    reject: any
  ): Promise<void> => {
    attempts++;
    const result = await fetchData();

    if (
      (!isRevert && result && result.updateGuardiansDeadline?.toNumber() > 0) ||
      (isRevert && result && result.updateGuardiansDeadline?.toNumber() === 0)
    ) {
      resolve(result);
    } else if (attempts >= maxAttempts) {
      resolve(result);
    } else {
      setTimeout(() => poll(resolve, reject), interval);
    }
  };

  return new Promise(poll);
};
