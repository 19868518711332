import styled from "styled-components";
import React from "react";
import tipsIcon from "src/assets/icons/tips.svg";
import { t } from "i18next";
import { Button } from "src/components/button/Button";
import { copy } from "src/utils/helper";
import toast from "src/components/toast/Toast";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  .ico {
    width: 28px;
    height: 16px;
    flex-shrink: 0;
    margin-bottom: 16px;
  }

  .description {
    color: var(--Deep-800, #1a2535);
    text-align: center;

    /* Main text */
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-bottom: 15px;
    span {
      color: var(--Kontos-Blue, #413dec);
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }
  }
`;

const WrapperCopy = styled.div`
  background: var(--Deep-25, #f5f5f6);
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 16px;
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);
  overflow: hidden;
  .text {
    -webkit-box-orient: vertical;
    color: var(--Deep-400, #80868f);
    padding-right: 10px;

    /* H6 */
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    .k {
      color: var(--Deep-800, #1a2535);
      /* H6 */
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-right: 4px;
    }
    .v {
      width: 100px;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      //display: block;
    }
  }
  .btn {
    width: 79px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    span {
      color: #fff !important;
      font-size: 14px;
    }
  }
`;

type IProps = {
  mailMap: {
    [key: string]: string;
  };
};

const copyDataArray = [
  {
    name: "Email",
    key: "mailto",
  },
  {
    name: "Title",
    key: "subject",
  },
  {
    name: "Content",
    key: "body",
  },
];

const CopyRecoveryEmailInfo: React.FC<IProps> = ({ mailMap }) => {
  return (
    <Wrapper>
      <img className={"ico"} src={tipsIcon} alt="tipsIcon" />
      <div className={"description"}>
        You can also manually copy and send the following email with your{" "}
        <span>security email</span> address.（Confirm within 5 minutes）
      </div>
      {copyDataArray.map((item, index) => (
        <WrapperCopy key={index}>
          <div className={"text"}>
            <div className={"k"}>{item.name}:</div>
            <div className={"v"}>{mailMap[item.key]}</div>
          </div>
          <div className={"btn"}>
            <Button
              onClick={() => {
                copy(mailMap[item.key]);
                toast({
                  text: "copy success",
                  type: "success",
                });
              }}
              text={t("Copy")}
              fullWidth={true}
              height={"32px"}
              disabled={false}
            />
          </div>
        </WrapperCopy>
      ))}
    </Wrapper>
  );
};

export default CopyRecoveryEmailInfo;
