import ImageWithFallback from "src/components/images/ImageWithFallback";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dapp } from "src/type/dapp";
import styled from "styled-components";
import defaultDappIcon from "src/assets/icons/dapp/default-dapp.svg";
import { useDebounce } from "react-use";
import Skeleton from "react-loading-skeleton";
import { calculateItemsPerRow, openUrl } from "src/utils/helper";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";

const DappSize = 80;
const DappGap = 5;
const DappLineGap = 10;
const DEBOUNCE_TIME = 500;
const RowNum = 2;

const Container = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
`;

const HeaderLeft = styled.div`
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: 16px;
  line-height: 20px;
`;

const HeaderRight = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: var(--Kontos-Blue, #413dec);
    }
  }
`;

const DappWrapper = styled.div<{ $loading?: boolean }>`
  width: 100%;
  margin-top: 16px;
  ${(props) =>
    !props.$loading &&
    `
      display: flex;
      flex-wrap: wrap;
    `}
  align-items: center;
  justify-content: space-between;
  gap: ${DappGap}px;
  overflow: hidden;
  margin-bottom: -${DappLineGap}px;
`;

const DappItem = styled.div`
  width: ${DappSize}px;
  height: ${DappSize}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${DappLineGap}px;
`;

const DappIcon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 15px;
  background-color: var(--Deep-50, #edeef1);
  user-select: none;
  cursor: pointer;
`;

const DappTitle = styled.div`
  margin-top: 4px;
  height: 28px;
  width: 80px;
  cursor: pointer;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;

  color: var(--Deep-800, #1a2535);
  text-align: center;
  font-family: "HarmonyOS Sans SC";
  font-size: 11px;
  font-weight: 400;
  vertical-align: top;
  @media (hover: hover) {
    &:hover {
      color: var(--Kontos-Blue, #413dec);
    }
  }
`;

export interface DappPlaceholder extends Dapp {
  isFake?: boolean;
}

interface IProps {
  dapps: Dapp[];
  onViewAll: () => void;
}

export const DiscoverExploreTrending: React.FC<IProps> = observer(
  ({ dapps, onViewAll }) => {
    const { t } = useTranslation();
    const { discoverStore } = useStores();
    const ref = useRef<HTMLDivElement>(null);
    const [displayDapps, setDisplayDapps] = useState<DappPlaceholder[]>(
      discoverStore.exploreTrendingDisplayItems.length > 0
        ? discoverStore.exploreTrendingDisplayItems
        : []
    );

    const appOnClick = useCallback((dapp: Dapp) => {
      openUrl(dapp.appUrl, null);
    }, []);

    const [, cancel] = useDebounce(
      () => {
        const calculateDappDisplayCount = () => {
          const rowWidth = ref.current?.offsetWidth;
          if (rowWidth) {
            const itemsNumPerRow = calculateItemsPerRow(
              DappSize,
              DappGap,
              rowWidth
            );
            const expectItemNum = itemsNumPerRow * RowNum;
            const items = dapps.slice(0, expectItemNum) as DappPlaceholder[];
            const difference = expectItemNum - items.length;
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                items.push({
                  isFake: true,
                  id: 10000 - i,
                  category: 0,
                  appType: 0,
                  chainIndex: "",
                  name: "",
                  desc: "",
                  imageUrl: "",
                  appUrl: "",
                  commentType: 0,
                  verified: 0,
                });
              }
            }

            setDisplayDapps(items);
            discoverStore.setExploreTrendingDisplayItems(items);
          }
        };

        if (displayDapps.length === 0) calculateDappDisplayCount();

        window.addEventListener("resize", calculateDappDisplayCount);

        return () =>
          window.removeEventListener("resize", calculateDappDisplayCount);
      },
      DEBOUNCE_TIME,
      [dapps]
    );

    useEffect(() => {
      return () => cancel();
    }, [cancel]);

    return (
      <Container>
        <Header>
          <HeaderLeft>{t("🔥Trending")}</HeaderLeft>
          <HeaderRight onClick={onViewAll}>{t("View all")}</HeaderRight>
        </Header>
        <DappWrapper ref={ref} $loading={displayDapps.length === 0}>
          {displayDapps.length === 0 ? (
            <Skeleton
              style={{
                flex: "1",
                height: "170px",
                width: "100%",
                borderRadius: "8px",
              }}
            />
          ) : (
            displayDapps.map((dapp) => (
              <DappItem key={dapp.id}>
                {dapp.isFake ? (
                  <></>
                ) : (
                  <>
                    <ImageWithFallback
                      src={dapp.imageUrl || defaultDappIcon}
                      alt="App Icon"
                      StyledImg={DappIcon}
                      fallbackSrc={defaultDappIcon}
                      onClick={() => appOnClick(dapp)}
                    />
                    <DappTitle onClick={() => appOnClick(dapp)}>
                      {dapp.name}
                    </DappTitle>
                  </>
                )}
              </DappItem>
            ))
          )}
        </DappWrapper>
      </Container>
    );
  }
);
