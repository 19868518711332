import React, { useState, useEffect, ImgHTMLAttributes } from "react";

interface ImageWithFallbackProps<T>
  extends ImgHTMLAttributes<HTMLImageElement> {
  src: string | undefined;
  fallbackSrc?: string;
  StyledImg?: React.ComponentType<T & ImgHTMLAttributes<HTMLImageElement>>; // Define StyledImg as the standard property that accepts generic T and img
  extraProps?: T; // Define extraProps as generic T for passing additional properties to StyledImg
}

const ImageWithFallback = <T,>({
  src,
  fallbackSrc = "",
  alt,
  className,
  StyledImg,
  extraProps,
  ...props
}: ImageWithFallbackProps<T>) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleError = () => {
    if (imgSrc !== fallbackSrc) {
      setImgSrc(fallbackSrc);
    }
  };

  const ImgComponent = StyledImg || "img";

  return (
    <ImgComponent
      className={className}
      src={imgSrc || fallbackSrc}
      alt={alt}
      onError={handleError}
      {...props}
      {...(extraProps as T)} // Using type assertions to pass extraProps as a property of StyledImg
    />
  );
};

export default ImageWithFallback;
