/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import styled from "styled-components";
import React from "react";
import kontosAvatarGrey from "src/assets/images/kontos-avatar-gray.svg";
import kontosAvatar from "src/assets/images/kontos-avatar1.svg";
import kontosAvatarRed from "src/assets/images/kontos-avatar-red.svg";
import loading from "src/assets/images/loading2.svg";
import warning from "src/assets/images/warning.svg";
import success from "src/assets/images/success.svg";
import kontosEmailGrey from "src/assets/icons/kontos-email-grey.svg";

const Wrapper = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  position: relative;

  img {
    position: absolute;
  }

  .img0 {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .img1 {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    left: 31px;
    top: 26px;
  }

  .img1Loading {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const inputStatusMap = {
  blur: "blur",
  active: "active",
  loading: "loading",
  error: "error",
  success: "success",
};

type IProps = {
  status: keyof typeof inputStatusMap;
  type?: "account" | "email";
};

const RegisterStatusIcon: React.FC<IProps> = ({ status, type = "account" }) => {
  const Img0 = () => {
    switch (type) {
      case "email":
        return <img className={"img0"} src={kontosEmailGrey} />;
      case "account":
        switch (status) {
          case "blur":
            return <img className={"img0"} src={kontosAvatarGrey} />;
          case "active":
            return <img className={"img0"} src={kontosAvatar} />;
          case "loading":
            return <img className={"img0"} src={kontosAvatar} />;
          case "error":
            return <img className={"img0"} src={kontosAvatarRed} />;
          case "success":
            return <img className={"img0"} src={kontosAvatar} />;
          default:
            return <img className={"img0"} src={kontosAvatarGrey} />;
        }
    }
  };

  const Img1 = () => {
    switch (status) {
      case "loading":
        return <img className={"img1 img1Loading"} src={loading} />;
      case "error":
        return <img className={"img1"} src={warning} />;
      case "success":
        return <img className={"img1"} src={success} />;

      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <Img0 />
      <Img1 />
    </Wrapper>
  );
};

export default observer(RegisterStatusIcon);
