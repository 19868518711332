import { theme } from "src/App";
import styled from "styled-components";

const StyledSvg = styled.svg`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      .path-1 {
        stroke: ${(props) => props.theme.colors.__kontos_blue};
      }

      .path-2 {
        fill: ${(props) => props.theme.colors.__kontos_blue};
      }
    }
  }
`;

interface IProps {
  className?: string;
  onClick?: () => void;
}

const EditIcon: React.FC<IProps> = ({ className, onClick }) => {
  return (
    <StyledSvg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        className="path-1"
        d="M10.7681 12.0812L10.905 12.0482L11.0045 11.9487L18.3161 4.63711C18.9318 4.02144 18.9318 3.02322 18.3161 2.40755C17.7004 1.79187 16.7022 1.79187 16.0865 2.40755L8.77496 9.71911L8.67543 9.81864L8.64244 9.95547L8.15884 11.9613L7.96722 12.7561L8.76204 12.5646L10.7681 12.0812Z"
        stroke={theme.colors.__deep_400}
      />
      <path
        className="path-2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1953 2.96631C12.1953 2.69017 11.9715 2.46631 11.6953 2.46631H5C3.34315 2.46631 2 3.80946 2 5.46631V15.9108C2 17.5677 3.34315 18.9108 5 18.9108H14.4165C16.0734 18.9108 17.4165 17.5677 17.4165 15.9108V9.40623C17.4165 9.13009 17.1926 8.90623 16.9165 8.90623C16.6404 8.90623 16.4165 9.13009 16.4165 9.40623V15.9108C16.4165 17.0154 15.5211 17.9108 14.4165 17.9108H5C3.89543 17.9108 3 17.0154 3 15.9108V5.46631C3 4.36174 3.89543 3.46631 5 3.46631H11.6953C11.9715 3.46631 12.1953 3.24245 12.1953 2.96631Z"
        fill={theme.colors.__deep_400}
      />
    </StyledSvg>
  );
};

export default EditIcon;
