import styled from "styled-components";
import favoriteIco from "src/assets/icons/dapp/favorite.svg";
import notFavoriteIco from "src/assets/icons/dapp/not-favorite.svg";
import favoriteHoverIco from "src/assets/icons/dapp/favorite-hover.svg";

const Container = styled.div<{ $isFavorite: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: ${(props) =>
    props.$isFavorite ? `url(${favoriteIco})` : `url(${notFavoriteIco})`};
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center center;

  @media (hover: hover) {
    &:hover {
      background-image: ${(props) =>
        props.$isFavorite ? `url(${favoriteIco})` : `url(${favoriteHoverIco})`};
    }
  }
  &:active {
    background-image: ${(props) =>
      props.$isFavorite ? `url(${favoriteIco})` : `url(${favoriteHoverIco})`};
  }
`;

interface IProps {
  isFavorite: boolean;
  onClick: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
}

const FavoriteButton: React.FC<IProps> = ({ isFavorite, onClick, style }) => {
  return (
    <Container
      style={style}
      $isFavorite={isFavorite}
      onClick={(e) => onClick(e)}
    />
  );
};

export default FavoriteButton;
