import styled from "styled-components";
import { FtAsset } from "@/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";
import {
  DEFAULT_DECIMAL,
  DEFAULT_GAS_LIMIT_FOR_KONTOS_OTHERS_TRANSFER,
  DEFAULT_PRECISION,
  KONTOS_CHAIN_INDEX,
  KONTOS_JSON_RPC,
  KONTOS_NATIVE_ADDRESS,
  TEMP_KEY,
} from "src/config";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CircleIconPair from "src/components/icons/CircleIconPair";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import { t } from "i18next";
import { KontosButton } from "src/components/button/KontosButton";
import NumericInputV2 from "src/components/input/NumericInputV2";
import toast from "src/components/toast/Toast";
import {
  ContractInteraction,
  SendKontosNativeProps,
  SendKontosOthersProps,
  SendOthersProps,
} from "../contract-interaction/ContractInteraction";
import { transferToSendFtAssetTypeToInteractionType } from "../contract-interaction/types";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { ethers } from "ethers";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import ERC20 from "src/assets/abis/ERC20.json";
import { prepareRequest, signTransaction } from "../transfer/kontosEthers";
import { loadingStore } from "src/store/loadingStore";
import { getChainByChainIndex, isSameFtAsset } from "src/utils/zkkontosHelper";
import { ChainConfig } from "@/type/zkkontos";
import { fetchSendCallTaskDataV3 } from "src/service/trade-service";
import { useStores } from "src/hooks/useStore";
import Header from "src/components/common/header";
import walletIco from "src/assets/icons/wallet.svg";
import triangleIco from "src/assets/icons/triangle0.svg";
import { SelectReceiveModal } from "src/pages/send-h5/SelectReceiveModal";
import successIco from "src/components/toast/icons/success.svg";
import errorIco from "src/assets/icons/error1.svg";
import { AddressCheckResult } from "src/components/input/AddressInputV2";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";

const Container = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  margin: 0 16px 16px 16px;
  box-sizing: border-box;

  .border-blue {
    border: 1px solid var(--Kontos-Blue, #413dec);
  }

  .all-blue {
    border: 1px solid var(--Kontos-Blue, #413dec);
    background: var(--Kontos-Blue, #413dec);
  }
`;

const InputPlaceholder = styled.div`
  height: 56px;
  border-radius: 8px;
  background: var(--White, #fff);
  cursor: pointer;
  position: relative;
  font-family: "HarmonyOS Sans SC";
  font-size: 16px;
  font-weight: 400;
  line-height: 56px;
  padding-left: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 14px;
  }
  &.success {
    border: 1px solid var(--Success, #10ce5c);
    padding-left: 10px;
  }
  &.fail {
    border: 1px solid var(--error-notice, #ff1e2b);
    padding-left: 10px;
  }
  &:hover {
    border: 1px solid var(--Kontos-Blue, #413dec);
  }
  //.mask{
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  z-index: 2;
  //}
`;

const SendInput = styled.div`
  border: 1px solid var(--Deep-50, #edeef1);
  padding: 10px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  &:hover {
    border: 1px solid var(--Kontos-Blue, #413dec);
  }
  .sendAssets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .l {
      overflow: hidden;
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }

    .r {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;

      .amount {
        color: var(--Deep-400, #80868f);
        margin: 0 4px;
      }

      .max {
        color: var(--Kontos-Blue, #413dec);
        cursor: pointer;
      }
    }
  }
  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .l {
      display: flex;
      align-items: center;
      padding: 10px 8px;
      border-radius: 8px;
      cursor: pointer;
      .triangle {
        margin-right: 8px;
        transform: rotate(180deg);
      }
      &:hover {
        background: var(--Deep-25, #f5f5f6);
      }
      .inputSymbol {
        margin-left: 14px;
        .symbol {
          color: var(--Deep-800, #1a2535);
          font-family: "HarmonyOS Sans Bold";
          font-size: 14px;
          margin-bottom: 2px;
        }

        .chain-symbol {
          color: var(--Deep-400, #80868f);
          font-family: "HarmonyOS Sans SC";
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .r {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .usd-price {
        margin-top: 6px;
        color: var(--Deep-400, #80868f);
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;

const AddressTitle = styled.div`
  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 18px;
  font-weight: 700;

  margin: 0 0 8px 8px;
`;

const BlankFilledArea = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const StyledKontosButton = styled(KontosButton)`
  width: calc(100% - 40px);
  position: fixed;
  bottom: calc(16px + var(--navi-height));
`;

interface StepSendProps {
  toSendFtAsset?: FtAsset;
  displayPrecision?: number;
  maxAvailable?: KontosNumber;
  onBack?: () => void;
  onSuccess?: () => void;
}

export enum ToSendFtAssetType {
  KontosNative,
  KontosOthers,
  Others,
}

const StepSend: React.FC<StepSendProps> = ({
  toSendFtAsset,
  displayPrecision,
  maxAvailable,
  onBack,
  onSuccess,
}) => {
  const { userStore, sheetStore, chainStore, tradeStore } = useStores();
  const maxValue: KontosNumber = maxAvailable
    ? maxAvailable
    : new KontosNumber(toSendFtAsset?.balance, DEFAULT_DECIMAL);
  const [toSendFtAssetValue, setToSendFtAssetValue] = useState<KontosNumber>();
  const [toSendFtAssetType, setToSendFtAssetType] =
    useState<ToSendFtAssetType>();
  const [isAmountInputFocused, setIsAmountInputFocused] =
    useState<boolean>(false);
  const [isAddressInputFocused, setIsAddressInputFocused] =
    useState<boolean>(false);

  const [receiver, setReceiver] = useState<string>();

  const [showInteractionModal, setShowInteractionModal] = useState(false);
  const [showSelectReceiverModal, setShowSelectReceiverModal] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [innerModalOpen, setInnerModalOpen] = useState(false);
  const [isReceiveAddressValid, setIsReceiveAddressValid] =
    useState<boolean>(false);

  const [sendKontosNativeProps, setSendKontosNativeProps] =
    useState<SendKontosNativeProps>();
  const [sendKontosOthersProps, setSendKontosOthersProps] =
    useState<SendKontosOthersProps>();
  const [sendOthersProps, setSendOthersProps] = useState<SendOthersProps>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const title = useMemo(() => {
    if (isAddressInputFocused) {
      return t("To Address");
    }
    return t("Send");
  }, [isAddressInputFocused]);
  const [okText, setOkText] = useState<string[] | undefined>(undefined);
  const [errText, setErrText] = useState<string[] | undefined>(undefined);
  const [initPage, setInitPage] = useState<boolean>(true);
  const showValidationError = useMemo(() => {
    return (
      !initPage &&
      ((receiver && !isReceiveAddressValid) || (errText && errText?.length > 0))
    );
  }, [receiver, isReceiveAddressValid, errText, initPage]);
  const amountInputRef = useRef<{ focus: () => void }>(null);

  const onInnerModalChange = (isOpen: boolean) => {
    setInnerModalOpen(isOpen);
  };

  useMouseDownOutside({
    ref: wrapperRef,
    callback: () => {
      setShowInteractionModal(false);
      setShowSelectReceiverModal(false);
    },
    shouldClose: !innerModalOpen,
  });

  useEffect(() => {
    amountInputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (tradeStore.fromAi && receiver === "") {
      if (
        !toSendFtAsset ||
        !userStore.userHoldings.some((item) =>
          isSameFtAsset(item, toSendFtAsset)
        )
      ) {
        toast({
          text: t("It seems that you do not hold the asset"),
          type: "warning",
        });
        return;
      }
      amountInputRef.current?.focus();
    }

    return () => {
      tradeStore.resetAiFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showInteractionModal) {
      sheetStore.openOuterSend();
    } else {
      sheetStore.closeOuterSend();
    }
  }, [sheetStore, showInteractionModal]);

  useEffect(() => {
    if (toSendFtAsset?.chainIndex === KONTOS_CHAIN_INDEX) {
      if (toSendFtAsset?.address?.toLowerCase() === KONTOS_NATIVE_ADDRESS) {
        setToSendFtAssetType(ToSendFtAssetType.KontosNative);
      } else if (toSendFtAsset?.address !== undefined) {
        setToSendFtAssetType(ToSendFtAssetType.KontosOthers);
      }
    } else if (toSendFtAsset?.chainIndex !== undefined) {
      setToSendFtAssetType(ToSendFtAssetType.Others);
    } else {
      setToSendFtAssetType(undefined);
    }
  }, [toSendFtAsset]);

  const handleKontosNativeSend = async () => {
    setSendKontosNativeProps({
      toSendFtAsset: toSendFtAsset!,
      amount: toSendFtAssetValue!,
    });

    setShowInteractionModal(true);
  };

  const handleKontosOthersSend = async () => {
    loadingStore.showLoading();
    try {
      const provider = new ethers.providers.JsonRpcProvider(KONTOS_JSON_RPC);
      const tempPrivateKey = TEMP_KEY;
      const wallet = new ethers.Wallet(tempPrivateKey, provider);
      const evm_address = KontosQueryCli.nameAddress(
        userStore.accountInfo!.name
      );

      const token = new ethers.Contract(toSendFtAsset!.address, ERC20, wallet);

      token.signer.signTransaction = signTransaction;
      const oldPrepareRequest = provider.prepareRequest;
      provider.prepareRequest = prepareRequest(oldPrepareRequest, evm_address);

      const amount = toSendFtAssetValue!;

      const gasPrice = await provider.getGasPrice();
      const nonce = await provider.getTransactionCount(evm_address);

      setSendKontosOthersProps({
        toSendFtAsset: toSendFtAsset!,
        amount: amount,
        gasPrice: gasPrice,
        gasLimit: DEFAULT_GAS_LIMIT_FOR_KONTOS_OTHERS_TRANSFER,
        nonce: nonce,
        contract: token,
      });

      setShowInteractionModal(true);
    } catch (e) {
      console.log(e);
      const errorMessage =
        e instanceof Error
          ? e.message
          : "An unknown error occurred when preparing your transfer";
      toast({ type: "error", text: errorMessage });
    } finally {
      loadingStore.hideLoading();
    }
  };

  const handleOthersSend = async () => {
    loadingStore.showLoading();
    try {
      // const [transferTaskDataResult] = await Promise.all([
      //   getTransferTaskData(
      //     userStore.accountInfo?.name!,
      //     userStore.accountInfo?.name!,
      //     toSendFtAsset?.chainIndex!,
      //     receiver?.replace(".os", "")!,
      //     toSendFtAsset?.address!,
      //     toSendFtAssetValue!
      //   ),
      // ]);

      const transferTaskDataResult = await fetchSendCallTaskDataV3(
        userStore.accountInfo?.name!,
        toSendFtAsset?.chainIndex!,
        receiver?.replace(".os", "")!,
        toSendFtAsset?.address!,
        toSendFtAssetValue!
      );

      if (
        !transferTaskDataResult?.newTasksByPaymentPlans ||
        transferTaskDataResult.newTasksByPaymentPlans.length === 0
      ) {
        throw new Error("Insufficient Balance");
      }

      if (transferTaskDataResult?.isChainUnderSyncing === true) {
        toast({
          type: "warning",
          text: "Your previous transaction is still syncing on the blockchain. Please try again later.",
        });
        return;
      }

      setSendOthersProps({
        toSendFtAsset: toSendFtAsset!,
        amount: toSendFtAssetValue!,
        taskData: transferTaskDataResult,
        chain: getChainByChainIndex(
          chainStore.chains,
          toSendFtAsset?.chainIndex!
        ) as ChainConfig,
      });

      setShowInteractionModal(true);
    } catch (e) {
      console.log(e);
      const errorMessage =
        e instanceof Error
          ? e.message
          : "An unknown error occurred when preparing your transfer";
      toast({ type: "error", text: errorMessage });
    } finally {
      loadingStore.hideLoading();
    }
  };

  const handleOnClickNext = () => {
    if (!toSendFtAssetValue?.gt(0)) {
      amountInputRef.current?.focus();
      toast({ type: "info", text: t("Please input amount") });
      return;
    }

    if (
      !receiver ||
      !isReceiveAddressValid ||
      (errText && errText?.length > 0)
    ) {
      setShowSelectReceiverModal(true);
      return;
    }

    setSelectedIndex(0);
    if (!toSendFtAssetValue || !toSendFtAssetValue.gt(0)) {
      toast({
        text: t("Please input amount"),
        type: "info",
      });
      return;
    }
    if (receiver === undefined) {
      toast({
        text: t("Please input a valid address"),
        type: "info",
      });
      return;
    }
    if (!userStore.accountInfo?.name) {
      toast({
        text: "Account info missing",
        type: "info",
      });
      return;
    }
    switch (toSendFtAssetType) {
      case ToSendFtAssetType.KontosNative:
        setShowInteractionModal(true);
        handleKontosNativeSend();
        break;
      case ToSendFtAssetType.KontosOthers:
        handleKontosOthersSend();
        break;
      case ToSendFtAssetType.Others:
        handleOthersSend();
        break;
      default:
        toast({
          text: "Invalid Asset",
          type: "warning",
        });
        break;
    }
  };

  const handleUserInputFtAssetValueChange = (
    value: KontosNumber,
    forceToSet: boolean = false
  ) => {
    if (value.eq(0) && forceToSet === false) {
      setToSendFtAssetValue(undefined);
    } else {
      setToSendFtAssetValue(value);
    }
  };

  const handleSelectIndex = useCallback((_index: number) => {
    setSelectedIndex(_index);
  }, []);

  const allowEOA = useMemo(() => {
    switch (toSendFtAssetType) {
      case ToSendFtAssetType.KontosNative:
        return false;
      case ToSendFtAssetType.KontosOthers:
        return false;
      case ToSendFtAssetType.Others:
        return true;
      default:
        return true;
    }
  }, [toSendFtAssetType]);

  const requireAA = useMemo(() => {
    switch (toSendFtAssetType) {
      case ToSendFtAssetType.KontosNative:
      case ToSendFtAssetType.KontosOthers:
      case ToSendFtAssetType.Others:
      default:
        return false;
    }
  }, [toSendFtAssetType]);

  return (
    <>
      <Header
        title={title}
        padding="0 20px 20px 20px"
        enableBack
        callBack={() => {
          if (isAddressInputFocused) {
            setIsAddressInputFocused(false);
          } else {
            onBack?.();
          }
        }}
        rightBtnText={isAddressInputFocused ? "Done" : ""}
        rightBtnCallBack={() => {}}
      />
      <Container ref={wrapperRef}>
        {/* input box */}
        {!isAddressInputFocused && (
          <SendInput
            className={` ${isAmountInputFocused ? "border-blue" : ""}`}
          >
            <div className={"sendAssets"}>
              <div className={"l"}>{t("Send Assets")}:</div>
              <div className={"r"}>
                <img src={walletIco} alt="" />
                <span className={"amount"}>
                  {maxValue.toFormat(displayPrecision)}
                </span>
                <span
                  className={"max"}
                  onClick={() => setToSendFtAssetValue(maxValue)}
                >
                  {t("Max")}
                </span>
              </div>
            </div>
            <div className={"inputWrap"}>
              <div
                className={"l"}
                onClick={() => {
                  if (onBack) {
                    onBack();
                    setToSendFtAssetValue(undefined);
                  }
                }}
              >
                <img className={"triangle"} src={triangleIco} alt="" />
                <CircleIconPair
                  mainIcon={toSendFtAsset?.imageUrl}
                  mainIconFallbackSrc={DefaultTokenSvg}
                  subIcon={toSendFtAsset?.chainGreyImageUrl}
                  subIconFallbackSrc={DefaultChainSvg}
                  mainWidth={32}
                  mainHeight={32}
                  subWidth={16}
                  subHeight={16}
                  totalWidth={40}
                  totalHeight={32}
                />
                <div className={"inputSymbol"}>
                  <div className={"symbol"}>{toSendFtAsset?.symbol}</div>
                  <div className={"chain-symbol"}>
                    {toSendFtAsset?.chainSymbol}
                  </div>
                </div>
              </div>
              <div className={"r"}>
                <NumericInputV2
                  ref={amountInputRef}
                  alignRight={true}
                  unit={""}
                  precision={7}
                  maxValue={maxValue.toNumber()}
                  placeholder={`0.00`}
                  onChange={handleUserInputFtAssetValueChange}
                  value={toSendFtAssetValue?.round(DEFAULT_PRECISION)}
                  onBlur={() => {
                    setIsAmountInputFocused(false);
                    handleUserInputFtAssetValueChange(
                      toSendFtAssetValue || new KontosNumber(0),
                      true
                    );
                  }}
                  onFocus={() => {
                    setIsAmountInputFocused(true);
                  }}
                  fontSize="18px"
                />
                <div className="usd-price">
                  {toSendFtAsset?.usdPrice &&
                  toSendFtAssetValue &&
                  toSendFtAssetValue.gt(0)
                    ? "≈ " +
                      toSendFtAssetValue
                        ?.multiply(
                          toSendFtAsset?.usdPrice || 0,
                          DEFAULT_DECIMAL
                        )
                        .toFixed(displayPrecision, true) +
                      " USD"
                    : "0.00"}
                </div>
              </div>
            </div>
          </SendInput>
        )}

        {/* Address */}
        {!isAddressInputFocused && (
          <AddressTitle className="to-address">{t("To Address")}</AddressTitle>
        )}
        <InputPlaceholder
          className={`${receiver && isReceiveAddressValid ? "success" : ""} ${
            showValidationError ? "fail" : ""
          }`}
          onClick={() => {
            setShowSelectReceiverModal(true);
            setTimeout(() => {
              setInitPage(false);
            }, 500);
            // sheetStore.openSheet(SheetView.QrReader);
          }}
          style={{
            color: receiver
              ? "var(--Deep-800, #1A2535)"
              : "var(--Deep-400, #80868F)",
          }}
        >
          {receiver && isReceiveAddressValid && <img src={successIco} alt="" />}
          {showValidationError && <img src={errorIco} alt="" />}
          {receiver ? receiver : t("Enter recipient's address")}
        </InputPlaceholder>
        {!initPage && (
          <AddressCheckResult
            errText={errText}
            okText={okText}
            isFocus={false}
          />
        )}

        {/* Blank filled area */}
        <BlankFilledArea />

        {/* Next */}
        {!isAddressInputFocused && (
          <StyledKontosButton
            // disabled={
            //   // !toSendFtAssetValue ||
            //   // !toSendFtAssetValue.gt(0) ||
            //   // !receiver ||
            //   // !isReceiveAddressValid ||
            //   // (errText && errText?.length > 0)
            // }
            onClick={handleOnClickNext}
          >
            {t("Next")}
          </StyledKontosButton>
        )}

        {/* select receiver */}
        <BottomSheet
          isOpen={showSelectReceiverModal}
          onClose={() => setShowSelectReceiverModal(false)}
          mountPoint={domNode}
        >
          <SelectReceiveModal
            setShowSelectTokenModal={setShowSelectReceiverModal}
            setReceiver={setReceiver}
            allowEOA={allowEOA}
            requireAA={requireAA}
            toSendFtAsset={toSendFtAsset}
            receiver={receiver}
            setIsReceiveAddressValid={setIsReceiveAddressValid}
            onOkTextChange={setOkText}
            onErrTextChange={setErrText}
          />
        </BottomSheet>

        {/* Three send methods */}
        <BottomSheet
          isOpen={showInteractionModal}
          onClose={() => setShowInteractionModal(false)}
          mountPoint={domNode}
        >
          <ContractInteraction
            interactionType={transferToSendFtAssetTypeToInteractionType(
              toSendFtAssetType!
            )}
            target={
              (ethers.utils.isAddress(receiver || "")
                ? receiver
                : receiver?.replaceAll(".os", "") + ".os") || ""
            }
            onCancel={() => setShowInteractionModal(false)}
            onSuccess={() => {
              setShowInteractionModal(false);
              onSuccess?.();
            }}
            onInnerIndexChange={handleSelectIndex}
            wallet={userStore.accountInfo!.name + ".os"}
            onInnerModalChange={onInnerModalChange}
            sendKontosNativeProps={sendKontosNativeProps}
            sendKontosOthersProps={sendKontosOthersProps}
            sendOthersProps={sendOthersProps}
            selectedTaskDataIndex={selectedIndex}
            displayPrecision={displayPrecision}
          />
        </BottomSheet>
      </Container>
    </>
  );
};

export default StepSend;
