import React from 'react';
import styled from 'styled-components';
import CSS from 'csstype';

const Wrapper = styled.div`
 width: auto;
 height: 1px;
 background: transparent url("/static/dottedLine.svg") repeat-x left center;
 background-size: 311px 1px;
`;

type IProps = {
  styles?: CSS.Properties
}

export function DottedLine({styles = {}}: IProps) {
  return (
    <Wrapper style={styles} />
  );
}

export default DottedLine;
