import styled from "styled-components";
import React from "react";
import addNew from "src/assets/icons/add-new.svg";
import { t } from "i18next";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 99px;
  background: var(--Deep-800, #1a2535);
  margin-top: 16px;
  color: var(--White, #fff);
  /* H7 */
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 5px;
  }
`;

type IProps = {
  text?: string;
  onClick?: () => void;
};

const AddNewBtn: React.FC<IProps> = ({ text, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <img src={addNew} alt="addNew" />
      {text || t("Add New Gmail")}
    </Wrapper>
  );
};

export default AddNewBtn;
