import styled from "styled-components";
import bgImg from "src/assets/images/event/eb/eb-account-bg.png";
import { useState } from "react";
import EbAccountPanelMain from "./EbAccountPanelMain";
import EbAccountPanelCredit from "./EbAccountPanelCredit";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
  margin: 0 24px 12px 24px;
  border-radius: 8px;
  height: 176px;

  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

interface Props {
  name: string | undefined;
  credit: string | undefined;
  creditLevel: string | undefined;
  creditPercentage: number | undefined;
  energyBallCount: string | undefined;
  onCheckBackpack: () => void;
}

enum IStep {
  Main,
  Credit,
}

const EbAccountPanel: React.FC<Props> = ({
  name,
  credit,
  creditLevel,
  creditPercentage,
  energyBallCount,
  onCheckBackpack,
}) => {
  const [step, setStep] = useState<IStep>(IStep.Main);

  return (
    <Container layout>
      {step === IStep.Main && (
        <EbAccountPanelMain
          accountName={name}
          accountCreditLevel={creditLevel}
          ballNum={energyBallCount}
          onCreditClick={() => setStep(IStep.Credit)}
          onBackpackClick={onCheckBackpack}
        />
      )}

      {step === IStep.Credit && (
        <EbAccountPanelCredit
          percentage={creditPercentage}
          accountCredit={credit}
          accountCreditLevel={creditLevel}
          onBack={() => setStep(IStep.Main)}
        />
      )}
    </Container>
  );
};

export default EbAccountPanel;
