import KontosNumber, { trimTrailingZeros } from "src/utils/KontosNumber";
import React, {
  ChangeEvent,
  useState,
  useRef,
  useEffect,
  ReactNode,
  useCallback,
  Ref,
  useImperativeHandle,
  forwardRef,
} from "react";
import styled from "styled-components";
import toast from "src/components/toast/Toast";

const Container = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;

const StyledInput = styled.input<{
  fontSize?: string;
  fontWeight?: string;
  specificHeight?: number;
}>`
  width: 100%;
  padding: 0;
  background-color: transparent;
  caret-color: #413dec;
  outline: none;
  border: none;
  height: ${(props) =>
    props.specificHeight ? `${props.specificHeight}px` : "24px"};
  line-height: ${(props) =>
    props.specificHeight ? `${props.specificHeight}px` : "24px"};

  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans Bold";
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};

  &::placeholder {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans Bold";
    font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  }
`;

const StyledSpan = styled.span<{ $show: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  user-select: none;
  transform: translateY(-50%);
  visibility: ${(props) => (props.$show ? "visible" : "hidden")};
  white-space: nowrap;
`;

interface NumericInputV2Props {
  className?: string;
  precision?: number;
  placeholder?: string;
  unit?: string;
  maxValue?: number;
  minValue?: number;
  value?: KontosNumber | undefined;
  fontSize?: string;
  height?: number;
  attachment?: ReactNode;
  alignRight?: boolean;
  readonly?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: KontosNumber) => void;
  ref?: Ref<{ focus: () => void }>;
}

const getTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) return 0;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

const NumericInputV2: React.FC<NumericInputV2Props> = forwardRef(
  (
    {
      className,
      precision,
      placeholder,
      unit,
      maxValue,
      minValue,
      value,
      fontSize,
      height,
      attachment,
      alignRight,
      readonly,
      onFocus,
      onBlur,
      onChange,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<string>("");
    const inputRef = useRef<HTMLInputElement | null>(null);
    const spanRef = useRef<HTMLSpanElement | null>(null);
    const [showAttachment, setShowAttachment] = useState(false);

    const formattedUnit = unit ? ` ${unit}` : "";

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    const updateSpanPosition = useCallback(() => {
      if (!inputRef.current || !spanRef.current) return;

      const textWidth = getTextWidth(
        inputValue ? inputValue + " " + unit : placeholder || "",
        getComputedStyle(inputRef.current).font
      );
      spanRef.current.style.left = `${textWidth + 10}px`;
      setShowAttachment(true);
    }, [inputValue, placeholder, unit]);

    useEffect(() => {
      if (attachment) {
        updateSpanPosition();
      }
    }, [updateSpanPosition, attachment]);

    const handleCursorPosition = useCallback(() => {
      setTimeout(() => {
        const cursorPosition = inputRef.current!.selectionStart || 0;
        if (cursorPosition > inputValue.length) {
          inputRef.current!.setSelectionRange(
            inputValue.length,
            inputValue.length
          );
        }
      }, 0);
    }, [inputValue.length]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const oldCursorPosition = inputRef.current!.selectionStart || 0; // Get the old cursor position
      const newValue = e.target.value.replace(formattedUnit, "");
      if (newValue === "" || /^\d*\.?\d*$/.test(newValue)) {
        let formattedValue = newValue.replace(",", ".");

        const parts = formattedValue.split(".");

        if (precision !== undefined && formattedValue.includes(".")) {
          if (parts[1].length > precision) {
            // toast({
            //   text: `We currently only support ${precision} decimal places`,
            //   type: "warning",
            // });
          }
          formattedValue = `${parts[0]}.${parts[1].slice(0, precision)}`;
        }

        if (
          minValue !== undefined &&
          parseFloat(formattedValue) !== 0 &&
          parseFloat(formattedValue) < minValue
        ) {
          formattedValue = minValue.toString();
          toast({
            text: `Transaction value must now exceed $${minValue}`,
            type: "warning",
          });
        }

        // Two zeros or more at the beginning are meaningless
        if (formattedValue.startsWith("00")) {
          formattedValue = "0";
        }

        // Check if the value exceeds the maximum value
        if (maxValue !== undefined && parseFloat(formattedValue) > maxValue) {
          formattedValue = maxValue.toString();
        }

        // Only update the state and notify the parent component when the value really changes
        if (formattedValue !== inputValue) {
          setInputValue(formattedValue);

          // Notify the parent component of the change in value (if the value is empty, it will not be notified)
          if (
            onChange &&
            !formattedValue.endsWith(".") &&
            !parts[1]?.endsWith("0")
          ) {
            onChange(new KontosNumber(formattedValue));
          }
        }

        setTimeout(() => {
          // Use the old cursor position directly
          inputRef.current!.setSelectionRange(
            oldCursorPosition,
            oldCursorPosition
          );
        }, 0);
      }
    };

    const handleFocus = () => {
      const cursorPosition = inputRef.current!.selectionStart || 0;
      if (cursorPosition >= inputValue.length) {
        inputRef.current!.setSelectionRange(
          inputValue.length,
          inputValue.length
        );
      }
      handleCursorPosition();
    };

    const handleBlur = () => {
      const realValue = inputValue.replace(formattedUnit, "");
      if (realValue.endsWith(".")) {
        const formattedValue = realValue.slice(0, -1);
        if (parseFloat(formattedValue) === 0) {
          setInputValue("");
        } else {
          setInputValue(formattedValue);
        }
        return;
      }

      if (realValue.includes(".")) {
        const parts = realValue.split(".");
        if (parts?.[1]?.endsWith("0")) {
          const formattedValue = trimTrailingZeros(realValue);
          if (parseFloat(formattedValue) === 0) {
            setInputValue("");
          } else {
            setInputValue(formattedValue);
          }
          return;
        }
      }

      if (parseFloat(realValue) === 0) {
        setInputValue("");
        return;
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        inputRef.current?.blur();
      }
    };

    useEffect(() => {
      if (value && value.gt(0)) {
        setInputValue(value.round(precision).toString());
      } else {
        setInputValue("");
      }
    }, [precision, value]);

    return (
      <Container className={className}>
        <StyledInput
          ref={inputRef}
          style={alignRight ? { textAlign: "right" } : {}}
          type="text"
          inputMode="decimal"
          value={inputValue ? inputValue + formattedUnit : ""}
          onChange={handleChange}
          onFocus={(e) => {
            handleFocus();
            if (onFocus) onFocus();
          }}
          onBlur={(e) => {
            handleBlur();
            if (onBlur) onBlur();
          }}
          onClick={handleCursorPosition}
          onKeyUp={handleCursorPosition}
          onKeyDown={handleKeyDown}
          placeholder={!inputValue ? placeholder : ""}
          fontSize={fontSize}
          readOnly={readonly}
        />

        {/* Attachment */}
        {attachment && (
          <StyledSpan ref={spanRef} $show={showAttachment}>
            {attachment}
          </StyledSpan>
        )}
      </Container>
    );
  }
);

export default NumericInputV2;
