import styled from "styled-components";
import { DiscoverHeader } from "./header/DiscoverHeader";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef } from "react";
import { SheetView } from "src/store/SheetStore";
import {
  ROUTE_DISCOVER_EXPLORE,
  ROUTE_DISCOVER_FAVORITES,
  ROUTE_DISCOVER_SEARCH,
  ROUTE_DISCOVER_TRENDINGS,
  ROUTE_QR_READER,
} from "src/router/router-config";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Tab } from "src/components/tab/KontosTab";
import { DiscoverSearchSheet } from "./search/DiscoverSearchSheet";

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledScrollable = styled(Scrollable)`
  margin-top: 112px;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

export const DiscoverTabs: Tab[] = [
  { route: ROUTE_DISCOVER_EXPLORE, label: t("Explore") },
  { route: ROUTE_DISCOVER_FAVORITES, label: t("Favorites") },
  {
    route: ROUTE_DISCOVER_TRENDINGS,
    label: t("Trending List"),
  },
];

export const Discover: React.FC = observer(() => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const { discoverStore, sheetStore, favStore } = useStores();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isQrReaderOpen = sheetStore.sheetVisibility.get(
    SheetView.QrReader
  ) as boolean;
  const isDiscoverSearchOpen = sheetStore.sheetVisibility.get(
    SheetView.DiscoverSearch
  ) as boolean;

  useEffect(() => {
    favStore.startCronjob();
    discoverStore.getAndSetDappHistory();
    discoverStore.startTrackingInnerWidth();
    discoverStore.startTrackingInnerHeight();

    return () => {
      favStore.stopCronjob();
      discoverStore.stopTrackingInnerWidth();
      discoverStore.stopTrackingInnerHeight();
    };
  }, [discoverStore, favStore]);

  const handleScan = useCallback(() => {
    sheetStore.openSheet(SheetView.QrReader);
    navigate(pathname + "/" + ROUTE_QR_READER);
  }, [navigate, pathname, sheetStore]);

  const handleSearch = useCallback(() => {
    sheetStore.openSheet(SheetView.DiscoverSearch);
    navigate(pathname + "/" + ROUTE_DISCOVER_SEARCH);
  }, [navigate, pathname, sheetStore]);

  useEffect(() => {
    if (!isQrReaderOpen && pathname.includes(ROUTE_QR_READER)) {
      navigate(pathname.replace("/" + ROUTE_QR_READER, ""));
      return;
    } else if (isQrReaderOpen && !pathname.includes(ROUTE_QR_READER)) {
      sheetStore.closeSheetByView(SheetView.QrReader);
      return;
    }

    if (!isDiscoverSearchOpen && pathname.includes(ROUTE_DISCOVER_SEARCH)) {
      navigate(pathname.replace("/" + ROUTE_DISCOVER_SEARCH, ""));
      return;
    } else if (
      isDiscoverSearchOpen &&
      !pathname.includes(ROUTE_DISCOVER_SEARCH)
    ) {
      sheetStore.closeSheetByView(SheetView.DiscoverSearch);
      return;
    }
  }, [isDiscoverSearchOpen, isQrReaderOpen, navigate, pathname, sheetStore]);

  return (
    <Container ref={wrapperRef}>
      <DiscoverHeader
        tabs={DiscoverTabs}
        onFocus={handleSearch}
        onScan={handleScan}
      />

      <StyledScrollable>
        <Outlet />
      </StyledScrollable>

      <DiscoverSearchSheet mountPoint={domNode} />
    </Container>
  );
});
