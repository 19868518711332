import styled from "styled-components";
import warningIco from "src/assets/icons/dapp/wc-warning.svg";
import threatIco from "src/assets/icons/dapp/wc-threat.svg";
import { VerifyStatus } from "src/store/DappConnectStore";
import { useTranslation } from "react-i18next";

const Container = styled.div<{ titleColor: string; bgColor: string }>`
  border-radius: 8px;
  background: ${(props) => props.bgColor};
  padding: 16px 18px;
  display: flex;
  align-items: center;

  > .left {
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 16px;
      height: 16px;
    }
  }

  > .right {
    margin-left: 16px;
    display: flex;
    flex-direction: column;

    > .title {
      color: ${(props) => props.titleColor};
      font-family: "HarmonyOS Sans Bold";
      font-size: 16px;
      line-height: 20px;
    }

    > .desc {
      margin-top: 4px;
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

interface Props {
  className?: string;
  type: VerifyStatus.Unverified | VerifyStatus.Mismatch | VerifyStatus.Threat;
}

export const WcConnectTip: React.FC<Props> = ({ className, type }) => {
  const { t } = useTranslation();
  const bgColor =
    type === VerifyStatus.Unverified
      ? "rgba(250, 173, 20, 0.10)"
      : "rgba(255, 30, 43, 0.10)";
  const titleColor =
    type === VerifyStatus.Unverified
      ? "var(--Warning, #FAAD14)"
      : "var(--error-notice, #FF1E2B)";
  const icon = VerifyStatus.Unverified ? warningIco : threatIco;
  const title = VerifyStatus.Unverified
    ? t("Unknown domain")
    : VerifyStatus.Mismatch
      ? t("Domain mismatch")
      : t("Known security risk");
  const desc = VerifyStatus.Unverified
    ? t(
        "This domain cannot be verified. Check the request carefully before approving."
      )
    : VerifyStatus.Mismatch
      ? t(
          "This website has a domain that does not match the sender of this request. Approving may lead to loss of funds."
        )
      : t(
          "This domain is flagged as unsafe by multiple security providers. Leave immediately to protect your assets."
        );

  return (
    <Container className={className} bgColor={bgColor} titleColor={titleColor}>
      <div className="left">
        <img src={icon} alt="" />
      </div>
      <div className="right">
        <div className="title">{title}</div>
        <div className="desc">{desc}</div>
      </div>
    </Container>
  );
};
