import styled from "styled-components";
import { Trans } from "react-i18next";
import { DISCORD } from "../../config";

const Wrap = styled.div`
  box-sizing: border-box;
  font-family: "HarmonyOS Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  color: #000d1f;
  margin-top: 16px;
  margin-bottom: 40px;
  a {
    color: #413dec;
    text-decoration: none;
  }
`;

const RegisterKontosHelp = () => {
  return (
    <Wrap>
      <Trans i18nKey="Need help? Join our Discord">
        Need help? Join our <a href={DISCORD}>Discord</a>
      </Trans>
    </Wrap>
  );
};

export default RegisterKontosHelp;
