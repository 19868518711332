import CircleIconPair from "src/components/icons/CircleIconPair";
import { FtAsset } from "@/type/zkkontos";
import styled from "styled-components";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import ArrowIcon from "src/assets/icons/trade/black-right-arrow.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Container = styled.div``;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemTextContainer = styled.div<{ $allowClick: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-start;

  .upper {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${({ theme }) => theme.colors.__deep_800};
    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;

    .icon {
      width: 9px;
      height: 12px;
      margin-left: 4px;
    }
    ${({ $allowClick }) =>
      $allowClick
        ? `cursor: pointer;
      -webkit-tap-highlight-color: transparent;`
        : ``}
  }

  .bottom {
    margin-top: 5px;

    color: ${({ theme }) => theme.colors.__deep_400};
    font-family: HarmonyOS Sans;
    font-size: 12px;
  }
`;

const ItemTextContainer2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-end;

  .upper {
    color: ${({ theme }) => theme.colors.__deep_400};
    font-family: HarmonyOS Sans;
    font-size: 12px;
  }

  .bottom {
    margin-top: 5px;
    text-align: right;
    color: ${({ theme }) => theme.colors.__kontos_blue};
    font-family: "HarmonyOS Sans Bold";
    font-size: 16px;
  }
`;

interface PaymentChargeItemIDisplayFtAsset {
  imageUrl?: string;
  chainGreyImageUrl?: string;
  symbol: string;
  chainSymbol: string;
}

interface Props {
  currentFtAsset?: PaymentChargeItemIDisplayFtAsset | FtAsset;
  text: string;
  amountText: string | undefined;
  isFixed: boolean;
  assetLoading: boolean;
  taskDataLoading: boolean;
  onClick: () => void;
}

const PaymentChargeItem: React.FC<Props> = ({
  currentFtAsset,
  text,
  amountText,
  isFixed,
  assetLoading,
  taskDataLoading,
  onClick,
}) => {
  return (
    <Container>
      <ItemContainer>
        <CircleIconPair
          mainIcon={currentFtAsset?.imageUrl || DefaultTokenSvg}
          mainIconFallbackSrc={DefaultTokenSvg}
          subIcon={currentFtAsset?.chainGreyImageUrl || DefaultChainSvg}
          subIconFallbackSrc={DefaultChainSvg}
          mainWidth={45}
          mainHeight={45}
          subWidth={26}
          subHeight={26}
          totalWidth={63}
          totalHeight={49}
        />
        <ItemTextContainer $allowClick={!isFixed}>
          <div
            className="upper"
            onClick={() => {
              !isFixed && onClick();
            }}
          >
            {!assetLoading ? (
              currentFtAsset?.symbol
            ) : (
              <Skeleton count={1} style={{ width: "150px" }} />
            )}
            {!isFixed && <img className="icon" src={ArrowIcon} alt="" />}
          </div>
          <div className="bottom">
            {!assetLoading ? (
              currentFtAsset?.chainSymbol
            ) : (
              <Skeleton count={1} style={{ width: "150px" }} />
            )}
          </div>
        </ItemTextContainer>
        <ItemTextContainer2>
          <div className="upper">{text}</div>
          <div className="bottom">
            {!taskDataLoading ? (
              amountText
            ) : (
              <Skeleton count={1} style={{ width: "150px" }} />
            )}
          </div>
        </ItemTextContainer2>
      </ItemContainer>
    </Container>
  );
};

export default PaymentChargeItem;
