import styled from "styled-components";
import bgImg from "src/assets/images/ai-score-bg.png";
import { useEffect } from "react";
import ProgressBar from "progressbar.js";
import { AiScorePath } from "./AiScorePath";
import * as GlobalStyle from "src/style";
import { t } from "i18next";
import Skeleton from "react-loading-skeleton";
import errorIcon from "src/assets/icons/error1.svg";
import warningIcon from "src/assets/icons/warn1.svg";
import successIcon from "src/assets/icons/success1.svg";

const Container = styled.div`
  background-image: url(${bgImg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  height: 200px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarWrapper = styled.div`
  position: relative;
  width: 168px;
  height: 168px;
  border: 1px solid #edeef1;
  border-radius: 50%;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledAiScorePath = styled(AiScorePath)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 149.907px;
  height: 149.907px;
  flex-shrink: 0;
`;

const ScoreText = styled(GlobalStyle.BaseText)`
  color: ${(props) => props.color || GlobalStyle.colors.deep50};
  text-align: center;
  font-family: "HarmonyOS Sans Bold";
  font-size: 40px;
  line-height: 47px;
`;

const DescText = styled(GlobalStyle.BaseText)`
  color: ${(props) => props.color || GlobalStyle.colors.deep400};
  text-align: center;
  font-family: "HarmonyOS Sans";
  font-size: 12px;
  line-height: 14px;
`;

const StatusIcon = styled.img`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50px;
  width: 16px;
  height: 16px;
`;

const ScoreBox = styled.div<{ $hasIcon?: boolean }>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: ${(props) => (props.$hasIcon ? "75px" : "61px")};
  height: 47px;
`;

const DescBox = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 18px;
  height: 14px;
`;

const measureScore = (score?: number, requesting?: boolean) => {
  if (requesting) {
    return {
      icon: null,
      score: <Skeleton height={40} width={60} />,
      desc: <Skeleton height={10} width={20} />,
      stroke: undefined,
    };
  }

  if (score === undefined) {
    return {
      icon: null,
      score: <ScoreText color={GlobalStyle.colors.deep200}>N/A</ScoreText>,
      desc: <DescText color={GlobalStyle.colors.deep400}>N/A</DescText>,
      stroke: undefined,
    };
  } else if (score < 40) {
    return {
      icon: errorIcon,
      score: <ScoreText color={GlobalStyle.colors.error}>{score}</ScoreText>,
      desc: (
        <DescText color={GlobalStyle.colors.error}>{t("Bearish")}</DescText>
      ),
      stroke: GlobalStyle.colors.error,
    };
  } else if (score >= 40 && score < 60) {
    return {
      icon: warningIcon,
      score: <ScoreText color={GlobalStyle.colors.warning}>{score}</ScoreText>,
      desc: (
        <DescText color={GlobalStyle.colors.warning}>{t("Neutral")}</DescText>
      ),
      stroke: GlobalStyle.colors.warning,
    };
  } else if (score >= 60) {
    return {
      icon: successIcon,
      score: <ScoreText color={GlobalStyle.colors.success}>{score}</ScoreText>,
      desc: (
        <DescText color={GlobalStyle.colors.success}>{t("Excellent")}</DescText>
      ),
      stroke: GlobalStyle.colors.success,
    };
  }
};

interface IProps {
  score?: number;
  requesting?: boolean;
}

const pathId = "ai-score-path";

export const AiScorePanel: React.FC<IProps> = ({ score, requesting }) => {
  const scoreObj = measureScore(score, requesting);

  useEffect(() => {
    if (score) {
      let bar = new ProgressBar.Path(`#${pathId}`, {
        easing: "easeInOut",
        duration: 1000,
      });
      bar.animate(score / 100);
    } else {
      let bar = new ProgressBar.Path(`#${pathId}`, {
        easing: "easeInOut",
        duration: 1,
      });
      bar.animate(0);
    }
  }, [score]);

  return (
    <Container>
      <BarWrapper>
        <StyledAiScorePath id={pathId} stroke={scoreObj?.stroke} />

        {scoreObj?.icon && <StatusIcon src={scoreObj.icon} />}

        <ScoreBox $hasIcon={scoreObj?.icon}>{scoreObj?.score}</ScoreBox>

        <DescBox>{scoreObj?.desc}</DescBox>
      </BarWrapper>
    </Container>
  );
};
