import styled from "styled-components";
import PaymentPlanItem from "../payment-items/PaymentPlanItem";
import { getIconListWithOmit } from "src/utils/zkkontosHelper";
import { NewTasksByPaymentPlan } from "@zkkontos/kontos-sdk/src/api/types";
import { DEFAULT_ASSET_ICON_OMIT, DEFAULT_DECIMAL } from "src/config";
import { useState } from "react";
import { t } from "i18next";
import KontosNumber from "src/utils/KontosNumber";

const Container = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }

  .plan-item {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`;

interface Props {
  newTasksByPaymentPlans: NewTasksByPaymentPlan[];
  selectedTaskDataIndex: number;
  onSelectPaymentPlan: (index: number) => void;
}

const PaymentPlan: React.FC<Props> = ({
  newTasksByPaymentPlans,
  selectedTaskDataIndex,
  onSelectPaymentPlan,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const handleItemExpand = (index: number): void => {
    if (activeIndex === index) {
      setActiveIndex(undefined);
    } else {
      setActiveIndex(index);
    }
  };

  const handleItemSelect = (index: number): void => {
    onSelectPaymentPlan(index);
  };

  return (
    <Container>
      {newTasksByPaymentPlans.map((newTask, index) => {
        return (
          <div className="plan-item" key={index}>
            <PaymentPlanItem
              uniformedPayments={newTask.uniformedPayments}
              tokenIcons={getIconListWithOmit(
                newTask.uniformedPayments,
                DEFAULT_ASSET_ICON_OMIT
              )}
              title={
                index === 0 ? t("Recommend Plan") : t("Plan #") + (index + 1)
              }
              subTitle={
                `${newTask.uniformedPayments?.length} ` +
                t("Assets") +
                " ≈ " +
                new KontosNumber(
                  newTask.totalPaymentsInUsd,
                  DEFAULT_DECIMAL
                ).toFormat() +
                " USD"
              }
              isOpen={activeIndex === index}
              isSelected={selectedTaskDataIndex === index}
              onSelect={() => handleItemSelect(index)}
              onExpand={() => handleItemExpand(index)}
            />
          </div>
        );
      })}
    </Container>
  );
};

export default PaymentPlan;
