import styled from "styled-components";
import { SessionTypes } from "@walletconnect/types/dist/types/sign-client/session";
import { VerifyStatus } from "src/store/DappConnectStore";
import DappIcon from "src/components/icons/DappIcon";
import warningIco from "src/assets/icons/dapp/wc-warning.svg";
import threatIco from "src/assets/icons/dapp/wc-threat.svg";
import matchIco from "src/assets/icons/dapp/wc-match.svg";
import { useTranslation } from "react-i18next";
import Divider from "src/components/divider/Divider";
import { useCallback } from "react";
import { DeepButton } from "src/components/button/DeepButton";

// ------- Connect Item ------------ //
const ItemContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .match {
    color: var(--Kontos-Blue, #413dec);
  }

  .unverified {
    color: var(--Warning, #faad14);
  }

  .danger {
    color: var(--error-notice, #ff1e2b);
  }

  > .dapp-item-line-1 {
    padding: 27px 29px 0 18px;
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      height: 32px;
    }

    > span {
      margin-left: 11px;
      overflow: hidden;
      color: var(--Deep-800, #1a2535);
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "HarmonyOS Sans Bold";
      font-size: 16px;
      line-height: 20px;
    }
  }

  > .dapp-item-line-2 {
    margin-top: 20px;
    padding: 0 18px 12px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .dapp-item-line-2-left {
      display: flex;
      align-items: center;

      > img {
        width: 15.743px;
        height: 15.743px;
      }

      > span {
        margin-left: 8.26px;
        margin-right: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }
    }

    > .dapp-item-line-2-right {
      > .btn {
        padding: 6px 16px;
        color: var(--White, #fff);
        text-align: center;
        font-family: "HarmonyOS Sans Bold";
        font-size: 14px;
      }
    }
  }

  > .dapp-item-line-3 {
    padding: 12px 16px;
  }
`;

interface ItemProps {
  icon: any;
  name: string;
  url: string;
  type: VerifyStatus;
  onDisconnect: () => void;
}

const DappConnectItem: React.FC<ItemProps> = ({
  icon,
  name,
  url,
  type,
  onDisconnect,
}) => {
  const { t } = useTranslation();
  const colorClass =
    type === VerifyStatus.DomainMatch
      ? "match"
      : VerifyStatus.Unverified
        ? "unverified"
        : "danger";
  const statusIcon =
    type === VerifyStatus.DomainMatch
      ? matchIco
      : VerifyStatus.Unverified
        ? warningIco
        : threatIco;
  const desc = VerifyStatus.Unverified
    ? t(
        "This domain cannot be verified. Check the request carefully before approving."
      )
    : VerifyStatus.Mismatch
      ? t(
          "This website has a domain that does not match the sender of this request. Approving may lead to loss of funds."
        )
      : t(
          "This domain is flagged as unsafe by multiple security providers. Leave immediately to protect your assets."
        );

  return (
    <ItemContainer>
      {/* Line 1 */}
      <div className="dapp-item-line-1">
        <DappIcon size={32} src={icon} />
        <span>{name}</span>
      </div>

      {/* Line 2 */}
      <div className="dapp-item-line-2">
        <div className="dapp-item-line-2-left">
          <img src={statusIcon} alt="" />
          <span className={colorClass}>{url}</span>
        </div>
        <div className="dapp-item-line-2-right">
          <DeepButton className="btn" onClick={onDisconnect}>
            {t("Disconnect")}
          </DeepButton>
        </div>
      </div>

      {type !== VerifyStatus.DomainMatch && (
        <>
          <Divider left={18} right={18} />
          <div className={`dapp-item-line-3 ${colorClass}`}>{desc}</div>
        </>
      )}
    </ItemContainer>
  );
};
// ------- end Connect Item ------------ //

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

interface Props {
  sessions: SessionTypes.Struct[];
  onDisconnect: (topic: string, pairingTopic: string) => void;
}

const DappConnectItems: React.FC<Props> = ({ sessions, onDisconnect }) => {
  const handleOnDisconnect = useCallback(
    (topic: string, pairingTopic: string) => {
      onDisconnect(topic, pairingTopic);
    },
    [onDisconnect]
  );

  return (
    <Container>
      {sessions.map((session) => {
        const metadata = session.peer.metadata;

        return (
          <DappConnectItem
            key={session.topic}
            icon={metadata.icons?.[0]}
            name={metadata.name}
            url={metadata.url}
            type={VerifyStatus.DomainMatch}
            onDisconnect={() =>
              handleOnDisconnect(session.topic, session.pairingTopic)
            }
          ></DappConnectItem>
        );
      })}
    </Container>
  );
};

export default DappConnectItems;
