import { makeAutoObservable } from "mobx";
import { FtAsset } from "src/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";
import { RootStore } from "../RootStore";
import { RespTaskDataV3 } from "@zkkontos/kontos-sdk/src/api";
import { ReqTaskChargeAsset } from "@zkkontos/kontos-sdk/src/api/reqTypes";
import { handleError } from "src/pages/contract-interaction/executableMethods";

export enum PaymentMode {
  PaymentPlan,
  Receive,
  Bridge,
  OTC,
}

export interface PaymentData {
  mode: PaymentMode;
  fetchAndSetTaskData: (
    chargetAsset?: ReqTaskChargeAsset
  ) => Promise<RespTaskDataV3 | undefined>;
  targetFtAsset: FtAsset;
  targetFtAssetQuantity: KontosNumber;
  targetFtAssetValue: KontosNumber;
  receiveScope?: {
    enable: boolean;
    asset?: FtAsset;
  };
  bridgeScope?: {
    enable: boolean;
    asset?: FtAsset;
  };
  otcScope?: {
    enable: boolean;
    asset?: FtAsset;
  };
}

export class PaymentStore {
  rootStore: RootStore;
  paymentScopes: PaymentMode[] = [];
  paymentMode: PaymentMode = PaymentMode.PaymentPlan;
  paymentReceiveFtAsset?: FtAsset;
  paymentBridgeFtAsset?: FtAsset;
  paymentOtcFtAsset?: FtAsset;
  paymentTargetFtAsset?: FtAsset;
  paymentTargetFtAssetQuantity?: KontosNumber;
  paymentTargetFtAssetValue?: KontosNumber;
  fetchAndSetTaskData?: (
    chargetAsset?: ReqTaskChargeAsset
  ) => Promise<RespTaskDataV3 | undefined>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPaymentMode = (mode: PaymentMode) => {
    this.paymentMode = mode;
  };

  setPaymentReceiveFtAsset = (asset?: FtAsset) => {
    this.paymentReceiveFtAsset = asset;
  };

  setPaymentBridgeFtAsset = (asset?: FtAsset) => {
    this.paymentBridgeFtAsset = asset;
  };

  setPaymentOtcFtAsset = (asset?: FtAsset) => {
    this.paymentOtcFtAsset = asset;
  };

  generateByPlan = () => {
    this.reset();
    this.paymentMode = PaymentMode.PaymentPlan;
    this.paymentScopes.push(PaymentMode.PaymentPlan);
  };

  generateByOthers = (data: PaymentData) => {
    this.reset();
    this.paymentMode = data.mode;
    this.fetchAndSetTaskData = data.fetchAndSetTaskData;
    this.paymentTargetFtAsset = data.targetFtAsset;
    this.paymentTargetFtAssetQuantity = data.targetFtAssetQuantity;
    this.paymentTargetFtAssetValue = data.targetFtAssetValue;
    if (data.receiveScope?.enable) {
      this.paymentReceiveFtAsset = data.receiveScope.asset;
      this.paymentScopes.push(PaymentMode.Receive);
    }
    if (data.bridgeScope?.enable) {
      this.paymentReceiveFtAsset = data.bridgeScope.asset;
      this.paymentScopes.push(PaymentMode.Bridge);
    }
    if (data.otcScope?.enable) {
      this.paymentReceiveFtAsset = data.otcScope.asset;
      this.paymentScopes.push(PaymentMode.OTC);
    }
  };

  reset = () => {
    this.paymentMode = PaymentMode.PaymentPlan;
    this.paymentScopes = [];
    this.paymentReceiveFtAsset = undefined;
    this.paymentBridgeFtAsset = undefined;
    this.paymentOtcFtAsset = undefined;
    this.paymentTargetFtAsset = undefined;
    this.paymentTargetFtAssetQuantity = undefined;
    this.paymentTargetFtAssetValue = undefined;
    this.fetchAndSetTaskData = undefined;
  };

  wrappedFetchAndSetTaskData = async (
    chargetAsset?: ReqTaskChargeAsset
  ): Promise<RespTaskDataV3 | undefined> => {
    if (this.fetchAndSetTaskData) {
      try {
        return await this.fetchAndSetTaskData(chargetAsset);
      } catch (e) {
        handleError(e);
      }
    }
  };
}
