import { makeAutoObservable, runInAction } from "mobx";
import { ChainConfig } from "@zkkontos/kontos-sdk/src/api/types";
import { callAaaccounts } from "src/apis/explorer-apis";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import { RootStore } from "./RootStore";

export class ReceiveStore {
  rootStore: RootStore;
  chain?: ChainConfig;
  aaAccounts: { [key: string]: string } = {};

  get toReceiveAaAddress(): string | undefined {
    if (this.chain?.chainIndex && this.aaAccounts) {
      return this.aaAccounts?.[this.chain.chainIndex] || undefined;
    }
    return undefined;
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    this.restore();
  }

  restore = async () => {
    this.refreshAaAccounts();
  };

  refreshAaAccounts = async () => {
    if (!this.rootStore.userStore.accountName) {
      return;
    }
    try {
      const resp = await callAaaccounts(this.rootStore.userStore.accountName);
      let aaAccounts = resp.aaAccounts;
      const nameAddress = KontosQueryCli.nameAddress(
        this.rootStore.userStore.accountName
      );
      aaAccounts[0] = nameAddress;
      runInAction(() => {
        this.aaAccounts = aaAccounts;
      });
    } catch (e) {
      console.warn("Failed to fetch aaaccounts for receive modal", e);
    }
  };

  updateChain = (_chain: ChainConfig) => {
    this.chain = _chain;
  };
}
