import { observer } from "mobx-react";
import React from "react";
import { loadingStore } from "src/store/loadingStore";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhiteBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const LoadingGif = styled.img`
  height: 20px;
  width: 100px;
`;

const LoadingOverlay: React.FC = observer(() => {
  if (!loadingStore.isLoading) return null;

  return (
    <Overlay>
      <WhiteBox>
        <LoadingGif src="/static/loading.gif" alt="Loading..." />
      </WhiteBox>
    </Overlay>
  );
});

export default LoadingOverlay;
