import { DEFAULT_DECIMAL } from "src/config";
import { loadingStore } from "src/store/loadingStore";
import {
  filterEventNewTaskOpHash,
  getChainByChainIndex,
  parseApiErrorMessage,
  transferPayment,
  transferTaskDetail,
} from "src/utils/zkkontosHelper";
import {
  KontosClient,
  KontosQueryCli,
  TypeURLMsgRegisterTask,
  getDefaultClientPolymorphism,
} from "@zkkontos/kontos-sdk";
import { RespTaskDataV3 } from "@zkkontos/kontos-sdk/src/api/brokerApi";
import KontosNumber from "src/utils/KontosNumber";
import { Decimal } from "@zkkontos/math";
import { ethers } from "ethers";
import { parseEther } from "ethers/lib/utils";
import {
  enhancedFromHex,
  fromHex,
  keccak256,
  toBech32,
} from "@zkkontos/kontos-sdk/src/core/utils";
import { RegisterSingleTaskData } from "@zkkontos/kontos-sdk/src/api/types";
import Long from "long";
import {
  base64ToUint8Array,
  checkCryptoSupport,
  getDeviceInfo,
} from "src/utils/helper";
import {
  OpOrIntention,
  Payment,
  TaskDetail,
} from "@zkkontos/kontos-sdk/src/codec/kontos/cc/v1/cc";
import { ChainConfig, FtAsset, TaskType } from "src/type/zkkontos";
import toast from "src/components/toast/Toast";
import { uploadErrorOnce } from "src/service/wallet-service";
import { paramStore } from "src/store/independent/paramStore";
import { MsgRegisterTask } from "@zkkontos/kontos-sdk/src/codec/kontos/cc/v1/tx";
import { rootStore } from "src/index";
import { RecordOverviewDisplayProps } from "@/components/task-activity/TaskOrActivityOverviewItem";
import localKeeper from "src/store/localKeeper";

enum RegisterMethod {
  Buy = "buy",
  Sell = "sell",
  Send = "send",
  Swap = "swap",
  Dapp = "dapp",
  BatchSell = "batchsell",
}

// console.log(checkCryptoSupport());
// console.log(getDeviceInfo());

const fetchUserOpFromSingleTaskData = async (
  accountName: string,
  cli: KontosClient,
  syncAccountTask: RegisterSingleTaskData,
  chain: ChainConfig
) => {
  let userOp = {
    sender: syncAccountTask.opOrIntention.user_op?.sender || "",
    nonce: syncAccountTask.opOrIntention.user_op?.nonce
      ? Long.fromValue(syncAccountTask.opOrIntention.user_op?.nonce)
      : new Long(0),
    initCode: syncAccountTask.opOrIntention.user_op?.init_code
      ? base64ToUint8Array(syncAccountTask.opOrIntention.user_op?.init_code)
      : new Uint8Array(),
    callData: syncAccountTask.opOrIntention.user_op?.call_data
      ? base64ToUint8Array(syncAccountTask.opOrIntention.user_op?.call_data)
      : new Uint8Array(),
    callGasLimit: syncAccountTask.opOrIntention.user_op?.call_gas_limit
      ? Long.fromValue(syncAccountTask.opOrIntention.user_op?.call_gas_limit)
      : new Long(0),
    requiredAssets:
      syncAccountTask.opOrIntention.user_op?.required_assets || [],
    validUntil: syncAccountTask.opOrIntention.user_op?.valid_until
      ? Long.fromValue(syncAccountTask.opOrIntention.user_op?.valid_until)
      : new Long(0),
    sigOrProof: new Uint8Array(),
  };
  const userOpHash = cli.getUserOpHash(userOp, {
    chainDesc: "",
    chainSymbol: chain?.chainSymbol || "",
    entryPointAddress: chain.entryPointAddress,
    chainId: chain.chainIndex,
    lightClientVerifierAddress: "",
    minTxConfirmPeriod: new Long(0),
    minTrustedBlocks: new Long(0),
    minInterval: new Long(0),
    status: chain?.status || 0,
  });
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = cli.key.privateKey as CryptoKey;
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(userOpHash)
  );
  userOp.sigOrProof = sig;
  return userOp;
};

export const executeTaskData = async (
  method: RegisterMethod,
  accountName: string,
  chains: ChainConfig[],
  cli: KontosClient,
  respTaskData: RespTaskDataV3,
  selectedIndex: number,
  onSuccess?: (resp?: any) => void,
  onFail?: (e: any) => void
) => {
  loadingStore.showLoading();

  const toRegisterTasks: {
    chainIndex: string;
    opOrIntention: OpOrIntention;
    payments: Payment[];
    taskDetail: TaskDetail;
  }[] = [];

  // error reporting: save chain data before tx
  let nonce = -1;
  let gas = -1;
  let msgs: {
    typeUrl: string;
    value: MsgRegisterTask;
  }[] = [];
  let txHex = "";
  let cryptoSupport = {};

  try {
    let resp = undefined;

    await Promise.all(
      respTaskData.newTasksByPaymentPlans[selectedIndex].tasks.map(
        async (task) => {
          if (task.opOrIntention.intention) {
            const intention = task.opOrIntention.intention;
            const intentionTask = {
              chainIndex: task.chainIndex,
              opOrIntention: {
                intention: {
                  receiver: intention.receiver,
                  ftAssets: [
                    {
                      assetAddress: intention.ft_assets[0].asset_address,
                      assetAmount: intention.ft_assets[0].asset_amount,
                      targetPrice: intention.ft_assets[0].target_price,
                      slippage: intention.ft_assets[0].slippage,
                    },
                  ],
                  nftAssets: [],
                  digitalAssets: [],
                  data: intention.data,
                  initCode: intention.init_code,
                },
              },
              payments: task.payments.map(transferPayment),
              taskDetail: transferTaskDetail(task.taskDetail),
            };
            toRegisterTasks.push(intentionTask);
          } else if (task.opOrIntention.user_op) {
            const userOp = await fetchUserOpFromSingleTaskData(
              accountName,
              cli,
              task,
              getChainByChainIndex(chains, task.chainIndex)!
            );
            const opTask = {
              chainIndex: task.chainIndex,
              opOrIntention: {
                userOp: userOp,
              },
              payments: task.payments.map(transferPayment),
              taskDetail: transferTaskDetail(task.taskDetail),
            };
            toRegisterTasks.push(opTask);
          }
        }
      )
    );
    if (toRegisterTasks.length > 0) {
      // error reporting: save chain data before tx
      try {
        await Promise.all([
          (async () => {
            msgs = toRegisterTasks.map((task) => ({
              typeUrl: TypeURLMsgRegisterTask,
              value: MsgRegisterTask.fromPartial({
                chainIndex: task.chainIndex,
                from: KontosQueryCli.nameAddress(accountName),
                opOrIntention: task.opOrIntention,
                payments: task.payments,
                taskDetail: task.taskDetail,
              }),
            }));

            gas = await cli.signingCli.simulate(
              KontosQueryCli.kontosAddress(accountName),
              msgs,
              undefined
            );
          })(),
          (async () => {
            const { sequence } = await cli.signingCli.getSequence(
              KontosQueryCli.kontosAddress(accountName)
            );
            nonce = sequence;
          })(),
          (async () => {
            cryptoSupport = await checkCryptoSupport();
          })(),
        ]);
      } catch (e) {
        console.log("tx debug warning");
      }

      // execute
      resp = await cli.registerMultiTasks(toRegisterTasks);
    }
    onSuccess?.(resp);
    return filterEventNewTaskOpHash(resp);
  } catch (e) {
    try {
      txHex = await cli.fetchTxHex(msgs);
    } catch (e) {
      txHex = e instanceof Error ? e.message : JSON.stringify(e);
    }

    const kontosAddressFromRpc = rootStore.userStore.accountInfo?.kontosAddress;
    const kontosAddressFromCli = cli.signer.kontosAddress;
    let nameBytes;
    let addressBytes;
    let addressBytes_2;
    let calculatedKontosAddress;

    if (kontosAddressFromRpc !== kontosAddressFromCli) {
      try {
        nameBytes = fromHex(
          ethers.utils.defaultAbiCoder
            .encode(["string"], [cli.signer.name])
            .slice(2)
        );
      } catch (e) {
        nameBytes = e instanceof Error ? e.message : e;
      }
      try {
        if (nameBytes instanceof Uint8Array)
          addressBytes = keccak256(nameBytes).slice(12);
      } catch (e) {
        addressBytes = e instanceof Error ? e.message : e;
      }
      try {
        addressBytes_2 = fromHex(cli.signer.nameAddress.slice(2));
      } catch (e) {
        addressBytes_2 = e instanceof Error ? e.message : e;
      }
      try {
        if (addressBytes_2 instanceof Uint8Array)
          calculatedKontosAddress = toBech32(cli.signer.prefix, addressBytes_2);
      } catch (e) {
        calculatedKontosAddress = e instanceof Error ? e.message : e;
      }
    }

    uploadErrorOnce(
      accountName,
      method,
      {
        toRegisterTasks,
        reqTaskData: paramStore.getTaskDataParams(),
        respTaskData: respTaskData,
        debugData: {
          sender: accountName,
          kontosAddress: KontosQueryCli.kontosAddress(accountName),
          kontosAddressFromRpc,
          kontosAddressFromCli,
          signer: {
            prefix: cli.signer.prefix,
            name: cli.signer.name,
            nameAddress: cli.signer.nameAddress,
          },
          kontosAddressCalcProcess: {
            nameBytes,
            addressBytes,
            addressBytes_2,
            calculatedKontosAddress,
          },
          nonce: nonce,
          gas: gas,
          pubKey: rootStore.userStore.accountInfo?.pubKey,
          msgs: msgs,
          txHex,
        },
        deviceInfo: getDeviceInfo(),
        cryptoSupport,
      },
      e
    );
    onFail?.(e);
  } finally {
    loadingStore.hideLoading();
  }
};

export const buy = async (
  accountName: string,
  chains: ChainConfig[],
  cli: KontosClient,
  respTaskData: RespTaskDataV3,
  selectedIndex: number,
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (resp: any) => {
    const opHash = filterEventNewTaskOpHash(resp);
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: TaskType.Intention,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: rootStore.spotStore.toReceiveFtAsset?.chainImageUrl,
        chainSymbol: rootStore.spotStore.toReceiveFtAsset?.chainSymbol || "-",
        intentionProps: {
          totalUsdCost:
            respTaskData.newTasksByPaymentPlans[selectedIndex]
              .totalPaymentsInUsd,
          requiredAssets: [
            {
              amount:
                rootStore.spotStore.toReceiveFtAssetQuantity?.toStringWithDecimal(
                  DEFAULT_DECIMAL
                ) || "-",
              imageUrl: rootStore.spotStore.toReceiveFtAsset?.imageUrl,
            },
          ],
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "buying assets");
    onFail?.(e);
  };

  await executeTaskData(
    RegisterMethod.Buy,
    accountName,
    chains,
    cli,
    respTaskData,
    selectedIndex,
    newOnSuccess,
    newOnFail
  );
};

export const sell = async (
  accountName: string,
  chains: ChainConfig[],
  cli: KontosClient,
  respTaskData: RespTaskDataV3,
  selectedIndex: number,
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (resp: any) => {
    const opHash = filterEventNewTaskOpHash(resp);
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: TaskType.Intention,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: rootStore.spotStore.toBuyFtAsset?.chainImageUrl,
        chainSymbol: rootStore.spotStore.toBuyFtAsset?.chainSymbol || "-",
        intentionProps: {
          totalUsdCost:
            respTaskData.newTasksByPaymentPlans[selectedIndex]
              .totalPaymentsInUsd,
          requiredAssets: [
            {
              amount:
                rootStore.spotStore.toBuyFtAssetQuantity?.toStringWithDecimal(
                  DEFAULT_DECIMAL
                ) || "-",
              imageUrl: rootStore.spotStore.toBuyFtAsset?.imageUrl,
            },
          ],
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "selling assets");
    onFail?.(e);
  };

  await executeTaskData(
    RegisterMethod.Sell,
    accountName,
    chains,
    cli,
    respTaskData,
    selectedIndex,
    newOnSuccess,
    newOnFail
  );
};

export const batchSell = async (
  accountName: string,
  cli: KontosClient,
  respTaskData: RespTaskDataV3,
  selectedIndex: number,
  chains: ChainConfig[],
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (resp: any) => {
    const opHash = filterEventNewTaskOpHash(resp);
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: TaskType.Intention,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl:
          rootStore.spotStore.toBuyFtAssetForBatchSell?.chainImageUrl,
        chainSymbol:
          rootStore.spotStore.toBuyFtAssetForBatchSell?.chainSymbol || "-",
        intentionProps: {
          totalUsdCost:
            respTaskData.newTasksByPaymentPlans[selectedIndex]
              .totalPaymentsInUsd,
          requiredAssets: [
            {
              amount:
                rootStore.spotStore.toBuyFtAssetQuantity?.toStringWithDecimal(
                  DEFAULT_DECIMAL
                ) || "-",
              imageUrl: rootStore.spotStore.toBuyFtAssetForBatchSell?.imageUrl,
            },
          ],
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "selling");
    onFail?.(e);
  };

  await executeTaskData(
    RegisterMethod.BatchSell,
    accountName,
    chains,
    cli,
    respTaskData,
    selectedIndex,
    newOnSuccess,
    newOnFail
  );
};

// Send Kontos Native
export const sendKontosNative = async (
  cli: KontosClient,
  amount: KontosNumber,
  toAddress: string,
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = () => {
    handleSuccess("Transaction successful!");
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "sending");
    onFail?.(e);
  };

  loadingStore.showLoading();
  try {
    await cli.sendNativeToken(
      toAddress.replaceAll(".os", ""),
      Decimal.fromUserInput(amount.toString(), DEFAULT_DECIMAL)
    );
    newOnSuccess?.();
  } catch (e) {
    newOnFail?.(e);
  } finally {
    loadingStore.hideLoading();
  }
};

// Send Kontos Others
export const sendKontosOthers = async (
  token: ethers.Contract,
  gasPrice: ethers.BigNumber,
  nonce: number,
  gasLimit: number,
  amount: KontosNumber,
  toAddress: string,
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = () => {
    handleSuccess("Transaction successful!");
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "sending");
    onFail?.(e);
  };

  loadingStore.showLoading();
  try {
    await token.transfer(
      KontosQueryCli.nameAddress(toAddress),
      parseEther(amount.toString()),
      {
        gasPrice: gasPrice,
        nonce: nonce,
        gasLimit: gasLimit,
      }
    );
    newOnSuccess?.();
  } catch (e) {
    newOnFail?.(e);
  } finally {
    loadingStore.hideLoading();
  }
};

// Send Others
export const sendOthers = async (
  chain: ChainConfig,
  amount: string,
  toSendFtAsset: FtAsset,
  accountName: string,
  cli: KontosClient,
  respTaskData: RespTaskDataV3,
  selectedIndex: number,
  chains: ChainConfig[],
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (resp: any) => {
    const opHash = filterEventNewTaskOpHash(resp);
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: TaskType.Transfer,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: chain?.imageURL,
        chainSymbol: chain?.chainSymbol || "-",
        transferProps: {
          amount: amount,
          symbol: toSendFtAsset.symbol,
          imageUrl: toSendFtAsset.imageUrl,
          totalUsdCost:
            respTaskData.newTasksByPaymentPlans[selectedIndex]
              .totalPaymentsInUsd,
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "sending");
    onFail?.(e);
  };

  await executeTaskData(
    RegisterMethod.Send,
    accountName,
    chains,
    cli,
    respTaskData,
    selectedIndex,
    newOnSuccess,
    newOnFail
  );
};

export const swap = async (
  accountName: string,
  cli: KontosClient,
  respTaskData: RespTaskDataV3,
  selectedIndex: number,
  chains: ChainConfig[],
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (resp: any) => {
    const opHash = filterEventNewTaskOpHash(resp);
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: TaskType.Swap,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: rootStore.swapStore?.chain?.imageURL,
        chainSymbol: rootStore.swapStore?.chain?.chainSymbol || "-",
        swapProps: {
          outFtAssetAmount:
            rootStore.swapStore?.toReceiveFtAssetQuantity?.toStringWithDecimal(
              DEFAULT_DECIMAL
            ) || "-",
          outFtAssetSymbol:
            rootStore.swapStore?.toReceiveFtAsset?.symbol || "-",
          outFtAssetImageUrl:
            rootStore.swapStore?.toReceiveFtAsset?.imageUrl || "-",
          ftAssetAmount:
            rootStore.swapStore?.toSwapFtAssetQuantity?.toStringWithDecimal(
              DEFAULT_DECIMAL
            ) || "-",
          ftAssetSymbol: rootStore.swapStore?.toSwapFtAsset?.symbol || "-",
          ftAssetImageUrl: rootStore.swapStore?.toSwapFtAsset?.imageUrl || "-",
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "swaping");
    onFail?.(e);
  };

  await executeTaskData(
    RegisterMethod.Swap,
    accountName,
    chains,
    cli,
    respTaskData,
    selectedIndex,
    newOnSuccess,
    newOnFail
  );
};

export const executeDappIntention = async (
  accountName: string,
  cli: KontosClient,
  respTaskData: RespTaskDataV3,
  selectedIndex: number,
  chains: ChainConfig[],
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (resp: any) => {
    const opHash = filterEventNewTaskOpHash(resp);
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: TaskType.OpCall,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: rootStore.swapStore?.chain?.imageURL,
        chainSymbol: rootStore.swapStore?.chain?.chainSymbol || "-",
        opCallProps: {
          totalUsdCost:
            respTaskData.newTasksByPaymentPlans[selectedIndex]
              .totalPaymentsInUsd,
          opCallDestAddrs: [],
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "executing this interaction");
    onFail?.(e);
  };

  await executeTaskData(
    RegisterMethod.Dapp,
    accountName,
    chains,
    cli,
    respTaskData,
    selectedIndex,
    newOnSuccess,
    newOnFail
  );
};

export const handleSuccess = (text: string) => {
  toast({
    text: text,
    type: "success",
  });
};

export const handleError = (e: any, text?: string) => {
  const errorMessage =
    e instanceof Error
      ? parseApiErrorMessage(e).message
      : text
        ? "An unknown error occurred when " + text
        : "An unknown error occurred";
  if (errorMessage.toLocaleLowerCase() !== "canceled") {
    toast({ type: "error", text: errorMessage });
  }
};
