import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18n";
import App from "./App";
// import "./wasm_exec.js";
import { RootStore } from "./store/RootStore";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const rootStore = new RootStore();
export const StoreContext: React.Context<RootStore> =
  React.createContext(rootStore);
root.render(
  <>
    <StoreContext.Provider value={rootStore}>
      <App />
    </StoreContext.Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
