import { FtAsset } from "src/type/zkkontos";
import styled from "styled-components";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import { t } from "i18next";
import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FavoriteButton from "src/components/button/FavoriteButton";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import { InfoButton } from "src/components/button/InfoButton";
import { AssetSecurityIcon } from "../asset-select/AssetSecurityIcon";

const Container = styled.div<{ $isReactive?: boolean }>`
  flex: 1;
  padding: 14px;

  background: ${(props) => props.theme.colors.__white};
  border-radius: 8px;
  border: ${(props) => (props.$isReactive ? "2px solid #fff" : "none")};

  position: relative;

  @media (hover: hover) {
    &:hover {
      border-color: ${(props) =>
        props.$isReactive
          ? "var(--Kontos-Blue, #413dec)"
          : props.theme.colors.__white};
    }
  }

  &:active {
    border-color: ${(props) =>
      props.$isReactive
        ? "var(--Kontos-Blue, #413dec)"
        : props.theme.colors.__white};
  }

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  .left-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    .token-img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      border-radius: 50%;
    }
    .token-symbol {
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans BOLD";
      font-size: 16px;
      margin-right: 6px;
    }
    .chain-img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border-radius: 50%;
    }
    .chain-name {
      white-space: nowrap;

      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
      margin-right: 6px;
    }
    .tick {
      width: 12px;
      height: 12px;
    }
  }
  .left-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: ${(props) => props.theme.colors.__deep_400};
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }
`;

const RightContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px 14px 14px 0;

  display: flex;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  .number-box-line-1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 16px;
    margin-top: 4px;
    margin-bottom: 14px;
    .info {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }
  }

  .number-box-line-2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .fluctuate {
      margin-right: 6px;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }
    .positive {
      color: ${(props) => props.theme.colors.__success};
    }

    .negative {
      color: ${(props) => props.theme.colors.__error};
    }

    .value {
      white-space: nowrap;
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans BOLD";
      font-size: 16px;
    }

    .symbol {
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

interface Props {
  ftAsset?: FtAsset;
  requesting?: boolean;
  favRequesting: boolean;
  isFavorite?: boolean;
  isBorderReactive?: boolean;
  onClick: () => void;
  onFavClick: (ftAsset: FtAsset) => void;
  onDetailClick: () => void;
}

const LIQUIDITY_PRECISION = 3;

export const SpotAssetPanelV2: React.FC<Props> = memo(
  ({
    ftAsset,
    requesting = false,
    favRequesting,
    isFavorite,
    isBorderReactive,
    onClick,
    onFavClick,
    onDetailClick,
  }) => {
    const priceChange = new KontosNumber(
      ftAsset?.h24PriceChangePercentage,
      DEFAULT_DECIMAL
    );
    const usdPrice = new KontosNumber(ftAsset?.usdPrice, DEFAULT_DECIMAL);
    const liquidity = new KontosNumber(ftAsset?.h24VolumeUsd, DEFAULT_DECIMAL);

    return (
      <Container onClick={onClick} $isReactive={isBorderReactive}>
        <LeftContainer>
          <div className={"left-top"}>
            <ImageWithFallback
              className={"token-img"}
              src={ftAsset?.imageUrl || defaultTokenIcon}
              fallbackSrc={defaultTokenIcon}
              alt=""
            />
            <span className={"token-symbol"}>
              {ftAsset?.symbol ? ftAsset.symbol : "-"}
            </span>
            <ImageWithFallback
              className={"chain-img"}
              src={ftAsset?.chainGreyImageUrl || defaultChainIcon}
              fallbackSrc={defaultChainIcon}
              alt=""
            />
            <span className={"chain-name"}>
              {ftAsset?.chainSymbol ? ftAsset.chainSymbol : "-"}
            </span>
            {ftAsset && (
              <AssetSecurityIcon
                isGreatLiquidity={ftAsset.isGreatLiquidity}
                securityLevel={ftAsset.securityLevel}
                disableHoverToClick
              />
            )}
          </div>
          <div className={"left-bottom"}>
            <div className="number-box-line-2">
              {t("Vol $")}
              {!liquidity.eq(0) ? (
                liquidity.toFormatWithSymbol(LIQUIDITY_PRECISION)
              ) : requesting ? (
                <Skeleton count={1} style={{ width: "50px" }} />
              ) : (
                "-"
              )}
            </div>
          </div>
        </LeftContainer>
        <RightContainer>
          <NumberBox>
            <div className="number-box-line-1">
              {isFavorite !== undefined && ftAsset !== undefined && (
                <FavoriteButton
                  isFavorite={isFavorite}
                  onClick={(e) => {
                    e.stopPropagation();
                    onFavClick(ftAsset);
                  }}
                />
              )}

              {ftAsset !== undefined && (
                <InfoButton
                  className="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDetailClick();
                  }}
                />
              )}
            </div>

            <div className="number-box-line-2">
              <div
                className={`fluctuate ${
                  priceChange.gt(0) ? "positive" : "negative"
                }`}
              >
                {!priceChange.eq(0) ? (
                  (priceChange.gt(0) ? "↑" : "↓") +
                  priceChange.multiply(100).toFixed(2) +
                  "%"
                ) : requesting ? (
                  <Skeleton count={1} style={{ width: "60px" }} />
                ) : (
                  ""
                )}
              </div>
              <div className="value">
                <span className="symbol">{"$ "}</span>
                {!usdPrice.eq(0) ? (
                  usdPrice.toFormat()
                ) : requesting ? (
                  <Skeleton count={1} style={{ width: "50px" }} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </NumberBox>
        </RightContainer>
      </Container>
    );
  }
);
