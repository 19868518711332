import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{
  top: number;
  bottom: number;
  left: number;
  right: number;
  $isDashed: boolean;
  color: string;
  maxWidth?: number;
}>`
  border-top: ${(props) =>
    props.$isDashed ? `1px dashed ${props.color}` : `1px solid ${props.color}`};
  width: ${(props) => `calc(100% - ${props.left}px - ${props.right}px)`};
  margin-top: ${(props) => props.top}px;
  margin-bottom: ${(props) => props.bottom}px;
  margin-left: ${(props) => props.left}px;
  margin-right: ${(props) => props.right}px;
  ${(props) =>
    typeof props.maxWidth === "number" && `max-width: ${props.maxWidth}px`};
`;

interface Props {
  className?: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  isDashed?: boolean;
  color?: string;
  maxWidth?: number;
}

const Divider: React.FC<Props> = ({
  className,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  isDashed = false,
  color = "#cccfd2",
  maxWidth,
}) => {
  return (
    <Wrapper
      className={className}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      $isDashed={isDashed}
      color={color}
      maxWidth={maxWidth}
    />
  );
};

export default Divider;
