import { observer } from "mobx-react";
import styled from "styled-components";
import React from "react";
import kontosAvatar from "src/assets/images/kontos-avatar-recover.svg";
import success from "src/assets/images/success.svg";
import pending from "src/assets/images/pending.svg";
import { t } from "i18next";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    text-align: right;

    /* H7 */
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .normal {
    color: var(--Deep-100, #cccfd2);
  }

  .pending {
    color: var(--Warning, #faad14);
  }

  .success {
    color: var(--Success, #10ce5c);
  }

  img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-left: 6px;
  }
`;

export const rocoverStatusMap = {
  normal: "normal",
  pending: "pending",
  success: "success",
};

type IProps = {
  status: keyof typeof rocoverStatusMap;
};

const RecoverInputStatusText: React.FC<IProps> = ({ status }) => {
  const Img0 = () => {
    return <img className={"img0"} src={kontosAvatar} />;
  };

  const Img1 = () => {
    switch (status) {
      case "success":
        return <img className={"img1"} src={success} />;
        break;

      default:
        return null;
    }
  };

  return (
    <Wrapper>
      {status === "normal" && (
        <span className={"normal"}>{t("No request")}</span>
      )}
      {status === "pending" && (
        <>
          <span className={"pending"}>{t("Pending...")}</span>
          <img src={pending} />
        </>
      )}
      {status === "success" && (
        <>
          <span className={"success"}>{t("Confirmed")}</span>
          <img src={success} />
        </>
      )}
    </Wrapper>
  );
};

export default observer(RecoverInputStatusText);
