import { Unlock } from "./Unlock";
import { useStores } from "src/hooks/useStore";
import { SheetView } from "src/store/SheetStore";
import { useCallback } from "react";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { observer } from "mobx-react";
import { KontosClient } from "@zkkontos/kontos-sdk";

interface Props {
  mountPoint: Element | undefined;
}

export const UnlockSheet: React.FC<Props> = observer(({ mountPoint }) => {
  const { sheetStore } = useStores();
  const isOpen = sheetStore.sheetVisibility.get(SheetView.Unlock) as boolean;
  const sheet = sheetStore.getLatestSheetForView(SheetView.Unlock);

  const onClose = useCallback(
    () => sheetStore.closeSheetByView(SheetView.Unlock),
    [sheetStore]
  );

  const hanldePINSuccess = useCallback(
    (cli: KontosClient) => {
      onClose();
      sheet?.data?.unlockCallback?.(cli);
    },
    [onClose, sheet?.data]
  );

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      mountPoint={mountPoint}
      zExtraIndex={3}
      disableScrollLocking={true}
    >
      <Unlock onSuccess={hanldePINSuccess} />
    </BottomSheet>
  );
});
