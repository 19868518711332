import { VerifyStatus } from "src/store/DappConnectStore";
import styled from "styled-components";
import warningIco from "src/assets/icons/dapp/wc-warning.svg";
import threatIco from "src/assets/icons/dapp/wc-threat.svg";
import matchIco from "src/assets/icons/dapp/wc-match.svg";

const Container = styled.div<{ bgUrl: any }>`
  width: 100%;
  display: flex;
  align-items: center;

  > .left {
    border-radius: 15px;
    width: 56.258px;
    height: 56.258px;

    background-image: url(${(props) => props.bgUrl});
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;

    /* > img {
      width: 56.258px;
      height: 56.258px;
      border-radius: 15px;
    } */
  }

  > .right {
    margin-left: 11.94px;
    display: flex;
    flex-direction: column;

    > .line-1 {
      display: flex;
      align-items: center;

      > span {
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans Bold";
        font-size: 18px;
        line-height: 22px;
      }

      > img {
        margin-left: 4px;
        width: 16px;
        height: 16px;
      }
    }

    > .line-2 {
      margin-top: 7px;
      overflow: hidden;
      color: var(--Deep-400, #80868f);
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

interface Props {
  className?: string;
  type: VerifyStatus;
  icon?: any;
  name?: string;
  url?: string;
}

export const WcConnectGeneralInfo: React.FC<Props> = ({
  className,
  type,
  icon,
  name,
  url,
}) => {
  const statusIcon =
    type === VerifyStatus.DomainMatch
      ? matchIco
      : VerifyStatus.Unverified
        ? warningIco
        : threatIco;

  return (
    <Container className={className} bgUrl={icon}>
      <div className="left">{/* <img src={icon} alt="" /> */}</div>
      <div className="right">
        <div className="line-1">
          <span>{name}</span>
          <img src={statusIcon} alt="" />
        </div>
        <div className="line-2">{url}</div>
      </div>
    </Container>
  );
};
