import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { MAX_WIDTH } from "src/config";
import { UiSettingStore } from "./ui/UiSettingStore";
import {
  ROUTE_TRADE,
  ROUTE_TRADE_SPOT,
  ROUTE_TRADE_SWAP,
} from "src/router/router-config";

export class UiStore {
  rootStore: RootStore;
  uiSettingStore: UiSettingStore = new UiSettingStore(this);
  isOverWidth: boolean = window.innerWidth > MAX_WIDTH;
  innerWidth?: number;
  innerHeight?: number;
  tradeRoute: string = ROUTE_TRADE;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    window.addEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.isOverWidth = window.innerWidth > MAX_WIDTH;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }
  setTradeRouteSpot = () => {
    this.tradeRoute = ROUTE_TRADE + "/" + ROUTE_TRADE_SPOT;
  };

  setTradeRouteSwap = () => {
    this.tradeRoute = ROUTE_TRADE + "/" + ROUTE_TRADE_SWAP;
  };
}
