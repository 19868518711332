import { Dapp } from "src/type/dapp";
import styled from "styled-components";
import matchIco from "src/assets/icons/dapp/wc-match.svg";
import FavoriteButton from "src/components/button/FavoriteButton";
import { DappAppItem } from "../common/DappAppItem";
import { openUrl } from "src/utils/helper";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 10px 16px 10px 12px;

  @media (hover: hover) {
    &:hover {
      background: var(--Deep-25, #f5f5f6);
    }
  }

  &:active {
    background: var(--Deep-25, #f5f5f6);
  }

  .discover-trending-card-left {
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;

    .discover-trending-card-left-rank {
      position: absolute;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: "HarmonyOS Sans Bold";
      font-size: 14px;
      z-index: 1;
    }

    .rank-margin {
      margin-left: 21px;
    }

    .discover-trending-card-left-icon {
      display: flex;
      align-items: center;
      width: 56px;
      height: 56px;
    }

    .discover-trending-card-left-content {
      flex: 1;
      overflow-x: hidden;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .discover-trending-card-left-content-line-1 {
        display: flex;
        align-items: center;
        overflow-x: hidden;

        .discover-trending-card-left-content-line-1-text {
          color: var(--Deep-800, #1a2535);
          font-family: "HarmonyOS Sans Bold";
          font-size: 18px;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        .discover-trending-card-left-content-line-1-icon {
          margin-left: 5px;
          width: 15.743px;
          height: 15.743px;
        }
      }

      .discover-trending-card-left-content-line-2 {
        margin-top: 7px;
        overflow: hidden;
        color: var(--Deep-400, #80868f);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "HarmonyOS Sans SC";
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .discover-trending-card-right {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const RankedFirst = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--error-notice, #ff1e2b);
  overflow: visible;
  z-index: 2;
`;

const RankedSecond = styled.div`
  color: var(--error-notice, #ff1e2b);
`;

const RankedThird = styled.div`
  color: var(--Warning, #faad14);
`;

const RankedOthers = styled.div`
  color: var(--Deep-200, #b2b6bc);
`;

const getRankStyle = (rank: number) => {
  switch (rank) {
    case 1:
      return (
        <RankedFirst>
          <div>🔥</div>
          <div>1</div>
        </RankedFirst>
      );
    case 2:
      return <RankedSecond>2</RankedSecond>;
    case 3:
      return <RankedThird>3</RankedThird>;
    default:
      return <RankedOthers>{rank}</RankedOthers>;
  }
};

interface IDisplayProps {
  icon: any;
  statusIcon: any;
  title: string;
  subTitle: string;
  url: string;
}

interface IProps {
  className?: string;
  dapp: Dapp;
  isFavorite: boolean;
  rank?: number;
  onRightClick: () => void;
}

export const DiscoverTrendingCard: React.FC<IProps> = ({
  className,
  dapp,
  isFavorite,
  rank,
  onRightClick,
}) => {
  const iDisplayItem: IDisplayProps = {
    icon: dapp.imageUrl,
    statusIcon: dapp.verified ? matchIco : undefined,
    title: dapp.name,
    subTitle: dapp.desc,
    url: dapp.appUrl,
  };

  return (
    <Container className={className}>
      <div
        className="discover-trending-card-left"
        onClick={() => openUrl(iDisplayItem?.url, null)}
      >
        {!!rank && (
          <div className={`discover-trending-card-left-rank `}>
            {getRankStyle(rank)}
          </div>
        )}
        <div
          className={`discover-trending-card-left-icon ${
            !!rank && "rank-margin"
          }`}
        >
          <DappAppItem icon={iDisplayItem.icon} />
        </div>
        <div className="discover-trending-card-left-content">
          <div className="discover-trending-card-left-content-line-1">
            <span className="discover-trending-card-left-content-line-1-text">
              {iDisplayItem.title}
            </span>

            {iDisplayItem.statusIcon && (
              <img
                className="discover-trending-card-left-content-line-1-icon"
                src={iDisplayItem.statusIcon}
                alt=""
              />
            )}
          </div>
          <div className="discover-trending-card-left-content-line-2">
            {iDisplayItem.subTitle}
          </div>
        </div>
      </div>
      <div className="discover-trending-card-right">
        <FavoriteButton isFavorite={isFavorite} onClick={onRightClick} />
      </div>
    </Container>
  );
};
