import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KontosButton } from "src/components/button/KontosButton";
import { VerifyStatus } from "src/store/DappConnectStore";
import styled from "styled-components";
import spinIco from "src/assets/icons/dapp/spin.png";

const Container = styled.div`
  width: 100%;

  > .wc-connect-connect {
    height: 56px;
    position: relative;

    > .wc-connect-loading {
      @keyframes wc-connect-spin {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      animation: wc-connect-spin 1s linear infinite;
    }
  }

  > .threat {
    background: var(--error-notice, #ff1e2b);
  }

  > .warning {
    background: var(--Warning, #faad14);
  }

  > .cancel {
    height: 56px;
    margin-top: 12px;
  }
`;

interface Props {
  className?: string;
  type: VerifyStatus;
  onConnect: () => void;
  onCancel?: () => void;
}

const WARNING_INTERVAL = 1000;
const THREAT_INTERVAL = 3000;

export const WcConnectBtns: React.FC<Props> = ({
  className,
  type,
  onConnect,
  onCancel,
}) => {
  const { t } = useTranslation();
  const notSafe = type !== VerifyStatus.DomainMatch;
  const extraClassName = notSafe
    ? type === VerifyStatus.Unverified
      ? "warning"
      : "threat"
    : "";
  const [loading, setLoading] = useState<boolean>(notSafe);

  useEffect(() => {
    if (type !== VerifyStatus.DomainMatch) {
      if (type === VerifyStatus.Mismatch) {
        setTimeout(() => {
          setLoading(false);
        }, WARNING_INTERVAL);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, THREAT_INTERVAL);
      }
    }
  }, [type]);

  return (
    <Container className={className}>
      {/* Connect */}
      <KontosButton
        className={`wc-connect-connect ${extraClassName}`}
        onClick={() => {
          !loading && onConnect();
        }}
        $loading={loading}
      >
        {loading ? (
          <img className="wc-connect-loading" src={spinIco} alt="loading..." />
        ) : (
          t("Connect")
        )}
      </KontosButton>

      {/* Cancel */}
      {notSafe && (
        <KontosButton className="cancel" onClick={onCancel} $isOutlined>
          {t("Cancel")}
        </KontosButton>
      )}
    </Container>
  );
};
