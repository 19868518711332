import { makeAutoObservable } from "mobx";
import { UserStore } from "./UserStore";
import { UiStore } from "./UiStore";
import { ReceiveStore } from "./ReceiveStore";
import { EbStore } from "./events/EbStore";
import { DappConnectStore } from "./DappConnectStore";
import { SheetStore } from "./SheetStore";
import { DiscoverStore } from "./DiscoverStore";
import { MarketStore } from "./MarketStore";
import { FavStore } from "./FavStore";
import { ChainStore } from "./data/ChainStore";
import { SpotStore } from "./trade/SpotStore";
import { PaymentStore } from "./trade/PaymentStore";
import { SwapStore } from "./trade/SwapStore";
import { TradeStore } from "./trade/TradeStore";
import { TipStore } from "./TipStore";

export class RootStore {
  chainStore: ChainStore;
  userStore: UserStore;
  spotStore: SpotStore;
  swapStore: SwapStore;
  receiveStore: ReceiveStore;
  uiStore: UiStore;
  ebStore: EbStore;
  discoverStore: DiscoverStore;
  dappConnectStore: DappConnectStore;
  sheetStore: SheetStore;
  marketStore: MarketStore;
  favStore: FavStore;
  paymentStore: PaymentStore;
  tradeStore: TradeStore;
  tipStore: TipStore;

  constructor() {
    this.chainStore = new ChainStore(this);
    this.sheetStore = new SheetStore(this);
    this.userStore = new UserStore(this);
    this.spotStore = new SpotStore(this);
    this.swapStore = new SwapStore(this);
    this.receiveStore = new ReceiveStore(this);
    this.uiStore = new UiStore(this);
    this.ebStore = new EbStore(this);
    this.discoverStore = new DiscoverStore(this);
    this.dappConnectStore = new DappConnectStore(this);
    this.marketStore = new MarketStore(this);
    this.favStore = new FavStore(this);
    this.paymentStore = new PaymentStore(this);
    this.tradeStore = new TradeStore(this);
    this.tipStore = new TipStore(this);
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
