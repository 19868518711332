import styled from "styled-components";
import LeftBlueArrowSvg from "src/assets/icons/contract/left-blue-arrow.svg";
import { t } from "i18next";
import { memo } from "react";
import { AssetChange } from "src/type/zkkontos";
import successIcon from "src/assets/icons/task/success.svg";
import { DEFAULT_DECIMAL, EXPLORER_URL } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import { beautifyISOTimestamp, formatAddress, openUrl } from "src/utils/helper";
import Divider from "src/components/divider/Divider";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import FloatingButton from "src/pages/trade/FloatingButton";
import OpenUrlIcon from "src/assets/icons/task/open-url.svg";
import { ethers } from "ethers";
import { SingerSendOrReceive } from "src/apis/types";
import { TokenOrChainIcon } from "../icons/TokenOrChainIcon";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .success {
    color: ${({ theme }) => theme.colors.__success};
  }
  .error {
    color: ${({ theme }) => theme.colors.__error};
  }
  .warning {
    color: ${({ theme }) => theme.colors.__warning};
  }
  .strong {
    color: ${({ theme }) => theme.colors.__deep_800};
  }
  .kontos {
    color: ${({ theme }) => theme.colors.__kontos_blue};
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 23px;
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: HarmonyOS Sans Bold;
  font-size: 24px;

  .back-icon {
    width: 22px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .spacer {
    width: 22px;
  }
`;

const StatusBox = styled.div`
  margin-top: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .status-box-icon {
    width: 45px;
    height: 45px;
  }

  .status-box-text {
    font-family: HarmonyOS Sans Bold;
    font-size: 14px;
  }

  .status-box-amount {
    font-family: HarmonyOS Sans Bold;
    font-size: 18px;
  }
`;

const Scrollable = styled.div`
  padding: 0 32px 48px 32px;
  flex: 1;
  overflow: auto;
`;

const ItemLine = styled.div.attrs(() => ({}))`
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: HarmonyOS Sans;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.__deep_400};

  .strong {
    color: var(--Deep-800, #1a2535);
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  > span:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .blue {
    color: ${({ theme }) => theme.colors.__kontos_blue};
  }
`;

const ItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.__deep_800};
  font-family: "HarmonyOS Sans Bold";
  font-size: 14px;
`;

const ItemLine3 = styled.div`
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.__deep_400};
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;

  word-break: break-all;
  white-space: normal;

  .item1,
  .item2,
  .item3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    gap: 6px;
  }

  .item1 {
    width: 35%;
  }

  .spacer {
    flex-grow: 1;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
`;

const FloatingBtnWraper = styled.div`
  position: sticky;
  bottom: 16px;
  width: 89%;
  z-index: 1;
  margin: 0 auto;
`;

interface IProps {
  assetChange: AssetChange;
  onBack: () => void;
}

export const ActivityDetail: React.FC<IProps> = memo(
  ({ assetChange, onBack }) => {
    return (
      <Container>
        <TitleBox>
          <img
            className="back-icon"
            src={LeftBlueArrowSvg}
            alt="back"
            onClick={() => onBack()}
          />
          <span>{t("Activity Details")}</span>
          <div className="spacer"></div>
        </TitleBox>

        <StatusBox>
          <img className="status-box-icon" src={successIcon} alt="status" />
          <span className={`status-box-text success`}>
            {assetChange.singerSendOrReceive === SingerSendOrReceive.Receive &&
              t("Successfully received")}

            {assetChange.singerSendOrReceive === SingerSendOrReceive.Send &&
              t("Successfully sent")}
          </span>

          {assetChange.singerSendOrReceive === SingerSendOrReceive.Receive && (
            <span className={`status-box-amount kontos`}>
              {`+${new KontosNumber(
                assetChange.transferAmount,
                DEFAULT_DECIMAL
              ).toFormat()} ${assetChange.assetDetail.symbol}`}
            </span>
          )}
          {assetChange.singerSendOrReceive === SingerSendOrReceive.Send && (
            <span className={`status-box-amount`}>
              {`-${new KontosNumber(
                assetChange.transferAmount,
                DEFAULT_DECIMAL
              ).toFormat()} ${assetChange.assetDetail.symbol}`}
            </span>
          )}
        </StatusBox>

        <div style={{ padding: "0 32px" }}>
          <Divider isDashed={true} top={24} bottom={12} />
        </div>

        <Scrollable>
          {/* Overview */}

          <ItemLine>
            <span>{t("Type")}:</span>
            <span className="blue">
              {assetChange.singerSendOrReceive ===
                SingerSendOrReceive.Receive && t("Receive")}
              {assetChange.singerSendOrReceive === SingerSendOrReceive.Send &&
                t("Send")}
            </span>
          </ItemLine>
          <ItemLine>
            <span>{t("Network")}:</span>
            <TokenOrChainIcon
              style={{ marginRight: "4px" }}
              src={assetChange.assetDetail.chainGreyImageUrl}
              size={16}
              type="chain"
            />
            <span>{assetChange.assetDetail.chainSymbol}</span>
          </ItemLine>
          <ItemLine>
            <span>
              {t("Sender") +
                (assetChange.singerSendOrReceive === SingerSendOrReceive.Send
                  ? t(" (You)")
                  : "")}
              :
            </span>
            {ethers.utils.isAddress(assetChange.transferFromName) ? (
              <span
                className={`${
                  assetChange.singerSendOrReceive ===
                  SingerSendOrReceive.Receive
                    ? "strong"
                    : ""
                }`}
              >
                {formatAddress(assetChange.transferFromName)}
              </span>
            ) : (
              <>
                <Icon src={avatarIcon} />
                <span
                  className={`${
                    assetChange.singerSendOrReceive ===
                    SingerSendOrReceive.Receive
                      ? "strong"
                      : ""
                  }`}
                >
                  {formatAddress(assetChange.transferFromName)}
                </span>
              </>
            )}
          </ItemLine>

          <Divider isDashed={true} top={24} bottom={24} />

          <ItemTitle> {t("Kontos Transfer")}</ItemTitle>
          <ItemLine>
            <span>
              {t("Receiver") +
                (assetChange.singerSendOrReceive === SingerSendOrReceive.Receive
                  ? t(" (You)")
                  : "")}
              :
            </span>
            {ethers.utils.isAddress(assetChange.transferToName) ? (
              <span
                className={`${
                  assetChange.singerSendOrReceive === SingerSendOrReceive.Send
                    ? "strong"
                    : ""
                }`}
              >
                {formatAddress(assetChange.transferToName)}
              </span>
            ) : (
              <>
                <Icon src={avatarIcon} />
                <span
                  className={`${
                    assetChange.singerSendOrReceive === SingerSendOrReceive.Send
                      ? "strong"
                      : ""
                  }`}
                >
                  {formatAddress(assetChange.transferToName)}
                </span>
              </>
            )}
          </ItemLine>
          <ItemLine3>
            <span className="item1">
              <TokenOrChainIcon
                src={assetChange.assetDetail.imageUrl}
                size={16}
                type="token"
              />
              {assetChange.assetDetail.symbol}
            </span>
            <span className="item2">
              <TokenOrChainIcon
                src={assetChange.assetDetail.chainGreyImageUrl}
                size={16}
                type="token"
              />
              {assetChange.assetDetail.chainSymbol}
            </span>
            <span className="spacer"></span>
            {assetChange.singerSendOrReceive ===
              SingerSendOrReceive.Receive && (
              <span className="item3 kontos">
                {`+${new KontosNumber(
                  assetChange.transferAmount,
                  DEFAULT_DECIMAL
                ).toFormat()} ${assetChange.assetDetail.symbol}`}
              </span>
            )}
            {assetChange.singerSendOrReceive === SingerSendOrReceive.Send && (
              <span className="item3 strong">
                {`-${new KontosNumber(
                  assetChange.transferAmount,
                  DEFAULT_DECIMAL
                ).toFormat()} ${assetChange.assetDetail.symbol}`}
              </span>
            )}
          </ItemLine3>

          {/* Others */}
          <Divider isDashed={true} top={24} bottom={24} />

          {assetChange.singerSendOrReceive === SingerSendOrReceive.Send && (
            <ItemLine>
              <span>{t("Total fee:")}</span>
              <span className="success">{t("Free")}</span>
            </ItemLine>
          )}
          <ItemLine>
            <span>{t("Creating time:")}</span>
            <span>{beautifyISOTimestamp(assetChange.blockTime)}</span>
          </ItemLine>
        </Scrollable>

        <FloatingBtnWraper>
          <FloatingButton
            onClick={() => {
              const url =
                EXPLORER_URL +
                "transaction-detail?txHash=" +
                assetChange.txHash;
              openUrl(url);
            }}
            fixedIcon={OpenUrlIcon}
          >
            {t("View on Kontos Explorer")}
          </FloatingButton>
        </FloatingBtnWraper>
      </Container>
    );
  }
);
