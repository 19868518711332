import { makeAutoObservable } from "mobx";
import { RootStore } from "../RootStore";
import { SpotStore } from "./SpotStore";
import { SwapStore } from "./SwapStore";
import localKeeper from "../localKeeper";
import { FtAsset } from "@/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";

export class TradeStore {
  rootStore: RootStore;
  spotStore: SpotStore;
  swapStore: SwapStore;
  payableChangeCount: number = 0;
  showManageTip: boolean = false;
  disableAiScore?: boolean;
  fromAi: boolean = false;
  toSendFtAsset?: FtAsset;
  toSendMax?: KontosNumber;

  constructor(rootStore: RootStore) {
    this.disableAiScore = localKeeper.getDisableAiScore();
    this.rootStore = rootStore;
    this.spotStore = rootStore.spotStore;
    this.swapStore = rootStore.swapStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  resetInput = () => {
    this.spotStore.resetInput();
    this.swapStore.resetSwapInput();
  };

  reset = () => {
    this.spotStore.reset();
    this.swapStore.reset();
  };

  changePayableAssets = () => {
    this.payableChangeCount += 1;
  };

  setShowManageTip = (show: boolean) => {
    this.showManageTip = show;
  };

  toggleDisableAiScore = () => {
    localKeeper.setDisableAiScore(!this.disableAiScore);
    this.disableAiScore = localKeeper.getDisableAiScore();
  };

  setFromAi = (from: boolean) => {
    this.fromAi = from;
  };

  resetSendInput = () => {
    this.toSendFtAsset = undefined;
  };

  setToSendFtAsset = (asset: FtAsset) => {
    this.toSendFtAsset = asset;
  };

  setToSendMax = (max?: KontosNumber) => {
    this.toSendMax = max;
  };

  resetAiFields = () => {
    this.fromAi = false;
    this.toSendFtAsset = undefined;
    this.toSendMax = undefined;
  };
}
