import { EbBanner } from "src/pages/events/energy-boosting/EbBanner";
import styled from "styled-components";
import { DiscoverExploreTrending } from "./DiscoverExploreTrending";
import { useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { DappHistoryPanel } from "../dapp-history/DappHistoryPanel";
import { useNavigate } from "react-router-dom";
import { PREV, ROUTE_DISCOVER_TRENDINGS } from "src/router/router-config";
import { AnimatedLayout } from "src/components/tab/AnimatedLayout";
import { Flag } from "src/configs/featureFlags";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const StyledEbBanner = styled(EbBanner)`
  margin: 0 16px;
  width: calc(100% - 32px);
`;

export const DiscoverExploreTab: React.FC = observer(() => {
  const { discoverStore } = useStores();
  const navigate = useNavigate();
  const showEbBanner = Flag.eb;

  const handleViewAll = useCallback(() => {
    navigate(PREV + ROUTE_DISCOVER_TRENDINGS);
  }, [navigate]);

  return (
    <AnimatedLayout>
      <Container>
        {showEbBanner && <StyledEbBanner />}

        <DiscoverExploreTrending
          dapps={discoverStore.dappTrendings}
          onViewAll={handleViewAll}
        />

        <DappHistoryPanel />
      </Container>
    </AnimatedLayout>
  );
});
