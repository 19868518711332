import { DappHistoryItem } from "@/type/dapp";
import styled from "styled-components";
import { DappCard } from "../common/DappCard";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 26px;
  padding: 26px;
`;

interface IProps {
  className?: string;
  dapps: DappHistoryItem[];
  onRemove: (timestamp: number) => void;
}

export const DappHistoryList: React.FC<IProps> = ({
  className,
  dapps,
  onRemove,
}) => {
  return (
    <Container className={className}>
      {dapps.map((dapp) => (
        <DappCard
          key={dapp.timestamp}
          historyItem={dapp}
          onRightClick={() => onRemove(dapp.timestamp)}
        />
      ))}
    </Container>
  );
};
