import styled from "styled-components";
import tipsIcon from "src/assets/icons/tips.svg";
import { ReactNode } from "react";

const Container = styled.div`
  max-width: 320px;
  padding: 8px;
  border-radius: 8px;
  background: var(--Deep-700, #333d4c);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const TipsIcon = styled.img`
  width: 21px;
  height: 12px;
`;

const Content = styled.div`
  color: #fff;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

interface IProps {
  children?: ReactNode;
}

export const TipsDark: React.FC<IProps> = ({ children }) => {
  return (
    <Container>
      <TipsIcon src={tipsIcon} />
      <Content>{children}</Content>
    </Container>
  );
};
