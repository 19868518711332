import { theme } from "src/App";
import styled from "styled-components";

const StyledSvg = styled.svg<{ $isAvtive: boolean; $disable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  .path-1 {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_100};
  }

  .path-2 {
    stroke: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_200};
  }

  .ellipse-1 {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_200};
  }

  cursor: ${(props) => (!props.$disable ? "pointer" : "not-allowed")};

  @media (hover: hover) {
    &:hover {
      .path-1 {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }

      .path-2 {
        stroke: ${(props) =>
          !props.$disable && props.theme.colors.__kontos_blue};
      }

      .ellipse-1 {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }
    }
  }
`;

interface IProps {
  className?: string;
  isActive?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

const TradeIcon: React.FC<IProps> = ({
  className,
  isActive = false,
  disable = false,
  onClick,
}) => {
  return (
    <StyledSvg
      $isAvtive={isActive}
      $disable={disable}
      className={className}
      onClick={onClick}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-1"
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.078 2.33577C20.078 4.00395 21.3461 5.36681 22.913 5.93931C26.4953 7.24822 29.3304 10.1026 30.6107 13.6921C31.164 15.2433 32.5077 16.4999 34.1546 16.4999C34.2215 16.4999 34.2771 16.4437 34.2703 16.3771C33.5128 8.94328 27.6079 3.02994 20.1718 2.24866C20.1212 2.24334 20.078 2.28491 20.078 2.33577ZM17.078 2.33575C17.078 2.27651 17.0282 2.22755 16.9692 2.23317C9.51042 2.94335 3.55841 8.81292 2.73188 16.2278C2.71515 16.3778 2.85119 16.4998 3.00214 16.4998C4.55189 16.4999 5.81831 15.3197 6.31933 13.8532C7.59201 10.128 10.5319 7.17224 14.2527 5.87529C15.812 5.33174 17.078 3.98713 17.078 2.33575ZM16.9693 33.7666C17.0283 33.7722 17.0781 33.7232 17.0781 33.664C17.0781 32.0126 15.8122 30.668 14.2529 30.1245C10.5321 28.8276 7.5921 25.8718 6.31937 22.1466C5.81831 20.6801 4.55188 19.4999 3.00209 19.4998C2.85116 19.4998 2.71515 19.6218 2.73186 19.7718C3.55836 27.1868 9.51044 33.0564 16.9693 33.7666ZM20.0781 33.664C20.0781 33.7148 20.1213 33.7564 20.1719 33.7511C27.6079 32.9697 33.5127 27.0565 34.2703 19.6229C34.2771 19.5562 34.2215 19.4999 34.1545 19.4999C32.5076 19.4999 31.164 20.7564 30.6107 22.3076C29.3304 25.897 26.4954 28.7514 22.9132 30.0603C21.3463 30.6329 20.0781 31.9958 20.0781 33.664Z"
        fill={theme.colors.__deep_100}
      />
      <path
        className="path-2"
        id="Line 149"
        d="M18.5 8.9494L18.5 11.2121"
        stroke={theme.colors.__deep_200}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="path-2"
        id="Line 150"
        d="M18.5 24.788L18.5 27.0507"
        stroke={theme.colors.__deep_200}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="path-2"
        id="Line 151"
        d="M9.5 17.9999L11.75 17.9999"
        stroke={theme.colors.__deep_200}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="path-2"
        id="Line 152"
        d="M25.25 17.9999L27.5 17.9999"
        stroke={theme.colors.__deep_200}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        className="ellipse-1"
        id="Ellipse 3561"
        cx="18.5"
        cy="17.9999"
        rx="3.375"
        ry="3.39404"
        fill={theme.colors.__deep_200}
      />
    </StyledSvg>
  );
};

export default TradeIcon;
