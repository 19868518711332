import {
  ActivityType,
  TaskPreview,
  TaskType,
  toActivityType,
} from "src/type/zkkontos";
import { classifyTaskStatus } from "src/utils/zkkontosHelper";
import Long from "long";
import {
  beautifyISOTimestamp,
  formatAddress,
  getDisplayAmount,
  shortAddress,
} from "src/utils/helper";
import styled from "styled-components";
import { TokenOrChainIcon } from "../icons/TokenOrChainIcon";
import * as GlobalStyle from "src/style";
import rightArrowIcon from "src/assets/icons/right-arrow.svg";
import rightArrowActiveIcon from "src/assets/icons/right-arrow.active.svg";
import sendIcon from "src/assets/icons/send.svg";
import receiveIcon from "src/assets/icons/receive.svg";
import { t } from "i18next";
import statusPendingIcon from "src/assets/icons/task/status-pending.svg";
import statusCompletedIcon from "src/assets/icons/task/status-completed.svg";
import statusFailIcon from "src/assets/icons/task/status-fail.svg";
import statusUncaughtIcon from "src/assets/icons/task/status-uncaught.svg";
import { useMemo } from "react";
import { AssetChange, SingerSendOrReceive } from "src/apis/types";
import uncaughtGif from "src/assets/images/uncaught.gif";
import { Icon } from "../icons/Icon";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import { ethers } from "ethers";

const VIEW_MORE_TIP_CLASS = "task-or-activity-item-view-more-tip";
const BOX_ERRORABLE_TEXT_CLASS = "task-or-activity-item-box-errorable";
const ARROW_ICON_CLASS = "task-box-arrow";
const TYPE_BOX_CLASS = "task-box-type-box";

const getActivityIcon = (type: ActivityType) => {
  switch (type) {
    case ActivityType.Receive:
      return receiveIcon;
    case ActivityType.Send:
      return sendIcon;
  }
};

const getTaskStatusIcon = (isFake?: boolean, statusCode?: number): any => {
  if (isFake) {
    return statusUncaughtIcon;
  }
  switch (statusCode) {
    case 0: // Pending
      return statusPendingIcon;
    case 1: // Canceled
      return statusFailIcon;
    case 2: // Claimed
      return statusPendingIcon;
    case 3: // Timeout
      return statusFailIcon;
    case 4: // Fail
      return statusFailIcon;
    case 5: // Success
      return statusCompletedIcon;
    case 6: // Fail at new account
    case 7: // Fail at pre pay
    case 8: // Fail at execute tx
    case 9: // Fail at lock assets
      return statusFailIcon;
    case 10: // Unconfirmed by payer
      return statusPendingIcon;
    default:
      return statusPendingIcon;
  }
};

const getTaskStatusText = (isFake?: boolean, statusCode?: number): string => {
  if (isFake) {
    return t("Uncaught...");
  }
  switch (statusCode) {
    case 0: // Pending
      return t("Pending...");
    case 1: // Canceled
      return t("Cancelled!");
    case 2: // Claimed
      return t("Claimed...");
    case 3: // Timeout
      return t("Timeout!");
    case 4: // Fail
      return t("Fail");
    case 5: // Success
      return t("Completed");
    case 6: // Fail at new account
    case 7: // Fail at pre pay
    case 8: // Fail at execute tx
    case 9: // Fail at lock assets
      return t("Fail");
    case 10: // Unconfirmed by payer
      return t("Unconfirmed");
    default:
      return t("Unknown");
  }
};

const Container = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const TimeLIne = styled.div`
  color: ${({ theme }) => theme.colors.__deep_400};
  font-family: HarmonyOS Sans;
  font-size: 14px;
`;

const TaskBox = styled.div<{ $isFake?: boolean; $isFailed?: boolean }>`
  position: relative;
  margin-top: 8px;
  padding: 36px 10px 10px 10px;
  overflow-x: hidden;

  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  ${GlobalStyle.BaseText} {
    ${(props) =>
      props.$isFake && `color: ${GlobalStyle.colors.deep200} !important`};
  }

  .${TYPE_BOX_CLASS} {
    ${(props) =>
      props.$isFake &&
      `background-color: ${GlobalStyle.colors.deep200} !important`};
  }

  .${BOX_ERRORABLE_TEXT_CLASS} ${GlobalStyle.BaseText} {
    ${(props) =>
      props.$isFailed && `color: ${GlobalStyle.colors.error} !important`};
  }

  @media (hover: hover) {
    &:hover {
      cursor: ${(props) => (props.$isFake ? "not-allowed" : "pointer")};
      -webkit-tap-highlight-color: transparent;
      border-color: ${(props) =>
        !props.$isFake && "var(--Kontos-Blue, #413dec)"};

      .${VIEW_MORE_TIP_CLASS} {
        color: ${(props) =>
          !props.$isFake && "var(--Kontos-Blue, #413dec)"} !important;
      }

      .${ARROW_ICON_CLASS} {
        background-image: ${(props) =>
          !props.$isFake && `url(${rightArrowActiveIcon})`} !important;
      }
    }
  }

  &:active {
    cursor: ${(props) => (props.$isFake ? "not-allowed" : "pointer")};
    -webkit-tap-highlight-color: transparent;
    border-color: ${(props) => !props.$isFake && "var(--Kontos-Blue, #413dec)"};

    .${VIEW_MORE_TIP_CLASS} {
      color: ${(props) =>
        !props.$isFake && "var(--Kontos-Blue, #413dec)"} !important;
    }

    .${ARROW_ICON_CLASS} {
      background-image: ${(props) =>
        !props.$isFake && `url(${rightArrowActiveIcon})`} !important;
    }
  }
`;

const TypeBox = styled.div.attrs((props) => ({
  className: `${props.className || ""} ${TYPE_BOX_CLASS}`,
}))`
  position: absolute;
  left: 0;
  top: 0;
  padding: 4px 20px;

  border-bottom-right-radius: 8px;
  background: var(--Deep-800, #1a2535);
  line-height: 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--White, #fff);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const UncaugthIcon = styled.img`
  width: 50px;
  height: 18px;
`;

const ActivityIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const ViewMoreTip = styled.div.attrs((props) => ({
  className: `${props.className || ""} ${VIEW_MORE_TIP_CLASS}`,
}))`
  position: absolute;
  top: 4px;
  right: 6px;
  height: 20px;

  display: flex;
  align-items: center;
  gap: 4px;

  color: var(--Deep-200, #b2b6bc);
  font-family: "HarmonyOS Sans";
  font-size: 12px;

  span {
    padding: 2px 0 4px 0;
    line-height: 14px;
  }
`;

const ViewMoreIcon = styled.div.attrs((props) => ({
  className: `${props.className || ""} ${ARROW_ICON_CLASS}`,
}))`
  width: 20px;
  height: 20px;

  background-image: url(${rightArrowIcon});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
`;

const BoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 9px;
`;

const BoxLeftLineOne = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BoxLeftLineTwo = styled.div.attrs((props) => ({
  className: `${props.className || ""} ${BOX_ERRORABLE_TEXT_CLASS}`,
}))`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StatusIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const BoxRight = styled.div.attrs((props) => ({
  className: `${props.className || ""} ${BOX_ERRORABLE_TEXT_CLASS}`,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
`;

const BoxRightLineOne = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const BoxRightLineTwo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const transferAcToDisplayData = (
  ac: AssetChange
): RecordOverviewDisplayProps => {
  let displayAc: RecordOverviewDisplayProps = {
    id: ac.assetChangeId.toString(),
    activityType: toActivityType(ac.singerSendOrReceive),
    isFake: false,
    createdAt: ac.blockTime as unknown as number,
    chainImageUrl: ac.assetDetail.chainGreyImageUrl,
    chainSymbol: ac.assetDetail.chainSymbol,
  };
  switch (ac.singerSendOrReceive) {
    case SingerSendOrReceive.Receive:
      return {
        ...displayAc,
        receiveProps: {
          amount: ac.transferAmount,
          assetSymbol: ac?.assetDetail?.symbol || "-",
          assetImageUrl: ac?.assetDetail?.imageUrl,
          address: ac?.transferFromName,
        },
      };
    case SingerSendOrReceive.Send:
      return {
        ...displayAc,
        sendProps: {
          amount: ac.transferAmount,
          assetSymbol: ac?.assetDetail?.symbol || "-",
          assetImageUrl: ac?.assetDetail?.imageUrl,
          address: ac?.transferToName,
        },
      };
  }
  return displayAc;
};

export const transferTaskToDisplayData = (
  task: TaskPreview
): RecordOverviewDisplayProps => {
  let displayTask: RecordOverviewDisplayProps = {
    id: task.opHash,
    tasktype: task.taskType as TaskType,
    isFake: false,
    createdAt: task.createdAt as unknown as number,
    includeSyncAccount: !!task.includeSyncAccount,
    chainImageUrl: task.chainImageUrl,
    chainSymbol: task.chainSymbol,
    taskStatus: task.taskStatus,
  };
  switch (task.taskType) {
    case TaskType.Transfer:
      return {
        ...displayTask,
        transferProps: {
          amount: task.callDataDetail?.ftAsset?.amount || "",
          symbol: task.callDataDetail?.ftAsset?.symbol || "",
          imageUrl: task.callDataDetail?.ftAsset?.imageUrl || "",
          totalUsdCost: task.totalUsdCost,
        },
      };
    case TaskType.Swap:
      return {
        ...displayTask,
        swapProps: {
          outFtAssetAmount: task.callDataDetail?.outFtAsset?.amount || "",
          outFtAssetSymbol: task.callDataDetail?.outFtAsset?.symbol || "",
          outFtAssetImageUrl: task.callDataDetail?.outFtAsset?.imageUrl || "",
          ftAssetAmount: task.callDataDetail?.ftAsset?.amount || "",
          ftAssetSymbol: task.callDataDetail?.ftAsset?.symbol || "",
          ftAssetImageUrl: task.callDataDetail?.ftAsset?.imageUrl || "",
        },
      };
    case TaskType.Intention:
      return {
        ...displayTask,
        intentionProps: {
          totalUsdCost: task.totalUsdCost,
          requiredAssets: task.requiredAssets,
        },
      };
    case TaskType.OpCall:
      return {
        ...displayTask,
        opCallProps: {
          totalUsdCost: task.totalUsdCost,
          opCallDestAddrs: task.opCallDestAddrs || [],
        },
      };
    default:
      return displayTask;
  }
};

export interface RecordOverviewDisplayProps {
  id: string;
  tasktype?: TaskType;
  activityType?: ActivityType;
  isFake?: boolean;
  createdAt: number;
  includeSyncAccount?: boolean;
  chainImageUrl?: string;
  chainSymbol: string;
  taskStatus?: number;
  transferProps?: {
    amount: string;
    symbol: string;
    imageUrl: string;
    totalUsdCost: string;
  };
  swapProps?: {
    outFtAssetAmount?: string;
    outFtAssetSymbol?: string;
    outFtAssetImageUrl?: string;
    ftAssetAmount?: string;
    ftAssetSymbol?: string;
    ftAssetImageUrl?: string;
  };
  intentionProps?: {
    totalUsdCost: string;
    requiredAssets: {
      amount: string;
      imageUrl?: string;
    }[];
  };
  opCallProps?: {
    totalUsdCost: string;
    opCallDestAddrs: string[];
  };
  sendProps?: {
    amount?: string;
    assetSymbol?: string;
    assetImageUrl?: any;
    address?: string;
  };
  receiveProps?: {
    amount?: string;
    assetSymbol?: string;
    assetImageUrl?: any;
    address?: string;
  };
}

interface IProps {
  task?: TaskPreview;
  ac?: AssetChange;
  displayItem?: RecordOverviewDisplayProps;
  onClick?: () => void;
}

export const TaskOrActivityOverviewItem: React.FC<IProps> = ({
  task,
  ac,
  displayItem,
  onClick,
}) => {
  const record = useMemo(() => {
    return displayItem
      ? displayItem
      : task
        ? transferTaskToDisplayData(task)
        : ac
          ? transferAcToDisplayData(ac)
          : null;
  }, [ac, task, displayItem]);

  if (!record) {
    return <></>;
  }

  return (
    <Container>
      {/* Task Timestamp */}
      <TimeLIne>
        {beautifyISOTimestamp(Long.fromValue(record.createdAt).toNumber())}
      </TimeLIne>

      <TaskBox
        $isFake={record.isFake}
        $isFailed={
          record.taskStatus
            ? classifyTaskStatus(record.taskStatus) === "error"
            : false
        }
        onClick={() => {
          onClick?.();
        }}
      >
        <TypeBox>
          {record.isFake ? (
            <UncaugthIcon src={uncaughtGif} />
          ) : (
            <>
              {record.tasktype && (
                <>
                  <span>{record.tasktype}</span>
                  {record.includeSyncAccount && (
                    <>
                      <span>{"+"}</span>
                      <span>{"AA"}</span>
                    </>
                  )}
                </>
              )}

              {record.activityType && (
                <>
                  <ActivityIcon src={getActivityIcon(record.activityType)} />
                  <span>{record.activityType}</span>
                </>
              )}
            </>
          )}
        </TypeBox>
        <ViewMoreTip>
          <span>{t("View more")}</span>
          <ViewMoreIcon />
        </ViewMoreTip>

        {/* Box Left */}
        <BoxLeft>
          <BoxLeftLineOne>
            <TokenOrChainIcon
              src={record.chainImageUrl}
              size={16}
              type="chain"
            />
            <GlobalStyle.SmallerText>
              {record.chainSymbol ? record.chainSymbol : "-"}
            </GlobalStyle.SmallerText>
          </BoxLeftLineOne>

          <BoxLeftLineTwo>
            {record.tasktype && (
              <>
                <StatusIcon
                  src={getTaskStatusIcon(record.isFake, record.taskStatus)}
                />
                <GlobalStyle.SmallerText>
                  {getTaskStatusText(record.isFake, record.taskStatus)}
                </GlobalStyle.SmallerText>
              </>
            )}

            {record.activityType &&
              (record.isFake ? (
                <>
                  <StatusIcon src={statusUncaughtIcon} />
                  <GlobalStyle.SmallerText>
                    {t("Uncaught...")}
                  </GlobalStyle.SmallerText>
                </>
              ) : (
                <>
                  <StatusIcon src={statusCompletedIcon} />
                  <GlobalStyle.SmallerText>
                    {t("Completed")}
                  </GlobalStyle.SmallerText>
                </>
              ))}
          </BoxLeftLineTwo>
        </BoxLeft>

        {/* Box Right */}
        <BoxRight>
          <BoxRightLineOne>
            {record.transferProps && (
              <>
                <GlobalStyle.H6Text>
                  {`-${getDisplayAmount(record.transferProps.amount)} ${
                    record.transferProps.symbol
                  }`}
                </GlobalStyle.H6Text>
                <TokenOrChainIcon
                  src={record.transferProps.imageUrl}
                  type="token"
                  size={16}
                />
              </>
            )}

            {record.swapProps && (
              <>
                <GlobalStyle.H6Text color={GlobalStyle.colors.kontos}>
                  {`+${getDisplayAmount(record.swapProps.outFtAssetAmount)} ${
                    record.swapProps.outFtAssetSymbol
                  }`}
                </GlobalStyle.H6Text>
                <TokenOrChainIcon
                  src={record.swapProps.outFtAssetImageUrl}
                  type="token"
                  size={16}
                />
              </>
            )}

            {record.intentionProps && (
              <GlobalStyle.H6Text>
                {`-${getDisplayAmount(record.intentionProps.totalUsdCost)} USD`}
              </GlobalStyle.H6Text>
            )}

            {record.opCallProps && (
              <GlobalStyle.H6Text>
                {`-${getDisplayAmount(record.opCallProps.totalUsdCost)} USD`}
              </GlobalStyle.H6Text>
            )}

            {record.receiveProps && (
              <>
                <GlobalStyle.H6Text color={GlobalStyle.colors.kontos}>
                  {`+${getDisplayAmount(record.receiveProps.amount)} ${
                    record.receiveProps.assetSymbol
                  }`}
                </GlobalStyle.H6Text>
                <TokenOrChainIcon
                  src={record.receiveProps.assetImageUrl}
                  type="token"
                  size={16}
                />
              </>
            )}

            {record.sendProps && (
              <>
                <GlobalStyle.H6Text>
                  {`-${getDisplayAmount(record.sendProps.amount)} ${
                    record.sendProps.assetSymbol
                  }`}
                </GlobalStyle.H6Text>
                <TokenOrChainIcon
                  src={record.sendProps.assetImageUrl}
                  type="token"
                  size={16}
                />
              </>
            )}
          </BoxRightLineOne>

          <BoxRightLineTwo>
            {record.transferProps && (
              <GlobalStyle.SmallerText>
                {`Gas: -${getDisplayAmount(
                  record.transferProps.totalUsdCost
                )} USD`}
              </GlobalStyle.SmallerText>
            )}

            {record.swapProps && (
              <>
                <GlobalStyle.SmallerText>
                  {`-${getDisplayAmount(record.swapProps.ftAssetAmount)} ${
                    record.swapProps.ftAssetSymbol
                  }`}
                </GlobalStyle.SmallerText>
                <TokenOrChainIcon
                  src={record.swapProps.ftAssetImageUrl}
                  type="token"
                  size={16}
                />
              </>
            )}

            {record.intentionProps && (
              <>
                <GlobalStyle.SmallerText>
                  {`+${getDisplayAmount(
                    record.intentionProps.requiredAssets?.[0].amount
                  )}`}
                </GlobalStyle.SmallerText>
                <TokenOrChainIcon
                  src={record.intentionProps.requiredAssets?.[0].imageUrl}
                  type="token"
                  size={16}
                />
              </>
            )}

            {record.opCallProps && (
              <GlobalStyle.SmallerText>
                {record.opCallProps.opCallDestAddrs.length > 0
                  ? record.opCallProps.opCallDestAddrs.length > 1
                    ? shortAddress(record.opCallProps.opCallDestAddrs[0]) +
                      " (" +
                      record.opCallProps.opCallDestAddrs.length +
                      ")"
                    : shortAddress(record.opCallProps.opCallDestAddrs[0])
                  : `...`}
              </GlobalStyle.SmallerText>
            )}

            {record.receiveProps &&
              (ethers.utils.isAddress(record.receiveProps.address || "-") ? (
                <GlobalStyle.SmallerText>
                  {formatAddress(record.receiveProps.address || "-")}
                </GlobalStyle.SmallerText>
              ) : (
                <>
                  <Icon src={avatarIcon} size={16} />
                  <GlobalStyle.SmallerText>
                    {formatAddress(record.receiveProps.address || "-")}
                  </GlobalStyle.SmallerText>
                </>
              ))}

            {record.sendProps &&
              (ethers.utils.isAddress(record.sendProps.address || "-") ? (
                <GlobalStyle.SmallerText>
                  {formatAddress(record.sendProps.address || "-")}
                </GlobalStyle.SmallerText>
              ) : (
                <>
                  <Icon src={avatarIcon} size={16} />
                  <GlobalStyle.SmallerText>
                    {formatAddress(record.sendProps.address || "-")}
                  </GlobalStyle.SmallerText>
                </>
              ))}
          </BoxRightLineTwo>
        </BoxRight>
      </TaskBox>
    </Container>
  );
};
