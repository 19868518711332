import { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import AddSvg from "src/assets/icons/add3.svg";

const Button = styled.button`
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 28px;
  border: none;
  border-radius: 99px;
  background: ${(props) => props.theme.colors.__deep_800};
  transition: 0.2s;

  color: ${(props) => props.theme.colors.__white};
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const FixedIcon = styled.img`
  display: span;
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

interface Props {
  children?: ReactNode;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const AddButton: React.FC<Props> = ({ children, disabled, onClick }) => {
  return (
    <Button disabled={disabled} onClick={onClick}>
      <FixedIcon src={AddSvg} />
      {children}
    </Button>
  );
};

export default AddButton;
