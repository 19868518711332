import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import {
  ReqFtAssetsV3,
  RespFtAssetsV3,
} from "@zkkontos/kontos-sdk/src/api/assetApi";
import { FtAssetV2 } from "@zkkontos/kontos-sdk/src/api/types";
import { EXPLORER_KONTOS_URL } from "src/config";
import { API } from "./core/API";
import { KontosClient } from "@zkkontos/kontos-sdk";
import { FtAsset } from "src/type/zkkontos";
import { ftassetsV3 } from "@zkkontos/kontos-sdk/src/api/assetApi";

/*
 * markets api
 * */
export interface AssetLabel {
  chainIndex: string;
  category: number;
  imageUrl: string;
  symbol: string;
  subCategories: string[] | null;
}

interface AssetLabelsData {
  assetLabels: AssetLabel[];
}

interface ReqFtAssetsLael {}

export const getFtAssetsLabel = async (
  data: ReqFtAssetsLael,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<AssetLabelsData> => {
  return request<ReqFtAssetsLael, AssetLabelsData>(
    API.markets.ftAssetsLabel,
    data,
    endpoint
  );
};

//CategoryList = []CategoryTypeDetail{
//    {int64(Watchlist), "Favorites", ""},
//    {int64(RecommendedV2), "Recommended", ""},
//    {int64(TopSearchers), "HOT", ""},
//    {int64(Trending), "Trending", ""},
//    {int64(NewCoins), "New", ""},
//    {int64(MEME), "MEME", ""},
//    {int64(H24Volume), "H24Volume", ""},
//    {int64(AI), "AI", ""},
//    {int64(TopGainers), "TopGainers", ""},
//    {int64(TopLosers), "TopLosers", ""},
//    {int64(RWA), "RWA", ""},
//    {int64(GameFi), "GameFi", ""},
//    {int64(DePin), "DePin", ""},
//    {int64(SolanaEcosystem), "SolanaEcosystem", ""},
//    {int64(SolanaMEME), "SolanaMEME", ""},
//    {int64(BaseEcosystem), "BaseEcosystem", ""},
//    {int64(BaseMEME), "BaseMEME", ""},
// }
interface AssetData {
  ftAssets: FtAsset[];
  marketPredictInfo: {
    action: number;
    account: string;
    bullish: number;
    bearish: number;
  };
}

interface ReqFtAssetsV2 {
  chainIndex: string;
  category: number;
  subcategory: string;
  offset: number;
  limit: number;
  account?: string;
  sortName?: 0 | 1 | 2;
  sortPriceChange?: 0 | 1 | 2;
  sortLastPrice?: 0 | 1 | 2;
  sortH24Vol?: 0 | 1 | 2;
  regex?: string;
}

export const getFtAssetsV2 = async (
  data: ReqFtAssetsV2,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<AssetData> => {
  return request<ReqFtAssetsV2, AssetData>(
    API.markets.ftAssetsV2,
    data,
    endpoint
  );
};

export const getFtAssetsV3 = async (
  data: ReqFtAssetsV3
): Promise<RespFtAssetsV3> => {
  return ftassetsV3(data);
};

interface ReqAssetsDetailQuote {
  chainIndex: string;
  ftAssetAddress: string;
}

interface ReqPredictInfo {
  account: string;
}

export interface RespPredictInfo {
  marketPredict: {
    action: number;
    account: string;
    bullish: number;
    bearish: number;
  };
}

export interface AssetsDetailQuote {
  description: string;
  medias: {
    mediaUrl: string;
    imageUrl: string;
  }[];
}

export const getFtAssetsDetailQuote = async (
  data: ReqAssetsDetailQuote,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<AssetsDetailQuote> => {
  return request<ReqAssetsDetailQuote, AssetsDetailQuote>(
    API.markets.ftAssetDetail,
    data,
    endpoint
  );
};

export const getMarketPredictInfo = async (
  data: ReqPredictInfo,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespPredictInfo> => {
  return request<ReqPredictInfo, RespPredictInfo>(
    API.markets.marketPredictInfo,
    data,
    endpoint
  );
};

export enum ChartFilterOption {
  day = 0,
  minutes = 1,
  hour = 2,
}

interface ReqOHLC {
  chainIndex: string;
  ftAssetAddress: string;
  coinGeckoCoinId: string;
  interval: ChartFilterOption;
}

export interface OHLCData {
  time: number;
  open: string;
  height: string;
  low: string;
  close: string;
}

interface RespOHLC {
  ohlc: OHLCData[];
  h24: OHLCData[];
  allTime: OHLCData[];
}

export const getOHLC = async (
  data: ReqOHLC,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespOHLC> => {
  try {
    const rs = await request<ReqOHLC, RespOHLC>(
      API.markets.OHLC,
      data,
      endpoint
    );
    return rs;
  } catch (e) {
    console.log(e);
    return { ohlc: [], h24: [], allTime: [] };
  }
};

export enum VoteAction {
  Bullish = 0,
  Bearish = 1,
}

export interface ReqVote {
  action: VoteAction;
  account: string;
  expiredAt: number;
  signature: string;
}

export interface RespVote {
  action: number;
  account: string;
  bullish: number;
  bearish: number;
}

export const callVoteAction = async (
  data: ReqVote,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespPredictInfo> => {
  return request<ReqVote, RespPredictInfo>(API.markets.vote, data, endpoint);
};

export interface IVote {
  account: string;
  cli: KontosClient;
  expiration?: number;
  action?: VoteAction;
}

export interface ReqGetFtAssetRiskInfo {
  chainIndex: string;
  assetAddress: string;
}

export interface IFtAssetRiskData {
  risky: number;
  alert: number;
  buyFee: string;
  sellingFee: string;
  risks?: {
    riskTypeName: string;
    riskDetailList?: {
      riskCategoryType: string;
      // 0 normal 1 warning 2 danger
      riskCategoryLevel: number;
      description: string;
    }[];
  }[];
}

export const getFtAssetsRiskInfo = async (
  data: ReqGetFtAssetRiskInfo,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<IFtAssetRiskData> => {
  return request<ReqGetFtAssetRiskInfo, IFtAssetRiskData>(
    API.markets.ftAssetsRiskInfo,
    data,
    endpoint
  );
};
