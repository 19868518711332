import styled from "styled-components";
import LogoIcon from "src/assets/images/logo.svg";
import { useState } from "react";
import { ROUTE_MAIN } from "../../../router/router-config";
// import kontosStore from '../../../store';
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { PINCodeInput } from "../../../components/images/PINCodeInput";
import { Button } from "src/components/button/Button";
import { KontosKeyHelper } from "@zkkontos/kontos-sdk";
import toast from "src/components/toast/Toast";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(/static/start-bg.png) no-repeat center center;
  width: 100%;
  height: 100%;
  padding: 20px;
  max-width: 375px;
  margin: 0 auto;

  .app-icon {
    width: 42px;
    height: 42px;
    margin-top: 80px;
    margin-bottom: 20px;
  }

  .title {
    color: #413dec;
    font-weight: 700;
    font-size: 24px;
    font-family: "HarmonyOS Sans SC";
  }

  .desc {
    position: relative;
    color: #1a2535;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 12px;
    padding-left: 20px;

    :before {
      position: absolute;
      top: 5px;
      left: 5px;
      content: "";
      border-radius: 50%;
      padding: 2px;
      background: #000;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    transform: translateY(23px);

    .logo {
      height: auto;
      width: 65px;
      margin-left: 8px;
      transform: translateY(2px);
    }

    .radio {
      margin: 0;
      margin-right: 10px;
      appearance: none;
      position: relative;

      :before {
        content: "";
        width: 15px;
        height: 15px;
        border: 1px solid #413dec;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
      }
    }

    .checked:after {
      content: "";
      width: 11px;
      height: 11px;
      background: #413dec;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      position: absolute;
    }

    .link {
      color: #413dec;
      cursor: pointer;
      margin-left: 4px;
    }

    .logo {
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 24px;

    .info {
      width: 100%;
      color: #1a2535;
      text-align: center;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
`;

export const PINCode: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [seed, setSeed] = useState("");

  const handleConfirm = async () => {
    try {
      setLoading(true);
      // const storageKey = await kontosStore.getKey();
      // const key = await KontosKeyHelper.reEncryptStorageKontosKey(
      //   storageKey,
      //   seed
      // );
      const key = {
        accountName: "blues18",
        pubKeyData: {
          compressedPubKey:
            "02a314f9c4607d14aa6f57453e99f26fefeb5b85c2eaa13504ce749f09d03c979d",
          publicKeyX:
            "0xa314f9c4607d14aa6f57453e99f26fefeb5b85c2eaa13504ce749f09d03c979d",
          publicKeyY:
            "0x8b8a0bb8dc93c872be7a351b16e6fad91b8e33517dfd9af68eb830e71f305a16",
        },
        privateKey: {},
        encryptedPrivateKey:
          "îP\u0001}uLPoÔå\u0003 FÖ¯\u001deñrÒ\u0004à\u001d\r$­ß¶ÜÂªS\u000f\u0010\u0005ÓI\u0002áÅ½¾m]s+\u0017üa²/ ù`RP©\u0007X¶\u0018ÚnøLßcÚ[¿v\n¨ü+¥G\u0002Ä\u00145oZ/ul\u0014Óö;\u001e»ô¥{_{\u0005ÅóÇ¥ô}WIÝwkW9E\b i'ú\r±ånø\u001eý e¹ÇRh¯$´?¨\u0004^CUé~XN0v')QU3\u001dLÔ,³O!yÒ\u0001»Þ>9¯:\t)QgtLo^\u0007C¸ØpmÙ8)ËÏ¤_VwkläDE",
        salt: "ïqáÇZ©'3ÏäEGÝ`i",
        iv: "\u0018 ;J«\u00189Ýßá",
        isDefaultSeed: false,
      };
      // await kontosStore.updateKey(key, seed);
      setLoading(false);
      navigate(ROUTE_MAIN);
    } catch (e) {
      console.error("cannot set pin code:", e);
      if (e instanceof Error) {
        toast({ text: e.message, type: "error" });
      } else {
        toast({ text: "unknown error", type: "error" });
      }
    }
  };

  return (
    <Wrapper>
      <img className="app-icon" src="/static/kontos-app.svg" alt="kontos-app" />
      <div className="title">{t("Set Unlock PIN Code")}</div>
      <div className="input-wrapper">
        <div className="info">{t("Enter the PIN Code to Unlock")}</div>
        <PINCodeInput size="small" onPINChange={setSeed} />
      </div>
      <div className="desc">
        {t("The code is only used to unlock your wallet on this device")}
      </div>
      <div className="desc">
        {t("The code is not related to the Kontos wallet recovery password")}
      </div>
      <Button
        text={t("Confirm")}
        fullWidth
        loading={loading}
        disabled={!seed || seed.length !== 6}
        onClick={handleConfirm}
      />
      <div className="footer">
        <span>{t("Powered by")}</span>
        <img src={LogoIcon} className={"logo"} />
      </div>
    </Wrapper>
  );
};
